export interface User {
    email: string;
    access_token: string;
    customerId: string;
    is_admin: string;
    token_type: string;
    userName: string;
    isActive: boolean | string;
    isMFAEnabled: boolean | string;
}

export interface LoginPayload {
    email: string;
    password: string,
    forceLogin?: boolean;
    mfaOTP?: string;
    rememberMFADevice?: boolean;
    deviceName?: string;
    isOTP?: boolean;
    mfaToken?: string;
}

export enum Login_Status {
    LOGGED_OUT = 1,
    LOGGING_IN = 2,
    LOGIN_SUCCESS = 3,
    INCORRECT_PASSWORD = 4,
    ENTER_RESET_PASSWORD_CODE = 5,
    ALREADY_LOGGED_IN = 6,
    ENTER_MFA_OTP = 7,
    MFA_OTP_EXPIRED_USED = 8,
    MFA_OTP_INVALID = 9,
    SERVER_ERROR = 10,
    INVALID_USER = 11
}