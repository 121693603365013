export function BackIcon(props: any) {
 return (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
   <g id="Group_149757" data-name="Group 149757" transform="translate(-32 -11)">
    <g id="Rectangle_23766" data-name="Rectangle 23766" transform="translate(32 11)" fill="#5f6f57" stroke="#707070" strokeWidth="1" opacity="0">
     <rect width="24" height="24" stroke="none" />
     <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
    </g>
    <path id="Path_206347" data-name="Path 206347" d="M7.771,12.542,3,7.771m0,0L7.771,3M3,7.771h9.542a4.771,4.771,0,1,1,0,9.542H10.156" transform="translate(33.688 12.688)" fill="none" stroke="#5f6f57" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
   </g>
  </svg>
 );
}
