import React from 'react'

export default function AssetIcon(props: any) {
    return (
        <svg{...props} id="Group_222590" data-name="Group 222590" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <path id="Path_238761" data-name="Path 238761" d="M24,0A24,24,0,1,1,0,24,24,24,0,0,1,24,0Z" fill="#cfe3c9" />
            <path id="Path_237219" data-name="Path 237219" d="M16.068,26.065a1.41,1.41,0,0,0,.938,1.34l5.666,2.06,2.06,5.665a1.429,1.429,0,0,0,2.679,0l2.06-5.671L35.136,27.4a1.429,1.429,0,0,0,0-2.679l-5.671-2.06L27.41,17a1.429,1.429,0,0,0-2.679,0l-2.06,5.671-5.665,2.054A1.41,1.41,0,0,0,16.068,26.065Z" transform="translate(-2.066 -2.068)" fill="#12131a" />
        </svg>
    )
}
