import { createSelector } from "reselect";
import { ClientRootState } from "../reducers";
import { Selectors as PortfolioSelectors } from "../modules/portfoliosReducer";
import { AssetTypeEnum, AssetTypeTotal, HoldingAssetAllocationDropdownValue, HoldingsMode, SortDirection, StockAddInfo } from "../../constants";
import Big from "big.js";
import { getAMIDAssetTotalForAMID, getAMIDAssetTotals, getAssetAddInfo, getAssetAllocationCategTotals, getAssetTypeSubCategAMIDAssetTotals, getAssetTypeSubCategTotalForID, getAssetTypeSubCategTotals, getHoldingTableData, getMarketCapIDFromAddInfo, getMarketCapNameFromAddInfo, getMarketCapNameFromID, getPieChartData, getMFSEBICategoryNameFromAddInfo, getMFSEBICategoryIDFromAddInfo, getSectorIDFromAddInfo, getSectorNameFromAddInfo, getCategIDFromAddInfoFunctionBasedOnMode, getHoldingTableDataFromList, getTodayTableData, getCategNameFromAddInfoFunctionBasedOnMode, getPieChartDataFromList, getL1AACategFilterPortfolioSummary, getSIDAssetsForAMID, getMFSEBISubCategoryIDFromAddInfo, getMFSEBISubCategoryNameFromAddInfo, getCategIDForMFSEBISubCategoryID } from "../../utilities";
import { getStocksAssetAllocationIncludingMFETFs } from "../../utilities/mfPortfolioBreakdown";

//#region L1

const selectRawPortfolioSummaryAll = (state: ClientRootState) => state.shared.portfolioSummary.RawPortfolioSummary;

const selectPortfolioSummaryRaw = createSelector(
    [
      // Usual first input - extract value from `state`
      selectRawPortfolioSummaryAll,
      // Take the second arg, `category`, and forward to the output selector
      (state, params: {CCID: number, FamilyId: number, PFID: number}) => params
    ],
    // Output selector gets (`items, category)` as args
    (rawPortfolioSummaryAll, params) => ((rawPortfolioSummaryAll[params.CCID] || {})[params.FamilyId] || {})[params.PFID]
)

const selectActivePortfolioSummaryRaw = createSelector(
    [
        selectRawPortfolioSummaryAll,
        PortfolioSelectors.selectActivePortfolioIDs
    ],
    (rawPortfolioSummaryAll, activePortfolioIDs) => {
        if (!activePortfolioIDs) return undefined;

        return ((rawPortfolioSummaryAll[activePortfolioIDs.CCID] || {})[activePortfolioIDs.FamilyId] || {})[activePortfolioIDs.PFID];
    }
)

const portfolioSummarySelector = (state: ClientRootState) => state.shared.portfolioSummary;
const selectPriceURLs = (state: ClientRootState) => portfolioSummarySelector(state).ActivePortfolioPrices.PriceURLs;
const selectActiveAssetsAddInfo = (state: ClientRootState) => portfolioSummarySelector(state).ActiveAssetsAddInfo;
const selectActiveMFETFPortfolioBreakdown = (state: ClientRootState) => portfolioSummarySelector(state).ActiveMFETFPortfolioBreakdown;
const selectIsActiveMFETFPortfolioBreakdownLoading = (state: ClientRootState) => selectActiveMFETFPortfolioBreakdown(state)?.IsLoading;
const selectActivePortfolioSummary = (state: ClientRootState) => portfolioSummarySelector(state).ActivePortfolioSummary;
const selectActivePortfolioSummaryTotal = (state: ClientRootState) => selectActivePortfolioSummary(state)?.PortfolioTotal;
const selectActivePortfolioSummaryTotalCurrentValue = (state: ClientRootState) => selectActivePortfolioSummaryTotal(state)?.CurrValue;
const selectActivePortfolioSummaryTotalAmountInvested = (state: ClientRootState) => selectActivePortfolioSummaryTotal(state)?.InvAmt;
const selectActivePortfolioSummaryAssetTypeTotals = (state: ClientRootState) => selectActivePortfolioSummary(state)?.AssetTypeTotals;
const selectActivePortfolioSummaryAssetAllocationCategTotals = createSelector([selectActivePortfolioSummary, selectActiveAssetsAddInfo],
    (activePortfolioSummary, activeAssetsAddInfo) => getAssetAllocationCategTotals(activePortfolioSummary, activeAssetsAddInfo)
)

const selectIsActivePortfolioSummaryLoading = (state: ClientRootState) => selectActivePortfolioSummary(state) == undefined;

const selectAssetsForSearch = (state: ClientRootState) => selectActivePortfolioSummary(state)?.AssetsForSearch;

const selectActivePortfolioSummaryAssetTypeListUnsorted = createSelector([selectActivePortfolioSummaryAssetTypeTotals], assetTypeTotals => {
    var assetTypeList: AssetTypeTotal[] = [];
    if (!assetTypeTotals) return assetTypeList;

    assetTypeTotals.AllIDs.forEach((assetTypeID) => {
        assetTypeList.push(assetTypeTotals.ByID[assetTypeID]);
    })

    return assetTypeList;
});


const selectActivePortfolioSummaryAssetTypeListSorted = (SortByField: 'CurrValue'|'TGain', SortDir: SortDirection) => createSelector([selectActivePortfolioSummaryAssetTypeListUnsorted], assetTypeListUnsorted => {
    return assetTypeListUnsorted.sort((x,y) => (SortDir == SortDirection.Up ? 1 : -1) * (Big(x[SortByField] || 0).lt(y[SortByField] || 0) ? 1 : -1));
});

const selectActivePortfolioSummaryAssetTypeListForChart = selectActivePortfolioSummaryAssetTypeListSorted('CurrValue', SortDirection.Up);

const selectL1PieChartData_ByAssetAllocation = createSelector(
    [
        selectActivePortfolioSummaryAssetAllocationCategTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (activePortfolioSummaryAssetAllocationCategTotals, totalField) => {
    return getPieChartData(totalField, (item: any) => item.AssetAllocationCategName, (item: any) => ({AssetAllocationCategID: item.AssetAllocationCategID}), activePortfolioSummaryAssetAllocationCategTotals)
});

const selectL1PieChartData_ByAssetType = createSelector(
    [
        selectActivePortfolioSummaryAssetTypeListUnsorted,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (assetTypeTotals, totalField) => {
    return getPieChartDataFromList(totalField, (item: any) => item.AssetTypeName, (item: any) => ({AssetTypeID: item.AssetTypeID}), assetTypeTotals.filter(x => x.AssetTypeID != AssetTypeEnum.LN), 10);
});

const selectL1HoldingsPieChartData = (holdingsMode: HoldingsMode, totalField: HoldingAssetAllocationDropdownValue) => {
    switch (holdingsMode) {
        default:
        case HoldingsMode.AssetAllocation:
            return (state: ClientRootState) => selectL1PieChartData_ByAssetAllocation(state, totalField);
        case HoldingsMode.MProfitClassification:
            return (state: ClientRootState) => selectL1PieChartData_ByAssetType(state, totalField);
    }
}

const selectL1Table_ByAssetAllocation = createSelector(
    [selectActivePortfolioSummaryAssetAllocationCategTotals],
    (activePortfolioSummaryAssetAllocationCategTotals) => {
        return getHoldingTableData(activePortfolioSummaryAssetAllocationCategTotals);
    }
);

const selectL1Table_ByAssetType = createSelector(
    [selectActivePortfolioSummaryAssetTypeTotals],
    (assetTypeTotals) => {
        return getHoldingTableData(assetTypeTotals);
    }
);

const selectL1HoldingsTable = (holdingsMode: HoldingsMode) => {
    switch (holdingsMode) {
        default:
        case HoldingsMode.AssetAllocation:
            return selectL1Table_ByAssetAllocation;
        case HoldingsMode.MProfitClassification:
            return selectL1Table_ByAssetType;
    }
}

const selectActiveAssetTypesList = createSelector([selectActivePortfolioSummaryAssetTypeListForChart], assetTypeList => {
    return assetTypeList.map(x => ({
        AssetTypeID: x.AssetTypeID,
        AssetTypeName: x.AssetTypeName
    }));
})

const selectMyStocksTGainPct = createSelector(
    [selectActivePortfolioSummary],
    (activePortfolioSummary) => {
        var TGainPct = activePortfolioSummary?.AssetTypeTotals?.ByID[AssetTypeEnum.EQ]?.TGainPct;
        return TGainPct ? Big(TGainPct).toNumber() : undefined;
    }
)

const selectLeaderboardGainersLosers = createSelector(
    [selectActivePortfolioSummary],
    (activePortfolioSummary) => {
        var stocks = activePortfolioSummary?.AssetsByAssetType[AssetTypeEnum.EQ];
        if (!stocks) return undefined;

        var amidAssetTotals = getAMIDAssetTotals(stocks);

        var stocksSorted = amidAssetTotals.sort((x, y) => Big(x.TGainPct || 0).lt(y.TGainPct || 0) ? -1 : 1);

        var Losers = stocksSorted.filter(x => Big(x.TGainPct || 0).lt(0)).slice(0, 10);
        var Gainers = stocksSorted.filter(x => Big(x.TGainPct || 0).gt(0)).reverse().slice(0, 10);

        return {
            Gainers,
            Losers
        }
    }
)

export const L1PortfolioSummarySelectors = {
    selectPortfolioSummaryRaw, portfolioSummarySelector, selectPriceURLs, selectActivePortfolioSummary, selectActivePortfolioSummaryTotal,
    selectActivePortfolioSummaryTotalCurrentValue, selectActivePortfolioSummaryTotalAmountInvested, selectActivePortfolioSummaryAssetTypeTotals, selectActivePortfolioSummaryAssetTypeListForChart,
    selectActivePortfolioSummaryAssetTypeListUnsorted, selectActivePortfolioSummaryAssetTypeListSorted,
    selectActivePortfolioSummaryAssetAllocationCategTotals, selectL1PieChartData_ByAssetAllocation,
    selectL1HoldingsPieChartData, selectL1HoldingsTable,
    selectActiveAssetTypesList,
    selectIsActivePortfolioSummaryLoading,
    selectActiveAssetsAddInfo,
    selectMyStocksTGainPct, selectLeaderboardGainersLosers,
    selectAssetsForSearch
};

const selectAssetsByAssetTypeAll = createSelector(
    [L1PortfolioSummarySelectors.selectActivePortfolioSummary],
    (activePortfolioSummary) => {
        return activePortfolioSummary?.AssetsByAssetType;
    }
)

//#endregion

//#region L1 AA

const selectActivePortfolioSummaryForAACategID
    = createSelector([selectActivePortfolioSummary, PortfolioSelectors.selectActiveAssetAllocationCategID, selectActiveAssetsAddInfo],
    (activePortfolioSummary, assetAllocationCategID, assetsAddInfo) => {
        if (activePortfolioSummary && assetsAddInfo && assetAllocationCategID) {
            return getL1AACategFilterPortfolioSummary(activePortfolioSummary, assetAllocationCategID, assetsAddInfo);
        } else {
            return undefined;
        }
});

const selectActivePortfolioSummaryForAACategIDTotal = (state: ClientRootState) => selectActivePortfolioSummaryForAACategID(state)?.PortfolioTotal;
const selectActivePortfolioSummaryForAACategIDTotalCurrentValue = (state: ClientRootState) => selectActivePortfolioSummaryForAACategIDTotal(state)?.CurrValue;
const selectActivePortfolioSummaryForAACategIDTotalAmountInvested = (state: ClientRootState) => selectActivePortfolioSummaryForAACategIDTotal(state)?.InvAmt;
const selectActivePortfolioSummaryForAACategIDAssetTypeTotals = (state: ClientRootState) => selectActivePortfolioSummaryForAACategID(state)?.AssetTypeTotals;

const selectL1_AA_Table_ByAssetType = createSelector(
    [selectActivePortfolioSummaryForAACategIDAssetTypeTotals],
    (assetTypeTotals) => {
        return getHoldingTableData(assetTypeTotals);
    }
);

const selectL1_AA_PieChartData_ByAssetType = createSelector(
    [
        selectActivePortfolioSummaryForAACategIDAssetTypeTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (assetTypeTotals, totalField) => {
    return getPieChartData(totalField, (item: any) => item.AssetTypeName, (item: any) => ({AssetTypeID: item.AssetTypeID}), assetTypeTotals, 10);
});

const selectActivePortfolioSummaryForAACategIDAssetTypeListUnsorted = createSelector([selectActivePortfolioSummaryForAACategIDAssetTypeTotals], assetTypeTotals => {
    var assetTypeList: AssetTypeTotal[] = [];
    if (!assetTypeTotals) return assetTypeList;

    assetTypeTotals.AllIDs.forEach((assetTypeID) => {
        if (assetTypeID != AssetTypeEnum.LN) {
            assetTypeList.push(assetTypeTotals.ByID[assetTypeID]);
        }
    })

    return assetTypeList;
});

const selectActivePortfolioSummaryForAACategIDAssetTypeListSorted = (SortByField: 'CurrValue'|'TGain', SortDir: SortDirection) => createSelector([selectActivePortfolioSummaryForAACategIDAssetTypeListUnsorted], assetTypeListUnsorted => {
    return assetTypeListUnsorted.sort((x,y) => (SortDir == SortDirection.Up ? 1 : -1) * (Big(x[SortByField] || 0).lt(y[SortByField] || 0) ? 1 : -1));
});

export const L1_AA_PortfolioSummarySelectors = {
    selectActivePortfolioSummaryForAACategID,
    selectActivePortfolioSummaryForAACategIDTotal,
    selectActivePortfolioSummaryForAACategIDTotalCurrentValue,
    selectActivePortfolioSummaryForAACategIDTotalAmountInvested,
    selectL1_AA_Table_ByAssetType,
    selectL1_AA_PieChartData_ByAssetType,
    selectActivePortfolioSummaryForAACategIDAssetTypeListSorted
}

//#endregion

//#region L2

const selectActiveL2PortfolioSummaryTotal = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs, L1PortfolioSummarySelectors.selectActivePortfolioSummary, L1_AA_PortfolioSummarySelectors.selectActivePortfolioSummaryForAACategID],
    (activeScreenIDs, activePortfolioSummary, activePortfolioSummaryForAACategID) => {
        if (!activeScreenIDs || !activeScreenIDs.AssetType) return undefined;

        var relevantActivePortfolioSummary = activeScreenIDs.AssetAllocationCategID && activeScreenIDs.AssetAllocationCategID > 0 ? activePortfolioSummaryForAACategID : activePortfolioSummary;

        return relevantActivePortfolioSummary?.AssetTypeTotals?.ByID[activeScreenIDs.AssetType];
    }
)

const selectActiveL2PortfolioSummaryTotalCurrentValue = (state: ClientRootState) => selectActiveL2PortfolioSummaryTotal(state)?.CurrValue;
const selectActiveL2PortfolioSummaryTotalAmountInvested = (state: ClientRootState) => selectActiveL2PortfolioSummaryTotal(state)?.InvAmt;

const selectActiveL2Assets = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs, L1PortfolioSummarySelectors.selectActivePortfolioSummary, L1_AA_PortfolioSummarySelectors.selectActivePortfolioSummaryForAACategID],
    (activeScreenIDs, activePortfolioSummary, activePortfolioSummaryForAACategID) => {
        if (!activeScreenIDs || !activeScreenIDs.AssetType) return undefined;

        var relevantActivePortfolioSummary = activeScreenIDs.AssetAllocationCategID && activeScreenIDs.AssetAllocationCategID > 0 ? activePortfolioSummaryForAACategID : activePortfolioSummary;
    
        return relevantActivePortfolioSummary?.AssetsByAssetType[activeScreenIDs.AssetType];
    }
)

const selectL2TodaysTable = (holdingsMode: HoldingsMode) => {
    switch (holdingsMode) {
        case HoldingsMode.Sectors:
            return selectL2TodayTable_ByStocksSector;
        case HoldingsMode.MarketCap:
            return selectL2TodayTable_ByStocksMarketCap;
        default:
        case HoldingsMode.Assets:
            return selectL2TodayTable_ByAsset;
        case HoldingsMode.MFSEBICategory:
            return selectL2TodayTable_ByMFSEBICategory;
    }
}

const selectL2TodayTable_ByAsset = createSelector(
    [selectActiveL2Assets], (activeL2Assets) => {
        if (!activeL2Assets) return undefined;

        return getAMIDAssetTotals(activeL2Assets).sort((x,y) => {return Big(x.CurrValue || 0).gt(y.CurrValue || 0) ? -1 : 1});
    } 
)

const selectActivePortfolioSummaryStocksSectorTotals = createSelector([
        (state: ClientRootState) => selectAssetsByAssetType(state, AssetTypeEnum.EQ),
        selectActiveAssetsAddInfo
    ],
    (assetsByAssetType, activeAssetsAddInfo) => {
        return getAssetTypeSubCategTotals(
            AssetTypeEnum.EQ, assetsByAssetType, activeAssetsAddInfo,
            getSectorIDFromAddInfo,
            getSectorNameFromAddInfo,
        )
    }
)

const selectActivePortfolioSummaryStocksMarketCapTotals = createSelector([
        (state: ClientRootState) => selectAssetsByAssetType(state, AssetTypeEnum.EQ),
        selectActiveAssetsAddInfo
    ],
    (assetsByAssetType, activeAssetsAddInfo) => {
        return getAssetTypeSubCategTotals(
            AssetTypeEnum.EQ, assetsByAssetType, activeAssetsAddInfo,
            getMarketCapIDFromAddInfo,
            getMarketCapNameFromAddInfo,
        )
    }
)

const selectActivePortfolioSummaryMFSEBICategoryTotals = createSelector([
        (state: ClientRootState) => selectAssetsByAssetType(state, AssetTypeEnum.MFEQ),
        selectActiveAssetsAddInfo
    ],
    (assetsByAssetType, activeAssetsAddInfo) => {
        return getAssetTypeSubCategTotals(
            AssetTypeEnum.MFEQ, assetsByAssetType, activeAssetsAddInfo,
            getMFSEBICategoryIDFromAddInfo,
            getMFSEBICategoryNameFromAddInfo,
        )
    }
)

const selectL2TodayTable_ByStocksSector = createSelector(
    [selectActivePortfolioSummaryStocksSectorTotals],
    (totals) => {
        return getTodayTableData(totals);
    }
);

const selectL2TodayTable_ByStocksMarketCap = createSelector(
    [selectActivePortfolioSummaryStocksMarketCapTotals],
    (totals) => {
        return getTodayTableData(totals);
    }
);

const selectL2TodayTable_ByMFSEBICategory = createSelector(
    [selectActivePortfolioSummaryMFSEBICategoryTotals],
    (totals) => {
        return getTodayTableData(totals);
    }
);

const selectL2PieChartData_ByAsset = createSelector(
    [
        selectL2TodayTable_ByAsset,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
    return getPieChartDataFromList(totalField, (item: any) => item.Name, (item: any) => ({AMID: item.AMID, SID: item.SID}), totals, 10);
});

const selectL2PieChartData_ByStocksSector = createSelector(
    [
        selectActivePortfolioSummaryStocksSectorTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
    return getPieChartData(totalField, (item: any) => item.CategName, (item: any) => ({CategID: item.CategID}), totals, 10);
});

const selectL2PieChartData_ByStocksMarketCap = createSelector(
    [
        selectActivePortfolioSummaryStocksMarketCapTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
    return getPieChartData(totalField, (item: any) => item.CategName, (item: any) => ({CategID: item.CategID}), totals, 10);
});

const selectL2PieChartData_ByMFSEBICategory = createSelector(
    [
        selectActivePortfolioSummaryMFSEBICategoryTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
    return getPieChartData(totalField, (item: any) => item.CategName, (item: any) => ({CategID: item.CategID}), totals, 10);
});

const selectL2HoldingsPieChartData = (holdingsMode: HoldingsMode, totalField: HoldingAssetAllocationDropdownValue, assetType?: AssetTypeEnum) => {
    switch (holdingsMode) {
        default:
        case HoldingsMode.Sectors:
            return (state: ClientRootState) => selectL2PieChartData_ByStocksSector(state, totalField);
        case HoldingsMode.MarketCap:
            return (state: ClientRootState) => selectL2PieChartData_ByStocksMarketCap(state, totalField);
        case HoldingsMode.Assets:
            return (state: ClientRootState) => selectL2PieChartData_ByAsset(state, totalField);
        case HoldingsMode.MFSEBICategory:
            return (state: ClientRootState) => selectL2PieChartData_ByMFSEBICategory(state, totalField);
    }
}

const selectL2HoldingsTable_ByAsset = createSelector(
    [selectL2TodayTable_ByAsset],
    (totals) => {
        return getHoldingTableDataFromList(totals);
    }
);

const selectL2HoldingsTable_ByStocksSector = createSelector(
    [selectActivePortfolioSummaryStocksSectorTotals],
    (totals) => {
        return getHoldingTableData(totals);
    }
);

const selectL2HoldingsTable_ByStocksMarketCap = createSelector(
    [selectActivePortfolioSummaryStocksMarketCapTotals],
    (totals) => {
        return getHoldingTableData(totals);
    }
);

const selectL2HoldingsTable_ByMFSEBICategory = createSelector(
    [selectActivePortfolioSummaryMFSEBICategoryTotals],
    (totals) => {
        return getHoldingTableData(totals);
    }
);

const selectL2HoldingsTable = (holdingsMode: HoldingsMode) => {
    switch (holdingsMode) {
        default:
        case HoldingsMode.Sectors:
            return selectL2HoldingsTable_ByStocksSector;
        case HoldingsMode.MarketCap:
            return selectL2HoldingsTable_ByStocksMarketCap;
        case HoldingsMode.Assets:
            return selectL2HoldingsTable_ByAsset;
        case HoldingsMode.MFSEBICategory:
            return selectL2HoldingsTable_ByMFSEBICategory;
    }
}

const selectL2StocksAllocationIncludingMFETFPortBreakdown = createSelector(
    [
        selectAssetsByAssetTypeAll,
        selectActiveMFETFPortfolioBreakdown,
        selectActiveAssetsAddInfo
    ], (
        assetsByAssetType,
        activeMFETFPortfolioBreakdownObject,
        assetsAddInfo
    ) => {
        if (!assetsByAssetType || !activeMFETFPortfolioBreakdownObject) return undefined;

        var MFETFPortfolioBreakdown = getStocksAssetAllocationIncludingMFETFs(-1, assetsByAssetType, activeMFETFPortfolioBreakdownObject.Data, assetsAddInfo);

        return MFETFPortfolioBreakdown.sort((x,y) => {return Big(x.CurrValue || 0).gt(y.CurrValue || 0) ? -1 : 1});
    } 
)

const selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown = createSelector(
    [
        selectL2StocksAllocationIncludingMFETFPortBreakdown,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
    return getPieChartDataFromList(totalField, (item: any) => item.Name, (item: any) => ({AMID: item.AMID, SID: item.SID}), totals, 10);
});

const selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown = createSelector(
    [   
        selectL2StocksAllocationIncludingMFETFPortBreakdown,
        selectActivePortfolioSummaryTotalCurrentValue
    ],
    (totals, portfolioCurrentValue) => {
        return getHoldingTableDataFromList(totals, true, portfolioCurrentValue);
    }
);

export const L2PortfolioSummarySelectors = {
    selectActiveL2PortfolioSummaryTotal,
    selectActiveL2PortfolioSummaryTotalCurrentValue,
    selectActiveL2PortfolioSummaryTotalAmountInvested,
    selectL2PieChartData_ByAsset,
    selectL2TodaysTable,
    selectL2HoldingsPieChartData, selectL2HoldingsTable,
    selectL2StocksAllocationIncludingMFETFPortBreakdown,
    selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown,
    selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown,
    selectIsActiveMFETFPortfolioBreakdownLoading
}

//#endregion

//#region L3

const selectAssetsByAssetType = createSelector(
    [L1PortfolioSummarySelectors.selectActivePortfolioSummary, (state: ClientRootState, assetTypeID?: number) => assetTypeID],
    (activePortfolioSummary, assetTypeID) => {
        if (!assetTypeID) return undefined;

        return activePortfolioSummary?.AssetsByAssetType[assetTypeID];
    }
)

const selectActiveL3IDs = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs],
    (activeScreenIDs) => {
        if (!activeScreenIDs || !activeScreenIDs.AssetType) return undefined;

        switch (activeScreenIDs.AssetType) {
            case AssetTypeEnum.EQ:
                if (activeScreenIDs.L3Mode === HoldingsMode.Sectors) {
                    return { Mode: HoldingsMode.Sectors, AssetTypeID: activeScreenIDs.AssetType, CategID: activeScreenIDs.SectorID };
                } else if (activeScreenIDs.L3Mode === HoldingsMode.MarketCap) {
                    return { Mode: HoldingsMode.MarketCap, AssetTypeID: activeScreenIDs.AssetType, CategID: activeScreenIDs.MarketCapID };
                } else {
                    return undefined;
                }
            case AssetTypeEnum.MFEQ:
            case AssetTypeEnum.MFD:
                if (activeScreenIDs.L3Mode === HoldingsMode.MFSEBICategory) {
                    return { Mode: HoldingsMode.MFSEBICategory, AssetTypeID: activeScreenIDs.AssetType, CategID: activeScreenIDs.SEBICategoryID };
                } else if (activeScreenIDs.L3Mode === HoldingsMode.MFSEBISubCategory) {
                    return { Mode: HoldingsMode.MFSEBISubCategory, AssetTypeID: activeScreenIDs.AssetType, CategID: getCategIDForMFSEBISubCategoryID(activeScreenIDs.SEBICategoryID, activeScreenIDs.SEBISubCategoryID) };
                }
        }

        return undefined;
    }
)

const selectActiveL3TableData = createSelector(
    [
        selectActiveL2Assets,
        selectActiveL3IDs,
        selectActiveAssetsAddInfo
    ], (activeL2Assets, activeL3IDs, assetsAddInfo) => {
        if (!activeL2Assets || !activeL3IDs || activeL3IDs.CategID === undefined) return undefined;

        var getCategIDFromAssetAddInfo = getCategIDFromAddInfoFunctionBasedOnMode(activeL3IDs.Mode);

        if (getCategIDFromAssetAddInfo) {
            return getAssetTypeSubCategAMIDAssetTotals(activeL3IDs.CategID, activeL3IDs.AssetTypeID, activeL2Assets, assetsAddInfo, getCategIDFromAssetAddInfo);
        } else {
            return undefined;
        }
    } 
)

const selectL3PieChartData = createSelector(
    [
        selectActiveL3TableData,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
    return getPieChartDataFromList(totalField, (item: any) => item.Name, (item: any) => ({AMID: item.AMID, SID: item.SID}), totals, 10);
});

const selectActiveL3PortfolioSummaryTotal = createSelector(
    [
        selectActiveL2Assets,
        selectActiveL3IDs,
        selectActiveAssetsAddInfo
    ], (activeL2Assets, activeL3IDs, assetsAddInfo) => {
        if (!activeL2Assets || !activeL3IDs || activeL3IDs.CategID == undefined) return undefined;
        
        var getCategIDFromAssetAddInfo = getCategIDFromAddInfoFunctionBasedOnMode(activeL3IDs.Mode);
        var getCategNameFromAddInfo = getCategNameFromAddInfoFunctionBasedOnMode(activeL3IDs.Mode);
        
        if (getCategIDFromAssetAddInfo) {
            return getAssetTypeSubCategTotalForID(activeL3IDs.CategID, activeL3IDs.AssetTypeID, activeL2Assets, assetsAddInfo, getCategIDFromAssetAddInfo, getCategNameFromAddInfo);
        } else {
            return undefined;
        }
    } 
)

const selectActiveL3CategDetails = (state: ClientRootState) => {
    const total = selectActiveL3PortfolioSummaryTotal(state);
    return total ? { CategID: total.CategID, CategName: total.CategName } : undefined;
}

// const selectL3HoldingsPieChartData = (holdingsMode: HoldingsMode, totalField: HoldingAssetAllocationDropdownValue, assetType?: AssetTypeEnum) => {
//     switch (holdingsMode) {
//         default:
//         case HoldingsMode.Sectors:
//             return (state: ClientRootState) => selectL2PieChartData_ByStocksSector(state, totalField);
//         case HoldingsMode.MarketCap:
//             return (state: ClientRootState) => selectL2PieChartData_ByStocksMarketCap(state, totalField);
//         case HoldingsMode.Assets:
//             return (state: ClientRootState) => selectL2PieChartData_ByAsset(state, totalField);
//         case HoldingsMode.MFSEBICategory:
//             return (state: ClientRootState) => selectL2PieChartData_ByMFSEBICategory(state, totalField);
//     }
// }

const selectL3HoldingsPieChartData = createSelector(
    [
        selectActiveL3TableData,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
    return getPieChartDataFromList(totalField, (item: any) => item.Name, (item: any) => ({AMID: item.AMID, SID: item.SID}), totals, 10);
});


const selectL3HoldingsTable = createSelector(
    [selectActiveL3TableData],
    (totals) => {
        return getHoldingTableDataFromList(totals);
    }
);

export const L3PortfolioSummarySelectors = {
    selectActiveL3TableData,
    selectActiveL3PortfolioSummaryTotal,
    selectActiveL3IDs,
    selectL3PieChartData,
    selectL3HoldingsTable,
    selectL3HoldingsPieChartData,
    selectActiveL3CategDetails
}

// const selectActiveL3PortfolioSummaryTotal_Sector = createSelector(
//     [
//         (state: ClientRootState) => selectAssetsByAssetType(state, AssetTypeEnum.EQ),
//         selectActiveAssetsAddInfo,
//         (state: ClientRootState, sectorID: number) => sectorID,
//     ],
//     (assetsByAssetType, assetsAddInfo, sectorID) => {
//         return getAssetTypeSubCategAssetLists(sectorID, AssetTypeEnum.EQ, assetsByAssetType, assetsAddInfo, getSectorIDFromAddInfo);
//     }
// )

//#endregion

//#region L3 Category

const selectActiveMFSEBICategoryBySubCategoryTotals = createSelector(
    [
        (state: ClientRootState) => selectAssetsByAssetType(state, AssetTypeEnum.MFEQ),
        selectActiveL3IDs,
        selectActiveAssetsAddInfo
    ], (assetsByAssetType, activeL3IDs, assetsAddInfo) => {
        if (!assetsByAssetType || !activeL3IDs || activeL3IDs.CategID === undefined) return undefined;

        return getAssetTypeSubCategTotals(
            AssetTypeEnum.MFEQ, assetsByAssetType, assetsAddInfo,
            getMFSEBISubCategoryIDFromAddInfo,
            getMFSEBISubCategoryNameFromAddInfo,
            {
                getCategIDFromAssetAddInfo: getMFSEBICategoryIDFromAddInfo,
                matchValue: activeL3IDs.CategID
            }
        )
    } 
)

const selectL3CategoryHoldingsTable_ByMFSEBISubCategory = createSelector(
    [selectActiveMFSEBICategoryBySubCategoryTotals],
    (totals) => {
        return getHoldingTableData(totals);
    }
);

const selectL3CategoryHoldingsTable = (holdingsMode: HoldingsMode) => {
    switch (holdingsMode) {
        default:
        case HoldingsMode.MFSEBISubCategory:
            return selectL3CategoryHoldingsTable_ByMFSEBISubCategory;
        case HoldingsMode.Assets:
            return selectL3HoldingsTable;
    }
}

const selectL3CategoryPieChartData_ByMFSEBISubCategory = createSelector(
    [
        selectActiveMFSEBICategoryBySubCategoryTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
        return getPieChartData(totalField, (item: any) => item.CategName, (item: any) => ({CategID: item.CategID}), totals, 10);
});


const selectL3CategoryHoldingsPieChartData = (holdingsMode: HoldingsMode, totalField: HoldingAssetAllocationDropdownValue, assetType?: AssetTypeEnum) => {
    switch (holdingsMode) {
        case HoldingsMode.MFSEBISubCategory:
            return (state: ClientRootState) => selectL3CategoryPieChartData_ByMFSEBISubCategory(state, totalField);
        default:
        case HoldingsMode.Assets:
            return (state: ClientRootState) => selectL3PieChartData(state, totalField);
    }
}

const selectL3CategoryTodayTable_ByMFSEBISubCategory = createSelector(
    [selectActiveMFSEBICategoryBySubCategoryTotals],
    (totals) => {
        return getTodayTableData(totals);
    }
);

const selectL3CategoryTodayTable = (holdingsMode: HoldingsMode) => {
    switch (holdingsMode) {
        case HoldingsMode.MFSEBISubCategory:
            return selectL3CategoryTodayTable_ByMFSEBISubCategory;
        default:
            case HoldingsMode.Assets:
            return selectActiveL3TableData;
    }
}

export const L3CategoryPortfolioSummarySelectors = {
    selectL3CategoryHoldingsTable,
    selectL3CategoryHoldingsPieChartData,
    selectL3CategoryTodayTable
}

//#endregion

//#region L4

const selectActiveL4PortfolioSummaryTotal = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs, selectActiveL2Assets],
    (activeScreenIDs, SIDAssetTotals) => {
        if (!activeScreenIDs || !SIDAssetTotals || !activeScreenIDs.AssetType || !activeScreenIDs.AMID) return undefined;

        if (activeScreenIDs.SID && activeScreenIDs.SID > 0) {
            return SIDAssetTotals.ByID[activeScreenIDs.SID];
        } else {
            return getAMIDAssetTotalForAMID(SIDAssetTotals, activeScreenIDs.AMID);
        }
    }
)

const selectActiveL4TGainPct = createSelector(
    [selectActiveL4PortfolioSummaryTotal],
    (activeL4PortfolioSummaryTotal) => {
        var TGainPct = activeL4PortfolioSummaryTotal?.TGainPct;
        return TGainPct ? Big(TGainPct).toNumber() : undefined;
    }
)

const selectActiveL4AssetName = createSelector(
    [selectActiveL4PortfolioSummaryTotal],
    (activeL4PortfolioSummaryTotal) => {
        return activeL4PortfolioSummaryTotal?.Name
    }
)

const selectActiveL4AssetAddInfo = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs, selectActiveAssetsAddInfo],
    (activeScreenIDs, assetsAddInfo) => {
        if (!activeScreenIDs || !activeScreenIDs.AssetType || !activeScreenIDs.AMID) return undefined;

        return getAssetAddInfo(activeScreenIDs.AssetType, activeScreenIDs.AMID, assetsAddInfo);
    }
)

const selectActiveL4AssetStocksSector = createSelector(
    [selectActiveL4AssetAddInfo],
    (assetAddInfo) => {
        var SectorID = (assetAddInfo as StockAddInfo)?.SectorID;
        var SectorName = (assetAddInfo as StockAddInfo)?.SectorName;
        return { SectorID, SectorName };
    }
)

const selectActiveL4AssetStocksMarketCap = createSelector(
    [selectActiveL4AssetAddInfo],
    (assetAddInfo) => {
        var MarketCapID = (assetAddInfo as StockAddInfo)?.MarketCapClassification;
        var MarketCapName = getMarketCapNameFromID(MarketCapID);
        return { MarketCapID, MarketCapName };
    }
)

const selectActiveL4PercentOfTotal_Portfolio = (totalField: HoldingAssetAllocationDropdownValue) => ((state: ClientRootState) => selectActivePortfolioSummaryTotal(state)?.[totalField]);

const selectActiveL4PercentOfTotal_AssetType = (totalField: HoldingAssetAllocationDropdownValue) => ((state: ClientRootState) => selectActiveL2PortfolioSummaryTotal(state)?.[totalField]);

const selectStocksSectorTotal = createSelector(
    [
        (state: ClientRootState) => selectAssetsByAssetType(state, AssetTypeEnum.EQ),
        selectActiveAssetsAddInfo,
        (state, sectorID: number) => (sectorID)
    ],
    (assetsByAssetType, activeAssetsAddInfo, sectorID) => {
    return getAssetTypeSubCategTotalForID(
        sectorID, AssetTypeEnum.EQ, assetsByAssetType, activeAssetsAddInfo,
        getSectorIDFromAddInfo,
        getSectorNameFromAddInfo
    );
});

const selectActiveL4PercentOfTotal_Sector = (totalField: HoldingAssetAllocationDropdownValue, sectorID: number) => ((state: ClientRootState) => selectStocksSectorTotal(state, sectorID)?.[totalField]);


const selectL4TodayTable_BySID = createSelector(
    [selectActiveL2Assets, PortfolioSelectors.selectActiveAMIDSID], (activeL2Assets, {AMID, SID}) => {
        if (!activeL2Assets || !AMID) return [];

        return getSIDAssetsForAMID(activeL2Assets, AMID);
    } 
)

const selectL4HoldingsTable_BySID = createSelector(
    [selectL4TodayTable_BySID],
    (totals) => {
        return getHoldingTableDataFromList(totals);
    }
);

const selectActiveL4Refno = createSelector(
    [selectActiveL4PortfolioSummaryTotal],
    (activeL4PortfolioSummaryTotal) => {
        return activeL4PortfolioSummaryTotal?.Refno
    }
)

export const L4PortfolioSummarySelectors = {
    selectActiveL4PortfolioSummaryTotal,
    selectActiveL4TGainPct,
    selectActiveL4AssetAddInfo,
    selectActiveL4AssetName,
    selectActiveL4AssetStocksSector,
    selectActiveL4AssetStocksMarketCap,
    selectActiveL4PercentOfTotal_Portfolio, selectActiveL4PercentOfTotal_AssetType, selectActiveL4PercentOfTotal_Sector,
    selectL4TodayTable_BySID, selectL4HoldingsTable_BySID,
    selectActiveL4Refno
}

//#endregion