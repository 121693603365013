
export function ReportsIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="Group_150673" data-name="Group 150673" transform="translate(-35 -318)">
        <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(35 318)" fill="#fff" opacity="0" />
        <g id="Group_151375" data-name="Group 151375" transform="translate(39 322)">
          <path id="Path_198539" data-name="Path 198539" d="M5.625,1.5A1.875,1.875,0,0,0,3.75,3.375v17.25A1.876,1.876,0,0,0,5.625,22.5h12.75a1.876,1.876,0,0,0,1.875-1.875V12.75A3.75,3.75,0,0,0,16.5,9H14.625A1.875,1.875,0,0,1,12.75,7.125V5.25A3.75,3.75,0,0,0,9,1.5ZM7.5,15a.75.75,0,0,1,.75-.75h7.5a.75.75,0,0,1,0,1.5H8.25A.75.75,0,0,1,7.5,15Zm.75,2.25a.75.75,0,0,0,0,1.5H12a.75.75,0,0,0,0-1.5Z" fill={props.color} fillRule="evenodd" />
          <path id="Path_198540" data-name="Path 198540" d="M12.971,1.816A5.23,5.23,0,0,1,14.25,5.25V7.125a.375.375,0,0,0,.375.375H16.5a5.23,5.23,0,0,1,3.434,1.279,9.768,9.768,0,0,0-6.963-6.963Z" fill={props.color} />
        </g>
      </g>
    </svg>


  );
}

