export function GreenArrowUp(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="Group_149182" data-name="Group 149182" transform="translate(-124.58)">
        <g id="Rectangle_23691" data-name="Rectangle 23691" transform="translate(124.58)" fill="#0e8c43" stroke="#707070" strokeWidth="1" opacity="0">
          <rect width="16" height="16" stroke="none" />
          <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
        </g>
        <path id="c" d="M5,.514a1,1,0,0,1,1.748,0l4.873,8.769a1,1,0,0,1-.874,1.486H1A1,1,0,0,1,.127,9.283Z" transform="translate(126.579 2.556)" fill="#5f854c" />
      </g>
    </svg>
  );
}