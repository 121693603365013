import { NumberFormatPrefs, SharedRedux } from '@mprofit/shared';
import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { Store } from 'redux';

interface StoreProviderProps {
  store: Store;
  children: ReactNode;
}

const PDFStoreContext = createContext<Store | undefined>(undefined);

export const PDFStoreProvider: React.FC<StoreProviderProps> = ({ store, children }) => {
  return (
    <PDFStoreContext.Provider value={store}>
      {children}
    </PDFStoreContext.Provider>
  );
};

export const usePDFStore = (): Store => {
    const context = useContext(PDFStoreContext);
    if (!context) {
      throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
};

export const usePDFSelector = <TState, TSelected>(
    selector: (state: TState) => TSelected
  ): TSelected => {
    const store = usePDFStore();
    const [selectedState, setSelectedState] = useState<TSelected>(() => selector(store.getState() as TState));
  
    useEffect(() => {
      const unsubscribe = store.subscribe(() => {
        const newState = store.getState() as TState;
        setSelectedState(selector(newState));
      });
      return unsubscribe;
    }, [store, selector]);
  
    return selectedState;
};

export const usePDFNumberFormatPrefs = (): NumberFormatPrefs => {
  var userProfile = usePDFSelector(SharedRedux.Dashboard.Selectors.selectUserProfile);
  return {
    ShowDecimals: userProfile.ShowDecimals,
    ShowInLakhsMillions: userProfile.ShowInLakhsMillions,
    Separator: userProfile.Separator
  }
};