import React from 'react';
import { Box, IconButton, Snackbar, SnackbarCloseReason, SnackbarContent, SnackbarProps } from '@mui/material';
import { CloseIcon } from '../../../assets/icons/CloseIcon';

interface ISnackbarProps extends SnackbarProps {
  closeSnackbar: React.MouseEventHandler<HTMLButtonElement> | undefined
  onClose: ((event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void) | undefined
}

export default function SnackbarComponent({ closeSnackbar, onClose, open, autoHideDuration, message, sx, anchorOrigin }: ISnackbarProps) {

  const action = (
    <Box sx={{ position: 'absolute', right: '10px', top: '0' }}>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      sx={sx}
      open={open}
      autoHideDuration={autoHideDuration || 400000}
      onClose={onClose}
    >
      <SnackbarContent
        style={{ backgroundColor: '#FFFFFF' }}
        action={action}
        message={message}
      />
    </Snackbar>
  )
}
