import { SharedConstants, FormatAmountUsingPrefsHelper } from "@mprofit/shared";
import { Box, Divider } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import styles from "./index.module.css";
import CustomTypography from "../customTypography";
import BottomLegends from "./bottomLegends";
import { useNumberFormatPrefs } from "../../hooks/userPreferencesHooks";
import { DownloadPDF } from "../../download-pdf";
import { CompleteChartPage, PdfCashFlowChart } from "@mprofit-soft/pdf";
import Lottie from "react-lottie";
import * as animationData from '../../../assets/animation/assetWisePerformanceAnimation.json'
import { DefaultTableIcon } from "packages/web/src/assets/icons/DefaultTableIcon";
import { CashflowChartIn, CashflowChartOut } from "packages/shared/src/constants";
import { CashflowChartPDFDownloadButton } from "./pdf";

type BarOrderItem = {
  key: string;
  color: string;
  innerLabelColor?: string;
};

type BarOrder = BarOrderItem[];

const CASH_IN_BAR_ORDER: BarOrder = [
  {
    key: "Cash In",
    color: "#E2DA90"
  },
  {
    key: "Opening Cash",
    color: "#5F939A",
    innerLabelColor: "#fff"
  },
  {
    key: "Opening Investment Value",
    color: "#F5CFA0"
  }
];

const CASH_OUT_BAR_ORDER: BarOrder = [
  {
    key: "Cash Out",
    color: "#485D3E",
    innerLabelColor: "#fff"
  },
  {
    key: "Income Payout",
    color: "#FFB598"
  },
  {
    key: "Closing Cash",
    color: "#B1DBFF"
  },
  {
    key: "Closing Investment Value",
    color: "#ACC18A"
  }
]


const getFilteredKeys = (barOrder: BarOrderItem[], dataPoint: CashflowChartIn | CashflowChartOut): BarOrderItem[] => {
  return barOrder.filter(item => (dataPoint as any)[item.key] > 0);
};


const CustomLegend = (customLegendProps: {
  payload?: SharedConstants.PieChartData[];
  onItemClick?: ((item: SharedConstants.PieChartData) => void) | undefined;
}) => {
  const { payload, onItemClick } = customLegendProps;

  return (
    <BottomLegends
      data={payload || []}
      onItemClick={onItemClick}
    />
  );
};

const TopLabel = (e: any) => {
  const numberFormatPrefs = useNumberFormatPrefs();
  return (
    <g>
      <foreignObject
        x={e.x - 8}
        y={e.y - 28}
        width={"60px"}
        height={"40px"}
      >
        {
          e.value > 0 ? <span className={styles.topLabel}>{FormatAmountUsingPrefsHelper(numberFormatPrefs, e.value).FormattedValue}</span> : null
        }
      </foreignObject>
    </g>
  );
};


export default function CashflowChart({
  data,
  onItemClick,
  showOpeningValuationAndCashBal,
  isPerformanceLoading
}: {
  data: never[] | SharedConstants.CashflowChartData;
  onItemClick?: any;
  showOpeningValuationAndCashBal: boolean;
  isPerformanceLoading: boolean;
}) {
  const numberFormatPrefs = useNumberFormatPrefs();

  var maxValue = 0;
  if (data && data[0]) {
    var cashInData = data[0];
    for (var key of Object.keys(cashInData)) {
      if (key !== 'name') {
        var value = (cashInData as any)[key];
        maxValue = Math.max(maxValue, value || 0);
      }
    }

    var cashOutData = data[1];
    for (var key of Object.keys(cashOutData)) {
      if (key !== 'name') {
        var value = (cashOutData as any)[key];
        maxValue = Math.max(maxValue, value || 0);
      }
    }
  }

  const labelListFormatter = (tick: number) => {
    if (tick <= 0 || (tick * 10 < maxValue)) {
      return null;
    }

    return `${FormatAmountUsingPrefsHelper(numberFormatPrefs, tick).FormattedValue}`
  }

  const filteredCashInItems = data && data.length > 0 ? getFilteredKeys(CASH_IN_BAR_ORDER, data[0]) : [];
  const filteredCashOutItems = data && data.length > 1 ? getFilteredKeys(CASH_OUT_BAR_ORDER, data[1]) : [];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        width: "100%",
        height: "100%",
        border: "1px solid #EBECF2",
        borderRadius: "8px",
        position: 'relative'
      }}
    >
      <Box sx={{ padding: "32px", display:"flex", alignItems:"center", gap: '10px' }}>
        <CustomTypography variant="Medium20">
          Cashflow Snapshot
        </CustomTypography>
        {
          !isPerformanceLoading &&
          <CashflowChartPDFDownloadButton/>
        }
      </Box>
      <Box>
        <Divider style={{ borderColor: "#EBECF2", margin: "0px" }} />
      </Box>

      {
        isPerformanceLoading ?
          <Box sx={{ position: 'absolute', top: 72, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, .1)", zIndex: 1, backdropFilter: "blur(3px)" }} >
            <Box sx={{ width: '256px', height: '256px', position: 'relative', top: '50%', left: "50%", transform: 'translate(-50% , -50%)' }} >
              <Lottie options={defaultOptions} height={256} width={256} />
              <Box sx={{
                position: 'absolute',
                top: '80px',
                left: '-10px',

              }}>
                <DefaultTableIcon />
              </Box>
            </Box>
          </Box > : null
      }

      <ResponsiveContainer
        width="100%"
        height={600}
      >
        <BarChart
          width={400}
          // height={500}
          // style={{ paddingLeft: '32px' }}
          data={data}
          margin={{
            top: 32,
            right: 32,
            left: 32,
            bottom: 32,
          }}
        >


          <CartesianGrid
            stroke="#EBECF2"
            vertical={false}
            opacity={"0.6"}
          />
          <XAxis
            dataKey="name"
            axisLine={{ stroke: "#EBECF2" }}
            tickLine={false}
          />
          <YAxis
            axisLine={{ stroke: "#EBECF2" }}
            tickLine={false}
            fontFamily="Rubik"
            tickFormatter={(tick) => { return `${FormatAmountUsingPrefsHelper(numberFormatPrefs, tick, 2).FormattedValue}` }}
          />
          <Legend content={<CustomLegend onItemClick={onItemClick} />} wrapperStyle={{ left: 0, right: 0, width: '100%' }} />

          {
            filteredCashInItems.map((item, idx) => {
              if ((item.key === "Opening Investment Value" || item.key === "Opening Cash") && !showOpeningValuationAndCashBal) return null;

              return (
                <Bar
                  key={item.key}
                  dataKey={item.key}
                  stackId="a"
                  fill={item.color}
                  barSize={45}
                  radius={(idx === filteredCashInItems.length - 1) ? [8, 8, 0, 0] : [0, 0, 0, 0]}
                  label={(idx === filteredCashInItems.length - 1) ? e => TopLabel(e) : false}
                >
                  <LabelList
                    dataKey={item.key}
                    fontFamily="Rubik"
                    fill={item.innerLabelColor || '#64677A'}
                    position="inside"
                    fontSize={10}
                    formatter={labelListFormatter}
                  />
                </Bar>
              );
            })
          }

          {filteredCashOutItems.map((item, idx) => {
            return (
              <Bar
                key={item.key}
                dataKey={item.key}
                stackId="a"
                fill={item.color}
                barSize={45}
                radius={(idx === filteredCashOutItems.length - 1) ? [8, 8, 0, 0] : [0, 0, 0, 0]}
                label={(idx === filteredCashOutItems.length - 1) ? e => TopLabel(e) : false}
              >
                <LabelList
                  dataKey={item.key}
                  fontFamily="Rubik"
                  fill={item.innerLabelColor || '#64677A'}
                  position="inside"
                  fontSize={10}
                  formatter={labelListFormatter}
                />
              </Bar>
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
