export function CashflowTable_CashInIcon(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_228673" data-name="Group 228673" transform="translate(0 -0.5)">
                <rect id="Rectangle_49047" data-name="Rectangle 49047" width="48" height="48" rx="4" transform="translate(0 0.5)" fill="#f5f5f6"/>
                <g id="Group_228678" data-name="Group 228678" transform="translate(10.962 15.083)">
                <path id="Path_272289" data-name="Path 272289" d="M10.346,11.795H8.9A2.906,2.906,0,0,1,6,8.9H6A2.906,2.906,0,0,1,8.9,6H29.178a2.906,2.906,0,0,1,2.9,2.9h0a2.906,2.906,0,0,1-2.9,2.9H27.73" transform="translate(-6 -6)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <path id="Path_272290" data-name="Path 272290" d="M28.833,10V25.935H11.449V10M10,10H30.281" transform="translate(-7.103 -7.103)" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <g id="Group_228678-2" data-name="Group 228678" transform="translate(9.859 5.795)">
                    <path id="Path_272292" data-name="Path 272292" d="M25.179,36v9.535m3.179-6.357L25.179,36,22,39.178" transform="translate(-22 -36)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                </g>
                </g>
            </g>
        </svg>
    );
}
