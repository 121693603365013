
export function TableIconDown(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8.86" height="12.512" viewBox="0 0 8.86 12.512">
            <g id="Group_222042" data-name="Group 222042" transform="translate(1.413 1)">
                <line id="Line_23" data-name="Line 23" y1="10.126" transform="translate(3.016)" fill="none" stroke="#c94c40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line id="Line_24" data-name="Line 24" x1="3.001" y2="3" transform="translate(3.031 7.098)" fill="none" stroke="#c94c40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line id="Line_25" data-name="Line 25" x2="3.001" y2="3" transform="translate(0.001 7.098)" fill="none" stroke="#c94c40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
        </svg>
    );
}