import React from 'react'

export default function MidCap(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20.001" viewBox="0 0 20 20.001">
            <g id="Group_222589" data-name="Group 222589" transform="translate(9335 0.001)">
                <rect id="Rectangle_34469" data-name="Rectangle 34469" width="20" height="20" transform="translate(-9335 -0.001)" fill="#12131a" opacity="0" />
                <g id="Group_222316" data-name="Group 222316" transform="translate(-9335 0)">
                    <path id="Path_237231" data-name="Path 237231" d="M0,0H20V20H0Z" fill="none" />
                    <path id="Path_237232" data-name="Path 237232" d="M10.5,9a5,5,0,0,0-5-5H3V5.667a5,5,0,0,0,5,5h2.5" transform="translate(-0.5 -0.667)" fill="none" stroke="#12131a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                    <path id="Path_237233" data-name="Path 237233" d="M12,13a5,5,0,0,1,5-5h2.5v.833a5,5,0,0,1-5,5H12" transform="translate(-2 -1.334)" fill="none" stroke="#12131a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                    <line id="Line_2161" data-name="Line 2161" y1="9.394" transform="translate(10 8.5)" fill="none" stroke="#12131a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                </g>
            </g>
        </svg>

    )
}
