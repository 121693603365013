import { Box } from '@mui/material'
import CustomTypography from '../../../shared/customTypography';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonWithTextAndIcon from '../../../shared/button-with-label-and-icon';
import CalanderTodayIcon from 'packages/web/src/assets/icons/calanderTodayIcon';
import { EyeVisiblityIcon } from 'packages/web/src/assets/icons/eyeVisiblityIcon';

export default function ReadyToDownloadCard() {
  return (
    <Box
      sx={style.container}>
      <Box>
        <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A' } }}>Created on 17 Nov 2022</CustomTypography>
        <CustomTypography padding="16px 0px" variant='Regular24'>Spectrum - Capital Gains ITR Format</CustomTypography>
        <Box sx={style.iconContainer}>
          <CalanderTodayIcon color="#64677a" />
          <CustomTypography paddingLeft="8px" variant='Regular16'>14 Sep 2022</CustomTypography>
        </Box>
      </Box>
      <Box>
        <CustomTypography variant='Regular18'>Chandraprakash Parthasarathy</CustomTypography>
        <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A', padding: '8px 0px 16px 0px' } }}>Parthasarathy family</CustomTypography>
        <ButtonWithTextAndIcon startIcon={<EyeVisiblityIcon />} buttonlabel="View report" />
      </Box>
    </Box>
  )
}
const style = {
  container: {
    boxSizing: 'border-box',
    padding: "24px",
    flex: '0 0 360px',
    minHeight: '352px',
    borderRadius: '8px',
    background: '#FFFFFF',
    border: '1px solid #EBECF2',
    margin: '0px 24px 24px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    background: '#EAF4DF',
    padding: '4px 16px 4px 12px',
    borderRadius: '16px',
    width: 'fit-content'
  }
}