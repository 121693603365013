
export function TransactionIcon(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
            <g id="Group_152993" data-name="Group 152993" transform="translate(-920 -138)">
                <rect id="Rectangle_24810" data-name="Rectangle 24810" width="64" height="64" transform="translate(920 138)" fill="#fff" opacity="0" />
                <g id="wallet-transaction" transform="translate(489.224 -165.31)">
                    <path id="Path_206230" data-name="Path 206230" d="M463.226,311.286,472,354.5a2.467,2.467,0,0,1-1.927,2.908l-24.565,4.988a2.467,2.467,0,0,1-2.908-1.927l-8.774-43.212a2.467,2.467,0,0,1,1.927-2.908l24.565-4.988A2.469,2.469,0,0,1,463.226,311.286Z" transform="translate(-1.804 -1.802)" fill="#fff" />
                    <path id="Path_206230_-_Outline" data-name="Path 206230 - Outline" d="M459.006,307.31a2.664,2.664,0,0,1,2.61,2.135l8.774,43.212a2.662,2.662,0,0,1-2.082,3.142l-24.565,4.988a2.678,2.678,0,0,1-.533.054A2.662,2.662,0,0,1,440.6,358.7l-8.774-43.212a2.663,2.663,0,0,1,2.081-3.142l24.565-4.988A2.681,2.681,0,0,1,459.006,307.31Zm-15.795,53.134a2.286,2.286,0,0,0,.454-.046l24.565-4.988A2.272,2.272,0,0,0,470,352.736l-8.774-43.212a2.268,2.268,0,0,0-2.675-1.772l-24.565,4.988a2.272,2.272,0,0,0-1.772,2.675l8.774,43.212A2.275,2.275,0,0,0,443.212,360.444Z" transform="translate(0)" />
                    <path id="Ellipse_2066" data-name="Ellipse 2066" d="M.855-2a2.847,2.847,0,0,1,2.019.836A2.847,2.847,0,0,1,3.71.855a2.847,2.847,0,0,1-.836,2.019A2.847,2.847,0,0,1,.855,3.71a2.847,2.847,0,0,1-2.019-.836A2.847,2.847,0,0,1-2,.855a2.847,2.847,0,0,1,.836-2.019A2.847,2.847,0,0,1,.855-2Zm0,5.314A2.459,2.459,0,1,0-1.6.855,2.462,2.462,0,0,0,.855,3.314Z" transform="translate(457.859 318.12)" fill="#101010" />
                    <path id="Ellipse_2067" data-name="Ellipse 2067" d="M.855-2a2.847,2.847,0,0,1,2.019.836A2.847,2.847,0,0,1,3.71.855a2.847,2.847,0,0,1-.836,2.019A2.847,2.847,0,0,1,.855,3.71a2.847,2.847,0,0,1-2.019-.836A2.847,2.847,0,0,1-2,.855a2.847,2.847,0,0,1,.836-2.019A2.847,2.847,0,0,1,.855-2Zm0,5.314A2.459,2.459,0,1,0-1.6.855,2.462,2.462,0,0,0,.855,3.314Z" transform="translate(457.141 314.155)" fill="#101010" />
                    <rect id="Rectangle_25207" data-name="Rectangle 25207" width="49.028" height="3.171" transform="translate(458.844 357.519) rotate(-101.48)" fill="#101010" />
                    <path id="Path_206231" data-name="Path 206231" d="M546.589,382.77l1.312,6.458a.647.647,0,0,1-.506.764l-2.258.459a.647.647,0,0,1-.764-.506l-1.312-6.459a.647.647,0,0,1,.506-.764l2.258-.459A.649.649,0,0,1,546.589,382.77Z" transform="translate(-100.266 -67.528)" fill="#485d3e" />
                    <path id="Path_206232" data-name="Path 206232" d="M531.485,669.625a.308.308,0,0,1,.3.247l2.719,13.392a.308.308,0,0,1-.241.362l-1.128.23a.3.3,0,0,1-.06.006.309.309,0,0,1-.3-.247h0l-2.719-13.393a.308.308,0,0,1,.241-.362l1.127-.229A.3.3,0,0,1,531.485,669.625Zm2.615,13.631-2.684-13.22-.956.194,2.684,13.22Zm1.232-8.466a.309.309,0,0,1,.3.247l1.559,7.68a.308.308,0,0,1-.241.362l-1.128.23a.319.319,0,0,1-.06.006.309.309,0,0,1-.3-.247l-1.559-7.681a.308.308,0,0,1,.241-.362l1.128-.229A.309.309,0,0,1,535.331,674.791Zm1.456,7.919-1.524-7.508-.956.194,1.524,7.508Z" transform="translate(-88.552 -326.475)" fill="#101010" />
                    <path id="Path_206237" data-name="Path 206237" d="M3.918,4.021A10.614,10.614,0,0,1,2.3,3.937,2.458,2.458,0,0,1,.875,3.286,2.667,2.667,0,0,1,.216,2.054,8,8,0,0,1,0,0H.4c0,1.534.241,2.489.759,3.006.625.624,1.689.622,3.037.619H31.537v.4H3.918Z" transform="translate(438.985 359.363) rotate(-11)" />
                    <g id="Group_152832" data-name="Group 152832" transform="translate(465.33 308.128)">
                        <rect id="Rectangle_25208" data-name="Rectangle 25208" width="3.581" height="12.418" transform="translate(17.334 1.8) rotate(90)" fill="#5f854c" />
                        <path id="Path_206238" data-name="Path 206238" d="M203.63,913.011l6.209,3.592V909.42Z" transform="translate(-203.63 -909.42)" fill="#5f854c" />
                    </g>
                    <g id="Group_152833" data-name="Group 152833" transform="translate(476.83 315.754)">
                        <rect id="Rectangle_25209" data-name="Rectangle 25209" width="3.581" height="12.418" transform="translate(0 5.382) rotate(-90)" fill="#101010" />
                        <path id="Path_206239" data-name="Path 206239" d="M324.919,1025.671l-6.209-3.591v7.183Z" transform="translate(-307.585 -1022.08)" fill="#101010" />
                    </g>
                    <g id="Group_154350" data-name="Group 154350" transform="translate(458.546 321.724)">
                        <ellipse id="Ellipse_2316" data-name="Ellipse 2316" cx="12.693" cy="12.063" rx="12.693" ry="12.063" transform="translate(0 0)" fill="#acc18a" />
                        <path id="Path_235205" data-name="Path 235205" d="M236.5,119.8a10.933,10.933,0,0,1,11.08,10.126c.006-.149.01-.3.009-.45a11.036,11.036,0,0,0-22.043.151c0,.151.007.3.015.45A10.933,10.933,0,0,1,236.5,119.8Z" transform="translate(-223.448 -118.247)" fill="#101010" />
                        <path id="Path_235206" data-name="Path 235206" d="M236.637,152.956a10.786,10.786,0,0,1-11.078-9.949,4.162,4.162,0,0,0-.009.45c.422,6.125,6.009,9.755,11.089,9.72a10.889,10.889,0,0,0,10.954-9.872,3.578,3.578,0,0,0-.015-.45A10.787,10.787,0,0,1,236.637,152.956Z" transform="translate(-223.448 -131.624)" fill="#101010" />
                        <path id="Path_235207" data-name="Path 235207" d="M235.217,153.96a11.875,11.875,0,0,1-12.2-10.954c-.006.164-.011.329-.01.5.044,6.369,5.512,11.5,12.214,11.449s12.1-5.247,12.055-11.616c0-.166-.008-.331-.017-.495A11.876,11.876,0,0,1,235.217,153.96Z" transform="translate(-222.021 -131.615)" fill="#101010" />
                        <path id="Path_235215" data-name="Path 235215" d="M4.793,11.45a.507.507,0,0,1-.27-.065.881.881,0,0,1-.2-.18L.376,6.608A2.088,2.088,0,0,1,.229,6.42.48.48,0,0,1,.18,6.167v-.18a.354.354,0,0,1,.1-.262.354.354,0,0,1,.262-.1h.981a4.5,4.5,0,0,0,1.889-.311,1.744,1.744,0,0,0,.883-.834A2.822,2.822,0,0,0,4.531,3.3a2.822,2.822,0,0,0-.237-1.178,1.757,1.757,0,0,0-.851-.834A3.889,3.889,0,0,0,1.718.981H.36A.354.354,0,0,1,.1.883.354.354,0,0,1,0,.622V.36A.354.354,0,0,1,.1.1.354.354,0,0,1,.36,0H6.674a.354.354,0,0,1,.262.1.354.354,0,0,1,.1.262V.622a.354.354,0,0,1-.1.262.354.354,0,0,1-.262.1H4.286L3.5.523a2.238,2.238,0,0,1,1.619.99A3.5,3.5,0,0,1,5.561,3.3a3.6,3.6,0,0,1-.36,1.6A2.663,2.663,0,0,1,4.016,6.085a4.677,4.677,0,0,1-2.2.442H1.407L1.292,6.1l4.171,4.874a.33.33,0,0,1,.115.229.2.2,0,0,1-.1.188.446.446,0,0,1-.229.057ZM.36,3.6A.354.354,0,0,1,.1,3.5.354.354,0,0,1,0,3.239V2.977a.354.354,0,0,1,.1-.262.354.354,0,0,1,.262-.1H6.674a.338.338,0,0,1,.36.36v.262a.338.338,0,0,1-.36.36Z" transform="translate(9.603 6.338)" fill="#131100" />
                    </g>
                </g>
            </g>
        </svg>

    );
}