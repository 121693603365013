import Rubik300 from './static/Rubik-Light.ttf';
import Rubik400 from './static/Rubik-Regular.ttf';
import Rubik500 from './static/Rubik-Medium.ttf';
import Rubik600 from './static/Rubik-SemiBold.ttf';
import Rubik700 from './static/Rubik-Bold.ttf';
import Rubik800 from './static/Rubik-ExtraBold.ttf';
import Rubik900 from './static/Rubik-Black.ttf';

export const RubikFonts_ReactPDF = [
    {
      src: Rubik300,
      fontWeight: 300,
    },
    {
      src: Rubik400,
      fontWeight: 400,
    },
    {
      src: Rubik500,
      fontWeight: 500,
    },
    {
      src: Rubik600,
      fontWeight: 600,
    },
    {
      src: Rubik700,
      fontWeight: 700,
    },
    {
      src: Rubik800,
      fontWeight: 800,
    },
    {
      src: Rubik900,
      fontWeight: 900,
    },
]

export default RubikFonts_ReactPDF;