export function ULIP(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226550" data-name="Group 226550" transform="translate(-17925 6220.79)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(35 318.21)" fill="#fff" opacity="0" />
          <g id="Group_150593" data-name="Group 150593" transform="translate(35 322.421)">
            <g id="Rectangle_24192" data-name="Rectangle 24192" transform="translate(0 -0.211)" fill="none" stroke="#fff" stroke-width="1">
              <rect width="20" height="12" rx="4" stroke="none" />
              <rect x="0.5" y="0.5" width="19" height="11" rx="3.5" fill="none" />
            </g>
            <path id="Name" d="M-8.774-3.019a2.712,2.712,0,0,1-1.144-.224,1.628,1.628,0,0,1-.75-.69,2.393,2.393,0,0,1-.265-1.194V-8.211a.185.185,0,0,1,.053-.136.175.175,0,0,1,.129-.053h.69a.172.172,0,0,1,.133.053.193.193,0,0,1,.049.136v3.085a1.264,1.264,0,0,0,.3.921,1.088,1.088,0,0,0,.811.3,1.075,1.075,0,0,0,.807-.3,1.275,1.275,0,0,0,.292-.921V-8.211a.185.185,0,0,1,.053-.136A.185.185,0,0,1-7.485-8.4H-6.8a.172.172,0,0,1,.133.053.193.193,0,0,1,.049.136v3.085a2.421,2.421,0,0,1-.261,1.194,1.61,1.61,0,0,1-.743.69A2.706,2.706,0,0,1-8.774-3.019Zm3.525-.076a.175.175,0,0,1-.129-.053.175.175,0,0,1-.053-.129V-8.218a.175.175,0,0,1,.053-.129A.175.175,0,0,1-5.249-8.4h.7a.175.175,0,0,1,.129.053.175.175,0,0,1,.053.129V-4h2.4a.185.185,0,0,1,.136.053.185.185,0,0,1,.053.136v.531a.175.175,0,0,1-.053.129.185.185,0,0,1-.136.053Zm4.45,0a.175.175,0,0,1-.129-.053.175.175,0,0,1-.053-.129V-8.211a.185.185,0,0,1,.053-.136A.175.175,0,0,1-.8-8.4H1.232a2.635,2.635,0,0,1,1.031.186,1.47,1.47,0,0,1,.682.561,1.691,1.691,0,0,1,.243.936,1.667,1.667,0,0,1-.243.936,1.463,1.463,0,0,1-.682.549,2.683,2.683,0,0,1-1.031.182H.1v1.773a.182.182,0,0,1-.049.129.172.172,0,0,1-.133.053Zm.887-2.8H1.194A.985.985,0,0,0,1.869-6.1.783.783,0,0,0,2.1-6.718a.849.849,0,0,0-.22-.61.913.913,0,0,0-.69-.231H.088Z" transform="translate(14.134 11.305)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
  );
}
