import { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from 'react'
import { Box } from "@mui/material";
import styles from "./index.module.css";
import CustomTypography from "../../customTypography";
import { DashboardItemContainer } from "../../../portfolio/shared";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { FormatAmountUsingPrefsHelper, SharedConstants, formatDateForReading, formatDecimal, formatDecimalWithRupeeSymbol, formatPercent } from "@mprofit/shared";
import BottomLegends from './BottomLegends';
import Big from 'big.js';
import { useNumberFormatPrefs } from '../../../hooks/userPreferencesHooks';

interface PieChartWithBottomLegendProps {
  data: SharedConstants.PieChartData[];
  totalValue: string | undefined;
  onItemClick?: (item: SharedConstants.PieChartData) => void;
  showHeading?: boolean;
}

interface CustomLabel {
  viewBox: any;
  labelText: string;
  value: string;
}

const PieChartWithBottomLegend = ({ data, totalValue, onItemClick, showHeading = true }: PieChartWithBottomLegendProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const chartRef = useRef<any>();

  const handleMouseLeaveFromPie = () => {
    setActiveIndex(null)
    if (!chartRef?.current) {
      return;
    }
    chartRef.current.setState({
      isTooltipActive: false,
      tooltipPosition: undefined
    });
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box className={styles.tooltip_outer} sx={{
          // '&:before': {
          //   content: '""',
          //   display: 'block',
          //   position: 'absolute',
          //   top: 0,
          //   right: 6,
          //   width: 6,
          //   height: 6,
          //   bgcolor: 'background.paper',
          //   transform: 'translateY(-50%) rotate(45deg)',
          //   zIndex: 0,
          // },
        }}>
          <Box className={styles.tooltip_container} sx={{ borderLeft: `3px solid ${payload[0].payload.fill}` }}>
            <Box className={styles.tooltip_wrapper}>
              <Box className={styles.tooltip_currentPrice}>{formatDecimal(payload[0].payload.numValueBig, 2, 'IN')}</Box>
              <p className={styles.tooltip_percentageCont}>{formatPercent(payload[0].value, 1, 'IN')}</p>
            </Box>
            <Box className={styles.tooltip_loanText}>{payload[0].name}</Box>
          </Box>
        </Box>
      );
    }
    return null
  };

  const CustomLabel = ({ viewBox, labelText, value }: CustomLabel) => {
    const { cx, cy } = viewBox;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fontFamily="Rubik"
          fill="#12131A"
          fontSize="inherit"
          fontWeight="600"
        >
          {value}
        </text>
        <text
          x={cx}
          y={cy + 20}
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fill="#64677A"
        >
          {" "}
          {labelText}
        </text>
      </g>
    );
  };

  var isEmpty = false;

  if (totalValue && Big(totalValue).eq(0)) {
    isEmpty = true;
    data = [
      {
        name: '',
        value: 1,
        numValueBig: Big(1),
        pctValueBig: Big(1),
        IDs: {},
        color: '#f5f5f6'
      }
    ]
  }

  const numberFormatPrefs = useNumberFormatPrefs();
  const { FormattedValue, IsNumberInLakhsMillions } = FormatAmountUsingPrefsHelper(numberFormatPrefs, totalValue, 2);

  return (
    <DashboardItemContainer className='apptour-today-assetallocation'>
      <Box className={styles.todayPerfomanceContainer} sx={{ maxHeight: { md: '600px', lg1: '660px' } }}>
        {
          showHeading ?
            <Box className={styles.headingContainer}>
              <Box className={styles.headingSubContainer}>
                <Box>
                  <CustomTypography variant="Medium20" >Asset Allocation</CustomTypography>
                  <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }}>{`as on ${formatDateForReading(new Date())}`}</CustomTypography>
                </Box>
              </Box>
            </Box> : null
        }
        <Box className={styles.infoContainer} >
          <Box sx={{ width: '100%', height: { xs: "400px", md: "350px", lg: "400px", xl: '585px' } }} onMouseLeave={handleMouseLeaveFromPie}>
            <ResponsiveContainer height='100%' width='100%'>
              <PieChart ref={chartRef} onMouseLeave={handleMouseLeaveFromPie}>
                {
                  !isEmpty &&
                  <Tooltip
                    content={<CustomTooltip />}
                    wrapperStyle={{ outline: "none", borderRadius: "10px", zIndex: "1" }}
                  />
                }
                <Pie
                  data={data}
                  innerRadius={"55%"}
                  fill="#8884d8"
                  dataKey="value"
                  cursor={onItemClick && !isEmpty ? 'pointer' : 'default'}
                  stroke="none"
                  onMouseEnter={(data, index) => setActiveIndex(index)}
                  onMouseLeave={handleMouseLeaveFromPie}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.color}
                      fillOpacity={!isEmpty && activeIndex === index ? 0.5 : 1}
                      onClick={onItemClick ? () => onItemClick(entry) : undefined}
                    />
                  ))}
                  <Label
                    content={<CustomLabel viewBox labelText="Current value" value={FormattedValue} />}
                    position="center"
                  />
                </Pie>
                {/* <Tooltip /> */}
                {
                  !isEmpty &&
                  <Legend
                    wrapperStyle={{ width: '100%' }}
                    content={<CustomLegend chartRef={chartRef} onItemClick={onItemClick} setActiveIndex={setActiveIndex} />}
                    layout="vertical"
                    verticalAlign="bottom"
                    align="center"
                    iconType="circle"
                    iconSize={10}
                  />
                }
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Box>
    </DashboardItemContainer>
  );
};

const CustomLegend = (customLegendProps: { payload?: SharedConstants.PieChartData[], chartRef: MutableRefObject<any>, setActiveIndex: Dispatch<SetStateAction<number | null>>, onItemClick: ((item: SharedConstants.PieChartData) => void) | undefined }) => {
  const { payload, chartRef, setActiveIndex, onItemClick } = customLegendProps;

  return (
    <BottomLegends data={payload || []} chartRef={chartRef} setActiveIndex={setActiveIndex} onItemClick={onItemClick} />
  );
};

export default PieChartWithBottomLegend;
