import { SharedConstants, SharedRedux } from "@mprofit/shared";
import PerformanceTwoColumnChart from "../../../shared/performance-two-column-chart";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { Dispatch } from "react";

export const PerformanceFYChart = (props: {MPrUrlParams: MPrUrlParams, OnRefreshXIRRFYWise: (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean) => void, OnRefreshXIRRYearWise: (urlParams: MPrUrlParams, dispatch: Dispatch<any>) => void, ActiveMode: SharedConstants.AdvancedPerformanceChartMode, isLoading: boolean, disabled?: boolean}) => {
  if (props.ActiveMode == SharedConstants.AdvancedPerformanceChartMode.YearWise) {
    return <PerformanceFYChart_YearWise MPrUrlParams={props.MPrUrlParams} OnRefresh={props.OnRefreshXIRRYearWise} isLoading={props.isLoading} disabled={props.disabled}/>
  } else {
    return <PerformanceFYChart_FYWise MPrUrlParams={props.MPrUrlParams} OnRefresh={props.OnRefreshXIRRFYWise} isLoading={props.isLoading} disabled={props.disabled}/>
  }
}  

export const PerformanceFYChart_FYWise = (props: {MPrUrlParams: MPrUrlParams, OnRefresh: (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean) => void, isLoading: boolean, disabled?: boolean}) => {
  const chartData = useAppSelector(SharedRedux.Performance.Selectors.selectXIRRFYWiseWithIndices);
  
  const dispatch = useAppDispatch();

  const onRefresh = () => {
    props.OnRefresh(props.MPrUrlParams, dispatch, true)
  }

  return (
    <PerformanceTwoColumnChart data={chartData} isLoading={props.isLoading} onRefresh={onRefresh} disabled={props.disabled}/>
  )
} 

export const PerformanceFYChart_YearWise = (props: {MPrUrlParams: MPrUrlParams, OnRefresh: (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean) => void, isLoading: boolean, disabled?: boolean}) => {
  const chartData = useAppSelector(SharedRedux.Performance.Selectors.selectXIRRYearWiseWithIndices);
  
  const dispatch = useAppDispatch();

  const onRefresh = () => {
    props.OnRefresh(props.MPrUrlParams, dispatch, true)
  }

  return (
    <PerformanceTwoColumnChart data={chartData} isLoading={props.isLoading} onRefresh={onRefresh} disabled={props.disabled}/>
  )
}