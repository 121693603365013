export function ArrowNarrowUpIcon(props: any) {
  return (
    <svg{...props} id="ic-arrow-narrow-up" xmlns="http://www.w3.org/2000/svg" width="29.971" height="29.971" viewBox="0 0 29.971 29.971">
      <path id="Path_397" data-name="Path 397" d="M0,0H29.971V29.971H0Z" fill="none" />
      <g id="Group_152070" data-name="Group 152070">
        <line id="Line_23" data-name="Line 23" y2="18.968" transform="translate(15.104 5.384)" fill="none" stroke="#5f854c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        <line id="Line_24" data-name="Line 24" x1="5.62" y1="5.62" transform="translate(15.133 5.437)" fill="none" stroke="#5f854c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        <line id="Line_25" data-name="Line 25" y1="5.62" x2="5.62" transform="translate(9.457 5.437)" fill="none" stroke="#5f854c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
      </g>
    </svg>
  );
}