export function ClosingCashIcon_AllToDate(props: any) {
    return (
        <svg id="Group_149319" data-name="Group 149319" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
            <rect id="Rectangle_23692" data-name="Rectangle 23692" width="56" height="56" rx="8" fill="#f5f5f6"/>
            <g id="_20a803a93fe3bd982fcf02d6c47cfa96" data-name="20a803a93fe3bd982fcf02d6c47cfa96" transform="translate(13.797 11.184)">
                <path id="Path_198519" data-name="Path 198519" d="M31.221,28.888a1.479,1.479,0,0,0-2.1,0l-6.126,6.126H14.943V33.847h6.71a1.459,1.459,0,1,0,0-2.917H17.8l-.175-.233a4.522,4.522,0,0,0-7.235,0l-.175.233H9.4v7.06H23.636a1.677,1.677,0,0,0,1.05-.408l6.535-6.535A1.676,1.676,0,0,0,31.221,28.888Z" transform="translate(-3.707 -12.259)"/>
                <path id="Path_198520" data-name="Path 198520" d="M.5,30.1V40.252H4.526V30.1Z" transform="translate(0 -12.946)"/>
                <path id="Path_272299" data-name="Path 272299" d="M17.14,11H3.566A.566.566,0,0,0,3,11.566v7.918a.566.566,0,0,0,.566.566H17.14a.566.566,0,0,0,.566-.566V11.566A.566.566,0,0,0,17.14,11ZM5.664,13.664a.594.594,0,0,1-.4.164.566.566,0,0,1-.215-.045.65.65,0,0,1-.187-.119.594.594,0,0,1-.164-.4.617.617,0,0,1,.164-.4.65.65,0,0,1,.187-.119.569.569,0,0,1,.781.52A.594.594,0,0,1,5.664,13.664Zm4.689,4.123a2.262,2.262,0,1,1,2.262-2.262A2.262,2.262,0,0,1,10.353,17.787Zm5.492.4a.566.566,0,1,1,.164-.4.594.594,0,0,1-.164.4Z" transform="translate(8.378 -7.307)" fill="#12131a"/>
            </g>
        </svg>
    );
}

export function ClosingCashIcon_Periodwise(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_228673" data-name="Group 228673" transform="translate(0 -0.5)">
                <rect id="Rectangle_49047" data-name="Rectangle 49047" width="48" height="48" rx="4" transform="translate(0 0.5)" fill="#f5f5f6"/>
                <path id="_8988853dc2c7377256d1b11dba51ad61" data-name="8988853dc2c7377256d1b11dba51ad61" d="M344.555-181.17l-3.291-4.173a3.541,3.541,0,0,0-2.261-1.3V-188.8a.5.5,0,0,0-.5-.5.5.5,0,0,0-.5.5v2.152a3.476,3.476,0,0,0-2.256,1.3l-3.3,4.178a2.094,2.094,0,0,0-.445,1.284v12.534a2.052,2.052,0,0,0,2.056,2.046h8.878A2.052,2.052,0,0,0,345-167.336v-12.544A2.057,2.057,0,0,0,344.555-181.17Zm-6.052-.587a1.456,1.456,0,0,1,1.456,1.46,1.456,1.456,0,0,1-1.456,1.46,1.455,1.455,0,0,1-1.451-1.46A1.458,1.458,0,0,1,338.5-181.756Zm1.15,8.582h.515a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-.515a1.806,1.806,0,0,1-.5.873,1.9,1.9,0,0,1-1.08.552l1.491,1.414a.5.5,0,0,1,.02.707.5.5,0,0,1-.705.02l-2.386-2.257a.5.5,0,0,1-.155-.366.491.491,0,0,1,.5-.492h.95a.927.927,0,0,0,.655-.276c.005,0,.005-.01.005-.01a1.03,1.03,0,0,0,.125-.166h-1.741a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h1.736a.932.932,0,0,0-.785-.4h-.95a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h3.336a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-.69A1.152,1.152,0,0,1,339.653-173.175Z" transform="translate(-314.122 200.122)"/>
            </g>
        </svg>
    );
}