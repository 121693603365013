import { ReportsData, SharedRedux } from '@mprofit/shared'
import { Divider, Grid, Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BackIcon } from '../../../../assets/icons/backIcon'
import { Cross } from '../../../../assets/icons/Cross'

import ButtonPrimaryCTA from '../../../shared/button-primary-cta'
import CustomModal from '../../../shared/custom-modal'
import CustomTypography from '../../../shared/customTypography'
import HorizontalStepper from '../../../shared/stepper'
import TabsButton from '../../../shared/tabs-button'
import PortfolioSummary from '../portfolio-summary'
import ReportoverviewCard from '../report-overview-card'

import { HttpStatusCode } from 'axios';
import { WebRedux } from '@mprofit/web'

interface IPerformanceReportModalProps {
  openModal: boolean,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function PerformanceReportModal({ openModal, setOpenModal }: IPerformanceReportModalProps) {
  const dispatch = useDispatch();
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [activeStep, setActiveStep] = useState<number>(0);
  const [value, setValue] = React.useState(0);
  const [tabLabel, setTabLabel] = useState<string[]>([]);
  const [summaryData, setSummaryData] = useState<any>([])


  useEffect(() => {
    let Category = ReportsData.ReportCategoryLists.INV.map((item) => item.CategoryName)
    setTabLabel(Category)
  }, [])

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenModal(false)
    }
    else {
      setActiveStep(prevState => prevState - 1)
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleConfigure = (data: any) => {
    setSummaryData(data);
    setActiveStep(1)
  }

  const generateReport = () => {
    SharedRedux.Reports.APIs.createReportsJobAPI({ IsGlobal: false, ReportParams: {} })
      .then((response) => {
        if (response.status === HttpStatusCode.Ok && response.data.IsSuccess) {
          dispatch(WebRedux.Reports.Actions.openSnackBar({ JobId: response.data.JobId }));
          dispatch(SharedRedux.BackgroundJobs.Actions.pollBackgroundJob({ JobId: response.data.JobId }));
        }
      })
  }

  function renderStepper(activeStep: number) {
    switch (activeStep) {
      case 0:
        return (
          <Box className="selectReport">
            <Box sx={style.tabsContainer}>
              <TabsButton
                labels={tabLabel}
                value={value}
                handleChange={handleChange}
              />
            </Box>
            <Divider />
            {tabLabel.map((panel, index) => (
              <TabPanel key={index} value={value} index={index}>
                <ReportoverviewCard data={ReportsData.ReportCategoryLists.INV[value]} handleConfigure={handleConfigure} />
              </TabPanel>
            ))}

          </Box>
        )
      case 1:
        return (
          <PortfolioSummary summaryData={summaryData} />
        )
      default:
        break;
    }
  }

  return (
    <CustomModal open={openModal} handleClose={() => handleClose()}>
      <Grid sx={style.modalContainer}>
        {/* <Box sx={{ height: '100%' }}> */}
        <Grid sx={style.modalHeader} container >
          <Grid item xs={3}   >
            <Box sx={{ cursor: 'pointer', width: 'fit-content', display: 'flex' }} onClick={() => handleBack()}>
              <BackIcon />
              <CustomTypography variant='Medium16' sx={{ marginLeft: "8px" }}>Back</CustomTypography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <HorizontalStepper
              steps={["select report", "Configure & generate"]}
              setSkipped={setSkipped}
              skipped={skipped}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="flex-end">
            <Cross style={{ cursor: 'pointer' }} onClick={() => handleClose()} />
          </Grid>
          <Grid item xs={12} sx={{ padding: { xs: "8px 0px", lg: '16px 0px' } }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          sx={{
            flex: '1',
            minHeight: '0',
            overflow: 'auto',
            "&::-webkit-scrollbar": {
              width: 1,
            },
          }}>
          {renderStepper(activeStep)}
        </Grid>

        {activeStep === 1 &&
          <Grid sx={{ flex: '0 0 auto' }} >
            <Box padding="16px 0px">
              <Divider />
            </Box>
            <Box sx={{ textAlign: 'end' }}>
              <ButtonPrimaryCTA
                handleClick={() => generateReport()}
                buttonText='Generate report'
              // disabled
              />
            </Box>
          </Grid>
        }
      </Grid>
    </CustomModal >
  )
}

const style = {
  modalContainer: {
    width: '85%',
    maxHeight: '90%',
    height: '90%',
    background: '#FFFFFF',
    borderRadius: '10px',
    padding: '0px 32px 16px 32px',
    display: 'flex',
    flexDirection: 'column',
    // overflow: 'auto',
  },
  mainContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  modalHeader: {
    flex: '0 0 100px',
    padding: '8px 0px',
    alignItems: 'center'
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContainer: {
    display: 'flex'
  },
  rightContent: {
    flex: '1 1 100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '24px',
    border: '1px solid #EBECF2',
    borderRadius: '0px 8px 8px 0px',
    '&:hover': {
      background: '#F9F9F9'
    }
  },
  additionalContainer: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    border: '1px solid #D8DAE5',
    borderRadius: '4px',
    padding: '21px 24px'
  }
}
