import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Stack } from "@mui/material"
import CustomModal from "../custom-modal"
import styles from "./index.module.css";
import CustomTypography from "../customTypography";
import { CloseIcon } from "packages/web/src/assets/icons/CloseIcon";
import ButtonPrimaryCTA from "../button-primary-cta";
import ButtonText from "../button-text";
import { useEffect, useState } from "react";
import SearchBar from "../search-bar";
import { SharedUtilities } from "@mprofit/shared";

interface MultiSelectModalContentProps {
  setIsModalOpen: (isOpen: boolean) => void;
  maxCount?: number;
  initialSelectedItems?: any[];
  isInitialSelectedItemsWithoutIDs?: boolean;
  list: any[];
  idField: string;
  textField: string;
  onSave: (selectedItems: {[ID: number]: boolean}) => void;
  title: string;
  subtitle?: string;
  multiLineSubtitle?: string[];
}
interface MultiSelectModalProps extends MultiSelectModalContentProps {
    isModalOpen: boolean;
}

export const MultiSelectModal = ({isModalOpen, setIsModalOpen, maxCount = 5, initialSelectedItems, isInitialSelectedItemsWithoutIDs = false, list, onSave, title, subtitle, multiLineSubtitle, idField, textField}: MultiSelectModalProps) => {
  return <CustomModal open={isModalOpen} handleClose={() => setIsModalOpen(false)}>
      <MultiSelectModalContent {...{setIsModalOpen, maxCount, initialSelectedItems, isInitialSelectedItemsWithoutIDs, list, onSave, title, subtitle, multiLineSubtitle, idField, textField}}/>
    </CustomModal>
}

export const MultiSelectModalContent = ({setIsModalOpen, maxCount = 5, initialSelectedItems, isInitialSelectedItemsWithoutIDs = false, list, onSave, title, subtitle, idField, textField, multiLineSubtitle}: MultiSelectModalContentProps ) => {
    const [selectedItemsByID, setSelectedItemsByID] = useState<{[ID: number]: boolean}>({});
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
      var initialSelectedItemsByID: {[ID: number]: boolean} = {};
      if (initialSelectedItems) {
        initialSelectedItems.forEach((item) => {
          initialSelectedItemsByID[isInitialSelectedItemsWithoutIDs ? item : item[idField]] = true;
        })
      }

      setSelectedItemsByID(initialSelectedItemsByID);
    }, [initialSelectedItems])

    const [selectedCount, setSelectedCount] = useState(0);

    const onToggleItem = (item: any) => {
      var previouslySelected = (selectedItemsByID[item[idField]] === true);
      var newSelected = {...selectedItemsByID};
      if (!(maxCount >= 0 && previouslySelected === false && selectedCount >= maxCount)) {
          newSelected[item[idField]] = previouslySelected === false;
          setSelectedItemsByID(newSelected);
      }
    }

    useEffect(() => {
        var _selectedCount = 0;
        for (const [key, value] of Object.entries(selectedItemsByID)) {
            if (value === true) {
                _selectedCount++;
            }
        }
        setSelectedCount(_selectedCount);
    }, [selectedItemsByID]);

    const isMaxSelected = maxCount >= 0 && selectedCount >= maxCount;

    const finalList = SharedUtilities.filterItems(searchTerm, list, [textField]);

    return (
      <Stack className={styles.modalContainer} sx={{width: {xl: '34%', lg: '50%', xs: '75%'}}}>
        <Box
          sx={{ boxSizing: 'border-box', display: 'flex', justifyContent: 'space-between', padding: '24px' }}>
          <Box>
            <CustomTypography variant="Medium24">{title}</CustomTypography>
            {
              multiLineSubtitle ?
              <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                {
                  multiLineSubtitle.map(x => (
                    <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }}>{x}</CustomTypography>
                  ))
                }
              </Box>
              :
              <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }}>{subtitle}</CustomTypography>
            }
          </Box>
          <Box onClick={() => setIsModalOpen(false)}>
            <CloseIcon style={{ cursor: 'pointer', color: '#707070', fontSize: '24px' }} />
          </Box>
        </Box>
        <Box sx={{marginBottom: '10px'}}>
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} isNonRoundedBorders={true}/>
        </Box>
        <FormGroup
          sx={{
            boxSizing: 'border-box',
            flexWrap: 'nowrap',
            overflow: 'auto',
            height: '600px',
            '::-webkit-scrollbar': {
              width: '1px',
            },
          }}>

          {finalList.map((item, index) => (
            <Box key={index} sx={{'&:hover': {cursor: 'pointer', backgroundColor: "#EAF4DF"}}} onClick={() => onToggleItem(item)}>
              <FormControlLabel value={item[idField]}
                sx={{
                  boxSizing: 'border-box',
                  '& .MuiFormControlLabel-label': { fontSize: '18px' },
                  marginRight: '0px',
                  color: '#12131A',
                  padding: '10px',
                }}
                className={styles.itemLabel}
                control={
                  <Checkbox
                    disableRipple
                    checked={selectedItemsByID[item[idField]] === true}
                    onChange={() => onToggleItem(item)}
                    sx={{
                      border: "5px",
                      marginLeft: '14px',
                      color: '#BABDCC',
                      '&.Mui-checked': {
                        color: '#5F854C',
                      },
                    }} />
                }
                label={item[textField]}
              />
              <Divider
                sx={{ width: '84%', margin: 'auto' }} />
            </Box>
          ))}

        </FormGroup>
        <Box className={styles.modalFooter}>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <CustomTypography variant={isMaxSelected ? "Medium16" : "Regular16"} sx={{color: isMaxSelected ? '#12131A' : '#64677A', letterSpacing: '0px'}}>{maxCount >= 0 ? `${selectedCount} of ${maxCount} selected` : `${selectedCount} selected`}</CustomTypography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center'}}>
              <Box>
                  <ButtonText buttonText="Cancel" handleClick={() => setIsModalOpen(false)} buttonSize="large" />
              </Box>
              <Box>
                  <ButtonPrimaryCTA buttonText="Done" handleClick={() => onSave(selectedItemsByID)} />
              </Box>
          </Box>
        </Box>
      </Stack>
    )
}