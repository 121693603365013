import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ClientRootState } from "../reducers";
import { AM_PeriodRequest, AssetTypeEnum, AssetTypeNameMapping, BarChartData, BarChartTwoColumnData, CashMgmtDetails, IndexReturnsByIndex, IndexReturnsByPeriod, PeriodInfo, PieChartData, SetPeriodPayload, TimePeriodFromEndDate, XIRRBenchmarkItem, XIRRFYWiseItem, XIRRLoadingStatuses, XIRRPerformance, XIRRStatus, XIRRTableItem, XIRRTotalItem, XIRRYearWiseItem, getPieChartColorByIndex, getTotalFeesPieChartColorByFee, CashflowChartData } from "../../constants";
import { Selectors as DashboardSelectors } from './dashboardReducer';
import { Big } from 'big.js'
import { SharedUtilities, formatDateYYYYMMDD } from "@mprofit/shared";
import { Actions as PortfolioActions } from './portfoliosReducer';
import { convertISTDateToSameDateUTC, getLastRefreshedTimeFromStr, getMPrMinDate, getPMSFeesNameFromFieldName, getToday } from "../../utilities";
import { Actions as LoginActions } from './loginReducer';

export interface SharedPerformanceState {
    ActivePeriod: PeriodInfo,
    XIRRPerformance: XIRRPerformance;
    PortfolioATTY?: AssetTypeEnum[];
    IsRefreshedInSameSession: {
        XIRRTotal: boolean;
        XIRRTable: boolean;
    },
    CashMgmtDetails: CashMgmtDetails | undefined;
}

const initialState: SharedPerformanceState = {
    ActivePeriod: {
        PeriodFrom: undefined,
        PeriodTo: undefined,
        PeriodText: 'All to Date',
        IsAllToDate: true
    },
    PortfolioATTY: undefined,
    XIRRPerformance: {
        XIRRTotal: {
            IsLoading: false
        },
        XIRRTable: {
            IsLoading: false
        },
        XIRRFYWise: {
            IsLoading: false
        },
        XIRRYearWise: {
            IsLoading: false
        },
        XIRRBenchmark: {
            IsLoading: false
        },
        XIRRTablePortfolioWise: {
            IsLoading: false
        }
    },
    IsRefreshedInSameSession: {
        XIRRTotal: false,
        XIRRTable: false
    },
    CashMgmtDetails: undefined
}

const PerformanceSlice = createSlice({
    name: 'performance',
    initialState,
    reducers: {
        setXIRRTotal: (state, action: PayloadAction<XIRRTotalItem | undefined>) => {
            state.XIRRPerformance.XIRRTotal.IsLoading = (action.payload && XIRRLoadingStatuses.includes(action.payload.Status)) === true
            state.XIRRPerformance.XIRRTotal.Data = action.payload;
            state.IsRefreshedInSameSession.XIRRTotal = true;
        },
        setXIRRTotalIndexReturns: (state, action: PayloadAction<IndexReturnsByIndex[]>) => {
            state.XIRRPerformance.XIRRTotal.IndexReturns = action.payload;
            state.XIRRPerformance.XIRRTotal.IsIndexLoading = false;
        },
        refreshXIRRTotal: (state, action: PayloadAction<RefreshXIRRPayload>) => {
            state.XIRRPerformance.XIRRTotal = {
                IsLoading: true
            }
        },
        refreshXIRRTotalIndexReturns: (state, action: PayloadAction<AM_PeriodRequest>) => {
            state.XIRRPerformance.XIRRTotal.IsIndexLoading = true;
            state.XIRRPerformance.XIRRTotal.IndexReturnsPeriodRequest = action.payload;
            state.XIRRPerformance.XIRRTotal.IsOlderThan2012Return = action.payload.IsOlderThan2012Return === true;
        },
        setXIRRTable: (state, action: PayloadAction<XIRRTableItem | undefined>) => {
            state.XIRRPerformance.XIRRTable.IsLoading = (action.payload && XIRRLoadingStatuses.includes(action.payload.Status)) === true;
            state.XIRRPerformance.XIRRTable.Item = action.payload;
        },
        refreshXIRRTable: (state, action: PayloadAction<RefreshXIRRPayload>) => {
            state.XIRRPerformance.XIRRTable = {
                IsLoading: true
            }
        },
        setXIRRFYWise: (state, action: PayloadAction<XIRRFYWiseItem | undefined>) => {
            state.XIRRPerformance.XIRRFYWise.IsLoading = (action.payload && XIRRLoadingStatuses.includes(action.payload.Status)) === true;
            state.XIRRPerformance.XIRRFYWise.Item = action.payload;
        },
        setXIRRFYWiseIndexReturns: (state, action: PayloadAction<IndexReturnsByPeriod[]>) => {
            state.XIRRPerformance.XIRRFYWise.IndexReturns = action.payload;
            state.XIRRPerformance.XIRRFYWise.IsIndexLoading = false;
        },
        refreshXIRRFYWise: (state, action: PayloadAction<RefreshXIRRPayload>) => {
            state.XIRRPerformance.XIRRFYWise = {
                IsLoading: true
            }
        },
        refreshXIRRFYWiseIndexReturns: (state, action: PayloadAction<AM_PeriodRequest[]>) => {
            state.XIRRPerformance.XIRRFYWise.IsIndexLoading = true;
            state.XIRRPerformance.XIRRFYWise.IndexReturnsPeriodRequests = action.payload;
        },
        setXIRRYearWise: (state, action: PayloadAction<XIRRYearWiseItem | undefined>) => {
            state.XIRRPerformance.XIRRYearWise.IsLoading = (action.payload && XIRRLoadingStatuses.includes(action.payload.Status)) === true;
            state.XIRRPerformance.XIRRYearWise.Item = action.payload;
        },
        setXIRRYearWiseIndexReturns: (state, action: PayloadAction<IndexReturnsByPeriod[]>) => {
            state.XIRRPerformance.XIRRYearWise.IndexReturns = action.payload;
            state.XIRRPerformance.XIRRYearWise.IsIndexLoading = false;
        },
        refreshXIRRYearWise: (state, action: PayloadAction<RefreshXIRRPayload>) => {
            state.XIRRPerformance.XIRRYearWise = {
                IsLoading: true
            }
        },
        refreshXIRRYearWiseIndexReturns: (state, action: PayloadAction<AM_PeriodRequest[]>) => {
            state.XIRRPerformance.XIRRYearWise.IsIndexLoading = true;
            state.XIRRPerformance.XIRRYearWise.IndexReturnsPeriodRequests = action.payload;
        },
        clearCachedXIRR: (state) => {
            clearCachedXIRR(state);
        },
        setActivePeriod: (state, action: PayloadAction<SetPeriodPayload>) => {
            var periodFromValue = action.payload.PeriodFromValue;
            if (periodFromValue && periodFromValue <= getMPrMinDate()) {
                periodFromValue = undefined;
            }
            var periodToValue = action.payload.PeriodToValue;
            if (periodToValue && periodToValue >= getToday()) {
                periodToValue = undefined;
            }
            state.ActivePeriod = {
                PeriodFrom: periodFromValue ? formatDateYYYYMMDD(periodFromValue) : undefined,
                PeriodTo: periodToValue ? formatDateYYYYMMDD(periodToValue) : undefined,
                PeriodText: action.payload.PeriodText,
                IsAllToDate: action.payload.IsAllToDate
            }
            clearCachedXIRR(state);
        },
        resetPeriodToAllToDateWithoutRefresh: (state) => {
            state.ActivePeriod = initialState.ActivePeriod;
        },
        setPortfolioATTY: (state, action: PayloadAction<AssetTypeEnum[] | undefined>) => {
            state.PortfolioATTY = action.payload;
        },
        clearIsRefreshedInSameSession: (state) => {
            state.IsRefreshedInSameSession = { ...initialState.IsRefreshedInSameSession };
        },
        refreshXIRRBenchmark: (state, action: PayloadAction<RefreshXIRRPayload>) => {
            state.XIRRPerformance.XIRRBenchmark = {
                IsLoading: true
            }
        },
        setXIRRBenchmark: (state, action: PayloadAction<XIRRBenchmarkItem | undefined>) => {
            state.XIRRPerformance.XIRRBenchmark.IsLoading = (action.payload && XIRRLoadingStatuses.includes(action.payload.Status)) === true
            state.XIRRPerformance.XIRRBenchmark.Item = action.payload;
        },
        getCashMgmtDetails: (state, action: PayloadAction<CashMgmtRequestPayload>) => {
            // FIXME: Can add a loading state here
        },
        setCashMgmtDetails: (state, action: PayloadAction<CashMgmtDetails | undefined>) => {
            state.CashMgmtDetails = action.payload;
        },
        refreshXIRRTablePortfolioWise: (state, action: PayloadAction<RefreshXIRRPayload>) => {
            state.XIRRPerformance.XIRRTablePortfolioWise = {
                IsLoading: true
            }
        },
        setXIRRTablePortfolioWise: (state, action: PayloadAction<XIRRTableItem | undefined>) => {
            state.XIRRPerformance.XIRRTablePortfolioWise.IsLoading = (action.payload && XIRRLoadingStatuses.includes(action.payload.Status)) === true;
            state.XIRRPerformance.XIRRTablePortfolioWise.Item = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(PortfolioActions.setActiveScreenIDs, (state, action) => {
                state.IsRefreshedInSameSession = { ...initialState.IsRefreshedInSameSession };
            })
            .addCase(LoginActions.logout, () => initialState)
            .addCase(LoginActions.resetData, () => initialState)
    }
});

const clearCachedXIRR = (state: SharedPerformanceState) => {
    state.XIRRPerformance.XIRRTotal = {
        IsLoading: false
    };
    state.XIRRPerformance.XIRRTable = {
        IsLoading: false
    };
    state.XIRRPerformance.XIRRFYWise = {
        IsLoading: false
    };
    state.XIRRPerformance.XIRRYearWise = {
        IsLoading: false
    };
    state.XIRRPerformance.XIRRBenchmark = {
        IsLoading: false
    };
    state.XIRRPerformance.XIRRTablePortfolioWise = {
        IsLoading: false
    }
}



//#region Actions

//#endregion

//#region Payloads
export interface RefreshXIRRPayload { Force: boolean, NoRefresh: boolean, XIRRObjectID?: string, CCID: number, FamilyId: number, PFID: number, ATTY?: number, AssetAllocationCategID?: number, AMID?: number, SID?: number, PeriodFrom?: string, PeriodTo?: string, PortfolioATTY?: AssetTypeEnum[], IsClearPrevious?: boolean, IsBenchmarkXIRRRequired?: boolean, BenchmarkMProfitCode?: number, IsCashMgmtPortfolio?: boolean, IsGroupByPortfolio?: boolean, IsCashMgmtEnabled?: boolean }
export interface CashMgmtRequestPayload { FamilyId: number, PFID: number, CCID: number };

//#endregion

//#region Selectors

const performanceSelector = (state: ClientRootState) => state.shared.performance;
const xirrSelector = (state: ClientRootState) => performanceSelector(state).XIRRPerformance;
const selectIsRefreshedInSameSession = (state: ClientRootState) => performanceSelector(state).IsRefreshedInSameSession;

const selectActivePeriod = (state: ClientRootState) => performanceSelector(state).ActivePeriod;
const selectActivePeriodText = (state: ClientRootState) => performanceSelector(state).ActivePeriod?.PeriodText;
const selectIsPeriodAllToDate = (state: ClientRootState) => performanceSelector(state).ActivePeriod?.IsAllToDate === true;

const selectPortfolioATTY = (state: ClientRootState) => performanceSelector(state).PortfolioATTY;
const selectPortfolioATTYText = createSelector([selectPortfolioATTY],
    (portfolioATTY) => {
        if (!portfolioATTY || portfolioATTY.length == 0) return 'Portfolio';

        if (portfolioATTY.length == 1) {
            return AssetTypeNameMapping[portfolioATTY[0]];
        } else if (portfolioATTY.length == 2) {
            return `${AssetTypeNameMapping[portfolioATTY[0]]} & ${AssetTypeNameMapping[portfolioATTY[1]]}`;
        }

        return `${portfolioATTY.length} Asset Classes`;
    }
)

const selectXIRRTotal = (state: ClientRootState) => xirrSelector(state).XIRRTotal;
const selectXIRRTotalData = (state: ClientRootState) => selectXIRRTotal(state).Data;
const selectXIRRTotalIndexReturns = (state: ClientRootState) => selectXIRRTotal(state).IndexReturns;
const selectXIRRTotalIsOlderThan2012Return = (state: ClientRootState) => selectXIRRTotal(state).IsOlderThan2012Return;
const selectXIRRTotalIndexReturnsPeriodRequest = (state: ClientRootState) => selectXIRRTotal(state).IndexReturnsPeriodRequest;
const selectXIRRTotalIsLoading = (state: ClientRootState) => {
    var xirrTotal = selectXIRRTotal(state);
    return (xirrTotal.IsLoading || xirrTotal.IsIndexLoading) === true;
}
const selectXIRRTotalLastRefreshedTime = (state: ClientRootState) => {
    var lastRefreshedTimeStr = selectXIRRTotalData(state)?.LastRefreshedTime;
    return getLastRefreshedTimeFromStr(lastRefreshedTimeStr);
}
const selectXIRRTotalIsRefreshedInSameSession = (state: ClientRootState) => selectIsRefreshedInSameSession(state).XIRRTotal;


const selectXIRRTable = (state: ClientRootState) => xirrSelector(state).XIRRTable;
const selectXIRRTableIsLoading = (state: ClientRootState) => selectXIRRTable(state).IsLoading;
const selectXIRRTableData = (state: ClientRootState) => selectXIRRTable(state).Item;
const selectXIRRTableLastRefreshedTime = (state: ClientRootState) => {
    var lastRefreshedTimeStr = selectXIRRTableData(state)?.LastRefreshedTime;
    return getLastRefreshedTimeFromStr(lastRefreshedTimeStr);
}

const selectXIRRTablePortfolioWise = (state: ClientRootState) => xirrSelector(state).XIRRTablePortfolioWise;
const selectXIRRTablePortfolioWiseIsLoading = (state: ClientRootState) => selectXIRRTablePortfolioWise(state).IsLoading;
const selectXIRRTablePortfolioWiseData = (state: ClientRootState) => selectXIRRTablePortfolioWise(state).Item;

const selectXIRRFYWise = (state: ClientRootState) => xirrSelector(state).XIRRFYWise;
const selectXIRRFYWiseIsLoading = (state: ClientRootState) => {
    var xirrFYWise = selectXIRRFYWise(state);
    return (xirrFYWise.IsLoading || xirrFYWise.IsIndexLoading) === true;
}

const selectXIRRFYWiseData = (state: ClientRootState) => selectXIRRFYWise(state).Item;
const selectXIRRFYWiseIndexReturns = (state: ClientRootState) => selectXIRRFYWise(state).IndexReturns;
const selectXIRRFYWiseIndexReturnsPeriodRequests = (state: ClientRootState) => selectXIRRFYWise(state).IndexReturnsPeriodRequests;
const selectXIRRFYWiseLastRefreshedTime = (state: ClientRootState) => {
    var lastRefreshedTimeStr = selectXIRRFYWiseData(state)?.LastRefreshedTime;
    return getLastRefreshedTimeFromStr(lastRefreshedTimeStr);
}

const selectXIRRBenchmark = (state: ClientRootState) => xirrSelector(state).XIRRBenchmark;
const selectXIRRBenchmarkData = (state: ClientRootState) => selectXIRRBenchmark(state).Item;
const selectXIRRBenchmarkIsLoading = (state: ClientRootState) => selectXIRRBenchmark(state).IsLoading;
const selectXIRRBenchmarkIsBenchmarkPricesNotAvailable = (state: ClientRootState) => selectXIRRBenchmarkData(state)?.Status === XIRRStatus.BenchmarkPricesNotAvailable;
const selectXIRRBenchmarkLastRefreshedTime = (state: ClientRootState) => {
    var xirrBenchmarkData = selectXIRRBenchmarkData(state);
    var lastRefreshedTimeStr = xirrBenchmarkData?.Status !== undefined && ![XIRRStatus.Pending, XIRRStatus.Processing].includes(xirrBenchmarkData?.Status) ? xirrBenchmarkData?.LastRefreshedTime : undefined;
    return getLastRefreshedTimeFromStr(lastRefreshedTimeStr);
}

const selectCashMgmtDetails = (state: ClientRootState) => performanceSelector(state).CashMgmtDetails;

const selectTotalFeesDistributionPieChartData = createSelector([selectXIRRTotalData], (cashMgmtTotals) => {
    var feesBreakdown = cashMgmtTotals?.FeesBreakdown;
    var pieChartData: PieChartData[] = [];
    var totalValue = 0;

    if (feesBreakdown) {
        totalValue = Object.values(feesBreakdown).reduce((a, b) => a + b, 0);

        if (totalValue <= 0) return { PieChartData: [], TotalValue: "0" };

        pieChartData = Object.keys(feesBreakdown).map(fee => {
            var feeValue = (feesBreakdown && (feesBreakdown as any)[fee]) || 0
            var itemValue = Big(feeValue);
            var percentValue = itemValue.div(totalValue);
            return {
                name: getPMSFeesNameFromFieldName(fee), value: feeValue || 0, IDs: {}, numValueBig: itemValue,
                pctValueBig: percentValue, originalName: fee,
            }
        }).filter(x => x.numValueBig.gt(0))
            .sort((x, y) => x.numValueBig.lt(y.numValueBig) ? 1 : -1)
            .map((x) => ({ ...x, color: getTotalFeesPieChartColorByFee(x.originalName) }));

    }

    return {
        PieChartData: pieChartData,
        TotalValue: `${totalValue}`
    }
})

const selectCashflowChartData = createSelector([selectXIRRTotalData], (cashMgmtTotals) => {

    if (!cashMgmtTotals) return [];

    var cashFlowChartData: CashflowChartData = [{
        name: "In",
        ...(cashMgmtTotals.PeriodFrom == null ? {} : { "Opening Investment Value": cashMgmtTotals?.OpeningValuation || 0, "Opening Cash": cashMgmtTotals?.OpeningCashBalance || 0 }),
        "Cash In": cashMgmtTotals?.TotalInflow || 0,
    },
    {
        name: "Out",
        "Cash Out": cashMgmtTotals?.TotalOutflow || 0,
        "Income Payout": cashMgmtTotals?.TotalIncome || 0,
        "Closing Investment Value": cashMgmtTotals?.ClosingValuation || 0,
        "Closing Cash": cashMgmtTotals?.ClosingCashBalance || 0,
    }];

    return cashFlowChartData;
})

const selectXIRRTotalPerformanceIndicesChartValues = createSelector([DashboardSelectors.selectPerformanceIndicesWatchlist, selectXIRRTotalIndexReturns], (watchlist, indexReturns) => {
    if (!indexReturns || !watchlist || !watchlist.Items) return undefined;

    var chartValues: BarChartData[] = [];

    watchlist.Items.forEach((item, index) => {
        var indexReturn = indexReturns.find(x => x.MProfitCode == item.MProfitCode);
        if (indexReturn) {
            chartValues.push({
                Name: item.AssetName || `Index ${index}`,
                Value: indexReturn.CAGR,
                ValueStr: ''
            })
        }
    })

    return chartValues;
});

const selectXIRRFYWiseWithIndices = createSelector([selectXIRRFYWiseData, selectXIRRFYWiseIndexReturns],
    (xirrFYWise, indexReturns) => {
        console.log('selectXIRRFYWiseWithIndices', xirrFYWise, indexReturns)

        if (!xirrFYWise || !xirrFYWise.FinancialYearWiseGroupData || !indexReturns) return undefined;

        var chartData: BarChartTwoColumnData[] = [];

        for (var item of xirrFYWise.FinancialYearWiseGroupData) {
            if (item.XIRR !== null) {
                var periodFrom = new Date(item.PeriodFrom);
                var periodTo = new Date(item.PeriodTo);
                var name: string | undefined = undefined;
                if (new Date(item.PeriodFrom) < new Date(1970, 4, 1)) {
                    if (item.FirstTransDate) {
                        name = 'All to Date';
                        periodFrom = convertISTDateToSameDateUTC(item.FirstTransDate);
                    } else {
                        continue;
                    }
                }

                var indexReturn = indexReturns && indexReturns
                    .find(x => SharedUtilities.checkSameDay(new Date(x.StartDateStr), periodFrom)
                        && SharedUtilities.checkSameDay(new Date(x.EndDateStr), periodTo));

                var startYear = periodFrom.getFullYear();
                var endYear = startYear + 1;

                chartData.push({
                    Name: name || `FY ${('' + startYear).substring(2, 4)}-${('' + endYear).substring(2, 4)}`,
                    Column1Value: item.XIRR,
                    Column2Value: indexReturn?.CAGR || 0
                })
            }
        };

        return chartData;
    }
)

const selectXIRRYearWise = (state: ClientRootState) => xirrSelector(state).XIRRYearWise;
const selectXIRRYearWiseIsLoading = (state: ClientRootState) => {
    var xirrYearWise = selectXIRRYearWise(state);
    return (xirrYearWise.IsLoading || xirrYearWise.IsIndexLoading) === true;
}
const selectXIRRYearWiseData = (state: ClientRootState) => selectXIRRYearWise(state).Item;
const selectXIRRYearWiseIndexReturns = (state: ClientRootState) => selectXIRRYearWise(state).IndexReturns;
const selectXIRRYearWiseIndexReturnsPeriodRequests = (state: ClientRootState) => selectXIRRYearWise(state).IndexReturnsPeriodRequests;
const selectXIRRYearWiseLastRefreshedTime = (state: ClientRootState) => {
    var lastRefreshedTimeStr = selectXIRRYearWiseData(state)?.LastRefreshedTime;
    return getLastRefreshedTimeFromStr(lastRefreshedTimeStr);
}

const selectPerformanceXIRRTableData = (isGroupByPortfolio: boolean) => {
    if (isGroupByPortfolio) {
        return selectXIRRTablePortfolioWise;
    } else {
        return selectXIRRTable;
    }
}

const selectXIRRYearWiseWithIndices = createSelector([selectXIRRYearWiseData, selectXIRRYearWiseIndexReturns],
    (xirrYearWise, indexReturns) => {
        if (!xirrYearWise || !xirrYearWise.YearWiseGroupData || !indexReturns) return undefined;

        var chartData: BarChartTwoColumnData[] = [];

        for (var item of xirrYearWise.YearWiseGroupData) {
            if (item.XIRR !== null) {
                var periodFrom = new Date(item.PeriodFrom);
                var periodTo = new Date(item.PeriodTo);
                if (new Date(item.PeriodFrom) < new Date(1970, 4, 1)) {
                    if (item.FirstTransDate) {
                        periodFrom = convertISTDateToSameDateUTC(item.FirstTransDate);
                    } else {
                        continue;
                    }
                }
                var indexReturn = indexReturns && indexReturns.find(x => SharedUtilities.checkSameDay(new Date(x.StartDateStr), periodFrom)
                    && SharedUtilities.checkSameDay(new Date(x.EndDateStr), periodTo));

                var name: string | undefined = undefined;
                if (item.TimePeriodFromEndDate.Year < 0 && item.TimePeriodFromEndDate.Month < 0 && item.TimePeriodFromEndDate.Day < 0) {
                    name = 'All to Date';
                } else {
                    if (item.TimePeriodFromEndDate.Year > 0) {
                        name = `${item.TimePeriodFromEndDate.Year} Year${item.TimePeriodFromEndDate.Year > 1 ? 's' : ''}`
                    }
                }

                if (name) {
                    chartData.push({
                        Name: name,
                        Column1Value: item.XIRR,
                        Column2Value: indexReturn?.CAGR
                    })
                }
            }
        };

        return chartData.reverse();
    }
)

//#endregion

export default PerformanceSlice.reducer;
export const Actions = { ...PerformanceSlice.actions };
export const Selectors = {
    selectXIRRTotalIsLoading,
    selectXIRRTotalIndexReturnsPeriodRequest,
    selectXIRRTotalIsOlderThan2012Return,
    selectXIRRTotalData,
    selectXIRRTotalIsRefreshedInSameSession,
    selectXIRRTable,
    selectXIRRTableIsLoading,
    selectXIRRTotalLastRefreshedTime,
    selectXIRRTableData,
    selectXIRRTableLastRefreshedTime,
    selectXIRRFYWise,
    selectXIRRFYWiseIsLoading,
    selectXIRRFYWiseData,
    selectXIRRFYWiseIndexReturnsPeriodRequests,
    selectXIRRFYWiseLastRefreshedTime,
    selectActivePeriod,
    selectActivePeriodText,
    selectIsPeriodAllToDate,
    selectPortfolioATTY,
    selectPortfolioATTYText,
    selectXIRRTotalPerformanceIndicesChartValues,
    selectXIRRFYWiseWithIndices,
    selectXIRRYearWise,
    selectXIRRYearWiseIsLoading,
    selectXIRRYearWiseData,
    selectXIRRYearWiseIndexReturnsPeriodRequests,
    selectXIRRYearWiseLastRefreshedTime,
    selectXIRRYearWiseWithIndices,
    selectXIRRBenchmarkData,
    selectXIRRBenchmarkIsLoading,
    selectXIRRBenchmarkLastRefreshedTime,
    selectXIRRBenchmarkIsBenchmarkPricesNotAvailable,
    selectCashMgmtDetails,
    selectTotalFeesDistributionPieChartData,
    selectCashflowChartData,
    selectXIRRTablePortfolioWise,
    selectXIRRTablePortfolioWiseData,
    selectXIRRTablePortfolioWiseIsLoading,
    selectPerformanceXIRRTableData
};
