import { Box, Button, TypographyPropsVariantOverrides } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import CustomTypography from '../customTypography';
import { ArrowDown } from '../../../assets/icons/ArrowDown';
import { OverridableStringUnion } from "@mui/types";
import { Variant } from '@mui/material/styles/createTypography';

interface ButtonProps {
    text: string,
    icon?: JSX.Element,
    disabled?: boolean,
    handleClick?: (() => void) | undefined,
    typographyVariant?: OverridableStringUnion<"inherit" | Variant, TypographyPropsVariantOverrides> | undefined
}

const ModalButton = ({ text, icon, disabled, handleClick, typographyVariant = 'Regular18' }: ButtonProps) => {
    return (
        <Box
            onClick={disabled ? undefined : handleClick}
            sx={{
                cursor: disabled ? 'default' : 'pointer',
                display: 'flex',
                alignItems: 'center',
                padding: '9px 12px 9px 16px',
                color: "#64677A",
                border: "1px solid #D8DAE5",
                borderRadius: '4px',
                '&:hover': disabled ? {} : {
                    background: '#f6f6f6'
                }
            }}>
            {icon}
            <CustomTypography variant={typographyVariant} sx={{ color: { xs: '#64677A', marginLeft: icon ? '14px' : '0px' } }}>{text}</CustomTypography>
            <ArrowDown style={{ paddingLeft: '12px' }} />
        </Box>

        // <Button endIcon={<ArrowDown />}></Button>
    )
}

export default ModalButton