export function PPF(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226556" data-name="Group 226556" transform="translate(-17890 6538.834)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 0.166)" fill="#fff" opacity="0" />
          <g id="Group_222246" data-name="Group 222246" transform="translate(0 4.411)">
            <g id="Rectangle_24192" data-name="Rectangle 24192" transform="translate(0 -0.245)" fill="none" stroke="#fff" stroke-width="1">
              <rect width="20" height="12" rx="4" stroke="none" />
              <rect x="0.5" y="0.5" width="19" height="11" rx="3.5" fill="none" />
            </g>
            <path id="Generated_a_sustaine" data-name="Generated a sustaine" d="M1.117-3.107a.175.175,0,0,1-.129-.053.175.175,0,0,1-.053-.129V-8.211a.192.192,0,0,1,.049-.136A.172.172,0,0,1,1.117-8.4H3.144a2.192,2.192,0,0,1,1.429.427A1.524,1.524,0,0,1,5.1-6.721a1.506,1.506,0,0,1-.518,1.244,2.216,2.216,0,0,1-1.433.42H2.017v1.77a.182.182,0,0,1-.049.129.172.172,0,0,1-.132.053ZM3.106-5.9A.982.982,0,0,0,3.779-6.1a.781.781,0,0,0,.234-.616.825.825,0,0,0-.227-.616.934.934,0,0,0-.681-.223H2V-5.9ZM6.1-3.107a.175.175,0,0,1-.129-.053.175.175,0,0,1-.053-.129V-8.211a.192.192,0,0,1,.049-.136A.172.172,0,0,1,6.1-8.4H8.128a2.192,2.192,0,0,1,1.429.427,1.524,1.524,0,0,1,.522,1.252,1.506,1.506,0,0,1-.518,1.244,2.216,2.216,0,0,1-1.433.42H7v1.77a.182.182,0,0,1-.049.129.172.172,0,0,1-.132.053ZM8.09-5.9A.982.982,0,0,0,8.763-6.1.781.781,0,0,0,9-6.721a.825.825,0,0,0-.227-.616.934.934,0,0,0-.681-.223h-1.1V-5.9Zm2.995,2.79a.175.175,0,0,1-.129-.053.175.175,0,0,1-.053-.129V-8.211a.192.192,0,0,1,.049-.136.172.172,0,0,1,.132-.053h3.282a.184.184,0,0,1,.136.053.184.184,0,0,1,.053.136v.529a.172.172,0,0,1-.053.132.192.192,0,0,1-.136.049H11.939v1.437h2.276a.184.184,0,0,1,.136.053.184.184,0,0,1,.053.136v.529a.172.172,0,0,1-.053.132.192.192,0,0,1-.136.049H11.939v1.875a.172.172,0,0,1-.053.132.192.192,0,0,1-.136.049Z" transform="translate(2.338 11.425)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>

  );
}
