import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { MProfitAPI, ResponseGenerator } from "../../api";
import { Actions, CashMgmtRequestPayload, RefreshXIRRPayload, Selectors } from "../modules/performanceReducer";
import { Selectors as LoginSelectors } from "../modules/loginReducer";
import type { PayloadAction } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { AM_PeriodRequest, AssetTypeEnum, IndexMaster, PeriodInfo, Portfolio, TimePeriodFromEndDate, WatchlistItem, XIRRBenchmarkItem, XIRRFYWiseItem, XIRRStatus, XIRRTableItem, XIRRTotalItem, XIRRYearWiseItem } from "../../constants";
import { ActiveScreenPayload, Selectors as PortfolioSelectors } from '../modules/portfoliosReducer';
import { Selectors as DashboardSelectors, Actions as DashboardActions } from '../modules/dashboardReducer'
import { LicensingSelectors } from "../modules/licensing";
import { convertISTDateToSameDateUTC, convertUTCDateToAssetMastersFormat } from "../../utilities";

interface performanceXirrTotalAPIParams { Query: RefreshXIRRPayload; PortfolioATTY?: AssetTypeEnum[]; };
interface cashMgmtAPIParams { Query: { FamilyId: number; PFID: number; CCID: number; }; };

const performanceXirrApi = ({ Query, PortfolioATTY }: performanceXirrTotalAPIParams) => {
    return MProfitAPI.post('/api/Performance/XIRRTotal', { PortfolioATTY }, {
        params: {
            xirrObjectID: Query.XIRRObjectID,
            ccid: Query.CCID,
            familyId: Query.FamilyId,
            pfid: Query.PFID,
            atty: Query.ATTY || -1,
            amid: Query.AMID || -1,
            sid: Query.SID || -1,
            assetAllocationCategID: Query.AssetAllocationCategID,
            periodFrom: Query.PeriodFrom,
            periodTo: Query.PeriodTo,
            force: Query.XIRRObjectID ? false : Query.Force,
            noRefresh: Query.NoRefresh === true,
            isClearPrevious: Query.IsClearPrevious === true,
            isBenchmarkXIRRRequired: Query.IsBenchmarkXIRRRequired === true,
            benchmarkMProfitCode: Query.BenchmarkMProfitCode || undefined,
            isCashMgmtPortfolio: Query.IsCashMgmtPortfolio || undefined,
        }
    });
}

const cashMgmtDetailedApi = ({ Query }: cashMgmtAPIParams) => {
    return MProfitAPI.get('/api/Summary/CashMgmtDetailed', {
        params: {
            familyId: Query.FamilyId,
            pfid: Query.PFID,
            ccid: Query.CCID
        }
    })
}

function* getCashMgmtDetails(action: PayloadAction<CashMgmtRequestPayload>) {
    const cashMgmtDetailsResponse: ResponseGenerator = yield call(cashMgmtDetailedApi, { Query: { ...action.payload } })
    if (cashMgmtDetailsResponse.status == HttpStatusCode.Ok) {
        yield put(Actions.setCashMgmtDetails(cashMgmtDetailsResponse.data));
    }
}

function* onRefreshXirrData(action: PayloadAction<RefreshXIRRPayload>) {
    var isPerformanceViewAllowed: boolean = yield select(LicensingSelectors.selectIsPerformanceViewEnabled);
    if (!isPerformanceViewAllowed) {
        return;
    }

    if (action.payload.ATTY === AssetTypeEnum.BND) {
        yield put(Actions.resetPeriodToAllToDateWithoutRefresh());
    }

    var IsCashMgmtPortfolio: boolean = yield select(LicensingSelectors.selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck)

    var activePeriod: PeriodInfo | undefined = yield select(Selectors.selectActivePeriod);
    if (!activePeriod) return;

    var xirrObjectID: string | undefined = undefined;
    var XIRRData: XIRRTotalItem | undefined = undefined;

    var PortfolioATTY: AssetTypeEnum[] | undefined = yield select(Selectors.selectPortfolioATTY);

    if (activePeriod.IsAllToDate === true || IsCashMgmtPortfolio || (action.payload.ATTY && action.payload.ATTY > 0)) {
        PortfolioATTY = undefined;
    } else if (!PortfolioATTY) {
        return;
    }

    var isRefreshedInSameSession: boolean = yield select(Selectors.selectXIRRTotalIsRefreshedInSameSession);

    var Force = action.payload.Force || (activePeriod.IsAllToDate === false || isRefreshedInSameSession === false);

    var isIndexReturnsRefreshed = false;

    while (true) {
        if (XIRRData !== undefined && XIRRData.Status !== XIRRStatus.Processing && XIRRData.Status !== XIRRStatus.Pending && XIRRData.Status !== XIRRStatus.Completed_CalculatingRelUnrelGain) {
            break;
        } else {
            if (XIRRData !== undefined) {
                yield delay(2000);
            }
            var apiParams: performanceXirrTotalAPIParams = {
                Query: { ...action.payload, XIRRObjectID: xirrObjectID, PeriodFrom: activePeriod.PeriodFrom, PeriodTo: activePeriod.PeriodTo, Force, IsClearPrevious: action.payload.Force, IsCashMgmtPortfolio },
                PortfolioATTY,
            };
            const response: ResponseGenerator = yield call(performanceXirrApi, apiParams);
            if (response.status == HttpStatusCode.Ok) {
                XIRRData = response.data;
                if (XIRRData != null) {
                    xirrObjectID = XIRRData.ID;
                }
                yield put(Actions.setXIRRTotal(XIRRData));

                if (XIRRData != null && isIndexReturnsRefreshed === false && XIRRData.Status === XIRRStatus.Completed_CalculatingRelUnrelGain) {
                    isIndexReturnsRefreshed = true;
                    yield (call as any)(refreshXIRRTotalIndexReturns, XIRRData);
                }

                if (action.payload.NoRefresh) break;
            } else {
                break;
            }
        }
    }

    if (isIndexReturnsRefreshed === false) {
        yield (call as any)(refreshXIRRTotalIndexReturns, XIRRData);
    }
}

function* onRefreshXirrBenchmark(action: PayloadAction<RefreshXIRRPayload>) {
    var isPerformanceViewAllowed: boolean = yield select(LicensingSelectors.selectIsPerformanceViewEnabled);
    if (!isPerformanceViewAllowed) {
        return;
    }

    if (action.payload.ATTY === AssetTypeEnum.BND) {
        yield put(Actions.resetPeriodToAllToDateWithoutRefresh());
    }

    var IsCashMgmtPortfolio: boolean = yield select(LicensingSelectors.selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck)

    var activePeriod: PeriodInfo | undefined = yield select(Selectors.selectActivePeriod);
    if (!activePeriod) return;

    var xirrObjectID: string | undefined = undefined;
    var XIRRData: XIRRBenchmarkItem | undefined = undefined;

    var PortfolioATTY: AssetTypeEnum[] | undefined = yield select(Selectors.selectPortfolioATTY);

    if (activePeriod.IsAllToDate === true || IsCashMgmtPortfolio || (action.payload.ATTY && action.payload.ATTY > 0)) {
        PortfolioATTY = undefined;
    } else if (!PortfolioATTY) {
        return;
    }

    var Force = true;

    var xirrBenchmarkSingleIndex: WatchlistItem | undefined = yield select(DashboardSelectors.selectXIRRBenchmarkSingleIndex);
    var benchmarkMProfitCode: number = xirrBenchmarkSingleIndex?.MProfitCode || -1;

    while (true) {
        if (XIRRData !== undefined && XIRRData.Status !== XIRRStatus.Processing && XIRRData.Status !== XIRRStatus.Pending && XIRRData.Status !== XIRRStatus.Completed_CalculatingRelUnrelGain) {
            break;
        } else {
            if (XIRRData !== undefined) {
                yield delay(2000);
            }
            var apiParams: performanceXirrTotalAPIParams = {
                Query: { ...action.payload, XIRRObjectID: xirrObjectID, PeriodFrom: activePeriod.PeriodFrom, PeriodTo: activePeriod.PeriodTo, Force, IsClearPrevious: action.payload.Force, IsBenchmarkXIRRRequired: true, BenchmarkMProfitCode: benchmarkMProfitCode, IsCashMgmtPortfolio },
                PortfolioATTY,
            };
            const response: ResponseGenerator = yield call(performanceXirrApi, apiParams);
            if (response.status == HttpStatusCode.Ok) {
                XIRRData = response.data;
                if (XIRRData != null) {
                    xirrObjectID = XIRRData.ID;
                }
                yield put(Actions.setXIRRBenchmark(XIRRData));

                if (xirrBenchmarkSingleIndex === undefined && XIRRData && XIRRData.BenchmarkMProfitCode > 0) {
                    const indicesMaster: IndexMaster[] = yield select(DashboardSelectors.selectIndicesMasterForPerformance);

                    if (indicesMaster && XIRRData) {
                        var activeIndex = indicesMaster.find(x => x.MProfitCode === XIRRData?.BenchmarkMProfitCode);
                        if (activeIndex) {
                            yield put(DashboardActions.setXIRRBenchmarkSingleIndex({
                                IndexItem: {
                                    AssetName: activeIndex.IndexName,
                                    MProfitCode: activeIndex.MProfitCode
                                },
                                NoRefresh: true
                            }));
                        }
                    }
                }

                if (action.payload.NoRefresh) break;
            } else {
                break;
            }
        }
    }
}

function* refreshXIRRTotalIndexReturns(XIRRData: XIRRTotalItem) {
    if (XIRRData && XIRRData.XIRR) {
        var startDate = convertUTCDateToAssetMastersFormat(XIRRData.PeriodFrom ? new Date(XIRRData.PeriodFrom) : new Date('1900-01-01'));
        var endDate = convertUTCDateToAssetMastersFormat(XIRRData.PeriodTo ? new Date(XIRRData.PeriodTo) : new Date());
        var IsOlderThan2012Return = false;
        if (new Date(XIRRData.PeriodFrom) < new Date(1970, 4, 1)) {
            if (XIRRData.FirstTransDate) {
                var firstTransDate = convertISTDateToSameDateUTC(XIRRData.FirstTransDate);
                if (firstTransDate < new Date(2012, 3, 1)) {
                    // var today = new Date();
                    // startDate = convertUTCDateToAssetMastersFormat(new Date(today.getFullYear() - 10, today.getMonth(), today.getDate() + 1));
                    IsOlderThan2012Return = true;
                }

                startDate = convertUTCDateToAssetMastersFormat(firstTransDate);
            } else {
                return;
            }
        }

        yield put(Actions.refreshXIRRTotalIndexReturns({ StartDate: startDate, EndDate: endDate, IsOlderThan2012Return }));
    }
}

interface performanceXirrTableAPIParams { Query: RefreshXIRRPayload; PortfolioATTY?: AssetTypeEnum[]; };

const performanceXirrTableAPI = ({ Query, PortfolioATTY }: performanceXirrTableAPIParams) => {
    return MProfitAPI.post('/api/Performance/XIRRTable', { PortfolioATTY }, {
        params: {
            xirrObjectID: Query.XIRRObjectID,
            ccid: Query.CCID,
            familyId: Query.FamilyId,
            pfid: Query.PFID,
            atty: Query.ATTY || -1,
            assetAllocationCategID: Query.AssetAllocationCategID,
            amid: Query.AMID || -1,
            sid: Query.SID || -1,
            periodFrom: Query.PeriodFrom,
            periodTo: Query.PeriodTo,
            force: Query.XIRRObjectID ? false : Query.Force,
            noRefresh: Query.NoRefresh === true,
            isGroupByPortfolio: Query.IsGroupByPortfolio,
            isCashMgmtEnabled: Query.IsCashMgmtEnabled
        }
    });
}


function* onRefreshXirrTable(action: PayloadAction<RefreshXIRRPayload>) {
    var isPerformanceViewAllowed: boolean = yield select(LicensingSelectors.selectIsPerformanceViewEnabled);
    if (!isPerformanceViewAllowed) {
        return;
    }

    var activePeriod: PeriodInfo | undefined = yield select(Selectors.selectActivePeriod);
    if (!activePeriod) return;

    var PortfolioATTY: AssetTypeEnum[] | undefined = yield select(Selectors.selectPortfolioATTY);

    if (activePeriod.IsAllToDate === true || (action.payload.ATTY && action.payload.ATTY > 0)) {
        PortfolioATTY = undefined;
    } else if (!PortfolioATTY) {
        return;
    }

    var IsCashMgmtEnabled: boolean = yield select(LicensingSelectors.selectIsPMSCashManagementEnabled);

    var xirrObjectID: string | undefined = undefined;
    var XIRRTable: XIRRTableItem | undefined = undefined;
    while (true) {
        if (XIRRTable !== undefined && XIRRTable.Status !== XIRRStatus.Processing && XIRRTable.Status !== XIRRStatus.Pending) {
            break
        } else {
            if (XIRRTable !== undefined) {
                yield delay(2000);
            }
            var apiParams: performanceXirrTableAPIParams = {
                Query: { ...action.payload, XIRRObjectID: xirrObjectID, PeriodFrom: activePeriod.PeriodFrom, PeriodTo: activePeriod.PeriodTo, IsCashMgmtEnabled },
                PortfolioATTY,
            };
            const response: ResponseGenerator = yield call(performanceXirrTableAPI, apiParams);
            if (response.status == HttpStatusCode.Ok) {
                XIRRTable = response.data;
                if (XIRRTable != null) {
                    xirrObjectID = XIRRTable.ID;
                }

                if (action.payload.IsGroupByPortfolio) {
                    yield put(Actions.setXIRRTablePortfolioWise(XIRRTable));
                } else {
                    yield put(Actions.setXIRRTable(XIRRTable));
                }

                if (action.payload.NoRefresh) break;
            } else {
                break;
            }
        }
    }

}

interface performanceXirrFYWiseAPIParams { Query: RefreshXIRRPayload; PortfolioATTY?: AssetTypeEnum[]; };

const performanceXirrFYWiseAPI = ({ Query, PortfolioATTY }: performanceXirrFYWiseAPIParams) => {
    return MProfitAPI.post('/api/Performance/XIRRFYWise', { PortfolioATTY }, {
        params: {
            xirrObjectID: Query.XIRRObjectID,
            ccid: Query.CCID,
            familyId: Query.FamilyId,
            pfid: Query.PFID,
            atty: Query.ATTY || -1,
            assetAllocationCategID: Query.AssetAllocationCategID,
            amid: Query.AMID || -1,
            force: Query.XIRRObjectID ? false : Query.Force,
            noRefresh: Query.NoRefresh === true,
            isCashMgmtPortfolio: Query.IsCashMgmtPortfolio || undefined
        }
    });
}


function* onRefreshXirrFYWise(action: PayloadAction<RefreshXIRRPayload>) {
    var isPerformanceViewAllowed: boolean = yield select(LicensingSelectors.selectIsPerformanceViewEnabled);
    if (!isPerformanceViewAllowed) {
        return;
    }

    var IsCashMgmtPortfolio: boolean = yield select(LicensingSelectors.selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck)

    var xirrObjectID: string | undefined = undefined;
    var XIRRFYWise: XIRRFYWiseItem | undefined = undefined;
    while (true) {
        if (XIRRFYWise !== undefined && XIRRFYWise.Status !== XIRRStatus.Processing && XIRRFYWise.Status !== XIRRStatus.Pending) {
            break;
        } else {
            if (XIRRFYWise !== undefined) {
                yield delay(2000);
            }
            var apiParams: performanceXirrFYWiseAPIParams = {
                Query: { ...action.payload, XIRRObjectID: xirrObjectID, IsCashMgmtPortfolio },
                PortfolioATTY: IsCashMgmtPortfolio ? undefined : [AssetTypeEnum.EQ, AssetTypeEnum.MFEQ],
            };
            const response: ResponseGenerator = yield call(performanceXirrFYWiseAPI, apiParams);
            if (response.status == HttpStatusCode.Ok) {
                XIRRFYWise = response.data;
                if (XIRRFYWise != null) {
                    xirrObjectID = XIRRFYWise.ID;
                }
                yield put(Actions.setXIRRFYWise(XIRRFYWise));

                if (action.payload.NoRefresh) break;
            } else {
                break;
            }
        }
    }

    if (XIRRFYWise && XIRRFYWise.FinancialYearWiseGroupData) {
        var timePeriodsForIndex: AM_PeriodRequest[] = [];
        for (var fyWiseItem of XIRRFYWise.FinancialYearWiseGroupData) {
            var startDate = convertUTCDateToAssetMastersFormat(new Date(fyWiseItem.PeriodFrom));
            var endDate = convertUTCDateToAssetMastersFormat(new Date(fyWiseItem.PeriodTo));
            if (new Date(fyWiseItem.PeriodFrom) < new Date(1970, 4, 1)) {
                if (fyWiseItem.FirstTransDate) {
                    startDate = convertUTCDateToAssetMastersFormat(convertISTDateToSameDateUTC(fyWiseItem.FirstTransDate));
                } else {
                    continue;
                }
            }
            timePeriodsForIndex.push({
                StartDate: startDate,
                EndDate: endDate
            })
        }

        if (timePeriodsForIndex.length > 0) {
            yield put(Actions.refreshXIRRFYWiseIndexReturns(timePeriodsForIndex));
        }
    }
}

interface performanceXirrYearWiseAPIParams { Query: RefreshXIRRPayload; PortfolioATTY?: AssetTypeEnum[]; TimePeriods: TimePeriodFromEndDate[] };

const performanceXirrYearWiseAPI = ({ Query, PortfolioATTY, TimePeriods }: performanceXirrYearWiseAPIParams) => {
    return MProfitAPI.post('/api/Performance/XIRRYearWise', { PortfolioATTY, TimePeriods }, {
        params: {
            xirrObjectID: Query.XIRRObjectID,
            ccid: Query.CCID,
            familyId: Query.FamilyId,
            pfid: Query.PFID,
            atty: Query.ATTY || -1,
            assetAllocationCategID: Query.AssetAllocationCategID,
            amid: Query.AMID || -1,
            force: Query.XIRRObjectID ? false : Query.Force,
            noRefresh: Query.NoRefresh === true,
            isCashMgmtPortfolio: Query.IsCashMgmtPortfolio || undefined,
        }
    });
}


function* onRefreshXirrYearWise(action: PayloadAction<RefreshXIRRPayload>) {
    var isPerformanceViewAllowed: boolean = yield select(LicensingSelectors.selectIsPerformanceViewEnabled);
    if (!isPerformanceViewAllowed) {
        return;
    }

    var IsCashMgmtPortfolio: boolean = yield select(LicensingSelectors.selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck)

    var xirrObjectID: string | undefined = undefined;
    var XIRRYearWise: XIRRYearWiseItem | undefined = undefined;
    var timePeriods: TimePeriodFromEndDate[] = [
        { Year: 5, Month: 0, Day: 0 },
        { Year: 3, Month: 0, Day: 0 },
        { Year: 2, Month: 0, Day: 0 },
        { Year: 1, Month: 0, Day: 0 },
        { Year: -1, Month: -1, Day: -1 },
    ];
    while (true) {
        if (XIRRYearWise !== undefined && XIRRYearWise.Status !== XIRRStatus.Processing && XIRRYearWise.Status !== XIRRStatus.Pending) {
            break;
        } else {
            if (XIRRYearWise !== undefined) {
                yield delay(2000);
            }
            var apiParams: performanceXirrYearWiseAPIParams = {
                Query: { ...action.payload, XIRRObjectID: xirrObjectID, IsCashMgmtPortfolio },
                PortfolioATTY: IsCashMgmtPortfolio ? undefined : [AssetTypeEnum.EQ, AssetTypeEnum.MFEQ],
                TimePeriods: timePeriods
            };
            const response: ResponseGenerator = yield call(performanceXirrYearWiseAPI, apiParams);
            if (response.status == HttpStatusCode.Ok) {
                XIRRYearWise = response.data;
                if (XIRRYearWise != null) {
                    xirrObjectID = XIRRYearWise.ID;
                }

                yield put(Actions.setXIRRYearWise(XIRRYearWise));

                if (action.payload.NoRefresh) break;
            } else {
                break;
            }
        }
    }

    if (XIRRYearWise && XIRRYearWise.YearWiseGroupData) {
        var timePeriodsForIndex: AM_PeriodRequest[] = [];
        for (var yearWiseItem of XIRRYearWise.YearWiseGroupData) {
            var startDate = convertUTCDateToAssetMastersFormat(new Date(yearWiseItem.PeriodFrom));
            var endDate = convertUTCDateToAssetMastersFormat(new Date(yearWiseItem.PeriodTo));
            if (new Date(yearWiseItem.PeriodFrom) < new Date(1970, 4, 1)) {
                if (yearWiseItem.FirstTransDate) {
                    startDate = convertUTCDateToAssetMastersFormat(convertISTDateToSameDateUTC(yearWiseItem.FirstTransDate));
                } else {
                    continue;
                }
            }
            timePeriodsForIndex.push({
                StartDate: startDate,
                EndDate: endDate
            })
        }

        if (timePeriodsForIndex.length > 0) {
            yield put(Actions.refreshXIRRYearWiseIndexReturns(timePeriodsForIndex));
        }
    }
}

function* refreshActiveXIRR() {
    var activeScreenIDs: ActiveScreenPayload = yield select(PortfolioSelectors.selectActiveScreenIDs);
    var refreshXIRRPayload = {
        Force: false,
        NoRefresh: false,
        CCID: activeScreenIDs.DatabaseID || -1,
        FamilyId: activeScreenIDs.FamilyID || -1,
        PFID: activeScreenIDs.PortfolioID || -1,
        ATTY: activeScreenIDs.AssetType || -1,
        AssetAllocationCategID: activeScreenIDs.AssetAllocationCategID,
        AMID: activeScreenIDs.AMID || -1,
        SID: activeScreenIDs.SID || -1
    }

    if (refreshXIRRPayload.CCID > 0 && refreshXIRRPayload.FamilyId > 0 && refreshXIRRPayload.PFID > 0) {
        yield put(Actions.refreshXIRRTotal(refreshXIRRPayload));
    }
}

function* performanceSaga() {
    yield takeLatest(Actions.refreshXIRRTotal.type, onRefreshXirrData);
    yield takeLatest(Actions.refreshXIRRTable.type, onRefreshXirrTable);
    yield takeLatest(Actions.refreshXIRRTablePortfolioWise.type, onRefreshXirrTable);
    yield takeLatest(Actions.refreshXIRRFYWise.type, onRefreshXirrFYWise);
    yield takeLatest(Actions.refreshXIRRYearWise.type, onRefreshXirrYearWise);
    yield takeLatest(Actions.refreshXIRRBenchmark.type, onRefreshXirrBenchmark);
    yield takeLatest(Actions.setActivePeriod.type, refreshActiveXIRR);
    yield takeLatest(Actions.setPortfolioATTY.type, refreshActiveXIRR);
    yield takeLatest(Actions.getCashMgmtDetails.type, getCashMgmtDetails);
}

export default performanceSaga;
