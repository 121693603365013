import { MPrUrlParams } from 'packages/web/src/classes/MPrUrlParams';
import L1_Today from './level_1';
import L2_Today from './level_2';
import { SharedConstants } from '@mprofit/shared';
import L4_Today from './level_4';
import L3_Today from './level_3';
import L1_AA_Today from './level_1_aa';
import L3_Category_Today from './level_3_category';

const Today = (props: { MPrUrlParams: MPrUrlParams }) => {
  const mprUrlParams = props.MPrUrlParams || new MPrUrlParams();

  // return <L4_Today />

  switch (mprUrlParams.Screen) {
    default:
    case SharedConstants.ScreenEnum.L1:
      return <L1_Today MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L1_AA:
      return <L1_AA_Today MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L2:
      return <L2_Today MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L3_Category:
      return <L3_Category_Today MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L3:
      return <L3_Today MPrUrlParams={mprUrlParams} />
    case SharedConstants.ScreenEnum.L4:
    case SharedConstants.ScreenEnum.L4_RefNo:
      return <L4_Today />
  }

}

export default Today;

export const todayStyles = {
  todayContainer: {
    padding: '0px 40px',
    marginBottom: '62px'
  },
  leftContainer: {
    display: 'grid',
    gridTemplateColumns: { xs: '58% 40%',md: '58.5% 40%',lg: '57% 40%',lg1: '57.5% 40%', xl: '68% 30.3%' },
    gridGap: { xs: '15px', lg: '24px', xl: '24px' },
  },
  leftContainerFullWidth: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: { xs: '15px', lg: '24px', xl: '24px' }
  },
  rightContainer: {
    gridRow: { xs: '2 / 3', xl: '1 / 3' },
    gridColumn: '2',
    marginLeft: { xs: '-3px',lg: '-3px',lg1: '0px', xl: '0px' }
  },
  portfolioPerformance: {
    display: 'flex',
    width: {xs:'99%',md: '99%', lg:'99.5%',lg1: '99.7%', xl:'100%'},
    gridRow: { xs: '2 / 3', xl: 'none' }
  },
  topCards: {
    display: 'grid',
    gridGap: '24px',
    gridColumn: { xs: '1 / 3', xl: 1 }
  }
}