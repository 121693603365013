export function MoneyHandIcon(props: any) {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
  <g id="Group_151803" data-name="Group 151803" transform="translate(-1009 -679)">
    <g id="Group_149319" data-name="Group 149319" transform="translate(1009.184 679)">
      <rect id="Rectangle_23692" data-name="Rectangle 23692" width="48" height="48" rx="8" transform="translate(-0.184)" fill="#f5f5f6"/>
    </g>
    <g id="_20a803a93fe3bd982fcf02d6c47cfa96" data-name="20a803a93fe3bd982fcf02d6c47cfa96" transform="translate(1020.5 690.514)">
      <path id="Path_198519" data-name="Path 198519" d="M27.692,28.817a1.24,1.24,0,0,0-1.761,0L20.8,33.952H14.046v-.978h5.625a1.223,1.223,0,1,0,0-2.445H16.443l-.147-.2a3.79,3.79,0,0,0-6.065,0l-.147.2H9.4v5.918H21.334a1.406,1.406,0,0,0,.88-.342l5.478-5.478A1.4,1.4,0,0,0,27.692,28.817Z" transform="translate(-4.547 -14.28)"/>
      <path id="Path_198520" data-name="Path 198520" d="M.5,30.1v8.51H3.875V30.1Z" transform="translate(0 -15.123)"/>
      <path id="Path_198521" data-name="Path 198521" d="M23.458.5a6.358,6.358,0,1,0,6.358,6.358A6.346,6.346,0,0,0,23.458.5Zm3.081,3.326H25.463a3.917,3.917,0,0,1,.489,1.125h.587v.978h-.587a2.564,2.564,0,0,1-2.494,2.054H21.893l3.032,3.081-.685.685L20.475,7.983h-.049V7.934l-.1-.1.1-.1V7h2.983a1.635,1.635,0,0,0,1.516-1.076H20.719V4.951h4.206a1.543,1.543,0,0,0-1.516-1.076H20.426V2.9h6.065v.929Z" transform="translate(-8.481 0)"/>
    </g>
  </g>
</svg>

    );
  }
  