export function CashInIcon_AllToDate(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
            <g id="Group_149319" data-name="Group 149319" transform="translate(0 -0.5)">
                <rect id="Rectangle_23692" data-name="Rectangle 23692" width="56" height="56" rx="8" transform="translate(0 0.5)" fill="#fff"/>
                <g id="Group_228719" data-name="Group 228719" transform="translate(11.653 14.877)">
                <path id="Path_272289" data-name="Path 272289" d="M11.449,13.265H9.633A3.643,3.643,0,0,1,6,9.633H6A3.643,3.643,0,0,1,9.633,6H35.062a3.643,3.643,0,0,1,3.633,3.633h0a3.643,3.643,0,0,1-3.633,3.633H33.246" transform="translate(-6 -6)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <path id="Path_272290" data-name="Path 272290" d="M33.613,10V29.98h-21.8V10M10,10H35.429" transform="translate(-6.367 -6.367)" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <g id="Group_228678" data-name="Group 228678" transform="translate(12.361 7.265)">
                    <path id="Path_272292" data-name="Path 272292" d="M25.986,36V47.955m3.986-7.97L25.986,36,22,39.985" transform="translate(-22 -36)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                </g>
                </g>
            </g>
        </svg>
    );
}

export function CashInIcon_Periodwise(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_229289" data-name="Group 229289" transform="translate(0 -0.5)">
                <rect id="Rectangle_23692" data-name="Rectangle 23692" width="48" height="48" rx="8" transform="translate(0 0.5)" fill="#f5f5f6"/>
                <g id="Group_228719" data-name="Group 228719" transform="translate(9.988 12.824)">
                <path id="Path_272289" data-name="Path 272289" d="M10.671,12.228H9.114A3.123,3.123,0,0,1,6,9.114H6A3.123,3.123,0,0,1,9.114,6h21.8a3.123,3.123,0,0,1,3.114,3.114h0a3.123,3.123,0,0,1-3.114,3.114H29.353" transform="translate(-6 -6)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <path id="Path_272290" data-name="Path 272290" d="M30.24,10V27.126H11.557V10M10,10H31.8" transform="translate(-6.886 -6.886)" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <g id="Group_228678" data-name="Group 228678" transform="translate(10.595 6.227)">
                    <path id="Path_272292" data-name="Path 272292" d="M25.416,36V46.248m3.416-6.832L25.416,36,22,39.416" transform="translate(-22 -36)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                </g>
                </g>
            </g>
        </svg>
    );
}