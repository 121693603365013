import { CompleteChartPage, PdfBarChart } from "@mprofit-soft/pdf";
import { DownloadPDF, ExtraHeaderProps, ExtraOverviewProps } from "../../../download-pdf";
import { ChartsSelectors } from "packages/web/src/redux/selectors/chartsSelectors";
import { usePDFSelector } from "../../../download-pdf/redux";

export const XIRRBenchmarkChartPDFPage = ({ headerProps, overviewProps}: {
        headerProps: ExtraHeaderProps;
        overviewProps: ExtraOverviewProps;
    }) => {
    const {chartData, domain} = usePDFSelector(ChartsSelectors.selectXIRRBenchmarkChartData);

    if (!chartData) {
        return <></>;
    }

    return <CompleteChartPage
            headerProps={{
                ...headerProps,
                reportType: "Performance",
            }}
            overviewProps={{
                ...overviewProps,
                heading: "XIRR Comparison",
                subHeading: "This chart shows how your XIRR compares against equivalent cashflows in the index"
            }}
            ChartComponent={(extraChartProps) =>
            <PdfBarChart
                {...extraChartProps}
                chartData={chartData?? []}
                barSizeType="large"
                isFirstBarBlack={true}
                axisDomain={domain}
            />
        }
    />
}

export const XIRRBenchmarkChartPDFDownloadButton = () => {
    return <DownloadPDF
        PDFPage={
            XIRRBenchmarkChartPDFPage
        }
        ReportNameForFileName="XIRR Comparison"
    />
}