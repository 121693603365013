import { BackgroundJobsDetailed, BulkCorporateActionMode, Job_Status_Code, Job_Type, MastersMgmtJobType, Status_Color_Class } from "../constants";
import { getIsMprMinDate } from "./shared";
import dateFormat from "dateformat";
import { getPortfolioMappingFieldToUseText } from "./mastersMgmt";

export const getBackgroundJobDetailedExtraFields = (jobDetailedRaw: BackgroundJobsDetailed, isUndoAllowed: {[JobType: number]: boolean}) => {
    var jobDetailed: BackgroundJobsDetailed = {...jobDetailedRaw};
    jobDetailed.isUndoAllowed = isUndoAllowed || {};
    jobDetailed.PeriodLabel = 'Period';
    jobDetailed.JobUserID = jobDetailed.IsMyJob ? -1 : jobDetailed.UserID;

    const _Date = new Date(jobDetailed.Date);
    const _FromPeriod = jobDetailed.FromPeriod ? new Date(jobDetailed.FromPeriod) : undefined;
    const _ToPeriod = jobDetailed.ToPeriod ? new Date(jobDetailed.ToPeriod) : undefined;

    if (!isHidePeriodText(jobDetailed.JobType)) {
        if (jobDetailed.JobType == Job_Type.AutoCorpActionJob && jobDetailed.IsNewPeriod) {
            jobDetailed.PeriodText = 'All New Corporate Actions';
        } else if (jobDetailed.JobType == Job_Type.RecalculationJob) {
            if (jobDetailed.IsFnoSetPeriod) {
                jobDetailed.PeriodText = getIsMprMinDate(_FromPeriod) ? 'Inception' : dateFormat(_FromPeriod,'d/m/yyyy');
                jobDetailed.PeriodLabel = 'Period From';
            }
        } else if (!jobDetailed.IsPeriodSet && useIsPeriodSet(jobDetailed.JobType)) {
            jobDetailed.PeriodText = 'All to Date';
        } else {
            jobDetailed.PeriodText = jobDetailed.IsAsOnDate ? dateFormat(_ToPeriod,'d/m/yyyy') : ((_FromPeriod == undefined || _FromPeriod <= new Date('1970-01-01') ? 'All' : dateFormat(_FromPeriod,'d/m/yyyy')) + ' - ' + dateFormat(_ToPeriod == undefined ? _Date : _ToPeriod,'d/m/yyyy'));
        }
    }

    setBackgroundJobStatusFields(jobDetailed);

    setBackgroundJobDetails(jobDetailed);

    return jobDetailed;
}

function isHidePeriodText(JobType: Job_Type) {
    return [Job_Type.MastersMgmtJob,Job_Type.CopyPortfolioJob,Job_Type.DBMigrationJob].includes(JobType);
}

function useIsPeriodSet(JobType: Job_Type) {
    return [Job_Type.BulkDeleteJob].includes(JobType);
}

export function setBackgroundJobStatusFields(jobDetailed: BackgroundJobsDetailed) {
    jobDetailed.ActionText = undefined;
    jobDetailed.IsForwardAction = false;
    jobDetailed.RunActionOnJobOpen = false;
    jobDetailed.ShowUndo = false;
    jobDetailed.HideActionFromJobModal = false;
    jobDetailed.UndoText = 'Undo';
    jobDetailed.ShowBackToPortfolio = false;
    jobDetailed.ShowViewCompleted = false;
    jobDetailed.ViewCompletedText = 'View Details';

    switch(jobDetailed.StatusCode) {
        case Job_Status_Code.Processing:
        case Job_Status_Code.Pending:
            jobDetailed.StatusText = getProcessingText(jobDetailed.JobType, jobDetailed.Mode);
            jobDetailed.StatusColorClass = Status_Color_Class.YELLOW;
            jobDetailed.ActionText = undefined;
            break;
        case Job_Status_Code.Error:
            jobDetailed.StatusText = "Error";
            jobDetailed.StatusColorClass = Status_Color_Class.RED;
            jobDetailed.ActionText = undefined;
            break;
        case Job_Status_Code.Completed:
            jobDetailed.StatusText = "Complete";
            jobDetailed.StatusColorClass = Status_Color_Class.GREEN;
            jobDetailed.ActionText = getCompletedActionText(jobDetailed.JobType, jobDetailed.isUndoAllowed);
            var { IsForwardAction, RunActionOnJobOpen, ShowUndo, ShowBackToPortfolio, UndoText, HideActionFromJobModal, ShowViewCompleted, ViewCompletedText } = isCompleteForwardAction(jobDetailed.JobType);
            jobDetailed.IsForwardAction = IsForwardAction;
            jobDetailed.RunActionOnJobOpen = RunActionOnJobOpen;
            jobDetailed.ShowBackToPortfolio = ShowBackToPortfolio == true;
            jobDetailed.ShowUndo = ShowUndo == true;
            jobDetailed.ShowViewCompleted = ShowViewCompleted == true;
            jobDetailed.HideActionFromJobModal = HideActionFromJobModal == true;
            jobDetailed.UndoText = UndoText || 'Undo';
            jobDetailed.ViewCompletedText = ViewCompletedText || 'View Details';
            break;
        case Job_Status_Code.PVIEW:
            jobDetailed.StatusText = "Preview Available";
            jobDetailed.StatusColorClass = Status_Color_Class.GREEN;
            jobDetailed.ActionText = "Preview Data";
            jobDetailed.IsForwardAction = true;
            break;
        case Job_Status_Code.Cancelled:
            jobDetailed.StatusText = "Cancelled";
            jobDetailed.StatusColorClass = Status_Color_Class.NEUTRAL;
            break;
        case Job_Status_Code.Reversed:
            jobDetailed.StatusText = "Undo Complete";
            jobDetailed.StatusColorClass = Status_Color_Class.NEUTRAL;
            jobDetailed.ShowBackToPortfolio = true;
            break;
        case Job_Status_Code.No_Access:
            jobDetailed.StatusText = getNoAccessText(jobDetailed.JobType, jobDetailed.Mode);
            jobDetailed.StatusColorClass = Status_Color_Class.RED;
            jobDetailed.ActionText = "View Details";
            jobDetailed.IsForwardAction = true;
            break;
        case Job_Status_Code.Invalid_Field:
            jobDetailed.StatusText = getInvalidFieldText(jobDetailed.JobType);
            jobDetailed.StatusColorClass = Status_Color_Class.RED;
            jobDetailed.ActionText = "View Details";
            jobDetailed.IsForwardAction = true;
            break;
        case Job_Status_Code.File_Not_Supported:
            jobDetailed.StatusText = "Incorrect File Uploaded";
            jobDetailed.StatusColorClass = Status_Color_Class.RED;
            break;
        case Job_Status_Code.Duplicate_Values:
            jobDetailed.StatusText = "Duplicate Values Found";
            jobDetailed.StatusColorClass = Status_Color_Class.RED;
            jobDetailed.ActionText = "View Details";
            jobDetailed.IsForwardAction = true;
            break;
        case Job_Status_Code.License_Expired:
            jobDetailed.StatusText = "License Expired";
            jobDetailed.StatusColorClass = Status_Color_Class.RED;
            jobDetailed.ActionText = "Try Again";
            jobDetailed.IsForwardAction = true;
            break;
        case Job_Status_Code.UNDO_SPECIFIC_ERR:
        case Job_Status_Code.UNDO_ERR:
            jobDetailed.StatusText = "Undo Error";
            jobDetailed.StatusColorClass = Status_Color_Class.RED;
            jobDetailed.ActionText = "View Details";
            jobDetailed.IsForwardAction = true;
            break;
        case Job_Status_Code.License_Not_Supported:
            jobDetailed.StatusText = "License Error";
            jobDetailed.StatusColorClass = Status_Color_Class.RED;
            jobDetailed.ActionText = "View Details";
            jobDetailed.IsForwardAction = true;
            break;
    }
}

function setBackgroundJobDetails(jobDetailed: BackgroundJobsDetailed) {
    switch(jobDetailed.JobType) {
        case Job_Type.BulkCorpActionJob:
            jobDetailed.Details = jobDetailed.AssetName;
            jobDetailed.ExtraFieldLabel = 'Source: ';
            jobDetailed.ExtraFieldValue = jobDetailed.CorpActionID > 0 ? 'MProfit' : 'User Input';
            break;
        case Job_Type.MastersMgmtJob:
            if (jobDetailed.MastersMgmtJobType == MastersMgmtJobType.ADD_PORTFOLIO_MAPPINGS_FROM_PORTFOLIO_TABLE) {
                var fieldToUse = getPortfolioMappingFieldToUseText(jobDetailed.PortfolioMappingFieldToUse);
                jobDetailed.Details = jobDetailed.FullTemplateGroupName + (fieldToUse ? ' - Field: ' + fieldToUse : null);
            } else if (jobDetailed.MastersMgmtJobType == MastersMgmtJobType.ADD_PORTFOLIO_MAPPINGS_BY_PORTFOLIO_NAME || jobDetailed.MastersMgmtJobType == MastersMgmtJobType.ADD_PORTFOLIO_MAPPINGS_BY_CODE) {
                jobDetailed.Details = jobDetailed.FullTemplateGroupName;
            }
            break;
        case Job_Type.CopyPortfolioJob:
            jobDetailed.Details = `${(jobDetailed.DeleteOldOnCompletion ? 'Move' : 'Copy')} portfolio ${jobDetailed.FromPortfolioName} from family ${jobDetailed.FromFamilyName} to family ${jobDetailed.ToFamilyName}`
            break;
        case Job_Type.BulkDeleteJob:
            if (jobDetailed.IsAssetClassLevel) {
                if (jobDetailed.AssetType) {
                    jobDetailed.Details = 'Asset Class: ' + jobDetailed.AssetType;
                }
            } else {
                if (jobDetailed.AssetName) {
                    jobDetailed.Details = 'Asset: ' + jobDetailed.AssetName + (jobDetailed.AssetType ? ', Asset Class: ' + jobDetailed.AssetType : '');
                }
            }
            break;
        case Job_Type.DBMigrationJob:
            jobDetailed.JobTypeText = 'Account Update';
            break;
    }
}

function getCompletedActionText(JobType: Job_Type, isUndoAllowed: {[JobType: number]: boolean}) {
    switch (JobType) {
        default:
            return '';
        case Job_Type.GlobalReportJob:
            return "Download Report";
        case Job_Type.BulkCorpActionJob:
            return isUndoAllowed[Job_Type.BulkCorpActionJob] ? "Undo" : "View Details";
        case Job_Type.ReportJob:
            return "View Report";
    }
}

function getProcessingText(JobType: Job_Type, Mode: number) {
    switch(JobType) {
        default:
            return "In Process";
        case Job_Type.BulkCorpActionJob:
        case Job_Type.AutoCorpActionJob:
            switch(Mode) {
                default:
                    return "In Process";
                case BulkCorporateActionMode.Save:
                    return "Saving Records";
                case BulkCorporateActionMode.Undo:
                    return "Undo In Process";
            }
    }
}

function getNoAccessText(JobType: Job_Type, Mode: number) {
    switch(JobType) {
        default:
            return "No Access";
        case Job_Type.BulkCorpActionJob:
            switch(Mode) {
                default:
                    return "In Process";
                case BulkCorporateActionMode.Undo:
                    return "Undo No Access";
            }
    }
}

function getInvalidFieldText(JobType: Job_Type) {
    switch(JobType) {
        default:
            return "Invalid Field";
        case Job_Type.MastersMgmtJob:
            return "Incorrect File Uploaded";
            break;
    }
}

function isCompleteForwardAction(JobType: Job_Type) {
    switch (JobType) {
        default:
            return { IsForwardAction: false, RunActionOnJobOpen: false };
        case Job_Type.GlobalReportJob:
            return { IsForwardAction: false, RunActionOnJobOpen: true };
        case Job_Type.ReportJob:
            return { IsForwardAction: true, RunActionOnJobOpen: true };
        case Job_Type.BulkCorpActionJob:
        case Job_Type.AutoCorpActionJob:
            return { IsForwardAction: true, RunActionOnJobOpen: true, ShowUndo: false, UndoText: 'Undo', ShowViewCompleted: true, ShowBackToPortfolio: true, ViewCompletedText: 'View Saved Records', HideActionFromJobModal: true };
    }
}