
export function GoalPortfolioIcon_FamilyView(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_150676" data-name="Group 150676" transform="translate(-4 -4)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="24" height="24" transform="translate(4 4)" fill="#fff" opacity="0"/>
          <g id="Group_150685" data-name="Group 150685" transform="translate(15.738 -1.524)">
            <path id="Path_198004" data-name="Path 198004" d="M12.758,2a10.684,10.684,0,0,0-2.852.4l.517,1.248a9.442,9.442,0,1,1-6.711,6.711L2.463,9.832A10.737,10.737,0,1,0,12.758,2Z" transform="translate(-12.8 4.525)" fill="#64677a"/>
            <path id="Path_198005" data-name="Path 198005" d="M7.368,12.9a7.255,7.255,0,1,0,5.57-5.543l.416,1.047A6.2,6.2,0,1,1,8.408,13.32Z" transform="translate(-14.49 2.814)" fill="#64677a"/>
            <path id="Path_198006" data-name="Path 198006" d="M16.148,18.873a2.738,2.738,0,0,1-2.684-2.47l-1.094-.456v.181a3.819,3.819,0,1,0,3.778-3.819h-.161l.45,1.134a2.731,2.731,0,0,1-.289,5.429Z" transform="translate(-16.19 1.134)" fill="#64677a"/>
            <path id="Path_198007" data-name="Path 198007" d="M6.328,9.751a.336.336,0,0,0,.235.1H9.012l3.6,3.6a.676.676,0,1,0,.96-.953L9.9,8.851V6.509a.336.336,0,0,0-.1-.235L6.052,2.523a.336.336,0,0,0-.57.235V5.442H2.838a.336.336,0,0,0-.235.57Z" transform="translate(-12.946 4.385)" fill="#64677a"/>
          </g>
        </g>
      </svg>

    );
  }
  