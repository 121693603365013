import { Box, Grid, styled } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import CustomTypography from '../../customTypography'
import { SharedConstants, SharedUtilities } from '@mprofit/shared';

interface IYearsProps {
    setSelectedYear?: (props: SetYearProps) => void;
    startYearNumber?: number;
    setYearSelectClosed?: () => void;
}

export interface SetYearProps {
    startDate: Date;
    endDate: Date;
    title: string;
    yearNumber: number;
}

export default function Year({ setSelectedYear, startYearNumber, setYearSelectClosed }: IYearsProps) {
    const now = new Date();
    const currentYear = now.getFullYear();

    const [selectedYearNumber, setSelectedYearNumber] = useState<number>(startYearNumber || currentYear);

    const yearsList = useMemo(SharedUtilities.getYearListForPeriodSelection, []);

    const onSelectYear = (yearNumber: number) => {
        setSelectedYearNumber(yearNumber);

        onYearChange(yearNumber);
    }

    const onYearChange = (yearNumber: number, isMount: boolean = false) => {
        var newSelectedYear = SharedUtilities.getYearSelectedItem(yearsList, yearNumber);

        if (newSelectedYear && setSelectedYear) {
            setSelectedYear(newSelectedYear);

            if (!isMount && setYearSelectClosed) {
                setYearSelectClosed();
            }
        }
    }

    useEffect(() => {
        onYearChange(selectedYearNumber, true);
    }, []);

    return (
        <ControlledYear
            onSelectYear={onSelectYear}
            selectedYearNumber={selectedYearNumber}
            yearsList={yearsList}
        />
    )
}

interface ControlledYearProps {
    selectedYearNumber: number;
    onSelectYear: (yearNumber: number) => void;
    yearsList: SharedConstants.YearListItem[];
}

export const ControlledYear = ({selectedYearNumber, onSelectYear, yearsList}: ControlledYearProps) => {

    return (
        <Grid container spacing={2} >
            <Grid item xs={12}>
                <Grid spacing={1} container maxHeight={340} sx={{
                    overFlowY: 'auto', overflowX: 'hidden', '::-webkit-scrollbar': { width: '1px' }
                }}>
                    {yearsList.map((yearItem, index) => {
                        return (
                            <Grid item xs={4} key={yearItem.index} >
                                <YearContainer sx={selectedYearNumber !== yearItem.year ? {
                                    backgroundColor: "#F9F9F9",
                                    cursor: 'pointer',
                                    ":hover": {
                                        background: '#EAF4DF'
                                    }
                                } : {
                                    backgroundColor: "#5F6F57",
                                    cursor: 'pointer'
                                }} onClick={() => onSelectYear(yearItem.year)}>
                                    <CustomTypography variant="Regular20" sx={{ color: { xs: selectedYearNumber === yearItem.year ? "#FFFFFF" : "#000000" } }}>{yearItem.title}</CustomTypography>
                                </YearContainer>
                            </Grid>
                        )
                    })
                    }
                </Grid>
            </Grid>
        </Grid >
    )
}

const YearContainer = styled(Grid)({
    background: '#F9F9F9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0px',
    borderRadius: '4px',
    cursor: 'pointer'
});