import { SharedConstants, SharedRedux, formatDateForReading } from '@mprofit/shared';
import { Box, Grid } from '@mui/material'
import { CustomTypography } from 'packages/web/src/components/shared'
import DoughnutPieChart from 'packages/web/src/components/shared/doughnut-pie-chart.tsx'
import DropDown, { DropdownItem } from 'packages/web/src/components/shared/drop-down'
import { useAppSelector } from 'packages/web/src/redux/hooks';
import { Big } from 'big.js';

interface L4HoldingPieChartProps {
    AssetTypeID: SharedConstants.AssetTypeEnum | undefined,
    StocksSectorInfo: { SectorID: number; SectorName: string; } | undefined,
    CurrValue: string | undefined,
    InvAmt: string | undefined,
    TotalField: SharedConstants.HoldingAssetAllocationDropdownValue;
    OnDropdownChange?: (selected: DropdownItem) => void,
    DropdownList?: DropdownItem[];
}


export default function L4HoldingPieChart({ AssetTypeID, StocksSectorInfo, CurrValue, InvAmt, TotalField, DropdownList, OnDropdownChange }: L4HoldingPieChartProps) {
    // const assetName

    const AssetTotalValue_Big = Big((TotalField == 'CurrValue' ? CurrValue : InvAmt) || 0);

    const assetName = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4AssetName);
    const refNum = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4Refno);

    const showSectorChart = AssetTypeID == SharedConstants.AssetTypeEnum.EQ && StocksSectorInfo != undefined && StocksSectorInfo.SectorID > 0;

    return (
        <Grid container sx={{ padding: '24px 32px', paddingBottom: '0' }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <CustomTypography variant='Medium20'>{assetName + (refNum ? ' (#' + refNum + ')' : '')}</CustomTypography>
                    <CustomTypography sx={{ color: { xs: '#64677A' } }}>as on {formatDateForReading(new Date())}</CustomTypography>
                </Box>
                {
                    DropdownList && OnDropdownChange &&
                    <Box>
                        <DropDown inputValue={TotalField} inputList={DropdownList} handleChange={OnDropdownChange} />
                    </Box>
                }
            </Grid>
            <Grid item xs={12}>
                <Grid container height="317px">
                    <Grid item xs={showSectorChart ? 4 : 6} display='flex' width={"100%"}>
                        <L4HoldingOfPortfolioChart AssetTotalValue_Big={AssetTotalValue_Big} TotalField={TotalField} />
                    </Grid>
                    <Grid item xs={showSectorChart ? 4 : 6} display='flex' width={"100%"}>
                        <L4HoldingOfAssetTypeChart AssetTotalValue_Big={AssetTotalValue_Big} TotalField={TotalField} IsSectorChartShown={showSectorChart} />
                    </Grid>
                    {
                        showSectorChart &&
                        <Grid item xs={showSectorChart ? 4 : 6} display='flex' width={"100%"}>
                            <L4HoldingOfStockSectorChart AssetTotalValue_Big={AssetTotalValue_Big} TotalField={TotalField} StocksSectorInfo={StocksSectorInfo} />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid >
    )
}

const L4HoldingOfPortfolioChart = ({ AssetTotalValue_Big, TotalField }: { AssetTotalValue_Big: Big, TotalField: SharedConstants.HoldingAssetAllocationDropdownValue }) => {
    const PortfolioTotalValue = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4PercentOfTotal_Portfolio(TotalField));
    const PortfolioTotalValue_Big = Big(PortfolioTotalValue || 0);
    const PercentValue_Big = PortfolioTotalValue_Big.gt(0) ? AssetTotalValue_Big.div(PortfolioTotalValue_Big) : Big(0);
    return <L4HoldingChart
        Name={'% of Portfolio'}
        PercentValue={PercentValue_Big}
        ChartColors={['#7bb8d1', '#afd4e4']}
        ShowSeparator={true}
    />
}

const L4HoldingOfAssetTypeChart = ({ AssetTotalValue_Big, TotalField, IsSectorChartShown }: { AssetTotalValue_Big: Big, TotalField: SharedConstants.HoldingAssetAllocationDropdownValue, IsSectorChartShown: boolean }) => {
    const AssetTypeTotalValue = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4PercentOfTotal_AssetType(TotalField));
    const AssetTypeTotalValue_Big = Big(AssetTypeTotalValue || 0);
    const PercentValue_Big = AssetTypeTotalValue_Big.gt(0) ? AssetTotalValue_Big.div(AssetTypeTotalValue_Big) : Big(0);
    const assetTypeIDName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetTypeIDName);
    const categName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategName);
    // const categNameSuffix = categName && categName.length > 0 ? ` (${categName})` : '';

    return <L4HoldingChart
        Name={`% of ${assetTypeIDName.AssetTypeName}`}
        PercentValue={PercentValue_Big}
        ChartColors={['#bda5eb', '#d8caf3']}
        ShowSeparator={IsSectorChartShown}
    />
}

const L4HoldingOfStockSectorChart = ({ AssetTotalValue_Big, TotalField, StocksSectorInfo }: { AssetTotalValue_Big: Big, TotalField: SharedConstants.HoldingAssetAllocationDropdownValue, StocksSectorInfo: { SectorID: number; SectorName: string; } }) => {
    const SectorTotalValue = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4PercentOfTotal_Sector(TotalField, StocksSectorInfo.SectorID));
    if (SectorTotalValue == undefined) return <></>
    const SectorTotalValue_Big = Big(SectorTotalValue || 0);
    const PercentValue_Big = SectorTotalValue_Big.gt(0) ? AssetTotalValue_Big.div(SectorTotalValue_Big) : Big(0);
    return <L4HoldingChart
        Name={`% of ${StocksSectorInfo.SectorName || 'Sector'}`}
        PercentValue={PercentValue_Big}
        ChartColors={['#ffb799', '#ffd4c2']}
        ShowSeparator={false}
    />
}

const L4HoldingChart = ({ ChartColors, Name, PercentValue, ShowSeparator }: { Name: string, PercentValue: Big, ChartColors: [string, string], ShowSeparator: boolean }) => {
    return (
        <>
            <DoughnutPieChart Name={Name} PercentValue={PercentValue} Colors={ChartColors} />
            {
                ShowSeparator &&
                <Box sx={{
                    borderRight: "1px solid #EBECF2", height: "70%",
                    display: 'flex',
                    alignSelf: 'end',
                }}></Box>
            }
        </>
    )
}