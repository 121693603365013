import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Chip, IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from "../../../assets/icons/global";
import { Dispatch, SetStateAction } from "react";

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  isNonRoundedBorders?: boolean;
}

export default function SearchBar({ searchTerm, setSearchTerm, isNonRoundedBorders = false }: SearchBarProps) {

  return (
    <TextField
      sx={{
        border: "none",
        outline: "none",
        backgroundColor: "#ffffff",
        padding: 0,
        margin: 0,
        width: "100%",
        "& .MuiInputBase-root": {
          height: 40,
          paddingLeft: 0,
          borderRadius: isNonRoundedBorders ? 0 : '4px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderLeft: isNonRoundedBorders ? "none" : undefined,
          borderRight: isNonRoundedBorders ? "none" : undefined,
          borderColor: isNonRoundedBorders ? 'rgba(0, 0, 0, 0.12)' : undefined
        }, "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": isNonRoundedBorders ? {
            borderColor: 'rgba(0, 0, 0, 0.12)',
            borderWidth: '1px'
          } : {}
        }
      }}
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ margin: 0 }}>
            <IconButton disableRipple>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}