import React from 'react';
import { Box, Divider, Grid, IconButton, Stack } from "@mui/material";
import { CustomTypography } from "../../../shared";
import ButtonWithTextAndIcon from "../../../shared/button-with-label-and-icon";
import { PMSReportsIcon } from "packages/web/src/assets/icons/PMSReportsIcon";
import routeNames from "packages/web/src/routes/routeNames";
import PerformanceXIRRChartCard, { getPMSXIRRDisplay } from "../../../shared/performance-xirr-chart-card";
import CommonCard from "../../../shared/common-card";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { DistributionIcon } from "packages/web/src/assets/icons/DistributionIcon";
import CustomModal from "../../../shared/custom-modal";
import { CloseIcon } from "packages/web/src/assets/icons/CloseIcon";
import PieChartWithRightLegend from '../../../shared/asset-allocation-pie-chart/pie-chart-right-legend';
import { ClosingValuationIcon_AllToDate, ClosingValuationIcon_Periodwise } from 'packages/web/src/assets/icons/PMS/ClosingValuationIcons';
import { CashInIcon_AllToDate, CashInIcon_Periodwise } from 'packages/web/src/assets/icons/PMS/CashInIcons';
import { CashOutIcon_AllToDate, CashOutIcon_Periodwise } from 'packages/web/src/assets/icons/PMS/CashOutIcons';
import { ClosingCashIcon_AllToDate, ClosingCashIcon_Periodwise } from 'packages/web/src/assets/icons/PMS/ClosingCashIcons';
import { OpeningCashIcon_Periodwise } from 'packages/web/src/assets/icons/PMS/OpeningCashIcons';
import { OpeningValuationIcon_Periodwise } from 'packages/web/src/assets/icons/PMS/OpeningValuationIcons';
import { useNumberFormatPrefs } from '../../../hooks/userPreferencesHooks';
import { PMSXIRRCardPDFDownloadButton } from './pdf';

interface PerformancePMSProps {
    IsPerformanceLoading: boolean;
    PerformanceXIRRData: SharedConstants.XIRRTotalItem | undefined;
    IsPerformanceViewAllowed: boolean;
    PeriodText: string;
    IsPerformancePeriodAllToDate: boolean;
}

const PerformancePMS = ({ IsPerformanceViewAllowed, PerformanceXIRRData, IsPerformanceLoading, PeriodText, IsPerformancePeriodAllToDate }: PerformancePMSProps) => {

    const navigate = useNavigate();
    const location = useLocation();

    const xirrTotalIsLoading = useAppSelector(SharedRedux.Performance.Selectors.selectXIRRTotalIsLoading);

    const TitleSize = IsPerformancePeriodAllToDate ? 'Regular20' : 'Regular16';
    const AmountSize = IsPerformancePeriodAllToDate ? 'Medium28' : 'Medium24';

    return (
        <>
            <Grid container columnSpacing={4} mt={2} sx={{ display: 'flex', height: '100%' }}>
                <Grid item xs={12} md={4} sx={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <Box width="100%" height="100%" overflow="hidden">
                        <PerformanceXIRRChartCard
                            GenericTitleText="PMS Portfolio XIRR"
                            IsPerformanceViewAllowed={IsPerformanceViewAllowed}
                            IsPerformanceLoading={IsPerformanceLoading}
                            PeriodText={PeriodText}
                            PeroidBgColor="#E2DA90"
                            PeriodTextColor="#12131A"
                            BackgroundColor="#F6F4DD"
                            TitleColor="#12131A"
                            PerformanceXIRRData={PerformanceXIRRData}
                            ContainerSx={{ height: '100%' }}
                            IsPMS={true}
                        />
                    </Box>

                </Grid>

                <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{ display: 'flex', flex: 1 }}
                >
                    <Box sx={{ padding: IsPerformancePeriodAllToDate ? '44px 42px' : '20px 20px', bgcolor: 'white', borderRadius: "8px", width: '100%' }}>

                        {
                            !IsPerformancePeriodAllToDate ?

                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: "#F5F5F6",
                                        borderRadius: "8px",
                                        padding: IsPerformancePeriodAllToDate ? "24px 12px" : "16px 12px",
                                        marginBottom: '32px',
                                    }}
                                >
                                    <Box sx={{ flex: 1, display: 'flex', pl: 3 }}>
                                        <CommonCard
                                            Amount={`${PerformanceXIRRData?.OpeningValuation}`}
                                            Title={"Opening Investment Value"}
                                            Icon={<OpeningValuationIcon_Periodwise />}
                                            TitleSize={TitleSize}
                                            AmountSize={AmountSize}
                                            IsLoading={IsPerformanceLoading}
                                        />
                                    </Box>
                                    <Divider
                                        variant="middle"
                                        orientation="vertical"
                                        flexItem
                                        sx={{borderColor: '#D8DAE5'}}
                                    />
                                    <Box sx={{ flex: 1, display: 'flex', pl: 8 }}>
                                        <CommonCard
                                            Amount={`${PerformanceXIRRData?.OpeningCashBalance}`}
                                            Title={"Opening Cash"}
                                            Icon={<OpeningCashIcon_Periodwise />}
                                            TitleSize={TitleSize}
                                            AmountSize={AmountSize}
                                        />
                                    </Box>
                                </Box>

                                : null
                        }

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: !IsPerformancePeriodAllToDate ? "#FFFFFF" : "#F5F5F6",
                                borderRadius: "8px",
                                padding: IsPerformancePeriodAllToDate ? "24px 12px" : "16px 12px",
                                border: !IsPerformancePeriodAllToDate ? '1px solid #EBECF2' : 'none',
                            }}
                        >
                            <Box sx={{ flex: 1, display: 'flex', pl: 3 }}>
                                <CommonCard
                                    Amount={`${PerformanceXIRRData?.TotalInflow}`}
                                    Title={"Cash In"}
                                    Icon={IsPerformancePeriodAllToDate ? <CashInIcon_AllToDate /> : <CashInIcon_Periodwise />}
                                    TitleSize={TitleSize}
                                    AmountSize={AmountSize}
                                    IsLoading={IsPerformanceLoading}
                                />
                            </Box>
                            <Divider
                                variant="middle"
                                orientation="vertical"
                                flexItem
                                sx={{borderColor: '#D8DAE5'}}
                            />
                            <Box sx={{ flex: 1, display: 'flex', pl: 8 }}>
                                <CommonCard
                                    Amount={`${PerformanceXIRRData?.TotalOutflow}`}
                                    Title={"Cash Out"}
                                    Icon={IsPerformancePeriodAllToDate ? <CashOutIcon_AllToDate /> : <CashOutIcon_Periodwise />}
                                    TitleSize={TitleSize}
                                    AmountSize={AmountSize}
                                    IsLoading={IsPerformanceLoading}
                                />
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                marginTop: IsPerformancePeriodAllToDate ? '64px' : '32px',
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "8px",
                                padding: "0px 12px"
                            }}
                        >
                            <Box sx={{ flex: 1, display: 'flex', pl: 3 }}>
                                <CommonCard
                                    Amount={`${PerformanceXIRRData?.ClosingValuation}`}
                                    Title={IsPerformancePeriodAllToDate ? "Current Investment Value" : "Closing Investment Value"}
                                    Icon={IsPerformancePeriodAllToDate ? <ClosingValuationIcon_AllToDate /> : <ClosingValuationIcon_Periodwise />}
                                    TitleSize={TitleSize}
                                    AmountSize={AmountSize}
                                    IsLoading={IsPerformanceLoading}
                                />
                            </Box>
                            <Divider
                                variant="middle"
                                orientation="vertical"
                                flexItem
                                sx={{borderColor: '#D8DAE5'}}
                            />
                            <Box sx={{ flex: 1, display: 'flex', pl: 8 }}>
                                <CommonCard
                                    Amount={`${PerformanceXIRRData?.ClosingCashBalance}`}
                                    Title={!IsPerformancePeriodAllToDate ? "Closing Cash" : "Cash on Hand"}
                                    Icon={IsPerformancePeriodAllToDate ? <ClosingCashIcon_AllToDate /> : <ClosingCashIcon_Periodwise />}
                                    TitleSize={TitleSize}
                                    AmountSize={AmountSize}
                                    IsLoading={IsPerformanceLoading}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>


            </Grid>

            <Grid my={4} container>
                <Grid item
                    xs={12}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        borderRadius: "8px"
                    }} border={"1px solid #EBECF2"}>
                        <Box sx={{
                            padding: "20px"
                        }}>
                            <CommonCard Amount={`${PerformanceXIRRData?.TotalGain || 0}`} TitleColor="#64677A" Title={"Absolute Gain"} IsLoading={IsPerformanceLoading} />
                        </Box>
                        <Divider variant="middle" orientation="vertical" flexItem sx={{marginTop: '24px', marginBottom: '24px', borderColor: '#D8DAE5'}} />
                        <Box sx={{
                            padding: "20px"
                        }}>
                            <CommonCard Amount={`${PerformanceXIRRData?.IncomePayout || 0}`} TitleColor="#64677A" Title={"Income Payout"} IsLoading={IsPerformanceLoading} />
                        </Box>
                        <Divider variant="middle" orientation="vertical" flexItem sx={{marginTop: '24px', marginBottom: '24px', borderColor: '#D8DAE5'}} />
                        <Box sx={{
                            padding: "20px"
                        }}>
                            <CommonCard Amount={`${PerformanceXIRRData?.TotalFees || 0}`} IsLoading={IsPerformanceLoading} TitleColor="#64677A" Title={"Total Fees"} RightComponent={PerformanceXIRRData?.TotalFees && !xirrTotalIsLoading ? <MemoizedTotalFeesDistribution /> : undefined} />
                        </Box>
                    </Box>
                </Grid>

            </Grid>

        </>
    )
}

const TotalFeesDistribution = () => {

    const [open, setOpen] = useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const { PieChartData, TotalValue } = useAppSelector(SharedRedux.Performance.Selectors.selectTotalFeesDistributionPieChartData)

    return (
        <>

            <Box
                sx={{
                    display: "flex",
                    marginTop: "10px",
                    width: 150,
                    alignItems: "center",
                    marginLeft: "14px",
                    cursor: "pointer"
                }}
                onClick={openModal}
            >
                <Box>
                    <DistributionIcon />
                </Box>
                <CustomTypography
                    variant="Medium14"
                    sx={{ color: { xs: "#5F6F57" }, marginLeft: "5px" }}
                >
                    See distribution
                </CustomTypography>
            </Box>
            <CustomModal
                open={open}
                handleClose={closeModal}
            >
                <Box sx={{
                    width: { sm: '60%', md: '40%'},
                    background: "white",
                    borderRadius: "10px",
                }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" px={4} py={1}>
                        <CustomTypography variant="Medium24">
                            Total Fees Distribution
                        </CustomTypography>
                        <IconButton onClick={closeModal} disableRipple>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Divider />
                    <Box p={2}>
                        <PieChartWithRightLegend
                            data={PieChartData}
                            totalValue={TotalValue}
                            dropdownValue={undefined}
                            heading=""
                            subHeading=""
                            totalFieldLabel="Total Fees"
                            handleDropdownChange={(selected) => { }}
                            onItemClick={() => { }}
                        />
                    </Box>
                </Box>
            </CustomModal>
        </>
    );
};

const MemoizedTotalFeesDistribution = React.memo(TotalFeesDistribution);

export default React.memo(PerformancePMS);