import { Stack } from "@mui/material";
import CustomModal from "../../../shared/custom-modal";
import PeriodSelector from "../../../shared/period-selector";
import { useAppDispatch } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";

interface SetPerformancePeriodModalProps {
    showPerformancePeriodModal: boolean;
    setShowPerformancePeriodModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SetPerformancePeriodModal =
    ({showPerformancePeriodModal, setShowPerformancePeriodModal}: SetPerformancePeriodModalProps) => {
    const dispatch = useAppDispatch();

    return (
        <CustomModal
            open={showPerformancePeriodModal}
            handleClose={() => setShowPerformancePeriodModal(false)}
        >
            <Stack
                sx={{
                    width: "fit-content",
                    background: "white",
                    borderRadius: "10px",
                }}
                >
                <PeriodSelector
                    closePopOver={() => setShowPerformancePeriodModal(false)}
                    onSetPeriod={(period: SharedConstants.SetPeriodPayload) => {
                    dispatch(
                        SharedRedux.Performance.Actions.setActivePeriod(period)
                    );
                    }}
                />
                </Stack>
        </CustomModal>
    )
}