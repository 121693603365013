import { SharedRedux } from "@mprofit/shared";
import { useAppDispatch } from "packages/web/src/redux/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PortfolioLayout from "../../portfolio/portfolioLayout";

function AuthRedirectComponent() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      var loginNavTimeout = setTimeout(() => {
          navigate('/');
      }, 5000);

      var onEventFromNewApp = (event: MessageEvent<any>) => {
        if (event.origin === (process.env.REACT_APP_CLOUD_APP_URL || "https://cloud.mprofit.in")) {
            console.log(event, 'REDIRECTEVENT')
            if (event.data) {
                window.removeEventListener("message", onEventFromNewApp);

                dispatch(SharedRedux.Login.Actions.loginSuccess(event.data));
                navigate('/portfolio/today');
                sessionStorage.setItem('MprCurrentUser', JSON.stringify(event.data));
                sessionStorage.setItem('isOpenedFromAnalytics', '1');
                clearTimeout(loginNavTimeout);
            }
        }
    
        // …
    };

      window.addEventListener(
        "message",
        onEventFromNewApp,
        false
      );

      return () => {
        window.removeEventListener('message', onEventFromNewApp);
      };
    }, []);

    return (
      <PortfolioLayout MPrUrlParams={undefined} isPlaceholder={true} />
    );
}

export default AuthRedirectComponent;