import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CustomTypography from '../customTypography';
import { Box, SxProps, Theme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactNode } from 'react';

interface DropdownProps {
  inputValue: string | number,
  inputList: DropdownItem[],
  startIcon?: JSX.Element,
  disabled?: boolean,
  handleChange?: ((selected: DropdownItem) => void) | undefined,
  renderValue?: ((value: string) => ReactNode) | undefined,
  containerSx?: SxProps<Theme> | undefined,
  iconSx?: SxProps<Theme> | undefined
}

export interface DropdownItem {
  id: string | number;
  name: string;
}

export default function DropDown({ inputList, inputValue, startIcon, disabled, handleChange, renderValue, containerSx, iconSx }: DropdownProps) {
  const onChange = (event: SelectChangeEvent<string>, value: React.ReactNode) => {
    var name = event.target.value;
    var selected = inputList?.find(x => x.name == name);
    if (selected && handleChange) {
      handleChange(selected);
    }
  }

  return (
    <Select
      disabled={disabled}
      sx={containerSx || {
        background: '#FFFFFF',
        border: "1px solid #D8DAE5",
        color: "#64677A",
        height: { xs: "40px", lg: "48px" },
        '& .MuiSelect-select': {
          padding: { xs: "8px 12px 8px 16px ", lg: "13px 15px 11px 15px", lg1: "13px 22px 11px 16px" }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: "none",
        },
        '&:hover': {
          color: "#64677A",
          backgroundColor: '#F9F9F9',
          border: "1px solid #D8DAE5",
          outline: "none"
        },
        "&:acitve": {
          backgroundColor: '#EBECF2'
        },
        "&:focus": {
          backgroundColor: '#CFE3C9'
        },
        "& .Mui-disabled": {
          background: "#EBECF2"
        }
      }}
      displayEmpty
      value={inputList?.find(x => x.id == inputValue)?.name}
      onChange={onChange}
      renderValue={renderValue || ((selected) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {startIcon}
            <CustomTypography variant='Regular16' sx={{ marginRight: '8px', color: '#64677A' }}>
              {selected ? selected : ""}
            </CustomTypography>
          </Box>
        )
      })}
      IconComponent={(props) => (
        <KeyboardArrowDownIcon {...props} sx={iconSx}/>
      )}
    >
      {inputList.map((item) => (
        <MenuItem
          sx={{
            padding: "14px 16px 15x 44px",
            "&:focus": {
              backgroundColor: '#CFE3C9'
            },
            '&:hover': {
              color: "#64677A",
              backgroundColor: '#F9F9F9',
            },
          }}
          key={item.id}
          value={item.name}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
}