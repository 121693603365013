import React from 'react'
export default function EyeVisiblityOff(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_222473" data-name="Group 222473" transform="translate(-446 -540)">
                <rect id="Rectangle_34507" data-name="Rectangle 34507" width="48" height="48" transform="translate(446 540)" fill="#fff" opacity="0" />
                <g id="Group_222472" data-name="Group 222472" transform="translate(458.766 552.729)">
                    <path id="Path_237801" data-name="Path 237801" d="M3.44,2.456a.7.7,0,0,0-.985.985L19.175,20.16a.7.7,0,1,0,.985-.985L3.44,2.456Zm17.784,9.366a10.449,10.449,0,0,1-2.444,4L15.9,12.946A4.876,4.876,0,0,0,9.669,6.714l-2.3-2.3a10.461,10.461,0,0,1,13.856,6.375,1.64,1.64,0,0,1,0,1.034Z" transform="translate(-0.074)" fill="#babdcc" />
                    <path id="Path_237802" data-name="Path 237802" d="M15.216,11.733a3.582,3.582,0,0,1-.034.492L11.24,8.284a3.483,3.483,0,0,1,3.976,3.449Zm-2.991,3.449L8.284,11.24a3.483,3.483,0,0,0,3.941,3.941Z" transform="translate(-0.499 -0.426)" fill="#babdcc" />
                    <path id="Path_237803" data-name="Path 237803" d="M6.358,11.654a4.858,4.858,0,0,1,.282-1.638L3.76,7.136a10.45,10.45,0,0,0-2.443,4,1.643,1.643,0,0,0,0,1.035,10.461,10.461,0,0,0,13.856,6.375l-2.3-2.3a4.876,4.876,0,0,1-6.515-4.594Z" transform="translate(0 -0.346)" fill="#babdcc" />
                </g>
            </g>
        </svg>
    )
}
