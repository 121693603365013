
export function PortfolioIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="Group_150509" data-name="Group 150509" transform="translate(-35 -318)">
        <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(35 318)" fill="#fff" opacity="0" />
        <g id="Group_150649" data-name="Group 150649" transform="translate(39 322)">
          <path id="Path_198404" data-name="Path 198404" d="M2.25,13.5A8.25,8.25,0,0,1,10.5,5.25a.75.75,0,0,1,.75.75v6.75H18a.75.75,0,0,1,.75.75,8.25,8.25,0,0,1-16.5,0Z" fill="#d0e3bb" fillRule="evenodd" />
          <path id="Path_198405" data-name="Path 198405" d="M12.75,3a.75.75,0,0,1,.75-.75,8.25,8.25,0,0,1,8.25,8.25.75.75,0,0,1-.75.75H13.5a.75.75,0,0,1-.75-.75Z" fill="#d0e3bb" fillRule="evenodd" />
        </g>
      </g>
    </svg>


  );
}

