import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from '@mui/material';
import { DropdownItem } from '../drop-down';
import { MouseEvent } from 'react';

interface NavigationSecondaryTabsProps {
  id?: string
  tabs: DropdownItem[];
  disabled?: boolean;
  setActiveTab?: (activeTabID: string | number) => void;
  activeTabID?: string | number;
  bgColor?: string
}

export default function NavigationSecondaryTabs({ id, tabs, disabled, setActiveTab, activeTabID, bgColor }: NavigationSecondaryTabsProps) {
  const onChange = (event: MouseEvent<HTMLElement>, newValue: string | number) => {
    if (setActiveTab) setActiveTab(newValue);
  }

  return (
    <Box sx={{ padding: '5px', borderRadius: '8px', background: bgColor || "#EBECF2", minWidth: 'max-content', border: '2px solid #f6f6f6', }}>
      <ToggleButtonGroup
        id={id}
        disabled={disabled}
        value={activeTabID}
        exclusive
        onChange={onChange}
        aria-label="text alignment"
        sx={{ height: { xs: "33px", lg: "38px" } }}
      >
        {
          tabs.map(((tab, index) => (
            <ToggleButton
              key={index}
              value={tab.id}
              disableRipple
              sx={{
                border: "none",
                height: '100%',
                fontSize: '18px',
                fontWeight: '400',
                padding: "9px 32px 9px 32px",
                backgroundColor: bgColor || "#F5F5F6",
                textTransform: "initial",
                color: '#64677A',
                borderRadius: "8px !important",
                "&.Mui-selected": {
                  "&.MuiToggleButton-root": {
                    borderRadius: "8px",
                    background: "#D0DBBF",
                    color: '#12131A'
                  },
                },
                "&.Mui-disabled": {
                  "&.MuiToggleButton-root": {
                    border: "none",
                    borderRadius: "8px",
                    background: index === 0 ? "#EBECF2" : "transparent",
                    color: index === 0 ? "#BABDCC" : ""
                  },
                },
                "&:hover": {
                  "&.MuiToggleButton-root": {
                    borderRadius: "8px",
                    color: '#12131A'
                  },
                }
              }}
            >
              {tab.name}
            </ToggleButton>
          )))
        }
      </ToggleButtonGroup>
    </Box>
  );
}
