import { PortfolioMappingFieldToUse } from "../constants";

export const getPortfolioMappingFieldToUseText = (type: PortfolioMappingFieldToUse) => {
    switch (type) {
        default:
            return null;
        case PortfolioMappingFieldToUse.FULL_NAME:
            return "Portfolio Full Name";
        case PortfolioMappingFieldToUse.PAN:
            return "PAN"
        case PortfolioMappingFieldToUse.SHORT_NAME:
            return "Portfolio Short Name";
    }
}