import { Box, SxProps, Theme, styled } from "@mui/material"
import { ReactComponent as RedArrowDown } from '../../../assets/icons/redArrowDown.svg';
import { GreenArrowUp } from 'packages/web/src/assets/icons/GreeArrowUp';
import CustomTypography from "../customTypography";
import { formatPercent } from "@mprofit/shared";

interface ILossGainProps {
    Gain: string | number | undefined,
    GainUp: boolean,
    marginleft?: boolean,
    sx?: SxProps<Theme>
}

const LossGainBox = ({ Gain, GainUp, marginleft, sx={} }: ILossGainProps): JSX.Element => {
    return (
        <GainLossCont sx={{ marginLeft: marginleft ? "15px" : '0px', backgroundColor: GainUp ? '#EAF4DF' : '#FFF5F5', ...sx }}>
            {
                GainUp ? <GreenArrowUp /> : <RedArrowDown />
            }
            <CustomTypography variant='Medium16' sx={{ ml: '8px' }} color={GainUp ? '#5F854C' : '#C94C40'}>{formatPercent(Gain, 2, 'IN')}</CustomTypography>
        </GainLossCont>
    )
}

const GainLossCont = styled(Box)({
    display: 'flex',
    width: 'max-content',
    alignItems: 'center',
    borderRadius: '6px',
    padding: '4px 7px',
})

export default LossGainBox