import { formatDateForReadingWithTime, SharedConstants, SharedRedux } from "@mprofit/shared";
import { Box, Divider } from "@mui/material";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { Dispatch, useState } from "react";
import { CustomTypography } from "../../../shared";
import PrimaryIconButton from "../../../shared/primary-icon-button";
import ComponentTabs from "../../../shared/advanced-chart-tabs";
import { PerformanceFYChart } from "../performance-fy-chart";
import ModalButton from "../../../shared/modalButton";
import ChangeSingleIndexModal from "../../shared/change-single-index-modal";
import { t } from "i18next";
import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import styles from "./index.module.css";
import CachedIcon from '@mui/icons-material/Cached';
import { AdvancedPerformanceChartPDFDownloadButton } from "./pdf";

interface AdvancedPerformanceChart_Props {
    MPrUrlParams: MPrUrlParams;
    OnRefreshXIRRFYWise: (
      urlParams: MPrUrlParams,
      dispatch: Dispatch<any>,
      force?: boolean
    ) => void;
    OnRefreshXIRRYearWise: (
      urlParams: MPrUrlParams,
      dispatch: Dispatch<any>,
      force?: boolean
    ) => void;
    AdvancedChartTitleText: string;
    IsPerformanceViewAllowed: boolean;
  }

  
export const AdvancedPerformanceChart = (props: AdvancedPerformanceChart_Props) => {
    const [activePerfChartMode, setActivePerfChartMode] =
      useState<SharedConstants.AdvancedPerformanceChartMode>(
        SharedConstants.AdvancedPerformanceChartMode.YearWise
      );
  
    const lastRefreshedTime_FY = useAppSelector(
      SharedRedux.Performance.Selectors.selectXIRRFYWiseLastRefreshedTime
    );
    const lastRefreshedTime_YearWise = useAppSelector(
      SharedRedux.Performance.Selectors.selectXIRRYearWiseLastRefreshedTime
    );
  
    const isLoading_FY = useAppSelector(
      SharedRedux.Performance.Selectors.selectXIRRFYWiseIsLoading
    );
    const isLoading_YearWise = useAppSelector(
      SharedRedux.Performance.Selectors.selectXIRRYearWiseIsLoading
    );
  
    const isLoading =
      activePerfChartMode ===
        SharedConstants.AdvancedPerformanceChartMode.YearWise
        ? isLoading_YearWise
        : isLoading_FY;
    const lastRefreshedTime =
      !isLoading &&
      (activePerfChartMode ===
        SharedConstants.AdvancedPerformanceChartMode.YearWise
        ? lastRefreshedTime_YearWise
        : lastRefreshedTime_FY);
  
    const dispatch = useAppDispatch();
  
    const onRefreshClicked = () => {
      if (
        activePerfChartMode ===
        SharedConstants.AdvancedPerformanceChartMode.YearWise
      ) {
        props.OnRefreshXIRRYearWise(props.MPrUrlParams, dispatch, true);
      } else {
        props.OnRefreshXIRRFYWise(props.MPrUrlParams, dispatch, true);
      }
    };
  
  
    return (
      <Box
        sx={{
          margin: "5px 40px 25px 40px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          border: "1px solid #EBECF2",
          opacity: !props.IsPerformanceViewAllowed ? 0.3 : 1,
        }}
        className="apptour-performance-advancedchart"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "32px",
            opacity: !props.IsPerformanceViewAllowed ? 0.3 : 1,
          }}
        >
          <Box>
            <Box sx={{ display: "flex" }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
            >
              <CustomTypography variant="Medium24">
                {"Advanced Performance Insights"}
              </CustomTypography>
              {lastRefreshedTime && (
                <Box sx={{ alignItems: "center", justifyContent: "center" }}>
                  <PrimaryIconButton
                    icon={<CachedIcon />}
                    handleClick={onRefreshClicked}
                    iconBorderRadius={"4px"}
                  />
                </Box>
              )}
              {lastRefreshedTime && 
                <AdvancedPerformanceChartPDFDownloadButton chartMode={activePerfChartMode} advancedChartTitleText={props.AdvancedChartTitleText} />
              }
            </Box>
            <Box sx={{ display: "flex", gap: "5px" }}>
              {lastRefreshedTime && (
                <>
                  <CustomTypography
                    variant="Regular16"
                    sx={{ color: { xs: "#64677A" } }}
                  >
                    {t("portfolio.performance.advancedChart.lastRefreshed")}
                  </CustomTypography>
                  <CustomTypography
                    variant="Medium16"
                    sx={{ color: { xs: "#64677A" } }}
                  >
                    {formatDateForReadingWithTime(lastRefreshedTime)}{" "}
                  </CustomTypography>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box style={{ marginLeft: "12px" }}>
              <ComponentTabs
                // disabled={!isLoading}
                options={[
                  {
                    id: SharedConstants.AdvancedPerformanceChartMode.YearWise,
                    text: "Historical",
                  },
                  {
                    id: SharedConstants.AdvancedPerformanceChartMode.FYWise,
                    text: "FY-wise",
                  },
                ]}
                handleChange={(value) => setActivePerfChartMode(value)}
                value={activePerfChartMode}
                bgColor="#F6F6F6"
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <PerformanceFYChart
            isLoading={isLoading}
            MPrUrlParams={props.MPrUrlParams}
            OnRefreshXIRRFYWise={props.OnRefreshXIRRFYWise}
            OnRefreshXIRRYearWise={props.OnRefreshXIRRYearWise}
            ActiveMode={activePerfChartMode}
            disabled={!props.IsPerformanceViewAllowed}
          />
          <Divider />
          <AdvancedChartFooter
            isLoading={isLoading}
            SpecificTitleText={props.AdvancedChartTitleText}
            activePerfChartMode={activePerfChartMode}
            IsPerformanceViewAllowed={props.IsPerformanceViewAllowed}
          />
        </Box>
      </Box>
    );
};


const AdvancedChartFooter = ({
    SpecificTitleText,
    activePerfChartMode,
    IsPerformanceViewAllowed
  }: {
    SpecificTitleText: string;
    activePerfChartMode: SharedConstants.AdvancedPerformanceChartMode;
    IsPerformanceViewAllowed: boolean;
    isLoading: boolean;
  }) => {
    const [isChangeIndexModalOpen, setIsChangeIndexModalOpen] = useState(false);
    const performanceSingleIndex = useAppSelector(
      SharedRedux.Dashboard.Selectors.selectPerformanceSingleIndex
    );
    return (
      <>
        <ChangeSingleIndexModal
          isModalOpen={isChangeIndexModalOpen}
          setIsModalOpen={setIsChangeIndexModalOpen}
          initialActiveSingleIndex={performanceSingleIndex}
          activePerfChartMode={activePerfChartMode}
        />
        <Box className={styles.AdvancePerformanceChartFooter}>
          <CustomTypography
            variant="Regular20"
            sx={{
              display: "flex",
              alignItems: "center",
              paddingRight: "32px",
              color: { xs: "#64677A" },
            }}
          >
            <Box
              sx={{
                height: "12px",
                width: "12px",
                backgroundColor: "#FFB598",
                borderRadius: "12px",
                marginRight: "10px",
              }}
            ></Box>
            {SpecificTitleText}
          </CustomTypography>
          {performanceSingleIndex && (
            <ModalButton
              text={performanceSingleIndex.AssetName}
              icon={
                <Box
                  className="icon-box"
                  sx={{
                    height: "12px",
                    width: "12px",
                    backgroundColor: "#b1dbff",
                    transition:
                      "background-color 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    borderRadius: "12px",
                  }}
                ></Box>
              }
              handleClick={() => setIsChangeIndexModalOpen(true)}
              typographyVariant={"Regular20"}
              disabled={!IsPerformanceViewAllowed}
            />
          )}
        </Box>
        <Box
          sx={{
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "32px",
          }}
        >
          <CustomTypography variant="Regular16">
            {activePerfChartMode ===
              SharedConstants.AdvancedPerformanceChartMode.FYWise
              ? "Please note: For the Financial Year in which you started your first investment, the index return will be computed from the start date of your investment, to provide an accurate comparison."
              : "Please note: XIRR is available from the starting date of your investment. For example, if you started your first investment 3 years ago, you will view your All to Date, 1 year and 2 year XIRR."}
          </CustomTypography>
        </Box>
      </>
    );
};