export interface LicenseInfo {
    IsNumPortfoliosPerFamilyOverriden: boolean;
    SystemType: number;
    LicenseExpDt: string;
    TrialAdditionalServicesExpirationDt: string;
    NumPortsAllowed: number;
    NumPortsActual: number;
    ProductType: number;
    ProductId: number;
    IsExpired: boolean;
    IsTrial: boolean;
    IsTrialAdditionalServices: boolean;
    IsFree: boolean;
    NextTrialSignupType: number;
    NextB2C_Tier: number;
    NextB2B2C_Tier: number;
    NumNextTrialDays: number;
    IsPortfolioCountViolation: boolean;
    IsFamilyCountViolation: boolean;
    DaysToExpiry: number;
    IsAdditionalServicesTrialExpired: boolean;
    TrialAdditionalServicesDaysToExpiry: number;
    AdditionalServices: number[];
    AdditionalServicesTried: number[];
    HasPremiumPack: boolean;
    HasPmsPremiumPack: boolean;
    HasStrategyPack: boolean;
    IsCloudAddOnCountViolation: boolean;
    IsLicenseViolation: boolean;
    IsLicenseViolationWithoutAUMViolation: boolean;
    NumCloudUsers: number;
    NumCloudActiveUsers: number;
    IsLegacyProduct: boolean;
    B2X: number;
    B2C_Tier: number;
    B2B2C_Tier: number;
    IsAUMCheckProcessing: boolean;
    Customer_IsAUMViolation: boolean;
    AUMActual: number;
    AUMLimit: number;
    IsAUMViolation: boolean;
    NumDesktopUsersDefault: number;
    NumCloudUsersDefault: number;
    NumFamiliesDefault: number;
    NumFamiliesAdditional: number;
    NumFamiliesActual: number;
    NumFamiliesAllowed: number;
    NumPortfoliosPerFamily: number;
    NumPortfoliosPerFamilyFinal: number;
    IsTrialAdditionalFamilies: boolean;
    TrialAdditionalFamiliesExpirationDt: string;
    IsAdditionalFamiliesTrialExpired: boolean;
    TrialAdditionalFamiliesDaysToExpiry: number;
    IsTrialAdditionalUsers: boolean;
    TrialAdditionalUsersExpirationDt: string;
    IsAdditionalUsersTrialExpired: boolean;
    TrialAdditionalUsersDaysToExpiry: number;
    NonTrialInfo: Record<string, any>;
    IsTrialForPaidUser: boolean;
}

export enum ProductType {
    INVESTOR = 0,
    PRO = 1,
    ADVISOR = 2
}