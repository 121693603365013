import { Box } from '@mui/material';
import React from 'react'
import Lottie from 'react-lottie'
import * as skeltonData from '../../../assets/animation/skelton.json'
import CustomTypography from '../customTypography';

export default function GraphLoader() {

 return (
  <Box sx={styles.skeltonContainer}>
   <Lottie
    options={{
     loop: true,
     autoplay: true,
     animationData: skeltonData,
     rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
     }
    }}
    height={211}
    width={211}
   />
   <Box>
    <CustomTypography variant='Regular18'>Hold tight, we'll have your portfolio ready for you in no time!</CustomTypography>
   </Box>
  </Box>
 )
}

const styles = {
 skeltonContainer: {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
 }
}