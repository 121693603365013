import { call, put, select, takeLatest } from "redux-saga/effects";
import { ResponseGenerator } from "../../api";
import { Actions as PerformanceActions } from "../modules/performanceReducer";
import type { PayloadAction } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { AM_API } from "../../assetmasters_api";
import { AM_PeriodRequest, IndexMaster, ReturnsByIndexRequest, ReturnsByIndexResponseItem, ReturnsByPeriodRequest, ReturnsByPeriodResponseItem } from "../../constants/assetMasters";
import { Actions as DashboardActions, Selectors as DashboardSelectors } from "../modules/dashboardReducer";
import { PeriodInfo, TimePeriodFromEndDate, Watchlist, WatchlistItem } from "../../constants";
import { convertUTCDateToAssetMastersFormat, getFY } from "../../utilities";


const getIndexReturnsByPeriod = (payload: ReturnsByPeriodRequest) => {
    return AM_API.post<ReturnsByPeriodResponseItem[]>('/api/EQ/Indices/ReturnsByPeriod', payload);
}

const getIndexReturnsByIndex = (payload: ReturnsByIndexRequest) => {
    return AM_API.post<ReturnsByIndexResponseItem[]>('/api/EQ/Indices/ReturnsByIndex', payload);
}

const getIndicesMasterList = () => {
    return AM_API.get<IndexMaster[]>('/api/EQ/Indices/List');
}

function* onRefreshIndexReturnsByIndex(action: PayloadAction<AM_PeriodRequest>) {
    // var activePeriod: PeriodInfo | undefined = yield select(Selectors.selectActivePeriod);
    // if (!activePeriod) return;

    const indexWatchlist: Watchlist = yield select(DashboardSelectors.selectPerformanceIndicesWatchlist);
    const response: ResponseGenerator = yield call(getIndexReturnsByIndex, {
        MProfitCodes: action.payload.IsOlderThan2012Return ? [99906] : indexWatchlist ? indexWatchlist.Items.map(x => x.MProfitCode) : [99999, 99998, 99992, 99991, 99906],
        StartDate: action.payload.StartDate,
        EndDate: action.payload.EndDate,
    });

    if (response.status == HttpStatusCode.Ok) {
        yield put(PerformanceActions.setXIRRTotalIndexReturns(response.data));
    }
}

function* onRefreshIndexReturnsForFYWise(action: PayloadAction<AM_PeriodRequest[]>) {
    const performanceSingleIndex: WatchlistItem | undefined = yield select(DashboardSelectors.selectPerformanceSingleIndex);
    if (!performanceSingleIndex) return;

    const response: ResponseGenerator = yield call(getIndexReturnsByPeriod, {
        MProfitCode: performanceSingleIndex.MProfitCode,
        Periods: action.payload
    });

    if (response.status == HttpStatusCode.Ok) {
        yield put(PerformanceActions.setXIRRFYWiseIndexReturns(response.data));
    }
}

function* onRefreshIndexReturnsForYearWise(action: PayloadAction<AM_PeriodRequest[]>) {
    const performanceSingleIndex: WatchlistItem | undefined = yield select(DashboardSelectors.selectPerformanceSingleIndex);
    if (!performanceSingleIndex) return;

    const response: ResponseGenerator = yield call(getIndexReturnsByPeriod, {
        MProfitCode: performanceSingleIndex.MProfitCode,
        Periods: action.payload
    });

    if (response.status == HttpStatusCode.Ok) {
        yield put(PerformanceActions.setXIRRYearWiseIndexReturns(response.data));
    }
}

function* onRefreshIndicesMaster() {
    const response: ResponseGenerator = yield call(getIndicesMasterList);

    if (response.status == HttpStatusCode.Ok && response.data) {
        var indicesMaster: IndexMaster[] = response.data;
        yield put(DashboardActions.setIndicesMaster(indicesMaster.sort((x,y) => x.MProfitCode < y.MProfitCode ? 1 : -1)));
    }
}

function* assetMastersSaga() {
    yield takeLatest(PerformanceActions.refreshXIRRTotalIndexReturns.type, onRefreshIndexReturnsByIndex);
    yield takeLatest(PerformanceActions.refreshXIRRYearWiseIndexReturns.type, onRefreshIndexReturnsForYearWise);
    yield takeLatest(PerformanceActions.refreshXIRRFYWiseIndexReturns.type, onRefreshIndexReturnsForFYWise);
    yield takeLatest(DashboardActions.refreshIndicesMaster, onRefreshIndicesMaster)
}

export default assetMastersSaga;



