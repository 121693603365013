export function CashIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="Group_149319" data-name="Group 149319" transform="translate(0.184)">
        <rect id="Rectangle_23692" data-name="Rectangle 23692" width="48" height="48" rx="8" transform="translate(-0.184)" fill="#fff" />
        <g id="money" transform="translate(10.816 13)">
          <path id="Path_198514" data-name="Path 198514" d="M5,10H27a1,1,0,0,0,0-2H5a1,1,0,0,0,0,2ZM7,7H25a1,1,0,0,0,0-2H7A1,1,0,0,0,7,7Zm21,4H4a1,1,0,0,0-1,1V26a1,1,0,0,0,1,1H28a1,1,0,0,0,1-1V12A1,1,0,0,0,28,11ZM7.71,15.71A1.05,1.05,0,0,1,7,16a1,1,0,0,1-.38-.08,1.15,1.15,0,0,1-.33-.21A1.05,1.05,0,0,1,6,15a1.091,1.091,0,0,1,.29-.71,1.15,1.15,0,0,1,.33-.21A1.006,1.006,0,0,1,8,15,1.05,1.05,0,0,1,7.71,15.71ZM16,23a4,4,0,1,1,4-4A4,4,0,0,1,16,23Zm9.71.71A1,1,0,1,1,26,23a1.05,1.05,0,0,1-.29.71Z" transform="translate(-3 -5)" fill="#12131a" />
        </g>
      </g>
    </svg>

  );
}
