import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { AuthPage } from "../../constants/login";
import { SharedConstants } from "@mprofit/shared";

export interface AuthState {
 ActiveAuthPage: AuthPage;
 LoginPayload?: SharedConstants.LoginPayload;
};

const initialState: AuthState = {
 ActiveAuthPage: AuthPage.LoginForm
};

export const authSlice = createSlice({
 name: 'authPage',
 initialState,
 reducers: {
  activePage: (state, action: PayloadAction<{ activeAuth: number }>) => {
   state.ActiveAuthPage = action.payload.activeAuth
  },
  setLoginPayload: (state, action: PayloadAction<SharedConstants.LoginPayload | undefined>) => {
    state.LoginPayload = action.payload;
  },
  handleBack: (state) => {
   if (state.ActiveAuthPage > 1) {
    state.ActiveAuthPage = state.ActiveAuthPage === AuthPage.PasswordResetCodeVerification ? AuthPage.LoginForm : state.ActiveAuthPage - 1;
    state.LoginPayload = undefined;
   }
  }
 }
})

//#region Selectors

const authSelector = (state: RootState) => state.auth;
const selectActiveAuthPage = (state: RootState) => authSelector(state).ActiveAuthPage;
const selectLoginPayload = (state: RootState) => authSelector(state).LoginPayload;

//#endregion

export default authSlice.reducer;
export const Actions = authSlice.actions;
export const Selectors = { authSelector, selectActiveAuthPage, selectLoginPayload };