import { FunctionComponent } from "react";
import { Navigate, useSearchParams, useLocation } from 'react-router-dom'
import { MPrUrlParams } from "../classes/MPrUrlParams";

let token = true;

export function withCondition(
  Component: FunctionComponent<{ MPrUrlParams: MPrUrlParams }>,
  condition: boolean,
  redirectTo: string
) {

  return function InnerComponent(props: any) {
    const location = useLocation();
    const [searchParams] = useSearchParams();

    var mprUrlParams: MPrUrlParams = new MPrUrlParams();
    if (location && location.pathname.startsWith('/portfolio')) {
      var redirectToWithoutQueryParams = false;
      try {
        const urlParamsString = searchParams.get('p');
        if (!urlParamsString || urlParamsString.trim() === '') {
          redirectToWithoutQueryParams = false;
        } else {
          mprUrlParams = MPrUrlParams.getMPrUrlParamsFromString(urlParamsString);
        }
      } catch {
        redirectToWithoutQueryParams = true;
      }

      if (redirectToWithoutQueryParams) {
        return <Navigate to={{ pathname: location.pathname, search: '' }} replace />
      }
    }

    return condition ? <Component {...props} MPrUrlParams={mprUrlParams} /> : <Navigate to={redirectTo} replace />
  }
}

export const withLoggedIn = (Component: React.FunctionComponent<{ MPrUrlParams: MPrUrlParams }>) =>
  withCondition(Component, token, '/error')