
export function CapitalGainLargeIcon(props: any) {
 return (
  <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160">
   <g id="Group_154348" data-name="Group 154348" transform="translate(-24 -32)">
    <rect id="Rectangle_25935" data-name="Rectangle 25935" width="160" height="160" transform="translate(24 32)" fill="transparent" />
    <g id="Group_154336" data-name="Group 154336" transform="translate(26.5 49.5)" style={{ mixBlendMode: 'luminosity', isolation: 'isolate' }}>
     <g id="Group_154339" data-name="Group 154339" transform="translate(47.9 79.123)">
      <path id="Path_235054" data-name="Path 235054" d="M173.709,324.219v12.668c0,6.1,19.863,11.054,44.366,11.054s44.366-4.949,44.366-11.054V324.219Z" transform="translate(-173.709 -313.165)" fill="#acc18a" />
      <path id="Path_235055" data-name="Path 235055" d="M180.19,342.672a49.5,49.5,0,0,0,4.979,1.56V327.927H180.19Zm8.067,2.206c2.719.587,5.726,1.092,8.963,1.5V327.839h-8.963Zm25.893,2.757c1.037.018,2.081.03,3.137.03,1.976,0,3.918-.036,5.826-.1V330.815H214.15Zm13.046-.25q1.977-.113,3.884-.267v-16.3H227.2Zm24.5-3.87a39.606,39.606,0,0,0,3.884-1.4V326.48h-3.884Z" transform="translate(-172.921 -312.89)" fill="#acc18a" />
      <ellipse id="Ellipse_2267" data-name="Ellipse 2267" cx="44.366" cy="11.054" rx="44.366" ry="11.054" transform="translate(0 0)" fill="#acc18a" />
      <ellipse id="Ellipse_2268" data-name="Ellipse 2268" cx="36.029" cy="8.976" rx="36.029" ry="8.976" transform="translate(7.333 0.931)" fill="#acc18a" />
      <path id="Path_235056" data-name="Path 235056" d="M216.276,332.131c-19.362,0-35.15-3.806-35.989-8.578a2.278,2.278,0,0,0-.04.4c0,4.958,16.131,8.976,36.029,8.976s36.029-4.019,36.029-8.976a2.278,2.278,0,0,0-.04-.4C251.426,328.326,235.637,332.131,216.276,332.131Z" transform="translate(-172.914 -313.246)" fill="#acc18a" />
      <path id="Path_235057" data-name="Path 235057" d="M216.276,318.1c17.914,0,32.767,3.258,35.554,7.525a2.647,2.647,0,0,0,.476-1.451c0-4.958-16.131-8.976-36.029-8.976s-36.029,4.019-36.029,8.976a2.644,2.644,0,0,0,.476,1.451C183.509,321.355,198.361,318.1,216.276,318.1Z" transform="translate(-172.914 -314.263)" fill="#acc18a" />
      <path id="Path_235058" data-name="Path 235058" d="M218.075,333.86c-23.494,0-42.711-4.55-44.254-10.308a2.886,2.886,0,0,0-.112.747c0,6.105,19.863,11.054,44.366,11.054s44.366-4.948,44.366-11.054a2.886,2.886,0,0,0-.112-.747C260.786,329.309,241.569,333.86,218.075,333.86Z" transform="translate(-173.709 -313.246)" fill="#101010" />
      <path id="Path_235059" data-name="Path 235059" d="M236.313,324.537c.571.727.188,1.138-1.917,1.042-1.768-.081-2.419.242-2.371.889a2.69,2.69,0,0,1-.227,1.061c-.782,2.225-1.347,2.373-7.33,1.918-.533-.673-.491-1.185,1.636-1.525,2.372-.379,2.834-.856,2.9-2.3-3.642,0-7.3-.023-10.946.028a3.281,3.281,0,0,0-1.432.6c-.962.568-1.746,1.182-2.787,1.727a16.688,16.688,0,0,1-9.009,1.444c-3.633-.262-5.783-1.181-6.717-2.475-.629-.871-1.107-1.616-4.145-1.409-.284.019-1.082-.378-1.052-.569s.874-.533,1.244-.511c2.868.165,3.2-.5,3.166-1.318a.89.89,0,0,1,.075-.289c.668-2.137,1.309-2.3,7.1-1.856.624.747.227,1.257-1.871,1.6-1.764.287-2.5.916-2.231,1.811,3.265,0,6.522.033,9.768-.03a3.983,3.983,0,0,0,1.653-.636,32.891,32.891,0,0,1,3.55-1.99c4.926-2.01,12.99-1.394,15.7,1.155C232.581,324.317,232.581,324.317,236.313,324.537Zm-35.889,1.1c.606,1.029,1.99,1.483,4.346,1.451s3.636-.57,4.6-1.451Zm28.281-1.29c-.486-.952-2.028-1.3-4.1-1.359-2.514-.072-4.243.365-5.387,1.359Z" transform="translate(-171.373 -313.611)" fill="#acc18a" />
     </g>
     <g id="Group_154340" data-name="Group 154340" transform="translate(32.583 64.031)">
      <path id="Path_235060" data-name="Path 235060" d="M160.055,310.763v12.668c0,6.105,19.863,11.054,44.366,11.054s44.366-4.949,44.366-11.054V310.763Z" transform="translate(-160.055 -299.709)" fill="#acc18a" />
      <path id="Path_235061" data-name="Path 235061" d="M166.537,329.216a49.487,49.487,0,0,0,4.979,1.56V314.471h-4.979Zm8.067,2.205c2.719.587,5.726,1.092,8.963,1.5V314.382H174.6Zm25.893,2.758c1.038.018,2.081.031,3.137.031,1.976,0,3.918-.036,5.826-.1V317.359H200.5Zm13.046-.25q1.977-.113,3.884-.267v-16.3h-3.884Zm24.5-3.87a39.563,39.563,0,0,0,3.884-1.4v-15.64h-3.884Z" transform="translate(-159.267 -299.434)" fill="#acc18a" />
      <ellipse id="Ellipse_2269" data-name="Ellipse 2269" cx="44.366" cy="11.054" rx="44.366" ry="11.054" transform="translate(0)" fill="#acc18a" />
      <ellipse id="Ellipse_2270" data-name="Ellipse 2270" cx="36.029" cy="8.976" rx="36.029" ry="8.976" transform="translate(7.333 0.931)" fill="#acc18a" />
      <path id="Path_235062" data-name="Path 235062" d="M202.622,318.674c-19.362,0-35.15-3.806-35.989-8.578a2.272,2.272,0,0,0-.04.4c0,4.958,16.131,8.976,36.029,8.976s36.029-4.019,36.029-8.976a2.289,2.289,0,0,0-.04-.4C237.772,314.87,221.984,318.674,202.622,318.674Z" transform="translate(-159.26 -299.79)" fill="#acc18a" />
      <path id="Path_235063" data-name="Path 235063" d="M202.622,304.641c17.914,0,32.767,3.258,35.554,7.525a2.648,2.648,0,0,0,.476-1.451c0-4.958-16.131-8.976-36.029-8.976s-36.029,4.019-36.029,8.976a2.644,2.644,0,0,0,.476,1.451C169.855,307.9,184.709,304.641,202.622,304.641Z" transform="translate(-159.26 -300.807)" fill="#acc18a" />
      <path id="Path_235064" data-name="Path 235064" d="M204.421,320.4c-23.494,0-42.711-4.551-44.254-10.308a2.89,2.89,0,0,0-.112.747c0,6.105,19.863,11.054,44.366,11.054s44.366-4.949,44.366-11.054a2.891,2.891,0,0,0-.112-.747C247.133,315.853,227.916,320.4,204.421,320.4Z" transform="translate(-160.055 -299.79)" fill="#101010" />
      <path id="Path_235065" data-name="Path 235065" d="M222.66,311.081c.571.727.188,1.138-1.917,1.042-1.768-.081-2.419.242-2.371.889a2.689,2.689,0,0,1-.227,1.061c-.782,2.225-1.347,2.373-7.33,1.917-.533-.673-.491-1.186,1.637-1.525,2.372-.379,2.834-.856,2.9-2.3-3.642,0-7.3-.024-10.946.028a3.283,3.283,0,0,0-1.432.6c-.962.568-1.746,1.182-2.787,1.727a16.691,16.691,0,0,1-9.009,1.443c-3.633-.262-5.783-1.181-6.717-2.475-.629-.87-1.107-1.616-4.144-1.409-.284.019-1.082-.378-1.052-.569s.874-.533,1.244-.511c2.868.165,3.2-.5,3.166-1.318a.9.9,0,0,1,.075-.289c.669-2.137,1.309-2.3,7.1-1.856.624.747.227,1.257-1.871,1.6-1.764.287-2.5.916-2.231,1.811,3.265,0,6.522.033,9.768-.03a3.981,3.981,0,0,0,1.653-.636,32.836,32.836,0,0,1,3.55-1.99c4.926-2.01,12.99-1.394,15.7,1.155C218.928,310.861,218.928,310.861,222.66,311.081Zm-35.889,1.1c.606,1.029,1.99,1.483,4.346,1.451s3.636-.57,4.6-1.451Zm28.281-1.291c-.486-.952-2.028-1.3-4.1-1.359-2.514-.072-4.243.364-5.387,1.359Z" transform="translate(-157.719 -300.155)" fill="#acc18a" />
     </g>
     <g id="Group_154341" data-name="Group 154341" transform="translate(40.811 47.574)">
      <path id="Path_235066" data-name="Path 235066" d="M167.386,296.091v12.668c0,6.105,19.863,11.054,44.366,11.054s44.366-4.949,44.366-11.054V296.091Z" transform="translate(-167.386 -285.037)" fill="#acc18a" />
      <path id="Path_235067" data-name="Path 235067" d="M173.867,314.544a49.485,49.485,0,0,0,4.979,1.56V299.8h-4.979Zm8.067,2.205c2.719.587,5.726,1.093,8.963,1.5V299.71h-8.963Zm25.893,2.758c1.037.018,2.081.03,3.137.03,1.976,0,3.918-.036,5.826-.1V302.687h-8.963Zm13.046-.25q1.977-.113,3.884-.267v-16.3h-3.884Zm24.5-3.87a39.737,39.737,0,0,0,3.884-1.4V298.353h-3.884Z" transform="translate(-166.598 -284.762)" fill="#acc18a" />
      <ellipse id="Ellipse_2271" data-name="Ellipse 2271" cx="44.366" cy="11.054" rx="44.366" ry="11.054" transform="translate(0 0)" fill="#acc18a" />
      <ellipse id="Ellipse_2272" data-name="Ellipse 2272" cx="36.029" cy="8.976" rx="36.029" ry="8.976" transform="translate(7.332 0.931)" fill="#acc18a" />
      <path id="Path_235068" data-name="Path 235068" d="M209.952,304c-19.362,0-35.15-3.806-35.989-8.578a2.271,2.271,0,0,0-.04.4c0,4.958,16.131,8.976,36.029,8.976s36.029-4.019,36.029-8.976a2.282,2.282,0,0,0-.04-.4C245.1,300.2,229.314,304,209.952,304Z" transform="translate(-166.591 -285.118)" fill="#acc18a" />
      <path id="Path_235069" data-name="Path 235069" d="M209.952,289.969c17.914,0,32.767,3.258,35.554,7.525a2.647,2.647,0,0,0,.476-1.451c0-4.958-16.131-8.976-36.029-8.976s-36.029,4.019-36.029,8.976a2.644,2.644,0,0,0,.475,1.451C177.186,293.227,192.038,289.969,209.952,289.969Z" transform="translate(-166.591 -286.135)" fill="#acc18a" />
      <path id="Path_235070" data-name="Path 235070" d="M211.752,305.732c-23.494,0-42.711-4.55-44.254-10.308a2.89,2.89,0,0,0-.112.747c0,6.105,19.863,11.054,44.366,11.054s44.366-4.949,44.366-11.054a2.886,2.886,0,0,0-.112-.747C254.463,301.181,235.246,305.732,211.752,305.732Z" transform="translate(-167.386 -285.118)" fill="#101010" />
      <path id="Path_235071" data-name="Path 235071" d="M229.99,296.409c.571.727.188,1.137-1.917,1.042-1.768-.081-2.419.242-2.371.889a2.686,2.686,0,0,1-.227,1.061c-.782,2.225-1.347,2.373-7.33,1.918-.533-.673-.491-1.186,1.636-1.526,2.372-.379,2.834-.856,2.9-2.3-3.642,0-7.3-.024-10.946.028a3.281,3.281,0,0,0-1.432.6c-.962.568-1.746,1.182-2.787,1.727a16.69,16.69,0,0,1-9.009,1.443c-3.633-.262-5.783-1.181-6.717-2.475-.629-.87-1.107-1.616-4.145-1.409-.284.019-1.082-.378-1.052-.569s.874-.533,1.244-.512c2.868.165,3.2-.5,3.166-1.318a.893.893,0,0,1,.075-.29c.668-2.137,1.309-2.3,7.1-1.856.624.747.227,1.257-1.871,1.6-1.764.287-2.5.917-2.231,1.812,3.265,0,6.522.033,9.768-.03a3.979,3.979,0,0,0,1.653-.636,32.849,32.849,0,0,1,3.55-1.99c4.926-2.01,12.99-1.394,15.7,1.155C226.259,296.189,226.259,296.189,229.99,296.409Zm-35.889,1.1c.606,1.029,1.99,1.483,4.346,1.452s3.636-.57,4.6-1.452Zm28.281-1.291c-.486-.952-2.028-1.3-4.1-1.359-2.514-.072-4.243.365-5.387,1.359Z" transform="translate(-165.05 -285.483)" fill="#acc18a" />
     </g>
     <g id="Group_154342" data-name="Group 154342" transform="translate(51.587 33.342)">
      <path id="Path_235072" data-name="Path 235072" d="M177,283.4V296.07c0,6.105,19.863,11.054,44.366,11.054s44.366-4.949,44.366-11.054V283.4Z" transform="translate(-177.001 -272.348)" fill="#acc18a" />
      <path id="Path_235073" data-name="Path 235073" d="M183.483,301.856a49.5,49.5,0,0,0,4.979,1.56V287.11h-4.979Zm8.067,2.205c2.719.587,5.726,1.092,8.963,1.5V287.022H191.55Zm25.893,2.757c1.038.018,2.081.03,3.137.03q2.965,0,5.826-.1V290h-8.963Zm13.046-.249q1.977-.113,3.884-.267V290h-3.884Zm24.5-3.871a39.649,39.649,0,0,0,3.884-1.4V285.664h-3.884Z" transform="translate(-176.213 -272.073)" fill="#acc18a" />
      <ellipse id="Ellipse_2273" data-name="Ellipse 2273" cx="44.366" cy="11.054" rx="44.366" ry="11.054" transform="translate(0)" fill="#acc18a" />
      <ellipse id="Ellipse_2274" data-name="Ellipse 2274" cx="36.029" cy="8.976" rx="36.029" ry="8.976" transform="translate(7.333 0.931)" fill="#acc18a" />
      <path id="Path_235074" data-name="Path 235074" d="M219.568,291.314c-19.362,0-35.15-3.806-35.989-8.578a2.271,2.271,0,0,0-.04.4c0,4.958,16.13,8.977,36.029,8.977s36.029-4.019,36.029-8.977a2.286,2.286,0,0,0-.04-.4C254.719,287.509,238.93,291.314,219.568,291.314Z" transform="translate(-176.206 -272.429)" fill="#acc18a" />
      <path id="Path_235075" data-name="Path 235075" d="M219.568,277.281c17.914,0,32.767,3.258,35.554,7.525a2.645,2.645,0,0,0,.476-1.451c0-4.958-16.131-8.976-36.029-8.976s-36.029,4.019-36.029,8.976a2.644,2.644,0,0,0,.476,1.451C186.8,280.538,201.654,277.281,219.568,277.281Z" transform="translate(-176.206 -273.446)" fill="#acc18a" />
      <path id="Path_235076" data-name="Path 235076" d="M221.367,293.044c-23.494,0-42.711-4.55-44.254-10.308a2.891,2.891,0,0,0-.112.747c0,6.105,19.863,11.054,44.366,11.054s44.366-4.949,44.366-11.054a2.89,2.89,0,0,0-.112-.747C264.079,288.492,244.862,293.044,221.367,293.044Z" transform="translate(-177.001 -272.429)" fill="#101010" />
      <path id="Path_235077" data-name="Path 235077" d="M239.606,283.721c.571.727.188,1.137-1.917,1.042-1.768-.081-2.419.242-2.371.89a2.687,2.687,0,0,1-.227,1.061c-.782,2.225-1.347,2.373-7.33,1.918-.533-.673-.491-1.186,1.636-1.525,2.372-.379,2.834-.856,2.9-2.3-3.642,0-7.3-.023-10.946.028a3.282,3.282,0,0,0-1.432.6c-.962.568-1.746,1.182-2.787,1.727a16.688,16.688,0,0,1-9.009,1.444c-3.633-.262-5.783-1.181-6.717-2.475-.629-.87-1.107-1.616-4.145-1.409-.284.019-1.082-.378-1.052-.569s.874-.533,1.244-.512c2.868.165,3.2-.5,3.166-1.318a.889.889,0,0,1,.075-.289c.668-2.137,1.309-2.3,7.1-1.856.624.747.227,1.257-1.871,1.6-1.764.287-2.5.916-2.231,1.811,3.265,0,6.522.032,9.768-.03a3.982,3.982,0,0,0,1.653-.636,32.9,32.9,0,0,1,3.55-1.99c4.926-2.01,12.99-1.394,15.7,1.155C235.874,283.5,235.874,283.5,239.606,283.721Zm-35.889,1.1c.606,1.028,1.99,1.483,4.346,1.451s3.636-.57,4.6-1.451ZM232,283.532c-.486-.952-2.028-1.3-4.1-1.359-2.514-.072-4.243.365-5.387,1.359Z" transform="translate(-174.665 -272.794)" fill="#acc18a" />
     </g>
     <g id="Group_154343" data-name="Group 154343" transform="translate(64.565 17.387)">
      <path id="Path_235078" data-name="Path 235078" d="M188.562,269.178v12.668c0,6.105,19.863,11.054,44.366,11.054s44.366-4.949,44.366-11.054V269.178Z" transform="translate(-188.562 -258.124)" fill="#acc18a" />
      <path id="Path_235079" data-name="Path 235079" d="M195.043,287.632a49.475,49.475,0,0,0,4.979,1.56V272.887h-4.979Zm8.067,2.205c2.719.587,5.726,1.092,8.963,1.5V272.8H203.11ZM229,292.6c1.037.018,2.081.03,3.137.03,1.976,0,3.918-.036,5.826-.1V275.775H229Zm13.046-.25q1.977-.113,3.884-.267v-16.3h-3.884Zm24.5-3.87a39.65,39.65,0,0,0,3.884-1.4V271.44h-3.884Z" transform="translate(-187.774 -257.849)" fill="#acc18a" />
      <ellipse id="Ellipse_2275" data-name="Ellipse 2275" cx="44.366" cy="11.054" rx="44.366" ry="11.054" transform="translate(0 0)" fill="#acc18a" />
      <ellipse id="Ellipse_2276" data-name="Ellipse 2276" cx="36.029" cy="8.976" rx="36.029" ry="8.976" transform="translate(7.333 0.932)" fill="#acc18a" />
      <path id="Path_235080" data-name="Path 235080" d="M231.129,277.09c-19.362,0-35.15-3.806-35.989-8.578a2.27,2.27,0,0,0-.04.4c0,4.958,16.13,8.976,36.029,8.976s36.029-4.019,36.029-8.976a2.289,2.289,0,0,0-.04-.4C266.279,273.286,250.49,277.09,231.129,277.09Z" transform="translate(-187.767 -258.205)" fill="#acc18a" />
      <path id="Path_235081" data-name="Path 235081" d="M231.129,263.057c17.914,0,32.767,3.258,35.554,7.525a2.647,2.647,0,0,0,.476-1.451c0-4.958-16.131-8.976-36.029-8.976S195.1,264.173,195.1,269.13a2.644,2.644,0,0,0,.476,1.451C198.362,266.315,213.215,263.057,231.129,263.057Z" transform="translate(-187.767 -259.222)" fill="#acc18a" />
      <path id="Path_235082" data-name="Path 235082" d="M232.928,278.82c-23.494,0-42.711-4.55-44.254-10.308a2.888,2.888,0,0,0-.112.747c0,6.1,19.863,11.054,44.366,11.054s44.366-4.949,44.366-11.054a2.889,2.889,0,0,0-.112-.747C275.639,274.269,256.423,278.82,232.928,278.82Z" transform="translate(-188.562 -258.205)" fill="#101010" />
      <path id="Path_235083" data-name="Path 235083" d="M251.167,269.5c.571.727.188,1.137-1.917,1.042-1.768-.081-2.419.242-2.371.89a2.684,2.684,0,0,1-.227,1.061c-.782,2.225-1.347,2.373-7.33,1.917-.533-.673-.491-1.186,1.636-1.525,2.372-.379,2.834-.856,2.9-2.3-3.642,0-7.3-.023-10.946.028a3.282,3.282,0,0,0-1.432.6c-.962.568-1.746,1.182-2.787,1.727a16.689,16.689,0,0,1-9.009,1.444c-3.633-.263-5.783-1.181-6.717-2.475-.629-.87-1.107-1.616-4.144-1.409-.284.019-1.082-.378-1.052-.569s.874-.533,1.244-.511c2.868.165,3.2-.5,3.166-1.318a.891.891,0,0,1,.075-.289c.668-2.137,1.309-2.3,7.1-1.856.624.747.227,1.257-1.871,1.6-1.764.287-2.5.916-2.231,1.811,3.265,0,6.522.033,9.768-.03a3.982,3.982,0,0,0,1.653-.636,32.881,32.881,0,0,1,3.55-1.99c4.926-2.01,12.99-1.394,15.7,1.155C247.434,269.277,247.434,269.277,251.167,269.5Zm-35.889,1.1c.606,1.029,1.99,1.483,4.346,1.451s3.636-.57,4.6-1.451Zm28.281-1.29c-.486-.952-2.028-1.3-4.1-1.359-2.514-.072-4.243.365-5.387,1.359Z" transform="translate(-186.226 -258.57)" fill="#acc18a" />
     </g>
     <g id="Group_154344" data-name="Group 154344" transform="translate(50.586)">
      <path id="Path_235084" data-name="Path 235084" d="M176.106,253.676v12.668c0,6.105,19.863,11.054,44.366,11.054s44.366-4.948,44.366-11.054V253.676Z" transform="translate(-176.106 -242.622)" fill="#acc18a" />
      <path id="Path_235085" data-name="Path 235085" d="M182.588,272.13a49.477,49.477,0,0,0,4.979,1.56V257.384h-4.979Zm8.066,2.205c2.719.587,5.726,1.093,8.963,1.5V257.3h-8.963Zm25.893,2.757c1.038.018,2.081.03,3.137.03,1.976,0,3.918-.036,5.826-.1V260.272h-8.963Zm13.047-.249q1.977-.113,3.884-.267v-16.3h-3.884Zm24.5-3.87a39.666,39.666,0,0,0,3.884-1.4V255.939h-3.884Z" transform="translate(-175.318 -242.347)" fill="#acc18a" />
      <ellipse id="Ellipse_2277" data-name="Ellipse 2277" cx="44.366" cy="11.054" rx="44.366" ry="11.054" transform="translate(0 0)" fill="#acc18a" />
      <ellipse id="Ellipse_2278" data-name="Ellipse 2278" cx="36.029" cy="8.976" rx="36.029" ry="8.976" transform="translate(7.333 0.931)" fill="#acc18a" />
      <path id="Path_235086" data-name="Path 235086" d="M218.673,261.588c-19.362,0-35.15-3.806-35.989-8.578a2.279,2.279,0,0,0-.04.4c0,4.958,16.13,8.976,36.029,8.976s36.029-4.019,36.029-8.976a2.285,2.285,0,0,0-.04-.4C253.823,257.783,238.035,261.588,218.673,261.588Z" transform="translate(-175.311 -242.703)" fill="#acc18a" />
      <path id="Path_235087" data-name="Path 235087" d="M218.673,247.555c17.914,0,32.767,3.258,35.554,7.525a2.648,2.648,0,0,0,.476-1.451c0-4.958-16.131-8.977-36.029-8.977s-36.029,4.019-36.029,8.977a2.644,2.644,0,0,0,.476,1.451C185.906,250.812,200.759,247.555,218.673,247.555Z" transform="translate(-175.311 -243.72)" fill="#acc18a" />
      <path id="Path_235088" data-name="Path 235088" d="M220.472,263.318c-23.494,0-42.711-4.55-44.254-10.308a2.888,2.888,0,0,0-.112.747c0,6.105,19.863,11.054,44.366,11.054s44.366-4.949,44.366-11.054a2.889,2.889,0,0,0-.112-.747C263.184,258.766,243.967,263.318,220.472,263.318Z" transform="translate(-176.106 -242.703)" fill="#101010" />
     </g>
     <g id="Group_154330" data-name="Group 154330" transform="translate(0 47.574)">
      <ellipse id="Ellipse_2316" data-name="Ellipse 2316" cx="40.814" cy="38.79" rx="40.814" ry="38.79" transform="translate(0 0)" fill="#acc18a" />
      <path id="Path_235205" data-name="Path 235205" d="M260.772,121.405c19.063-.131,34.706,14.634,35.627,32.559.018-.48.032-.961.028-1.446-.128-18.6-16.1-33.572-35.672-33.438s-35.336,15.323-35.208,33.925c0,.485.024.966.048,1.446C226.27,136.516,241.709,121.536,260.772,121.405Z" transform="translate(-218.798 -116.404)" fill="#101010" />
      <path id="Path_235206" data-name="Path 235206" d="M261.209,175.336c-19.063.131-34.7-14.067-35.623-31.993a13.4,13.4,0,0,0-.028,1.446c1.357,19.695,19.322,31.368,35.656,31.256,17.141-.118,33.758-12.564,35.223-31.744a11.5,11.5,0,0,0-.048-1.446C295.716,160.792,280.273,175.2,261.209,175.336Z" transform="translate(-218.798 -106.74)" fill="#101010" />
      <path id="Path_235207" data-name="Path 235207" d="M262.263,178.6c-20.989.145-38.208-15.488-39.221-35.223-.02.529-.035,1.058-.031,1.592.141,20.481,17.724,36.963,39.274,36.815s38.905-16.872,38.765-37.351c0-.534-.027-1.063-.053-1.591C300.252,162.585,283.251,178.453,262.263,178.6Z" transform="translate(-219.828 -106.746)" fill="#101010" />
      <path id="Path_235215" data-name="Path 235215" d="M15.411,36.818a1.632,1.632,0,0,1-.868-.21,2.834,2.834,0,0,1-.657-.579L1.21,21.249a6.714,6.714,0,0,1-.473-.6,1.544,1.544,0,0,1-.158-.815v-.579a1.137,1.137,0,0,1,.316-.842,1.137,1.137,0,0,1,.842-.316H4.892a14.484,14.484,0,0,0,6.075-1,5.606,5.606,0,0,0,2.84-2.682,9.076,9.076,0,0,0,.763-3.787,9.076,9.076,0,0,0-.763-3.787,5.65,5.65,0,0,0-2.735-2.682,12.507,12.507,0,0,0-5.549-1H1.157A1.137,1.137,0,0,1,.316,2.84,1.137,1.137,0,0,1,0,2V1.157A1.137,1.137,0,0,1,.316.316,1.137,1.137,0,0,1,1.157,0h20.3A1.137,1.137,0,0,1,22.3.316a1.137,1.137,0,0,1,.316.842V2a1.137,1.137,0,0,1-.316.842,1.137,1.137,0,0,1-.842.316H13.781L11.256,1.683q3.787.736,5.207,3.182a11.268,11.268,0,0,1,1.42,5.759,11.577,11.577,0,0,1-1.157,5.155,8.562,8.562,0,0,1-3.813,3.787,15.039,15.039,0,0,1-7.074,1.42H4.523l-.368-1.368L17.568,35.293a1.061,1.061,0,0,1,.368.736.645.645,0,0,1-.316.6,1.435,1.435,0,0,1-.736.184ZM1.157,11.571a1.137,1.137,0,0,1-.842-.316A1.137,1.137,0,0,1,0,10.414V9.573a1.137,1.137,0,0,1,.316-.842,1.137,1.137,0,0,1,.842-.316h20.3a1.086,1.086,0,0,1,1.157,1.157v.842a1.086,1.086,0,0,1-1.157,1.157Z" transform="translate(30.88 20.381)" fill="#131100" />
     </g>
    </g>
   </g>
  </svg>
 );
}