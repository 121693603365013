export function BackArrowIcon(props: any) {
 return (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
   <g id="Group_153417" data-name="Group 153417" transform="translate(-32 -11)">
    <g id="Rectangle_23766" data-name="Rectangle 23766" transform="translate(32 11)" fill={props.color} stroke="#707070" strokeWidth="1" opacity="0">
     <rect width="24" height="24" stroke="none" />
     <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
    </g>
    <path id="Path_234930" data-name="Path 234930" d="M13.821,15.643,8.25,10.071,13.821,4.5" transform="translate(31.75 13.357)" fill="none" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
   </g>
  </svg>
 );
}
