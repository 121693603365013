import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface ReportsState {
    OpenSnackBars: number[]
}

const initialState: ReportsState = {
    OpenSnackBars: []
};

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        openSnackBar: (state, action: PayloadAction<{JobId: number}>) => {
            if (state.OpenSnackBars.findIndex(x => x === action.payload.JobId) < 0) {
                state.OpenSnackBars.push(action.payload.JobId);
            }
        },
        closeSnackBar: (state, action: PayloadAction<{JobId: number}>) => {
            state.OpenSnackBars = state.OpenSnackBars.filter(x => x !== action.payload.JobId);
        }
    }
})

//#region Selectors

const reportsSelector = (state: RootState) => state.reports;
const selectOpenSnackBars = (state: RootState) => reportsSelector(state).OpenSnackBars;

//#endregion

export default reportsSlice.reducer;
export const Actions = reportsSlice.actions;
export const Selectors = { reportsSelector, selectOpenSnackBars };