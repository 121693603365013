import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import PerformanceLayout from "../layout";
import { SharedRedux, SharedUtilities } from "@mprofit/shared";
import { Dispatch } from "react";
import { useAppSelector } from "packages/web/src/redux/hooks";


interface L1_AA_Performance_Props {
    MPrUrlParams: MPrUrlParams;
}

export default function L1_AA_Performance({ MPrUrlParams }: L1_AA_Performance_Props) {
    const categName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategName) || 'Category';
    const assetAllocationCategID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategID);

    const advancedChartTitle = getAdvancedChartTitle(assetAllocationCategID);

    return (
        <PerformanceLayout
            MPrUrlParams={MPrUrlParams}
            OnRefreshXIRRTotal={onRefreshXIRR}
            OnRefreshXIRRFYWise={onRefreshXIRRFYWise}
            OnRefreshXIRRYearWise={onRefreshXIRRYearWise}
            OnRefreshXIRRTable={onRefreshXIRRTable}
            OnRefreshXIRRTablePortfolioWise={onRefreshXIRRTablePortfolioWise}
            OnRefreshXIRRBenchmark={onRefreshXIRRBenchmark}
            GenericTitleText={`${categName} XIRR`}
            SpecificTitleText={categName}
            AdvancedChartTitleText={advancedChartTitle}
            HideAdvancedChart={advancedChartTitle === undefined}
            RowFieldName={'Asset Class'}
            IsAllowTour={true}
            ShowPMSData={false}
        />
    );
}

const getXIRRPayload = (urlParams: MPrUrlParams, force?: boolean, noRefresh?: boolean): SharedRedux.Performance.RefreshXIRRPayload | undefined => {
    if (urlParams && urlParams.PortfolioID && urlParams.PortfolioID > 0) {
        return {
            CCID: urlParams.DatabaseID || -1, FamilyId: urlParams.FamilyID || -1, PFID: urlParams.PortfolioID || -1,
            AssetAllocationCategID: urlParams.AssetAllocationCategID,
            Force: force === true, NoRefresh: noRefresh === true
        }
    }

    return undefined;
}

const onRefreshXIRR = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRTotal(refreshXIRRPayload));
    }
}

const onRefreshXIRRFYWise = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRFYWise(refreshXIRRPayload));
    }
}

const onRefreshXIRRYearWise = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRYearWise(refreshXIRRPayload));
    }
}

const onRefreshXIRRTable = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRTable(refreshXIRRPayload));
    }
}

const onRefreshXIRRTablePortfolioWise = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRTablePortfolioWise({ ...refreshXIRRPayload, IsGroupByPortfolio: true }));
    }
}

const onRefreshXIRRBenchmark = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRBenchmark(refreshXIRRPayload));
    }
}

const getAdvancedChartTitle = (assetAllocationCategID: number | undefined) => {
    return SharedUtilities.getAdvancedChartTitleForAssetAllocationCategID(assetAllocationCategID);
}