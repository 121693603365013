import React from 'react'

export default function SectorIcon(props: any) {
    return (
        <svg {...props} id="Group_222594" data-name="Group 222594" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <rect id="Rectangle_34576" data-name="Rectangle 34576" width="16" height="16" fill="#fff" />
            <g id="Group_222593" data-name="Group 222593" transform="translate(0 1)">
                <path id="Path_237217" data-name="Path 237217" d="M22.742,28.254h-1V21.121A1.019,1.019,0,0,0,20.721,20.1h-2.8a.255.255,0,0,0-.255.255v7.9H16.645V17.071a1.046,1.046,0,0,0-.392-.828A1.019,1.019,0,0,0,15.06,16.2L9.965,19.593a1.019,1.019,0,0,0-.453.85v7.812h-1a.521.521,0,0,0-.527.476.509.509,0,0,0,.509.543H22.759a.509.509,0,0,0,.509-.543A.521.521,0,0,0,22.742,28.254ZM12.314,26.726a.509.509,0,0,1-.543.509.521.521,0,0,1-.476-.527v-.984a.522.522,0,0,1,.476-.527.509.509,0,0,1,.543.509Zm0-3.566a.509.509,0,0,1-.543.509.522.522,0,0,1-.476-.527v-.984a.522.522,0,0,1,.476-.527.509.509,0,0,1,.543.509Zm2.547,3.566a.509.509,0,0,1-.543.509.521.521,0,0,1-.476-.526v-.985a.522.522,0,0,1,.476-.526.509.509,0,0,1,.543.509Zm0-3.566a.509.509,0,0,1-.543.509.522.522,0,0,1-.476-.526v-.985a.522.522,0,0,1,.476-.526.509.509,0,0,1,.543.509Z" transform="translate(-7.982 -16.028)" />
            </g>
        </svg>
    )
}
