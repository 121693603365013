import { Box, Grid } from '@mui/material'
import React from 'react'
import CustomTypography from '../../../shared/customTypography'
import { CategoryIconsTransparent } from '../category-icons'

interface IReportOverviewType {
    handleConfigure: any
    data?: any
}

const BackgroundColor: any = {
    0: '#F5CFA0',
    1: '#A9D6D1',
    2: '#DAE5A1',
    3: '#D2D4FF',
    4: '#D6CDA4',
    5: '#ACC18A'
}

export default function ReportoverviewCard({ handleConfigure, data }: IReportOverviewType) {
    return (
        <Box>
            <Box sx={{ padding: { xs: '16px 0px 8px 0px', xl: '36px 0px 16px 0px' } }}>
                <CustomTypography variant='Medium24'>Performance reports</CustomTypography>
                <CustomTypography variant="Regular18">Select a report type to see the available reports</CustomTypography>
            </Box>
            <Grid container spacing={3}>
                {data.Reports.map((report: any, index: number) => {
                    return (
                        <Grid item xs={12} lg={6} key={index}>
                            <Box sx={style.cardContainer}>
                                <Box
                                    sx={{
                                        minHeight: '212px',
                                        minWidth: '210px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '8px 0px 0px 8px',
                                        backgroundColor: BackgroundColor[index]
                                    }}
                                >
                                    {CategoryIconsTransparent[data.CategoryId]}
                                </Box>
                                <Box sx={style.rightContent} onClick={() => handleConfigure(report)}>
                                    <CustomTypography variant='Regular20' padding="24px 0px">{report.ReportName}</CustomTypography>
                                    <CustomTypography variant='Regular18' sx={style.description}>{report.ShortDescription}</CustomTypography>
                                    <CustomTypography variant='Medium16' sx={{ color: '#5F6F57', '&:hover': { textDecoration: "underline", color: { xs: '#5F6F57' } } }}>Read more</CustomTypography>
                                </Box>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

const style = {
    modalContainer: {
        width: '85%',
        maxHeight: '90%',
        height: '90%',
        background: '#FFFFFF',
        borderRadius: '10px',
        padding: '0px 32px 16px 32px',
        display: 'flex',
        flexDirection: 'column',
    },
    mainContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    modalHeader: {
        flex: '0 0 100px',
        padding: '8px 0px',
        alignItems: 'center'
    },
    cardContainer: {
        display: 'flex',
        cursor: 'pointer',
    },
    rightContent: {
        flex: '1 1 100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '24px',
        border: '1px solid #EBECF2',
        borderRadius: '0px 8px 8px 0px',
        '&:hover': {
            background: '#F9F9F9'
        }
    },
    description: {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        marginBottom: '24px',
        paddingRight: '30px',
        color: { xs: '#64677A' }
    }
}

