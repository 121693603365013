import { SharedConstants, formatDecimal, formatDecimalSplit, formatDecimalWithRupeeSymbol } from '@mprofit/shared';
import { Box, Grid, styled } from '@mui/material'
import CardValueBackground from 'packages/web/src/assets/icons/todayL4/cardValueBackground';
import { CustomTypography } from 'packages/web/src/components/shared';
import LossGainBox from 'packages/web/src/components/shared/loss-gain-box';

interface L4ValueCardProps {
    BackgroundColor: string,
    ScreenType?: string,
    AssetName?: string,
    RefNoStr?: string,
    TotalFields?: SharedConstants.TotalFields
}

export default function L4TodayValueCard({ BackgroundColor, ScreenType, AssetName, TotalFields = {}, RefNoStr }: L4ValueCardProps) {
    const totalCurrValue = formatDecimalSplit(TotalFields?.CurrValue, 2, 'IN');

    return (
        <Grid sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', background: BackgroundColor, }}>
            <Box sx={{ display: 'flex' }}>
                <LeftSide >
                    <GreyBoxContainer>
                        <CustomTypography color={'#fff'}>{ScreenType}</CustomTypography>
                    </GreyBoxContainer>
                    <CustomTypography variant='Regular24' sx={{ fontSize: "32px", lineHeight: '43px' }}>{AssetName}</CustomTypography>
                    {
                        RefNoStr &&
                        <CustomTypography variant='Regular20' sx={{ fontSize: "32px", lineHeight: '43px' }}>{'#' + RefNoStr}</CustomTypography>
                    }

                </LeftSide>
                <RightSide>
                    <CardValueBackground />
                </RightSide>
            </Box>
            <Grid container spacing={2} sx={{ padding: '24px 20px 24px 30px', display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={4} sx={{ borderRight: '1px solid #D8DAE5' }}>
                    <CustomTypography variant='Regular18'>Current value</CustomTypography>
                    <CustomTypography variant='Regular24'>
                        {'₹' + totalCurrValue[0]}
                        <span style={{ fontSize: "18px" }}>{'.' + (totalCurrValue[1] || '00')}</span>
                    </CustomTypography>
                </Grid>
                <Grid item xs={4} sx={{ borderRight: '1px solid #D8DAE5' }}>
                    <CustomTypography variant='Regular18'>Today's gain</CustomTypography>
                    <CustomTypography variant='Regular22' sx={{ display: 'flex' }}>
                        {formatDecimal(TotalFields.TGain, 2, 'IN')}
                        <LossGainBox marginleft={true} Gain={TotalFields.TGainPct} GainUp={TotalFields.TUp == true} />
                    </CustomTypography>
                </Grid>
                <Grid item xs={4}>
                    <CustomTypography variant='Regular18'>Unrealised gain</CustomTypography>
                    <CustomTypography variant='Regular22' sx={{ display: 'flex' }}>
                        {formatDecimal(TotalFields.OGain, 2, 'IN')}
                        <LossGainBox marginleft={true} Gain={TotalFields.OGainPct} GainUp={TotalFields.OUp == true} />
                    </CustomTypography>
                </Grid>
            </Grid>
        </Grid >
    )
}



const GreyBoxContainer = styled(Box)({
    background: '#3A3D4D',
    color: 'white',
    width: 'fit-content',
    borderRadius: '4px',
    padding: '5px 8px',
    marginBottom: '8px'
});

const RightSide = styled(Box)({
    // flex: '1 1 112px'
})

const LeftSide = styled(Box)({
    width: '100%',
    padding: "24px 0px 24px 32px"
})



