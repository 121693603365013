import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SetAPIURL } from '@mprofit/shared';
import { SetAPIResponseInterceptors } from 'packages/shared/src/api';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.REACT_APP_CLOUD_API_URL) {
  SetAPIURL(process.env.REACT_APP_CLOUD_API_URL);
  SetAPIResponseInterceptors({On401Error: () => {}, On403Error: () => {}});
}

if (process.env.NODE_ENV === "production" && window) {
  window.console.log = () => { };
}

export * as WebRedux from './redux';
