import { MastersMgmtJobType, PortfolioMappingFieldToUse } from "./mastersMgmt";

export interface BackgroundJobsByID {
    [JobId: number]: BackgroundJobsDetailed;
}

export interface BackgroundJobsStatus {
    StatusCode: Job_Status_Code;
    Mode: number;
    ErrorText: string;
}

export interface BackgroundJobsDetailed {
    JobId: number;
    JobType: Job_Type;
    StatusCode: Job_Status_Code;
    StatusText: string;
    StatusColorClass: string;
    JobTypeText: string
    Date: string;
    LastUpdatedTime: string;
    FamilyId: number;
    FamilyName: string;
    PFID: number;
    PortfolioName: string;
    IsNewPeriod: boolean;
    FromPeriod: string;
    ToPeriod: string;
    AssetType: string;
    AssetTypeID: number;
    IsAsOnDate: boolean;
    AssetName: string;
    AMID: number;
    Mode: number;
    BulkCorporateActionType: BulkCorporateActionType;
    ErrorText: string;
    UserID: number;
    UserEmail: string;
    IsMyJob: boolean;
    JobUserID: number;
    MastersMgmtJobType: MastersMgmtJobType;
    PortfolioMappingFieldToUse: PortfolioMappingFieldToUse;
    FullTemplateGroupName: string;

    ActionText?: string;
    IsForwardAction: boolean;
    RunActionOnJobOpen: boolean;
    ShowBackToPortfolio: boolean;
    ShowUndo: boolean;
    UndoText: string;
    HideActionFromJobModal: boolean;
    ShowViewCompleted: boolean;
    ViewCompletedText: string;
    isUndoAllowed: {[JobType: number]: boolean};

    Details: string;
    LongDetails: string;
    PeriodText: string;
    ExtraFieldLabel: string;
    ExtraFieldValue: string;

    IsPeriodSet: boolean;
    IsAssetClassLevel: boolean;
    SID: number;

    FromPFID: number;
    FromPortfolioName: string;
    ToPFID: number;
    ToPortfolioName: string;
    FromFamilyId: number;
    FromFamilyName: string;
    ToFamilyId: number;
    ToFamilyName: string;
    UpdateIdsOnCompletion: boolean;
    DeleteOldOnCompletion: boolean;

    IsAllFamilies: boolean;
    IsFnoSetPeriod: boolean;
    IsFno: boolean;

    PeriodLabel: string;

    CorpActionID: number;
}

export enum Job_Status_Code {
    Cancelled = -999,
    Queued = 8,
    Initialised = 9,
    Pending = 10, // Use for Reports processing state
    OnHold = 11,
    Error = 12, // Use for Reports error state
    WaitingForData = 13,
    Completed = 14, // Use for Reports completed state
    Reco_Completed = 15,
    StrategySourceNotFound = 16,
    Processing = 20, // Use for Reports processing state
    Template_Req = 40,
    Wrong_Template = 45,
    Wrong_Template_Version = 46,
    File_Not_Supported = 47,
    No_Data_Found = 48,
    PW_Req = 50,
    PW_Incorrect = 51,
    ASK_ATYPE = 52,
    PF_MAP = 55,
    PF_FOLIO_MAP = 57,
    AST_BRKR_MAP = 60,
    PVIEW = 70,
    Reco_Diff = 72,
    PF_AST = 77,
    DUP_FILE = 80,
    DUP_TRANS = 83,
    LARGE_CHARGES = 85,
    ALL = 90,
    IMPERR = 200,
    LINK_DOWNLOAD_ERROR = 202,
    UNDOIMP_ERROR = 205,
    UNDOIMP_INVALID = 210,
    IMP_DATEERR = 215,
    UNDO_ERR = 220,
    UNDO_SPECIFIC_ERR = 225,
    Reversed = 300,
    Auto_Import_Setup_Error = 701,
    Auto_Import_Filter_Error = 702,
    License_Expired = 801,
    License_Not_Supported = 802,
    Template_Inactive = 901,
    Template_Not_Found = 902,
    No_Access = 999,
    Invalid_Field = 1100,
    Duplicate_Values = 1105
};

export enum Job_Type {
    ALL = -1,
    IMPJOB = 15,
    EmailJob = 20,
    ClientSharingEmailJob = 25,
    GlobalReportJob = 30,
    ReportJob = 35,
    BatchReportJob = 40,
    BulkCorpActionJob = 45,
    APIReportJob = 50,
    MastersMgmtJob = 55,
    MastersMgmtTemplateRequest = 60,
    ImportRecoJob = 65,
    CopyPortfolioJob = 70,
    BulkDeleteJob = 75,
    DBMigrationJob = 80,
    RecalculationJob = 85,
    AutoCorpActionJob = 90
}

export enum BulkCorporateActionType {
    SELECT = -999,
    DIVIDEND = 1,
    BONUS = 2,
    SPLIT = 3,
    MERGER = 4,
    DEMERGER = 5
}

export enum BulkCorporateActionMode {
    None = -999,
    Submitted = 0,
    Preview = 1,
    Save = 2,
    Complete = 3,
    Undo = -1,
    UndoComplete = -2
}

export const Status_Color_Class = {
    GREEN: "success",
    YELLOW: "in-process",
    RED: "error",
    NEUTRAL: "neutral"
}