import greenArrowUp from '../../../assets/icons/greenArrowUp.svg'
import redArrowDown from '../../../assets/icons/redArrowDown.svg'

import { Box } from '@mui/system'

import { DraggableIcon } from '../../../assets/icons/draggableIcon'

import CustomTypography from '../customTypography'
import { SharedConstants, formatDecimal, formatPercent } from '@mprofit/shared'

interface TableListItemProps {
    item: SharedConstants.WatchlistCardItem,
}

const TableListItem = ({ item }: TableListItemProps) => {
    return (
        <Box sx={style.tableContainer}>
            <Box sx={{ width: "24px", margin: "auto" }}>
                <DraggableIcon />
            </Box>
            <Box sx={style.itemDetails}>
                <CustomTypography noWrap variant='Regular20'> {item.AssetName}</CustomTypography>
                {/* //     remove alignItems if item.AssetSymbol has value     // */}
                {/* <CustomTypography variant='Regular16' className={styles.assetSymbol}>{item.AssetSymbol}</CustomTypography> */}
            </Box>
            <Box sx={{paddingRight: '10px'}}>
                <CustomTypography variant='Regular18' sx={{ paddingRight: formatPercent(item.TGain, 2, 'IN').toString().length > 7 ? '6px' : '14px' }}>{formatDecimal(item.Price, 2, 'IN')}</CustomTypography>
            </Box>

            <Box sx={{ height: 65, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <CustomTypography variant='Medium18' sx={{ display: 'flex', gap: '5px', color: { xs: item.TUp ? '#5F854C' : '#C94C40' } }}>
                    {
                        item.TUp !== undefined &&
                        <img style={{ paddingTop: "3px" }}
                            height="18"
                            src={item.TUp ? greenArrowUp : redArrowDown}
                            alt="arrow">
                        </img>
                    }
                    {formatDecimal(item.TGain, 2, 'IN')}
                </CustomTypography>
                <CustomTypography variant='Regular16' sx={{ gap: '5px', color: { xs: item.TUp ? '#5F854C' : '#C94C40' } }}>{item.TGainPct === undefined ? '' : `(${formatPercent(item.TGainPct, 2, 'IN', true)})`}</CustomTypography>
            </Box>

        </Box >
    )
}

export default TableListItem

const style = {
    tableContainer: {
        padding: '17px 24px 18px 24px',
        borderTop: '1px solid #EBECF2',
        borderRadius: '4px',
        opacity: 1,
        display: 'flex'
    },
    infoTableContainer: {
        display: 'flex',
        width: '100%'
    },
    itemDetails: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 1 100%',
        padding: '0px 10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        alignItems: 'center'
    }
}