export function ART(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226570" data-name="Group 226570" transform="translate(-17890 6539.297)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 -0.297)" fill="#fff" opacity="0" />
          <path id="Path_237174" data-name="Path 237174" d="M8.542,14.083A5.542,5.542,0,0,1,8.542,3c3.06,0,5.542,2.205,5.542,4.926a2.63,2.63,0,0,1-2.771,2.463H9.773A1.231,1.231,0,0,0,9.157,12.7a.8.8,0,0,1-.616,1.385" transform="translate(1.31 1.31)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          <circle id="Ellipse_2131" data-name="Ellipse 2131" cx="0.308" cy="0.308" r="0.308" transform="translate(9.544 6.773)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
          <circle id="Ellipse_2875" data-name="Ellipse 2875" cx="0.308" cy="0.308" r="0.308" transform="translate(6.544 8.773)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
          <circle id="Ellipse_2876" data-name="Ellipse 2876" cx="0.308" cy="0.308" r="0.308" transform="translate(12.544 8.157)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
        </g>
      </g>
    </svg>
  );
}
