
export function PercentageIcon(props: any) {
 return (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 48 48">
   <g id="Group_149319" data-name="Group 149319" transform="translate(0)">
    <rect id="Rectangle_23692" data-name="Rectangle 23692" width="48" height="48" rx="4" transform="translate(0)" fill="#f5f5f6" />
    <path id="c26be4502c8a3bb9532df1880fcecf51" d="M6.953,7.605a.738.738,0,1,0,.738-.738A.738.738,0,0,0,6.953,7.605ZM11.125,0A11.125,11.125,0,1,0,22.25,11.125,11.125,11.125,0,0,0,11.125,0ZM5.563,7.605A2.129,2.129,0,1,1,7.692,9.734,2.129,2.129,0,0,1,5.563,7.605Zm1.883,8.184a.7.7,0,0,1-.985-.983L14.8,6.462a.7.7,0,1,1,.983.983L7.445,15.789Zm7.113.9a2.129,2.129,0,1,1,2.129-2.129,2.129,2.129,0,0,1-2.129,2.129Zm-.738-2.129a.738.738,0,1,0,.738-.738A.738.738,0,0,0,13.82,14.558Z" transform="translate(12.875 12.875)" />
   </g>
  </svg>
 );
}

