import { Box, CircularProgress, Container, Grid } from "@mui/material";
import CustomTypography from "../../../shared/customTypography";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ButtonWithTextAndIcon from "../../../shared/button-with-label-and-icon";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SyntheticEvent, useEffect, useState } from "react";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedRedux } from "@mprofit/shared";
import dateFormat from 'dateformat';

interface ISnackbarMessageProps {
  JobId: number,
  handleViewReport: (event: Event | SyntheticEvent<Element, Event>, reason?: string, index?: number) => Promise<void>
}

export default function SnackbarMessage({ handleViewReport, JobId }: ISnackbarMessageProps) {
  const [isDownloadable, setIsDownloadable] = useState<boolean>(false);
  const bgJobDetails = useAppSelector((state) => SharedRedux.BackgroundJobs.Selectors.selectBackgroundJobsDetailed(state, JobId));

  useEffect(() => {
    if (bgJobDetails !== undefined && bgJobDetails.StatusCode === 14) {
      setIsDownloadable(true)
    }
  }, [bgJobDetails]);

  return (
    <Container>
      <Grid container width={681}>
        <Grid item xs={3}>
          {
            !isDownloadable &&
            <Box paddingTop="16px">
              <CircularProgress />
            </Box>
          }
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CustomTypography variant="Regular16">{bgJobDetails?.StatusText}</CustomTypography>
              <Box sx={{ padding: '0px 5px', color: 'black' }}>•</Box>
            </Box>
            <CustomTypography variant="Regular16">{dateFormat(bgJobDetails?.Date, "d mmm yyyy, h:MM TT")}</CustomTypography>
          </Box>
          <CustomTypography padding="16px 0px" variant="Regular20">{bgJobDetails?.JobTypeText}</CustomTypography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', padding: '7px 16px 6px 16px', background: '#EAF4DF', borderRadius: '16px', width: 'fit-content' }}>
              <CalendarTodayIcon sx={{ color: 'gray' }} />
              <CustomTypography paddingLeft={2} variant="Regular16">{bgJobDetails?.PeriodText}</CustomTypography>
            </Box>
            {
              isDownloadable &&
              <Box>
                <ButtonWithTextAndIcon
                  buttonlabel={bgJobDetails?.ViewCompletedText}
                  startIcon={<VisibilityIcon />}
                  onClick={handleViewReport}
                />
              </Box>
            }
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}




