////@ts-nocheck

import { call, put, select, takeLatest } from "redux-saga/effects";
import { Actions as PortfolioActions, Selectors as PortfolioSelectors } from "../modules/portfoliosReducer";
import type { PayloadAction } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { ResponseGenerator } from "../../api";
import { AssetSearchItem, AssetTypeEnum, AssetTypeNameMapping, FamiliesByID, Family, GlobalSearchFilter, GlobalSearchQuery, GlobalSearchResults, PFolioTypes, Portfolio } from "../../constants";
import { filterItems } from "../../utilities";
import { Selectors as PortfolioSummarySelectors } from "../modules/portfolioSummaryReducer";

function* onGlobalSearch(action: PayloadAction<GlobalSearchQuery>) {
    var filteredFamilyList: Family[] = [];

    // if (action.payload.Filter == GlobalSearchFilter.ALL || action.payload.Filter == GlobalSearchFilter.FAMILY) {
    //     var familyList: Family[] | undefined  = yield select(PortfolioSelectors.selectFamilyList);
        
    //     if (familyList && familyList.length > 0) {
    //         filteredFamilyList = filterItems(action.payload.Term, familyList, ['FamilyName'], true)
    //     }
    // }

    var filteredPortfolioList: Portfolio[] = [];

    if (action.payload.Filter == GlobalSearchFilter.ALL || action.payload.Filter == GlobalSearchFilter.PORTFOLIO) {
        var portfolioList: Portfolio[] | undefined = yield select((state) => PortfolioSelectors.selectAllPortfoliosListForPType(state, PFolioTypes.PMS));

        if (portfolioList && portfolioList.length > 0) {
            const familiesByID: FamiliesByID = yield select(PortfolioSelectors.selectFamiliesByID);
            if (familiesByID) {
                portfolioList = portfolioList.map((portfolio) => {
                    return {
                        ...portfolio,
                        FamilyName: familiesByID.ByID[portfolio.FamilyId]?.FamilyName
                    }
                })
            }
        }

        if (portfolioList && portfolioList.length > 0) {
            filteredPortfolioList = filterItems(action.payload.Term, portfolioList, ['PName', 'PFullName', 'FamilyName'], true);
        }
    }

    var tempFilteredAssetsList: Array<{id: string, text: string}> = [];
    var filteredAssetsList: AssetSearchItem[] = [];

    if (action.payload.Filter == GlobalSearchFilter.ALL || action.payload.Filter == GlobalSearchFilter.ASSET) {
        var assetsList: Array<{id: string, text: string}> | undefined  = yield select(PortfolioSummarySelectors.selectAssetsForSearch);

        if (assetsList && assetsList.length > 0) {
            tempFilteredAssetsList = filterItems(action.payload.Term, assetsList, ['text'], true);
        }

        if (tempFilteredAssetsList.length > 0) {
            filteredAssetsList = tempFilteredAssetsList.map<AssetSearchItem>(item => {
                var idSplit = item.id.split('$');
                var AMID = idSplit.length > 1 ? +(idSplit[1]) : -1;
                var AssetTypeID = idSplit.length > 0 ? +(idSplit[0]): -1;
                return {
                    Name: item.text,
                    AMID,
                    AssetTypeID,
                    AssetTypeName: AssetTypeNameMapping[AssetTypeID as AssetTypeEnum]
                }
            }).filter(item => item.AssetTypeID > 0 && item.AMID > 0);
        }
    }

    var globalSearchResults: GlobalSearchResults = {
        Family: filteredFamilyList,
        Portfolio: filteredPortfolioList,
        Assets: filteredAssetsList
    }

    yield put(PortfolioActions.setGlobalSearchResults(globalSearchResults));
}

function* searchSaga() {
    yield takeLatest(PortfolioActions.globalSearch.type, onGlobalSearch);
}
export default searchSaga;



