export function DefaultTableIcon(props: any) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="232.82" height="101.044" viewBox="0 0 232.82 101.044">
      <g id="Group_149792" data-name="Group 149792" transform="translate(0 0)">
        <g id="Group_149149" data-name="Group 149149" transform="translate(95.792)">
          <path id="Path_198034" data-name="Path 198034" d="M581.291,390.524a1.162,1.162,0,0,0-1.158,1.163v39.664a1.162,1.162,0,0,0,1.158,1.163H716a1.162,1.162,0,0,0,1.158-1.163V391.687A1.162,1.162,0,0,0,716,390.524Z" transform="translate(-580.133 -390.524)" fill="#eaf4df" />
          <path id="Path_198035" data-name="Path 198035" d="M590.878,437.432H722.1V401.27H590.878Z" transform="translate(-587.975 -398.356)" fill="#fff" />
          <path id="Path_198036" data-name="Path 198036" d="M1010.136,506.94a1.926,1.926,0,0,0,0,3.853h7.675a1.926,1.926,0,0,0,0-3.853Z" transform="translate(-892.574 -475.366)" fill="#5f854c" />
          <rect id="Rectangle_23688" data-name="Rectangle 23688" width="88.597" height="0.507" transform="translate(38.558 27.837)" fill="#253326" />
          <ellipse id="Ellipse_1906" data-name="Ellipse 1906" cx="11.176" cy="11.22" rx="11.176" ry="11.22" transform="translate(8.863 9.775)" fill="#5f854c" />
          <path id="Path_198037" data-name="Path 198037" d="M610.005,319.67l-.28-.422,9.206-6.162,6.314,3.043,9.081-5.825.123.052,17.941,7.684,14.385-7.095,13.969,5.685,17.552-15.608.335.38-17.786,15.816L666.794,311.5l-14.39,7.1-18.031-7.722-9.093,5.833-6.307-3.04Z" transform="translate(-571.307 -294.663)" fill="#253326" />
          <path id="Path_198038" data-name="Path 198038" d="M724.506,507.917a1.647,1.647,0,0,0,0,3.294H741.67a1.647,1.647,0,0,0,0-3.294Z" transform="translate(-684.308 -476.078)" fill="#d0e3bb" />
        </g>
        <g id="Group_150858" data-name="Group 150858" transform="translate(0 59.054)">
          <path id="Path_198034-2" data-name="Path 198034" d="M581.291,390.524a1.162,1.162,0,0,0-1.158,1.163v39.664a1.162,1.162,0,0,0,1.158,1.163H716a1.162,1.162,0,0,0,1.158-1.163V391.687A1.162,1.162,0,0,0,716,390.524Z" transform="translate(-580.133 -390.524)" fill="#eaf4df" />
          <path id="Path_198035-2" data-name="Path 198035" d="M590.878,437.432H722.1V401.27H590.878Z" transform="translate(-587.975 -398.356)" fill="#fff" />
          <path id="Path_198036-2" data-name="Path 198036" d="M1010.136,506.94a1.926,1.926,0,0,0,0,3.853h7.675a1.926,1.926,0,0,0,0-3.853Z" transform="translate(-892.574 -475.366)" fill="#5f854c" />
          <rect id="Rectangle_23688-2" data-name="Rectangle 23688" width="88.597" height="0.507" transform="translate(38.558 27.837)" fill="#253326" />
          <ellipse id="Ellipse_1906-2" data-name="Ellipse 1906" cx="11.176" cy="11.22" rx="11.176" ry="11.22" transform="translate(8.863 9.775)" fill="#5f854c" />
          <path id="Path_198037-2" data-name="Path 198037" d="M610.005,319.67l-.28-.422,9.206-6.162,6.314,3.043,9.081-5.825.123.052,17.941,7.684,14.385-7.095,13.969,5.685,17.552-15.608.335.38-17.786,15.816L666.794,311.5l-14.39,7.1-18.031-7.722-9.093,5.833-6.307-3.04Z" transform="translate(-571.307 -294.663)" fill="#253326" />
          <path id="Path_198038-2" data-name="Path 198038" d="M724.506,507.917a1.647,1.647,0,0,0,0,3.294H741.67a1.647,1.647,0,0,0,0-3.294Z" transform="translate(-684.308 -476.078)" fill="#d0e3bb" />
        </g>
      </g>
    </svg>

  );
}