import { Box, IconButton, Stack } from '@mui/material';
import { CustomTypography } from '../shared';
import ButtonWithTextAndIcon from '../shared/button-with-label-and-icon';
import { PMSReportsIcon } from '../../assets/icons/PMSReportsIcon';
import { DownloadIcon } from '../../assets/icons/downloadIcon';
import CashflowItem from './CashflowItem';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { SharedRedux } from '@mprofit/shared';
import { useEffect } from 'react';
import { MPrUrlParams } from '../../classes/MPrUrlParams';
import { useLocation, useNavigate } from 'react-router-dom';
import routeNames from '../../routes/routeNames';
import ButtonText from '../shared/button-text';
import { BackIcon } from '../../assets/icons/backIcon';
import { ChevronLeft } from '@mui/icons-material';

export interface CashflowsProps {
    MPrUrlParams: MPrUrlParams
}

const Cashflows = (props: CashflowsProps) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const cashMgmtDetails = useAppSelector(SharedRedux.Performance.Selectors.selectCashMgmtDetails);
    const cashMgmtRows = cashMgmtDetails?.CashMgmtRows;

    useEffect(() => {
        if (!cashMgmtDetails) {
            const payload = {
                FamilyId: props.MPrUrlParams.FamilyID || -1,
                PFID: props.MPrUrlParams.PortfolioID || -1,
                CCID: props.MPrUrlParams.DatabaseID || -1
            }
            dispatch(SharedRedux.Performance.Actions.getCashMgmtDetails(payload));
        }
    }, [props.MPrUrlParams, dispatch, cashMgmtDetails])

    return (
        <Box px={5}>
            <Box>
                <ButtonText
                    buttonText="Back to dashboard"
                    startIcon={<ChevronLeft sx={{ height: 24, width: 24 }} />}
                    handleClick={() => navigate({ pathname: routeNames.PERFORMANCE, search: location.search })}
                />
            </Box>
            <Stack mt={4} direction="row" alignItems="center" justifyContent="space-between">
                <CustomTypography
                    variant="Medium28"
                    sx={{
                        color: '#12131A',
                    }}
                >
                    Performance Cashflows
                </CustomTypography>
                <Stack direction="row" gap={1}>
                    {/* <IconButton disableRipple sx={{ border: '1px solid #D8DAE5', backgroundColor: 'white', borderRadius: '4px', '&:hover': { backgroundColor: '#EFF0F5' }, }}>
                        <DownloadIcon />
                    </IconButton> */}
                    <ButtonWithTextAndIcon
                        buttonlabel="View Summary"
                        startIcon={<PMSReportsIcon />}
                        onClick={() => navigate({ pathname: routeNames.PERFORMANCE, search: location.search })}
                    />
                </Stack>
            </Stack>

            <Box mt={4}>
                {
                    cashMgmtRows !== undefined && cashMgmtRows?.length > 0 ? cashMgmtRows?.map((item, index) => {
                        return <CashflowItem key={index} item={item} />
                    }) : null
                }
            </Box>

        </Box>
    )
}



export default Cashflows;