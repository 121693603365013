import { MFTransactionReportTypes } from "../constants";

export const getMFTransReportSortDir = (value: any) => {
    if (value.ReportTransType == MFTransactionReportTypes.MF_DateTrans) {
        return -1;
    } else {
        return 1;
    }
}

export const getMFTransReportSortType = (value: any) => {
    if (value.ReportTransType == MFTransactionReportTypes.MF_DateTrans) {
        return 1;
    } else {
        return 0;
    }
}

export const getGrandfatheringFlagForBuyback = (value: any) => {
    if (value.ShowBuyBack) {
        return false;
    } else {
        return value.ShowGrndFathrGain;
    }
}