import { Box, Skeleton } from "@mui/material";
import CustomTypography from "../customTypography";
import XIRRTotalChart from "../../portfolio/performance/xirr-total-chart";
import { SharedConstants, formatPercent } from "@mprofit/shared";
import { useNumberFormatPrefs } from "../../hooks/userPreferencesHooks";
// import { XIRRCardPDFDownloadButton } from "./pdf";

interface ChartCardProps {
  IsPerformanceLoading: boolean;
  PerformanceXIRRData: SharedConstants.XIRRTotalItem | undefined;
  IsPerformanceViewAllowed: boolean;
  PeriodText: string;
  GenericTitleText: string;
  BackgroundColor?: string;
  TitleColor?: string;
  PeriodTextColor?: string;
  PeroidBgColor?: string;
  ContainerSx?: any;
  IsPMS?: boolean;
}

export const getPMSXIRRDisplay = (PerformanceXIRRData: SharedConstants.XIRRTotalItem | undefined, IsPerformanceViewAllowed: boolean) => {
  var XIRRDisplay: string | undefined = PerformanceXIRRData?.Status === SharedConstants.XIRRStatus.NoInvestments || PerformanceXIRRData?.XIRR === undefined || PerformanceXIRRData?.XIRR === null ? (
      `${!IsPerformanceViewAllowed ? "" : "N/A"}`
    ) : PerformanceXIRRData?.XIRR && PerformanceXIRRData?.XIRR > 100 ? (
      ">10000%"
    ) : (
      formatPercent(PerformanceXIRRData?.XIRR, 2, "IN")
    );

  return XIRRDisplay
}

export default function PerformanceXIRRChartCard({
  IsPerformanceLoading,
  PerformanceXIRRData,
  IsPerformanceViewAllowed,
  PeriodText,
  GenericTitleText,
  BackgroundColor,
  TitleColor,
  PeriodTextColor,
  PeroidBgColor,
  ContainerSx = {},
  IsPMS = false
}: ChartCardProps) {
  const numberFormatPrefs = useNumberFormatPrefs();
  var XIRRDisplay: string | undefined = undefined;
  if (!IsPerformanceLoading) {
    XIRRDisplay = getPMSXIRRDisplay(PerformanceXIRRData, IsPerformanceViewAllowed);
  }
  return (
    <>
      <Box
        sx={{
          padding: "20px 16px",
          // width: { xs: "50%", lg: "50%", lg1: "49%" },
          backgroundColor: BackgroundColor || "#cfe3c9",
          borderRadius: "10px",
          // position: "absolute",
          // left: "-24px",
          // top: "-24px",
          // maxWidth: "360px",
          ...ContainerSx
        }}
      >
        <CustomTypography
          sx={{
            color: "#12131A",
            textAlign: "center",
            fontSize: {
              xs: "28px",
              lg: "28px",
              lg1: "34px",
              xl: "48px",
            },
            marginTop: { xs: "24px", lg1: "34px", xl: "48px" },
            marginBottom: {
              sm: "16px",
              lg: "16px",
              lg1: "16px",
              xl: "23px",
            },
            justifyContent: "center",
            width: "100%",
            display: "flex",
            minHeight: { xs: "33px", lg: "72px" },
            position: "relative",
          }}
        >
          {IsPerformanceLoading ? (
            <Box
              width="inherit"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Skeleton
                animation="wave"
                sx={{
                  bgcolor: "#daead8",
                  width: "60%",
                  height: "26px",
                }}
              />
            </Box>
          ) : XIRRDisplay}
        </CustomTypography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: { xs: "13px", lg: "20px" },
            paddingLeft: "4px",
            paddingRight: "4px",
          }}
        >
          <CustomTypography
            variant="Medium20"
            sx={{ color: { xs: TitleColor || "#5F6F57" }, textAlign: "center" }}
          >
            {GenericTitleText}
          </CustomTypography>
          {/* {
            !IsPMS && PerformanceXIRRData && !IsPerformanceLoading && [SharedConstants.XIRRStatus.Completed, SharedConstants.XIRRStatus.NoInvestments].includes(PerformanceXIRRData.Status) &&
            <XIRRCardPDFDownloadButton />
          } */}
          {/* <InfoIcon /> */}
        </Box>
        <CustomTypography
          variant="Regular18"
          sx={{
            width: "fit-content",
            backgroundColor: PeroidBgColor || "#5F6F57",
            color: { xs: PeriodTextColor || "#ffffff" },
            textAlign: "center",
            maxWidth: "90%",
            margin: "auto",
            padding: "4px 8px",
            borderRadius: "4px",
            marginBottom: "38px",
          }}
        >
          {PeriodText}
        </CustomTypography>
        <XIRRTotalChart isPerformanceLoading={IsPerformanceLoading} />
      </Box>
    </>
  );
}
