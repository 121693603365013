import styled from '@emotion/styled';
import { Logout } from '@mui/icons-material'
import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { useRef, useState } from 'react'
import { CustomTypography } from '../../../shared';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';
import { NavigateOnLogout, NavigateToPortfolio } from 'packages/web/src/routes/navigationMethods';
import { SharedConstants, SharedRedux } from '@mprofit/shared';

export default function ProfileMenu() {
    const [isOpen, setIsOpen] = useState(false);
    const openMenu = () => {
        setIsOpen(true);
    };
    const closeMenu = () => {
        setIsOpen(false);
    };

    const avatarRef = useRef<HTMLButtonElement>(null);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onLogout = () => {
        closeMenu();
        NavigateOnLogout({navigate, dispatch, IsUserAction: true});
    }

    const openHelpCenter = () => {
        closeMenu();
        window.open('https://help.mprofit.in', "_blank");
    }

    const userAlphabet = useAppSelector(SharedRedux.Login.Selectors.selectUserAlphabet);
    const userEmail = useAppSelector(SharedRedux.Login.Selectors.selectUserEmail);
    const userName = useAppSelector(SharedRedux.Login.Selectors.selectUserName);

    const activePortfolioIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolioIDs);

    const openTutorial = () => {
        closeMenu();

        dispatch(SharedRedux.Dashboard.Actions.addUpdateUserProfileItem({Item: {Key: 'IsWebTutorialViewed_Main', Value: false}, NoUpdateOnServer: true}));
        
        if (!activePortfolioIDs) return;

        NavigateToPortfolio({activePortfolio: activePortfolioIDs, navigate, dispatch, screen: SharedConstants.ScreenEnum.L1, dashboardTab: SharedConstants.DashboardTabs.TODAY })
    }

    if (!userAlphabet) return <></>;

    return (
    <Box>
    <ProfileContainer sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
            onClick={openMenu}
            ref={avatarRef}
            // size="small"
            aria-controls={isOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : undefined}
            sx={{ width: '100%', height: '100%' }}
            disableRipple
        >
            <Avatar sx={{ width: 48, height: 48, background: 'inherit' }}>{userAlphabet}</Avatar>
        </IconButton>
    </ProfileContainer>
    <Menu
        anchorEl={avatarRef?.current}
        id = "account-menu"
        open = {
            isOpen
        }
        onClose = {
            closeMenu
        }
        PaperProps = {
            {
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }
        }
        transformOrigin = {
            {
                horizontal: 'right',
                vertical: 'top'
            }
        }
        anchorOrigin = {
            {
                horizontal: 'right',
                vertical: 'bottom'
            }
        }
    >
        <MenuItem
            disableRipple
            disableTouchRipple
            style={{
                pointerEvents: 'none',  // Prevents any mouse events like hover or click,
                userSelect: 'text'
            }}
        >
            <Avatar sx={{ width: 40, height: 40, background: '#5f6f57' }} >{userAlphabet}</Avatar>
            <Box>
                <CustomTypography variant="Regular18">{userName}</CustomTypography>
                <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A' } }}>{userEmail}</CustomTypography>
            </Box>
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={closeMenu}>
        <ListItemIcon>
        <PersonAdd fontSize="small" sx={{ color: '#828699' }} />
        </ListItemIcon>
        Profile
        </MenuItem>
        <MenuItem onClick={closeMenu}>
        <ListItemIcon>
        <Settings fontSize="small" sx={{ color: '#828699' }} />
        </ListItemIcon>
        Activity Logs
        </MenuItem> */}
        <MenuItem onClick={openHelpCenter}>
            <ListItemIcon>
                <HelpOutlineOutlinedIcon fontSize="small" sx={{ color: '#828699' }} />
            </ListItemIcon>
            Help Center
        </MenuItem>
        <MenuItem onClick={() => {closeMenu(); contactSupport(true)}}>
            <ListItemIcon>
                <HeadsetMicOutlinedIcon fontSize="small" sx={{ color: '#828699' }} />
            </ListItemIcon>
            Contact Support
        </MenuItem>
        <MenuItem onClick={() => openTutorial()}>
            <ListItemIcon>
                <InfoIcon fontSize="small" sx={{ color: '#828699' }} />
            </ListItemIcon>
            App Tour
        </MenuItem>

        <Divider />

        <MenuItem onClick={onLogout}>
            <ListItemIcon>
                <Logout fontSize="small" sx={{ color: '#828699' }} />
            </ListItemIcon>
            Logout
        </MenuItem>

        <Divider />
        <MenuItem
            disableRipple
            disableTouchRipple
            style={{
                pointerEvents: 'none',  // Prevents any mouse events like hover or click,
                userSelect: 'text'
            }}
        >
            <CustomTypography sx={{ fontSize: { xs: '14px' }, color: { xs: "#64677A" } }}>MProfit Analytics</CustomTypography>
        </MenuItem>
    </Menu>
    </Box>
    )
}

const contactSupport = (isContactButton: boolean) => {
    try {
        if (isContactButton) {
            (window as any).FreshworksWidget('prefill', 'ticketForm', {
                custom_fields: {
                  cf_customer_selected_type: 'New analytics dashboards', //Number field
                }
              });
            (window as any).FreshworksWidget('open', 'ticketForm');
        } else {
            (window as any).FreshworksWidget('open');
        }
    } catch {
        window.open('https://www.mprofit.in/contact-us/', "_blank");
    }
}

const ProfileContainer = styled(Box)({
    maxHeight: '48px',
    backgroundColor: '#5f6f57',
    height: '48px',
    width: '48px',
    borderRadius: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '20px'
})