
import SvgIcon from '@mui/material/SvgIcon';

export function ZigzagArrowDown(props: any) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
        <defs>
          <clipPath id="clipPath">
            <rect id="Rectangle_23758" data-name="Rectangle 23758" width="24" height="24" fill="#fff" stroke="#707070" strokeWidth="1" />
          </clipPath>
        </defs>
        <g id="Group_148420" data-name="Group 148420" transform="translate(-1)">
          <g id="Mask_Group_41" data-name="Mask Group 41" transform="translate(1)" clipPath="url(#clipPath)">
            <path id="direction-curve" d="M6.12,20.981,0,14.862l1.236-1.236,4.009,4.01V11.718a2.631,2.631,0,0,0-.574-1.638L2.707,7.625a4.388,4.388,0,0,1-.958-2.731V0H3.5V4.893a2.631,2.631,0,0,0,.574,1.638L6.036,8.986a4.388,4.388,0,0,1,.958,2.731v5.918L11,13.626l1.236,1.236Z" transform="translate(1 6.867) rotate(-40)" fill="#c94c40" />
          </g>
        </g>
      </svg>


    </SvgIcon>
  );
}