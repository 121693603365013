export function Cross(props: any) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="Group_150711" data-name="Group 150711" transform="translate(-250)">
        <rect id="Rectangle_24197" data-name="Rectangle 24197" width="48" height="48" transform="translate(250)" fill="#fff" opacity="0" />
        <g id="Group_150712" data-name="Group 150712" transform="translate(258 8)">
          <path id="Path_198418" data-name="Path 198418" d="M0,0H32V32H0Z" fill="none" />
          <line id="Line_524" data-name="Line 524" x1="16" y2="16" transform="translate(8 8)" fill="none" stroke="#64677a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          <line id="Line_525" data-name="Line 525" x2="16" y2="16" transform="translate(8 8)" fill="none" stroke="#64677a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
      </g>
    </svg>
  );
}