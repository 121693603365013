export enum LicenseType {
    INVESTOR = 0,
    ADVISOR = 1,
    PRO = 2,
    INVESTORPLUS = 3,
    FREE = 100,
    LITE = 101,
    PLUS = 102,
    HNI = 103,
    FAMILYOFFICE = 104,
    WEALTHESSENTIALS = 201,
    WEALTHADVANCED = 202,
    WEALTHPREMIUM = 203
}

export enum B2X {
    Undefined = 0,
    B2B2C = 1,
    B2C = 2
}

export enum B2C_Tier {
    UNDEFINED = 0,
    FREE = 1,
    LITE = 2,
    PLUS = 3,
    HNI = 4,
    FAMILYOFFICE = 5,
    DESKTOP_HNI = 104,
    DESKTOP_MULTIFAMILY = 105
}

export enum B2B2C_Tier {
    UNDEFINED = 0,
    ESSENTIAL = 1,
    ADVANCED = 2,
    PREMIUM = 3,
    STANDARD = 4,
    PLATINUM = 5
}