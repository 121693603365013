export function PR(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226569" data-name="Group 226569" transform="translate(-17890 6539)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" fill="#fff" opacity="0" />
          <path id="Path_237171" data-name="Path 237171" d="M6.1,14.538V11.413a.721.721,0,0,1,.721-.721H8.26a.721.721,0,0,1,.721.721v3.125m0,0h2.885V3.349M8.981,14.538h4.808V7.968M2.25,14.538h.962m11.538,0H3.212M2.25,6.846,5.135,5.8M12.827,3l-.962.349m0,3.978,1.923.641m.962.321-.962-.321M5.135,5.8V3H3.212V14.538M5.135,5.8l6.731-2.448" transform="translate(1.812 1.063)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </g>
      </g>
    </svg>
  );
}
