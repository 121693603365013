import { createAction, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ClientRootState } from "../reducers";
import { BackgroundJobsByID, BackgroundJobsDetailed, BackgroundJobsStatus } from "../../constants";
import { Actions as LoginActions } from './loginReducer';

export interface SharedBackgroundJobsState {
    Jobs: BackgroundJobsByID;
}

const initialState: SharedBackgroundJobsState = {
    Jobs: {}
}

const BackgroundJobsSlice = createSlice({
    name: 'backgroundJobs',
    initialState,
    reducers: {
        pollBackgroundJob: (state, action: PayloadAction<BackgroundJobStatusPayload>) => { },
        // refreshBackgroundJobsDetailed: (state, action: PayloadAction<BackgroundJobStatusPayload>) => { },
        // setBackgroundJobsStatus: (state, action: PayloadAction<{JobId: number, Status: BackgroundJobsStatus}>) => {
        //     if(state.Jobs[action.payload.JobId]) {
        //         state.Jobs[action.payload.JobId].Status = action.payload.Status;
        //     } else {
        //         state.Jobs[action.payload.JobId] = {Status: action.payload.Status};
        //     }
        // },
        setBackgroundJobsDetailed: (state, action: PayloadAction<{ JobId: number, Detailed: BackgroundJobsDetailed }>) => {
            if (state.Jobs[action.payload.JobId]) {
                state.Jobs[action.payload.JobId] = action.payload.Detailed;
            } else {
                state.Jobs[action.payload.JobId] = action.payload.Detailed;
            }
        },
        clearBackgroundJobsInfo: (state, action: PayloadAction<{ JobId: number }>) => {
            delete state.Jobs[action.payload.JobId];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(LoginActions.logout, () => initialState)
            .addCase(LoginActions.resetData, () => initialState)
    }
});

export interface BackgroundJobStatusPayload {
    JobId: number;
    JobUserID?: number;
    IsAdvisorData?: boolean;
}

//#region Selectors

const backgroundJobsSelector = (state: ClientRootState) => state.shared.backgroundJobs;
const selectBackgroundJobsDetailed = (state: ClientRootState, jobId: number) => backgroundJobsSelector(state).Jobs[jobId];

//#endregion

export default BackgroundJobsSlice.reducer;
export const Actions = BackgroundJobsSlice.actions;
export const Selectors = { backgroundJobsSelector, selectBackgroundJobsDetailed };