export function SortingIcon(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="Group_151893" data-name="Group 151893" transform="translate(-181)">
                <g id="Rectangle_24587" data-name="Rectangle 24587" transform="translate(181)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                    <rect width="16" height="16" stroke="none" />
                    <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
                </g>
                <path id="_9dd7c0c1bbd8cd45eba9d626fedf7d17" data-name="9dd7c0c1bbd8cd45eba9d626fedf7d17" d="M20.327,16.148a.309.309,0,0,1,.019-.309l3.316-4.764a.309.309,0,0,1,.507,0l3.316,4.764a.3.3,0,0,1-.257.473H20.6a.309.309,0,0,1-.275-.164Zm6.9,2.447H20.6a.3.3,0,0,0-.257.473l3.316,4.764a.309.309,0,0,0,.507,0l3.316-4.764a.3.3,0,0,0-.257-.473Z" transform="translate(165.085 -9.453)" fill="#12131a" />
            </g>
        </svg>

    );
}