import React from 'react'
import XirrBarChart from '../../../shared/xirr-bar-chart'
import { Box } from '@mui/material'
import Lottie from "react-lottie";
import * as animationData from '../../../../assets/animation/assetWisePerformanceAnimation.json'
import { DefaultTableIcon } from 'packages/web/src/assets/icons/DefaultTableIcon';

let placeholderData = [
    { Name: "Portfolio", ValueStr: "", IsBlack: true, Value: 0.7 },
    { Name: "Sensex", ValueStr: "", IsBlack: false, Value: 0.6 },
    { Name: "Nifty", ValueStr: "", IsBlack: false, Value: -0.7 },
    { Name: "BSE 100", ValueStr: "", IsBlack: false, Value: 0.4 },
    { Name: "BSE Midcap", ValueStr: "", IsBlack: false, Value: -0.2 }
]

export default function PortfolioXIRRLoader({chartHeading, chartSubHeading, IsPerformanceViewAllowed, SpecificTitleText}: {chartHeading: string, chartSubHeading: string, IsPerformanceViewAllowed: boolean, SpecificTitleText: string}) {
    const defaultOptions = {
        loop: IsPerformanceViewAllowed,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Box sx={{ position: 'absolute', top: "110px", height: "calc(100% - 210px)", left: "110px", width: "calc(100% - 110px)", backgroundColor: "rgba(255, 255, 255, .9)", zIndex: 1, backdropFilter: "blur(3px)" }} >
                <Box sx={{ width: '256px', height: '256px', position: 'relative', top: '50%', left: "50%", transform: 'translate(-50% , -50%)' }} >
                    <Lottie options={defaultOptions} height={256} width={256} />
                    <Box sx={{
                        position: 'absolute',
                        top: '80px',
                        left: '-10px'
                    }}>
                        <DefaultTableIcon />
                    </Box>
                </Box>
            </Box >
            <XirrBarChart
                key={'xirr-bar-chart-loader'}
                data={placeholderData}
                chartHeading={chartHeading}
                chartSubHeading={chartSubHeading}
                chartHeight={580}
                chartWidth={650}
                barSizeVal={14}
                domain={[-1, 1]}
                isLoading={true}
                IsPerformanceViewAllowed={IsPerformanceViewAllowed}
                isFirstBarBlack={true}
                SpecificTitleText={SpecificTitleText}
            />
        </Box >
    )
}
