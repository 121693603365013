export function XLSIcon(props: any) {
    return (
        <svg {...props} id="Group_154170" data-name="Group 154170" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <rect id="Rectangle_6639" data-name="Rectangle 6639" width="48" height="48" rx="4" fill="#3a3d4d" />
            <g id="Group_154172" data-name="Group 154172" transform="translate(12 11.842)">
                <path id="Path_198771" data-name="Path 198771" d="M22.051,16.291V13.219A3.949,3.949,0,0,0,18.1,9.27H16.347a1.316,1.316,0,0,1-1.316-1.316V6.2A3.949,3.949,0,0,0,11.082,2.25H8.888m2.633,0h-5.7A1.317,1.317,0,0,0,4.5,3.566V23.75a1.317,1.317,0,0,0,1.316,1.316H20.735a1.317,1.317,0,0,0,1.316-1.316V12.781A10.531,10.531,0,0,0,11.52,2.25Z" transform="translate(-1.276 -2.25)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <g id="Group_152113" data-name="Group 152113" transform="translate(0 12.158)">
                    <g id="Rectangle_24192" data-name="Rectangle 24192" transform="translate(1)" fill="#fff" stroke="#fff" strokeWidth="1">
                        <rect width="22" height="13" rx="2" stroke="none" />
                        <rect x="0.5" y="0.5" width="21" height="12" rx="1.5" fill="none" />
                    </g>
                    <text id="Name" transform="translate(12 10)" fontSize="10" fontFamily="Rubik-Medium, Rubik" fontWeight="500" letterSpacing="0.007em"><tspan x="-9" y="0">XLS</tspan></text>
                </g>
            </g>
        </svg>
    );
}
