export enum MastersMgmtJobType
{
    ADDRESS_DETAILS = 1,
    ADD_PORTFOLIOS = 2,
    EDIT_PORTFOLIO_NAMES = 3,
    EDIT_FAMILY_NAMES = 4,
    EDIT_FAMILY_CATEGS = 5,
    ADD_PORTFOLIO_MAPPINGS_FROM_PORTFOLIO_TABLE = 6,
    ADD_PORTFOLIO_MAPPINGS_BY_CODE = 7,
    ADD_PORTFOLIO_MAPPINGS_BY_PORTFOLIO_NAME = 8,
    SET_CASHMGMT_PORTFOLIOS = 9
}

export enum PortfolioMappingFieldToUse
{
    SHORT_NAME = 1,
    FULL_NAME = 2,
    PAN = 3,
    MANUAL = -999
}