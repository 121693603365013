import { Box, Grid, useTheme } from "@mui/material";
import styles from "./index.module.css";
import { DropdownItem } from "../../drop-down";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { FormatAmountUsingPrefsHelper, SharedConstants, formatDecimal, formatPercent } from "@mprofit/shared";
import Big from 'big.js';
import { useNumberFormatPrefs } from "../../../hooks/userPreferencesHooks";
import RightLegend from "../pie-chart-right-legend/Legends";
import CustomTypography from "../../customTypography";

interface PieChartWithRightLegendProps {
    data: any[];
    totalValue: string | undefined;
    totalFieldLabel?: string;
    heading: string;
    subHeading: string;
    dropdownValue?: string;
    dropdownList?: DropdownItem[];
    handleDropdownChange?: (selected: DropdownItem) => void,
    onItemClick?: (item: SharedConstants.PieChartData) => void
}

interface CustomLabel {
    viewBox: any;
    labelText: string;
    value: string;
}

const PieChartWithRightLegend = (props: PieChartWithRightLegendProps) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null)
    const [fontSize, setFontSize] = useState(16);


    const numberFormatPrefs = useNumberFormatPrefs();
    const { FormattedValue } = FormatAmountUsingPrefsHelper(numberFormatPrefs, props.totalValue, 2);

    const chartRef = useRef<any>();

    const handleMouseLeaveFromPie = () => {
        setActiveIndex(null)
        if (!chartRef?.current) {
            return;
        }
        chartRef.current.setState({
            isTooltipActive: false,
            tooltipPosition: undefined
        });
    };

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.tooltip_outer}>
                    <div className={styles.tooltip_container}>
                        <div className={styles.tooltip_wrapper}>
                            <div className={styles.tooltip_currentPrice}>{
                                FormatAmountUsingPrefsHelper(numberFormatPrefs, payload[0].value).FormattedValue
                            }</div>
                            <p className={styles.tooltip_percentageCont}>{formatPercent(payload[0]?.payload?.pctValueBig, 1, 'IN')}</p>
                        </div>
                        <div className={styles.tooltip_loanText}>{payload[0].name}</div>
                    </div>
                </div>
            );
        }
        return null
    };

    const theme = useTheme();
    const smBreakpointValue = theme.breakpoints.values.sm;
    const lg1BreakpointValue = theme.breakpoints.values.lg1;

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < smBreakpointValue) {
                setFontSize(12);
            } else if (screenWidth > smBreakpointValue && screenWidth <= lg1BreakpointValue) {
                setFontSize(14);
            } else {
                setFontSize(20);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const CustomLabel = ({ viewBox, labelText, value }: CustomLabel) => {
        const { cx, cy } = viewBox;
        return (
            <g>
                <text
                    x={cx}
                    y={cy}
                    className="recharts-text recharts-label"
                    textAnchor="middle"
                    dominantBaseline="central"
                    alignmentBaseline="middle"
                    fontFamily="Rubik"
                    fill="#12131A"
                    fontSize={`${fontSize}px`}
                    fontWeight="600"
                >
                    {value}
                </text>
                <text
                    x={cx}
                    y={cy + 20}
                    className="recharts-text recharts-label"
                    textAnchor="middle"
                    dominantBaseline="central"
                    alignmentBaseline="middle"
                    fill="#64677A"
                    fontSize={`${fontSize - 2}px`}
                >
                    {labelText}
                </text>
            </g>
        );
    };

    var data = props.data;

    var isEmpty = false;

    if (props.totalValue && Big(props.totalValue).eq(0)) {
        isEmpty = true;
        data = [
            {
                name: 'A',
                value: 1,
                numValueBig: Big(1),
                pctValueBig: Big(1),
                IDs: {},
                color: '#f5f5f6'
            }
        ]
    }

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" fontSize={13}>
                {formatPercent(percent, 1, 'IN')}
            </text>
        );
    }

    return (
        <Box className={styles.piechartWithLeftLegend_wrapper}>
            <Grid container className={styles.infoContainer}>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%', height: { xs: "300px", md: "380px", lg: "380px", lg1: "410px" } }} onMouseLeave={handleMouseLeaveFromPie}>
                        <ResponsiveContainer height='100%' width='100%'>
                            <PieChart
                                ref={chartRef}
                                onMouseLeave={handleMouseLeaveFromPie}
                            >
                                {
                                    !isEmpty &&
                                    <Tooltip
                                        content={<CustomTooltip />}
                                        wrapperStyle={{ outline: "none", borderRadius: "10px", zIndex: "1" }}
                                    />
                                }
                                <Pie
                                    data={data}
                                    innerRadius={"55%"}
                                    fill="#8884d8"
                                    dataKey="value"
                                    cursor={props.onItemClick && !isEmpty ? 'pointer' : 'default'}
                                    stroke="none"
                                    onMouseEnter={(data, index) => setActiveIndex(index)}
                                    onMouseLeave={handleMouseLeaveFromPie}
                                    label={renderCustomizedLabel}
                                    labelLine={false}
                                >
                                    {data.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.color}
                                            fillOpacity={!isEmpty && activeIndex === index ? 0.5 : 1}
                                            onClick={() => { if (props.onItemClick) props.onItemClick(entry) }}
                                        />
                                    ))}
                                    <Label content={<CustomLabel viewBox labelText={props.totalFieldLabel || ''} value={FormattedValue} />} position="center" />
                                </Pie>
                                {
                                    !isEmpty &&
                                    <Legend
                                        content={<CustomLegend chartRef={chartRef} setActiveIndex={setActiveIndex} onItemClick={props.onItemClick} />}
                                        layout="vertical"
                                        verticalAlign="middle"
                                        align="right"
                                        iconType="circle"
                                        iconSize={10}
                                    />
                                }
                            </PieChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const CustomLegend = (customLegendProps: { payload?: SharedConstants.PieChartData[], chartRef: MutableRefObject<any>, setActiveIndex: Dispatch<SetStateAction<number | null>>, onItemClick: ((item: SharedConstants.PieChartData) => void) | undefined }) => {
    const { payload, chartRef, setActiveIndex, onItemClick } = customLegendProps;

    return (
        <RightLegend data={payload || []} chartRef={chartRef} setActiveIndex={setActiveIndex} onItemClick={onItemClick} />
    );
};

export default PieChartWithRightLegend;
