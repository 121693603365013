import { IconButton } from '@mui/material'
import { Selected } from '../../../assets/icons/selected'
interface selectionProps {
  disabled?: boolean;
  activeColor?: string;
  handleClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  isSelected?: boolean,
  width?: string,
  height?: string
}

function Selection({ disabled, activeColor, handleClick, isSelected,width,height }: selectionProps) {

  return (
    <IconButton
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      sx={{
        borderRadius: "50%",
        color: '#12131A',
        background: isSelected ? activeColor : "#f5f5f6",
        height: height ||"32px",
        width: width ||"32px",
        '&:hover': {
          backgroundColor: isSelected ? activeColor : '#EBECF2',
          boxShadow: 0,
        },
        // '&:focus':{
        //     backgroundColor:,
        //     color:"white"
        // }
      }}
    >
      <Selected />
    </IconButton>
  )
}

export default Selection