import { createTheme } from '@mui/material/styles';


declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        lg1: true
        xl: true;
    }
    interface TypographyVariants {
        Bold28: React.CSSProperties;
        Bold24: React.CSSProperties;
        Bold20: React.CSSProperties;
        Medium14: React.CSSProperties;
        Medium16: React.CSSProperties;
        Medium18: React.CSSProperties;
        Medium20: React.CSSProperties;
        Medium24: React.CSSProperties;
        Medium28: React.CSSProperties;
        Regular28: React.CSSProperties;
        Regular24: React.CSSProperties;
        Regular22: React.CSSProperties;
        Regular20: React.CSSProperties;
        Regular18: React.CSSProperties;
        Regular16: React.CSSProperties;
        Regular14: React.CSSProperties;
    }
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        Bold28: React.CSSProperties;
        Bold24: React.CSSProperties;
        Bold20: React.CSSProperties;
        Medium14: React.CSSProperties;
        Medium16: React.CSSProperties;
        Medium18: React.CSSProperties;
        Medium20: React.CSSProperties;
        Medium24: React.CSSProperties;
        Medium28: React.CSSProperties;
        Regular14: React.CSSProperties;
        Regular16: React.CSSProperties;
        Regular18: React.CSSProperties;
        Regular20: React.CSSProperties;
        Regular24: React.CSSProperties;
        Regular22: React.CSSProperties;
        Regular28: React.CSSProperties;
    }
}


declare module '@mui/material/styles/createPalette' {
    interface PaletteOptions {
        base: PaletteColorsBase;
        infoBase: PaletteColorsByCategory;
        errorBase: PaletteColorsByCategory;
        alertBase: PaletteColorsByCategory;
        brandBase: PaletteColorsByShade;

    }

    interface PaletteColorsBase {
        black: string;
        white: string;
        offWhite: string;
    }

    interface PaletteColorsByCategory {
        secondaryDark?: string;
        primary: string;
        secondaryLight?: string;
        tertiaryLight?: string;
    }
    interface PaletteColorsByShade {
        900?: string;
        800?: string
        700?: string
        500?: string
        600?: string
        400?: string
        300?: string;
        200?: string;
        100?: string;
        20?: string;
        50?: string;
        lightest?: string;
    }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        Bold28: true;
        Bold24: true;
        Bold20: true;
        Medium14: true;
        Medium16: true;
        Medium18: true;
        Medium20: true;
        Medium24: true;
        Medium28: true;
        Regular14: true;
        Regular16: true;
        Regular18: true;
        Regular20: true;
        Regular22: true;
        Regular24: true;
        Regular28: true;
    }
}

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 767,
            md: 900,
            lg: 1200,
            lg1: 1366,
            xl: 1537,
        },
    },
    palette: {
        base: {
            black: '#100006',
            white: '#ffffff',
            offWhite: '#F6F6F6'
        },
        infoBase: {
            secondaryDark: '#4094F7',
            primary: '#D7EDFF',
        },
        errorBase: {
            secondaryDark: '#BF1D1D',
            primary: '#C94C40',
            secondaryLight: '#FFE5E5',
            tertiaryLight: '#FFF5F5',
        },
        alertBase: {
            secondaryDark: '#F8C51B',
            primary: '#F8DD4E',
            secondaryLight: '#FFFCC2',
        },
        brandBase: {
            400: '#253326',
            300: '#5F854C',
            200: '#5F6F57',
            100: '#CFE3C9',
            50: '#EAF4DF',
        }
    },
});

theme.typography.Bold28 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '28px',
        lineHeight: '42px',
        fontWeight: 600,
    },
};

theme.typography.Bold24 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '22px',
        lineHeight: '33px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: 600,
    },
};

theme.typography.Bold20 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: 600,
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 600,
    },
};

theme.typography.Medium16 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '15px',
        lineHeight: '22px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
    },
};

theme.typography.Medium28 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '28px',
        lineHeight: '42px',
        fontWeight: 600,
    },
};

theme.typography.Medium24 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '22px',
        lineHeight: '33px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: 500,
    },
};

theme.typography.Medium20 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 500,
    },
};

theme.typography.Medium18 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: 500,
    },
};

theme.typography.Medium14 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 500,
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 500,
    },
};

theme.typography.Regular28 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '28px',
        lineHeight: '42px',
        fontWeight: '400',
    },
};

theme.typography.Regular24 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '22px',
        lineHeight: '33px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: '400',
    },
};

theme.typography.Regular22 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        lineHeight: '30px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '20px',
        lineHeight: '33px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '22px',
        lineHeight: '36px',
        fontWeight: '400',
    },
};

theme.typography.Regular20 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: '400',
    },
};

theme.typography.Regular18 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '17px',
        lineHeight: '27px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: '400',
    },
};

theme.typography.Regular16 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '15px',
        lineHeight: '22px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
    },
};

theme.typography.Regular14 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
        lineHeight: '19px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '12px',
        lineHeight: '19px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '12px',
        lineHeight: '19px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('lg1')]: {
        fontSize: '13px',
        lineHeight: '19px',
        fontWeight: '400',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: '400',
    },
};

export const variants = {
    h3: 'h3'
}