import React from 'react'

export default function LargeCap(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_222589" data-name="Group 222589" transform="translate(9335)">
                <rect id="Rectangle_34469" data-name="Rectangle 34469" width="20" height="20" transform="translate(-9335)" fill="#12131a" opacity="0" />
                <path id="Path_238761" data-name="Path 238761" d="M40.074,21.987l-3.593-4.615h1.85a.625.625,0,0,0,.5-1.006L32.578,8.243a.625.625,0,0,0-.99,0l-6.249,8.123a.625.625,0,0,0,.5,1.006H27.68l-3.593,4.615a.625.625,0,0,0,.5,1.008h6.873V26.12a.625.625,0,0,0,1.25,0V23h6.873a.625.625,0,0,0,.494-1.008Zm-14.212-.241,3.593-4.615a.625.625,0,0,0-.5-1.008H27.1l4.979-6.473,4.979,6.473H35.207a.625.625,0,0,0-.493,1.008L38.3,21.746Z" transform="translate(-9356.956 -6.999)" fill="#010006" />
            </g>
        </svg>

    )
}
