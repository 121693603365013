import { Dispatch, SetStateAction, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step, StoreHelpers, TooltipRenderProps } from 'react-joyride';
import CustomTypography from '../customTypography';
import { CrossIcon } from 'packages/web/src/assets/icons/CrossIcon';
import ButtonPrimaryCTA from '../button-primary-cta';
import { SecondaryBackIcon } from 'packages/web/src/assets/icons/secondaryBackIcon';

interface IAppTour {
    children: any;
    runAppTour: boolean;
    appTourSteps: Step[];
    setRunAppTour: Dispatch<SetStateAction<boolean>>;
    onTourEnd?: () => void;
    isControlled?: boolean;
    onGoToStepCallback?: (stepIndex: number) => void;
    checkIfShouldDelayStepTillCallbackCompletes?: (stepIndex: number) => boolean;
}


const AppTour = (props: IAppTour) => {
    const helpers = useRef<StoreHelpers>();

    const setHelpers = (storeHelpers: StoreHelpers) => {
        helpers.current = storeHelpers;
    };

    const [stepIndex, setStepIndex] = useState(0);

    const handleJoyrideCallbackUncontrolled = (data: CallBackProps) => {
        const { status, action, step, index, type } = data;
        const options: string[] = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED];

        console.log('handleJoyrideCallbackUncontrolled',status,action,step,index,type,data)

        if (options.includes(status) || action === ACTIONS.CLOSE) {
            const { reset } = helpers.current!;
            reset(false);
            if (status === STATUS.FINISHED && type === EVENTS.TOUR_END && props.onTourEnd) {
                props.onTourEnd();
            }

            if (type === EVENTS.TOUR_END || action === ACTIONS.CLOSE) {
                props.setRunAppTour(false);
            }
        }
    };

    const handleJoyrideCallbackControlled = (data: CallBackProps) => {
        const { action, index, status, type } = data;

        if (([STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED] as string[]).includes(status) || ([ACTIONS.CLOSE] as string[]).includes(action)) {
            // Need to set our running state to false, so we can restart if we click start again.
            setStepIndex(0);
            props.setRunAppTour(false);
            if (status === STATUS.FINISHED && props.onTourEnd) {
                props.onTourEnd();
            }
        } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

            var shouldDelayStepTillCallbackCompletes = false;

            if (props.onGoToStepCallback) {
                if (props.checkIfShouldDelayStepTillCallbackCompletes) {
                    shouldDelayStepTillCallbackCompletes = props.checkIfShouldDelayStepTillCallbackCompletes(nextStepIndex);
                }

                props.onGoToStepCallback(nextStepIndex);
            }

            if (shouldDelayStepTillCallbackCompletes) {
                setTimeout(() => {
                    setStepIndex(nextStepIndex);
                }, 1000);
            } else {
                setStepIndex(nextStepIndex);
            }
        }
    }

    return (
        <>
            <Joyride
                steps={props.appTourSteps}
                run={props.runAppTour}
                disableScrolling={false}
                scrollOffset={300}// in future we may want to make this dynamic
                continuous={true}
                styles={{
                    options: {
                        arrowColor: '#5F854C',
                        backgroundColor: '#fff',
                        overlayColor: 'rgba(0, 0, 0, 0.6)',
                        zIndex: 10000,
                    },
                }}
                callback={props.isControlled ? handleJoyrideCallbackControlled : handleJoyrideCallbackUncontrolled}
                tooltipComponent={Tooltip}
                getHelpers={setHelpers}
                stepIndex={props.isControlled ? stepIndex : undefined}
                disableOverlayClose={true}
            />
            {props.children}
        </>
    );
};


const RoundIndicators = ({ count, activeIndex }: any) => {
    const indicators = [];

    for (let i = 0; i < count; i++) {
        indicators.push(
            <Box key={i}
                sx={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    opacity: i === activeIndex ? '100%' : '40%',
                    backgroundColor: i === activeIndex ? '#D6E1D6' : '#253326',
                    margin: '0 8px',
                }}
            />
        );
    }

    return <Box display="flex">{indicators}</Box>;
};

const Tooltip = ({
    backProps,
    index,
    size,
    isLastStep,
    primaryProps,
    closeProps,
    step,
    tooltipProps
}: TooltipRenderProps) => {
    return (
        <Box
            {...tooltipProps}
            width='fit-content'
            maxWidth='400px'
            minWidth='200px'
            height='fit-content'
            overflow="hidden"
            sx={{
                backgroundColor: '#5F854C',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '8px',
                padding: '24px',
            }}
        >
            <Box>
                {step.title && <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CustomTypography color={'#fff'} variant='Medium24'>
                        {step.title}
                    </CustomTypography>
                    <Box sx={{
                        display: 'flex',
                        ':hover': {
                            backgroundColor: '#253326',
                        },
                        height: '32px',
                        width: '32px',
                        paddingTop: '2px',
                        borderRadius: '20px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'

                    }}>
                        <Box {...closeProps}>
                            <CrossIcon color={'#fff'} height={24} width={24} />
                        </Box>
                    </Box>
                </Box>
                }
                {step.content && <Box sx={{ marginTop: '24px' }}>
                    <CustomTypography variant='Regular16' color={'#fff'}>{step.content}</CustomTypography></Box>}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '24px'
                }}
            >
                {/* Prev icon */}
                {
                    index > 0 &&
                <Box
                    {...backProps}
                    sx={{
                        display: 'flex',
                        ':hover': index > 0 ? {
                            backgroundColor: '#253326'
                        } : undefined,
                        paddingTop: '2px',
                        height: '40px',
                        width: '40px',
                        borderRadius: '4px',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {index > 0 && <Box>
                        <SecondaryBackIcon />
                    </Box>}
                </Box>}
                {/* Indicators */}
                <Box>
                    <RoundIndicators count={size} activeIndex={index} />
                </Box>
                {/* Next btn */}
                <Box {...primaryProps}>
                    <ButtonPrimaryCTA buttonText={isLastStep ? 'Close' : 'Next'} />
                </Box>
            </Box>

        </Box>
    );
}



export default AppTour;
