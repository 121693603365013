import React from 'react'
import { Box, Tab } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import styled from '@emotion/styled';

interface ITabsButtonProps {
  labels: string[],
  value: number,
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabsButton({ labels, value, handleChange }: ITabsButtonProps) {

  const NewTabs = styled(Tabs)({
    "& .MuiTab-root": {
      color: "black",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "black",
    }

  })
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <NewTabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#12161A",
          }
        }}
      >
        {labels.map((label, index) => (
          <Tab key={index} label={label} {...a11yProps(index)} />
        ))}
      </NewTabs>
    </Box>
  )
}
