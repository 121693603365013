
export function ReportDocumentIcon(props: any) {
 return (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
   <g id="Group_154378" data-name="Group 154378" transform="translate(0 -2)">
    <path id="Path_198779" data-name="Path 198779" d="M0,0H48V48H0Z" transform="translate(0 2)" fill="rgba(255,255,255,0)" />
    <g id="Group_154330" data-name="Group 154330" transform="translate(8.671 9.853)" opacity="0">
     <path id="Path_234943" data-name="Path 234943" d="M6.393,1.5A2.644,2.644,0,0,0,3.75,4.143v24.32a2.644,2.644,0,0,0,2.643,2.643H24.369a2.644,2.644,0,0,0,2.643-2.643v-11.1a5.287,5.287,0,0,0-5.287-5.287H19.082A2.643,2.643,0,0,1,16.439,9.43V6.787A5.287,5.287,0,0,0,11.152,1.5ZM9.037,20.533a1.057,1.057,0,0,1,1.057-1.057H20.668a1.057,1.057,0,0,1,0,2.115H10.094A1.057,1.057,0,0,1,9.037,20.533Zm1.057,3.172a1.057,1.057,0,0,0,0,2.115h5.287a1.057,1.057,0,0,0,0-2.115Z" transform="translate(0 0)" fill="#babdcc" fillRule="evenodd" />
     <path id="Path_234944" data-name="Path 234944" d="M12.971,1.816a7.374,7.374,0,0,1,1.8,4.841V9.3a.529.529,0,0,0,.529.529h2.643a7.374,7.374,0,0,1,4.841,1.8,13.772,13.772,0,0,0-9.817-9.817Z" transform="translate(3.779 0.13)" fill="#babdcc" />
    </g>
    <g id="Group_154375" data-name="Group 154375" transform="translate(14403.501 -3947.686)">
     <g id="Group_154376" data-name="Group 154376" transform="translate(-14395.501 3953.686)">
      <path id="Path_235257" data-name="Path 235257" d="M388.111,337.854a1.779,1.779,0,0,0-.841,1.514v.261h.207v-.261a1.567,1.567,0,0,1,1.152-1.514Zm25.38-.261H389.027a1.768,1.768,0,0,0-1.757,1.775v36.449a1.769,1.769,0,0,0,1.757,1.775h27.185a1.769,1.769,0,0,0,1.757-1.775V341.633Zm4.271,38.225a1.561,1.561,0,0,1-1.55,1.567H389.027a1.561,1.561,0,0,1-1.55-1.567V339.368a1.567,1.567,0,0,1,1.152-1.514,1.519,1.519,0,0,1,.4-.052h24.386l4.35,3.924Zm-29.651-37.964a1.779,1.779,0,0,0-.841,1.514v.261h.207v-.261a1.567,1.567,0,0,1,1.152-1.514Z" transform="translate(-387.27 -337.593)" fill="#090c09" />
      <path id="Path_235258" data-name="Path 235258" d="M643.865,341.771h-2.82A1.778,1.778,0,0,1,639.27,340v-2.3a.1.1,0,0,1,.209,0V340a1.568,1.568,0,0,0,1.567,1.567h2.82a.1.1,0,0,1,0,.209Z" transform="translate(-613.27 -337.593)" fill="#090c09" />
     </g>
     <path id="Path_235259" data-name="Path 235259" d="M438.459,429.764H414.647a.835.835,0,0,1,0-1.671h23.812a.835.835,0,1,1,0,1.671Z" transform="translate(-14806.541 3535.045)" fill="#dae5a1" />
     <path id="Path_235260" data-name="Path 235260" d="M426.449,395.764h-11.8a.835.835,0,0,1,0-1.671h11.8a.835.835,0,1,1,0,1.671Z" transform="translate(-14806.541 3565.494)" fill="#dae5a1" />
     <path id="Path_235261" data-name="Path 235261" d="M438.459,496.764H414.647a.836.836,0,0,1,0-1.671h23.812a.836.836,0,1,1,0,1.671Z" transform="translate(-14806.541 3475.042)" fill="#dae5a1" />
     <path id="Path_235262" data-name="Path 235262" d="M426.449,462.764h-11.8a.836.836,0,0,1,0-1.671h11.8a.836.836,0,1,1,0,1.671Z" transform="translate(-14806.541 3505.491)" fill="#dae5a1" />
     <path id="Path_235263" data-name="Path 235263" d="M437.093,559.812H414.952a1.725,1.725,0,0,1-1.723-1.723v-8.773a1.725,1.725,0,0,1,1.723-1.723h22.141a1.725,1.725,0,0,1,1.723,1.723v8.773a1.725,1.725,0,0,1-1.723,1.723Z" transform="translate(-14806.019 3428.025)" fill="#acc18a" opacity="0.8" />
    </g>
   </g>
  </svg>

 );
}

