import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface NavigationTabsProps {
    id?: string
    tabIDs: number[] | string[];
    disabled?: boolean;
    handleChange: (event: React.MouseEvent<HTMLElement>, newValue: any) => void;
    activeID: number | string;
    tabTexts?: { [tabID: string | number]: string },
    tabExtraClassNames?: { [tabID: string | number]: string }
}

export default function NavigationTabs({ id, tabIDs, disabled, handleChange, activeID, tabTexts, tabExtraClassNames }: NavigationTabsProps) {
    return (
        <ToggleButtonGroup
            id={id}
            disabled={disabled}
            value={activeID}
            exclusive
            size='small'
            onChange={handleChange}
            aria-label="text alignment"
            sx={{ height: { xs: "40px", lg: '48px' } }}
        >
            {
                tabIDs.map(((tabID, index) => (
                    <ToggleButton
                        value={tabID}
                        key={index}
                        disableRipple
                        sx={{
                            height: { xs: "40px", lg: '48px' },
                            boxSizing: 'border-box',
                            padding: { xs: "12px 16px", lg: "14px 14px 12px 14px", lg1: "14px 24px 12px 24px" },
                            textTransform: "initial",
                            borderRadius: "8px",
                            fontSize: "18px",
                            lineHeight: '22px',
                            color: '#64677A',
                            fontWeight: '400',
                            background: '#FFFFFF',
                            "&.Mui-selected": {
                                "&.MuiToggleButton-root": {
                                    background: "#12131A",
                                    color: "white"
                                },
                            },
                            "&.Mui-disabled": {
                                "&.MuiToggleButton-root": {
                                    background: index === 0 ? "#EBECF2" : "transparent",
                                    color: index === 0 ? "#BABDCC" : ""
                                },
                            },
                            "&:hover": {
                                "&.MuiToggleButton-root": {
                                    background: tabID === activeID ? "black" : "#EBECF2",
                                    color: tabID === activeID ? "white" : "#64677A"
                                },
                            },
                            "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
                                marginLeft: 'none',
                                borderLeft: '1px solid lightgray',
                            },
                            "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                                marginLeft: 'none',
                                borderLeft: '1px solid lightgray',
                            }
                        }}
                        className={tabExtraClassNames ? tabExtraClassNames[tabID] : undefined}
                    >
                        {tabTexts ? tabTexts[tabID] : tabID}
                    </ToggleButton>
                )))
            }
        </ToggleButtonGroup>
    );
}
