import React from 'react'

export default function LongBackArrow(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_149757" data-name="Group 149757" transform="translate(-32 -11)">
                <g id="Rectangle_23766" data-name="Rectangle 23766" transform="translate(32 11)" fill="#5f6f57" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="Path_235639" data-name="Path 235639" d="M6.75,15.75,3,12m0,0L6.75,8.25M3,12H21" transform="translate(32 11)" fill="none" stroke="#5f6f57" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </g>
        </svg>

    )
}
