export function PO(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226573" data-name="Group 226573" transform="translate(-17890 6539.102)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 -0.102)" fill="#fff" opacity="0" />
          <g id="mailbox" transform="translate(2.488 4.975)">
            <path id="Path_198394" data-name="Path 198394" d="M3.6,3.9A2.7,2.7,0,0,0,.9,6.6v5.4H6.3V6.6A2.7,2.7,0,0,0,3.6,3.9Zm0-.9h7.205a3.6,3.6,0,0,1,3.6,3.6v5.4a.9.9,0,0,1-.9.9H.9a.9.9,0,0,1-.9-.9V6.6A3.6,3.6,0,0,1,3.6,3Zm2.383.9a3.594,3.594,0,0,1,1.22,2.7v5.4h6.3V6.6a2.7,2.7,0,0,0-2.7-2.7Z" transform="translate(0 -3)" fill="#fff" />
            <path id="Path_198395" data-name="Path 198395" d="M10.92,8.252H8.4v-.9h4.5a.45.45,0,0,1,.45.45v.9a.45.45,0,0,1-.45.45h-.9a.45.45,0,0,1-.319-.131ZM4.8,6.9c0,.5-.4,0-.9,0s-.9.5-.9,0a.9.9,0,1,1,1.8,0Z" transform="translate(-0.298 -3.298)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
  );
}
