import React from 'react'

export default function PurchasePriceIcon(props: any) {
    return (
        <svg {...props} id="Group_151091" data-name="Group 151091" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <rect id="Rectangle_24259" data-name="Rectangle 24259" width="48" height="48" rx="8" fill="#f5f5f6" />
            <g id="fdb97a64aaafb3e34fbfdf04c3ee0b8b" transform="translate(11 12.366)">
                <path id="Path_198554" data-name="Path 198554" d="M42.718,76.029c-3.093,2.033-10.167,6.014-10.167,6.014H32.53a4.693,4.693,0,0,1-3.31.5c-.108-.022-7.16-.887-8.2-1.017L21,80.874V73.087c.562-.043,1.622-.087,1.731-.108a33.784,33.784,0,0,1,8.826,1.082,1.319,1.319,0,0,1,1.06,1.3v.151a1.454,1.454,0,0,1-.368.779,1.307,1.307,0,0,1-.93.389H26.062a.416.416,0,0,0-.433.411.44.44,0,0,0,.195.346.365.365,0,0,0,.216.065h4.629c.151,0,.952-.022,1.536-.065a4.256,4.256,0,0,0,.757-.13l.692-.195,7.9-3.31a1.262,1.262,0,0,1,1.168,2.228Z" transform="translate(-17.301 -59.372)" fill="#373737" />
                <rect id="Rectangle_24255" data-name="Rectangle 24255" width="2.834" height="9.345" transform="translate(0 12.828)" fill="#373737" />
                <path id="Path_198555" data-name="Path 198555" d="M56.961,20.929a60.866,60.866,0,0,1-1.882-10.34.483.483,0,0,0-.454-.389H44.609a.446.446,0,0,0-.454.389,58.807,58.807,0,0,1-1.882,10.34.424.424,0,0,0,.108.389.462.462,0,0,0,.368.173H56.506a.393.393,0,0,0,.346-.173A.424.424,0,0,0,56.961,20.929Zm-5.084-7.42v.844a2.25,2.25,0,0,1-4.5,0V13.51a.592.592,0,0,1-.3-.541.651.651,0,0,1,.649-.649.665.665,0,0,1,.649.649.592.592,0,0,1-.3.541v.844a1.536,1.536,0,0,0,3.072,0V13.51a.619.619,0,0,1-.281-.541.649.649,0,1,1,1.3,0A.576.576,0,0,1,51.877,13.51Z" transform="translate(-33.966 -10.2)" fill="#373737" />
            </g>
        </svg>

    )
}
