export function CD(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226555" data-name="Group 226555" transform="translate(-17890 6538.982)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 0.018)" fill="#fff" opacity="0" />
          <g id="money-bag" transform="translate(3.753 2.502)">
            <path id="Path_198705" data-name="Path 198705" d="M124.957,84.764l1.428-2.277a.516.516,0,0,0,.064-.412.533.533,0,0,0-.249-.334,6.1,6.1,0,0,0-6.111.009.535.535,0,0,0-.167.738l1.424,2.273a8.449,8.449,0,0,0-4.147,6.874,3.645,3.645,0,0,0,1.106,2.723c1.527,1.479,4.138,1.569,4.738,1.569h.1c.274,0,3.19.034,4.841-1.565a3.652,3.652,0,0,0,1.106-2.723A8.424,8.424,0,0,0,124.957,84.764Zm.21-2.35-1.3,2.084a3.343,3.343,0,0,1-1.419,0l-1.3-2.076A4.974,4.974,0,0,1,125.168,82.414Zm2.08,11.175c-1.364,1.329-4.052,1.265-4.082,1.265h-.034c-.026,0-2.706.069-4.078-1.265a2.575,2.575,0,0,1-.78-1.955,7.478,7.478,0,0,1,3.9-6.111.152.152,0,0,0,.043.009,3.038,3.038,0,0,0-.433,1.2.32.32,0,0,0,.27.364.176.176,0,0,0,.047,0,.319.319,0,0,0,.317-.274,2.115,2.115,0,0,1,.51-1.171c.009-.009.013-.017.021-.021.064,0,.133,0,.2,0,0,0,.009.013.017.017a1.477,1.477,0,0,1,.347.81.326.326,0,0,0,.317.274.176.176,0,0,0,.047,0,.32.32,0,0,0,.27-.365,2.456,2.456,0,0,0-.257-.793,2.4,2.4,0,0,0,.24-.051,7.381,7.381,0,0,1,3.889,6.106A2.523,2.523,0,0,1,127.247,93.589Z" transform="translate(-117.2 -80.914)" fill="#fff" />
            <path id="Path_198706" data-name="Path 198706" d="M11.535,8.25H9m2.535,1.267H9m1.267,2.535L9,10.785h.634a1.267,1.267,0,1,0,0-2.535" transform="translate(-3.996 0.039)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          </g>
        </g>
      </g>
    </svg>
  );
}
