import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities, formatDateForReading } from "@mprofit/shared";
import HoldingLayout from "../layout";
import { HoldingCurrentValue } from "../holdingCurrentValue";
import { HoldingAmountInvested } from "../holdingAmountInvested";
import { HoldingUnrealisedGain } from "../holdingUnrealisedGain";
import SharedVirtualizedTable, { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import { useHoldingsMode } from "packages/web/src/classes/HoldingsModeHook";
import { HoldingAssetAllocationChart } from "../holdingAssetAllocationChart";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigateToAssetType } from "packages/web/src/routes/navigationMethods";

interface L1_AA_Holding_Props {
  MPrUrlParams: MPrUrlParams;
}

export default function L1_AA_Holding(props: L1_AA_Holding_Props) {
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);
  // const categName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategName);

  return <HoldingLayout
    {...props}
    Screen_CurrentValueCard={CurrentValueCard}
    Screen_AmountInvestedCard={AmountInvestedCard}
    Screen_UnrealisedGainCard={UnrealisedGainCard}
    Screen_AssetAllocationChart={AssetAllocationChart}
    Screen_Table={HoldingsTable}
    isLoading={isLoading}
    tableHeading={`Current Holdings`}
  />;
}

function useHoldingsMode_L1() {
  return useHoldingsMode(SharedUtilities.getDefaultHoldingsModeForScreen(SharedConstants.ScreenEnum.L1), SharedUtilities.getHoldingsModesForScreen(SharedConstants.ScreenEnum.L1));
}

const CurrentValueCard = () => {
  const currentValue = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryForAACategIDTotalCurrentValue);

  return <HoldingCurrentValue
      CurrentValue={currentValue}
    />
}

const AmountInvestedCard = () => {
  const amountInvested = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryForAACategIDTotalAmountInvested);

  return <HoldingAmountInvested
      AmountInvested={amountInvested}
    />
}

const UnrealisedGainCard = () => {
  const totalValues = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryForAACategIDTotal);

  return <HoldingUnrealisedGain
    OGain={totalValues?.OGain}
    OGainPct={totalValues?.OGainPct}
    OUp={totalValues?.OUp}
  />
}

const AssetAllocationChart = () => {
  const [totalField, setTotalField] = useState<SharedConstants.HoldingAssetAllocationDropdownValue>('CurrValue');

  const {PieChartData, TotalValue} = useAppSelector((state) => SharedRedux.PortfolioSummary.Selectors.selectL1_AA_PieChartData_ByAssetType(state, totalField));

  const categName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategName);

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const onItemClick = (item: SharedConstants.PieChartData) => {
    if (item && item.IDs.AssetTypeID > 0) {
      NavigateToAssetType({ navigate, activeScreenIDs, assetTypeID: item.IDs.AssetTypeID, pathname: location.pathname });
    }
  }

  return <HoldingAssetAllocationChart
    data={PieChartData}
    totalValue={TotalValue}
    dropdownValue={totalField}
    heading={`${categName} Asset Allocation`}
    subHeading={`as on ${formatDateForReading(new Date())}`}
    handleDropdownChange={(selected) => {setTotalField(selected.id as SharedConstants.HoldingAssetAllocationDropdownValue)}}
    onItemClick={onItemClick}
  />
}

const HoldingsTable = ({searchTerm}: {searchTerm: string}) => {
  const tableData = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_AA_Table_ByAssetType);

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const onRowClick = (row: RowDataProps) => {
    console.log('onRowClick', row);
    if (row && row.AssetTypeID > 0) {
      NavigateToAssetType({ navigate, activeScreenIDs, assetTypeID: row.AssetTypeID, pathname: location.pathname });
    }
  }

  console.log(tableData, 'tableData');

  var fieldNamesToSearch = ['AssetTypeName'];

  return <SharedVirtualizedTable columns={tableColumns_byAssetType} rows={tableData} headerColor="#EAF4DF" sorting={true} onRowClick={onRowClick} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch}/>

}

const commonTableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'InvAmt',
    HeaderName: 'AMOUNT INVESTED',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'OGain',
    HeaderName: 'UNREALISED GAIN',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'OGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'OUp'
      }
    ]
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'HoldingPct',
    HeaderName: 'HOLDING',
    ColumnWidthPercent: '25%',
    FieldFormatter: TablePercentFieldFormatter,
    Align: 'right',
  }
];

const tableColumns_byAssetType: ColumnDataProps[] = [
  {
    FieldKey: 'AssetTypeName',
    HeaderName: 'CATEGORY',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDefaultFieldFormatter,
    Align: 'left',
    IsRowName: true
  },
  ...commonTableColumns
]
