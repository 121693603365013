import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Container, FormControl, InputAdornment, InputLabel, TextField, Typography, styled } from '@mui/material'
import LongBackArrow from 'packages/web/src/assets/icons/login/LongBackArrow';
import CustomTypography from '../../../shared/customTypography';
import { useForm, SubmitHandler } from "react-hook-form";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import EyeVisiblityOff from 'packages/web/src/assets/icons/login/eyeVisiblityOff';
import EyeVisible from 'packages/web/src/assets/icons/login/eyeVisible';
import CheckIcon from 'packages/web/src/assets/icons/login/checkIcon';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';
import { WebRedux } from '@mprofit/web';
import { SharedAPIs } from '@mprofit/shared';
import { AuthPage } from 'packages/web/src/constants/login';
import { HttpStatusCode } from 'axios'
import LoginBtnLoader from '../login-btn-loader';
interface IFormInputs {
    newPassword: string
    confirmPassword: string
}

export default function SetNewPassword() {
    const dispatch = useAppDispatch();
    const { register, formState: { errors }, watch, handleSubmit } = useForm<IFormInputs>({mode: 'onChange'});
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isMinimum, setIsMinimum] = useState(false);
    const [havingUpperCase, setHavingUpperCase] = useState(false);
    const [havingLowerCase, setHavingLowerCase] = useState(false);
    const [havingNumber, setHavingNumber] = useState(false)
    const [isError, setIsError] = useState(false);
    const [loading, setLoader] = useState(false);

    var loginPayload = useAppSelector(WebRedux.Auth.Selectors.selectLoginPayload) || { email: '', password: '', mfaOTP: '' };

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {
        setIsError(false)
        setLoader(true);
        const payload = {
            email: loginPayload.email,
            passwd: data.newPassword,
            otp: loginPayload.mfaOTP,
        }
        SharedAPIs.Login.SetPasswordAPI(payload).then(response => {
            setLoader(false);
            if (response.status === HttpStatusCode.Ok && response.data.IsSuccess) {
                dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: AuthPage.AllDone }))
            } else {
                setIsError(true)
            }
        })
    };

    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;

    const validatePassword = (password: string) => {
        let isValid = true;
        
        if (password.trim().length >= 8) {
            setIsMinimum(true)
        } else {
            setIsMinimum(false);
            isValid = false;
        }

        if (uppercaseRegex.test(password)) {
            setHavingUpperCase(uppercaseRegex.test(password))
        } else {
            setHavingUpperCase(false);
            isValid = false;
        }

        if (lowercaseRegex.test(password)) {
            setHavingLowerCase(true)
        } else {
            setHavingLowerCase(false);
            isValid = false;
        }

        if (numberRegex.test(password)) {
            setHavingNumber(true)
        } else {
            setHavingNumber(false);
            isValid = false;
        }

        return isValid;
    };

    return (
        <Container
            sx={{
                boxSizing: 'border-box',
                maxHeight: '73%',
                minHeight: { xs: '600px', lg1: '675px' },
                height: '65%',
                minWidth: '580px',
                width: '53%',
                marginLeft: 'auto',
                background: '#FFFFFF',
                borderRadius: '8px',
                margin: 'auto',
                padding: '24px'
            }}
        >
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <BackContainer onClick={() => { dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: AuthPage.LoginForm })) }}>
                    <LongBackArrow />
                    <CustomTypography variant='Medium16' sx={{ marginLeft: '8px', color: { xs: 'brandBase.300' } }}>Back to login</CustomTypography>
                </BackContainer>
                <HeaderCont>
                    <Heading>Set new password</Heading>
                    <CustomTypography variant='Regular18' sx={{ paddingTop: '16px' }}>We're almost done. Enter your new password and you're ready good to go.</CustomTypography>
                </HeaderCont>
                <FormContainer>
                    <CodeInputCont>
                        <Box component="form" noValidate autoComplete="off" sx={{ width: '100%', paddingTop: { lg: '10px', xl: '52px' } }} onSubmit={handleSubmit(onSubmit)}>
                            <InputLabel htmlFor="newPassword" sx={{ padding: '10px 0px', color: 'black', fontSize: '18px' }} >New password</InputLabel>
                            <FormControl fullWidth={true}>
                                <InputField
                                    id="newPassword"
                                    aria-describedby="newPassword-id"
                                    placeholder='Enter your password'
                                    hiddenLabel
                                    error={errors.newPassword && true}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        style: { height: '48px' },
                                        endAdornment: (
                                            <InputAdornment position="end" onClick={() => setShowPassword(prevState => !prevState)}>
                                                {showPassword ?
                                                    <EyeVisible style={{ cursor: 'pointer' }} />
                                                    :
                                                    <EyeVisiblityOff style={{ cursor: 'pointer' }} />
                                                }
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register("newPassword", { required: false, validate: validatePassword })}
                                />
                                <ValidationCont>
                                    <ValidationBox sx={{ background: isMinimum ? '#CFE3C9' : "#F9F9F9" }}>
                                        <CheckIcon color={isMinimum ? '#5F6F57' : "#BABDCC"} />
                                        <CustomTypography variant='Regular16' paddingLeft="4px"> 8 characters minimum</CustomTypography>
                                    </ValidationBox>
                                    <ValidationBox sx={{ background: havingUpperCase ? '#CFE3C9' : "#F9F9F9" }}>
                                        <CheckIcon color={havingUpperCase ? '#5F6F57' : "#BABDCC"} />
                                        <CustomTypography variant='Regular16' paddingLeft="4px"> one uppercase character</CustomTypography>
                                    </ValidationBox>
                                    <ValidationBox sx={{ background: havingLowerCase ? '#CFE3C9' : "#F9F9F9" }}>
                                        <CheckIcon color={havingLowerCase ? '#5F6F57' : "#BABDCC"} />
                                        <CustomTypography variant='Regular16' paddingLeft="4px"> one lowercase character</CustomTypography>
                                    </ValidationBox>
                                    <ValidationBox sx={{ background: havingNumber ? '#CFE3C9' : "#F9F9F9" }}>
                                        <CheckIcon color={havingNumber ? '#5F6F57' : "#BABDCC"} />
                                        <CustomTypography variant='Regular16' paddingLeft="4px"> one number</CustomTypography>
                                    </ValidationBox>
                                </ValidationCont>
                            </FormControl>

                            <InputLabel htmlFor="confirmPassword" sx={{ padding: '30px 0px 10px 0px', color: 'black', fontSize: '18px' }} >Confirm new password</InputLabel>
                            <FormControl fullWidth={true}>
                                <InputField
                                    id="confirmPassword"
                                    aria-describedby="confirmPassword"
                                    placeholder='Re-enter new password'
                                    hiddenLabel
                                    type={showPassword ? "text" : "password"}
                                    error={errors.confirmPassword && true}
                                    InputProps={{
                                        style: { height: '48px' },
                                        endAdornment: (
                                            <InputAdornment position="end" onClick={() => setShowPassword(prevState => !prevState)}>
                                                {showPassword ?
                                                    <EyeVisible style={{ cursor: 'pointer' }} />
                                                    :
                                                    <EyeVisiblityOff style={{ cursor: 'pointer' }} />
                                                }
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register("confirmPassword", {
                                        required: true,
                                        validate: (val: string) => {
                                            if (watch('newPassword') !== val) {
                                                return false;
                                            } else {
                                                return true
                                            }
                                        }
                                    })}
                                />
                                { errors.confirmPassword && <ErrorText sx={{ visibility: errors.confirmPassword ? "visible" : 'hidden', color: { xs: '#C94C40' }, marginTop: '5px' }} variant='Regular16'><WarningRoundedIcon fontSize="small" sx={{ marginRight: '5px' }} />Password does not match</ErrorText> }
                                {isError && <ErrorText sx={{ color: { xs: '#C94C40' }, marginTop: '5px' }} variant='Regular16'><WarningRoundedIcon fontSize="small" sx={{ marginRight: '5px' }} />Something went wrong. Please try again.</ErrorText>}
                            </FormControl>
                            <VerifyButton size='small' variant='contained' type='submit' sx={{marginTop: '20px'}}>
                                {
                                    loading ?
                                        <LoginBtnLoader />
                                        :
                                        'Save new password'
                                }
                            </VerifyButton>
                        </Box>
                    </CodeInputCont>
                </FormContainer>
            </Box>
        </Container>
    )
}

const BackContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const HeaderCont = styled(Box)({
    marginLeft: '32px',
    marginRight: '32px',
    marginTop: '3%'
});

const Heading = styled(Typography)({
    fontSize: '36px',
    lineHeight: '48px',
    fontWeight: 500
});

const FormContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '32px',
    paddingRight: '32px',
});

const VerifyButton = styled(Button)({
    padding: '15px 32px 14px 32px',
    background: '#010006',
    maxHeight: '48px',
    textTransform: 'none',
    color: 'base.white',
    width: '100%',
    ':hover': {
        background: '#252833'
    },
    ':disabled': {
        background: '#EBECF2',
        color: '#BABDCC'
    }
});

const CodeInputCont = styled(Box)({
    display: 'flex'
});

const ValidationCont = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    // marginBottom: '24px'
});

const ValidationBox = styled(Box)({
    border: '1px solid #EBECF2',
    borderRadius: '50px',
    padding: '4px 12px 5px 12px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
    marginTop: '6px'
});
const ErrorText = styled(CustomTypography)({
    minHeight: '24px',
    display: 'flex',
    alignItems: 'center',
});

const InputField = styled(TextField)({

});