export function FD(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226547" data-name="Group 226547" transform="translate(-17890 6539.205)">
          <g id="Group_226552" data-name="Group 226552" transform="translate(0 -0.205)">
            <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" fill="#fff" opacity="0" />
            <path id="Path_237164" data-name="Path 237164" d="M39.14,32.1a7.513,7.513,0,1,1-8.129-8.129.579.579,0,1,1,.1,1.154,6.358,6.358,0,1,0,6.879,6.879.579.579,0,1,1,1.154.1Zm-8.081-4.666v4.041a.577.577,0,0,0,.577.577h4.041a.577.577,0,1,0,0-1.154H32.213V27.438a.577.577,0,0,0-1.154,0Zm2.886-1.732a.866.866,0,1,0-.866-.866A.866.866,0,0,0,33.945,25.706Zm2.6,1.732a.866.866,0,1,0-.866-.866A.866.866,0,0,0,36.543,27.438Zm1.732,2.6a.866.866,0,1,0-.866-.866A.866.866,0,0,0,38.274,30.035Z" transform="translate(-21.642 -21.473)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
  );
}
