import { useAppSelector } from "packages/web/src/redux/hooks";
import XirrBarChart from "../../../shared/xirr-bar-chart";
import { SharedRedux } from "@mprofit/shared";
import PortfolioXIRRLoader from "../portfolio-XIRR-loader";
import useWindowDimensions from "../../../shared/utilities/windowDimensions";
import { useTheme } from "@mui/material";
import { ChartsSelectors } from "packages/web/src/redux/selectors/chartsSelectors";

export default function XIRRTotalIndicesChart({SpecificTitleText, IsPerformanceViewAllowed}: {SpecificTitleText: string, IsPerformanceViewAllowed: boolean}) {
    const dimension = useWindowDimensions();
    const isLast10YearIndexReturns = false;//useAppSelector(SharedRedux.Performance.Selectors.selectXIRRTotalIsOlderThan2012Return);
    const xirrTotalIsLoading = useAppSelector(SharedRedux.Performance.Selectors.selectXIRRTotalIsLoading);
    const lastRefreshedTime_Total = useAppSelector(SharedRedux.Performance.Selectors.selectXIRRTotalLastRefreshedTime);
    const {xirrTotalData, chartData, domain} = useAppSelector(ChartsSelectors.selectXIRRTotalChartData(SpecificTitleText));

    const theme = useTheme();
    const lgBreakpointValue = theme.breakpoints.values.lg1;

    const periodText = useAppSelector(SharedRedux.Performance.Selectors.selectActivePeriodText);

    var chartHeading = isLast10YearIndexReturns ? 'Index CAGR' : `My XIRR vs. Index CAGR`;
    var chartSubHeading = isLast10YearIndexReturns ? 'Trailing 10 Years' : periodText;

    if (xirrTotalIsLoading || !IsPerformanceViewAllowed || !xirrTotalData) {
        return <PortfolioXIRRLoader chartHeading={chartHeading} chartSubHeading={chartSubHeading} IsPerformanceViewAllowed={IsPerformanceViewAllowed} SpecificTitleText={SpecificTitleText}/>
    }
    else {
        return (
            <XirrBarChart
                key={'xirr-bar-chart'}
                data={chartData}
                chartHeading={chartHeading}
                chartSubHeading={chartSubHeading}
                chartHeight={dimension.width < lgBreakpointValue ? 460 : 530}
                chartWidth={650}
                barSizeVal={14}
                domain={domain}
                lastRefreshedTime={lastRefreshedTime_Total}
                IsPerformanceViewAllowed={IsPerformanceViewAllowed}
                isFirstBarBlack={!isLast10YearIndexReturns}
                SpecificTitleText={SpecificTitleText}
            />
        )
    }
}