import { ActivePortfolioSummary, AssetAllocationCategID, AssetAllocationCategNameMapping, AssetAllocationCategTotals, AssetAllocationCategTotals_Big, AssetAllocationCategTotal_Big, AssetsByAssetType, AssetTotal, SIDAssetTotals, AssetTypeEnum, AssetTypeNameMapping, AssetTypeTotal, AssetTypeTotals, PortfolioSummaryRaw, PortfolioTotal, PriceValues, TotalFields, TotalFields_Big, AMIDAssetTotal_Big, AMIDAssetTotal, WatchlistItem, AssetsAddInfo, PieChartData, getPieChartColorByIndex, getPieChartDataForTopNElements, HoldingsCategTotals_Big, HoldingsCategTotals, HoldingCategTotal_Big, HoldingCategTotal, HoldingsMode, SumAssetTypeRaw, FamilyPortfolioSummaryRaw, PFolioTypes, HoldingTableFields } from "../constants";
import Big from "big.js";
import { SharedPortfolioSummaryState } from "../redux/modules/portfolioSummaryReducer";
import { PayloadAction } from "@reduxjs/toolkit";
import dateFormat from "dateformat";
import { getCategIDForMFSEBISubCategoryID, getMarketCapNameFromID } from "./shared";
import { SetActivePortfolioPayload } from "../redux/modules/portfoliosReducer";
import { getAssetTypeNameForAA } from "./assetTypeNames";

//#region Portfolio Summary Updates

export const getActivePortfolioSummaryFromRaw = (action: PayloadAction<{ CCID: number, FamilyId: number, PFID: number, IsGroup: boolean }>, portfolioSummaryRaw: PortfolioSummaryRaw) => {
    var assetsByAssetType: AssetsByAssetType = {};
    var assetTypeTotals: AssetTypeTotals = {
        ByID: {},
        AllIDs: []
    };
    var portfolioTotal: PortfolioTotal = {};

    var portfolioTotal_InvAmt: Big = new Big(0);
    var portfolioTotal_TGain: Big = new Big(0);
    var portfolioTotal_CurrValue: Big = new Big(0);
    var portfolioTotal_PreviousValueForTGainPct: Big = new Big(0);
    var portfolioTotal_TransCount: number = 0;

    var assetsForSearchMap: Map<string, string> = new Map<string, string>();

    var sumAssetTypesByID: {ByID: {[ID: number]: SumAssetTypeRaw}, AllIDs: number[]} = {
        ByID: {},
        AllIDs: []
    };

    portfolioSummaryRaw.SumAssetTypes.forEach((assetTypeRaw) => {
        var assetTypeID: AssetTypeEnum = assetTypeRaw.AssetTypeID === AssetTypeEnum.MFD ? AssetTypeEnum.MFEQ : assetTypeRaw.AssetTypeID;

        if (sumAssetTypesByID.ByID[assetTypeID]) {
            sumAssetTypesByID.ByID[assetTypeID].Assets = [
                ...(sumAssetTypesByID.ByID[assetTypeID].Assets || []),
                ...assetTypeRaw.Assets
            ]
        } else {
            sumAssetTypesByID.ByID[assetTypeID] = {...assetTypeRaw, AssetTypeID: assetTypeID};
            sumAssetTypesByID.AllIDs.push(assetTypeID);
        }
    });

    var sumAssetTypes = sumAssetTypesByID.AllIDs.map(ID => sumAssetTypesByID.ByID[ID]);

    console.log(sumAssetTypesByID, sumAssetTypes, portfolioSummaryRaw.SumAssetTypes, 'sumAssetTypes');

    for (var assetTypeRaw of sumAssetTypes) {
        console.log(assetTypeRaw, 'sumAssetTypes1');

        var assetTypeID: AssetTypeEnum = assetTypeRaw.AssetTypeID;
        // var assetTypeID: AssetTypeEnum = assetTypeRaw.AssetTypeID == AssetTypeEnum.MFEQ ? (assetTypeRaw.SeqNo == 10 ? AssetTypeEnum.MFEQ : AssetTypeEnum.MFD) : assetTypeRaw.AssetTypeID;
        var assets: SIDAssetTotals = {
            ByID: {},
            AllIDs: []
        }
        var assetTypeTotal: AssetTypeTotal = {
            AssetTypeID: assetTypeID,
            SeqNo: assetTypeRaw.SeqNo,
            AssetTypeName: AssetTypeNameMapping[assetTypeID]
        };
        var assetTypeTotal_InvAmt: Big = new Big(0);
        var assetTypeTotal_TGain: Big = new Big(0);
        var assetTypeTotal_CurrValue: Big = new Big(0);
        var assetTypeTotal_PreviousValueForTGainPct: Big = new Big(0);
        var assetTypeTotal_TransCount: number = 0;

        for (var assetRaw of assetTypeRaw.Assets) {
            if ((assetRaw.Quant != 0) || (assetRaw.InvAmt != 0) || (assetRaw.CurrValue != 0) || (assetRaw.TransCount > 0)) {
                var asset: AssetTotal = {
                    AMID: assetRaw.AMID,
                    SID: assetRaw.SID,
                    PFID: assetRaw.PFID,
                    AssetTypeID: assetTypeID,
                    Name: assetRaw.Name,
                    Refno: assetRaw.Refno,
                    TransCount: assetRaw.TransCount,
                    Quant: Big(assetRaw.Quant).toJSON(),
                    InvAmt: Big(assetRaw.InvAmt).toJSON(),
                    PxPur: Big(assetRaw.Quant).gt(0) ? Big(assetRaw.InvAmt).div(assetRaw.Quant).toJSON() : undefined,
                    PxCurr: Big(assetRaw.CurrPrice || 0).toJSON(),
                    CurrValue: Big(assetRaw.CurrValue).toJSON(),
                    IsCurrVManual: assetRaw.IsCurrVManual,
                    IsOrigCurrValueNonZero: !Big(assetRaw.CurrValue).eq(0),
                    TGain: Big(assetRaw.TGain).toJSON()
                };

                const assetIDForSearch = getSearchIDFromAsset(asset);
                if (!assetsForSearchMap.has(assetIDForSearch)) {
                    assetsForSearchMap.set(assetIDForSearch, asset.Name);
                }

                if (Big(asset.CurrValue || 0).eq(0) && !asset.IsCurrVManual) {
                    asset.CurrValue = asset.InvAmt;
                }

                asset.PreviousValueForTGainPct = calculatePreviousValueForTGainPct(asset, asset.TGain);

                calculateGainFields(asset);

                assets.ByID[asset.SID] = asset;
                assets.AllIDs.push(asset.SID);

                assetTypeTotal_InvAmt = assetTypeTotal_InvAmt.plus(asset.InvAmt || 0);
                assetTypeTotal_TGain = assetTypeTotal_TGain.plus(asset.TGain || 0);
                assetTypeTotal_CurrValue = assetTypeTotal_CurrValue.plus(asset.CurrValue || 0);
                assetTypeTotal_PreviousValueForTGainPct = assetTypeTotal_PreviousValueForTGainPct.plus(asset.PreviousValueForTGainPct || 0);
                assetTypeTotal_TransCount += (asset.TransCount || 0);
            }
        };

        if (assets && assets.AllIDs && assets.AllIDs.length > 0) {
            assetsByAssetType[assetTypeID] = assets;

            assetTypeTotal.InvAmt = assetTypeTotal_InvAmt.toJSON();
            assetTypeTotal.TGain = assetTypeTotal_TGain.toJSON();
            assetTypeTotal.CurrValue = assetTypeTotal_CurrValue.toJSON();
            assetTypeTotal.PreviousValueForTGainPct = assetTypeTotal_PreviousValueForTGainPct.toJSON();
            assetTypeTotal.TransCount = assetTypeTotal_TransCount;

            calculateGainFields(assetTypeTotal);

            assetTypeTotals.ByID[assetTypeID] = assetTypeTotal;
            assetTypeTotals.AllIDs.push(assetTypeTotal.AssetTypeID);

            portfolioTotal_InvAmt = portfolioTotal_InvAmt.plus(assetTypeTotal.InvAmt || 0);
            portfolioTotal_TGain = portfolioTotal_TGain.plus(assetTypeTotal.TGain || 0);
            portfolioTotal_CurrValue = portfolioTotal_CurrValue.plus(assetTypeTotal.CurrValue || 0);
            portfolioTotal_PreviousValueForTGainPct = portfolioTotal_PreviousValueForTGainPct.plus(assetTypeTotal.PreviousValueForTGainPct || 0);
            portfolioTotal_TransCount += (assetTypeTotal.TransCount || 0);
        }
    };

    portfolioTotal.InvAmt = portfolioTotal_InvAmt.toJSON();
    portfolioTotal.TGain = portfolioTotal_TGain.toJSON();
    portfolioTotal.CurrValue = portfolioTotal_CurrValue.toJSON();
    portfolioTotal.PreviousValueForTGainPct = portfolioTotal_PreviousValueForTGainPct.toJSON();
    portfolioTotal.TransCount = portfolioTotal_TransCount;

    calculateGainFields(portfolioTotal);

    var assetsForSearch: Array<{id: string, text: string}> = Array.from(assetsForSearchMap.keys()).map(x => ({id: x, text: assetsForSearchMap.get(x) || ''}));

    return <ActivePortfolioSummary>({
        CCID: action.payload.CCID,
        FamilyId: action.payload.FamilyId,
        PFID: action.payload.PFID,
        IsGroup: action.payload.IsGroup,
        AssetsByAssetType: assetsByAssetType,
        AssetTypeTotals: assetTypeTotals,
        PortfolioTotal: portfolioTotal,
        AssetsForSearch: assetsForSearch,
    });
}

const getSearchIDFromAsset = (asset: AssetTotal) => {
    return asset.AssetTypeID + '$' + asset.AMID + '$';
}

const calculatePxPur = (asset: TotalFields_Big) => {
    asset.PxPur = Big(asset.Quant || 0).gt(0) ? Big(asset.InvAmt || 0).div(asset.Quant || 0) : undefined;
}

const calculatePreviousValueForTGainPct = (asset: TotalFields, TGain?: string) => {
    var isPxCurrZeroOrNull = asset.PxCurr == null || Big(asset.PxCurr).eq(0);
    if (asset.IsOrigCurrValueNonZero && isPxCurrZeroOrNull) { // asset with non-zero current value and zero current price - eg: FDs, PFF so previous value is amount invested
        return asset.InvAmt;
    } else if (isPxCurrZeroOrNull) { // asset with zero current value and zero current price - eg: Stocks, Gold, etc with zero price so we ignore their previous value in today's gain calculation
        return new Big(0).toJSON();
    } else { // asset with non-zero current price, previous value = today's current value - today's gain
        return Big(asset.CurrValue || 0).minus(Big(TGain || 0).round(2)).toJSON();
    }
}

const calculateGainFields = (asset: TotalFields, TGain?: string, shouldUpdatePreviousValue: boolean = false) => {
    if (!TGain) TGain = asset.TGain;

    if (shouldUpdatePreviousValue) {
        asset.PreviousValueForTGainPct = calculatePreviousValueForTGainPct(asset, TGain);
    }

    setTGainFields(asset, TGain);
    setOGainFields(asset, Big(asset.CurrValue || 0).minus(asset.InvAmt || 0).toJSON());
}

const setTGainFields = (asset: TotalFields, TGain?: string) => {
    asset.TGain = TGain;
    if (TGain) {
        var _TGain = Big(TGain || 0);
        var _PreviousValueForTGainPct = Big(asset.PreviousValueForTGainPct || 0);
        asset.TChange = !_TGain.eq(0);
        asset.TUp = _TGain.gte(0);
        if (_PreviousValueForTGainPct.gt(0)) {
            asset.TGainPct = _TGain.div(_PreviousValueForTGainPct).toJSON();
        } else if (_PreviousValueForTGainPct.lt(0)) {
            asset.TGainPct = _TGain.div(_PreviousValueForTGainPct.times(-1)).toJSON();
        } else if (_TGain.eq(0)) {
            asset.TGainPct = new Big(0).toJSON();
        } else {
            asset.TGainPct = undefined;
        }
    }
}

const setOGainFields = (asset: TotalFields, OGain?: string) => {
    asset.OGain = OGain;
    if (OGain) {
        var _OGain = Big(OGain || 0);
        var _InvAmt = Big(asset.InvAmt || 0);
        asset.OChange = !_OGain.eq(0);
        asset.OUp = _OGain.gt(0);
        if (_InvAmt.gt(0)) {
            asset.OGainPct = _OGain.div(_InvAmt).toJSON();
        } else if (_InvAmt.lt(0)) {
            asset.OGainPct = _OGain.div(_InvAmt.times(-1)).toJSON();
        } else {
            asset.OGainPct = undefined;
        }
    }
}

//#endregion

//#region L1 Asset Allocation Categ Filter

export const getL1AACategFilterPortfolioSummary = (portfolioSummary: ActivePortfolioSummary, assetAllocationCategIDFilter: AssetAllocationCategID, assetsAddInfo: AssetsAddInfo | undefined = undefined) => {
    var assetsByAssetType: AssetsByAssetType = {};
    var assetTypeTotals: AssetTypeTotals = {
        ByID: {},
        AllIDs: []
    };
    var portfolioTotal: PortfolioTotal = {};

    var portfolioTotal_InvAmt: Big = new Big(0);
    var portfolioTotal_TGain: Big = new Big(0);
    var portfolioTotal_CurrValue: Big = new Big(0);
    var portfolioTotal_PreviousValueForTGainPct: Big = new Big(0);
    var portfolioTotal_TransCount: number = 0;

    for (var assetTypeID of portfolioSummary.AssetTypeTotals.AllIDs) {
        var assetAllocationCateg = getAssetTypeToAssetAllocationCateg(assetTypeID);
        
        if (assetAllocationCateg != AssetAllocationCategID.PerAsset) {
            if (assetAllocationCateg != assetAllocationCategIDFilter) {
                continue;
            }
        } else if (!assetsAddInfo) {
            continue;
        }

        // var assetTypeID: AssetTypeEnum = assetTypeRaw.AssetTypeID == AssetTypeEnum.MFEQ ? (assetTypeRaw.SeqNo == 10 ? AssetTypeEnum.MFEQ : AssetTypeEnum.MFD) : assetTypeRaw.AssetTypeID;
        var assets: SIDAssetTotals = {
            ByID: {},
            AllIDs: []
        }

        var assetTypeTotalForPortfolio = portfolioSummary.AssetTypeTotals.ByID[assetTypeID];

        if (!assetTypeID) continue;

        var assetTypeTotal: AssetTypeTotal = {
            AssetTypeID: assetTypeTotalForPortfolio.AssetTypeID,
            SeqNo: assetTypeTotalForPortfolio.SeqNo,
            AssetTypeName: getAssetTypeNameForAA(assetTypeID, assetAllocationCategIDFilter)
        };
        var assetTypeTotal_InvAmt: Big = new Big(0);
        var assetTypeTotal_TGain: Big = new Big(0);
        var assetTypeTotal_CurrValue: Big = new Big(0);
        var assetTypeTotal_PreviousValueForTGainPct: Big = new Big(0);
        var assetTypeTotal_TransCount: number = 0;
        
        for (var SID of (portfolioSummary.AssetsByAssetType[assetTypeID]?.AllIDs || [])) {
            var asset = portfolioSummary.AssetsByAssetType[assetTypeID]?.ByID[SID];
            if (!asset) continue;
            
            if (assetsAddInfo) {
                if (assetAllocationCateg == AssetAllocationCategID.PerAsset) {
                    // var assetAddInfo = assetsAddInfoForAssetType?.[asset.AMID] || {};
                    var categID = getAssetAllocationCategIDForAsset(assetTypeID, asset.AMID, assetsAddInfo) || AssetAllocationCategID.None;
                    // console.log('assetsAddInfoForAssetType', assetAddInfo, asset.AMID, categID);

                    if (categID != assetAllocationCategIDFilter) {
                        continue;
                    }
                }
            } else {
                continue;
            }

            // const assetIDForSearch = getSearchIDFromAsset(asset);
            // if (!assetsForSearchMap.has(assetIDForSearch)) {
            //     assetsForSearchMap.set(assetIDForSearch, asset.Name);
            // }

            // if (Big(asset.CurrValue || 0).eq(0) && !asset.IsCurrVManual) {
            //     asset.CurrValue = asset.InvAmt;
            // }

            // asset.PreviousValueForTGainPct = calculatePreviousValueForTGainPct(asset, asset.TGain);

            // calculateGainFields(asset);

            assets.ByID[asset.SID] = asset;
            assets.AllIDs.push(asset.SID);

            assetTypeTotal_InvAmt = assetTypeTotal_InvAmt.plus(asset.InvAmt || 0);
            assetTypeTotal_TGain = assetTypeTotal_TGain.plus(asset.TGain || 0);
            assetTypeTotal_CurrValue = assetTypeTotal_CurrValue.plus(asset.CurrValue || 0);
            assetTypeTotal_PreviousValueForTGainPct = assetTypeTotal_PreviousValueForTGainPct.plus(asset.PreviousValueForTGainPct || 0);
            assetTypeTotal_TransCount += (asset.TransCount || 0);
        };

        if (assets && assets.AllIDs && assets.AllIDs.length > 0) {
            assetsByAssetType[assetTypeID] = assets;

            assetTypeTotal.InvAmt = assetTypeTotal_InvAmt.toJSON();
            assetTypeTotal.TGain = assetTypeTotal_TGain.toJSON();
            assetTypeTotal.CurrValue = assetTypeTotal_CurrValue.toJSON();
            assetTypeTotal.PreviousValueForTGainPct = assetTypeTotal_PreviousValueForTGainPct.toJSON();
            assetTypeTotal.TransCount = assetTypeTotal_TransCount;

            calculateGainFields(assetTypeTotal);

            assetTypeTotals.ByID[assetTypeID] = assetTypeTotal;
            assetTypeTotals.AllIDs.push(assetTypeTotal.AssetTypeID);

            portfolioTotal_InvAmt = portfolioTotal_InvAmt.plus(assetTypeTotal.InvAmt || 0);
            portfolioTotal_TGain = portfolioTotal_TGain.plus(assetTypeTotal.TGain || 0);
            portfolioTotal_CurrValue = portfolioTotal_CurrValue.plus(assetTypeTotal.CurrValue || 0);
            portfolioTotal_PreviousValueForTGainPct = portfolioTotal_PreviousValueForTGainPct.plus(assetTypeTotal.PreviousValueForTGainPct || 0);
            portfolioTotal_TransCount += (assetTypeTotal.TransCount || 0);
        }
    };

    portfolioTotal.InvAmt = portfolioTotal_InvAmt.toJSON();
    portfolioTotal.TGain = portfolioTotal_TGain.toJSON();
    portfolioTotal.CurrValue = portfolioTotal_CurrValue.toJSON();
    portfolioTotal.PreviousValueForTGainPct = portfolioTotal_PreviousValueForTGainPct.toJSON();
    portfolioTotal.TransCount = portfolioTotal_TransCount;

    calculateGainFields(portfolioTotal);

    // var assetsForSearch: Array<{id: string, text: string}> = Array.from(assetsForSearchMap.keys()).map(x => ({id: x, text: assetsForSearchMap.get(x) || ''}));

    return <ActivePortfolioSummary>({
        CCID: portfolioSummary.CCID,
        FamilyId: portfolioSummary.FamilyId,
        PFID: portfolioSummary.PFID,
        IsGroup: portfolioSummary.IsGroup,
        AssetAllocationCategID: assetAllocationCategIDFilter,
        AssetsByAssetType: assetsByAssetType,
        AssetTypeTotals: assetTypeTotals,
        PortfolioTotal: portfolioTotal,
        AssetsForSearch: []
    });
}

//#endregion

//#region Asset Allocation Categories

export const getAssetAllocationCategTotals = (portfolioSummary?: ActivePortfolioSummary, assetsAddInfo?: AssetsAddInfo): AssetAllocationCategTotals => {
    var assetAllocationCategTotals_Big: AssetAllocationCategTotals_Big = {
        ByID: {},
        AllIDs: []
    }

    var AssetAllocationCategTotals: AssetAllocationCategTotals = {
        ByID: {},
        AllIDs: []
    }
    
    if (portfolioSummary == null || assetsAddInfo == null) return AssetAllocationCategTotals;

    portfolioSummary.AssetTypeTotals.AllIDs.forEach((assetTypeID: AssetTypeEnum) => {
        var assetAllocationCateg = getAssetTypeToAssetAllocationCateg(assetTypeID);
        if (assetAllocationCateg == AssetAllocationCategID.PerAsset) {
            portfolioSummary.AssetsByAssetType[assetTypeID]?.AllIDs.forEach((SID) => {
                var asset = portfolioSummary.AssetsByAssetType[assetTypeID].ByID[SID];
                if (asset) {
                    addAssetToAssetAllocationCategTotal(asset, assetsAddInfo, assetAllocationCategTotals_Big);
                }
            })
        } else if (assetAllocationCateg != AssetAllocationCategID.Exclude) {
            addToAssetAllocationCategTotal(getAssetTypeToAssetAllocationCateg(assetTypeID), portfolioSummary.AssetTypeTotals.ByID[assetTypeID], assetAllocationCategTotals_Big);
        }
    });

    AssetAllocationCategTotals.AllIDs = assetAllocationCategTotals_Big.AllIDs;

    assetAllocationCategTotals_Big.AllIDs.forEach((assetAllocationCategID) => {
        var assetAllocationCategTotal_Big: AssetAllocationCategTotal_Big = assetAllocationCategTotals_Big.ByID[assetAllocationCategID];
        
        var assetAllocationCategTotal = {
            AssetAllocationCategID: assetAllocationCategTotal_Big.AssetAllocationCategID,
            AssetAllocationCategName: assetAllocationCategTotal_Big.AssetAllocationCategName,
            InvAmt: new Big(assetAllocationCategTotal_Big.InvAmt || 0).toJSON(),
            TGain: new Big(assetAllocationCategTotal_Big.TGain || 0).toJSON(),
            CurrValue: new Big(assetAllocationCategTotal_Big.CurrValue || 0).toJSON(),
            PreviousValueForTGainPct: new Big(assetAllocationCategTotal_Big.PreviousValueForTGainPct || 0).toJSON(),
            TransCount: assetAllocationCategTotal_Big.TransCount,
        }

        calculateGainFields(assetAllocationCategTotal);

        AssetAllocationCategTotals.ByID[assetAllocationCategID] = assetAllocationCategTotal;
    })

    return AssetAllocationCategTotals;
}

const getAssetAllocationCategIDForAsset = (assetTypeID: number, AMID: number, assetsAddInfo: AssetsAddInfo) => {
    if (assetTypeID == AssetTypeEnum.EQ) {
        var stockAddInfo = assetsAddInfo.Stocks && assetsAddInfo.Stocks[AMID];
        if (stockAddInfo && stockAddInfo.AssetAllocationCategID != null) {
            return stockAddInfo.AssetAllocationCategID;
        }       
        else {
            return AssetAllocationCategID.Equity;
        }
    } else if ((assetTypeID == AssetTypeEnum.MFEQ || assetTypeID == AssetTypeEnum.MFD)) {
        var mfAddInfo = assetsAddInfo.MF && assetsAddInfo.MF[AMID];
        if (mfAddInfo && mfAddInfo.AssetAllocationCategID != null) {
            return mfAddInfo.AssetAllocationCategID;
        }       
        else {
            return AssetAllocationCategID.Other;
        }
    } else if (assetTypeID == AssetTypeEnum.BND) {
        var bondsAddInfo = assetsAddInfo.Others && assetsAddInfo.Others[AssetTypeEnum.BND] && assetsAddInfo.Others[AssetTypeEnum.BND][AMID];
        if (bondsAddInfo && bondsAddInfo.AssetAllocationCategID != null) {
            return bondsAddInfo.AssetAllocationCategID;
        }       
        else {
            return AssetAllocationCategID.Debt;
        }
    }
}

const addAssetToAssetAllocationCategTotal = (asset: AssetTotal, assetsAddInfo: AssetsAddInfo, assetAllocationCategTotals_Big: AssetAllocationCategTotals_Big) => {
    if (asset.AssetTypeID == AssetTypeEnum.EQ) {
        var stockAddInfo = assetsAddInfo.Stocks && assetsAddInfo.Stocks[asset.AMID];
        if (stockAddInfo && stockAddInfo.AssetAllocationCategID != null) {
            addToAssetAllocationCategTotal(stockAddInfo.AssetAllocationCategID, asset, assetAllocationCategTotals_Big);
        }       
        else {
            addToAssetAllocationCategTotal(AssetAllocationCategID.Equity, asset, assetAllocationCategTotals_Big);
        }
    } else if ((asset.AssetTypeID == AssetTypeEnum.MFEQ || asset.AssetTypeID == AssetTypeEnum.MFD)) {
        var mfAddInfo = assetsAddInfo.MF && assetsAddInfo.MF[asset.AMID];
        if (mfAddInfo && mfAddInfo.AssetAllocationCategID != null) {
            addToAssetAllocationCategTotal(mfAddInfo.AssetAllocationCategID, asset, assetAllocationCategTotals_Big);
        }       
        else {
            addToAssetAllocationCategTotal(AssetAllocationCategID.Other, asset, assetAllocationCategTotals_Big);
        }
    } else if (asset.AssetTypeID == AssetTypeEnum.BND) {
        var bondsAddInfo = assetsAddInfo.Others && assetsAddInfo.Others[AssetTypeEnum.BND] && assetsAddInfo.Others[AssetTypeEnum.BND][asset.AMID];
        if (bondsAddInfo && bondsAddInfo.AssetAllocationCategID != null) {
            addToAssetAllocationCategTotal(bondsAddInfo.AssetAllocationCategID, asset, assetAllocationCategTotals_Big);
        }       
        else {
            addToAssetAllocationCategTotal(AssetAllocationCategID.Debt, asset, assetAllocationCategTotals_Big);
        }
    }
}

const addToAssetAllocationCategTotal = (assetAllocationCategID: AssetAllocationCategID, asset: TotalFields, assetAllocationCategTotals: AssetAllocationCategTotals_Big) => {
    if (assetAllocationCategID == null || assetAllocationCategID <= 0) return;

    var activeAssetAllocationCateg = assetAllocationCategTotals.ByID[assetAllocationCategID];
    if (activeAssetAllocationCateg == null) {
        activeAssetAllocationCateg = {
            AssetAllocationCategID: assetAllocationCategID,
            AssetAllocationCategName: AssetAllocationCategNameMapping[assetAllocationCategID],
            InvAmt: new Big(0),
            TGain: new Big(0),
            CurrValue: new Big(0),
            PreviousValueForTGainPct: new Big(0),
            TransCount: 0
        };

        assetAllocationCategTotals.AllIDs.push(assetAllocationCategID);
        assetAllocationCategTotals.ByID[assetAllocationCategID] = activeAssetAllocationCateg;
    }

    activeAssetAllocationCateg.InvAmt = (activeAssetAllocationCateg.InvAmt || new Big(0)).plus(asset.InvAmt || 0);
    activeAssetAllocationCateg.TGain = (activeAssetAllocationCateg.TGain || new Big(0)).plus(asset.TGain || 0);
    activeAssetAllocationCateg.CurrValue = (activeAssetAllocationCateg.CurrValue || new Big(0)).plus(asset.CurrValue || 0);
    activeAssetAllocationCateg.PreviousValueForTGainPct = (activeAssetAllocationCateg.PreviousValueForTGainPct || new Big(0)).plus(asset.PreviousValueForTGainPct || 0);
    activeAssetAllocationCateg.TransCount = (activeAssetAllocationCateg.TransCount || 0) + (asset.TransCount || 0);
}

const getAssetTypeToAssetAllocationCateg = (assetType: AssetTypeEnum) => {
    switch (assetType) {
        case AssetTypeEnum.EQ:
        case AssetTypeEnum.MFEQ:
        case AssetTypeEnum.MFD:
        case AssetTypeEnum.BND:
            return AssetAllocationCategID.PerAsset;
        case AssetTypeEnum.LN:
            return AssetAllocationCategID.Exclude;
        case AssetTypeEnum.PE:
            return AssetAllocationCategID.Equity;
        case AssetTypeEnum.PR:
            return AssetAllocationCategID.RealEstate;
        case AssetTypeEnum.GLD:
        case AssetTypeEnum.SLV:
        case AssetTypeEnum.JWL:
            return AssetAllocationCategID.Gold_Plus;
        case AssetTypeEnum.FD:
        case AssetTypeEnum.CD:
        case AssetTypeEnum.NCD:
        case AssetTypeEnum.PO:
        case AssetTypeEnum.PPF:
            return AssetAllocationCategID.Debt;
        case AssetTypeEnum.ULIP:
        case AssetTypeEnum.INS:
            return AssetAllocationCategID.Retirement;
        default:
        case AssetTypeEnum.ART:
        case AssetTypeEnum.MIS:
            return AssetAllocationCategID.Other;
        case AssetTypeEnum.BANKS:
            return AssetAllocationCategID.Cash;
    }
}

//#endregion

//#region Categories eg: Sectors, Market Cap, etc

const getAssetsAddInfoForAssetType = (assetTypeID: AssetTypeEnum, assetsAddInfo: AssetsAddInfo) => {
    switch (assetTypeID) {
        case AssetTypeEnum.EQ:
            return assetsAddInfo.Stocks;
        case AssetTypeEnum.MFEQ:
        case AssetTypeEnum.MFD:
            return assetsAddInfo.MF;
        default:
            return assetsAddInfo?.Others?.[assetTypeID];
    }
}

const addToSubCategTotal = (categID: string | number, asset: TotalFields, holdingCategTotals: HoldingsCategTotals_Big, categIDNameMapping: {[ID: (string | number)]: string}) => {
    var activeCateg = holdingCategTotals.ByID[categID];
    if (activeCateg == null) {
        activeCateg = {
            CategID: categID,
            CategName: categIDNameMapping[categID],
            InvAmt: new Big(0),
            TGain: new Big(0),
            CurrValue: new Big(0),
            PreviousValueForTGainPct: new Big(0),
            TransCount: 0
        };

        holdingCategTotals.AllIDs.push(categID);
        holdingCategTotals.ByID[categID] = activeCateg;
    }
    addToSubCategTotalGivenActiveCateg(activeCateg, asset);
}

const addToSubCategTotalGivenActiveCateg = (activeCateg: HoldingCategTotal_Big, asset: TotalFields) => {
    activeCateg.InvAmt = (activeCateg.InvAmt || new Big(0)).plus(asset.InvAmt || 0);
    activeCateg.TGain = (activeCateg.TGain || new Big(0)).plus(asset.TGain || 0);
    activeCateg.CurrValue = (activeCateg.CurrValue || new Big(0)).plus(asset.CurrValue || 0);
    activeCateg.PreviousValueForTGainPct = (activeCateg.PreviousValueForTGainPct || new Big(0)).plus(asset.PreviousValueForTGainPct || 0);
    activeCateg.TransCount = (activeCateg.TransCount || 0) + (asset.TransCount || 0);

    return activeCateg;
}

export const getAssetTypeSubCategTotals = (assetTypeID: AssetTypeEnum, assetsByAssetType?: SIDAssetTotals, assetsAddInfo?: AssetsAddInfo, getCategIDFromAssetAddInfo?: (item: any) => string | number, getCategNameFromAssetAddInfo?: (item: any) => string, filterAssetsAddInfo?: {getCategIDFromAssetAddInfo?: (item: any) => string | number, matchValue: string | number}): HoldingsCategTotals => {
    var holdingCategTotals_Big: HoldingsCategTotals_Big = {
        ByID: {},
        AllIDs: []
    }

    var holdingCategTotals: HoldingsCategTotals = {
        ByID: {},
        AllIDs: []
    }

    var categIDNameMapping: {[ID: (string | number)]: string} = {};
    
    if (assetsByAssetType == null || assetsAddInfo == null || getCategIDFromAssetAddInfo == null || getCategNameFromAssetAddInfo == null) return holdingCategTotals;

    var assetsAddInfoForAssetType: any = getAssetsAddInfoForAssetType(assetTypeID, assetsAddInfo);

    if (assetsAddInfoForAssetType == null) return holdingCategTotals;

    assetsByAssetType.AllIDs.forEach((SID) => {
        var asset = assetsByAssetType.ByID[SID];
        if (asset) {
            var assetAddInfo = assetsAddInfoForAssetType?.[asset.AMID] || {};
            var includeAsset = true;
            if (filterAssetsAddInfo && filterAssetsAddInfo.getCategIDFromAssetAddInfo) {
                var filterValue = filterAssetsAddInfo.getCategIDFromAssetAddInfo(assetAddInfo) || 0;
                if (filterValue !== filterAssetsAddInfo.matchValue) {
                    includeAsset = false;
                }
            }

            if (includeAsset) {
                var categID = getCategIDFromAssetAddInfo(assetAddInfo) || 0;
                if (categIDNameMapping[categID] == undefined) {
                    categIDNameMapping[categID] = getCategNameFromAssetAddInfo(assetAddInfo);
                }
                
                addToSubCategTotal(categID, asset, holdingCategTotals_Big, categIDNameMapping);
            }
        }
    })

    holdingCategTotals.AllIDs = holdingCategTotals_Big.AllIDs;

    holdingCategTotals_Big.AllIDs.forEach((categID) => {
        var holdingCategTotal_Big: HoldingCategTotal_Big = holdingCategTotals_Big.ByID[categID];
        
        var holdingCategTotal = {
            CategID: holdingCategTotal_Big.CategID,
            CategName: holdingCategTotal_Big.CategName,
            InvAmt: new Big(holdingCategTotal_Big.InvAmt || 0).toJSON(),
            TGain: new Big(holdingCategTotal_Big.TGain || 0).toJSON(),
            CurrValue: new Big(holdingCategTotal_Big.CurrValue || 0).toJSON(),
            PreviousValueForTGainPct: new Big(holdingCategTotal_Big.PreviousValueForTGainPct || 0).toJSON(),
            TransCount: holdingCategTotal_Big.TransCount,
        }

        calculateGainFields(holdingCategTotal);

        holdingCategTotals.ByID[categID] = holdingCategTotal;
    })

    return holdingCategTotals;
}

export const getAssetTypeSubCategTotalForID = (categID: string | number, assetTypeID: AssetTypeEnum, assetsByAssetType?: SIDAssetTotals, assetsAddInfo?: AssetsAddInfo, getCategIDFromAssetAddInfo?: (item: any) => string | number, getCategNameFromAssetAddInfo?: (item: any) => string): HoldingCategTotal | undefined => {
    var holdingCategTotal_Big: HoldingCategTotal_Big | undefined;
    
    if (assetsByAssetType == null || assetsAddInfo == null || getCategIDFromAssetAddInfo == null || getCategNameFromAssetAddInfo == null) return undefined;

    var assetsAddInfoForAssetType: any = getAssetsAddInfoForAssetType(assetTypeID, assetsAddInfo);

    if (assetsAddInfoForAssetType == null) return undefined;

    assetsByAssetType.AllIDs.forEach((SID) => {
        var asset = assetsByAssetType.ByID[SID];
        if (asset) {
            var assetAddInfo = assetsAddInfoForAssetType?.[asset.AMID] || {};
            var activeCategID = getCategIDFromAssetAddInfo(assetAddInfo) || 0;
            console.log(activeCategID, categID, 'getAssetTypeSubCategTotalForID')
            if ((activeCategID || 0) == categID) {
                if (holdingCategTotal_Big == undefined) {
                    holdingCategTotal_Big = {
                        CategID: activeCategID,
                        CategName: getCategNameFromAssetAddInfo(assetAddInfo),
                        InvAmt: new Big(0),
                        TGain: new Big(0),
                        CurrValue: new Big(0),
                        PreviousValueForTGainPct: new Big(0),
                        TransCount: 0
                    };
                }
                holdingCategTotal_Big = addToSubCategTotalGivenActiveCateg(holdingCategTotal_Big, asset);
            }
        }
    })

    if (holdingCategTotal_Big != undefined) {
        var holdingCategTotal = {
            CategID: holdingCategTotal_Big.CategID,
            CategName: holdingCategTotal_Big.CategName,
            InvAmt: new Big(holdingCategTotal_Big.InvAmt || 0).toJSON(),
            TGain: new Big(holdingCategTotal_Big.TGain || 0).toJSON(),
            CurrValue: new Big(holdingCategTotal_Big.CurrValue || 0).toJSON(),
            PreviousValueForTGainPct: new Big(holdingCategTotal_Big.PreviousValueForTGainPct || 0).toJSON(),
            TransCount: holdingCategTotal_Big.TransCount,
        }
    
        calculateGainFields(holdingCategTotal);
    
        return holdingCategTotal;
    } else {
        return undefined;
    }
}

export const getAssetTypeSubCategAMIDAssetTotals = (categID: string | number, assetTypeID: AssetTypeEnum, assetsByAssetType?: SIDAssetTotals, assetsAddInfo?: AssetsAddInfo, getCategIDFromAssetAddInfo?: (item: any) => string | number): AMIDAssetTotal[] => {
    var assetList: AssetTotal[] = [];
    
    if (assetsByAssetType == null || assetsAddInfo == null || getCategIDFromAssetAddInfo == null) return assetList;

    var assetsAddInfoForAssetType: any = getAssetsAddInfoForAssetType(assetTypeID, assetsAddInfo);

    if (assetsAddInfoForAssetType == null) return assetList;

    const isIncludedInCateg = (asset: AssetTotal) => {
        var assetAddInfo = assetsAddInfoForAssetType?.[asset.AMID] || {};
        var activeCategID = getCategIDFromAssetAddInfo(assetAddInfo) || 0;
        return activeCategID == categID;
    }

    return getAMIDAssetTotals(assetsByAssetType, isIncludedInCateg);
}

//#endregion

//#region Total helpers

export const addToAssetTotal = (currentTotal: TotalFields_Big, asset: TotalFields) => {
    currentTotal.Quant = (currentTotal.Quant || new Big(0)).plus(asset.Quant || 0);
    currentTotal.InvAmt = (currentTotal.InvAmt || new Big(0)).plus(asset.InvAmt || 0);
    currentTotal.TGain = (currentTotal.TGain || new Big(0)).plus(asset.TGain || 0);
    currentTotal.CurrValue = (currentTotal.CurrValue || new Big(0)).plus(asset.CurrValue || 0);
    currentTotal.PreviousValueForTGainPct = (currentTotal.PreviousValueForTGainPct || new Big(0)).plus(asset.PreviousValueForTGainPct || 0);
    currentTotal.TransCount = (currentTotal.TransCount || 0) + (asset.TransCount || 0);
}

const getNewTotalField = (): TotalFields_Big => {
    return {
        Quant: new Big(0),
        InvAmt: new Big(0),
        TGain: new Big(0),
        CurrValue: new Big(0),
        PreviousValueForTGainPct: new Big(0),
        TransCount: 0
    }
}

// const getTotalFieldsBigForAggregation = (asset: TotalFields_Big | TotalFields): TotalFields_Big => {
//     return {
//         InvAmt: new Big(asset.InvAmt),
//         TGain: new Big(asset.TGain),
//         CurrValue: new Big(asset.CurrValue),
//         PreviousValueForTGainPct: new Big(asset.PreviousValueForTGainPct),
//         TransCount: asset.TransCount
//     }
// }

const getTotalFieldFromTotalFieldBigForAggregation = (totalFieldBig: TotalFields_Big): TotalFields => {
    calculatePxPur(totalFieldBig);
    return {
        Quant: totalFieldBig.Quant ? new Big(totalFieldBig.Quant).toJSON(): '',
        PxPur: totalFieldBig.PxPur ? new Big(totalFieldBig.PxPur).toJSON() : '',
        PxCurr: totalFieldBig.PxCurr ? new Big(totalFieldBig.PxCurr).toJSON(): '',
        InvAmt: totalFieldBig.InvAmt ? new Big(totalFieldBig.InvAmt).toJSON(): '',
        TGain: totalFieldBig.TGain ? new Big(totalFieldBig.TGain).toJSON(): '',
        CurrValue: totalFieldBig.CurrValue ? new Big(totalFieldBig.CurrValue).toJSON(): '',
        PreviousValueForTGainPct: totalFieldBig.PreviousValueForTGainPct ? new Big(totalFieldBig.PreviousValueForTGainPct).toJSON(): '',
        TransCount: totalFieldBig.TransCount,
    }
}

//#endregion

//#region AMID Totals

export const getAMIDAssetByAMID = (sidAssetTotals: SIDAssetTotals, isIncludedFunction: (asset: AssetTotal) => boolean = (asset: AssetTotal) => true) => {
    console.log('getAMIDAssetTotals1',sidAssetTotals)
    var assetsByAMID: {[AMID: number]: AMIDAssetTotal_Big} = {};
    for (var SID of sidAssetTotals.AllIDs) {
        var asset = sidAssetTotals.ByID[SID];
        if (!asset) continue;

        if (!isIncludedFunction || !isIncludedFunction(asset)) continue;

        var amidAsset = assetsByAMID[asset.AMID];
        if (!amidAsset) {
            amidAsset = {
                ...getNewTotalField(),
                PFID: asset.PFID,
                AMID: asset.AMID,
                AssetTypeID: asset.AssetTypeID,
                Name: asset.Name,
                PxCurr: asset.PxCurr ? new Big(asset.PxCurr) : undefined
            }
        }
        
        addToAssetTotal(amidAsset, asset);
        
        assetsByAMID[asset.AMID] = amidAsset;
    }

    return assetsByAMID;
}

export const getAMIDAssetTotals = (sidAssetTotals: SIDAssetTotals, isIncludedFunction: (asset: AssetTotal) => boolean = (asset: AssetTotal) => true) => {
    var assetsByAMID = getAMIDAssetByAMID(sidAssetTotals, isIncludedFunction);

    console.log('getAMIDAssetTotals2',assetsByAMID)

    var amidAssetList: AMIDAssetTotal[] = [];

    for (var AMIDStr in assetsByAMID) {
        var AMID = +AMIDStr;
        var amidAsset = assetsByAMID[AMID];
        if (amidAsset) {
            var amidAssetTotal: AMIDAssetTotal = {
                PFID: amidAsset.PFID,
                AMID: amidAsset.AMID,
                AssetTypeID: amidAsset.AssetTypeID,
                Name: amidAsset.Name,
                ...getTotalFieldFromTotalFieldBigForAggregation(amidAsset)
            };
            calculateGainFields(amidAssetTotal);
            amidAssetList.push(amidAssetTotal);
        }
    }

    console.log('getAMIDAssetTotals3',amidAssetList)

    return amidAssetList;
}

export const getAMIDAssetTotalForAMID = (sidAssetTotals: SIDAssetTotals, AMID: number) => {
    var amidAsset: AMIDAssetTotal_Big | undefined = undefined;
    for (var SID of sidAssetTotals.AllIDs) {
        var asset = sidAssetTotals.ByID[SID];
        if (!asset || asset.AMID != AMID) continue;

        if (!amidAsset) {
            amidAsset = {
                ...getNewTotalField(),
                PFID: asset.PFID,
                AMID: asset.AMID,
                AssetTypeID: asset.AssetTypeID,
                Name: asset.Name,
                PxCurr: asset.PxCurr ? new Big(asset.PxCurr) : undefined
            }
        }
        
        addToAssetTotal(amidAsset, asset);

        if (amidAsset.AssetTypeID > AssetTypeEnum.ASSET_WITH_QUANT) {
            amidAsset.SID = asset.SID;
            break;
        }
    }

    if (!amidAsset) return undefined;

    var amidAssetTotal: AMIDAssetTotal = {
        PFID: amidAsset.PFID,
        AMID: amidAsset.AMID,
        AssetTypeID: amidAsset.AssetTypeID,
        Name: amidAsset.Name,
        SID: amidAsset.SID,
        ...getTotalFieldFromTotalFieldBigForAggregation(amidAsset)
    };
    
    calculateGainFields(amidAssetTotal);

    return amidAssetTotal;
}

//#endregion

//#region Filter SIDs for AMID

export const getSIDAssetsForAMID = (sidAssetTotals: SIDAssetTotals, AMID: number) => {
    var sidAssetList: AssetTotal[] = [];
    for (var SID of sidAssetTotals.AllIDs) {
        var asset = sidAssetTotals.ByID[SID];
        if (!asset || asset.AMID != AMID) continue;

        sidAssetList.push(asset);
    }

    return sidAssetList;
}

//#endregion

//#region AssetsAddInfo

export const getAssetAddInfo = (AssetTypeID: AssetTypeEnum, AMID: number, assetsAddInfo: AssetsAddInfo) => {
    if (AssetTypeID == AssetTypeEnum.EQ) {
        return assetsAddInfo.Stocks && assetsAddInfo.Stocks[AMID];
    } else if ((AssetTypeID == AssetTypeEnum.MFEQ || AssetTypeID == AssetTypeEnum.MFD)) {
        return assetsAddInfo.MF && assetsAddInfo.MF[AMID];
    } else if (AssetTypeID == AssetTypeEnum.BND) {
        return assetsAddInfo.Others && assetsAddInfo.Others[AssetTypeEnum.BND] && assetsAddInfo.Others[AssetTypeEnum.BND][AMID];
    }
}

//#endregion

//#region Price Update

export const updatePricesInActivePortfolioSummary = (state: SharedPortfolioSummaryState, activePortfolioSummary?: ActivePortfolioSummary, priceValues?: PriceValues) => {
    if (!activePortfolioSummary || !priceValues || !state.ActivePortfolioSummary) return;

    console.log('assetsForAssetType', activePortfolioSummary, priceValues);

    var portfolioTotal: PortfolioTotal = { ...activePortfolioSummary.PortfolioTotal };

    var portfolioTotal_InvAmt: Big = new Big(0);
    var portfolioTotal_TGain: Big = new Big(0);
    var portfolioTotal_CurrValue: Big = new Big(0);
    var portfolioTotal_PreviousValueForTGainPct: Big = new Big(0);
    var portfolioTotal_TransCount: number = 0;

    for (const _assetTypeID in activePortfolioSummary.AssetTypeTotals.ByID) {
        const assetTypeID = +_assetTypeID;
        var assetTypeIDForPrices = assetTypeID;
        if (assetTypeIDForPrices == AssetTypeEnum.MFD) assetTypeIDForPrices = AssetTypeEnum.MFEQ
        var assetTypeTotal = { ...activePortfolioSummary.AssetTypeTotals.ByID[assetTypeID] };

        const priceValuesForAssetType = priceValues[assetTypeIDForPrices];
        if (priceValuesForAssetType) {
            var assetsForAssetType = activePortfolioSummary.AssetsByAssetType[assetTypeID];
            if (!assetsForAssetType) continue;

            var assetTypeTotal_InvAmt: Big = new Big(0);
            var assetTypeTotal_TGain: Big = new Big(0);
            var assetTypeTotal_CurrValue: Big = new Big(0);
            var assetTypeTotal_PreviousValueForTGainPct: Big = new Big(0);
            var assetTypeTotal_TransCount: number = 0;

            for (const _SID in assetsForAssetType.ByID) {
                const SID = +_SID;
                var asset = { ...assetsForAssetType.ByID[SID] };
                const prices = priceValuesForAssetType['' + asset.AMID];
                if (prices) {
                    var PxCurr = Big(prices.CP);
                    var PxPrev = Big(prices.PP);
                    asset.PxCurr = PxCurr.toJSON();
                    asset.PxPrev = PxPrev.toJSON();

                    if (PxCurr.gt(0)) {
                        if (Big(asset.Quant || 0).gt(0)) {
                            asset.CurrValue = PxCurr.times(asset.Quant || 0).round(2).toJSON();
                            calculateGainFields(asset, ((PxCurr.minus(PxPrev)).times(asset.Quant || 0)).round(2).toJSON(), true);
                        }
                    } else {
                        asset.CurrValue = asset.InvAmt;
                        calculateGainFields(asset, new Big(0).toJSON(), true);
                    }
                }

                state.ActivePortfolioSummary.AssetsByAssetType[assetTypeID].ByID[SID] = asset;

                assetTypeTotal_InvAmt = assetTypeTotal_InvAmt.plus(asset.InvAmt || 0);
                assetTypeTotal_TGain = assetTypeTotal_TGain.plus(asset.TGain || 0);
                assetTypeTotal_CurrValue = assetTypeTotal_CurrValue.plus(asset.CurrValue || 0);
                assetTypeTotal_PreviousValueForTGainPct = assetTypeTotal_PreviousValueForTGainPct.plus(asset.PreviousValueForTGainPct || 0);
                assetTypeTotal_TransCount += (asset.TransCount || 0);
            }

            assetTypeTotal.InvAmt = assetTypeTotal_InvAmt.toJSON();
            assetTypeTotal.TGain = assetTypeTotal_TGain.toJSON();
            assetTypeTotal.CurrValue = assetTypeTotal_CurrValue.toJSON();
            assetTypeTotal.PreviousValueForTGainPct = assetTypeTotal_PreviousValueForTGainPct.toJSON();
            assetTypeTotal.TransCount = assetTypeTotal_TransCount;

            calculateGainFields(assetTypeTotal);

            state.ActivePortfolioSummary.AssetTypeTotals.ByID[assetTypeID] = assetTypeTotal;
        }

        portfolioTotal_InvAmt = portfolioTotal_InvAmt.plus(assetTypeTotal.InvAmt || 0);
        portfolioTotal_TGain = portfolioTotal_TGain.plus(assetTypeTotal.TGain || 0);
        portfolioTotal_CurrValue = portfolioTotal_CurrValue.plus(assetTypeTotal.CurrValue || 0);
        portfolioTotal_PreviousValueForTGainPct = portfolioTotal_PreviousValueForTGainPct.plus(assetTypeTotal.PreviousValueForTGainPct || 0);
        portfolioTotal_TransCount += (assetTypeTotal.TransCount || 0);
    }

    portfolioTotal.InvAmt = portfolioTotal_InvAmt.toJSON();
    portfolioTotal.TGain = portfolioTotal_TGain.toJSON();
    portfolioTotal.CurrValue = portfolioTotal_CurrValue.toJSON();
    portfolioTotal.PreviousValueForTGainPct = portfolioTotal_PreviousValueForTGainPct.toJSON();
    portfolioTotal.TransCount = portfolioTotal_TransCount;

    calculateGainFields(portfolioTotal);

    state.ActivePortfolioSummary.PortfolioTotal = portfolioTotal;

    console.log('updatePricesInActivePortfolioSummary', portfolioTotal);
}

//#endregion

// #region Price URLs

const stockUrlStart = 'https://am.mprofit.in/apiXML/EQMF/prices?op=100&date=';
const mfUrlStart = 'https://am.mprofit.in/apiXML/EQMF/prices?op=100&date=';
const bondUrlStart = 'https://fno.mprofit.in/Bonds/ResponseBonds.aspx?op=500&date=';
const fnoUrlStart = 'https://fno.mprofit.in/Response.aspx?op=500&fno_code=';

export const getPriceURLs = (activePortfolioSummary: ActivePortfolioSummary) => {
    let priceURLs: { [AssetTypeID: number]: string[] } = {};

    var today = dateFormat(new Date(), 'dd-mm-yyyy');

    var amidsByAssetType = new Map<AssetTypeEnum, Set<number>>();

    for (const _assetTypeID in activePortfolioSummary.AssetsByAssetType) {
        var assetTypeID: AssetTypeEnum = +_assetTypeID;
        if (assetTypeID == AssetTypeEnum.MFD) assetTypeID = AssetTypeEnum.MFEQ;

        if ([AssetTypeEnum.EQ, AssetTypeEnum.MFEQ, AssetTypeEnum.MFD, AssetTypeEnum.BND].includes(assetTypeID)) {
            if (!amidsByAssetType.has(assetTypeID)) {
                amidsByAssetType.set(assetTypeID, new Set<number>());
            }
            var amidsForAssetType = amidsByAssetType.get(assetTypeID);
            if (amidsForAssetType == undefined) continue;

            for (const _SID in activePortfolioSummary.AssetsByAssetType[_assetTypeID].ByID) {
                const asset = activePortfolioSummary.AssetsByAssetType[_assetTypeID].ByID[_SID];
                amidsForAssetType.add(asset.AMID);
            }
        }
    }

    amidsByAssetType.forEach((amidsForAssetType, assetTypeID) => {
        var urlStart: string = '';
        var urlsForAssetType: string[] = [];

        switch (assetTypeID) {
            case AssetTypeEnum.EQ:
                urlStart = stockUrlStart + today + '&bse_code=';
                break;
            case AssetTypeEnum.MFEQ:
            case AssetTypeEnum.MFD:
                urlStart = mfUrlStart + today + '&mf_code=';
                break;
            case AssetTypeEnum.BND:
                urlStart = bondUrlStart + today + '&fno_code=';
                break;
        }

        var url = urlStart;

        var count = 0;
        amidsForAssetType.forEach((AMID) => {
            count += 1;
            url += AMID + ',';
            if (count == 50) {
                count = 0;
                url = url.slice(0, -1);
                urlsForAssetType.push(url);
                url = urlStart;
            }
        });

        if (url[url.length - 1] == ',') {
            url = url.slice(0, -1);
        }
        if (count > 0) {
            urlsForAssetType.push(url);
        }

        priceURLs[assetTypeID] = urlsForAssetType;
    })

    return priceURLs;
}

export const getWatchlistPriceURLs = (watchlistItems: WatchlistItem[]) => {
    var priceUrls: string[] = [];
    var today = dateFormat(new Date(), 'dd-mm-yyyy');

    var urlStart = stockUrlStart + today + '&bse_code=';

    var url = urlStart;

    var count = 0;
    watchlistItems.forEach((item) => {
        count += 1;
        url += item.MProfitCode + ',';
        if (count == 50) {
            count = 0;
            url = url.slice(0, -1);
            priceUrls.push(url);
            url = urlStart;
        }
    });

    if (url[url.length - 1] == ',') {
        url = url.slice(0, -1);
    }
    if (count > 0) {
        priceUrls.push(url);
    }

    return priceUrls;
}

// #endregion

//#region Selector helpers

export const getHoldingTableData = (rows?: {AllIDs: (string | number)[], ByID: {[ID: string | number]: TotalFields}}) => {
    if (rows == null) return [];

    var totalValue = rows.AllIDs.reduce((accumulator, ID) => {
        var currValueOfActiveItem = Big(rows.ByID[ID]['CurrValue'] || 0);
        return accumulator.plus(currValueOfActiveItem.gte(0) ? currValueOfActiveItem : 0);
    }, Big(0));

    var table: TotalFields[] = [];

    rows.AllIDs.forEach(x => {
        var item = rows.ByID[x];
        if (item) {
            var isNegative = Big(item.CurrValue || 0).lt(0);
            table.push({
                ...item,
                HoldingPctValue: totalValue.gt(0) && !isNegative ? Big(item.CurrValue || 0).div(totalValue).toNumber() : 0,
                HoldingPct: totalValue.gt(0) && !isNegative ? Big(item.CurrValue || 0).div(totalValue).toJSON() : undefined
            });
        }
    })

    return table.sort((x, y) => x.HoldingPctValue != undefined && y.HoldingPctValue != undefined ? (x.HoldingPctValue < y.HoldingPctValue ? 1 : -1) : 0);
}

export const getTodayTableData = (rows?: {AllIDs: (string | number)[], ByID: {[ID: string | number]: TotalFields}}) => {
    if (rows == null) return [];
    
    var table: TotalFields[] = [];

    rows.AllIDs.forEach(x => {
        var item = rows.ByID[x];
        if (item) {
            table.push(item);
        }
    })

    return table.sort((x,y) => {return Big(x.CurrValue || 0).gt(y.CurrValue || 0) ? -1 : 1});
}

export const getHoldingTableDataFromList = (rows?: TotalFields[], showPercentOfPortfolio: boolean = false, portfolioCurrentValue?: string) => {
    if (rows == null) return [];

    var totalValue = rows.reduce((accumulator, row) => {
        var currValueOfActiveItem = Big(row['CurrValue'] || 0);
        return accumulator.plus(currValueOfActiveItem.gte(0) ? currValueOfActiveItem : 0);
    }, Big(0));

    var table: TotalFields[] = [];

    rows.forEach(item => {
        if (item) {
            var isNegative = Big(item.CurrValue || 0).lt(0);
            var holdingItem: HoldingTableFields = {
                ...item,
                HoldingPctValue: totalValue.gt(0) && !isNegative ? Big(item.CurrValue || 0).div(totalValue).toNumber() : 0,
                HoldingPct: totalValue.gt(0) && !isNegative ? Big(item.CurrValue || 0).div(totalValue).toJSON() : undefined
            };

            try {
                if (showPercentOfPortfolio && portfolioCurrentValue) {
                    var portfolioCurrentValueBig = Big(portfolioCurrentValue);
                    if (portfolioCurrentValueBig.gt(0) && !isNegative) {
                        var holdingBig = Big(item.CurrValue || 0).div(portfolioCurrentValueBig);
                        holdingItem.PctOfTotalValue = holdingBig.toNumber();
                        holdingItem.PctOfTotal = holdingBig.toJSON();
                    } else {
                        holdingItem.PctOfTotalValue = 0;
                        holdingItem.PctOfTotal = undefined;
                    }
                }
            } catch {

            }

            table.push(holdingItem);
        }
    })

    return table;
}

export const getPieChartDataFromList = (totalField: 'CurrValue' | 'InvAmt', getFieldNameFromItem: (item: any) => string, getFieldIDsFromItem: (item: any) => {[IDName: string]: number}, rows?: TotalFields[], maxItemCount?: number) => {
    if (rows == undefined) return { PieChartData: [], TotalValue: "0" };

    var totalValue = rows.reduce((accumulator, row) => {
            return accumulator.plus(row[totalField] || 0);
        }, Big(0));

    if (totalValue.lte(0)) return { PieChartData: [], TotalValue: "0" };

    var PieChartData: PieChartData[] = rows.map<PieChartData>((item) => {
        var itemValue = Big(item[totalField] || 0);
        var percentValue = itemValue.div(totalValue);
        return {
            name: getFieldNameFromItem(item),
            IDs: getFieldIDsFromItem(item),
            value: percentValue.toNumber(),
            numValueBig: itemValue,
            pctValueBig: percentValue
        }
    })
    .filter(x => x.numValueBig.gt(0))
    .sort((x, y) => x.numValueBig.lt(y.numValueBig) ? 1 : -1)
    .map((x, index) => ({...x, color: getPieChartColorByIndex(index)}));

    if (maxItemCount != null) {
        PieChartData = getPieChartDataForTopNElements(PieChartData, totalValue, maxItemCount);
    }

    return { PieChartData, TotalValue: totalValue.toJSON() };
}

export const getPieChartData = (totalField: 'CurrValue' | 'InvAmt', getFieldNameFromItem: (item: any) => string, getFieldIDsFromItem: (item: any) => {[IDName: string]: number}, rowsByID?: {AllIDs: (string | number)[], ByID: {[ID: string | number]: TotalFields}}, maxItemCount?: number) => {
    var rows: TotalFields[] | undefined;
    if (rowsByID != null) {
        rows = rowsByID.AllIDs.map(ID => rowsByID.ByID[ID]);
    }
    
    return getPieChartDataFromList(totalField, getFieldNameFromItem, getFieldIDsFromItem, rows, maxItemCount);
}

//#endregion

//#region Get IDs and Names from Assets Add Info

export const getSectorIDFromAddInfo = (item: any) => item?.SectorID || 0;
export const getSectorNameFromAddInfo = (item: any) => item?.SectorName || 'ETF & Others';

export const getMarketCapIDFromAddInfo = (item: any) => item?.MarketCapClassification || 0;
export const getMarketCapNameFromAddInfo = (item: any) => getMarketCapNameFromID(item?.MarketCapClassification) || 'Other';

export const getMFSEBICategoryNameFromAddInfo = (item: any) => item?.SEBICategory || 'Others';
export const getMFSEBICategoryIDFromAddInfo = (item: any) => item?.SEBICategoryID || 19;

export const getMFSEBISubCategoryNameFromAddInfo = (item: any) => item?.SubCategoryName || 'Others';
export const getMFSEBISubCategoryIDFromAddInfo = (item: any) => getCategIDForMFSEBISubCategoryID(item?.SEBICategoryID, item?.SubCategoryID);

export const getCategIDFromAddInfoFunctionBasedOnMode = (mode: HoldingsMode) => {
    switch (mode) {
        case HoldingsMode.Sectors:
            return getSectorIDFromAddInfo;
        case HoldingsMode.MarketCap:
            return getMarketCapIDFromAddInfo;
        case HoldingsMode.MFSEBICategory:
            return getMFSEBICategoryIDFromAddInfo;
        case HoldingsMode.MFSEBISubCategory:
            return getMFSEBISubCategoryIDFromAddInfo;
    }

    return undefined;
}

export const getCategNameFromAddInfoFunctionBasedOnMode = (mode: HoldingsMode) => {
    switch (mode) {
        case HoldingsMode.Sectors:
            return getSectorNameFromAddInfo;
        case HoldingsMode.MarketCap:
            return getMarketCapNameFromAddInfo;
        case HoldingsMode.MFSEBICategory:
            return getMFSEBICategoryNameFromAddInfo;
        case HoldingsMode.MFSEBISubCategory:
            return getMFSEBISubCategoryNameFromAddInfo;
    }

    return undefined;
}

//#endregion

//#region Crate combined raw portfolio summary for groups

export const getCombinedPortfolioSummaryRawForGroup = (activePortfolio: SetActivePortfolioPayload, familyPortfolioSummaryRaw: FamilyPortfolioSummaryRaw, portfoliosInGroup: number[]) => {
    var combinedPortfolioSummaryRaw: PortfolioSummaryRaw = {
        FamilyId: activePortfolio.FamilyId,
        PortfolioId: activePortfolio.PFID,
        SumAssetTypes: [],
        PFolioType: PFolioTypes.PMS
    }

    var sumAssetTypesByID: {ByID: {[ID: number]: SumAssetTypeRaw}, AllIDs: number[]} = {
        ByID: {},
        AllIDs: []
    };
    
    portfoliosInGroup.forEach((PFID) => {
        var portfolioSummaryRaw = familyPortfolioSummaryRaw?.[PFID];
        if (portfolioSummaryRaw) {
            portfolioSummaryRaw.SumAssetTypes.forEach((assetTypeRaw) => {
                var assetTypeID: AssetTypeEnum = assetTypeRaw.AssetTypeID;
        
                if (sumAssetTypesByID.ByID[assetTypeID]) {
                    sumAssetTypesByID.ByID[assetTypeID].Assets = [
                        ...(sumAssetTypesByID.ByID[assetTypeID].Assets || []),
                        ...assetTypeRaw.Assets
                    ]
                } else {
                    sumAssetTypesByID.ByID[assetTypeID] = {...assetTypeRaw, AssetTypeID: assetTypeID};
                    sumAssetTypesByID.AllIDs.push(assetTypeID);
                }
            });
        }
    })

    combinedPortfolioSummaryRaw.SumAssetTypes = sumAssetTypesByID.AllIDs.map(ID => sumAssetTypesByID.ByID[ID]);

    return combinedPortfolioSummaryRaw;
}

//#endregion