import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import PerformanceLayout from "../layout";
import { Dispatch } from "react";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { useAppSelector } from "packages/web/src/redux/hooks";

interface L2_Performance_Props {
    MPrUrlParams: MPrUrlParams;
}

export default function L2_Performance({ MPrUrlParams }: L2_Performance_Props) {
    const assetTypeIDName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetTypeIDName);
    const isTradedBonds = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsActiveAssetTypeTradedBonds);

    return (
        <PerformanceLayout
            MPrUrlParams={MPrUrlParams}
            OnRefreshXIRRTotal={onRefreshXIRR}
            OnRefreshXIRRFYWise={onRefreshXIRRFYWise}
            OnRefreshXIRRYearWise={onRefreshXIRRYearWise}
            OnRefreshXIRRTable={onRefreshXIRRTable}
            OnRefreshXIRRBenchmark={onRefreshXIRRBenchmark}
            OnRefreshXIRRTablePortfolioWise={() => {}}
            GenericTitleText={`${(assetTypeIDName?.AssetTypeName || "Asset Class")} XIRR`}
            SpecificTitleText={assetTypeIDName?.AssetTypeName}
            RowFieldName={'Asset'}
            HideAdvancedChart={isTradedBonds}
            ShowPMSData={false}
        />
    );
}

const getXIRRPayload = (urlParams: MPrUrlParams, force?: boolean, noRefresh?: boolean): SharedRedux.Performance.RefreshXIRRPayload | undefined => {
    if (urlParams && urlParams.PortfolioID && urlParams.PortfolioID > 0 && urlParams.AssetType && urlParams.AssetType > 0) {
        return {
            CCID: urlParams.DatabaseID || -1, FamilyId: urlParams.FamilyID || -1, PFID: urlParams.PortfolioID || -1, ATTY: urlParams.AssetType,
            AssetAllocationCategID: urlParams.AssetAllocationCategID,
            Force: force === true, NoRefresh: noRefresh === true
        }
    }
}

const onRefreshXIRR = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRTotal(refreshXIRRPayload));
    }
}

const onRefreshXIRRFYWise = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRFYWise(refreshXIRRPayload));
    }
}

const onRefreshXIRRYearWise = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);
    console.log(force, refreshXIRRPayload, 'onRefreshXIRRYearWise')

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRYearWise(refreshXIRRPayload));
    }
}

const onRefreshXIRRTable = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRTable(refreshXIRRPayload));
    }
}

const onRefreshXIRRBenchmark = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRBenchmark(refreshXIRRPayload));
    }
}