import { Routes, Route } from "react-router-dom";
import routeNames from "./routeNames";
import Login from "../components/auth/login";
import Register from "../components/auth/register";
import Temp from "../components/temp";
import Cashflows from "../components/cashflows";
import { withLoggedIn } from './protectedRoutes';
import ErrorPage from '../components/ErrorPage';
import Today from '../components/portfolio/today';
import Holding from '../components/portfolio/holding';
import Performance from '../components/portfolio/performance';
import PortfolioLayout from '../components/portfolio/portfolioLayout';
import Reports from "../components/reports/explore-reports";
import SavedReports from "../components/reports/saved-reports";
import ReportLog from "../components/reports/report-log";
import AuthRedirectComponent from "../components/auth/redirect";

const Router = () => {
  const Layout = withLoggedIn(PortfolioLayout);
  const TodayPage = withLoggedIn(Today);
  const HoldingPage = withLoggedIn(Holding);
  const PerformancePage = withLoggedIn(Performance);
  const CashflowsPage = withLoggedIn(Cashflows);

  return (
    <Routes>
      <Route element={<Layout />} path={routeNames.PORTFOLIO}>
        <Route path={routeNames.TODAY} element={<TodayPage />} />
        <Route path={routeNames.HOLDING} element={<HoldingPage />} />
        <Route path={routeNames.PERFORMANCE} element={<PerformancePage />} />
        <Route path={routeNames.REPORTS} element={<Reports />} />
        <Route path={routeNames.SAVEDREPORTS} element={<SavedReports />} />
        <Route path={routeNames.REPORTLOG} element={<ReportLog />} />
        <Route path={routeNames.TEMP} element={<Temp />} />
        <Route path={routeNames.CASHFLOWS} element={<CashflowsPage />} />
      </Route>
      <Route path={routeNames.REGISTER} element={<Register />} />
      <Route path={routeNames.LOGIN} element={<Login />} />
      <Route path={routeNames.REDIRECT} element={<AuthRedirectComponent />} />
      <Route path={routeNames.ERROR} element={<ErrorPage />} />
    </Routes>
  )
}
export default Router;


