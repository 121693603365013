import { NumberFormatPrefs, SharedRedux } from '@mprofit/shared';
import { useAppSelector } from '../../../redux/hooks';

export const useShowInLakshMillions = (): boolean => {
  return useAppSelector(SharedRedux.Dashboard.Selectors.selectShowInLakhsMillions);
};

export const useNumberFormatPrefs = (): NumberFormatPrefs => {
  var userProfile = useAppSelector(SharedRedux.Dashboard.Selectors.selectUserProfile);
  return {
    ShowDecimals: userProfile.ShowDecimals,
    ShowInLakhsMillions: userProfile.ShowInLakhsMillions,
    Separator: userProfile.Separator
  }
};
