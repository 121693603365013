export function LeftNavIcon(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="34" height="33" viewBox="0 0 34 33">
            <g id="Group_153869" data-name="Group 153869" transform="translate(0 33.244) rotate(-90)">
                <rect id="Rectangle_24186" data-name="Rectangle 24186" width="33" height="34" transform="translate(0.244)" fill="#fff" opacity="0" />
                <path id="arrow-ios-downward-outline" d="M5.6,5.6a.8.8,0,0,1-.512-.184l-4.8-4A.8.8,0,0,1,1.312.185L5.6,3.767,9.884.313a.8.8,0,0,1,1.127.12A.8.8,0,0,1,10.9,1.6L6.1,5.462A.8.8,0,0,1,5.6,5.6Z" transform="translate(9.893 12.85)" fill="#fff" />
                <line id="Line_689" data-name="Line 689" y2="10" transform="translate(20.744 21.15) rotate(90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
            </g>
        </svg>
    );
}
