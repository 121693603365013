import { Typography, TypographyProps } from "@mui/material";

function CustomTypography(props: TypographyProps) {

  return (
    <Typography
      color={'#12131A'}
      {...props}
      variantMapping={{
        'Bold28': 'div',
        'Bold24': 'div',
        'Bold20': 'div',
        'Medium28': 'div',
        'Medium24': 'div',
        'Medium20': 'div',
        'Medium18': 'div',
        'Medium16': 'div',
        'Medium14': 'div',
        'Regular28': 'div',
        'Regular24': 'div',
        'Regular22': 'div',
        'Regular20': 'div',
        'Regular18': 'div',
        'Regular16': 'div',
      }}
    >
      {props.children}
    </Typography>
  )
}

export default CustomTypography