import { CompleteChartPage, PdfAdvancedPerformanceChart, PdfBarChart } from "@mprofit-soft/pdf";
import { DownloadPDF, ExtraHeaderProps, ExtraOverviewProps } from "../../../download-pdf";
import { ChartsSelectors } from "packages/web/src/redux/selectors/chartsSelectors";
import { usePDFSelector } from "../../../download-pdf/redux";
import { SharedConstants } from "@mprofit/shared";

const _AdvancedPerformanceChartPDFPage = ({ headerProps, overviewProps, chartMode, advancedChartTitleText}: {
        headerProps: ExtraHeaderProps;
        overviewProps: ExtraOverviewProps;
        chartMode: SharedConstants.AdvancedPerformanceChartMode;
        advancedChartTitleText: string;
    }) => {
    const {chartData, domain, isLoading, performanceSingleIndex } = usePDFSelector(ChartsSelectors.selectXIRRAdvancedChartData(chartMode));

    if (!chartData) {
        return <></>;
    }

    return <CompleteChartPage
        headerProps={{
            ...headerProps,
            reportType: "Performance",
        }}
        overviewProps={{
            ...overviewProps,
            heading: `Advanced Performance Insights - ${chartMode === SharedConstants.AdvancedPerformanceChartMode.YearWise ? "Historical" : "FY-Wise"}`,
            hidePeriod: true
        }}
        ChartComponent={(extraChartProps) => <PdfAdvancedPerformanceChart
                {...extraChartProps}
                chartData={chartData}
                legendData={{Column1Label: advancedChartTitleText, Column2Label: performanceSingleIndex?.AssetName?? ''}}
                axisDomain={domain}
            />
        }
    />
}

export const AdvancedPerformanceChartPDFPage = (chartMode: SharedConstants.AdvancedPerformanceChartMode, advancedChartTitleText: string) => ({ headerProps, overviewProps }: {
    headerProps: ExtraHeaderProps;
    overviewProps: ExtraOverviewProps;
}) => {
    return <_AdvancedPerformanceChartPDFPage {...{headerProps, overviewProps, chartMode, advancedChartTitleText}}/>;
}

interface XIRRBarChartPDFDownloadButtonProps {
    chartMode: SharedConstants.AdvancedPerformanceChartMode;
    advancedChartTitleText: string;
}

export const AdvancedPerformanceChartPDFDownloadButton = ({chartMode, advancedChartTitleText}: XIRRBarChartPDFDownloadButtonProps) => {
    return <DownloadPDF
        PDFPage={
            AdvancedPerformanceChartPDFPage(chartMode, advancedChartTitleText)
        }
        ReportNameForFileName={`Advanced Performance Insights - ${chartMode === SharedConstants.AdvancedPerformanceChartMode.YearWise ? "Historical" : "FY-Wise"}`}
        IsAdvancedPerformanceChart={true}
    />
}