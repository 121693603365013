
import { Box, Grid } from '@mui/material'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, RadialBarChart, PolarAngleAxis, RadialBar } from 'recharts'
import CustomTypography from '../customTypography';
import { formatPercent } from '@mprofit/shared';
import { Big } from 'big.js';

interface IDoughnutsProps {
    Name: string;
    PercentValue: Big;
    Colors: string[]
}

export default function DoughnutPieChart({ Name, PercentValue, Colors }: IDoughnutsProps) {


    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                minHeight: { xs: "180px", sm: "150px", lg: "232px" },
                height: '100%',
                position: 'relative',
            }}>
            <CustomTypography variant='Regular28' sx={{ position: 'absolute', top: '10%', left: '75%' }}>{formatPercent(PercentValue, 1, 'IN')}</CustomTypography>
            <ResponsiveContainer height='80%' width='100%'>
                <RadialBarChart
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={62}
                    outerRadius={62}
                    barSize={75}
                    data={[{ name: Name, value: PercentValue.toNumber() * 100 }]}
                    startAngle={90}
                    endAngle={-270}
                >
                    {/* <Legend layout="vertical" verticalAlign="bottom" align='center' wrapperStyle={{ bottom: '-16px' }} /> */}
                    <PolarAngleAxis
                        type="number"
                        domain={[0, 100]}
                        angleAxisId={0}
                        tick={false}
                    />
                    <RadialBar
                        dataKey="value"
                        cornerRadius={0}
                        background={{ fill: '#f5f5f6' }}
                        fill={`url(#stripe-${Colors[0]})`}
                    />
                    <defs>
                        <pattern id={`stripe-${Colors[0]}`} width="15" height="6" patternUnits="userSpaceOnUse" patternTransform="rotate(25)">
                            <rect x="0" y="0" width="10" height="6" fill={Colors[0]} />
                            <rect x="10" y="0" width="5" height="6" fill={Colors[1]} />
                        </pattern>

                    </defs>
                </RadialBarChart>
            </ResponsiveContainer>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px'
                }}
            >
                <Box
                    sx={{
                        width: '12px',
                        height: '12px',
                        borderRadius: '12px',
                        backgroundColor: Colors[0]
                    }}
                ></Box>
                <CustomTypography>{Name}</CustomTypography>
            </Box>
        </Grid>
    )
}
