
export function TaxSoftwareLargeIcon(props: any) {
 return (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="156.779" height="124.781" viewBox="0 0 156.779 124.781">
   <g id="man-creating-a-nft" transform="translate(0.145 0.145)" style={{ mixBlendMode: 'luminosity', isolation: 'isolate' }}>
    <path id="Path_207095" data-name="Path 207095" d="M214.9,155.739H77.992a9.775,9.775,0,0,1-9.792-9.792V66.392A9.775,9.775,0,0,1,77.992,56.6H214.9a9.775,9.775,0,0,1,9.792,9.792V145.86A9.85,9.85,0,0,1,214.9,155.739Z" transform="translate(-68.2 -56.6)" fill="#fff" stroke="#000" strokeWidth="0.289" />
    <path id="Path_207096" data-name="Path 207096" d="M68.2,151.9v5.945a9.775,9.775,0,0,0,9.792,9.791H214.9a9.775,9.775,0,0,0,9.792-9.791V151.9Z" transform="translate(-68.2 -68.585)" fill="#acc18a" stroke="#192752" strokeWidth="0.289" />
    <path id="Path_207097" data-name="Path 207097" d="M157.667,69.3,127,86.977v35.566l30.667,17.783,30.774-17.783V86.977Z" transform="translate(-79.48 -61.937)" fill="#485d3e" />
    <path id="Path_207098" data-name="Path 207098" d="M157.479,72.7,129.9,88.566v31.946l27.579,15.973,27.686-15.973V88.566Z" transform="translate(-79.293 -61.716)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.289" />
    <rect id="Rectangle_25223" data-name="Rectangle 25223" width="24.741" height="21.944" transform="translate(65.831 99.139)" fill="#acc18a" stroke="#000" strokeWidth="0.289" />
    <rect id="Rectangle_25224" data-name="Rectangle 25224" width="49.307" height="3.41" transform="translate(53.591 121.083)" fill="#acc18a" stroke="#000" strokeWidth="0.289" />
    <path id="Path_207100" data-name="Path 207100" d="M168.241,170H143.5v8.13Z" transform="translate(-77.669 -70.861)" fill="#090b00" />
    <path id="Path_207101" data-name="Path 207101" d="M435.741,1142.3s-.085,3.006,0,3.351,2.963.128,3.264.386-.3,11.252,0,11.6a6.075,6.075,0,0,0,3.393,0c.3-.3.043-11.725.343-11.854s2.706.257,3.093-.215.473-2.876,0-3.177S435.754,1141.822,435.741,1142.3Zm30.224,5.626s1.245,1.57,1.2,2.031-4.037,7.161-3.737,7.547,3.779.656,4.252.371,2.534-6.685,2.835-6.685,1.417,6.54,2.062,6.685,3.693.015,3.865-.371-3.049-7.149-3.006-7.547,2.448-6.24,2.534-6.884-3.951-.644-4.166-.258-1.6,4.724-1.873,4.767-3.281-4.951-3.668-5.031-3.178.007-3.393.65S465.965,1147.922,465.965,1147.922Zm-8.6-5.067c-.558-.644-4.381-.472-4.724,0s-3.135,15.016-3.135,15.016c.064.456,3.5.188,3.651,0s.322-3.571.516-3.743,1.932-.043,1.976.215.2,3.178.333,3.528,3.253.327,3.447,0S457.926,1143.5,457.368,1142.854Zm-3.7,8.021s.713-3.684,1.161-4.029.929,3.649.929,4.029-2.09,0-2.09,0Z" transform="translate(-377.841 -1107.203)" fill="#fff" />
   </g>
  </svg>
 );
}