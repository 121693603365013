import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { MultiSelectModal } from "../multi-select-modal";
import { memo } from "react";

interface DefaultIndicesModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    maxCount?: number;
}

export const DefaultIndicesModal = memo(({isModalOpen, setIsModalOpen, maxCount = 5}: DefaultIndicesModalProps ) => {
    const defaultIndicesList = useAppSelector(SharedRedux.Dashboard.Selectors.selectIndicesWatchlist);

    const dispatch = useAppDispatch();

    const onSave = (selectedIndices: {[MProfitCode: number]: boolean}) => {
      dispatch(SharedRedux.Dashboard.Actions.updateIndicesWatchlist({WatchlistType: SharedConstants.WatchlistTypeEnum.SystemIndices, Indices: selectedIndices}));
      setIsModalOpen(false);
    }

    const title = 'Track Indices';
    const subtitle = 'Select the indices you wish to track daily';

    const indicesMaster = useAppSelector(SharedRedux.Dashboard.Selectors.selectIndicesMaster);

    return <MultiSelectModal
      {...{ isModalOpen, setIsModalOpen, maxCount }}
      onSave={onSave}
      initialSelectedItems={defaultIndicesList?.Items}
      list={indicesMaster}
      idField={'MProfitCode'}
      textField={'IndexName'}
      title={title}
      subtitle={subtitle}
    />
});