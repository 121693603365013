export function RcaCorporateDefaultIcon(props: any) {
  return (
    <svg id="Group_149622" data-name="Group 149622" xmlns="http://www.w3.org/2000/svg" width="250.006" height="122.286" viewBox="0 0 250.006 122.286">
      <path id="Path_198095" data-name="Path 198095" d="M710.658,364.922H547.878c-4.771,0-8.638,4.129-8.642,9.218v28.139c0,5.089,3.871,9.213,8.642,9.218H710.658c4.771,0,8.638-4.129,8.642-9.218V374.14C719.3,369.051,715.429,364.927,710.658,364.922Z" transform="translate(-469.447 -298.35)" fill="#eaf4df" />
      <path id="Path_198108" data-name="Path 198108" d="M710.953,244.458H548.173c-4.771,0-8.638,4.129-8.642,9.218v28.139c0,5.089,3.871,9.213,8.642,9.218H710.953c4.771,0,8.638-4.129,8.642-9.218V253.676C719.591,248.587,715.724,244.463,710.953,244.458Zm7.732,37.357a8.014,8.014,0,0,1-7.732,8.248H548.173a8.014,8.014,0,0,1-7.732-8.248V253.676a8.014,8.014,0,0,1,7.732-8.248H710.953a8.014,8.014,0,0,1,7.733,8.248Z" transform="translate(-469.589 -244.458)" fill="#253326" />
      <path id="Path_198109" data-name="Path 198109" d="M606.159,289.654c-8.307,0-15.041-7.183-15.041-16.044s6.734-16.044,15.041-16.044S621.2,264.749,621.2,273.61,614.462,289.644,606.159,289.654Z" transform="translate(-494.449 -250.322)" fill="#5f854c" />
      <path id="Path_198110" data-name="Path 198110" d="M751.43,304.017H673.249a3.889,3.889,0,0,1,0-7.763H751.43a3.889,3.889,0,0,1,0,7.763Z" transform="translate(-532.276 -267.63)" fill="#d0e3bb" />
      <path id="Path_198111" data-name="Path 198111" d="M699.124,274.168H673.249a3.889,3.889,0,0,1,0-7.763h25.875a3.889,3.889,0,0,1,0,7.763Z" transform="translate(-532.276 -254.277)" fill="#d0e3bb" />
      <path id="Path_395" data-name="Path 395" d="M613.431,285.915a1.65,1.65,0,0,1-1.035-.367l-.019-.015-3.9-3.181a1.931,1.931,0,0,1-.321-2.591,1.661,1.661,0,0,1,2.429-.342l2.525,2.065,5.967-8.3a1.661,1.661,0,0,1,2.428-.342h0l-.037.055.038-.055a1.933,1.933,0,0,1,.32,2.59l-7.019,9.763a1.7,1.7,0,0,1-1.378.721Z" transform="translate(-502.489 -256.984)" fill="#fff" />
      <path id="Path_198112" data-name="Path 198112" d="M575.954,381.458H413.174c-4.771,0-8.638,4.129-8.642,9.218v28.139c0,5.089,3.871,9.213,8.642,9.218h162.78c4.771,0,8.638-4.129,8.642-9.218V390.676c0-5.089-3.871-9.213-8.642-9.218Z" transform="translate(-404.532 -305.747)" fill="#fff" />
      <path id="Path_198113" data-name="Path 198113" d="M575.954,381.458H413.174c-4.771,0-8.638,4.129-8.642,9.218v28.139c0,5.089,3.871,9.213,8.642,9.218h162.78c4.771,0,8.638-4.129,8.642-9.218V390.676c0-5.089-3.871-9.213-8.642-9.218Zm7.732,37.357a8.014,8.014,0,0,1-7.732,8.248H413.174a8.014,8.014,0,0,1-7.733-8.248V390.676a8.014,8.014,0,0,1,7.732-8.248h162.78a8.014,8.014,0,0,1,7.733,8.248Z" transform="translate(-404.532 -305.747)" fill="#253326" />
      <path id="Path_198114" data-name="Path 198114" d="M471.159,426.654c-8.307,0-15.041-7.183-15.041-16.044s6.734-16.044,15.041-16.044S486.2,401.749,486.2,410.61,479.462,426.644,471.159,426.654Z" transform="translate(-429.392 -311.611)" fill="#5f854c" />
      <path id="Path_198115" data-name="Path 198115" d="M616.43,441.017H538.249a3.889,3.889,0,0,1,0-7.763H616.43a3.889,3.889,0,0,1,0,7.763Z" transform="translate(-467.218 -328.919)" fill="#d0e3bb" />
      <path id="Path_198116" data-name="Path 198116" d="M564.124,411.168H538.249a3.889,3.889,0,0,1,0-7.763h25.875a3.889,3.889,0,0,1,0,7.763Z" transform="translate(-467.218 -315.566)" fill="#d0e3bb" />
      <path id="Path_395-2" data-name="Path 395" d="M478.431,422.915a1.651,1.651,0,0,1-1.035-.367l-.019-.015-3.9-3.181a1.931,1.931,0,0,1-.321-2.591,1.661,1.661,0,0,1,2.429-.342l2.525,2.065,5.967-8.3a1.661,1.661,0,0,1,2.428-.342h0l-.037.055.038-.055a1.933,1.933,0,0,1,.32,2.59l-7.019,9.763a1.7,1.7,0,0,1-1.378.721Z" transform="translate(-437.431 -318.273)" fill="#fff" />
    </svg>
  );
}