import { MPrUrlParams } from 'packages/web/src/classes/MPrUrlParams';
import { SharedConstants } from '@mprofit/shared';
import L1_Performance from './level_1';
import L2_Performance from './level_2';
import L4_Performance from './level_4';
import L1_AA_Performance from './level_1_aa';

const Performance = (props: { MPrUrlParams: MPrUrlParams }) => {
  const mprUrlParams = props.MPrUrlParams || new MPrUrlParams();

  console.log('Today mprUrlParams', mprUrlParams)

    switch(mprUrlParams.Screen) {
        default:
        case SharedConstants.ScreenEnum.L1:
            return <L1_Performance MPrUrlParams={mprUrlParams}/>;
        case SharedConstants.ScreenEnum.L1_AA:
            return <L1_AA_Performance MPrUrlParams={mprUrlParams}/>;
        case SharedConstants.ScreenEnum.L2:
            return <L2_Performance MPrUrlParams={mprUrlParams}/>;
        case SharedConstants.ScreenEnum.L3:
            return <div>Not Found</div>
        case SharedConstants.ScreenEnum.L4:
        case SharedConstants.ScreenEnum.L4_RefNo:
            return <L4_Performance MPrUrlParams={mprUrlParams}/>;
    }
}

export default Performance;