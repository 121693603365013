import { Box } from "@mui/material"
import CustomTypography from "../../../shared/customTypography"
import { ArrowNarrowUpIcon } from "packages/web/src/assets/icons/ArrowNarrowUpIcon"
import { RightGreenBubbleIcon } from "packages/web/src/assets/icons/rightGreenBubble"
import styles from "./index.module.css"
import { t } from "i18next"
import { DashboardItemContainer } from "../../shared"
import { formatDecimalWithRupeeSymbol } from "@mprofit/shared"
import dateFormat from "dateformat";
import { AmountWithTooltip } from "../../../shared/numberWithTooltip"

interface HoldingCurrentValueProps {
    CurrentValue?: string;
}

export const HoldingCurrentValue = ({ CurrentValue }: HoldingCurrentValueProps) => {
    return (
        <DashboardItemContainer>
            <Box className={styles.currentValueWrapper}>
                <Box className={styles.currentValue}>
                    <CustomTypography variant='Regular18' sx={{ color: "#64677A" }}>{t('portfolio.holding.currentValue')}</CustomTypography>
                    <CustomTypography variant='Medium28'>
                        <AmountWithTooltip Value={CurrentValue} />
                    </CustomTypography>
                </Box>
                <Box sx={{ position: 'absolute', zIndex: 0, top: '0', right: '0', width: { xs: '100px', lg1: '120px' } }}>
                    <RightGreenBubbleIcon width={"100%"} height={"auto"} />
                </Box>
                <CustomTypography variant='Regular16' sx={{ color: { xs: "#64677A" } }}>{`as on ${dateFormat(new Date(), 'd mmm yyyy')}`}</CustomTypography>
            </Box>
        </DashboardItemContainer>
    )
}