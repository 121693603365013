import { useEffect, useState } from 'react'

import styles from './index.module.css'

import watchListIcon from '../../../assets/icons/watchlistIcon.svg'

import { Box } from '@mui/system'
import { Grid, IconButton, TextField } from '@mui/material'
import ButtonText from '../button-text'
import { EditIcon } from '../../../assets/icons/editIcon'
import { MagnifyingIcon } from '../../../assets/icons/magnifyingIcon'
import ButtonPrimaryCTA from '../button-primary-cta'
import DraggableList from './DraggableList'
import { CloseIcon } from '../../../assets/icons/CloseIcon'
import { useAppSelector, useAppDispatch } from "../../../redux/hooks"
import { DropResult } from "@hello-pangea/dnd";
import CustomTypography from '../customTypography'
import { t } from 'i18next'
import { SharedConstants, SharedRedux } from '@mprofit/shared'
import Selection from '../selection'
import ClearIcon from '@mui/icons-material/Clear';

const WatchList = () => {
    const dispatch = useAppDispatch();
    const searchWatchList = useAppSelector(SharedRedux.Dashboard.Selectors.selectWatchListSearch);
    const watchlistWithPrices = useAppSelector(SharedRedux.Dashboard.Selectors.selectWatchListWithPrices);
    const [mode, setMode] = useState<'search' | 'cancel' | 'edit' | 'default'>("default")
    const [selectedValues, setSelectedValues] = useState<{ [MProfitCode: number]: boolean }>({})
    const [watchList, setWatchList] = useState(watchlistWithPrices || [])
    const [removeWatchList, setRemoveWatchList] = useState(watchlistWithPrices || [])
    const [removedItems, setItemsRemoved] = useState<Array<SharedConstants.WatchlistEditItem>>([])

    const handleButtonClickEvent = (newMode: 'search' | 'cancel' | 'edit' | 'default') => {
        if (newMode === 'default') {
            dispatch(SharedRedux.Dashboard.Actions.refreshWatchList());
            dispatch(SharedRedux.Dashboard.Actions.onCloseSearchWatchList());
        }
        setMode(newMode);
    }

    useEffect(() => {
        setWatchList(watchlistWithPrices || []);
        setRemoveWatchList(watchlistWithPrices || []);
        var newSelectedValues: { [MProfitCode: number]: boolean } = {};
        watchlistWithPrices?.forEach((item) => {
            newSelectedValues[item.MProfitCode] = true;
        });
        setSelectedValues(newSelectedValues);
    }, [watchlistWithPrices])

    const handleSelectedValue = (value: SharedConstants.WatchlistEditItem) => {
        var isSelectedPreviously = selectedValues[value.MProfitCode] === true;
        if (!isSelectedPreviously) {
            setSelectedValues({ ...selectedValues, [value.MProfitCode]: true });
            dispatch(SharedRedux.Dashboard.Actions.addItemToWatchList(value))
        } else {
            setSelectedValues({ ...selectedValues, [value.MProfitCode]: true });
            dispatch(SharedRedux.Dashboard.Actions.removeItemFromWatchList(value))
        }
    }

    const handleRemoveSelectedValue = (value: SharedConstants.WatchlistCardItem) => {
        setRemoveWatchList(removeWatchList?.filter((item: SharedConstants.WatchlistCardItem) => item !== value))
        setItemsRemoved([...removedItems, { MProfitCode: value.MProfitCode, IsPinned: false }])
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = Array.from(watchList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        var orderedWatchList: any = []
        items.forEach((i) => {
            orderedWatchList.push({ MProfitCode: i.MProfitCode, IsPinned: false })
        })
        setWatchList(items);
        dispatch(SharedRedux.Dashboard.Actions.updateWatchList(orderedWatchList))

    };

    return (
        <Grid sx={{ width: "100%", height: '100%', marginBottom: '15px', display: 'flex', flexDirection: 'column', minHeight: 0, flexGrow: 1 }}>
            {
                mode === "default" &&
                <>
                    <Box className={styles.watchListHeadingContainer} >
                        <Box>
                            <Box sx={{
                                display: "flex",
                                gap: "20px",
                                alignItems: 'center'
                            }}
                            >   <img src={watchListIcon} alt="castle"></img>
                                <CustomTypography m={0} variant='Medium24'>{t('portfolio.today.sharedComponents.watchlist.title')}</CustomTypography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <IconButton
                                sx={{ padding: 0 }}
                                onClick={() => handleButtonClickEvent("search")}
                                aria-label="search">
                                <MagnifyingIcon />
                            </IconButton>
                            <IconButton
                                sx={{ padding: 0 }}
                                onClick={() => handleButtonClickEvent("edit")}
                                aria-label="edit">
                                <EditIcon />
                            </IconButton>
                            {/* <ButtonText buttonText='See All' endIcon={<ChevronRightSharpIcon />} sx={{ color: '#5F6F57' }}  /> */}
                        </Box>
                    </Box>
                    {watchList.length > 0 ?
                        <DraggableList list={watchList} onDragEnd={onDragEnd} />
                        : null
                    }
                </>
            }
            {
                mode === "search" &&
                <>
                    <Box className={styles.watchListHeadingContainer} >
                        <TextField id="outlined-basic"
                            onChange={(e) => {
                                console.log('text changes in watchlist');
                                let text = e.target.value
                                if (text.length >= 3) {
                                    dispatch(SharedRedux.Dashboard.Actions.refreshSearchWatchlistListAPI(text))
                                }
                            }}
                            sx={{
                                width: "696px",
                                paddingTop: "24px",
                                "& .MuiInputBase-root": {
                                    height: 40
                                }
                            }}
                            placeholder='Please enter first 3 letters of the stock name'
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        disableRipple
                                        onClick={() => handleButtonClickEvent("default")}
                                        aria-label="close">
                                        <CloseIcon />
                                    </IconButton>)
                            }}
                        />
                    </Box>
                    <Box sx={{ overflow: 'scroll' }}>
                        {!searchWatchList.IsLoading ?
                            searchWatchList?.Items?.map((item, index: number) => (

                                <Box sx={style.tableContainer}
                                    onClick={() => handleSelectedValue({
                                        MProfitCode: item.id,
                                        IsPinned: false
                                    })}
                                >
                                    <Box sx={style.itemDetails}>
                                        <CustomTypography variant='Regular20'> {item.text}</CustomTypography>
                                        {/* <CustomTypography variant='Regular16' className={styles.assetSymbol}>{item.text}</CustomTypography> */}
                                    </Box>
                                    <Box>
                                        {/* <CustomTypography variant='Regular18' sx={{ paddingRight: '12px' }}>{decimal(1033.55, 2, 'IN')}</CustomTypography> */}
                                    </Box>

                                    {mode === "search" && <Box className={styles.btnContainerWatchList}>
                                        <Selection
                                            activeColor="#5F6F57"
                                            // handleClick={() => handleSelectedValue({
                                            //     MProfitCode: item.id,
                                            //     IsPinned: false
                                            // })}
                                            isSelected={selectedValues[item.id] === true}
                                        /></Box>
                                    }

                                </Box >
                            ))
                            : null
                        }
                    </Box>
                </>
            }
            {
                mode === "edit" &&
                <>
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "24px 24px 24px 33px", alignItems: 'center', }}>
                        <Box>
                            <Box sx={{
                                display: "flex",
                                gap: "20px",
                                alignItems: 'center'
                            }}>
                                <CustomTypography m={0} variant='Medium24'>{t('portfolio.today.sharedComponents.watchlist.editWatchlist')}</CustomTypography>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", gap: "20px", flexDirection: 'row', alignItems: "center", justifyContent: 'center' }}>
                            <ButtonText handleClick={() => {
                                handleButtonClickEvent("default")
                            }}
                                buttonText='Cancel' />
                            <ButtonPrimaryCTA buttonText='Save'
                                handleClick={() => {
                                    let updateWatchListDate: any = []
                                    removeWatchList.forEach((i) => {
                                        updateWatchListDate.push({ MProfitCode: i.MProfitCode, IsPinned: false })
                                    })
                                    dispatch(SharedRedux.Dashboard.Actions.updateWatchList(updateWatchListDate))
                                    handleButtonClickEvent("default")
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ overflow: 'scroll' }}>
                        {
                            removeWatchList && removeWatchList.map((item: any, index: number) => (

                                <Box sx={style.tableContainer}>
                                    <Box sx={style.itemDetails}>
                                        <CustomTypography variant='Regular20'> {item.AssetName}</CustomTypography>
                                        {/* <CustomTypography variant='Regular16' className={styles.assetSymbol}>{item.text}</CustomTypography> */}
                                    </Box>
                                    <Box>
                                        {/* <CustomTypography variant='Regular18' sx={{ paddingRight: '12px' }}>{decimal(1033.55, 2, 'IN')}</CustomTypography> */}
                                    </Box>

                                    {mode === "edit" && <Box className={styles.btnContainerWatchList}>
                                        <IconButton
                                            onClick={() => handleRemoveSelectedValue(item)}
                                            disableRipple
                                            sx={{
                                                color: "#C94C40",
                                                background: "#FFF5F5"
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Box>
                                    }

                                </Box >
                            ))

                        }
                    </Box>                </>
            }
        </Grid>
    )
}

export default WatchList

const style = {
    tableContainer: {
        padding: '17px 24px 18px 24px',
        borderTop: '1px solid #EBECF2',
        borderRadius: '4px',
        opacity: 1,
        display: 'flex',
        cursor: 'pointer'
    },
    infoTableContainer: {
        display: 'flex',
        width: '100%'
    },
    itemDetails: {
        flex: '1 1 100%',
        padding: '0px 10px'
    },


}