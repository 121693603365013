import { Modal, Box, styled, } from '@mui/material';

interface ModalProps {
  open: boolean,
  handleClose: () => void,
  children?: JSX.Element,
}

const CustomModal = ({ open, handleClose, children }: ModalProps) => {

  const StyledModal = styled(Modal)`
  .MuiBackdrop-root{
    opacity: 0.8 !important
  }
`;

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ outline: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        {children}
      </Box>
    </StyledModal>
  )
}

export default CustomModal;
