import { formatDecimalWithRupeeSymbol, formatPercent } from '@mprofit/shared'
import { Box, Grid } from '@mui/material'
import { RightGreenBubbleIcon } from 'packages/web/src/assets/icons/rightGreenBubble'
import { CustomTypography } from 'packages/web/src/components/shared'

export default function L4HoldingCurrentValuecard({ CurrValue, OGain, OGainPct, OUp }: { CurrValue: string | undefined, OGain: string | undefined, OGainPct: string | undefined, OUp: boolean | undefined }) {
    return (
        <Grid sx={{ padding: '0px 0px 24px 24px', display: 'flex', justifyContent: 'space-between', }}>
            <Grid sx={{ paddingTop: '32px', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flex: '1 1 100%' }}>
                    <CustomTypography variant='Regular20' sx={{ color: { xs: "#64677A" } }}>Current values</CustomTypography>
                    <CustomTypography variant="Regular28">{formatDecimalWithRupeeSymbol(CurrValue, 2, 'IN')}</CustomTypography>
                </Box>
                <Box sx={{ borderLeft: '2px solid #5F6F57', paddingLeft: '17px', marginTop: '15px' }}>
                    <CustomTypography sx={{ color: { xs: "#64677A" } }}>Unrealised gain</CustomTypography>
                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                        <CustomTypography variant='Medium20'>{formatDecimalWithRupeeSymbol(OGain, 2, 'IN')}</CustomTypography>
                        <CustomTypography variant='Medium16' sx={{ paddingLeft: '17px', color: OUp == true ? '#5F854C' : '#C94C40' }}>{formatPercent(OGainPct, 2, 'IN')}</CustomTypography>
                    </Box>
                </Box>
            </Grid>
            <Grid>
                <Box sx={{ width: { xs: '150px', lg: "153px", lg1: "150px", xl: "160px" } }}>
                    <RightGreenBubbleIcon width={"100%"} height={"auto"} />
                </Box>
            </Grid>
        </Grid>
    )
}
