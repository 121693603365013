export function SLV(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226567" data-name="Group 226567" transform="translate(-17890 6539.262)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 -0.262)" fill="#fff" opacity="0" />
          <g id="Group_222246" data-name="Group 222246" transform="translate(0 4.317)">
            <g id="Rectangle_24192" data-name="Rectangle 24192" transform="translate(0 0.42)" fill="none" stroke="#fff" stroke-width="1">
              <rect width="20" height="11" rx="4" stroke="none" />
              <rect x="0.5" y="0.5" width="19" height="10" rx="3.5" fill="none" />
            </g>
            <path id="Name" d="M2.541-3.191a2.861,2.861,0,0,1-1.151-.2,1.685,1.685,0,0,1-.7-.533,1.245,1.245,0,0,1-.255-.692A.151.151,0,0,1,.476-4.73a.151.151,0,0,1,.111-.044h.659a.219.219,0,0,1,.144.041.424.424,0,0,1,.085.1.749.749,0,0,0,.17.278.939.939,0,0,0,.344.226,1.474,1.474,0,0,0,.551.089,1.434,1.434,0,0,0,.8-.178A.553.553,0,0,0,3.6-4.7a.445.445,0,0,0-.141-.344,1.311,1.311,0,0,0-.433-.237,7.593,7.593,0,0,0-.773-.226,4.309,4.309,0,0,1-.929-.322,1.41,1.41,0,0,1-.563-.477A1.314,1.314,0,0,1,.573-7.04,1.3,1.3,0,0,1,.806-7.8a1.564,1.564,0,0,1,.666-.526A2.529,2.529,0,0,1,2.5-8.52a2.572,2.572,0,0,1,.851.13,1.878,1.878,0,0,1,.614.34,1.612,1.612,0,0,1,.374.452,1.062,1.062,0,0,1,.137.47.162.162,0,0,1-.041.107.142.142,0,0,1-.115.048H3.637A.263.263,0,0,1,3.519-7a.218.218,0,0,1-.1-.111.614.614,0,0,0-.289-.392A1.113,1.113,0,0,0,2.5-7.669a1.232,1.232,0,0,0-.644.148.5.5,0,0,0-.244.459.5.5,0,0,0,.122.344,1,1,0,0,0,.392.241,7.367,7.367,0,0,0,.714.222,5.185,5.185,0,0,1,1.029.326,1.346,1.346,0,0,1,.585.47,1.3,1.3,0,0,1,.185.722,1.332,1.332,0,0,1-.263.833,1.658,1.658,0,0,1-.736.529A3.02,3.02,0,0,1,2.541-3.191Zm3.131-.074a.171.171,0,0,1-.126-.052.171.171,0,0,1-.052-.126V-8.268a.171.171,0,0,1,.052-.126.171.171,0,0,1,.126-.052h.688a.171.171,0,0,1,.126.052.171.171,0,0,1,.052.126v4.123H8.885a.18.18,0,0,1,.133.052.18.18,0,0,1,.052.133v.518a.171.171,0,0,1-.052.126.18.18,0,0,1-.133.052Zm4.937,0a.269.269,0,0,1-.185-.059.336.336,0,0,1-.1-.148L8.744-8.224a.182.182,0,0,1-.015-.067A.151.151,0,0,1,8.774-8.4a.151.151,0,0,1,.111-.044h.651A.215.215,0,0,1,9.7-8.39a.259.259,0,0,1,.07.107l1.251,3.812,1.251-3.812a.259.259,0,0,1,.07-.107.215.215,0,0,1,.159-.056h.651a.155.155,0,0,1,.107.044.145.145,0,0,1,.048.111.182.182,0,0,1-.015.067L11.7-3.472a.336.336,0,0,1-.1.148.269.269,0,0,1-.185.059Z" transform="translate(3.269 11.603)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>


  );
}
