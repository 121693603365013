import { Fragment, ReactElement, useEffect, useState } from "react";
import { Document, DocumentProps, Font } from "@react-pdf/renderer";
import { IconButton } from "@mui/material";
import RubikFonts_ReactPDF from "../../assets/fonts/Rubik";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { useAppSelector } from "../../redux/hooks";
import { SharedAssetTypeNames, SharedConstants, SharedRedux, formatDate } from "@mprofit/shared";
import { DownloadPDFIcon } from "../../assets/icons/downloadPDFIcon";
import { useStore } from "react-redux";
import { PDFStoreProvider } from "./redux";

export interface ExtraHeaderProps {
  portfolioName: string;
  familyName: string;
  clientLogoURL?: string;
}

export interface ExtraOverviewProps {
  assetsToDisplay?: ATTYProps[];
  periodText?: string;
}

export type PDFPageValueType = React.FC<{ headerProps: ExtraHeaderProps, overviewProps: ExtraOverviewProps }> | undefined;

interface DownloadPDFProps {
  PDFPage?: PDFPageValueType;
  PDFPages?: ({PDFPage: PDFPageValueType, IsAdvancedPerformanceChart?: boolean})[];
  ReportNameForFileName: string;
  IsAdvancedPerformanceChart?: boolean;
  IsWhiteBackgroundButton?: boolean;
  IsHideIcon?: boolean;
  IsDownloadOnMount?: boolean;
  OnDownloadComplete?: () => void;
}

Font.register({
  family: "Rubik",
  fonts: RubikFonts_ReactPDF,
});

export const DownloadPDF = ({IsDownloadOnMount, PDFPage, PDFPages, ReportNameForFileName, IsAdvancedPerformanceChart, IsWhiteBackgroundButton, IsHideIcon, OnDownloadComplete}: DownloadPDFProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const activePortfolio = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolio);
  var portfolioName = (activePortfolio?.PName || activePortfolio?.PFullName) || '';
  var family = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveFamily);
  var familyName = family && family.FamilyName ? (family.FamilyName.trim().toUpperCase().endsWith('FAMILY') ? family.FamilyName : `${family.FamilyName} Family`) : '';
  var clientLogoURL: string | undefined = undefined;

  const periodText = useAppSelector(SharedRedux.Performance.Selectors.selectActivePeriodText);

  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  const portfolioATTY = useAppSelector(SharedRedux.Performance.Selectors.selectPortfolioATTY);
  const assetTypeIDName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetTypeIDName);
  const assetAllocationCategID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategID);
  const assetAllocationCategName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategName);
  const categDetails = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3CategDetails);
  const assetName = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4AssetName);
  const isCashMgmtPortfolio = useAppSelector(SharedRedux.Licensing.Selectors.selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck);
  const isPerformancePeriodAllToDate = useAppSelector(SharedRedux.Performance.Selectors.selectIsPeriodAllToDate);

  const fileName = getFileName(activeScreenIDs, ReportNameForFileName, assetName, assetTypeIDName.AssetTypeName, portfolioName, family?.FamilyName, assetAllocationCategName, categDetails?.CategName);

  const store = useStore();

  const handleDownload = async () => {
    setIsLoading(true);


    let blob;

    if (PDFPages && PDFPages?.length > 0) {
      blob = await pdf(
        <PDFStoreProvider store={store}>
          <Document>
            {PDFPages.map((item, index) => {
              var ActivePDFPage = item.PDFPage;
              var assetsToDisplay = getPortfolioATTYListForPDF(activeScreenIDs, item.IsAdvancedPerformanceChart === true ? [SharedConstants.AssetTypeEnum.EQ, SharedConstants.AssetTypeEnum.MFEQ] : (isCashMgmtPortfolio || isPerformancePeriodAllToDate ? undefined : portfolioATTY), assetName, assetAllocationCategID, assetAllocationCategName, categDetails?.CategName);
              if (ActivePDFPage) {
                return <Fragment key={index}>{<ActivePDFPage headerProps={{ portfolioName, familyName, clientLogoURL }} overviewProps={{ periodText, assetsToDisplay }} />}</Fragment>
              }
              return <Fragment></Fragment>;
            })}
          </Document>
        </PDFStoreProvider>
      ).toBlob();

      saveAs(blob, fileName);
    } else if (PDFPage) {
      var assetsToDisplay = getPortfolioATTYListForPDF(activeScreenIDs, IsAdvancedPerformanceChart === true ? [SharedConstants.AssetTypeEnum.EQ, SharedConstants.AssetTypeEnum.MFEQ] : (isCashMgmtPortfolio || isPerformancePeriodAllToDate ? undefined : portfolioATTY), assetName, assetAllocationCategID, assetAllocationCategName, categDetails?.CategName);

      blob = await pdf(
        <PDFStoreProvider store={store}>
          <Document><PDFPage headerProps={{portfolioName, familyName, clientLogoURL}} overviewProps={{periodText, assetsToDisplay}}/></Document>
        </PDFStoreProvider>
      ).toBlob();

      saveAs(blob, fileName);
    }
    setIsLoading(false);

    if (OnDownloadComplete) {
      OnDownloadComplete();
    }
  }

  useEffect(() => {
    if (IsDownloadOnMount) {
      handleDownload();
    }
  }, [])

  const isLegacyProduct = useAppSelector(SharedRedux.Licensing.Selectors.selectIsLicenseLegacyProduct);

  if (isLegacyProduct || IsHideIcon) {
    return <></>;
  } else {
    return <IconButton
      disableRipple
      disabled={isLoading}
      onClick={handleDownload}
      sx={IsWhiteBackgroundButton ? {
        border: "1px solid #D8DAE5",
        backgroundColor: "white",
        borderRadius: "4px",
        "&:hover": { backgroundColor: "#EFF0F5" },
        padding: '4px'
      } : {
        padding: 0
      }}
    >
      <DownloadPDFIcon />
    </IconButton>
  }
}

const getFileName = (activeScreenIDs: SharedRedux.Portfolios.ActiveScreenPayload | undefined, reportNameForFileName: string | undefined, assetName: string | undefined, assetTypeName: string | undefined, portfolioName: string | undefined, familyName: string | undefined, assetAllocationCategName: string | undefined, categName: string | undefined) => {
  if (!activeScreenIDs || !activeScreenIDs.Screen || !reportNameForFileName) return 'MProfit Report.pdf';

  var date = formatDate('ddmmmyy', new Date())?.toUpperCase();

  switch (activeScreenIDs.Screen) {
    case SharedConstants.ScreenEnum.L1:
      return `${reportNameForFileName}_${portfolioName}_${familyName}_${date}.pdf`;
    case SharedConstants.ScreenEnum.L1_AA:
      return `${reportNameForFileName}_${assetAllocationCategName}_${familyName}_${date}.pdf`;
    case SharedConstants.ScreenEnum.L2:
      return `${reportNameForFileName}_${assetTypeName}_${portfolioName}_${familyName}_${date}.pdf`;
    case SharedConstants.ScreenEnum.L2_AllStocks:
      return `${reportNameForFileName}_${portfolioName}_${familyName}_${date}.pdf`;
    case SharedConstants.ScreenEnum.L3:
    case SharedConstants.ScreenEnum.L3_Category:
      return `${reportNameForFileName}_${assetTypeName}_${categName}_${portfolioName}_${familyName}_${date}.pdf`;
    case SharedConstants.ScreenEnum.L4:
      return `${reportNameForFileName}_${assetTypeName}_${assetName}_${portfolioName}_${familyName}_${date}.pdf`;
  }
}

interface ATTYProps {
  ATTY: number;
  Name: string;
}

const getPortfolioATTYListForPDF =
  (activeScreenIDs: SharedRedux.Portfolios.ActiveScreenPayload | undefined, portfolioATTY: SharedConstants.AssetTypeEnum[] | undefined, AssetName: string | undefined, AssetAllocationCategID: number | undefined, AssetAllocationCategName: string | undefined, CategName: string | undefined): ATTYProps[] | undefined => {
    if (!activeScreenIDs || !activeScreenIDs.Screen) return undefined;

    var ATTY: SharedConstants.AssetTypeEnum | undefined = activeScreenIDs.AssetType;

    switch (activeScreenIDs.Screen) {
      case SharedConstants.ScreenEnum.L1:
      case SharedConstants.ScreenEnum.L1_AA:
        if (portfolioATTY != undefined && portfolioATTY.length > 0) {
          return portfolioATTY.map((item) => ({ATTY: item, Name: SharedAssetTypeNames.getAssetTypeNameForAA(item, AssetAllocationCategID)}));
        } else {
          return [{ATTY: -1, Name: AssetAllocationCategID && AssetAllocationCategID > 0 && AssetAllocationCategName ? AssetAllocationCategName : 'Overall Portfolio'}]
        }
      case SharedConstants.ScreenEnum.L2:
        if (!ATTY) return undefined;
        return [{ATTY: ATTY, Name: SharedAssetTypeNames.getAssetTypeNameForAA(ATTY, AssetAllocationCategID)}];
      case SharedConstants.ScreenEnum.L3:
      case SharedConstants.ScreenEnum.L3_Category:
        if (!ATTY || !CategName) return undefined;
        return [{ATTY: ATTY, Name: `${SharedConstants.AssetTypeNameMapping[ATTY]} - ${CategName}`}];
      case SharedConstants.ScreenEnum.L4:
        if (!ATTY) return undefined;
        return AssetName ? [{ATTY: ATTY, Name: AssetName}] : undefined;
    }
}