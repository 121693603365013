import { Box, Button, Typography, styled } from '@mui/material'
import Lottie from 'react-lottie'
import * as AllDoneAnimation from '../../../../assets/animation/allDone.json'
import allDoneBackground from '../../../../assets/background/alldonebackground.png'
import CustomTypography from '../../../shared/customTypography'
import { useAppDispatch } from 'packages/web/src/redux/hooks'
import { WebRedux } from '@mprofit/web'

export default function AllDone() {
    const dispatch = useAppDispatch();
    return (
        <Wrapper>
            <MainContent>
                <AnimationConainer>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: AllDoneAnimation,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        height={300}
                        width={300}
                    />
                </AnimationConainer>
                <Typography sx={{ fontSize: '36px', fontWeight: '500', lineHeight: '48px', paddingBottom: '16px' }}>All done!</Typography>
                <CustomTypography variant="Regular18">Your account password has been successfully reset. You can now use your new password to login.</CustomTypography>
                <LoginButton onClick={() => dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: 1 }))}>Continue to login</LoginButton>
            </MainContent>
        </Wrapper>
    )
}

const Wrapper = styled(Box)({
    boxSizing: 'border-box',
    maxHeight: '73%',
    minHeight: '550px',
    height: '65%',
    minWidth: '550px',
    width: '53%',
    marginLeft: 'auto',
    background: '#FFFFFF',
    borderRadius: '8px',
    margin: 'auto',
    padding: '0px 56px'
});

const MainContent = styled(Box)({
    textAlign: 'center'
});

const AnimationConainer = styled(Box)({
    width: '300px',
    height: '300px',
    backgroundImage: `url(${allDoneBackground})`,
    // backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%'
});

const LoginButton = styled(Button)({
    marginTop: '64px',
    padding: '15px 32px 14px 32px',
    background: '#010006',
    textTransform: 'none',
    color: '#FFFFFF',
    width: '100%',
    height: '48px',
    ':hover': {
        background: '#252833'
    },
    ':disabled': {
        background: '#EBECF2',
        color: '#BABDCC'
    }
});
