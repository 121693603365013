import { Box, Modal } from '@mui/material'
import { CloseIcon } from 'packages/web/src/assets/icons/CloseIcon'
import React, { useState } from 'react'
import CustomTypography from '../customTypography'
import ButtonPrimaryCTA from '../button-primary-cta'
import { RighSideTourModal } from 'packages/web/src/assets/icons/RighSideTourModal'

interface IAppTourModal {
    onStartClick: () => void;
    onClose: () => void;
}

const AppTourModal = ({ onStartClick, onClose }: IAppTourModal) => {
    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                '& .MuiBox-root': {
                    outline: 'none'
                }
            }}
        >
            <Box sx={{
                backgroundColor: 'white',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '65%',
                height: '65%',
                boxShadow: 24,
                padding: '2% 4% 4% 4%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box sx={{ height: '10%', display: 'flex', justifyContent: 'end', marginRight: '-2%', cursor: 'pointer' }} onClick={onClose}>
                    <CloseIcon sx={{ color: '#707070', marginTop: '-30px' }} />
                </Box>
                <Box sx={{ display: 'flex', flex: 1, overflowY: 'auto', gap: '50px' }}>
                    <Box sx={{ flex: {xs: '70%', xl: '60%'}, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <CustomTypography variant='Medium24' sx={{ marginBottom: '3%' }}>We've built a fresh interface with new analytics features to help you understand your investments better.</CustomTypography>
                        <CustomTypography variant='Regular20' sx={{ marginBottom: '3%' }}>Explore the Today, Holdings and Performance views for different insights in each portfolio.</CustomTypography>
                        <CustomTypography variant='Regular20' sx={{ marginBottom: '3%' }}>Discover new features such as period-wise XIRR, benchmark comparisons, sector-wise insights & more!</CustomTypography>
                        <CustomTypography variant='Regular20' sx={{ marginBottom: '3%' }}>Let us walk you through a quick tour!</CustomTypography>
                        <Box sx={{ paddingTop: '1%', display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
                            <ButtonPrimaryCTA buttonText='Start Tour'
                                handleClick={() => {
                                    onStartClick()
                                }}
                            />
                            <Box onClick={onClose}>
                                <CustomTypography variant="Medium18" sx={{cursor: 'pointer', color: '#5F854C', '&:hover': {textDecoration: 'underline'}}}>or skip for now</CustomTypography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ flex: {xs: '30%', xl: '50%'}, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <RighSideTourModal />
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default AppTourModal