import { useEffect, useState } from 'react'
import { Box, Divider, Grid, styled } from '@mui/material';
import CustomTypography from '../../customTypography';
import { LeftArrowOutlinedIcon } from 'packages/web/src/assets/icons/leftArrowOutlinedIcon';
import { SharedUtilities } from '@mprofit/shared';

interface IMonthsProps {
    startMonthNumber?: number;
    startYearNumber?: number;
    setSelectedMonth?: ({startDate, endDate, title} : SetMonthProps) => void;
    isOnlyMonthSelection?: boolean;
    setMonthSelectClosed?: () => void
}

export interface SetMonthProps {
    startDate: Date;
    endDate: Date;
    title: string;
    monthNumber: number;
    year: number;
    monthName: string;
}

export default function Month({ setSelectedMonth = () => {}, isOnlyMonthSelection = false, startMonthNumber, startYearNumber, setMonthSelectClosed }: IMonthsProps) {
    const now = new Date();

    const currentYearNumber = now.getFullYear();
    const currentMonth = now.getMonth() + 1;

    const [selectedYearNumber, setSelectedYearNumber] = useState<number>(startYearNumber || currentYearNumber);
    const [selectedMonthNumber, setSelectedMonthNumber] = useState<number>(startMonthNumber || currentMonth);

    const onSelectMonthNumber = (monthNumber: number) => {
        setSelectedMonthNumber(monthNumber);

        onMonthChange(monthNumber, selectedYearNumber);
    }

    const onSelectYearNumber = (yearNumber: number) => {
        setSelectedYearNumber(yearNumber);

        onMonthChange(selectedMonthNumber, yearNumber);
    }

    const onMonthChange = (monthNumber: number, yearNumber: number, isMount: boolean = false) => {
        var selectedMonth = SharedUtilities.getMonthSelectedItem(monthNumber, yearNumber);

        if (selectedMonth && setSelectedMonth) {
            setSelectedMonth(selectedMonth);

            if (!isMount && setMonthSelectClosed) {
                setMonthSelectClosed();
            }
        }
    }

    useEffect(() => {
        onMonthChange(selectedMonthNumber, selectedYearNumber, true);
    }, []);

    return <ControlledMonth
                selectedMonthNumber={selectedMonthNumber}
                selectedYearNumber={selectedYearNumber}
                onSelectMonthNumber={onSelectMonthNumber}
                onSelectYearNumber={onSelectYearNumber}
                onMonthChange={onMonthChange}
                isOnlyMonthSelection={isOnlyMonthSelection}
            />
}

interface ControlledMonthProps {
    selectedMonthNumber: number;
    selectedYearNumber: number;
    onSelectMonthNumber: (monthNumber: number) => void;
    onSelectYearNumber?: (yearNumber: number) => void;
    isOnlyMonthSelection: boolean;
    onMonthChange: (monthNumber: number, yearNumber: number, isMount?: boolean) => void;
}

export const ControlledMonth = ({selectedMonthNumber, selectedYearNumber, onSelectMonthNumber, onSelectYearNumber, isOnlyMonthSelection, onMonthChange}: ControlledMonthProps) => {
    const now = new Date();

    const currentYearNumber = now.getFullYear();

    const incrementDecrementYear = (isIncrement: boolean) => {
        if (!onSelectYearNumber) return;

        if (isIncrement === true && selectedYearNumber < currentYearNumber) {
            onSelectYearNumber(selectedYearNumber + 1)
        }
        else if (isIncrement === false) {
            onSelectYearNumber(selectedYearNumber - 1)
        }
    }

    return (
        <Grid container spacing={2}>
            {
                isOnlyMonthSelection === false &&
                <Grid item xs={12}>
                    <YearContainer>
                        <CustomTypography variant="Regular20" sx={{ color: { xs: '#64677A' } }}>{selectedYearNumber}</CustomTypography>
                        <IconCont>
                            <Box>
                                <LeftArrowOutlinedIcon size="32px" onClick={() => incrementDecrementYear(false)} style={{ cursor: 'pointer' }} />
                            </Box>
                            <Box sx={{ marginLeft: '8px' }} onClick={() => incrementDecrementYear(true)} >
                                <LeftArrowOutlinedIcon
                                    style={{ transform: 'rotate(180deg)', cursor: currentYearNumber === selectedYearNumber ? 'default' : 'pointer' }}
                                    disabled={currentYearNumber === selectedYearNumber}
                                    size="32px"
                                />
                            </Box>
                        </IconCont>
                    </YearContainer>
                    <Divider sx={{ width: '100%', margin: '10px 0px 10px 16px' }} />
                </Grid>
            }
            {SharedUtilities.monthsListForPeriodSelection.map((month, index) => {
                return (
                    <Grid item xs={4} key={month.shortName} >
                        <MonthContainer sx={
                            selectedMonthNumber !== month.monthNumber ? {
                                backgroundColor: "#F9F9F9",
                                cursor: 'pointer',
                                ":hover": {
                                    background: '#EAF4DF'
                                }
                            } : {
                                backgroundColor: "#5F6F57",
                                cursor: 'pointer'
                            }
                        } onClick={() => onSelectMonthNumber(month.monthNumber)}>
                            <CustomTypography variant="Regular20" sx={{ color: { xs: selectedMonthNumber === month.monthNumber ? "#FFFFFF" : "#000000" } }}>{month.shortName}</CustomTypography>
                        </MonthContainer>
                    </Grid>
                )
            })
            }
        </Grid >
    )
}

const MonthContainer = styled(Grid)({
    background: '#F9F9F9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0px',
    borderRadius: '4px',
    cursor: 'pointer'
});

const YearContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 16px'
});

const IconCont = styled(Box)({
    display: 'flex'
})