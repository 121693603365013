export function BarGraphIcon(props: any) {
 return (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 42 42">
   <g id="Group_149319" data-name="Group 149319" transform="translate(0)">
    <rect id="Rectangle_23692" data-name="Rectangle 23692" width="42" height="42" rx="8" transform="translate(0)" fill="#f5f5f6" />
    <g id="Layer_2" data-name="Layer 2" transform="translate(8.012 7.15)">
     <rect id="Rectangle_24232" data-name="Rectangle 24232" width="4.898" height="9.419" rx="1.83" transform="translate(3.421 13.756)" />
     <rect id="Rectangle_24233" data-name="Rectangle 24233" width="4.898" height="12.056" rx="1.83" transform="translate(10.196 11.118)" />
     <rect id="Rectangle_24234" data-name="Rectangle 24234" width="4.898" height="14.377" rx="1.83" transform="translate(16.97 8.797)" />
     <path id="Path_198518" data-name="Path 198518" d="M23.1,5.173,22,1.85l-4.046.686a.754.754,0,0,0-.671.882.844.844,0,0,0,.995.6l1.469-.249C18.243,5.369,13.511,8.609,2.713,10.35A.754.754,0,0,0,2,11.216a.851.851,0,0,0,.995.618c11.062-1.786,16.276-5.147,18.13-7.226l.309.972a.867.867,0,0,0,1.047.52.754.754,0,0,0,.618-.927Z" transform="translate(0)" />
    </g>
   </g>
  </svg>
 );
}
