import { Box, Divider, styled } from '@mui/material'
import CustomTypography from '../../../shared/customTypography'
import { FormatPercentUsingPrefs, SharedConstants } from '@mprofit/shared'
import { GreenArrowUp } from 'packages/web/src/assets/icons/GreeArrowUp'
import { ReactComponent as RedArrowDown } from '../../../../assets/icons/redArrowDown.svg';
import { DashboardItemContainer } from '../../shared/dashboardItemContainer';
import { useNumberFormatPrefs } from '../../../hooks/userPreferencesHooks';
import { GainWithTooltip } from '../../../shared/numberWithTooltip';
import { NumberPercent } from '../../../shared/numberPercent';

export default function TodayGainLoss({ TotalValues }: { TotalValues?: SharedConstants.TotalFields }) {
    const numberFormatPrefs = useNumberFormatPrefs();
    return (
        <DashboardItemContainer className='apptour-today-currentgain'>
            <Container sx={{ padding: { xs: '26px', lg: '32px 32px 26px 32px', boxSizing: 'border-box' } }}>
                <TopCard>
                    <CustomTypography variant='Regular18'>Today's gain</CustomTypography>
                    <ValueContainer>
                        <CustomTypography variant='Regular22'>
                            <GainWithTooltip Value={TotalValues?.TGain}/>
                        </CustomTypography>
                        {
                            TotalValues &&
                            <GainLossCont sx={{ backgroundColor: TotalValues?.TUp ? '#EAF4DF' : '#FFF5F5' }}>
                                {
                                    TotalValues?.TUp ? <GreenArrowUp /> : <RedArrowDown />
                                }
                                <CustomTypography variant='Medium16' sx={{ ml: '8px' }} color={TotalValues?.TUp ? '#5F854C' : '#C94C40'}>
                                    <NumberPercent Value={TotalValues?.TGainPct}/>
                                </CustomTypography>
                            </GainLossCont>
                        }
                    </ValueContainer>
                </TopCard>
                <Box >
                    <Divider sx={{ width: '100%', margin: '20px 0px', }} />
                </Box>

                <BottomCard>
                    <CustomTypography variant='Regular18'>Unrealised gain</CustomTypography>
                    <ValueContainer>
                        <CustomTypography variant='Regular22'>
                            <GainWithTooltip Value={TotalValues?.OGain}/>
                            {/* {FormatGainUsingPrefs(numberFormatPrefs, TotalValues?.OGain, 2)} */}
                        </CustomTypography>
                        {
                            TotalValues &&
                            <GainLossCont sx={{ backgroundColor: TotalValues?.OUp ? '#EAF4DF' : '#FFF5F5' }}>
                                {
                                    TotalValues?.OUp ? <GreenArrowUp /> : <RedArrowDown />
                                }
                                <CustomTypography variant='Medium16' sx={{ ml: '8px', color: TotalValues?.OUp ? '#5F854C' : '#C94C40' }}>
                                    <NumberPercent Value={TotalValues?.OGainPct}/>
                                </CustomTypography>
                            </GainLossCont>
                        }
                    </ValueContainer>
                </BottomCard>
            </Container >
        </DashboardItemContainer>
    )
}

const Container = styled(Box)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
})
const ValueContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center'
})
const GainLossCont = styled(Box)({
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    borderRadius: '6px',
    padding: '6px 7px',
    marginLeft: '24px'
})
const TopCard = styled(Box)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
})

const BottomCard = styled(Box)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
})