import { Box, Card } from '@mui/material'
import React, { useState } from 'react'
import { ArrowRight } from '../../../../assets/icons/arrowRight'
import CustomTypography from '../../../shared/customTypography'

interface ICardProps {
    title: string,
    icon: JSX.Element,
    desc: string,
    bgColor?: string,
}
export default function PopularReportCard(props: ICardProps) {
    const [showArrow, setShowArrow] = useState(false)
    return (
        <Card sx={styles.cardReport} variant="outlined" onMouseOver={() => setShowArrow(true)} onMouseOut={() => setShowArrow(false)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                <Box sx={{
                    backgroundColor: props.bgColor,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px',
                    height: '193px'
                }}>
                    {props.icon}
                </Box>
                <Box sx={{ padding: '24px', display: 'flex', flexDirection: 'column', marginTop: 'auto' }}>
                    <CustomTypography  sx={{ color: '#64677A',fontSize:'14px' }}>{props.desc}</CustomTypography>
                    <Box sx={{
                        marginTop: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '40px'
                    }}>
                        <CustomTypography variant='Regular20'
                            sx={{
                                whiteSpace: showArrow ?'nowrap':"normal",
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {props.title}
                        </CustomTypography>
                        <Box sx={{
                            display: showArrow ? 'block' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px'
                        }}>
                            <ArrowRight />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}

const styles = {
    card: {
        padding: '24px',
        borderRadius: '8px',
        borderColor: '#DFDFDF',
        display: 'flex'
    },
    cardReport: {
        minWidth: '398px',
        marginRight: '24px',
        borderRadius: '8px',
        borderColor: '#DFDFDF'
    },
    cardArrowWithoutBg: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
    }
}