import { combineReducers } from "redux";

import loginReducer from './modules/loginReducer';
import portfolioSummaryReducer from './modules/portfolioSummaryReducer';
import portfoliosReducer from './modules/portfoliosReducer';
import reportsReducer from "./modules/reportsReducer";
import backgroundJobsReducer from "./modules/backgroundJobsReducer";
import dashboardReducer from "./modules/dashboardReducer";
import performanceReducer from "./modules/performanceReducer";

const appReducer = combineReducers({
    login: loginReducer,
    portfolioSummary: portfolioSummaryReducer,
    portfolios: portfoliosReducer,
    reports: reportsReducer,
    backgroundJobs: backgroundJobsReducer,
    dashboard: dashboardReducer,
    performance: performanceReducer,
});

const sharedRootReducer = (state: any, action: any) => {
    return appReducer(state, action);
};

export type SharedRootState = ReturnType<typeof sharedRootReducer>;
export default sharedRootReducer;

export type ClientRootState = {
    shared: SharedRootState
}