import { SharedConstants } from "@mprofit/shared";
import { AdvancedIcon } from "packages/web/src/assets/icons/advancedIcon";
import { CapitalGainIcon } from "packages/web/src/assets/icons/capitalgainIcon";
import { CapitalGainLargeIcon } from "packages/web/src/assets/icons/capitalGainLargeIcon";
import { ClosingBalanceLargeIcon } from "packages/web/src/assets/icons/closingBalanaceLargeIcon";
import { IncomeOtherLargeIcon } from "packages/web/src/assets/icons/incomeAndOtherLargeIcon";
import { IncomeOtherIcon } from "packages/web/src/assets/icons/incomeOtherIcon";
import { PerformanceIconChartIcon } from "packages/web/src/assets/icons/PerformaceIconChartIcon";
import { PerformanceIcon } from "packages/web/src/assets/icons/performanceIcon";
import { PerformanceSummary } from "packages/web/src/assets/icons/PerformanceSummary";
import { TaxSoftFormatIcon } from "packages/web/src/assets/icons/taxSoftFormatIcon";
import { TaxSoftwareLargeIcon } from "packages/web/src/assets/icons/taxSoftwareLargeIcon";
import { TransactionIcon } from "packages/web/src/assets/icons/transactionIcon";
import { WalletTransactionLargeIcon } from "packages/web/src/assets/icons/walletTransactionLargeIcon";

export const CategoryIcons: any = {
 [SharedConstants.InvReportCategoryTypes.PERFORMANCE]: <PerformanceIcon />,
 [SharedConstants.InvReportCategoryTypes.CAPITALGAINS]: <CapitalGainIcon />,
 [SharedConstants.InvReportCategoryTypes.TRANSACTIONS]: <TransactionIcon />,
 [SharedConstants.InvReportCategoryTypes.ADVANCED]: <AdvancedIcon />,
 [SharedConstants.InvReportCategoryTypes.TAXFORMATS]: <TaxSoftFormatIcon />,
 [SharedConstants.InvReportCategoryTypes.INCOME]: <IncomeOtherIcon />,
}

export const CategoryIconsTransparent: any = {
 [SharedConstants.InvReportCategoryTypes.PERFORMANCE]: <PerformanceSummary />,
 [SharedConstants.InvReportCategoryTypes.CAPITALGAINS]: <CapitalGainLargeIcon />,
 [SharedConstants.InvReportCategoryTypes.TRANSACTIONS]: <WalletTransactionLargeIcon />,
 [SharedConstants.InvReportCategoryTypes.ADVANCED]: <ClosingBalanceLargeIcon />,
 [SharedConstants.InvReportCategoryTypes.TAXFORMATS]: <TaxSoftwareLargeIcon />,
 [SharedConstants.InvReportCategoryTypes.INCOME]: <IncomeOtherLargeIcon />,
}