export enum TranTypeMap {
    SETTLE_FNO = -100,
    BORROW = 10,
    IPON = 12,
    INSTPAY = 15,
    OP_BAL = 19,
    BUY = 20,
    SWI = 22,
    IPO = 25,
    TRANS_IN = 27,
    INVEST = 30,
    MtoMBUY = 32,
    DIV_REINV = 35,
    MERGER_RECFOR_CG = 37,
    MERGER_NEW = 38,
    BONUS_RECV = 40,
    SPLIT = 45,
    DMRGR = 46,
    DMRGR_NEW = 47,
    BUY_DEMARC = 50,
    PART_PAYMENT = 52,
    PREM_PAID = 55,
    FIRST_PREM = 58,
    PREM_ALLOCATED = 60,
    RENT_RECVD = 61,
    DIVIDEND_PAYOUT = 62,
    BONUS_PAYOUT = 63,
    MISC_INCOME = 64,
    INT_CUM = 65,
    BONUS_ACCURED = 66,
    INT_PAYOUT = 67,
    INT_TAX_FREE = 68,
    INT_WITH = 69,
    ULIP_FUND_ALLOC = 74,
    SET_CURR_PRICE = 75,
    SET_CURR_VAL = 76,
    IGNORE_TRANSAC = 78,
    SELL_DEMARC = 80,
    SPLIT_CLOSE = 85,
    DMRGR_CLOSE = 86,
    MERGE_CLOSE = 88,
    WRITE_OFF = 90,
    OFF_MARKET_SALE = 99,
    SELL = 101,
    SWO = 103,
    WTHDRWL = 105,
    TRANS_OUT = 109,
    MtoMSELL = 111,
    NEG_SELL = 120,
    PAYBACK = 140,
    BUY_BACK = 150,
    LTG_EQ = 201,
    LTG_DEBT = 202,
    STG = 203,
    ITG = 204,
    SIP = 1010,
    CLEAR_SWIO = 1020,
    BNK_OPBAL = 1105,
    BNK_DEPOSIT = 1120,
    BNK_DEPOSIT_DEMARC = 1150,
    BNK_WTHDRWL = 1160
}

export enum AcctTypes {
    INSU_COS = 201,
    BROKERS = 301,
    AGENTS = 305,
    BANK = 451,
    COMPANY = 501,
    SELLER = 551
}

export enum CurrentField {
    Price = 1,
    Value = 2
}