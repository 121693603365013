import React from 'react'

export default function CurrentPriceicon(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
            <g id="Group_222085" data-name="Group 222085" transform="translate(-1408.074 -238.074)">
                <rect id="Rectangle_24225" data-name="Rectangle 24225" width="64" height="64" rx="8" transform="translate(1408.074 238.074)" fill="#f5f5f6" />
                <g id="Group_222468" data-name="Group 222468" transform="translate(1428.574 258.074)">
                    <path id="Path_237798" data-name="Path 237798" d="M12,7.5a2.25,2.25,0,1,0,2.25,2.25A2.25,2.25,0,0,0,12,7.5Z" />
                    <path id="Path_237799" data-name="Path 237799" d="M1.5,4.875A1.875,1.875,0,0,1,3.375,3h17.25A1.876,1.876,0,0,1,22.5,4.875v9.75A1.875,1.875,0,0,1,20.625,16.5H3.375A1.875,1.875,0,0,1,1.5,14.625ZM8.25,9.75A3.75,3.75,0,1,1,12,13.5,3.75,3.75,0,0,1,8.25,9.75ZM18.75,9a.75.75,0,0,0-.75.75v.008a.75.75,0,0,0,.75.75h.008a.75.75,0,0,0,.75-.75V9.75a.75.75,0,0,0-.75-.75ZM4.5,9.75A.75.75,0,0,1,5.25,9h.008a.75.75,0,0,1,.75.75v.008a.75.75,0,0,1-.75.75H5.25a.75.75,0,0,1-.75-.75Z" fill-rule="evenodd" />
                    <path id="Path_237800" data-name="Path 237800" d="M2.25,18a.75.75,0,0,0,0,1.5,59.311,59.311,0,0,1,15.6,2.075,1.9,1.9,0,0,0,2.4-1.82V18.75A.75.75,0,0,0,19.5,18Z" />
                </g>
            </g>
        </svg>

    )
}
