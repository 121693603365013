export function ClosingValuationIcon_AllToDate(props: any) {
    return (
        <svg id="Group_149319" data-name="Group 149319" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
            <rect id="Rectangle_23692" data-name="Rectangle 23692" width="56" height="56" rx="8" fill="#f5f5f6"/>
            <g id="Group_222468" data-name="Group 222468" transform="translate(14.699 14.876)">
                <path id="Path_237798" data-name="Path 237798" d="M12.6,7.5a2.85,2.85,0,1,0,2.85,2.85A2.85,2.85,0,0,0,12.6,7.5Z" transform="translate(0.701 -1.8)"/>
                <path id="Path_237799" data-name="Path 237799" d="M1.5,5.375A2.375,2.375,0,0,1,3.875,3H25.727A2.376,2.376,0,0,1,28.1,5.375V17.726A2.375,2.375,0,0,1,25.727,20.1H3.875A2.375,2.375,0,0,1,1.5,17.726Zm8.551,6.176A4.75,4.75,0,1,1,14.8,16.3a4.75,4.75,0,0,1-4.75-4.75Zm13.3-.95a.95.95,0,0,0-.95.95v.01a.95.95,0,0,0,.95.95h.01a.95.95,0,0,0,.95-.95v-.01a.95.95,0,0,0-.95-.95ZM5.3,11.551a.95.95,0,0,1,.95-.95h.01a.95.95,0,0,1,.95.95v.01a.95.95,0,0,1-.95.95H6.25a.95.95,0,0,1-.95-.95Z" transform="translate(-1.5 -3)" fill-rule="evenodd"/>
                <path id="Path_237800" data-name="Path 237800" d="M2.45,18a.95.95,0,0,0,0,1.9,75.134,75.134,0,0,1,19.762,2.629,2.4,2.4,0,0,0,3.04-2.306V18.95A.95.95,0,0,0,24.3,18Z" transform="translate(-1.5 1.002)"/>
            </g>
        </svg>
    );
}

export function ClosingValuationIcon_Periodwise(props: any) {
    return (
        <svg id="Group_229291" data-name="Group 229291" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <rect id="Rectangle_23692" data-name="Rectangle 23692" width="48" height="48" rx="8" fill="#f5f5f6"/>
            <path id="Path_272358" data-name="Path 272358" d="M21.85,7.562H4.127V6.853L19.724,5.6V6.853H21.85V4.726a2.4,2.4,0,0,0-2.806-2.435L4.807,4.325A3.378,3.378,0,0,0,2,7.562V21.74a2.836,2.836,0,0,0,2.836,2.836H21.85a2.836,2.836,0,0,0,2.836-2.836V10.4A2.836,2.836,0,0,0,21.85,7.562ZM19.724,17.5a2.128,2.128,0,1,1,2.128-2.127A2.128,2.128,0,0,1,19.724,17.5Z" transform="translate(10.657 10.58)"/>
        </svg>
    );
}
