import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";

import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
let middlewares: any[] = [];
if (process.env.NODE_ENV === 'development') {
    middlewares = [...middlewares, sagaMiddleware, logger];
} else {
    middlewares = [...middlewares, sagaMiddleware];
}

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
            // Ignore these action types
            // ignoredActions: ['your/action/type'],
            // Ignore these field paths in all actions
            ignoredActionPaths: ['payload.OnNavigateToPortfolio','payload.PeriodFromValue','payload.PeriodToValue'],
            // Ignore these paths in the state
            // ignoredPaths: ['items.dates'],
        },
    }).concat(middlewares),
    devTools: process.env.NODE_ENV === 'development'
});
sagaMiddleware.run(rootSaga);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export * from './modules';
