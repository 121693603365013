import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities } from "@mprofit/shared";
import { MultiSelectModal } from "../multi-select-modal";
import { useState } from "react";
import { DropdownButton } from "../dropdown-button";
import { FilterFunnel } from "packages/web/src/assets/icons/FilterFunnel";
import { CaretDownIcon } from "packages/web/src/assets/icons/CaretDownIcon";
import { PerformanceDropdownAssetTypeIcons } from "packages/web/src/assets/icons/assetTypes";
import TooltipDark from "../tooltip-dark";
import { Box } from "@mui/material";

interface PerformanceAssetTypesSelectProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

interface assetTypeIconProps {
  [key: number]: JSX.Element;
}

const assetIcons: assetTypeIconProps = {
  [SharedConstants.AssetTypeEnum.EQ]: <PerformanceDropdownAssetTypeIcons.EQ />,
  [SharedConstants.AssetTypeEnum.ULIP]: <PerformanceDropdownAssetTypeIcons.ULIP />,
  [SharedConstants.AssetTypeEnum.MFEQ]: <PerformanceDropdownAssetTypeIcons.MFEQ />,
  [SharedConstants.AssetTypeEnum.INS]: <PerformanceDropdownAssetTypeIcons.INS />,
  [SharedConstants.AssetTypeEnum.MFD]: <PerformanceDropdownAssetTypeIcons.MFD />,
  [SharedConstants.AssetTypeEnum.PE]: <PerformanceDropdownAssetTypeIcons.PE />,
  [SharedConstants.AssetTypeEnum.FD]: <PerformanceDropdownAssetTypeIcons.FD />,
  [SharedConstants.AssetTypeEnum.BND]: <PerformanceDropdownAssetTypeIcons.BND />,
  [SharedConstants.AssetTypeEnum.NCD]: <PerformanceDropdownAssetTypeIcons.NCD />,
  [SharedConstants.AssetTypeEnum.CD]: <PerformanceDropdownAssetTypeIcons.CD />,
  [SharedConstants.AssetTypeEnum.PPF]: <PerformanceDropdownAssetTypeIcons.PPF />,
  [SharedConstants.AssetTypeEnum.PO]: <PerformanceDropdownAssetTypeIcons.PO />,
  [SharedConstants.AssetTypeEnum.GLD]: <PerformanceDropdownAssetTypeIcons.GLD />,
  [SharedConstants.AssetTypeEnum.SLV]: <PerformanceDropdownAssetTypeIcons.SLV />,
  [SharedConstants.AssetTypeEnum.JWL]: <PerformanceDropdownAssetTypeIcons.JWL />,
  [SharedConstants.AssetTypeEnum.PR]: <PerformanceDropdownAssetTypeIcons.PR />,
  [SharedConstants.AssetTypeEnum.ART]: <PerformanceDropdownAssetTypeIcons.ART />,
  [SharedConstants.AssetTypeEnum.MIS]: <PerformanceDropdownAssetTypeIcons.MIS />,
  [SharedConstants.AssetTypeEnum.LN]: <PerformanceDropdownAssetTypeIcons.LN />,
}

const getAssetIcon = (assetTypeID: SharedConstants.AssetTypeEnum) => {

}

export const PerformanceAssetTypesSelect = () => {
  const portfolioATTY = useAppSelector(SharedRedux.Performance.Selectors.selectPortfolioATTY);
  const portfolioATTYText = useAppSelector(SharedRedux.Performance.Selectors.selectPortfolioATTYText);

  const assetTypeList = SharedConstants.AssetTypesList_PeriodwiseXIRR;
  const [isModalOpen, setIsModalOpen] = useState(portfolioATTY === undefined);

  const dispatch = useAppDispatch();

  const onSave = (selectedItems: { [ID: number]: boolean }) => {
    dispatch(SharedRedux.Performance.Actions.setPortfolioATTY(SharedUtilities.convertSelectedIDDictToIDList(selectedItems)));
    setIsModalOpen(false);
  }

  const title = 'Select Asset Classes for Periodwise XIRR';
  const multiLineSubtitle = [
    'MProfit provides historical valuations for Stocks & Mutual Funds. If you would like to include other asset classes, please ensure you have updated the historical prices/valuations in MProfit.',
    'Please note that period-wise reporting for Traded Bonds is currently not available.'
  ];

  const dropdownText = portfolioATTY && portfolioATTY.length > 0 ? portfolioATTYText : 'Select Asset Classes';

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
        <DropdownButton text={dropdownText} IconLeft={<FilterFunnel />} IconRight={<CaretDownIcon />} onButtonClick={() => setIsModalOpen(true)} />
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {
            portfolioATTY && portfolioATTY.map(x => {
              const ATTY = x as SharedConstants.AssetTypeEnum;
              return (
                <TooltipDark title={SharedConstants.AssetTypeNameMapping[ATTY]}>
                  <Box sx={{ marginLeft: '-5px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {assetIcons[ATTY]}
                  </Box>
                </TooltipDark>
              )
            })
          }
        </Box>
      </Box>
      <MultiSelectModal
        {...{ isModalOpen, setIsModalOpen, maxCount: -1 }}
        onSave={onSave}
        initialSelectedItems={portfolioATTY || [SharedConstants.AssetTypeEnum.EQ, SharedConstants.AssetTypeEnum.MFEQ]}
        isInitialSelectedItemsWithoutIDs={true}
        list={assetTypeList}
        idField={'id'}
        textField={'text'}
        title={title}
        multiLineSubtitle={multiLineSubtitle}
      />
    </>
  );
}