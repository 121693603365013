import React from 'react'

export default function CardValueBackground(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="112" height="124" viewBox="0 0 112 124">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_34485" data-name="Rectangle 34485" width="112" height="124" rx="8" transform="translate(891 248)" fill="#acc18a" opacity="0.2" />
                </clipPath>
            </defs>
            <g id="Mask_Group_99" data-name="Mask Group 99" transform="translate(-891 -248)" opacity="0.6" clip-path="url(#clip-path)" style={{ mixBlendMode: "luminosity", isolation: "isolate" }}>
                <g id="Group_222361" data-name="Group 222361" transform="translate(902.73 273)" style={{ mixBlendMode: "luminosity", isolation: "isolate" }}>
                    <g id="Group_222352" data-name="Group 222352" transform="translate(0.152 11.846)">
                        <path id="Path_237521" data-name="Path 237521" d="M128.9,37.094H37.574A6.08,6.08,0,0,0,31.5,43.168v62.583a6.08,6.08,0,0,0,6.074,6.074H128.9a6.08,6.08,0,0,0,6.074-6.074V43.168a6.08,6.08,0,0,0-6.074-6.074Z" transform="translate(-21.592 -37.094)" fill="none" stroke="#707070" stroke-width="0.2" opacity="0.4" />
                        <path id="Path_237522" data-name="Path 237522" d="M97.875,52.188H6.55A6.08,6.08,0,0,0,.475,58.262v62.583a6.08,6.08,0,0,0,6.074,6.074H97.875a6.08,6.08,0,0,0,6.074-6.074V58.262a6.08,6.08,0,0,0-6.074-6.074Z" transform="translate(-0.475 -47.367)" fill="#e9edf9" stroke="#baa8ee" stroke-width="0.5" />
                    </g>
                    <g id="Group_222354" data-name="Group 222354" transform="translate(10.54 23.588)">
                        <path id="Path_237526" data-name="Path 237526" d="M42.782,73.859H33.821a.819.819,0,0,0,0,1.638h8.961a.819.819,0,0,0,0-1.638Z" transform="translate(-33.003 -73.859)" fill="#bdc2ae" />
                        <path id="Path_237527" data-name="Path 237527" d="M89.782,73.859H80.82a.819.819,0,0,0,0,1.638h8.961a.819.819,0,0,0,0-1.638Z" transform="translate(-64.993 -73.859)" fill="#bdc2ae" />
                        <path id="Path_237528" data-name="Path 237528" d="M136.782,73.859H127.82a.819.819,0,0,0,0,1.638h8.961a.819.819,0,0,0,0-1.638Z" transform="translate(-96.983 -73.859)" fill="#bdc2ae" />
                    </g>
                    <rect id="Rectangle_34493" data-name="Rectangle 34493" width="81.438" height="0.639" transform="translate(10.531 77.346)" fill="#e6e6e6" />
                    <g id="Group_222559" data-name="Group 222559" opacity="0">
                        <g id="Group_222353" data-name="Group 222353" transform="translate(12.656 44.771)">
                            <path id="Path_237523" data-name="Path 237523" d="M40.17,239.567l-.54-.34c.351-.558.714-1.1,1.077-1.609l.52.371c-.356.5-.712,1.03-1.057,1.578Z" transform="translate(-39.629 -206.502)" fill="#cbcedf" />
                            <path id="Path_237524" data-name="Path 237524" d="M51.375,169.884l-.457-.446a18.675,18.675,0,0,1,3.049-2.548l.352.533A18.031,18.031,0,0,0,51.375,169.884Zm15.935-4.032a12.2,12.2,0,0,1-1.978-.183l.1-.63a11.43,11.43,0,0,0,1.875.174A8.649,8.649,0,0,0,69.2,165l.138.624A9.289,9.289,0,0,1,67.31,165.852Zm-9.557-.085-.192-.609a13.457,13.457,0,0,1,2.113-.482,12.421,12.421,0,0,1,1.8-.132h.039v.639h-.039a11.783,11.783,0,0,0-1.7.126A12.822,12.822,0,0,0,57.752,165.768Zm15.225-1.846-.408-.491a12.82,12.82,0,0,0,2.182-2.555l.267-.373.518.373-.265.371A13.3,13.3,0,0,1,72.977,163.922Zm5.122-5.856-.393-.5A11.728,11.728,0,0,1,80.68,155.9q.291-.115.558-.205l.206.6c-.169.058-.346.123-.53.2A11.1,11.1,0,0,0,78.1,158.065Zm7.711-2.219c-.215,0-.419,0-.615,0l.007-.639.63,0a27.008,27.008,0,0,0,3.234-.188l.074.634a28.03,28.03,0,0,1-3.331.192Zm7.217-.836-.127-.626c1.147-.232,2.482-.508,3.8-.849l.16.618c-1.336.345-2.681.623-3.836.857Zm7.6-2.067-.239-.592a17.982,17.982,0,0,0,3.4-1.78l.358.529a18.609,18.609,0,0,1-3.519,1.844Zm6.454-4.564-.518-.373c.178-.247.332-.489.481-.722a8.235,8.235,0,0,1,1.978-2.275l.076-.061.4.5-.07.056a7.6,7.6,0,0,0-1.841,2.122C107.427,147.868,107.268,148.117,107.079,148.379Zm5.73-4.79-.233-.595a21.259,21.259,0,0,1,3.821-1.068l.118.628a20.633,20.633,0,0,0-3.706,1.035Z" transform="translate(-47.313 -141.371)" fill="#b6bcdc" />
                            <path id="Path_237525" data-name="Path 237525" d="M268.458,140.9l-.04-.637c.621-.039,1.27-.064,1.929-.076l.012.639C269.708,140.838,269.069,140.864,268.458,140.9Z" transform="translate(-195.351 -140.188)" fill="#cbcedf" />
                        </g>
                        <path id="Path_237529" data-name="Path 237529" d="M239.092,94.827c-5.8.15-10.121,1.856-12.861,5.062A11.074,11.074,0,0,0,224.372,103a13.749,13.749,0,0,1-1.9,3.3v-1.086a14.215,14.215,0,0,0,1.313-2.469,11.528,11.528,0,0,1,1.958-3.267c2.862-3.353,7.345-5.132,13.327-5.289Z" transform="translate(-151.424 -64.107)" fill="#cbcedf" />
                        <path id="Path_237530" data-name="Path 237530" d="M99.87,119.863a13.751,13.751,0,0,1-1.9,3.3q-.182.235-.393.489c-3.7,4.4-8.639,5.841-12.612,7-.249.073-.492.141-.728.2a21.9,21.9,0,0,1-6.908.9,6.569,6.569,0,0,0-4.11.936c-3.133,1.785-4.417,4.4-5.659,6.924-1.281,2.6-2.488,5.056-5.567,6.208a7.674,7.674,0,0,1-5.225-.013c-1.364-.361-3.821-9.244-6-10.539-4.493.942-7.224,15.627-10.632,23.489l-.588-.255c3.5-8.061,6.378-22.87,11.085-23.86,2.1-.441,4.861,10.169,6.295,10.545a7.121,7.121,0,0,0,4.838.032c2.842-1.06,3.941-3.3,5.215-5.889,1.226-2.491,2.616-5.314,5.915-7.2a7.179,7.179,0,0,1,4.439-1.019,21.414,21.414,0,0,0,6.9-.923l.549-.157c3.89-1.137,8.728-2.552,12.3-6.8a13.76,13.76,0,0,0,.885-1.166,14.214,14.214,0,0,0,1.313-2.469c.454-1.035,1.019-.731.584.259Z" transform="translate(-26.924 -80.969)" fill="#6c63ff" />
                    </g>
                    <g id="Group_222360" data-name="Group 222360" transform="translate(0)">
                        <ellipse id="Ellipse_2183" data-name="Ellipse 2183" cx="3.98" cy="3.896" rx="3.98" ry="3.896" transform="translate(0 0.103)" fill="#bdc2ae" />
                        <path id="Path_237546" data-name="Path 237546" d="M63.875,2.666H42.45A1.333,1.333,0,1,1,42.45,0H63.875a1.333,1.333,0,1,1,0,2.666Z" transform="translate(-27.986)" fill="#d2d9ee" opacity="0.5" />
                        <path id="Path_237547" data-name="Path 237547" d="M80.595,16.793H42.45a1.333,1.333,0,1,1,0-2.666H80.595a1.333,1.333,0,1,1,0,2.666Z" transform="translate(-27.986 -9.615)" fill="#d2d9ee" opacity="0.5" />
                    </g>
                    <g id="Group_222558" data-name="Group 222558" transform="translate(509.052 189.499)">
                        <path id="Path_238540" data-name="Path 238540" d="M381.42,1046.166s5.943-6.263,9.973-6.19c4.432.082,6.833,3.523,10.092,3.523,4.518,0,19.257-19.956,26.106-16.234,6.566,3.568,7.757,13.344,12.8,12.563s11.555-12.418,16.5-7.7c5.418,5.171,9.357,14.036,9.357,14.036Z" transform="translate(-877.006 -1158)" fill="#d2d9ee" />
                        <path id="Path_238541" data-name="Path 238541" d="M401.42,1086.283s5.772-3.933,8.09-3.933c3.467,0,4.953,2.622,10.236,2.622,6.279,0,16.928-14.472,24.269-14.472,5.614,0,8.06,13.145,14.859,12,3.88-.656,6.735-4.807,9.741-4.807,3.467,0,2.642,5.972,6.438,5.972,1.5,0,4.788-.728,7.595,2.622Z" transform="translate(-895.367 -1198.117)" fill="#d2d9ee" opacity="0" />
                    </g>
                </g>
            </g>
        </svg>

    )
}
