
export function CapitalGainIcon(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
            <g id="Group_154347" data-name="Group 154347" transform="translate(-24 -32)">
                <g id="Rectangle_25935" data-name="Rectangle 25935" transform="translate(24 32)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                    <rect width="64" height="64" stroke="none" />
                    <rect x="0.5" y="0.5" width="63" height="63" fill="none" />
                </g>
                <g id="Group_154336" data-name="Group 154336" transform="translate(22362.387 368.1)">
                    <g id="Group_154339" data-name="Group 154339" transform="translate(-22318.227 -297.451)">
                        <path id="Path_235054" data-name="Path 235054" d="M173.709,324.219v5.067c0,2.442,7.945,4.422,17.747,4.422s17.747-1.979,17.747-4.422v-5.067Z" transform="translate(-173.709 -319.798)" fill="#acc18a" />
                        <path id="Path_235055" data-name="Path 235055" d="M180.19,332.957a19.8,19.8,0,0,0,1.992.624v-6.522H180.19Zm3.227.883c1.088.235,2.29.437,3.585.6v-7.417h-3.585Zm10.357,1.1c.415.007.832.012,1.255.012.791,0,1.567-.014,2.33-.039v-6.7h-3.585Zm5.218-.1q.791-.045,1.554-.107v-6.521h-1.554Zm9.8-1.548a15.842,15.842,0,0,0,1.554-.558V326.48h-1.554Z" transform="translate(-177.282 -321.044)" fill="#acc18a" />
                        <ellipse id="Ellipse_2267" data-name="Ellipse 2267" cx="17.747" cy="4.421" rx="17.747" ry="4.421" transform="translate(0 0)" fill="#acc18a" />
                        <ellipse id="Ellipse_2268" data-name="Ellipse 2268" cx="14.412" cy="3.591" rx="14.412" ry="3.591" transform="translate(2.933 0.372)" fill="#acc18a" />
                        <path id="Path_235056" data-name="Path 235056" d="M194.659,326.984c-7.745,0-14.06-1.522-14.4-3.431a.911.911,0,0,0-.016.16c0,1.983,6.453,3.591,14.412,3.591s14.412-1.608,14.412-3.591a.911.911,0,0,0-.016-.16C208.719,325.462,202.4,326.984,194.659,326.984Z" transform="translate(-177.314 -319.43)" fill="#acc18a" />
                        <path id="Path_235057" data-name="Path 235057" d="M194.659,316.355c7.165,0,13.107,1.3,14.221,3.01a1.059,1.059,0,0,0,.19-.581c0-1.983-6.453-3.591-14.412-3.591s-14.412,1.608-14.412,3.591a1.057,1.057,0,0,0,.19.581C181.552,317.658,187.493,316.355,194.659,316.355Z" transform="translate(-177.314 -314.822)" fill="#acc18a" />
                        <path id="Path_235058" data-name="Path 235058" d="M191.456,327.675c-9.4,0-17.084-1.82-17.7-4.123a1.155,1.155,0,0,0-.045.3c0,2.442,7.945,4.421,17.747,4.421s17.747-1.979,17.747-4.421a1.155,1.155,0,0,0-.045-.3C208.54,325.855,200.853,327.675,191.456,327.675Z" transform="translate(-173.709 -319.43)" fill="#101010" />
                        <path id="Path_235059" data-name="Path 235059" d="M210.275,322.148c.228.291.075.455-.767.417-.707-.032-.968.1-.948.356a1.076,1.076,0,0,1-.091.424c-.313.89-.539.949-2.932.767-.213-.269-.2-.474.655-.61.949-.152,1.134-.342,1.16-.92-1.457,0-2.919-.009-4.378.011a1.312,1.312,0,0,0-.573.241c-.385.227-.7.473-1.115.691a6.675,6.675,0,0,1-3.6.577c-1.453-.1-2.313-.472-2.687-.99-.252-.348-.443-.647-1.658-.564-.113.008-.433-.151-.421-.227s.35-.213.5-.2c1.147.066,1.28-.2,1.267-.527a.356.356,0,0,1,.03-.116c.267-.855.524-.922,2.842-.743.249.3.091.5-.748.639-.706.115-1,.367-.892.725,1.306,0,2.609.013,3.907-.012a1.593,1.593,0,0,0,.661-.254,13.153,13.153,0,0,1,1.42-.8c1.97-.8,5.2-.558,6.282.462C208.782,322.06,208.782,322.06,210.275,322.148Zm-14.356.441c.242.411.8.593,1.739.581a2.543,2.543,0,0,0,1.841-.581Zm11.312-.516c-.194-.381-.811-.52-1.641-.544a3.051,3.051,0,0,0-2.155.544Z" transform="translate(-184.299 -317.777)" fill="#acc18a" />
                    </g>
                    <g id="Group_154340" data-name="Group 154340" transform="translate(-22324.354 -303.488)">
                        <path id="Path_235060" data-name="Path 235060" d="M160.055,310.763v5.067c0,2.442,7.945,4.422,17.746,4.422s17.746-1.979,17.746-4.422v-5.067Z" transform="translate(-160.055 -306.341)" fill="#acc18a" />
                        <path id="Path_235061" data-name="Path 235061" d="M166.537,319.5a19.8,19.8,0,0,0,1.992.624V313.6h-1.992Zm3.227.882c1.088.235,2.29.437,3.585.6v-7.417h-3.585Zm10.357,1.1c.415.007.832.012,1.255.012.791,0,1.567-.014,2.33-.039v-6.7h-3.585Zm5.218-.1q.791-.045,1.554-.107v-6.521h-1.554Zm9.8-1.548a15.823,15.823,0,0,0,1.554-.558v-6.256h-1.554Z" transform="translate(-163.629 -307.588)" fill="#acc18a" />
                        <ellipse id="Ellipse_2269" data-name="Ellipse 2269" cx="17.747" cy="4.421" rx="17.747" ry="4.421" transform="translate(0)" fill="#acc18a" />
                        <ellipse id="Ellipse_2270" data-name="Ellipse 2270" cx="14.412" cy="3.591" rx="14.412" ry="3.591" transform="translate(2.933 0.372)" fill="#acc18a" />
                        <path id="Path_235062" data-name="Path 235062" d="M181,313.527c-7.745,0-14.06-1.522-14.4-3.431a.908.908,0,0,0-.016.16c0,1.983,6.453,3.591,14.412,3.591s14.412-1.607,14.412-3.591a.916.916,0,0,0-.016-.16C195.065,312.005,188.749,313.527,181,313.527Z" transform="translate(-163.66 -305.974)" fill="#acc18a" />
                        <path id="Path_235063" data-name="Path 235063" d="M181,302.9c7.165,0,13.107,1.3,14.221,3.01a1.059,1.059,0,0,0,.19-.581c0-1.983-6.453-3.591-14.412-3.591s-14.412,1.608-14.412,3.591a1.057,1.057,0,0,0,.19.581C167.9,304.2,173.839,302.9,181,302.9Z" transform="translate(-163.66 -301.366)" fill="#acc18a" />
                        <path id="Path_235064" data-name="Path 235064" d="M177.8,314.219c-9.4,0-17.084-1.82-17.7-4.123a1.156,1.156,0,0,0-.045.3c0,2.442,7.945,4.421,17.746,4.421s17.746-1.979,17.746-4.421a1.156,1.156,0,0,0-.045-.3C194.886,312.4,187.2,314.219,177.8,314.219Z" transform="translate(-160.055 -305.974)" fill="#101010" />
                        <path id="Path_235065" data-name="Path 235065" d="M196.622,308.691c.228.291.075.455-.767.417-.707-.032-.968.1-.948.356a1.076,1.076,0,0,1-.091.424c-.313.89-.539.949-2.932.767-.213-.269-.2-.474.655-.61.949-.152,1.134-.342,1.16-.92-1.457,0-2.919-.009-4.378.011a1.313,1.313,0,0,0-.573.241c-.385.227-.7.473-1.115.691a6.676,6.676,0,0,1-3.6.577c-1.453-.1-2.313-.472-2.687-.99-.252-.348-.443-.646-1.658-.563-.114.008-.433-.151-.421-.227s.35-.213.5-.2c1.147.066,1.28-.2,1.267-.527a.358.358,0,0,1,.03-.116c.267-.855.524-.921,2.842-.742.249.3.091.5-.748.639-.706.115-1,.367-.892.725,1.306,0,2.609.013,3.907-.012a1.592,1.592,0,0,0,.661-.254,13.136,13.136,0,0,1,1.42-.8c1.97-.8,5.2-.558,6.282.462C195.129,308.6,195.129,308.6,196.622,308.691Zm-14.356.441c.242.411.8.593,1.739.581a2.543,2.543,0,0,0,1.841-.581Zm11.312-.516c-.194-.381-.811-.52-1.641-.544a3.05,3.05,0,0,0-2.155.544Z" transform="translate(-170.645 -304.321)" fill="#acc18a" />
                    </g>
                    <g id="Group_154341" data-name="Group 154341" transform="translate(-22321.063 -310.071)">
                        <path id="Path_235066" data-name="Path 235066" d="M167.386,296.091v5.067c0,2.442,7.945,4.421,17.747,4.421s17.747-1.979,17.747-4.421v-5.067Z" transform="translate(-167.386 -291.669)" fill="#acc18a" />
                        <path id="Path_235067" data-name="Path 235067" d="M173.867,304.829a19.794,19.794,0,0,0,1.992.624v-6.522h-1.992Zm3.227.882c1.088.235,2.29.437,3.585.6V298.9h-3.585Zm10.357,1.1c.415.007.832.012,1.255.012.791,0,1.567-.014,2.33-.039v-6.7h-3.585Zm5.218-.1q.791-.045,1.554-.107v-6.521h-1.554Zm9.8-1.548a15.893,15.893,0,0,0,1.554-.558v-6.256h-1.554Z" transform="translate(-170.959 -292.917)" fill="#acc18a" />
                        <ellipse id="Ellipse_2271" data-name="Ellipse 2271" cx="17.747" cy="4.421" rx="17.747" ry="4.421" transform="translate(0 0)" fill="#acc18a" />
                        <ellipse id="Ellipse_2272" data-name="Ellipse 2272" cx="14.412" cy="3.591" rx="14.412" ry="3.591" transform="translate(2.933 0.372)" fill="#acc18a" />
                        <path id="Path_235068" data-name="Path 235068" d="M188.335,298.855c-7.745,0-14.06-1.522-14.4-3.431a.909.909,0,0,0-.016.16c0,1.983,6.453,3.591,14.412,3.591s14.412-1.608,14.412-3.591a.914.914,0,0,0-.016-.16C202.4,297.333,196.079,298.855,188.335,298.855Z" transform="translate(-170.99 -291.302)" fill="#acc18a" />
                        <path id="Path_235069" data-name="Path 235069" d="M188.335,288.227c7.165,0,13.107,1.3,14.221,3.01a1.059,1.059,0,0,0,.19-.581c0-1.983-6.453-3.591-14.412-3.591s-14.412,1.607-14.412,3.591a1.058,1.058,0,0,0,.19.581C175.228,289.53,181.169,288.227,188.335,288.227Z" transform="translate(-170.99 -286.694)" fill="#acc18a" />
                        <path id="Path_235070" data-name="Path 235070" d="M185.132,299.547c-9.4,0-17.084-1.82-17.7-4.123a1.156,1.156,0,0,0-.045.3c0,2.442,7.945,4.422,17.746,4.422s17.746-1.979,17.746-4.422a1.155,1.155,0,0,0-.045-.3C202.217,297.727,194.53,299.547,185.132,299.547Z" transform="translate(-167.386 -291.302)" fill="#101010" />
                        <path id="Path_235071" data-name="Path 235071" d="M203.952,294.02c.228.291.075.455-.767.417-.707-.032-.968.1-.948.356a1.075,1.075,0,0,1-.091.424c-.313.89-.539.949-2.932.767-.213-.269-.2-.474.655-.61.949-.152,1.134-.342,1.16-.92-1.457,0-2.919-.009-4.378.011a1.312,1.312,0,0,0-.573.241c-.385.227-.7.473-1.115.691a6.676,6.676,0,0,1-3.6.577c-1.453-.1-2.313-.472-2.687-.99-.252-.348-.443-.646-1.658-.564-.113.008-.433-.151-.421-.227s.35-.213.5-.2c1.147.066,1.28-.2,1.267-.527a.357.357,0,0,1,.03-.116c.267-.855.524-.922,2.842-.742.249.3.091.5-.748.639-.706.115-1,.367-.892.725,1.306,0,2.609.013,3.907-.012a1.592,1.592,0,0,0,.661-.254,13.137,13.137,0,0,1,1.42-.8c1.97-.8,5.2-.558,6.282.462C202.459,293.932,202.459,293.932,203.952,294.02Zm-14.356.441c.242.411.8.593,1.739.581a2.543,2.543,0,0,0,1.841-.581Zm11.312-.516c-.194-.381-.811-.52-1.641-.544a3.051,3.051,0,0,0-2.155.544Z" transform="translate(-177.976 -289.649)" fill="#acc18a" />
                    </g>
                    <g id="Group_154342" data-name="Group 154342" transform="translate(-22316.752 -315.764)">
                        <path id="Path_235072" data-name="Path 235072" d="M177,283.4v5.067c0,2.442,7.945,4.422,17.746,4.422s17.746-1.979,17.746-4.422V283.4Z" transform="translate(-177.001 -278.981)" fill="#acc18a" />
                        <path id="Path_235073" data-name="Path 235073" d="M183.483,292.141a19.8,19.8,0,0,0,1.992.624v-6.523h-1.992Zm3.227.882c1.088.235,2.29.437,3.585.6v-7.417H186.71Zm10.357,1.1c.415.007.832.012,1.255.012.791,0,1.567-.014,2.33-.039v-6.7h-3.585Zm5.218-.1q.791-.045,1.554-.107V287.4h-1.554Zm9.8-1.548a15.856,15.856,0,0,0,1.554-.558v-6.256h-1.554Z" transform="translate(-180.575 -280.228)" fill="#acc18a" />
                        <ellipse id="Ellipse_2273" data-name="Ellipse 2273" cx="17.747" cy="4.421" rx="17.747" ry="4.421" transform="translate(0)" fill="#acc18a" />
                        <ellipse id="Ellipse_2274" data-name="Ellipse 2274" cx="14.412" cy="3.591" rx="14.412" ry="3.591" transform="translate(2.933 0.372)" fill="#acc18a" />
                        <path id="Path_235074" data-name="Path 235074" d="M197.951,286.167c-7.745,0-14.06-1.522-14.4-3.431a.909.909,0,0,0-.016.16c0,1.983,6.452,3.591,14.412,3.591s14.412-1.607,14.412-3.591a.914.914,0,0,0-.016-.16C212.011,284.645,205.7,286.167,197.951,286.167Z" transform="translate(-180.606 -278.613)" fill="#acc18a" />
                        <path id="Path_235075" data-name="Path 235075" d="M197.951,275.539c7.165,0,13.107,1.3,14.221,3.01a1.058,1.058,0,0,0,.19-.581c0-1.983-6.453-3.591-14.412-3.591s-14.412,1.607-14.412,3.591a1.058,1.058,0,0,0,.19.581C184.844,276.842,190.785,275.539,197.951,275.539Z" transform="translate(-180.606 -274.005)" fill="#acc18a" />
                        <path id="Path_235076" data-name="Path 235076" d="M194.747,286.859c-9.4,0-17.084-1.82-17.7-4.123a1.156,1.156,0,0,0-.045.3c0,2.442,7.945,4.422,17.747,4.422s17.746-1.98,17.746-4.422a1.156,1.156,0,0,0-.045-.3C211.832,285.039,204.145,286.859,194.747,286.859Z" transform="translate(-177.001 -278.613)" fill="#101010" />
                        <path id="Path_235077" data-name="Path 235077" d="M213.568,281.332c.228.291.075.455-.767.417-.707-.032-.968.1-.948.356a1.075,1.075,0,0,1-.091.424c-.313.89-.539.949-2.932.767-.213-.269-.2-.474.655-.61.949-.152,1.134-.342,1.16-.92-1.457,0-2.919-.009-4.378.011a1.313,1.313,0,0,0-.573.241c-.385.227-.7.473-1.115.691a6.675,6.675,0,0,1-3.6.577c-1.453-.1-2.313-.472-2.687-.99-.252-.348-.443-.647-1.658-.564-.113.008-.433-.151-.421-.227s.35-.213.5-.2c1.147.066,1.28-.2,1.267-.527a.356.356,0,0,1,.03-.116c.267-.855.524-.922,2.842-.743.249.3.091.5-.748.639-.706.115-1,.367-.892.725,1.306,0,2.609.013,3.907-.012a1.593,1.593,0,0,0,.661-.254,13.161,13.161,0,0,1,1.42-.8c1.97-.8,5.2-.558,6.282.462C212.075,281.243,212.075,281.243,213.568,281.332Zm-14.356.441c.242.411.8.593,1.739.581a2.544,2.544,0,0,0,1.841-.581Zm11.312-.516c-.194-.381-.811-.52-1.641-.544a3.05,3.05,0,0,0-2.155.544Z" transform="translate(-187.591 -276.961)" fill="#acc18a" />
                    </g>
                    <g id="Group_154343" data-name="Group 154343" transform="translate(-22311.561 -322.145)">
                        <path id="Path_235078" data-name="Path 235078" d="M188.562,269.178v5.067c0,2.442,7.945,4.422,17.747,4.422s17.746-1.979,17.746-4.422v-5.067Z" transform="translate(-188.562 -264.756)" fill="#acc18a" />
                        <path id="Path_235079" data-name="Path 235079" d="M195.043,277.917a19.8,19.8,0,0,0,1.992.624v-6.522h-1.992Zm3.227.882c1.088.235,2.29.437,3.585.6v-7.417H198.27Zm10.357,1.1c.415.007.832.012,1.255.012.791,0,1.567-.014,2.33-.039v-6.7h-3.585Zm5.218-.1q.791-.045,1.554-.107v-6.521h-1.554Zm9.8-1.548a15.858,15.858,0,0,0,1.554-.558V271.44h-1.554Z" transform="translate(-192.135 -266.004)" fill="#acc18a" />
                        <ellipse id="Ellipse_2275" data-name="Ellipse 2275" cx="17.747" cy="4.421" rx="17.747" ry="4.421" transform="translate(0 0)" fill="#acc18a" />
                        <ellipse id="Ellipse_2276" data-name="Ellipse 2276" cx="14.412" cy="3.591" rx="14.412" ry="3.591" transform="translate(2.933 0.373)" fill="#acc18a" />
                        <path id="Path_235080" data-name="Path 235080" d="M209.512,271.943c-7.745,0-14.06-1.522-14.4-3.431a.909.909,0,0,0-.016.16c0,1.983,6.452,3.591,14.412,3.591s14.412-1.607,14.412-3.591a.916.916,0,0,0-.016-.16C223.572,270.421,217.256,271.943,209.512,271.943Z" transform="translate(-192.167 -264.389)" fill="#acc18a" />
                        <path id="Path_235081" data-name="Path 235081" d="M209.512,261.315c7.165,0,13.107,1.3,14.221,3.01a1.059,1.059,0,0,0,.19-.581c0-1.983-6.452-3.591-14.412-3.591s-14.412,1.608-14.412,3.591a1.058,1.058,0,0,0,.19.581C196.4,262.618,202.346,261.315,209.512,261.315Z" transform="translate(-192.167 -259.781)" fill="#acc18a" />
                        <path id="Path_235082" data-name="Path 235082" d="M206.309,272.635c-9.4,0-17.084-1.82-17.7-4.123a1.155,1.155,0,0,0-.045.3c0,2.442,7.945,4.422,17.747,4.422s17.746-1.979,17.746-4.422a1.156,1.156,0,0,0-.045-.3C223.393,270.815,215.706,272.635,206.309,272.635Z" transform="translate(-188.562 -264.389)" fill="#101010" />
                        <path id="Path_235083" data-name="Path 235083" d="M225.129,267.107c.228.291.075.455-.767.417-.707-.032-.968.1-.948.356a1.074,1.074,0,0,1-.091.424c-.313.89-.539.949-2.932.767-.213-.269-.2-.474.655-.61.949-.152,1.134-.342,1.16-.92-1.457,0-2.919-.009-4.378.011a1.313,1.313,0,0,0-.573.241c-.385.227-.7.473-1.115.691a6.676,6.676,0,0,1-3.6.577c-1.453-.105-2.313-.472-2.687-.99-.252-.348-.443-.647-1.658-.563-.113.008-.433-.151-.421-.227s.35-.213.5-.2c1.147.066,1.28-.2,1.267-.527a.357.357,0,0,1,.03-.116c.267-.855.524-.921,2.842-.743.249.3.091.5-.748.639-.706.115-1,.367-.892.725,1.306,0,2.609.013,3.907-.012a1.593,1.593,0,0,0,.661-.254,13.158,13.158,0,0,1,1.42-.8c1.97-.8,5.2-.558,6.282.462C223.636,267.019,223.636,267.019,225.129,267.107Zm-14.356.441c.242.411.8.593,1.739.581a2.543,2.543,0,0,0,1.841-.581Zm11.312-.516c-.194-.381-.811-.52-1.641-.544a3.05,3.05,0,0,0-2.155.544Z" transform="translate(-199.152 -262.736)" fill="#acc18a" />
                    </g>
                    <g id="Group_154344" data-name="Group 154344" transform="translate(-22317.152 -329.1)">
                        <path id="Path_235084" data-name="Path 235084" d="M176.106,253.676v5.067c0,2.442,7.945,4.421,17.747,4.421s17.747-1.979,17.747-4.421v-5.067Z" transform="translate(-176.106 -249.255)" fill="#acc18a" />
                        <path id="Path_235085" data-name="Path 235085" d="M182.588,262.415a19.794,19.794,0,0,0,1.992.624v-6.523h-1.992Zm3.226.882c1.088.235,2.29.437,3.585.6v-7.417h-3.585Zm10.357,1.1c.415.007.832.012,1.255.012.791,0,1.567-.014,2.33-.039v-6.7h-3.585Zm5.219-.1q.791-.045,1.554-.107v-6.521H201.39Zm9.8-1.548a15.865,15.865,0,0,0,1.554-.558v-6.256h-1.554Z" transform="translate(-179.68 -250.502)" fill="#acc18a" />
                        <ellipse id="Ellipse_2277" data-name="Ellipse 2277" cx="17.747" cy="4.421" rx="17.747" ry="4.421" transform="translate(0 0)" fill="#acc18a" />
                        <ellipse id="Ellipse_2278" data-name="Ellipse 2278" cx="14.412" cy="3.591" rx="14.412" ry="3.591" transform="translate(2.933 0.372)" fill="#acc18a" />
                        <path id="Path_235086" data-name="Path 235086" d="M197.056,256.441c-7.745,0-14.06-1.522-14.4-3.431a.911.911,0,0,0-.016.16c0,1.983,6.452,3.591,14.412,3.591s14.412-1.608,14.412-3.591a.913.913,0,0,0-.016-.16C211.116,254.919,204.8,256.441,197.056,256.441Z" transform="translate(-179.711 -248.887)" fill="#acc18a" />
                        <path id="Path_235087" data-name="Path 235087" d="M197.056,245.813c7.165,0,13.107,1.3,14.221,3.01a1.059,1.059,0,0,0,.19-.581c0-1.983-6.453-3.591-14.412-3.591s-14.412,1.608-14.412,3.591a1.057,1.057,0,0,0,.19.581C183.949,247.116,189.89,245.813,197.056,245.813Z" transform="translate(-179.711 -244.279)" fill="#acc18a" />
                        <path id="Path_235088" data-name="Path 235088" d="M193.852,257.133c-9.4,0-17.084-1.82-17.7-4.123a1.156,1.156,0,0,0-.045.3c0,2.442,7.945,4.421,17.746,4.421s17.747-1.979,17.747-4.421a1.156,1.156,0,0,0-.045-.3C210.937,255.313,203.25,257.133,193.852,257.133Z" transform="translate(-176.106 -248.887)" fill="#101010" />
                    </g>
                    <g id="Group_154330" data-name="Group 154330" transform="translate(-22337.387 -310.071)">
                        <ellipse id="Ellipse_2316" data-name="Ellipse 2316" cx="16.326" cy="15.516" rx="16.326" ry="15.516" transform="translate(0 0)" fill="#acc18a" />
                        <path id="Path_235205" data-name="Path 235205" d="M239.637,120.01a14.062,14.062,0,0,1,14.251,13.024c.007-.192.013-.384.011-.578-.051-7.441-6.439-13.429-14.269-13.375s-14.134,6.129-14.083,13.57c0,.194.01.386.019.578A14.063,14.063,0,0,1,239.637,120.01Z" transform="translate(-222.847 -118.009)" fill="#101010" />
                        <path id="Path_235206" data-name="Path 235206" d="M239.811,155.847c-7.625.052-13.881-5.627-14.249-12.8a5.363,5.363,0,0,0-.011.578c.543,7.878,7.729,12.547,14.263,12.5,6.856-.047,13.5-5.026,14.089-12.7a4.6,4.6,0,0,0-.019-.578C253.614,150.03,247.437,155.795,239.811,155.847Z" transform="translate(-222.847 -128.409)" fill="#101010" />
                        <path id="Path_235207" data-name="Path 235207" d="M238.711,157.143c-8.4.058-15.283-6.2-15.688-14.089-.008.212-.014.423-.012.637.056,8.192,7.09,14.785,15.71,14.726s15.562-6.749,15.506-14.941c0-.214-.011-.425-.021-.636C253.907,150.738,247.107,157.085,238.711,157.143Z" transform="translate(-221.738 -128.402)" fill="#101010" />
                        <path id="Path_235215" data-name="Path 235215" d="M6.164,14.727a.653.653,0,0,1-.347-.084,1.134,1.134,0,0,1-.263-.231L.484,8.5a2.686,2.686,0,0,1-.189-.242.618.618,0,0,1-.063-.326V7.7a.455.455,0,0,1,.126-.337.455.455,0,0,1,.337-.126H1.957a5.794,5.794,0,0,0,2.43-.4A2.243,2.243,0,0,0,5.523,5.765,3.63,3.63,0,0,0,5.828,4.25a3.63,3.63,0,0,0-.305-1.515A2.26,2.26,0,0,0,4.429,1.662a5,5,0,0,0-2.22-.4H.463a.455.455,0,0,1-.337-.126A.455.455,0,0,1,0,.8V.463A.455.455,0,0,1,.126.126.455.455,0,0,1,.463,0H8.584a.455.455,0,0,1,.337.126.455.455,0,0,1,.126.337V.8a.455.455,0,0,1-.126.337.455.455,0,0,1-.337.126H5.512L4.5.673A2.879,2.879,0,0,1,6.585,1.946a4.507,4.507,0,0,1,.568,2.3A4.631,4.631,0,0,1,6.69,6.312,3.425,3.425,0,0,1,5.165,7.827a6.016,6.016,0,0,1-2.83.568H1.809l-.147-.547,5.365,6.27a.424.424,0,0,1,.147.295.258.258,0,0,1-.126.242.574.574,0,0,1-.295.074Zm-5.7-10.1A.455.455,0,0,1,.126,4.5.455.455,0,0,1,0,4.166V3.829a.455.455,0,0,1,.126-.337.455.455,0,0,1,.337-.126H8.584a.434.434,0,0,1,.463.463v.337a.434.434,0,0,1-.463.463Z" transform="translate(12.352 8.152)" fill="#131100" />
                    </g>
                </g>
            </g>
        </svg>

    );
}