import { Grid } from '@mui/material'
import { Box } from '@mui/system';
import CustomTypography from '../../../shared/customTypography';
import { MPrUrlParams } from 'packages/web/src/classes/MPrUrlParams';
import { ComponentType, ReactNode, useState } from 'react';
import { DashboardItemContainer } from '../../shared';
import { GraphLoader } from '../../../shared';
import { TableSearchBar } from '../../../shared/table-search-bar';
import useAutoScrollTop from '../../../hooks/useAutoScrollTop';

interface HoldingLayoutProps {
  MPrUrlParams: MPrUrlParams;
  Screen_AssetAllocationChart: ComponentType<any>;
  Screen_CurrentValueCard?: ComponentType<any>;
  Screen_AmountInvestedCard?: ComponentType<any>;
  Screen_UnrealisedGainCard?: ComponentType<any>;
  Screen_Table: ComponentType<any>;
  isLoading: boolean;
  tableHeading: string;
  Screen_AppTour?: ComponentType<any>;
}

export default function HoldingLayout({ MPrUrlParams, Screen_CurrentValueCard, Screen_AmountInvestedCard, Screen_UnrealisedGainCard, Screen_AssetAllocationChart, Screen_Table, isLoading, tableHeading, Screen_AppTour }: HoldingLayoutProps) {
  useAutoScrollTop();

  const Screen_AppTourFinal = Screen_AppTour || (({children}: {children: ReactNode}) => (
    <>
      {children}
    </>
  ));

  if (isLoading) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', background: '#FFFFFF', height: '100%' }} >
      <GraphLoader />
    </Box >
  }

  var showTotalsSection = (Screen_CurrentValueCard && Screen_AmountInvestedCard && Screen_UnrealisedGainCard) !== undefined;
  return (
    <Screen_AppTourFinal>
    <Box padding="0px 40px">
      <Grid container spacing={{ xs: 2, lg: 3 }} sx={{ marginBottom: { xs: "16px", lg: "60px" } }}>
        <Grid item xs={12} md={showTotalsSection ? 6 : 12}>
          <DashboardItemContainer className='apptour-holding-assetallocation'>
            <Screen_AssetAllocationChart AssetType={MPrUrlParams?.AssetType} />
          </DashboardItemContainer>
        </Grid>
        {
          Screen_CurrentValueCard && Screen_AmountInvestedCard && Screen_UnrealisedGainCard &&
          <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} className='apptour-holding-keymetrics'>
            <Grid container spacing={{ xs: 2, lg: 3 }} sx={{ marginBottom: { xs: "16px", lg: "24px" } }}>
              <Grid item xs={6}>
                <Screen_AmountInvestedCard />
              </Grid>
              <Grid item xs={6}>
                <Screen_CurrentValueCard />
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 2, lg: 3 }}>
              <Grid item xs={12}>
                <Screen_UnrealisedGainCard />
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
      <TableSection Screen_Table={Screen_Table} MPrUrlParams={MPrUrlParams} tableHeading={tableHeading}/>
    </Box>
    </Screen_AppTourFinal>
  )
}

const TableSection = ({Screen_Table, MPrUrlParams, tableHeading}: {Screen_Table: ComponentType<any>, MPrUrlParams: MPrUrlParams, tableHeading: string}) => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <Grid container spacing={{ xs: 2, lg: 3 }} className='apptour-holding-table'>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
        <CustomTypography variant="Medium28">{tableHeading}</CustomTypography>
        <Box width="408px">
          {/* <SearchBar searchOptions={[]} /> */}
          <TableSearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
        </Box>
      </Grid>
      <Grid item xs={12} marginBottom={"40px"}>
        <DashboardItemContainer>
          <Screen_Table AssetType={MPrUrlParams?.AssetType} searchTerm={searchTerm}/>
        </DashboardItemContainer>
      </Grid>
    </Grid>
  )
}
