import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd";
import { Box } from '@mui/system'
import styles from './index.module.css'
import TableListItem from './TableList'
import Big from 'big.js'
import { SharedConstants } from "@mprofit/shared";


interface draggableListProps {
    list: SharedConstants.WatchlistCardItem[],
    onDragEnd: (result: DropResult) => void,
    // handleSelectedValue: (value: watchListDataProp) => void,
    // handleRemoveSelectedValue: (value: watchListDataProp) => void,
}


const DraggableList = ({ list, onDragEnd }: draggableListProps) => {    
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='watchListArea'>
                {
                    (provided) => (
                        <Box sx={{ overflowY: "scroll", marginBottom: '15px' }}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={styles.watchListContainer}>
                            {
                                list && list.map((item, index) => (
                                    <Draggable key={`${item.MProfitCode}`} draggableId={`${item.MProfitCode}`} index={index}>
                                        {(provided) => (
                                            <div
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                <TableListItem
                                                    item={item}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                            }
                            {provided.placeholder}
                        </Box>
                    )
                }
            </Droppable>
        </DragDropContext>
    )
}


export default DraggableList