
export function PerformanceIcon(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
            <g id="Group_154300" data-name="Group 154300" transform="translate(-915.043 -138.043)">
                <rect id="Rectangle_24810" data-name="Rectangle 24810" width="64" height="64" transform="translate(915.043 138.043)" fill="#fff" opacity="0" />
                <g id="Group_152830" data-name="Group 152830" transform="translate(920.039 141.034)">
                    <g id="Group_154190" data-name="Group 154190" transform="translate(30.347 31.609)">
                        <g id="Group_154189" data-name="Group 154189" transform="translate(0.166 0)">
                            <path id="Path_206164" data-name="Path 206164" d="M414.693,923.9H406.15a.18.18,0,0,1-.117-.041.132.132,0,0,1-.048-.1v-26.6a.131.131,0,0,1,.048-.1.18.18,0,0,1,.117-.041h8.542a.181.181,0,0,1,.117.041.129.129,0,0,1,.048.1v26.6a.131.131,0,0,1-.048.1A.181.181,0,0,1,414.693,923.9Zm-8.377-.28h8.211V897.3h-8.211Z" transform="translate(-405.186 -897.018)" fill="#acc18a" />
                            <path id="Path_206165" data-name="Path 206165" d="M401.061,916.871h-8.542v25.772h8.542Z" transform="translate(-392.518 -915.867)" fill="#485d3e" />
                        </g>
                        <path id="Path_206166" data-name="Path 206166" d="M398.449,940.146h-8.542a.179.179,0,0,1-.117-.04.131.131,0,0,1-.049-.1V914.234a.133.133,0,0,1,.049-.1.185.185,0,0,1,.117-.041h8.542a.185.185,0,0,1,.117.041.134.134,0,0,1,.048.1v25.772a.132.132,0,0,1-.048.1A.179.179,0,0,1,398.449,940.146Zm-8.377-.28h8.211V914.375h-8.211Z" transform="translate(-389.741 -913.23)" fill="#485d3e" />
                    </g>
                    <g id="Group_154192" data-name="Group 154192" transform="translate(44.697 15.432)">
                        <g id="Group_154189-2" data-name="Group 154189" transform="translate(0.165 0)">
                            <path id="Path_206164-2" data-name="Path 206164" d="M414.693,939.87H406.15a.146.146,0,0,1-.117-.065.273.273,0,0,1-.048-.159v-42.4a.272.272,0,0,1,.048-.158.146.146,0,0,1,.117-.065h8.542a.147.147,0,0,1,.117.065.269.269,0,0,1,.048.158v42.4a.27.27,0,0,1-.048.159A.147.147,0,0,1,414.693,939.87Zm-8.377-.447h8.211V897.466h-8.211Z" transform="translate(-405.186 -897.018)" fill="#acc18a" />
                            <path id="Path_206165-2" data-name="Path 206165" d="M401.061,916.871h-8.542v41.08h8.542Z" transform="translate(-392.518 -915.271)" fill="#5f854c" />
                        </g>
                        <path id="Path_206166-2" data-name="Path 206166" d="M398.449,955.622h-8.542a.146.146,0,0,1-.117-.065.269.269,0,0,1-.049-.158v-41.08a.263.263,0,0,1,.049-.158.145.145,0,0,1,.117-.066h8.542a.145.145,0,0,1,.117.066.266.266,0,0,1,.048.158V955.4a.272.272,0,0,1-.048.158A.146.146,0,0,1,398.449,955.622Zm-8.377-.447h8.211V914.541h-8.211Z" transform="translate(-389.741 -912.718)" fill="#5f854c" />
                    </g>
                    <path id="Path_206168" data-name="Path 206168" d="M623.167,1078.19h-8.542v22.722h8.542Z" transform="translate(-611.261 -1042.554)" fill="#acc18a" />
                    <path id="Path_234920" data-name="Path 234920" d="M623.167,1078.19h-8.542v22.722h8.542Z" transform="translate(-611.261 -1042.554)" fill="none" />
                    <path id="Path_206169" data-name="Path 206169" d="M636.074,771.717h-8.543a.182.182,0,0,1-.117-.041.13.13,0,0,1-.048-.1V737.445a.129.129,0,0,1,.048-.1.18.18,0,0,1,.117-.042h8.543a.179.179,0,0,1,.117.042.129.129,0,0,1,.048.1v34.131a.13.13,0,0,1-.048.1A.181.181,0,0,1,636.074,771.717Zm-8.377-.282h8.212V737.586H627.7Z" transform="translate(-609.825 -714.101)" fill="#acc18a" />
                    <path id="Path_206170" data-name="Path 206170" d="M845.272,756.268H836.73v33.994h8.542Z" transform="translate(-820.149 -731.937)" fill="#5f854c" />
                    <path id="Path_206171" data-name="Path 206171" d="M842.661,787.789h-8.542a.18.18,0,0,1-.117-.042.13.13,0,0,1-.049-.1V753.6a.132.132,0,0,1,.049-.1.185.185,0,0,1,.117-.041h8.542a.185.185,0,0,1,.117.041.134.134,0,0,1,.048.1v34.043a.132.132,0,0,1-.048.1A.18.18,0,0,1,842.661,787.789Zm-8.377-.282H842.5V753.746h-8.212Z" transform="translate(-817.537 -729.3)" fill="#5f854c" />
                    <g id="Group_154193" data-name="Group 154193" transform="translate(0 0)">
                        <path id="Path_234923" data-name="Path 234923" d="M1.386,0A1.386,1.386,0,1,1,0,1.386,1.386,1.386,0,0,1,1.386,0Z" transform="translate(0 27.73)" fill="#3a3d4d" />
                        <path id="Path_234922" data-name="Path 234922" d="M1.386,0A1.386,1.386,0,1,1,0,1.386,1.386,1.386,0,0,1,1.386,0Z" transform="translate(18.487 12.016)" fill="#3a3d4d" />
                        <path id="Path_234921" data-name="Path 234921" d="M1.386,0A1.386,1.386,0,1,1,0,1.386,1.386,1.386,0,0,1,1.386,0Z" transform="translate(34.2 18.487)" fill="#3a3d4d" />
                        <path id="Path_234924" data-name="Path 234924" d="M1.386,0A1.386,1.386,0,1,1,0,1.386,1.386,1.386,0,0,1,1.386,0Z" transform="translate(51.762 0)" fill="#3a3d4d" />
                        <path id="Path_234925" data-name="Path 234925" d="M1.662,29.7l-.47-.521L19.434,12.745l15.981,6.986L52.644,1.146l.515.477L35.591,20.573l-16.023-7Z" transform="translate(0.003 0.003)" fill="#3a3d4d" />
                    </g>
                </g>
            </g>
        </svg>

    );
}