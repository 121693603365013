export function PerformanceSummary(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="136.151" height="146.112" viewBox="0 0 136.151 146.112">
            <g id="Group_152830" data-name="Group 152830" style={{ mixBlendMode: 'luminosity', isolation: 'isolate' }}>
                <g id="Group_154190" data-name="Group 154190" transform="translate(75.763 78.914)">
                    <g id="Group_154189" data-name="Group 154189" transform="translate(0.414 0)">
                        <path id="Path_206164" data-name="Path 206164" d="M427.724,964.135H406.4a.452.452,0,0,1-.292-.1.326.326,0,0,1-.121-.249V897.368a.327.327,0,0,1,.121-.248.453.453,0,0,1,.292-.1h21.326a.452.452,0,0,1,.292.1.326.326,0,0,1,.121.248v66.416a.326.326,0,0,1-.121.249A.452.452,0,0,1,427.724,964.135Zm-20.914-.7h20.5V897.718h-20.5Z" transform="translate(-403.988 -897.017)" fill="#acc18a" />
                        <path id="Path_206165" data-name="Path 206165" d="M413.845,916.871H392.518v64.342h21.327Z" transform="translate(-392.518 -914.365)" fill="#485d3e" />
                    </g>
                    <path id="Path_206166" data-name="Path 206166" d="M411.48,979.137H390.153a.452.452,0,0,1-.292-.1.326.326,0,0,1-.121-.249V914.444a.326.326,0,0,1,.121-.248.453.453,0,0,1,.292-.1H411.48a.454.454,0,0,1,.292.1.327.327,0,0,1,.121.248v64.342a.327.327,0,0,1-.121.249A.453.453,0,0,1,411.48,979.137Zm-20.914-.7h20.5V914.795h-20.5Z" transform="translate(-389.741 -911.939)" fill="#485d3e" />
                </g>
                <g id="Group_154192" data-name="Group 154192" transform="translate(111.589 38.527)">
                    <g id="Group_154189-2" data-name="Group 154189" transform="translate(0.413 0)">
                        <path id="Path_206164-2" data-name="Path 206164" d="M427.724,1004H406.4a.367.367,0,0,1-.292-.163.674.674,0,0,1-.121-.4V897.576a.671.671,0,0,1,.121-.395.367.367,0,0,1,.292-.164h21.326a.367.367,0,0,1,.292.164.671.671,0,0,1,.121.395v105.866a.674.674,0,0,1-.121.4A.367.367,0,0,1,427.724,1004Zm-20.914-1.116h20.5V898.135h-20.5Z" transform="translate(-403.988 -897.017)" fill="#acc18a" />
                        <path id="Path_206165-2" data-name="Path 206165" d="M413.845,916.871H392.518V1019.43h21.327Z" transform="translate(-392.518 -912.876)" fill="#5f854c" />
                    </g>
                    <path id="Path_206166-2" data-name="Path 206166" d="M411.48,1017.771H390.153a.367.367,0,0,1-.292-.163.672.672,0,0,1-.121-.4V914.653a.671.671,0,0,1,.121-.4.369.369,0,0,1,.292-.163H411.48a.369.369,0,0,1,.292.163.674.674,0,0,1,.121.4v102.56a.675.675,0,0,1-.121.4A.367.367,0,0,1,411.48,1017.771Zm-20.914-1.116h20.5V915.211h-20.5Z" transform="translate(-389.741 -910.66)" fill="#5f854c" />
                </g>
                <path id="Path_206168" data-name="Path 206168" d="M635.95,1078.19H614.624v56.727H635.95Z" transform="translate(-606.229 -989.22)" fill="#acc18a" />
                <path id="Path_234920" data-name="Path 234920" d="M635.95,1078.19H614.624v56.727H635.95Z" transform="translate(-606.229 -989.22)" fill="none" />
                <path id="Path_206169" data-name="Path 206169" d="M649.105,823.22H627.779a.451.451,0,0,1-.292-.1.33.33,0,0,1-.121-.251V737.656a.329.329,0,0,1,.121-.249.45.45,0,0,1,.292-.1h21.327a.452.452,0,0,1,.292.1.329.329,0,0,1,.12.249v85.211a.33.33,0,0,1-.12.251A.452.452,0,0,1,649.105,823.22Zm-20.914-.705h20.5V738.009h-20.5Z" transform="translate(-583.573 -679.379)" fill="#acc18a" />
                <path id="Path_206170" data-name="Path 206170" d="M858.056,756.268H836.729v84.869h21.327Z" transform="translate(-795.335 -695.524)" fill="#5f854c" />
                <path id="Path_206171" data-name="Path 206171" d="M855.692,839.158H834.366a.452.452,0,0,1-.292-.1.33.33,0,0,1-.121-.249v-84.99a.327.327,0,0,1,.121-.249.452.452,0,0,1,.292-.1h21.327a.451.451,0,0,1,.292.1.327.327,0,0,1,.121.249v84.99a.33.33,0,0,1-.121.249A.45.45,0,0,1,855.692,839.158Zm-20.914-.7h20.5V754.168h-20.5Z" transform="translate(-792.97 -693.138)" fill="#5f854c" />
                <g id="Group_154193" data-name="Group 154193" transform="translate(0 0)">
                    <path id="Path_234923" data-name="Path 234923" d="M3.461,0A3.461,3.461,0,1,1,0,3.461,3.461,3.461,0,0,1,3.461,0Z" transform="translate(0 69.229)" fill="#3a3d4d" />
                    <path id="Path_234922" data-name="Path 234922" d="M3.461,0A3.461,3.461,0,1,1,0,3.461,3.461,3.461,0,0,1,3.461,0Z" transform="translate(46.153 29.999)" fill="#3a3d4d" />
                    <path id="Path_234921" data-name="Path 234921" d="M3.461,0A3.461,3.461,0,1,1,0,3.461,3.461,3.461,0,0,1,3.461,0Z" transform="translate(85.383 46.153)" fill="#3a3d4d" />
                    <path id="Path_234924" data-name="Path 234924" d="M3.461,0A3.461,3.461,0,1,1,0,3.461,3.461,3.461,0,0,1,3.461,0Z" transform="translate(129.228 0)" fill="#3a3d4d" />
                    <path id="Path_234925" data-name="Path 234925" d="M2.365,72.431l-1.173-1.3L46.734,30.1l39.9,17.441,43.014-46.4,1.285,1.191L87.07,49.648l-40-17.487Z" transform="translate(1.793 1.722)" fill="#3a3d4d" />
                </g>
            </g>
        </svg>
    );
}