import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { DownloadIcon } from 'packages/web/src/assets/icons/downloadIcon'
import { ReportDocumentIcon } from 'packages/web/src/assets/icons/reportDocumentIcon'
import CustomTypography from '../../../shared/customTypography'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { EyeVisiblityIcon } from 'packages/web/src/assets/icons/eyeVisiblityIcon'


const data = [{
  reportName: 'Schedule 112A - Capital Gains ITR Format Report',
  date: '14 Sep 2021 - 17 Nov 2022',
  personName: 'Chandraprakash Parthasarathy',
  familyName: 'Parthasarathy family',
  createdDate: '17 Nov 2022',
  isDownloadable: true
},
{
  reportName: 'Portfolio Summary',
  date: '14 Sep 2021 - 17 Nov 2022',
  personName: 'Rahul KC',
  familyName: 'Kumar family',
  createdDate: '19 dec 2022',
  isDownloadable: false
},
{
  reportName: 'Schedule 112A - Capital Gains ITR Format Report',
  date: '14 Sep 2021 - 17 Nov 2022',
  personName: 'Chandraprakash Parthasarathy',
  familyName: 'Parthasarathy family',
  createdDate: '17 Nov 2022',
  isDownloadable: true
},
{
  reportName: 'Portfolio Summary',
  date: '14 Sep 2021 - 17 Nov 2022',
  personName: 'Rahul KC',
  familyName: 'Kumar family',
  createdDate: '19 dec 2022',
  isDownloadable: false
}
]
export default function ReportLogTable() {

  return (
    <TableContainer>
      <Table sx={{ borderRadius: '10px', borderSpacing: '0px 8px', borderCollapse: 'separate' }}>
        <TableBody>
          {
            data.map((row, index) => (
              <TableRow sx={style.tableRows} key={index}>
                <TableCell sx={{ border: '2px solid #D8DAE5', borderRight: 'none', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}><ReportDocumentIcon /></TableCell>
                <TableCell align="left" sx={{ border: '2px solid #D8DAE5', borderRight: 'none', borderLeft: 'none' }}>
                  <CustomTypography variant='Regular20'>{row.reportName}</CustomTypography>
                  <CustomTypography variant='Regular18' sx={style.year}> FY {row.date}</CustomTypography>
                </TableCell>
                <TableCell align="left" sx={{ border: '2px solid #D8DAE5', borderRight: 'none', borderLeft: 'none' }}>
                  <Box sx={style.summaryContainer}>
                    <CustomTypography variant="Regular18">{row.personName}</CustomTypography>
                    <CustomTypography variant="Regular18" sx={style.summarySubtitle}>{row.familyName}</CustomTypography>
                  </Box>
                </TableCell>
                <TableCell align="right" sx={{ border: '2px solid #D8DAE5', borderRight: 'none', borderLeft: 'none' }}>
                  <CustomTypography variant='Regular18'>{row.createdDate}</CustomTypography>
                  <CustomTypography variant='Regular18' sx={{ color: { xs: "#64677A" } }}>Created on</CustomTypography>
                </TableCell>
                <TableCell align="right" sx={{ border: '2px solid #D8DAE5', borderLeft: 'none', borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }}>
                  <Box>
                    {
                      row.isDownloadable ?
                        <DownloadIcon />
                        :
                        <EyeVisiblityIcon />
                    }
                  </Box>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
const style = {
  tableRows: {
    // '&:last-child td, &:last-child th': { border: 0 },
    background: '#FFFFFF',
    border: '2px solid #D8DAE5',
    borderRadius: '4px',
    margin: '10px'
  },
  reportType: {
    color: { xs: '#64677A' },
  },
  year: {
    textTransform: 'uppercase',
    color: { xs: '#64677A' },
  },
  summarySubtitle: {
    color: { xs: '#64677A' },
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
}