import { AMIDAssetTotal, AMIDAssetTotal_Big, AssetTotal, AssetTypeEnum, AssetsAddInfo, AssetsByAssetType, MFETFPortfolioBreakdown, MFPortfolioBreakdown, Stocks_Sub_Category_Type } from "../constants";
import { addToAssetTotal, getAMIDAssetByAMID, getAMIDAssetTotals } from "./portfolioSummary";
import Big from "big.js";

export const getStocksAssetAllocationIncludingMFETFs =
    (
        PFID: number,
        assetsByAssetType: AssetsByAssetType,
        mfETFPortfolioBreakdown?: MFETFPortfolioBreakdown,
        assetsAddInfo?: AssetsAddInfo
    ) => {
    if (!assetsByAssetType || !mfETFPortfolioBreakdown || !assetsAddInfo || !assetsAddInfo.Stocks) return [];

    var stocksNameDict: { [AMID: number]: string } = {};

    mfETFPortfolioBreakdown.StocksNames?.forEach((stocksNamesInfo) => {
        stocksNameDict[stocksNamesInfo.MProfitCode] = stocksNamesInfo.CompanyName;
    })

    var stocksByAMID: {[AMID: number]: AMIDAssetTotal_Big} = {};
    var MFsByAMID: {[AMID: number]: AMIDAssetTotal_Big} = {};
    for (var key in assetsByAssetType) {
        var assetTypeID = +key;
        switch (assetTypeID) {
            case AssetTypeEnum.EQ:
                stocksByAMID = getAMIDAssetByAMID(assetsByAssetType[assetTypeID]);
                break;
            case AssetTypeEnum.MFEQ:
                MFsByAMID = getAMIDAssetByAMID(assetsByAssetType[assetTypeID]);
                break;
        }
    }

    mfETFPortfolioBreakdown.MF?.forEach((mfPortfolioBreakdown) => {
        var MFETFAsset = MFsByAMID[mfPortfolioBreakdown.MProfitCode];
        if (MFETFAsset) {
            addMFETFBreakdownToStocksTotals(MFETFAsset, mfPortfolioBreakdown, PFID, assetTypeID, stocksByAMID, stocksNameDict);
        }
    })

    mfETFPortfolioBreakdown.ETF?.forEach((mfPortfolioBreakdown) => {
        var MFETFAsset = stocksByAMID[mfPortfolioBreakdown.MProfitCode];
        if (MFETFAsset) {
            addMFETFBreakdownToStocksTotals(MFETFAsset, mfPortfolioBreakdown, PFID, assetTypeID, stocksByAMID, stocksNameDict);
        }
    })

    var amidAssetList: AMIDAssetTotal[] = [];

    for (var AMIDStr in stocksByAMID) {
        var AMID = +AMIDStr;
        var addInfo = assetsAddInfo.Stocks[AMID];
        if (addInfo && addInfo.SubCategoryType === Stocks_Sub_Category_Type.ETF) {
            continue;
        }
        var amidAsset = stocksByAMID[AMID];
        if (amidAsset) {
            var amidAssetTotal: AMIDAssetTotal = {
                PFID: amidAsset.PFID,
                AMID: amidAsset.AMID,
                AssetTypeID: amidAsset.AssetTypeID,
                Name: amidAsset.Name,
                CurrValue: amidAsset.CurrValue ? new Big(amidAsset.CurrValue).toJSON(): '',
            };
            amidAssetList.push(amidAssetTotal);
        }
    }

    return amidAssetList;
}

const getNewAMIDAssetTotalForMFPortBreakdown = (PFID: number, AssetTypeID: number, AMID: number, stocksNameDict: { [AMID: number]: string }): AMIDAssetTotal_Big => {
    return {
        CurrValue: new Big(0),
        PFID,
        AMID,
        AssetTypeID,
        Name: stocksNameDict[AMID]
    };
}

const addMFETFBreakdownToStocksTotals = (MFETFAsset: AMIDAssetTotal_Big, mfPortfolioBreakdown: MFPortfolioBreakdown, PFID: number, assetTypeID: number, stocksByAMID: {[AMID: number]: AMIDAssetTotal_Big}, stocksNameDict: { [AMID: number]: string }) => {
    mfPortfolioBreakdown.AssetTypes.forEach((assetTypeBreakdown) => {
        if (assetTypeBreakdown.AssetTypeID === AssetTypeEnum.EQ) {
            console.log("🚀 ~ file: mfPortfolioBreakdown.ts:92 ~ mfPortfolioBreakdown.AssetTypes.forEach ~ assetTypeBreakdown:", assetTypeBreakdown)
            assetTypeBreakdown.Assets?.forEach((assetBreakdown) => {
                var stockAsset = stocksByAMID[assetBreakdown.MProfitCode];
                if (!stockAsset) {
                    stockAsset = getNewAMIDAssetTotalForMFPortBreakdown(PFID, assetTypeID, assetBreakdown.MProfitCode, stocksNameDict);
                }

                if (MFETFAsset) {
                    stockAsset.CurrValue = (stockAsset.CurrValue || new Big(0)).plus(Big(MFETFAsset.CurrValue || 0).times(assetBreakdown.PercentageOfAUM).div(100));
                }

                stocksByAMID[assetBreakdown.MProfitCode] = stockAsset;
            })
        }
    })
}