export function CaretDownIcon(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g id="Group_150755" data-name="Group 150755" transform="translate(-0.225)">
                <rect id="Rectangle_24197" data-name="Rectangle 24197" width="40" height="40" transform="translate(0.225)" fill="#fff" opacity="0" />
                <path id="Path_198444" data-name="Path 198444" d="M16.5,8.25l-6,6-6-6" transform="translate(10.225 9.25)" fill="none" stroke="#64677a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
        </svg>

    );
}
