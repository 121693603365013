import { Box, Divider, FormControl, FormControlLabel, Grid, InputBase, Link, Popover, Radio, RadioGroup } from '@mui/material'
import React, { useState } from 'react'
import CustomTypography from '../../../shared/customTypography'
import DropDown from '../../../shared/drop-down'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SecondaryIconButton from '../../../shared/secondary-icon-button';
import { AscendingOrderIcon } from 'packages/web/src/assets/icons/ascendingorderIcon';
import SwitchButton from '../../../shared/switch-button';
import CheckButton from '../../../shared/check-box';
import InputField from '../../../shared/InputField';
import { ErrorIcon } from 'packages/web/src/assets/icons/errorIcon';
import { PrintIcon } from 'packages/web/src/assets/icons/PrintIcon';
import { PDFIcon } from 'packages/web/src/assets/icons/PDFIcon';
import { XLSIcon } from 'packages/web/src/assets/icons/XLSIcon';
import { TiltedArrow } from 'packages/web/src/assets/icons/TiltedArrow';
import CustomModal from '../../../shared/custom-modal';
// import ViewSampleReport from '../view-sample-report';
import TooltipDark from '../../../shared/tooltip-dark';
import { SharedRedux, SharedUtilities } from '@mprofit/shared';
import { HttpStatusCode } from 'axios';
import { saveAs } from 'file-saver';
import PeriodSelector from '../../../shared/period-selector';

export default function PortfolioSummary({ summaryData }: any) {
  const [openModal, setOpenModal] = React.useState(false);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false);
  const [openReport, setOpenReport] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleDropdown = () => {

  }

  const handlePopOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const closePopOver = () => {
    setAnchorEl(null);
  }

  const handleDownloadFiles = (type: string) => {
    SharedRedux.Reports.APIs.downloadBackgroundReportAPI({ JobId: 1, IsExcel: type === "xls" ? true : false }).then((response) => {
      console.log(response, 'downloadBackgroundReport');
      if (response.status === HttpStatusCode.Ok) {
        saveAs(response.data, SharedUtilities.getFileNameFromHttpResponse(response) || 'report.pdf');
      }
    })
  }

  return (
    <Grid container sx={style.mainContainer} spacing={2}>
      <Grid item xs={5} height="100%">
        <Box width='98%' height='100%' position='relative'>

          <Box className="img-container" sx={{ position: 'relative', height: '100%' }}>
            <img src={summaryData.PreviewImage} width='100%' height='100%' alt='sample-portfolio' />

            <Box onClick={() => setOpenReport(true)} sx={style.sampleReportCont}>
              <CustomTypography variant='Regular16' sx={{ color: { xs: '#ffffff' }, paddingRight: '10px' }}>
                View Sample Report
              </CustomTypography>
              <TiltedArrow />
            </Box>

            <Box sx={{ width: '100%', position: 'absolute', display: 'flex', justifyContent: 'center', bottom: '0' }}>
              <Box sx={{ borderRadius: '8px', height: '64px', background: '#3A3D4D', display: 'flex', alignItems: 'center' }}>
                <TooltipDark title='Print'>
                  <Link href={summaryData.PreviewPdfUrl} target={'_blank'} underline={'none'}>
                    <Box sx={{ cursor: 'pointer', padding: '0px 5px' }}>
                      <PrintIcon />
                    </Box>
                  </Link>
                </TooltipDark>

                <TooltipDark title="Download PDF">
                  <Box sx={{ cursor: 'pointer', padding: '0px 5px' }}>
                    <PDFIcon onClick={() => handleDownloadFiles("pdf")} />
                  </Box>
                </TooltipDark>

                <TooltipDark title="Download excel sheet">
                  <Box sx={{ cursor: 'pointer', padding: '0px 5px' }}>
                    <XLSIcon onClick={() => handleDownloadFiles("xls")} />
                  </Box>
                </TooltipDark>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* <CustomModal open={openReport} handleClose={() => setOpenReport(!openReport)}>
          <ViewSampleReport pdfPreviewLink={summaryData.PreviewPdfUrl} xlsPreviewLink={summaryData.PreviewExcelUrl} setOpenReport={setOpenReport} />
        </CustomModal> */}

      </Grid>
      <Grid item xs={7} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ flex: '0 0 36px' }}>
          <CustomTypography sx={{ paddingBottom: '16px' }} variant='Medium24'>{summaryData.ReportName}</CustomTypography>
        </Box>
        <Box
          sx={{
            flex: '1 1 auto',
            minHeight: '0px',
            overflow: 'auto',
            "&::-webkit-scrollbar": {
              width: 1,
            }
          }}
        >
          <Box>
            {
              Array.isArray(summaryData.Description) ? summaryData.Description.map((item: any, index: number) => (
                <CustomTypography key={index} variant='Regular18' sx={{ paddingBottom: '24px', color: { xs: "#64677A" } }}>{item}</CustomTypography>
              ))
                :
                <CustomTypography variant='Regular18' sx={{ paddingBottom: '24px', color: { xs: "#64677A" } }}>{summaryData.Description}</CustomTypography>
            }
            <Box width="70%">
              <FormControl>
                <RadioGroup row>
                  {
                    summaryData.AssetTypes && summaryData.AssetTypes.map((label: any, index: number) => (
                      <Box key={index} sx={{ border: '1px solid #D8DAE5', margin: '0px 16px 16px 0px', padding: '0px 12px', borderRadius: '4px' }} >
                        <FormControlLabel
                          id={label.id}
                          value={label.text}
                          control={<Radio />}
                          label={label.text}
                        />
                      </Box>
                    ))
                  }
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <CustomTypography>TIME PERIOD</CustomTypography>

          <Box sx={style.timePeriodButton} aria-describedby={id} onClick={handlePopOver} >
            <InputBase
              placeholder="FY22-23"
              value={"FY22-23"}
              inputProps={{ 'aria-label': 'search' }}
            />
            <KeyboardArrowDownIcon />
          </Box>
          <br />
          <Box sx={style.additionalContainer} >
            <Box sx={style.dropdownBox}>
              <CustomTypography variant='Regular18' sx={{ color: { xs: '#64677A' } }}>Additional customization</CustomTypography>
              <Box sx={{ cursor: 'pointer' }}>
                {
                  showAdditionalInfo ?
                    <KeyboardArrowDownIcon style={{ transform: 'rotate(180deg)' }} onClick={() => setShowAdditionalInfo(prevState => !prevState)} />
                    :
                    <KeyboardArrowDownIcon onClick={() => setShowAdditionalInfo(prevState => !prevState)} />
                }
              </Box>
            </Box>
            {
              showAdditionalInfo &&
              <Box>
                <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A', padding: '24px 0px 16px 0px' } }}>Default sort</CustomTypography>
                <Box sx={style.sortCont}>
                  <Box paddingRight="10px">
                    <DropDown
                      inputList={[{id: "FY22-23", name: 'FY22-23'}]}
                      inputValue={"FY22-23"}
                      handleChange={handleDropdown}
                    />
                  </Box>

                  <Box paddingRight="10px">
                    <SecondaryIconButton
                      icon={<AscendingOrderIcon />}
                      tooltipText="Ascending"
                      buttonSize='large'
                    />
                  </Box>

                  <Box paddingRight="10px">
                    <SecondaryIconButton
                      icon={<AscendingOrderIcon style={{ transform: 'rotate(180deg)' }} />}
                      tooltipText="Ascending"
                      buttonSize='large'
                    />
                  </Box>
                </Box>

                <Box width="70%">
                  <FormControl>
                    <RadioGroup row>
                      {
                        ["All", "Intraday", "Short term", "Long term"].map((label, index) => (
                          <Box key={index} sx={{ border: '1px solid #D8DAE5', margin: '0px 16px 16px 0px', padding: '0px 12px', borderRadius: '4px' }} >
                            <FormControlLabel
                              value={label}
                              control={<Radio />}
                              label={label}
                            />
                          </Box>
                        ))
                      }
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box margin="24px 0px">
                  <Divider />
                </Box>
                <Grid container>
                  <Grid item xs={6}>
                    <SwitchButton label="With grandfathering" />
                  </Grid>
                  <Grid item xs={6}>
                    <SwitchButton label="Show portfolio address" />
                  </Grid>
                  <Grid item xs={6}>
                    <SwitchButton label="Only buyback gain/loss after Jul 5, 2019" />
                  </Grid>
                  <Grid item xs={6}>
                    <SwitchButton label="Show ISIN no." />
                  </Grid>
                </Grid>
              </Box>
            }
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 0px' }}>
            <CheckButton />
            <CustomTypography variant="Regular18">Save report with custom settings</CustomTypography>
          </Box>

          <Box sx={{ width: '50%', paddingLeft: '45px' }}>
            <InputField placeholder='Name of report' />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '45px', paddingTop: '10px' }}>
            <ErrorIcon />
            <CustomTypography variant='Regular16' paddingLeft="10px">A report with this name already exists. Please choose a different name.</CustomTypography>
          </Box>
        </Box>
      </Grid>


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopOver}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <PeriodSelector closePopOver={closePopOver} />
      </Popover>
    </Grid >
  )
}

const style = {
  mainContainer: {
    height: '100%',
  },
  additionalContainer: {
    border: '1px solid #D8DAE5',
    borderRadius: '4px',
    padding: '21px 24px'
  },
  dropdownBox: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
  },
  sortCont: {
    display: 'flex',
    padding: '12px 0px'
  },
  sampleReportCont: {
    background: '#3A3D4D',
    borderRadius: '4px',
    padding: '6px 16px',
    display: 'flex',
    cursor: 'pointer',
    height: '32px',
    width: 'fit-content',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  },
  timePeriodButton: {
    height: '48px',
    border: '0.5px solid #D8DAE5',
    padding: '2px 16px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    width: "fit-content"
  }
}