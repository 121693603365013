import { IconButton, Tooltip } from '@mui/material'

interface secondaryIconButtonProps {
    icon: JSX.Element,
    disabled?: boolean,
    buttonSize?: string,
    backgroundToggle?: boolean,
    tooltipText: string,
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

function SecondaryIconButton({ icon, disabled, buttonSize, backgroundToggle, tooltipText, onClick }: secondaryIconButtonProps) {

    return (
        <Tooltip
            arrow
            componentsProps={{
                tooltip: {
                    sx: {
                        color: "#FFFFFF",
                        backgroundColor: '#010006',
                    },
                }
            }}
            title={tooltipText}>

            <IconButton
                onClick={onClick}
                disabled={disabled}
                disableRipple
                sx={{
                    padding: 0,
                    borderRadius: "4px",
                    color: '#12131A',
                    background: backgroundToggle ? "#f5f5f6" : "none",
                    height: buttonSize === "large" ? "40px" : "32px",
                    width: '40px',
                    '&:hover': {
                        color: "#12131A",
                        backgroundColor: '#EBECF2',
                        boxShadow: 0,
                        borderRadius: "1px"
                    },
                    "&:disabled": {
                        backgroundColor: '#EBECF2'
                    },
                    "&:focus": {
                        backgroundColor: '#CFE3C9'
                    }
                }}

            >
                {icon}
            </IconButton>
        </Tooltip>
    )
}

export default SecondaryIconButton