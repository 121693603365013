import { Box } from "@mui/material"
import TooltipDark from "../tooltip-dark"
import { InfoIcon } from "../../../assets/icons/InfoIcon"

export const InfoIconTooltip = ({ tooltip, tooltipLines }: { tooltip?: string, tooltipLines?: string[]; }) => {
    return (
        <TooltipDark title={tooltip} tooltipLines={tooltipLines}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <InfoIcon />
            </Box>
        </TooltipDark>

    )
}