import { createAction, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ClientRootState } from "../reducers";
import { ReportMetaData, ReportRequests } from "../../constants";
import { FormSubmissionResponse } from "../../constants/formSubmissionResponse";
import { MProfitAPI } from "../../api";
import { Actions as LoginActions } from './loginReducer';

export interface SharedReportsState {
    Requests: ReportRequests;
}

const initialState: SharedReportsState = {
    Requests: {}
}

const ReportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        // requestReport: (state, action: PayloadAction<ReportRequestPayload>) => { }
    },
    extraReducers: (builder) => {
        builder
            .addCase(LoginActions.logout, () => initialState)
            .addCase(LoginActions.resetData, () => initialState)
    }
});

//#region Payloads

export interface ReportRequestPayload {
    IsGlobal: boolean;
    ReportParams: any;
}

//#endregion

//#region Selectors
const reportsSelector = (state: ClientRootState) => state.shared.reports;

//#endregion

//#region APIs

const createReportsJobAPI = (payload: ReportRequestPayload) => {
    return MProfitAPI.post<FormSubmissionResponse>('/api/Reports/' + (payload.IsGlobal ? 'GlobalJob' : 'Job'), payload.ReportParams);
}

const downloadReportsMetaDataAPI = (payload: { JobId: number }) => {
    return MProfitAPI.get<ReportMetaData>('/api/Jobs/GetDownloadReportMetaData', { params: { jobId: payload.JobId } });
}

const downloadBackgroundReportAPI = (payload: { JobId: number, IsExcel: boolean }) => {
    return MProfitAPI.get('/api/Jobs/DownloadBackgroundReport', { params: { jobId: payload.JobId, isExcel: payload.IsExcel }, responseType: 'blob' });
}

//#endregion

export default ReportsSlice.reducer;
export const Actions = { ...ReportsSlice.actions };
export const APIs = { createReportsJobAPI, downloadReportsMetaDataAPI, downloadBackgroundReportAPI };
export const Selectors = { reportsSelector };