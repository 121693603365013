
export function MagnifyingIcon(props: any) {
  return (
    <svg {...props} id="Group_151561" data-name="Group 151561" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <rect id="Rectangle_24197" data-name="Rectangle 24197" width="40" height="40" fill="#fff" opacity="0" />
      <path id="Path_198587" data-name="Path 198587" d="M23,23l-5.774-5.774m0,0a8.333,8.333,0,1,0-11.785,0A8.333,8.333,0,0,0,17.225,17.225Z" transform="translate(7 7)" fill="none" stroke="#3a3d4d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  );
}

