import { Box, Card } from '@mui/material';
import React, { useState } from 'react'
import { ArrowRight } from '../../../../assets/icons/arrowRight';
import CustomTypography from '../../../shared/customTypography';
import { CategoryIcons } from '../category-icons';
import PerformanceReportModal from '../performance-report-modal';
interface ICardProps {
    CategoryName: string,
    Description: string,
    CategoryId: number
}

export default function ExploreReportCard(props: ICardProps) {
    const [openModal, setOpenModal] = useState(false)

    return (
        <Card sx={styles.card} variant="outlined">
            <Box sx={styles.container}>
                <>{CategoryIcons[props.CategoryId]}</>
                <CustomTypography variant='Regular24' sx={{ marginTop: '24px' }}>{props.CategoryName}</CustomTypography>
                <Box sx={styles.bottomContainer}>
                    <CustomTypography variant='Regular18' sx={{ color: { xs: '#64677A' } }}>{props.Description}</CustomTypography>
                    <Box sx={styles.cardArrow}>
                        <ArrowRight onClick={() => { setOpenModal(!openModal) }} />
                    </Box>
                    {
                        openModal &&
                        <PerformanceReportModal openModal={openModal} setOpenModal={setOpenModal} />
                    }
                </Box>
            </Box>
        </Card>
    )
}
const styles = {
    card: {
        padding: '24px',
        borderRadius: '8px',
        borderColor: '#DFDFDF'
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardArrow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: "#cfe3c9",
            borderRadius: '4px'
        }
    },
    bottomContainer: {
        marginTop: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}
