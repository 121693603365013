import { CompleteTablePage } from "@mprofit-soft/pdf";
import { DownloadPDF, ExtraHeaderProps, ExtraOverviewProps } from "../../download-pdf";
import { ChartsSelectors } from "packages/web/src/redux/selectors/chartsSelectors";
import { usePDFSelector } from "../../download-pdf/redux";
import { SharedConstants } from "@mprofit/shared";
import { Box } from "@mui/material";

const _XIRRTablePDFPage = ({ headerProps, overviewProps, isGroupByPortfolio, rowFieldHeading, rowFieldName, tableMode}: {
        headerProps: ExtraHeaderProps;
        overviewProps: ExtraOverviewProps;
        isGroupByPortfolio: boolean;
        rowFieldHeading?: string;
        rowFieldName: string;
        tableMode: SharedConstants.AdvancedPerformanceTableMode;
    }) => {
    const {tableData, tableRowData, isLoading, columnData, chartDisclaimer, heading } = usePDFSelector(ChartsSelectors.selectXIRRTableData(isGroupByPortfolio, rowFieldHeading, rowFieldName, tableMode));

    if (!tableData || isLoading) {
        return <></>;
    }

    return <CompleteTablePage
        headerProps={{
            ...headerProps,
            reportType: "Performance",
        }}
        overviewProps={{
            ...overviewProps,
            heading
        }}
        data={{
            columnData,
            rowData: tableRowData,
            PMSBadgeField: isGroupByPortfolio ? 'IsCashMgmtPortfolio' : undefined
        }}
        chartDisclaimer={chartDisclaimer}
    />
}

export const XIRRTablePDFPage = (
    isGroupByPortfolio: boolean, rowFieldHeading: string | undefined, rowFieldName: string, tableMode: SharedConstants.AdvancedPerformanceTableMode
) => ({ headerProps, overviewProps }: {
    headerProps: ExtraHeaderProps;
    overviewProps: ExtraOverviewProps;
}) => {
    return <_XIRRTablePDFPage {...{headerProps, overviewProps, isGroupByPortfolio, rowFieldHeading, rowFieldName, tableMode}}/>;
}

interface XIRRTablePDFDownloadButtonProps {
    isGroupByPortfolio: boolean;
    rowFieldHeading?: string;
    rowFieldName: string;
    tableMode: SharedConstants.AdvancedPerformanceTableMode;
    heading: string;
}

export const XIRRTablePDFDownloadButton = ({heading, isGroupByPortfolio, rowFieldHeading, rowFieldName, tableMode}: XIRRTablePDFDownloadButtonProps) => {
    return <Box>
        <DownloadPDF
            PDFPage={
                XIRRTablePDFPage(isGroupByPortfolio, rowFieldHeading, rowFieldName, tableMode)
            }
            ReportNameForFileName={heading}
        />
    </Box>
}