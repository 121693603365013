import { useState } from 'react'
import { Box } from "@mui/material";
import { PerformanceChart } from "../../../../assets/icons/performanceChart";
import SecondaryIconButton from "../../../shared/secondary-icon-button";
import {
  BarChart,
  Bar,
  XAxis,
  ReferenceLine,
  Cell,
  ResponsiveContainer,
  YAxis,
  LabelList,
} from "recharts";
import styles from "./index.module.css";
import CustomTypography from "../../../shared/customTypography";
import { t } from "i18next";
import { SharedConstants } from "@mprofit/shared";
import LossGainBox from "../../../shared/loss-gain-box";
import ChangeIndicesIcon from "packages/web/src/assets/icons/todayL1/changeIndicesIcon";
import { DefaultIndicesModal } from '../../../shared/default-indices-modal';


const TodayPerformanceColumnChart = (props: { data?: SharedConstants.BarChartData[], myDataName?: string, isSingleAsset?: boolean }) => {
  const [hovered, setHovered] = useState(false);
  const [isChangeIndicesModalOpen, setIsChangeIndicesModalOpen] = useState<boolean>(false);

  const barColorFill = (value: number, isBlack?: boolean) => {
    if (isBlack) return "#000";
    else return value > 0 ? "#5F854C" : "#C94C40";
  };

  return (
    <Box className={[styles.todayPerfomanceContainer, 'apptour-today-todayperformance'].join(' ')}>
      {
        isChangeIndicesModalOpen &&
        <DefaultIndicesModal isModalOpen={isChangeIndicesModalOpen} setIsModalOpen={setIsChangeIndicesModalOpen} />
      }
      <Box className={styles.headingContainer}>
        <Box className={styles.headingSubContainer}>
          <PerformanceChart />
          <Box>
            <CustomTypography variant="Medium20">{t('portfolio.today.sharedComponents.portfolioPerformanceBar.title')}</CustomTypography>
            <CustomTypography variant="Regular18" sx={{ color: { xs: '#64677A' } }}>
              {props.myDataName ? `See how ${props.myDataName} ${props.isSingleAsset ? 'is': 'are'} moving today compared to market indices` : 'See how market indices are moving today'}
            </CustomTypography>
          </Box>
        </Box>
        <SecondaryIconButton
          tooltipText="Change Indices"
          backgroundToggle={true}
          buttonSize="large"
          icon={<ChangeIndicesIcon hovered={hovered} onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)} />}
          onClick={() => setIsChangeIndicesModalOpen(true)}
        />
      </Box>
      <Box sx={{
        paddingTop: "40px", flex: '1 1 100%', minHeight: '250px', maxHeight: '300px', overflowX: 'auto', overflowY: 'hidden', '::-webkit-scrollbar': {
          width: '1px',
        },
      }}>
        <Box height='100%' width='100%' minWidth={'540px'} className={[props?.data && props.data[0].IsBlack === true ? 'rechart-firstXitem-black' : undefined, 'today-perf-rechart'].join(' ')}>
          <BarChart width={900} height={270} data={props.data} barGap={45} margin={{ top: 35, right: 15, left: 20, bottom: 65, }}>
            <XAxis tickLine={false} axisLine={false} dy={42} dataKey="Name" fontFamily="rubik" interval={0} tickMargin={5} tick={{
              width: 90,
            }}
              tickFormatter={(value) => {
                // const lines = value && value.split(/\s+/);
                // if (lines.length > 3) {
                //   return `${lines.slice(0, 3).join(' ')}...`;
                // }
                const maxLength = 20;
                return value.length > maxLength ? `${value.slice(0, maxLength).trim()}...` : value;
              }}
            />
            <YAxis orientation="left" stroke="#D8DAE5" tickLine={false} axisLine={false} tick={false} hide={true} width={1} domain={getDomain(props.data)} />
            <ReferenceLine y={0} stroke="#D8DAE5" />
            <Bar dataKey="Value" fill="#C94C40" radius={[10, 10, 0, 0]} barSize={12} >
              {props?.data?.map((item, index: any) => {
                return (
                  <Cell key={index} fill={barColorFill(item.Value, item.IsBlack)} />
                )
              })}
              <LabelList dataKey="Value" content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </Box>
      </Box>
    </Box>
  );
};

const renderCustomizedLabel = (props: any) => {
  const { viewBox, value } = props;
  var { x, y, width, height } = viewBox;

  const offset = 22;

  // Define vertical offsets and position inverts based on the value being positive or negative
  const verticalSign = value > 0 ? 1 : -1;
  const verticalStart = verticalSign > 0 ? 'start' : 'end';
  const verticalOffset = verticalSign * offset;

  const attrs = {
    x: x + width / 2,
    y: y + height + verticalOffset,
    textAnchor: 'middle',
    verticalAnchor: verticalStart,
  };

  return <g>
    <foreignObject
      {...attrs}
      style={{ height: 1, width: 1, overflow: 'visible' }}
    >
      <LossGainBox Gain={value} GainUp={value > 0} sx={{ transform: 'translateX(-50%) translateY(-50%)' }} />
    </foreignObject>
  </g>
};

const getDomain = (data: SharedConstants.BarChartData[] | undefined) => {
  var min = 0;
  var max = 0;

  data?.forEach((item) => {
    if (item.Value > max) {
      max = item.Value;
    } else if (item.Value < min) {
      min = item.Value;
    }
  })

  if (min == 0 && max == 0) return [-1, 1];

  if (min != 0) {
    min = min < -0.1 ? min - 0.1 : min - 0.01;
  }

  if (max != 0) {
    max = max > 0.1 ? max + 0.1 : max + 0.01;
  }

  return [min, max];
}

export default TodayPerformanceColumnChart;
