import styles from './index.module.css';
import PrimaryIconButton from '../primary-icon-button';
import CachedIcon from '@mui/icons-material/Cached';
import { DefaultTableIcon } from '../../../assets/icons/DefaultTableIcon';
import ButtonPrimaryCTA from '../button-primary-cta';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Lottie from 'react-lottie';
import * as animationData from '../../../assets/animation/assetWisePerformanceAnimation.json'
import CustomTypography from '../customTypography';
import { t } from 'i18next';
import { Box, Grid, Stack } from '@mui/material';
import SharedVirtualizedTable, { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from '../virtual-table-component';
import { XIRRTableItem } from 'packages/shared/src/constants';
import { SharedConstants, formatDateForReadingWithTime } from '@mprofit/shared';
import { DownloadPDF } from '../../download-pdf';
import { CompleteTablePage } from '@mprofit-soft/pdf';
import { onClickContactSupportForUpgrade } from 'packages/web/src/classes/Utilities';

interface AssetWisePerformanceTableProps {
  TableRowData: RowDataProps[]
  TableData?: XIRRTableItem | undefined;
  columnData: ColumnDataProps[];
  isLoading: boolean,
  onRefresh?: () => void;
  rowFieldName: string;
  rowFieldHeading?: string;
  searchTerm?: string;
  fieldNamesToSearch?: string[];
  onRowClick: (row: RowDataProps) => void;
  disabled?: boolean;
  rowClickDisabled?: boolean;
  PMSBadgeField?: string;
  showLockedMessage: boolean;
}

export const getColumnData = (rowNameField: string, tableMode: SharedConstants.AdvancedPerformanceTableMode, isPerformancePeriodAllToDate: boolean, isGroupByPortfolio: boolean) => {
  var columnData: ColumnDataProps[]  = [
    {
      FieldKey: 'RowName',
      HeaderName: rowNameField,
      ColumnWidthPercent: '16%',
      FieldFormatter: TableDefaultFieldFormatter,
      Align: 'left',
      IsRowName: true
    },
    ...(
      isPerformancePeriodAllToDate ?
      []
      :
      [{
        FieldKey: 'TotalOpeningValuation',
        HeaderName: 'OPENING VALUATION',
        ColumnWidthPercent: '12%',
        FieldFormatter: TableDecimalFieldFormatter,
        Align: 'right',
      } as ColumnDataProps]
    ),
    ...(tableMode == SharedConstants.AdvancedPerformanceTableMode.Details ? getDetailedColumns(isGroupByPortfolio) : []),
    ...commonColumns,
    {
      FieldKey: 'XIRR',
      HeaderName: 'XIRR',
      ColumnWidthPercent: '12%',
      FieldFormatter: TablePercentFieldFormatter,
      Align: 'right',
    }
  ];
  
  return columnData;
}

const commonColumns: ColumnDataProps[] = [
  {
    FieldKey: 'TotalClosingValuation',
    HeaderName: 'CLOSING VALUATION',
    ColumnWidthPercent: '12%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'TotalGain',
    HeaderName: 'TOTAL GAIN',
    ColumnWidthPercent: '12%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [{
      FieldKey: 'TotalGainPct',
      FieldFormatter: TablePercentFieldFormatter,
      Align: 'right',
    }]
  },
  // {
  //   FieldKey: 'RealisedGain',
  //   HeaderName: 'REALISED GAIN',
  //   ColumnWidthPercent: '12%',
  //   FieldFormatter: TableDecimalFieldFormatter,
  //   Align: 'right',
  //   IsRowName: true,
  //   subColumns: [{
  //     FieldKey: 'RealisedGainPct',
  //     FieldFormatter: TableDecimalFieldFormatter,
  //     Align: 'right',
  //   }]
  // },
  // {
  //   FieldKey: 'UnrealisedGain',
  //   HeaderName: 'UNREALISED GAIN',
  //   ColumnWidthPercent: '12%',
  //   FieldFormatter: TableDecimalFieldFormatter,
  //   Align: 'right',
  //   subColumns: [{
  //     FieldKey: 'UnrealisedGainPct',
  //     FieldFormatter: TableDecimalFieldFormatter,
  //     Align: 'right',
  //   }]
  // }
];

const getDetailedColumns = (isGroupByPortfolio: boolean): ColumnDataProps[] => {
  return [
    {
      FieldKey: 'TotalInvestment',
      HeaderName: isGroupByPortfolio ? 'TOTAL INFLOW' : 'TOTAL INVESTMENT',
      ColumnWidthPercent: '12%',
      FieldFormatter: TableDecimalFieldFormatter,
      Align: 'right',
    },
    {
      FieldKey: 'TotalWithdrawal',
      HeaderName: isGroupByPortfolio ? 'TOTAL OUTFLOW' : 'TOTAL WITHDRAWAL',
      ColumnWidthPercent: '12%',
      FieldFormatter: TableDecimalFieldFormatter,
      Align: 'right',
    },
    ...(
      isGroupByPortfolio ?
      [{
        FieldKey: 'IncomePayout',
        HeaderName: 'Income Payout',
        ColumnWidthPercent: '12%',
        FieldFormatter: TableDecimalFieldFormatter,
        Align: 'right',
      } as ColumnDataProps]
      :
      [{
        FieldKey: 'TotalIncome',
        HeaderName: 'Total Income',
        ColumnWidthPercent: '12%',
        FieldFormatter: TableDecimalFieldFormatter,
        Align: 'right',
      } as ColumnDataProps]
    )
  ];
}

const skeletonRows = [
  {
    "RowName": "Stocks",
    "ClosingValuation": 10000000.2,
    "TotalGain": 20000.12,
    "RealisedGain": 30000.11,
    "RealisedGainPct": "19.23%",
    "UnrealisedGain": -9999.99,
    "UnrealisedGainPct": "-5.74%",
    "XIRR": "24.13%",
    "TotalInvestment": 50000.47,
    "TotalWithdrawal": 37132.42,
    "IncomePayout": 3413.27
  },
  {
    "RowName": "Mutual Funds",
    "ClosingValuation": 100000000.2,
    "TotalGain": -20000.12,
    "RealisedGain": -30000.11,
    "RealisedGainPct": "-10.23%",
    "UnrealisedGain": 9999.99,
    "UnrealisedGainPct": "5.74%",
    "XIRR": "-24.13%",
    "TotalInvestment": 50000.47,
    "TotalWithdrawal": 37132.42,
    "IncomePayout": 3413.27
  },
  {
    "RowName": "Property",
    "ClosingValuation": 100000000.2,
    "TotalGain": 20000.12,
    "RealisedGain": 30000.11,
    "RealisedGainPct": "10.23%",
    "UnrealisedGain": -9999.99,
    "UnrealisedGainPct": "-5.74%",
    "XIRR": "24.13%",
    "TotalInvestment": 50000.47,
    "TotalWithdrawal": 37132.42,
    "IncomePayout": 3413.27
  },
  {
    "RowName": "Private Equity",
    "ClosingValuation": 100000000.2,
    "TotalGain": -20000.12,
    "RealisedGain": -30000.11,
    "RealisedGainPct": "-10.23%",
    "UnrealisedGain": 9999.99,
    "UnrealisedGainPct": "5.74%",
    "XIRR": "-24.13%",
    "TotalInvestment": 50000.47,
    "TotalWithdrawal": 37132.42,
    "IncomePayout": 3413.27
  },
  {
    "RowName": "FDs",
    "ClosingValuation": 100000000.2,
    "TotalGain": 20000.12,
    "RealisedGain": 30000.11,
    "RealisedGainPct": "10.23%",
    "UnrealisedGain": -9999.99,
    "UnrealisedGainPct": "-5.74%",
    "XIRR": "24.13%",
    "TotalInvestment": 50000.47,
    "TotalWithdrawal": 37132.42,
    "IncomePayout": 3413.27
  },
  {
    "RowName": "Loans",
    "ClosingValuation": 100000000.2,
    "TotalGain": -20000.12,
    "RealisedGain": -30000.11,
    "RealisedGainPct": "-10.23%",
    "UnrealisedGain": 9999.99,
    "UnrealisedGainPct": "5.74%",
    "XIRR": "-24.13%",
    "TotalInvestment": 50000.47,
    "TotalWithdrawal": 37132.42,
    "IncomePayout": 3413.27
  },
  {
    "RowName": "Insurance",
    "ClosingValuation": 100000000.2,
    "TotalGain": 20000.12,
    "RealisedGain": 30000.11,
    "RealisedGainPct": "10.23%",
    "UnrealisedGain": -9999.99,
    "UnrealisedGainPct": "-5.74%",
    "XIRR": "24.13%",
    "TotalInvestment": 50000.47,
    "TotalWithdrawal": 37132.42,
    "IncomePayout": 3413.27
  },
  {
    "RowName": "Gold",
    "ClosingValuation": 100000000.2,
    "TotalGain": -20000.12,
    "RealisedGain": -30000.11,
    "RealisedGainPct": "-10.23%",
    "UnrealisedGain": 9999.99,
    "UnrealisedGainPct": "5.74%",
    "XIRR": "-24.13%",
    "TotalInvestment": 50000.47,
    "TotalWithdrawal": 37132.42,
    "IncomePayout": 3413.27
  },
  {
    "RowName": "Insurance",
    "ClosingValuation": 100000000.2,
    "TotalGain": 20000.12,
    "RealisedGain": 30000.11,
    "RealisedGainPct": "10.23%",
    "UnrealisedGain": -9999.99,
    "UnrealisedGainPct": "-5.74%",
    "XIRR": "24.13%",
    "TotalInvestment": 50000.47,
    "TotalWithdrawal": 37132.42,
    "IncomePayout": 3413.27
  },
  {
    "RowName": "Gold",
    "ClosingValuation": 100000000.2,
    "TotalGain": -20000.12,
    "RealisedGain": -30000.11,
    "RealisedGainPct": "-10.23%",
    "UnrealisedGain": 9999.99,
    "UnrealisedGainPct": "5.74%",
    "XIRR": "-24.13%",
    "TotalInvestment": 50000.47,
    "TotalWithdrawal": 37132.42,
    "IncomePayout": 3413.27
  }
]



export default function AssetWisePerformanceTable({ TableData, TableRowData, columnData, isLoading, onRefresh = () => { }, rowFieldName, rowFieldHeading, searchTerm, fieldNamesToSearch, onRowClick, disabled = false, rowClickDisabled = false, PMSBadgeField, showLockedMessage }: AssetWisePerformanceTableProps) {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const isSkeleton: boolean = !TableData || isLoading === true;

  if (isSkeleton) {
    return (
      <Stack>
        <Box sx={sxStyles.overLayContainer}>
          <Box sx={sxStyles.tableOverlay}>
            <Box sx={sxStyles.overlayItem}>
              {
                isLoading ?
                  <>
                    <Box sx={sxStyles.animationCont}>
                      <Lottie
                        options={defaultOptions}
                        height={256}
                        width={256}
                      />
                      <Box className={styles.animateStaticIcon} >
                        <DefaultTableIcon />
                      </Box>
                    </Box>
                    <CustomTypography>{'Calculating XIRR'}</CustomTypography>
                  </>
                  :
                  <Stack>
                    <DefaultTableIcon style={{ margin: "auto" }} />
                    {
                      showLockedMessage
                      ?
                      <>
                      <CustomTypography variant='Medium24' sx={{ marginTop: '40px' }}>{'Feature Locked'}</CustomTypography>
                      <CustomTypography variant='Regular18'>{'Upgrade your plan to use this feature'}</CustomTypography>
                      <Box paddingTop="17px">
                      <ButtonPrimaryCTA
                          disabled={false}
                          handleClick={onClickContactSupportForUpgrade}
                          // startIcon={<RemoveRedEyeOutlined />}
                          buttonText={'Contact MProfit'}
                      />
                      </Box>
                      </>
                      :
                      <>
                      <CustomTypography variant='Medium24' sx={{ marginTop: '40px' }}>{`View XIRR by ${rowFieldHeading || rowFieldName}`}</CustomTypography>
                      <CustomTypography variant='Regular18'>{`Get a detailed view of your annualized return for each ${(rowFieldHeading || rowFieldName).toLowerCase()} along with key performance metrics including total gain and income earned.`}</CustomTypography>
                      <Box paddingTop="17px">
                        <ButtonPrimaryCTA
                          handleClick={() => onRefresh()}
                          startIcon={<RemoveRedEyeOutlinedIcon />}
                          buttonText={'View Table'}
                          disabled={disabled}
                        />
                      </Box>
                      </>
                    }
                  </Stack>
              }
            </Box>
          </Box>
          <Grid sx={{ width: '100%', height: '100%', position: 'relative' }}>
            <Box sx={sxStyles.blurOverlay}></Box>
            <SharedVirtualizedTable columns={columnData} rows={skeletonRows} tableHeight={"500px"} headerColor="#EAF4DF" sorting={true} isScrollable={false} PMSBadgeField={PMSBadgeField}/>
          </Grid>
        </Box >

        <Box sx={sxStyles.lastRefreshedContainer}>
          {/* <PrimaryIconButton
            icon={<CachedIcon />}
            handleClick={onRefresh}
            iconBorderRadius={'4px'}
          /> */}
          {/* <Box sx={{ display: 'flex', gap: '5px' }}>
            <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A' } }}>
              {t('portfolio.today.sharedComponents.assetPerformanceTable.lastRefreshed')}
            </CustomTypography>
            <CustomTypography variant='Medium16' sx={{ color: { xs: '#64677A' } }}>
              {formatDateForReadingWithTime(TableData?.LastRefreshedTime)}
            </CustomTypography>
          </Box> */}
        </Box>
      </Stack >
    )
  }
    else {
      return (
        <Box sx={{ background: 'white' }}>
          <SharedVirtualizedTable
            columns={columnData}
            rows={TableRowData}
            tableHeight={"500px"}
            headerColor="#EAF4DF"
            sorting={true}
            searchTerm={searchTerm}
            fieldNamesToSearch={fieldNamesToSearch}
            onRowClick={onRowClick}
            defaultSortConfig={{key: 'ClosingValuation', direction: SharedConstants.SortDirection.Down}}
            rowClickDisabled={rowClickDisabled}
            PMSBadgeField={PMSBadgeField}
          />
          <Box
            className={styles.refresh}>
            <Box>
              <PrimaryIconButton
                icon={<CachedIcon />}
                handleClick={onRefresh}
                extraSx={{
                  marginLeft: 0
                }}
              />
            </Box>
            
            <Box sx={{ display: 'flex', gap: '5px' }}>
              <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A' } }}>
                {t('portfolio.today.sharedComponents.assetPerformanceTable.lastRefreshed')}
              </CustomTypography>
              <CustomTypography variant='Medium16' sx={{ color: { xs: '#64677A' } }}>
                {formatDateForReadingWithTime(TableData?.LastRefreshedTime)}
              </CustomTypography>
            </Box>
          </Box>
        </Box>
      )
  }
}

const sxStyles = {
  blurOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backdropFilter: 'blur(7px)',
    marginTop: '57px',
    zIndex: 1
  },
  overLayContainer: {
    width: '100%',
    height: 'inherit',
    overflowY: 'hidden',
    borderBottom: '1px solid #D8DAE5',
    // boxShadow: '0 -5px 3px -3px #D8DAE5',
    position: 'relative',
  },
  tableOverlay: {
    zIndex: 2,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    width: { sm: '82%', xl: '70%' }
  },
  overlayItem: {
    textAlign: 'center'
  },
  overLayTable: {
    width: 'inherit',
    color: '#bbb8af'
  },
  animationCont: {
    margin: 'auto',
    width: '256px',
    height: '256px',
    position: 'relative'
  },
  lastRefreshedContainer: {
    display: "flex",
    alignItems: 'center',
    gap: "20px",
    // padding: "24px",
    background: 'white',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',
    height: 72
  }
}