export function BND(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226553" data-name="Group 226553" transform="translate(-17890 6538.746)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 0.254)" fill="#fff" opacity="0" />
          <path id="Path_237165" data-name="Path 237165" d="M39.19,45.968a3.8,3.8,0,1,0-6.51,2.654v3.856a.543.543,0,0,0,.785.486L35.393,52l1.927.964a.543.543,0,0,0,.785-.486V48.622A3.785,3.785,0,0,0,39.19,45.968Zm-3.8-2.713a2.713,2.713,0,1,1-2.713,2.713A2.713,2.713,0,0,1,35.393,43.255Zm.243,7.652a.543.543,0,0,0-.486,0l-1.385.693V49.4a3.794,3.794,0,0,0,3.255,0v2.2Zm-4.04-.6a.543.543,0,0,1-.543.543H25.085A1.085,1.085,0,0,1,24,49.765v-8.68A1.085,1.085,0,0,1,25.085,40H37.02a1.085,1.085,0,0,1,1.085,1.085.543.543,0,0,1-1.085,0H25.085v8.68h5.968A.543.543,0,0,1,31.6,50.308Zm-1.085-3.8a.543.543,0,0,1-.543.543H27.255a.543.543,0,1,1,0-1.085h2.713A.543.543,0,0,1,30.51,46.51Zm0-2.17a.543.543,0,0,1-.543.543H27.255a.543.543,0,1,1,0-1.085h2.713A.543.543,0,0,1,30.51,44.34Z" transform="translate(-21.468 -36.203)" fill="#fff" />
        </g>
      </g>
    </svg>
  );
}
