import ButtonPrimaryCTA from '../shared/button-primary-cta'
import AddIcon from '@mui/icons-material/Add';
import ButtonText from '../shared/button-text';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import TuneIcon from '@mui/icons-material/Tune';
import SecondaryIconButton from '../shared/secondary-icon-button';
import PrimaryIconButton from '../shared/primary-icon-button';
import IndexCard from '../shared/index-card';
import Big from 'big.js';
import LeaderBoard from '../shared/leaderboard';
import { PopOutIcon } from '../../assets/icons/popOutIcon';
import SearchBar from '../shared/search-bar';
import WatchList from '../shared/watchlist';
import { Selected } from '../../assets/icons/selected';
import Selection from '../shared/selection';
import RecentCorporateJobs from '../shared/recent-corporate-actions';
import { DropResult } from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';

import ReactTable from '../shared/asset-wise-performanc-table';
import AssetWisePerformanceTable from '../shared/asset-wise-performanc-table';
import React from 'react';
import { MPrUrlParams } from '../../classes/MPrUrlParams';
// import TodayPerformanceColumnChart from '../shared/today-performance-column-chart';
import XirrBarChart from '../shared/xirr-bar-chart';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import HorizontalStepper from '../shared/stepper';
import FilterDropdown from '../shared/filter-dropdown';
import { SelectChangeEvent } from '@mui/material/Select';
import SavedReports from '../reports/saved-reports';
import { PerformanceIcon } from '../../assets/icons/performanceIcon';
import { CapitalGainIcon } from '../../assets/icons/capitalgainIcon';
import { TransactionIcon } from '../../assets/icons/transactionIcon';
import { AdvancedIcon } from '../../assets/icons/advancedIcon';
import { TaxSoftFormatIcon } from '../../assets/icons/taxSoftFormatIcon';
import { IncomeOtherIcon } from '../../assets/icons/incomeOtherIcon';
// import VirtualizedTable, { Column, Row } from '../shared/virtual-table-component';
import { Box } from '@mui/material';
import SharedVirtualizedTable, { RowDataProps } from '../shared/virtual-table-component';
import { DocumentIcon } from '../../assets/icons/documentIcon';
import { SharedConstants } from '@mprofit/shared';

const watchListData = [
  {
    id: 1,
    AssetName: "Apollo Hospitals Enterprise Ltd",
    AssetSymbol: "APOLLOHOSP",
    CurrentValue: Big(10014243.55),
    TGain: Big(230),
    TGainPct: Big(4),
    TUp: true
  },
  {
    id: 2,
    AssetName: "Marico Ltd",
    AssetSymbol: "RELIANCE",
    CurrentValue: Big(10014243.55),
    TGain: Big(5),
    TGainPct: Big(4),
    TUp: true
  }, {
    id: 3,
    AssetName: "Nestle India Ltd",
    AssetSymbol: "RELIANCE",
    CurrentValue: Big(2323),
    TGain: Big(5),
    TGainPct: Big(4),
    TUp: true
  },
  {
    id: 4,
    AssetName: "1",
    AssetSymbol: "RELIANCE",
    CurrentValue: Big(2323),
    TGain: Big(5.00),
    TGainPct: Big(42),
    TUp: true
  },
  {
    id: 5,
    AssetName: "2",
    AssetSymbol: "RELIANCE",
    CurrentValue: Big(2323),
    TGain: Big(5),
    TGainPct: Big(4),
    TUp: true
  }, {
    id: 6,
    AssetName: "R3",
    AssetSymbol: "RELIANCE",
    CurrentValue: Big(2323),
    TGain: Big(5),
    TGainPct: Big(4),
    TUp: true
  }, {
    id: 7,
    AssetName: "4",
    AssetSymbol: "RELIANCE",
    CurrentValue: Big(2323),
    TGain: Big(5),
    TGainPct: Big(4),
    TUp: true
  }, {
    id: 8,
    AssetName: "55",
    AssetSymbol: "RELIANCE",
    CurrentValue: Big(2323),
    TGain: Big(5),
    TGainPct: Big(4),
    TUp: true
  }, {
    id: 9,
    AssetName: "123213",
    AssetSymbol: "RELIANCE",
    CurrentValue: Big(2323),
    TGain: Big(5),
    TGainPct: Big(4),
    TUp: true
  }
]

type totlValueObj = {
  TopValue: string,
  BottomValue: string,
  TopValueColor: string,
  BottomValueColor: string,
}
export type dataPropType = {
  ASSET_CLASS: string,
  CURRENT_VALUE: string,
  GAIN: string,
  RELIAZED_GAIN: string,
  XIRR: totlValueObj
}

export const Tabledata = [{
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "-123123",
    TopValueColor: "black",
    BottomValueColor: "red",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}
]

function Temp() {
  const [items, setItems] = useState(watchListData);


  // console.log(urlParams.getMPrUrlParamsFromString("Bg3uVnIz63W"))
  let abc = new MPrUrlParams({ Screen: SharedConstants.ScreenEnum.L1, DatabaseID: 102030405, FamilyID: 1, PortfolioID: 2, AssetType: 50 })

  let encode = abc.getUrlString();
  console.log("encode", encode, "getURLParamsFromString", MPrUrlParams.getMPrUrlParamsFromString(encode))

  const todayPerformanceChartData = [
    {
      name: "My stocks",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "S&P 500",
      uv: -3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "SENSEX",
      uv: -2000,
      pv: -9800,
      amt: 2290,
    },
    {
      name: "NASDAQ",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Indice 4",
      uv: -1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Indice 5",
      uv: 2390,
      pv: -3800,
      amt: 2500,
    },
  ];
  const chartData = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  const ringChartData = [
    {
      name: "Stocks",
      value: 90,
      color: "#010006",
    },
    {
      name: "MutualFunds",
      value: 90,
      color: "#485D3E",
    },
    {
      name: "NPS/ULIP",
      value: 90,
      color: "#5F854C",
    },
    {
      name: "Insurance",
      value: 90,
      color: "#ACC18A",
    },
    {
      name: "PrivateEquity",
      value: 90,
      color: "#5F939A",
    },
    {
      name: "Post Office",
      value: 90,
      color: "#7CB9D2",
    },
    {
      name: "Gold",
      value: 90,
      color: "#B1DBFF",
    },
    {
      name: "Jewellery",
      value: 90,
      color: "#D2D4FF",
    },
    {
      name: "Property",
      value: 90,
      color: "#CBCEDF",
    },
    {
      name: "Art",
      value: 90,
      color: "#F5CFA0",
    },
    {
      name: "Loans",
      value: 90,
      color: "#E7A388",
    },
  ];


  // redux-toolkit temp test
  const dispatch = useDispatch();
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedOption, setSelectedOption] = React.useState<string[]>([""]);
  const [selected, setSelected] = useState("")

  //added logger to check logs
  // 

  const handleChange = (event: SelectChangeEvent<typeof selectedOption>) => {
    const { target: { value }, } = event;
    setSelectedOption(typeof value === 'string' ? value.split(',') : value);
  };

  const savedReportData = [
    {
      icon: <PerformanceIcon />,
      reportName: "My portfolio summary FY 22-23",
      reportType: 'stocks',
      year: "22-23",
      summaryType: 'Performance Summary',
      summarySubtitle: 'Performance'
    },
    {
      icon: <CapitalGainIcon />,
      reportName: "Mprofit UI Report",
      reportType: 'stocks',
      year: "22-23",
      summaryType: 'Unrealized Capital Gains - Summary',
      summarySubtitle: 'Capital Gains'
    },
    {
      icon: <TransactionIcon />,
      reportName: "Capital Gains",
      reportType: 'stocks',
      year: "22-23",
      summaryType: 'Mutual fund Transactions',
      summarySubtitle: 'Transactions'
    },
    {
      icon: <AdvancedIcon />,
      reportName: "Balance sheet",
      reportType: 'stocks',
      year: "22-23",
      summaryType: 'Closing Balance',
      summarySubtitle: 'Advanced'
    },
    {
      icon: <TaxSoftFormatIcon />,
      reportName: "New report design",
      reportType: 'stocks',
      year: "22-23",
      summaryType: 'Income Report - Summary',
      summarySubtitle: 'Income & Other'
    },
    {
      icon: <IncomeOtherIcon />,
      reportName: "New report design",
      reportType: 'stocks',
      year: "22-23",
      summaryType: 'Income Report - Summary',
      summarySubtitle: 'Income & Other'
    }
  ]


  const rows = [
    {
      "RowName": "Stocks",
      "CurrentValue": 10000000.2,
      "TotalGain": 20000.12,
      "RealisedGain": 30000.11,
      "RealisedGainPct": "19.23%",
      "UnrealisedGain": -9999.99,
      "UnrealisedGainPct": "-5.74%",
      "XIRR": "24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Mutual Funds",
      "CurrentValue": 100000000.2,
      "TotalGain": -20000.12,
      "RealisedGain": -30000.11,
      "RealisedGainPct": "-10.23%",
      "UnrealisedGain": 9999.99,
      "UnrealisedGainPct": "5.74%",
      "XIRR": "-24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Property",
      "CurrentValue": 100000000.2,
      "TotalGain": 20000.12,
      "RealisedGain": 30000.11,
      "RealisedGainPct": "10.23%",
      "UnrealisedGain": -9999.99,
      "UnrealisedGainPct": "-5.74%",
      "XIRR": "24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Private Equity",
      "CurrentValue": 100000000.2,
      "TotalGain": -20000.12,
      "RealisedGain": -30000.11,
      "RealisedGainPct": "-10.23%",
      "UnrealisedGain": 9999.99,
      "UnrealisedGainPct": "5.74%",
      "XIRR": "-24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "FDs",
      "CurrentValue": 100000000.2,
      "TotalGain": 20000.12,
      "RealisedGain": 30000.11,
      "RealisedGainPct": "10.23%",
      "UnrealisedGain": -9999.99,
      "UnrealisedGainPct": "-5.74%",
      "XIRR": "24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Loans",
      "CurrentValue": 100000000.2,
      "TotalGain": -20000.12,
      "RealisedGain": -30000.11,
      "RealisedGainPct": "-10.23%",
      "UnrealisedGain": 9999.99,
      "UnrealisedGainPct": "5.74%",
      "XIRR": "-24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Insurance",
      "CurrentValue": 100000000.2,
      "TotalGain": 20000.12,
      "RealisedGain": 30000.11,
      "RealisedGainPct": "10.23%",
      "UnrealisedGain": -9999.99,
      "UnrealisedGainPct": "-5.74%",
      "XIRR": "24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Gold",
      "CurrentValue": 100000000.2,
      "TotalGain": -20000.12,
      "RealisedGain": -30000.11,
      "RealisedGainPct": "-10.23%",
      "UnrealisedGain": 9999.99,
      "UnrealisedGainPct": "5.74%",
      "XIRR": "-24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Stocks",
      "CurrentValue": 10000000.2,
      "TotalGain": 20000.12,
      "RealisedGain": 30000.11,
      "RealisedGainPct": "19.23%",
      "UnrealisedGain": -9999.99,
      "UnrealisedGainPct": "-5.74%",
      "XIRR": "24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Mutual Funds",
      "CurrentValue": 100000000.2,
      "TotalGain": -20000.12,
      "RealisedGain": -30000.11,
      "RealisedGainPct": "-10.23%",
      "UnrealisedGain": 9999.99,
      "UnrealisedGainPct": "5.74%",
      "XIRR": "-24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Property",
      "CurrentValue": 100000000.2,
      "TotalGain": 20000.12,
      "RealisedGain": 30000.11,
      "RealisedGainPct": "10.23%",
      "UnrealisedGain": -9999.99,
      "UnrealisedGainPct": "-5.74%",
      "XIRR": "24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Private Equity",
      "CurrentValue": 100000000.2,
      "TotalGain": -20000.12,
      "RealisedGain": -30000.11,
      "RealisedGainPct": "-10.23%",
      "UnrealisedGain": 9999.99,
      "UnrealisedGainPct": "5.74%",
      "XIRR": "-24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "FDs",
      "CurrentValue": 100000000.2,
      "TotalGain": 20000.12,
      "RealisedGain": 30000.11,
      "RealisedGainPct": "10.23%",
      "UnrealisedGain": -9999.99,
      "UnrealisedGainPct": "-5.74%",
      "XIRR": "24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Loans",
      "CurrentValue": 100000000.2,
      "TotalGain": -20000.12,
      "RealisedGain": -30000.11,
      "RealisedGainPct": "-10.23%",
      "UnrealisedGain": 9999.99,
      "UnrealisedGainPct": "5.74%",
      "XIRR": "-24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Insurance",
      "CurrentValue": 100000000.2,
      "TotalGain": 20000.12,
      "RealisedGain": 30000.11,
      "RealisedGainPct": "10.23%",
      "UnrealisedGain": -9999.99,
      "UnrealisedGainPct": "-5.74%",
      "XIRR": "24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    },
    {
      "RowName": "Gold",
      "CurrentValue": 100000000.2,
      "TotalGain": -20000.12,
      "RealisedGain": -30000.11,
      "RealisedGainPct": "-10.23%",
      "UnrealisedGain": 9999.99,
      "UnrealisedGainPct": "5.74%",
      "XIRR": "-24.13%",
      "TotalInvestment": 50000.47,
      "TotalWithdrawal": 37132.42,
      "IncomePayout": 3413.27
    }
  ]

  // const columns: ColumnDataProps[] = [
  //   {
  //     FieldKey: 'RowName',
  //     HeaderName: 'Row Name',
  //     ColumnWidthPercent: 150,
  //     FieldFormatter: (value: any) => <strong>{value}</strong>,
  //     Align: 'left'
  //   },
  //   {
  //     FieldKey: 'CurrentValue',
  //     HeaderName: 'CURRENT VALUE',
  //     ColumnWidthPercent: 160,
  //     FieldFormatter: (value: any) => value,
  //     Align: 'right',
  //   },
  //   {
  //     FieldKey: 'TotalGain',
  //     HeaderName: 'TOTAL GAIN',
  //     ColumnWidthPercent: 170,
  //     FieldFormatter: (value: any) => value,
  //     Align: 'right',
  //   },
  //   {
  //     FieldKey: 'RealisedGain',
  //     HeaderName: 'REALISED GAIN',
  //     ColumnWidthPercent: 170,
  //     FieldFormatter: (value: any) => value,
  //     Align: 'right',
  //     subColumns: [{
  //       FieldKey: 'RealisedGainPct',
  //       ColumnWidthPercent: 170,
  //       FieldFormatter: (value: any) => value,
  //       Align: 'right',
  //     }]
  //   },
  //   {
  //     FieldKey: 'UnrealisedGain',
  //     HeaderName: 'UNREALISED GAIN',
  //     ColumnWidthPercent: 170,
  //     FieldFormatter: (value: any) => value,
  //     Align: 'right',
  //     subColumns: [{
  //       FieldKey: 'UnrealisedGainPct',
  //       ColumnWidthPercent: 170,
  //       FieldFormatter: (value: any) => value,
  //       Align: 'right',
  //     }]
  //   },
  //   {
  //     FieldKey: 'XIRR',
  //     HeaderName: 'XIRR',
  //     ColumnWidthPercent: 170,
  //     FieldFormatter: (value: any) => value,
  //     Align: 'right',
  //   },
  //   {
  //     FieldKey: 'TotalInvestment',
  //     HeaderName: 'TOTAL INVESTMENT',
  //     ColumnWidthPercent: 170,
  //     FieldFormatter: (value: any) => value,
  //     Align: 'right',
  //   },
  //   {
  //     FieldKey: 'TotalWithdrawal',
  //     HeaderName: 'TOTAL WITHDRAWAL',
  //     ColumnWidthPercent: 170,
  //     FieldFormatter: (value: any) => value,
  //     Align: 'right',
  //   },
  //   {
  //     FieldKey: 'IncomePayout',
  //     HeaderName: 'INCOME PAYOUT',
  //     ColumnWidthPercent: 170,
  //     FieldFormatter: (value: any) => value,
  //     Align: 'right',
  //   }
  // ];

  const [isLoading, setIsloading] = useState(false);

  const handleRefresh = () => {
    console.log("asfsaf")
  }

  return (

    <div className='temp-page'>
      {/* <RecentCorporateJobs
      RcaJobsData={data}
      /> */}

      {/* <WatchList watchListData={watchListData} /> */}

      {/* <PortfolioPerformanceChart/> */}

      {/* <RingChart/> */}

      {/* <LeaderBoard
        Losers={watchListData}
      /> */}
      {/* <h1>
        For Language - {t('hello')}
      </h1> */}
      {/* <TodayPerformanceColumnChart data={todayPerformanceChartData} />
      <XirrBarChart data={todayPerformanceChartData} /> */}
      {/* <AssetAllocationPieChart data={ringChartData} /> */}

      {/* <HorizontalStepper
        steps={["this is steps1", "this is steps2", "this is steps3"]}
        setSkipped={setSkipped}
        skipped={skipped}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      >
        {
          activeStep === 1 ?
            <Typography variant={'Bold24'}>this is---- </Typography>
            :
            <div>false</div>
        }
      </HorizontalStepper> */}


      {/* <FilterDropdown
        setSelectedOption={setSelectedOption}
        selectedOption={selectedOption}
        data={[
          'Performance',
          'Capital Gains',
          'Transactions',
          'Advanced',
          'Tax software formats',
          'Income & other',
        ]}
      /> */}

      {/* <SavedReports data={savedReportData} /> */}



      {/* <DynamicTable columns={columns} rows={rows} />; */}

      {/* <SharedVirtualizedTable columns={columns} rows={rows} tableHeight={"500px"} headerColor="#EAF4DF" /> */}
      {/* <VirtualizedTable columns={columns} rows={rows} rowHeight={53} /> */}
    </div>
  )
}

export default Temp

