export function EQ(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226544" data-name="Group 226544" transform="translate(-17889.658 6539.351)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(-0.343 -0.351)" fill="#5f854c" opacity="0" />
          <g id="Group_150728" data-name="Group 150728" transform="translate(3.679 2.351)">
            <g id="trending-up-outline" transform="translate(1.484 8.921)">
              <path id="Path_198278" data-name="Path 198278" d="M352,144h2.8v2.8" transform="translate(-344.41 -144)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
              <path id="Path_198279" data-name="Path 198279" d="M48,165.193l3.03-3.03a.8.8,0,0,1,1.13,0l1.267,1.267a.8.8,0,0,0,1.13,0l3.43-3.43" transform="translate(-48 -159.601)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </g>
            <g id="np_stocks_2397585_000000">
              <path id="Path_198227" data-name="Path 198227" d="M38.059,18.773h.557v.6a.3.3,0,0,0,.6,0v-.6h.557a.267.267,0,0,0,.258-.258V14.955a.258.258,0,0,0-.258-.258h-.557v-.6a.3.3,0,0,0-.6,0v.6h-.557a.267.267,0,0,0-.258.258v3.573a.247.247,0,0,0,.258.245Z" transform="translate(-33.005 -12.401)" fill="#fff" />
              <path id="Path_198228" data-name="Path 198228" d="M3.315,37.287a.3.3,0,1,0,.6,0v-.6H4.47a.267.267,0,0,0,.258-.258V32.858A.257.257,0,0,0,4.47,32.6H3.913V32a.3.3,0,1,0-.6,0v.6H2.758a.258.258,0,0,0-.258.258v3.573a.258.258,0,0,0,.258.258h.557Z" transform="translate(-2.5 -27.872)" fill="#fff" />
              <path id="Path_198229" data-name="Path 198229" d="M73.36,8.473h.557v.6a.3.3,0,1,0,.6,0v-.6h.557a.267.267,0,0,0,.258-.258V4.655a.257.257,0,0,0-.258-.258h-.557V3.8a.3.3,0,1,0-.6,0v.6H73.36a.258.258,0,0,0-.258.258V8.229a.247.247,0,0,0,.258.245Z" transform="translate(-63.51 -3.501)" fill="#fff" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
}
