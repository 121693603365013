export function OpenArrow(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_154371" data-name="Group 154371" transform="translate(-32 -11)">
                <g id="Rectangle_23766" data-name="Rectangle 23766" transform="translate(32 11)" fill="#5f6f57" stroke="#707070" strokeWidth="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="Path_235219" data-name="Path 235219" d="M12.333,5.667H5a2,2,0,0,0-2,2V17a2,2,0,0,0,2,2h9.333a2,2,0,0,0,2-2V9.667M7,15,19,3m0,0H14.333M19,3V7.667" transform="translate(33.5 11.5)" fill="none" stroke="#5f6f57" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </g>
        </svg>

    );
}

