export function DistributionIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_149757"
        data-name="Group 149757"
        transform="translate(-32 -11)"
      >
        <g
          id="Rectangle_23766"
          data-name="Rectangle 23766"
          transform="translate(32 11)"
          fill="#5f6f57"
          stroke="#707070"
          stroke-width="1"
          opacity="0"
        >
          <rect
            width="24"
            height="24"
            stroke="none"
          />
          <rect
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            fill="none"
          />
        </g>
        <g
          id="Ellipse_2888"
          data-name="Ellipse 2888"
          transform="translate(54 13.001) rotate(90)"
          fill="rgba(255,255,255,0)"
          stroke="#5f6f57"
          stroke-linecap="round"
          stroke-width="2.25"
          stroke-dasharray="11 6"
        >
          <circle
            cx="10"
            cy="10"
            r="10"
            stroke="none"
          />
          <circle
            cx="10"
            cy="10"
            r="8.875"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
}
