import { CompleteChartPage, PdfBarChart, PdfCashFlowChart } from "@mprofit-soft/pdf";
import { DownloadPDF, ExtraHeaderProps, ExtraOverviewProps } from "../../download-pdf";
import { ChartsSelectors } from "packages/web/src/redux/selectors/chartsSelectors";
import { usePDFSelector } from "../../download-pdf/redux";

export const CashflowChartPDFPage = ({ headerProps, overviewProps}: {
        headerProps: ExtraHeaderProps;
        overviewProps: ExtraOverviewProps;
    }) => {
    const {cashflowChartData, isPerformancePeriodAllToDate} = usePDFSelector(ChartsSelectors.selectCashflowChartData);

    if (!cashflowChartData) {
        return <></>;
    }

    return <CompleteChartPage
        headerProps={{
            ...headerProps,
            reportType: "Performance"
        }}
        overviewProps={{
            ...overviewProps,
            heading: "Cashflow Snapshot"
        }}
        ChartComponent={(extraChartProps) => 
        <PdfCashFlowChart
            {...extraChartProps}
            cashflowChartData={cashflowChartData}
            showOpeningValuationAndCashBal={!isPerformancePeriodAllToDate}
        />}
    />
}

export const CashflowChartPDFDownloadButton = () => {
    return <DownloadPDF
        PDFPage={
            CashflowChartPDFPage
        }
        ReportNameForFileName="Cashflow Snapshot"
    />
}