import { formatDecimalWithRupeeSymbol } from '@mprofit/shared'
import { Grid } from '@mui/material'
import QuantityIcon from 'packages/web/src/assets/icons/holdingL4/quantityIcon'
import { RupeeIcon } from 'packages/web/src/assets/icons/rupeeIcon'
import { CustomTypography } from 'packages/web/src/components/shared'
import React from 'react'

export default function L4HoldingAmountInvestedCard({InvAmt}: {InvAmt: string | undefined}) {
    return (
        <Grid padding="24px" sx={{ display: "flex", flexDirection: 'column', height: '100%' }}>
            <Grid sx={{ flex: "1 1 100%" }}>
                <RupeeIcon width="48px" height="48px" />
            </Grid>
            <Grid sx={{ flex: '1 1 100%',marginTop:'34px' }}>
                <CustomTypography variant='Regular18' sx={{ color: { xs: "#64677A" } }}>Amount Invested</CustomTypography>
                <CustomTypography variant='Regular24'>{formatDecimalWithRupeeSymbol(InvAmt, 2, 'IN')}</CustomTypography>
            </Grid>
        </Grid>
    )
}
