import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import CustomTypography from '../customTypography';
import { Fragment } from 'react';

// const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
interface IstepsProps {
    steps: string[],
    children?: JSX.Element,
    setSkipped: React.Dispatch<React.SetStateAction<Set<number>>>,
    skipped: Set<number>,
    activeStep: number,
    setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

export default function HorizontalStepper({ steps, children, activeStep, setSkipped, skipped, setActiveStep }: IstepsProps) {
    // const [activeStep, setActiveStep] = React.useState(0);
    // const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Stepper activeStep={activeStep} connector={null}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}
                            sx={{
                                cursor: 'pointer',
                                '& .MuiStepLabel-root .Mui-completed': {
                                    color: '#252833',
                                    border: 'none'
                                },
                                '& .MuiStepLabel-root .Mui-active': {
                                    color: '#252833',
                                    border: 'none'
                                },
                                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                    fill: '#FFFFFF',
                                },
                                '& .MuiStepLabel-root .MuiStepIcon-text': {
                                    fill: '#BABDCC'
                                },
                                '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root': {
                                    color: '#FFFFFF',
                                    border: '1px solid #BABDCC',
                                    borderRadius: '50%'
                                }
                            }}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}

            </Stepper>

            <Grid>
                {activeStep === steps.length ? (
                    <Fragment>
                        <CustomTypography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </CustomTypography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </Fragment>
                ) : (
                    <Fragment>
                        {children}
                    </Fragment>
                )}
            </Grid>
        </Box>
    );
}



