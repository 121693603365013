import { Box, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { ReportsIcon } from '../../../assets/icons/ReportsIcon';
import ButtonWithTextAndIcon from '../../shared/button-with-label-and-icon';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fragment, useState } from 'react';
import CustomTypography from '../../shared/customTypography';
import { PerformanceIcon } from '../../../assets/icons/performanceIcon';
import { CapitalGainIcon } from '../../../assets/icons/capitalgainIcon';
import { TransactionIcon } from '../../../assets/icons/transactionIcon';
import { AdvancedIcon } from '../../../assets/icons/advancedIcon';
import { TaxSoftFormatIcon } from '../../../assets/icons/taxSoftFormatIcon';
import { IncomeOtherIcon } from '../../../assets/icons/incomeOtherIcon';
import CustomModal from '../../shared/custom-modal';
import SavedDetailsModal from '../shared/saved-details-modal';
import EditDetailsModal from '../shared/edit-details-modal';
import { SavedReportEmptyPlaceholder } from 'packages/web/src/assets/graphics/savedReportEmptyPlaceholder';
import ButtonPrimaryCTA from '../../shared/button-primary-cta';
import { PlusIcon } from 'packages/web/src/assets/icons/plusIcon';
import { ThreeDotsIcon } from 'packages/web/src/assets/icons/threeDotsIcon';
import { t } from 'i18next';

const savedReportData = [
  {
    icon: <PerformanceIcon />,
    reportName: "My portfolio summary FY 22-23",
    reportType: 'stocks',
    year: "22-23",
    summaryType: 'Performance Summary',
    summarySubtitle: 'Performance'
  },
  {
    icon: <CapitalGainIcon />,
    reportName: "Mprofit UI Report",
    reportType: 'stocks',
    year: "22-23",
    summaryType: 'Unrealized Capital Gains - Summary',
    summarySubtitle: 'Capital Gains'
  },
  {
    icon: <TransactionIcon />,
    reportName: "Capital Gains",
    reportType: 'stocks',
    year: "22-23",
    summaryType: 'Mutual fund Transactions',
    summarySubtitle: 'Transactions'
  },
  {
    icon: <AdvancedIcon />,
    reportName: "Balance sheet",
    reportType: 'stocks',
    year: "22-23",
    summaryType: 'Closing Balance',
    summarySubtitle: 'Advanced'
  },
  {
    icon: <TaxSoftFormatIcon />,
    reportName: "New report design",
    reportType: 'stocks',
    year: "22-23",
    summaryType: 'Income Report - Summary',
    summarySubtitle: 'Income & Other'
  },
  {
    icon: <IncomeOtherIcon />,
    reportName: "New report design",
    reportType: 'stocks',
    year: "22-23",
    summaryType: 'Income Report - Summary',
    summarySubtitle: 'Income & Other'
  }
]
export default function SavedReports() {

  const [data, setData] = useState(savedReportData)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [editDetails, setEditDetails] = useState<boolean>(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (e: any) => {
    console.log(e.target.value)
  }


  return (
    <Fragment>
      {
        true ?
          <Stack sx={{ borderRadius: "8px", margin: "0px 40px" }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '24px' }}>
              <CustomTypography variant='Medium24' >{t('reports.saved.title')}</CustomTypography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PlusIcon color="#5F6F57" />
                <CustomTypography variant="Medium16" sx={{ color: { xs: "#5F6F57" }, paddingLeft: '8px' }}>Add report</CustomTypography>
              </Box>
            </Box>
            <TableContainer sx={{ background: '#FFFFFF' }}>
              <Table>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">{row.icon}</TableCell>
                      <TableCell align="left">
                        <CustomTypography variant='Regular20'>{row.reportName}</CustomTypography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CustomTypography variant='Regular16' sx={style.reportType}> {row.reportType} </CustomTypography>
                            <Box style={style.dots}></Box>
                          </Box>
                          <CustomTypography variant='Regular16' sx={style.year}> FY {row.year}</CustomTypography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box sx={style.summaryContainer}>
                          <CustomTypography variant="Regular20">{row.summaryType}</CustomTypography>
                          <CustomTypography variant="Regular16" sx={style.summarySubtitle}>{row.summarySubtitle}</CustomTypography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <ButtonWithTextAndIcon
                          startIcon={<ReportsIcon />}
                          buttonlabel='Generate report'
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Box
                          onClick={handleClick}
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                        >
                          <Box
                            sx={{
                              width: '48px',
                              height: '48px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '4px',
                              '&:focus': {
                                background: '#CFE3C9',
                              },
                              '&:active': {
                                background: '#CFE3C9',
                              },
                              '&:hover': {
                                background: '#EBECF2',
                              }
                            }}>
                            <ThreeDotsIcon />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Paper sx={{ width: 320, maxWidth: '100%' }}>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => setOpenDetails(true)}>
                  <ListItemIcon>
                    <VisibilityIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('reports.saved.menu.view')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => setEditDetails(true)}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('reports.saved.menu.edit')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleMenu} sx={{ color: 'errorBase.primary' }}>
                  <ListItemIcon>
                    <DeleteIcon sx={{ color: 'errorBase.primary' }} fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('reports.saved.menu.delete')}</ListItemText>
                </MenuItem>
                {openDetails &&
                  <CustomModal open={openDetails} handleClose={() => setOpenDetails(false)}>
                    <SavedDetailsModal setOpenDetails={() => setOpenDetails(false)} />
                  </CustomModal>}

                {
                  editDetails &&
                  <CustomModal open={editDetails} handleClose={() => setEditDetails(false)}>
                    <EditDetailsModal setEditDetails={() => setEditDetails(false)} />
                  </CustomModal>
                }
              </Menu>
            </Paper>
          </Stack>
          :
          <Stack sx={{ display: 'flex', flexGrow: '1', justifyContent: 'center', alignItems: 'center', height: "100%", background: "inherit" }}>
            <SavedReportEmptyPlaceholder />
            <CustomTypography variant='Medium24' padding="24px 0px 16px 0px">{t('reports.saved.noData.title')}</CustomTypography>
            <CustomTypography variant="Regular18">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.</CustomTypography>
            <br />
            <Box>
              <ButtonPrimaryCTA startIcon={<PlusIcon />} buttonText={t('reports.saved.noData.button')}></ButtonPrimaryCTA>
            </Box>
          </Stack>
      }
    </Fragment >
  )
}


const style = {
  dots: {
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    background: '#64677A',
    border: '1px solid #707070',
    margin: '0px 12px'
  },
  reportType: {
    textTransform: 'uppercase',
    color: { xs: '#64677A' },
  },
  year: {
    textTransform: 'uppercase',
    color: { xs: '#64677A' },
  },
  summarySubtitle: {
    color: { xs: '#64677A' },
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
}