
export function Refresh(props: any) {
  return (
    <svg {...props} id="arrows-exchange" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <rect id="Rectangle_24101" data-name="Rectangle 24101" width="32" height="32" rx="3" fill="#010006" opacity="0.54" />
      <g id="Group_150697" data-name="Group 150697" transform="translate(2)">
        <rect id="Rectangle_24196" data-name="Rectangle 24196" width="20" height="20" transform="translate(4 6)" fill="#fff" opacity="0" />
        <path id="Path_198400" data-name="Path 198400" d="M17.193,11.039q0-1.611-.12-3.193A3.493,3.493,0,0,0,13.847,4.62a42.439,42.439,0,0,0-6.385,0A3.493,3.493,0,0,0,4.236,7.846q-.022.288-.04.577m13,2.616,2.616-2.616m-2.616,2.616L14.578,8.423M4.116,12.782q0,1.611.12,3.193A3.493,3.493,0,0,0,7.462,19.2a42.421,42.421,0,0,0,6.385,0,3.493,3.493,0,0,0,3.226-3.226q.022-.288.04-.577m-13-2.616L6.731,15.4M4.116,12.782,1.5,15.4" transform="translate(3 4)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>
  );
}

