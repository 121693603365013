import CustomTypography from "../customTypography";

const PMSBadge = ({ badgeText = 'PMS', badgeStyles = {} }) => {
    return (
        <CustomTypography
            variant='Medium14'
            sx={{ color: '#E2DA90', borderWidth: 1, borderColor: '#E2DA90', borderStyle: 'solid', borderRadius: '4px', padding: '2px 6px', ...badgeStyles }}>
            {badgeText}
        </CustomTypography>
    )
}

export default PMSBadge;