import { Button } from '@mui/material'


interface PillsButtonProps {
    buttonLabel: string,
    startIcon?: JSX.Element,
    handleClick: (tableType: string) => void,
    optionType: string,
    activeOptionType: string,
    height?: string
}

function PillsButton({ buttonLabel, startIcon, handleClick, optionType, activeOptionType, height }: PillsButtonProps) {
    var isActive = activeOptionType === optionType;
    return (
        <Button
            variant="contained"
            onClick={() => handleClick(optionType)}
            startIcon={startIcon}
            disableRipple
            sx={{
                padding: "14px 16px 15x 44px",
                borderRadius: "50px",
                height: height ? height : "40px",
                backgroundColor: isActive ? '#CFE3C9' : '#F9F9F9',
                textTransform: 'none',
                boxShadow: "none",
                color: "#64677A",
                '&:hover': {
                    backgroundColor: '#EBECF2',
                    boxShadow: "none"
                }
            }}
        >
            {buttonLabel}
        </Button>
    )
}

export default PillsButton