
export function IncomeOtherIcon(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
            <g id="Group_154218" data-name="Group 154218" transform="translate(-920 -138)">
                <rect id="Rectangle_24810" data-name="Rectangle 24810" width="64" height="64" transform="translate(920 138)" fill="none" />
                <g id="Group_154204" data-name="Group 154204" transform="translate(926.999 142)">
                    <g id="Group_154351" data-name="Group 154351" transform="translate(9.117 9.474) rotate(-11)">
                        <ellipse id="Ellipse_2316" data-name="Ellipse 2316" cx="12.719" cy="12.088" rx="12.719" ry="12.088" transform="translate(0 0)" fill="#acc18a" />
                        <path id="Path_235205" data-name="Path 235205" d="M236.524,119.8a10.956,10.956,0,0,1,11.1,10.147c.006-.149.01-.3.009-.451a11.059,11.059,0,0,0-22.088.152c0,.151.007.3.015.451A10.956,10.956,0,0,1,236.524,119.8Z" transform="translate(-223.443 -118.246)" fill="#101010" />
                        <path id="Path_235206" data-name="Path 235206" d="M236.66,152.977a10.808,10.808,0,0,1-11.1-9.97,4.175,4.175,0,0,0-.009.451c.423,6.138,6.021,9.775,11.112,9.74a10.912,10.912,0,0,0,10.977-9.892,3.587,3.587,0,0,0-.015-.451A10.809,10.809,0,0,1,236.66,152.977Z" transform="translate(-223.443 -131.6)" fill="#101010" />
                        <path id="Path_235207" data-name="Path 235207" d="M235.243,153.983a11.9,11.9,0,0,1-12.222-10.977c-.006.165-.011.33-.01.5.044,6.382,5.523,11.519,12.239,11.473s12.124-5.258,12.08-11.64c0-.167-.008-.331-.017-.5A11.9,11.9,0,0,1,235.243,153.983Z" transform="translate(-222.019 -131.592)" fill="#101010" />
                        <path id="Path_235215" data-name="Path 235215" d="M4.8,11.474a.508.508,0,0,1-.27-.066.883.883,0,0,1-.2-.18L.377,6.622a2.092,2.092,0,0,1-.148-.188A.481.481,0,0,1,.18,6.179V6a.354.354,0,0,1,.1-.262.354.354,0,0,1,.262-.1h.983a4.514,4.514,0,0,0,1.893-.311A1.747,1.747,0,0,0,4.3,4.491a2.828,2.828,0,0,0,.238-1.18A2.828,2.828,0,0,0,4.3,2.131a1.761,1.761,0,0,0-.852-.836A3.9,3.9,0,0,0,1.721.983H.361A.354.354,0,0,1,.1.885.354.354,0,0,1,0,.623V.361A.354.354,0,0,1,.1.1.354.354,0,0,1,.361,0H6.688A.354.354,0,0,1,6.95.1a.354.354,0,0,1,.1.262V.623a.354.354,0,0,1-.1.262.354.354,0,0,1-.262.1H4.294L3.508.525a2.243,2.243,0,0,1,1.623.992,3.512,3.512,0,0,1,.443,1.795,3.608,3.608,0,0,1-.361,1.606A2.668,2.668,0,0,1,4.024,6.1a4.687,4.687,0,0,1-2.2.443H1.41l-.115-.426L5.475,11a.331.331,0,0,1,.115.229.2.2,0,0,1-.1.188.447.447,0,0,1-.229.057ZM.361,3.606a.354.354,0,0,1-.262-.1A.354.354,0,0,1,0,3.245V2.983a.354.354,0,0,1,.1-.262.354.354,0,0,1,.262-.1H6.688a.338.338,0,0,1,.361.361v.262a.338.338,0,0,1-.361.361Z" transform="translate(9.623 6.351)" fill="#131100" />
                    </g>
                    <g id="Group_153091" data-name="Group 153091" transform="translate(0 27.004)">
                        <g id="Group_154219" data-name="Group 154219">
                            <path id="Path_207259" data-name="Path 207259" d="M130.432,157.831H87.784a1.974,1.974,0,0,1-1.984-1.984V130.684a1.974,1.974,0,0,1,1.984-1.984h42.647a1.974,1.974,0,0,1,1.984,1.984v25.162A1.974,1.974,0,0,1,130.432,157.831Z" transform="translate(-85.799 -128.7)" fill="#485d3e" stroke="#485d3e" strokeWidth="1" />
                            <line id="Line_733" data-name="Line 733" x2="1.423" transform="translate(41.861 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_734" data-name="Line 734" x2="1.423" transform="translate(38.941 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_735" data-name="Line 735" x2="1.498" transform="translate(35.834 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_736" data-name="Line 736" x2="1.498" transform="translate(32.838 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_737" data-name="Line 737" x2="1.46" transform="translate(30.105 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_738" data-name="Line 738" x2="1.423" transform="translate(27.484 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_739" data-name="Line 739" x2="1.348" transform="translate(25.051 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_740" data-name="Line 740" x2="1.273" transform="translate(22.617 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_741" data-name="Line 741" x2="1.236" transform="translate(19.92 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_742" data-name="Line 742" x2="1.423" transform="translate(17.037 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <path id="Path_207260" data-name="Path 207260" d="M205.2,132.4a1.787,1.787,0,0,1,.6.973" transform="translate(-160.492 -131.015)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_743" data-name="Line 743" x1="1.423" transform="translate(2.734 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_744" data-name="Line 744" x1="1.423" transform="translate(5.654 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_745" data-name="Line 745" x1="1.535" transform="translate(8.65 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_746" data-name="Line 746" x1="1.535" transform="translate(11.646 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_747" data-name="Line 747" x1="1.46" transform="translate(14.453 0.974)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_748" data-name="Line 748" x1="1.423" transform="translate(7.303 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_749" data-name="Line 749" x1="1.498" transform="translate(10.336 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_750" data-name="Line 750" x2="1.46" transform="translate(4.008 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_751" data-name="Line 751" x1="1.535" transform="translate(13.293 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_752" data-name="Line 752" x1="1.46" transform="translate(16.102 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_753" data-name="Line 753" x1="1.423" transform="translate(18.76 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_754" data-name="Line 754" x1="1.348" transform="translate(21.381 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_755" data-name="Line 755" x1="1.273" transform="translate(24.002 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_756" data-name="Line 756" x1="1.198" transform="translate(26.771 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_757" data-name="Line 757" x1="1.423" transform="translate(29.432 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_758" data-name="Line 758" x2="1.423" transform="translate(40.814 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_759" data-name="Line 759" x2="1.535" transform="translate(37.705 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_760" data-name="Line 760" x2="1.535" transform="translate(34.711 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_761" data-name="Line 761" x2="1.423" transform="translate(32.014 28.082)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <path id="Path_207261" data-name="Path 207261" d="M88.2,132.4a1.787,1.787,0,0,0-.6.973" transform="translate(-86.926 -131.015)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_762" data-name="Line 762" y2="1.236" transform="translate(45.344 25.012)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_763" data-name="Line 763" y2="1.273" transform="translate(45.344 22.428)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_764" data-name="Line 764" y2="1.31" transform="translate(45.344 19.732)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_765" data-name="Line 765" y2="1.348" transform="translate(45.344 17.111)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_766" data-name="Line 766" y2="1.236" transform="translate(45.344 14.752)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_767" data-name="Line 767" y2="1.198" transform="translate(45.344 12.468)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_768" data-name="Line 768" y2="1.161" transform="translate(45.344 10.334)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_769" data-name="Line 769" y2="1.123" transform="translate(45.344 8.2)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_770" data-name="Line 770" y2="1.236" transform="translate(45.344 5.654)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_771" data-name="Line 771" y2="1.198" transform="translate(45.344 3.52)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <path id="Path_207262" data-name="Path 207262" d="M203.723,202.1a2.4,2.4,0,0,1-1.123.524" transform="translate(-158.865 -174.617)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_772" data-name="Line 772" y2="1.236" transform="translate(0.713 25.012)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_773" data-name="Line 773" y2="1.273" transform="translate(0.713 22.428)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_774" data-name="Line 774" y2="1.31" transform="translate(0.713 19.732)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_775" data-name="Line 775" y2="1.348" transform="translate(0.713 17.111)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_776" data-name="Line 776" y2="1.236" transform="translate(0.713 14.752)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_777" data-name="Line 777" y2="1.198" transform="translate(0.713 12.468)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_778" data-name="Line 778" y2="1.161" transform="translate(0.713 10.334)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_779" data-name="Line 779" y2="1.123" transform="translate(0.713 8.2)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_780" data-name="Line 780" y2="1.236" transform="translate(0.713 5.654)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <line id="Line_781" data-name="Line 781" y2="1.198" transform="translate(0.713 3.52)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <path id="Path_207263" data-name="Path 207263" d="M89,202.1a2.4,2.4,0,0,0,1.123.524" transform="translate(-87.801 -174.617)" fill="none" stroke="#fff" strokeWidth="0.289" />
                            <path id="Path_207264" data-name="Path 207264" d="M173.242,162.123H153.135a1.518,1.518,0,0,1-1.535-1.535v-6.253a1.518,1.518,0,0,1,1.535-1.535h20.107a1.518,1.518,0,0,1,1.535,1.535v6.253A1.518,1.518,0,0,1,173.242,162.123Z" transform="translate(-126.961 -143.776)" fill="#101010" />
                            <circle id="Ellipse_2117" data-name="Ellipse 2117" cx="2.134" cy="2.134" r="2.134" transform="translate(26.549 11.532)" fill="#485d3e" />
                        </g>
                    </g>
                    <path id="Path_206203" data-name="Path 206203" d="M338.788,580.7a.294.294,0,0,1,.292.266c.312,3.275,1.91,3.529,2.392,3.529a.834.834,0,0,0,.088,0,.294.294,0,0,1,.1.579,3.009,3.009,0,0,0-2.312,1.969,4.328,4.328,0,0,0-.267,1.581.294.294,0,0,1-.586.043c-.3-3.487-2.437-3.586-2.459-3.586a.294.294,0,0,1-.054-.581,2.928,2.928,0,0,0,2.182-1.919,4.863,4.863,0,0,0,.329-1.583.294.294,0,0,1,.28-.293Zm1.719,4.161a2.574,2.574,0,0,1-.633-.418,3.926,3.926,0,0,1-1.087-1.853c-.021.061-.044.123-.068.185a3.623,3.623,0,0,1-1.724,1.984,2.982,2.982,0,0,1,.477.3,3.856,3.856,0,0,1,1.281,1.9q.018-.052.037-.1A3.6,3.6,0,0,1,340.507,584.861Z" transform="translate(-335.372 -565.903)" />
                    <path id="Path_206203-2" data-name="Path 206203" d="M338.4,580.7a.256.256,0,0,1,.255.232c.272,2.854,1.665,3.076,2.085,3.076a.725.725,0,0,0,.076,0,.256.256,0,0,1,.087.5,2.622,2.622,0,0,0-2.015,1.716,3.77,3.77,0,0,0-.232,1.378.256.256,0,0,1-.511.037c-.262-3.039-2.124-3.125-2.143-3.125a.256.256,0,0,1-.047-.507,2.552,2.552,0,0,0,1.9-1.672,4.236,4.236,0,0,0,.287-1.38.256.256,0,0,1,.244-.255Zm1.5,3.626a2.238,2.238,0,0,1-.552-.364,3.422,3.422,0,0,1-.948-1.615c-.018.053-.038.107-.06.161a3.159,3.159,0,0,1-1.5,1.729,2.594,2.594,0,0,1,.415.258,3.361,3.361,0,0,1,1.117,1.658c.01-.03.021-.061.032-.091A3.136,3.136,0,0,1,339.9,584.327Z" transform="translate(-291.302 -570.468)" />
                    <path id="Path_234943" data-name="Path 234943" d="M395.221,1030.38s0,5.39-4.621,6.38c0,0,4.07.11,4.621,6.49,0,0-.33-5.39,4.73-6.49C399.952,1036.76,395.881,1037.31,395.221,1030.38Z" transform="translate(-353.533 -1030.38)" fill="#5f854c" />
                </g>
            </g>
        </svg>

    );
}