import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { barColorFill, renderCustomizedLabel, tickFormatter } from "../../../shared/xirr-bar-chart";
import { SharedConstants, SharedRedux, SharedUtilities, formatDateForReadingWithTime, formatPercent } from "@mprofit/shared";
import { AxisDomain } from "recharts/types/util/types";
import useWindowDimensions from "../../../shared/utilities/windowDimensions";
import { Box, Grid, Stack, useTheme } from "@mui/material";
import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { Dispatch, useEffect, useState } from "react";
import { DefaultTableIcon } from "packages/web/src/assets/icons/DefaultTableIcon";
import { CustomTypography } from "../../../shared";
import ButtonPrimaryCTA from "../../../shared/button-primary-cta";
import { Edit, RemoveRedEyeOutlined } from "@mui/icons-material";
import Lottie from "react-lottie";
import * as animationData from '../../../../assets/animation/assetWisePerformanceAnimation.json'
import ChangeSingleIndexModal from "../../shared/change-single-index-modal";
import ModalButton from "../../../shared/modalButton";
import {
  Bar,
  CartesianGrid,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
  BarChart
} from "recharts";
import PrimaryIconButton from "../../../shared/primary-icon-button";
import CachedIcon from '@mui/icons-material/Cached';
import { InfoIconTooltip } from "../../../shared/info-icon-tooltip";
import styles from './index.module.css';
import { BetaTag } from "../../../betaTag";
import { DownloadPDF } from "../../../download-pdf";
import { CompleteChartPage, PdfBarChart } from "@mprofit-soft/pdf";
import { onClickContactSupportForUpgrade } from "packages/web/src/classes/Utilities";
import { XIRRBenchmarkChartPDFDownloadButton } from "./pdf";
import { ChartsSelectors } from "packages/web/src/redux/selectors/chartsSelectors";
import { SetPerformancePeriodModal } from "../../shared/set-performance-period-modal";

interface XIRRBenchmarkChartProps {
    MPrUrlParams: MPrUrlParams;
    OnRefreshXIRRBenchmark: (
      urlParams: MPrUrlParams,
      dispatch: Dispatch<any>,
      force?: boolean
    ) => void;
    IsPerformanceViewAllowed: boolean;
    ShowLockedMessage?: boolean;
}
interface XIRRBenchmarkChartHeaderProps {
    IsPerformanceViewAllowed: boolean;
    onRefresh?: () => void;
    ShowLockedMessage?: boolean;
    AxisDomain?: AxisDomain;
    isChangeIndexModalOpen: boolean;
    setIsChangeIndexModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface XIRRBenchmarkBarChartProps {
  data?: SharedConstants.BarChartData[];
  chartLayout: 'vertical' | 'horizontal';
  chartHeight: number;
  chartWidth: number;
  chartStyle: any;
  barSizeVal: number;
  domain: AxisDomain;
  isFirstBarBlack: boolean;
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
  
var chartData: SharedConstants.BarChartData[] | undefined = undefined;

export default function XIRRBenchmarkChart(props: XIRRBenchmarkChartProps) {
    const dimension = useWindowDimensions();
    const dispatch = useAppDispatch();
    const xirrBenchmarkIsLoading = useAppSelector(SharedRedux.Performance.Selectors.selectXIRRBenchmarkIsLoading);
    const isBenchmarkPricesNotAvailable = useAppSelector(SharedRedux.Performance.Selectors.selectXIRRBenchmarkIsBenchmarkPricesNotAvailable);
    const lastRefreshedTimeTotal = useAppSelector(SharedRedux.Performance.Selectors.selectXIRRBenchmarkLastRefreshedTime);

    const xirrBenchmarkSingleIndex = useAppSelector(
      SharedRedux.Dashboard.Selectors.selectXIRRBenchmarkSingleIndex
    );

    const [isChangeIndexModalOpen, setIsChangeIndexModalOpen] = useState(false);
    const [showPerformancePeriodModal, setShowPerformancePeriodModal] = useState<boolean>(false);

    const theme = useTheme();
    const lgBreakpointValue = theme.breakpoints.values.lg1;

    const onRefresh = () => {
      props.OnRefreshXIRRBenchmark(props.MPrUrlParams, dispatch, false);
    }
    
    const skeletonData = [
      {
          Name: "My XIRR",
          Value: 0.1574,
      },
      {
          Name: `${xirrBenchmarkSingleIndex?.AssetName || "Benchmark"} XIRR`,
          Value: 0.09,
      }
    ]

    const { chartData, domain } = useAppSelector(ChartsSelectors.selectXIRRBenchmarkChartData);

    if (chartData) {
        return (
          <>
            <XIRRBenchmarkChartHeader IsPerformanceViewAllowed={props.IsPerformanceViewAllowed} onRefresh={onRefresh} ShowLockedMessage={props.ShowLockedMessage} AxisDomain={domain} isChangeIndexModalOpen={isChangeIndexModalOpen} setIsChangeIndexModalOpen={setIsChangeIndexModalOpen}/>
            <XIRRBenchmarkBarChart
              key={'xirr-bar-chart'}
              data={chartData}
              chartHeight={dimension.width < lgBreakpointValue ? 460 : 530}
              chartWidth={650}
              barSizeVal={40}
              domain={domain}
              chartLayout="vertical"
              chartStyle={{}}
              isFirstBarBlack={true}
            />
            {
              lastRefreshedTimeTotal &&
              <Box
                className={styles.refresh}>
                <Box>
                  <PrimaryIconButton
                    icon={<CachedIcon />}
                    handleClick={onRefresh}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: '5px' }}>
                  <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A' } }}>
                    Last Refreshed
                  </CustomTypography>
                  <CustomTypography variant='Medium16' sx={{ color: { xs: '#64677A' } }}>
                    {formatDateForReadingWithTime(lastRefreshedTimeTotal)}
                  </CustomTypography>
                </Box>
              </Box>
            }
          </>
        )
    }

    return (
        <>
        <XIRRBenchmarkChartHeader IsPerformanceViewAllowed={props.IsPerformanceViewAllowed} ShowLockedMessage={props.ShowLockedMessage} isChangeIndexModalOpen={isChangeIndexModalOpen} setIsChangeIndexModalOpen={setIsChangeIndexModalOpen}/>
        <Box sx={style.overLayContainer}>
        <Box sx={style.graphOverlay}>
          <Box sx={style.overlayItem}>
            {
              xirrBenchmarkIsLoading ?
                <>
                  <Box sx={style.animationCont}>
                    <Lottie options={defaultOptions} height={256} width={256} />
                    <Box sx={style.animationStatic} >
                      <DefaultTableIcon />
                    </Box>
                  </Box>
                  <CustomTypography>{'Calculating your benchmark XIRR'}</CustomTypography>
                </>
                :
                <Stack>
                    <DefaultTableIcon style={{ margin: "auto" }} />
                    {
                      props.ShowLockedMessage
                      ?
                      <>
                      <CustomTypography variant='Medium24' sx={{ marginTop: '40px' }}>{'Feature Locked'}</CustomTypography>
                      <CustomTypography variant='Regular18'>{'Upgrade your plan to use this feature'}</CustomTypography>
                      <Box paddingTop="17px">
                      <ButtonPrimaryCTA
                          disabled={props.ShowLockedMessage}
                          handleClick={onClickContactSupportForUpgrade}
                          // startIcon={<RemoveRedEyeOutlined />}
                          buttonText={'Contact MProfit'}
                      />
                      </Box>
                      </>
                      :
                      (
                        isBenchmarkPricesNotAvailable ?
                        <>
                        <CustomTypography variant='Medium24' sx={{ marginTop: '40px', marginLeft: '50px', marginRight: '50px' }}>{'Index data not available for very old dates in the selected time period. Please select a different time period for this index.'}</CustomTypography>
                        <Box paddingTop="17px">
                        <ButtonPrimaryCTA
                            handleClick={() => setShowPerformancePeriodModal(true)}
                            startIcon={<Edit />}
                            buttonText={'Change Period'}
                        />
                        </Box>
                        {
                          showPerformancePeriodModal && 
                          <SetPerformancePeriodModal showPerformancePeriodModal={showPerformancePeriodModal} setShowPerformancePeriodModal={setShowPerformancePeriodModal} />
                        }
                        </>
                        :
                        <>
                        <CustomTypography variant='Medium24' sx={{ marginTop: '40px' }}>{'Compare your XIRR against the Index'}</CustomTypography>
                        <Box paddingTop="17px">
                        <ButtonPrimaryCTA
                            disabled={!props.IsPerformanceViewAllowed}
                            handleClick={onRefresh}
                            startIcon={<RemoveRedEyeOutlined />}
                            buttonText={'View Chart'}
                        />
                        </Box>
                        </>
                      )
                    }

                </Stack>
            }
          </Box>
        </Box>
        <Grid sx={{ width: '100%', height: '100%', position: 'relative' }}>
          <Box sx={style.blurOverlay}></Box>
          <XIRRBenchmarkBarChart
            key={'xirr-bar-chart'}
            data={skeletonData}
            chartHeight={dimension.width < lgBreakpointValue ? 460 : 530}
            chartWidth={650}
            barSizeVal={40}
            chartLayout="vertical"
            chartStyle={{}}
            domain={[-0.3, 0.3]}
            isFirstBarBlack={true}
          />
        </Grid>
      </Box>
        </>
    )
}

const XIRRBenchmarkChartHeader = ({ IsPerformanceViewAllowed, onRefresh, ShowLockedMessage, AxisDomain, isChangeIndexModalOpen, setIsChangeIndexModalOpen }: XIRRBenchmarkChartHeaderProps) => {

  const xirrBenchmarkSingleIndex = useAppSelector(
    SharedRedux.Dashboard.Selectors.selectXIRRBenchmarkSingleIndex
  );
  // const lastRefreshedTimeTotal = useAppSelector(SharedRedux.Performance.Selectors.selectXIRRBenchmarkLastRefreshedTime);

  return (
    <>
      <Box sx={{ px: 4, pt: 2 }}>
        <Box sx={style.headingContainer}>
          <Box display="flex" gap={1.5} alignItems="center">
            <CustomTypography variant="Medium24">
              XIRR Comparison
            </CustomTypography>

            {onRefresh && <XIRRBenchmarkChartPDFDownloadButton />}

          {/* {
            onRefresh ? 
            <PrimaryIconButton
              icon={<CachedIcon />}
              handleClick={onRefresh}
            />
            : null
          } */}
          </Box>
          {/* <BetaTag/> */}
          <ModalButton
            text="Change Index"
            handleClick={() => setIsChangeIndexModalOpen(true)}
            disabled={!IsPerformanceViewAllowed}
          />
        </Box>
        <Box display="flex" gap={'10px'}>
          <CustomTypography variant="Regular16">
            This chart shows how your XIRR compares against equivalent cashflows in the index
          </CustomTypography>
          <InfoIconTooltip tooltipLines={[
            'The Index XIRR is computed by replicating the same cashflows in the index, that were made in your actual investment.',
            'This helps you understand how your investment performance compares against a similar notional investment in the index.']}/>
        </Box>
        {/* {
          lastRefreshedTimeTotal ? 
          <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }}>Last refreshed <span style={{ fontFamily: "Rubik-Medium", fontWeight: 500 }}>{formatDateForReadingWithTime(
            lastRefreshedTimeTotal
          )}</span></CustomTypography> : null
        } */}
          
      </Box>
      <ChangeSingleIndexModal
        isModalOpen={isChangeIndexModalOpen}
        setIsModalOpen={setIsChangeIndexModalOpen}
        initialActiveSingleIndex={xirrBenchmarkSingleIndex}
        source="XIRR_BENCHMARK_CHART" // FIXME: consume it from constants
      />      
    </>
  )
}

const XIRRBenchmarkBarChart = (props: XIRRBenchmarkBarChartProps) => {

  const {
    chartLayout,
    chartHeight,
    chartWidth,
    chartStyle,
    barSizeVal,
  } = props;

  const [isAnimationActive, setIsAnimationActive] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (props.data && props.data.length > 0 && isAnimationActive) {
        setIsAnimationActive(false);
      }
    }, 1500)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  const onAnimationEnd = () => {
    setTimeout(() => {
      if (props.data && props.data.length > 0 && isAnimationActive) {
        setIsAnimationActive(false);
      }
    }, 500)
  }


  return (
    <Box sx={{ width: '100%', height: chartHeight, paddingTop: '16px', }} className={props.isFirstBarBlack ? 'rechart-firstYitem-black' : undefined}>
      <ResponsiveContainer>
        <BarChart
          style={chartStyle}
          layout={chartLayout}
          width={chartWidth}
          height={chartHeight}
          data={props.data}
          margin={{
            top: 20,
            right: 50,
            bottom: 20,
            left: 50,
          }}
        >
          <CartesianGrid vertical horizontal={false} fillOpacity={0.8} stroke="#EBECF2" />
          <XAxis tickLine={false} axisLine={false} type="number" domain={props.domain || [-1, 1]} tickFormatter={(tick) => { return `${formatPercent(tick, 0, 'IN')}` }} fontFamily="rubik"/>
          <YAxis
            tickLine={false}
            axisLine={false}
            tick={{ dx: -100 }}
            fontFamily="rubik"
            dataKey="Name"
            orientation="left"
            type="category"
            tickFormatter={tickFormatter}
            tickMargin={10}
            width={100}
            textAnchor="start"
          />
          <Bar radius={[4, 8, 8, 4]} dataKey="Value" barSize={barSizeVal}
            isAnimationActive={isAnimationActive}
            onAnimationEnd={onAnimationEnd}
          >
            {props.data?.map((item, index: any) => (
              <Cell key={index} fill={barColorFill(item.Value, index, props.isFirstBarBlack)} />
            ))}
            <LabelList dataKey={'Value'} content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
} 

const style = {
    blurOverlay: {
      width: '91%',
      height: '100%',
      position: 'absolute',
      opacity: '0.9',
      backdropFilter: 'blur(7px)',
      zIndex: 1,
      left: '150px',
      bottom: '15%'
    },
    overLayContainer: {
      width: '100%',
      height: 'inherit',
      overflowY: 'hidden',
      position: 'relative',
      '::-webkit-scrollbar': {
        width: '1px'
      }
    },
    graphOverlay: {
      width: { xs: '70%', lg1: '80%', xl: '75%' },
      zIndex: 2,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    overlayItem: {
      textAlign: 'center'
    },
    animationCont: {
      margin: 'auto',
      width: '256px',
      height: '256px',
      position: 'relative'
    },
    animationStatic: {
      position: 'absolute',
      top: '80px',
      left: '-10px'
    },
    headingContainer: {display: 'flex',  flex: 1, flexDirection: 'row', alignItems: 'center',  justifyContent: 'space-between'},
    refreshButton: {
      height: 40,
      width: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'brandBase.100',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'brandBase.100'
      },
      "&:disabled": {
          backgroundColor: 'brandBase.100'
      },
      "&:focus": {
          backgroundColor: 'brandBase.100'
      }
    }
  }