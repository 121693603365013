import { StockTransactionReportTypes, MFTransactionReportTypes, MPrReportNumber, ITRTaxSoftwareType } from '../constants';
import { AssetTypeEnum } from '../constants';
import { getGrandfatheringFlagForBuyback, getMFTransReportSortDir, getMFTransReportSortType } from './report-computations';

const InvReportCustomisationFields = {
    [MPrReportNumber.PortfolioSummary]: {
        title: "Portfolio Summary",
        reportNumber: MPrReportNumber.PortfolioSummary,
        isAsOfDate: true,
        extra: {
            historicalRestricted: true,
            historicalRestrictedMesssage: 'You will be able to view historical amount invested but will not be able to view historical valuations. To view historical valuations, please upgrade to MProfit Pro or higher.',
            historicalAllowedForAdvisorPremiumClients: true,
            historicalRestrictedFieldToUpdate: {
                fieldName: 'HideCurrVals',
                valueIfRestricted: true
            }
        },
        custom: [
            {
                type: 'reportDate',
                data: {
                    key: 'period',
                    label: 'As of Date',
                    required: true,
                    extra: {
                        endOfMonthToDate: true,
                        // historicalRestricted: [{licType: LicenseType.INVESTOR, allowIfValid: false}, {licType: LicenseType.INVESTORPLUS, allowIfValid: false}, {licType: LicenseType.PRO, allowIfValid: true}, {licType: LicenseType.ADVISOR, allowIfValid: true}],
                        // historicalRestrictedMesssage: 'You will be able to view historical amount invested but will not be able to view historical valuations. To view historical valuations, please upgrade to MProfit Pro or higher.',
                        // historicalAllowedForAdvisorPremiumClients: true,
                        // historicalRestrictedFieldToUpdate: {
                        //     fieldName: 'HideCurrVals',
                        //     valueIfRestricted: true
                        // }
                    },
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Filter by Asset Type',
                    value: 0,
                    required: true,
                    dynamic: true,
                    order: 1,
                    showByDefault: true
                }
            },
            // {
            //     type: 'dropdown',
            //     data: {
            //         key: 'Goals',
            //         label: 'Select Goal',
            //         value: null,
            //         required: true,
            //         dynamic: true,
            //         order: 1
            //     }
            // },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Amount Invested'},
                        {id: 2,  text: 'Current Value'},
                        {id: 3,  text: 'Today\'s Gain'},
                        {id: 4,  text: 'Today\'s Gain %'},
                        {id: 5,  text: 'Unrealised Gain'},
                        {id: 6,  text: 'Unrealised Gain %'}
                    ],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ExpAll',
                    label: 'Expand All',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowTGain',
                    label: 'Today\'s Gain',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'HideCurrVals',
                    label: 'Current Values',
                    required: true,
                    value: false,
                    options: [
                        {id: false,  text: 'Show'},
                        {id: true,  text: 'Hide'}
                    ],
                    order: 6,
                    extra: {
                        requiresCurrentValueAccess: true
                    }
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowRefno',
                    label: 'Folio/Ref No.',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 7,
                    assetTypes: [0, 60, 61, 141, 140, 66, 95, 70, 115, 120, 135, 75, 77, 155, 150, 145, 160, 195]
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowNegVals',
                    label: 'Negative Values',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 8
                }
            }
        ]
    },
    [MPrReportNumber.HistoricalValuation]: {
        title: "Historical Valuation",
        reportNumber: MPrReportNumber.HistoricalValuation,
        isAsOfDate: true,
        isHistoricalDate: true,
        custom: [
            {
                type: 'reportDate',
                data: {
                    key: 'period',
                    label: 'As of Date',
                    required: true,
                    extra: {
                        endOfMonthToDate: true,
                        hideCustomDate: true,
                        isHistoricalDate: true,
                        isHistoricalValuation: true
                    },
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'}
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            // {
            //     type: 'dropdown',
            //     data: {
            //         key: 'Goals',
            //         label: 'Select Goal',
            //         value: null,
            //         required: true,
            //         dynamic: true,
            //         order: 1
            //     }
            // },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Amount Invested'},
                        {id: 2,  text: 'Current Value'},
                        {id: 3,  text: 'Today\'s Gain'},
                        {id: 4,  text: 'Today\'s Gain %'},
                        {id: 5,  text: 'Unrealised Gain'},
                        {id: 6,  text: 'Unrealised Gain %'}
                    ],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ExpAll',
                    label: 'Expand All',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowTGain',
                    label: 'Today\'s Gain',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowRefno',
                    label: 'Folio/Ref No.',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 7,
                    assetTypes: [60,61]
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowNegVals',
                    label: 'Negative Values',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 8
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'IsHistoricalValuation',
                    label: 'IsHistoricalValuation',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5,
                    hidden: true
                }
            },
        ]
    },
    [MPrReportNumber.CapitalGainSummary]: {
        title: "Capital Gains Summary",
        reportNumber: MPrReportNumber.CapitalGainSummary,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 5060,  text: 'Stocks & Mutual Funds (Equity)'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'},
                        {id: 63,  text: 'InvITs & REITs'},
                        {id: 40,  text: 'Traded Bonds'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Sale Amount'},
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectGain',
                    label: 'Capital Gain Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 1,  text: 'Intraday'},
                        {id: 2,  text: 'Short-Term'},
                        {id: 3,  text: 'Long-Term'},
                    ],
                    order: 2
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'BondType',
                    label: 'Bond Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 1,  text: 'Sovereign Bonds'},
                        {id: 2,  text: 'Traded Bonds'},
                    ],
                    assetTypes: [40],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'PeriodView',
                    label: 'Period-wise Details',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowGrndFathrGain',
                    label: 'With Grandfathering',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50,5060,60],
                    order: 6
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowIndexation',
                    label: 'With Indexation',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [61,40],
                    order: 6
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [5060,60,61],
                    order: 7
                }
            }
        ]
    },
    [MPrReportNumber.CapitalGainsITR]: {
        title: "Capital Gains ITR Format",
        reportNumber: MPrReportNumber.CapitalGainsITR,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true
                    },
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'},
                        {id: 63,  text: 'InvITs & REITs'},
                        {id: 40,  text: 'Traded Bonds'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 2,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Sale Amount'},
                        {id: 2, text: 'Date'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectGain',
                    label: 'Capital Gain Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 1,  text: 'Intraday'},
                        {id: 2,  text: 'Short-Term'},
                        {id: 3,  text: 'Long-Term'},
                    ],
                    order: 2
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'BondType',
                    label: 'Bond Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 1,  text: 'Sovereign Bonds'},
                        {id: 2,  text: 'Traded Bonds'},
                    ],
                    assetTypes: [40],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowGrndFathrGain',
                    label: 'With Grandfathering',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50,5060,60],
                    order: 6,
                    computations: {
                        computationFunction: getGrandfatheringFlagForBuyback,
                        order: 1,
                        dependantFields: ['ShowBuyBack']
                    }
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowIndexation',
                    label: 'With Indexation',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [61,40],
                    order: 6
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [5060,60,61],
                    order: 7
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayISINNO',
                    label: 'ISIN No',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    // assetTypes: [5060,60,61],
                    order: 8
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowBuyBack',
                    label: 'Only buyback gain/loss after July 5, 2019',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50],
                    order: 8
                }
            }
        ]
    },
    [MPrReportNumber.XIRR]: {
        title: "Annualised Return (XIRR)",
        reportNumber: MPrReportNumber.XIRR,
        custom: [
            {
                type: 'label',
                data: {
                    key: 'periodText',
                    label: 'As of Date',
                    required: true,
                    value: 'Today',
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 0, text: 'All Assets'},
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Current Value'},
                        {id: 2, text: 'Total Gain'},
                        {id: 3, text: 'Annualised Return (XIRR)'}
                    ],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowZeroVals',
                    label: 'Zero Values',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowTGain',
                    label: 'Total Gain Columns',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowPurDate',
                    label: 'Purchase Date',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 6
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio / Policy / Ref No.',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [0,60,61],
                    order: 7
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ExpAll',
                    label: 'Expand Asset Types',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 8
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowQuant',
                    label: 'Quantity Columns',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 9
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowTotals',
                    label: 'Totals',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 10
                }
            }
        ]
    },
    [MPrReportNumber.GroupXIRRSummary]: {
        title: "Group XIRR Summary",
        reportNumber: MPrReportNumber.GroupXIRRSummary,
        custom: [
            {
                type: 'label',
                data: {
                    key: 'periodText',
                    label: 'As of Date',
                    required: true,
                    value: 'Today',
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 0, text: 'All Assets'},
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Current Value'},
                        {id: 2, text: 'Total Gain'},
                        {id: 3, text: 'Annualised Return (XIRR)'}
                    ],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowZeroVals',
                    label: 'Zero Values',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowTGain',
                    label: 'Total Gain Columns',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ExpAll',
                    label: 'Expand Asset Types',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 8
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowQuant',
                    label: 'Quantity Columns',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 9
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowTotals',
                    label: 'Totals',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 10
                }
            }
        ]
    },
    [MPrReportNumber.GroupXIRRDetailed]: {
        title: "Group XIRR Detailed",
        reportNumber: MPrReportNumber.GroupXIRRDetailed,
        custom: [
            {
                type: 'label',
                data: {
                    key: 'periodText',
                    label: 'As of Date',
                    required: true,
                    value: 'Today',
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 0, text: 'All Assets'},
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Current Value'},
                        {id: 2, text: 'Total Gain'},
                        {id: 3, text: 'Annualised Return (XIRR)'}
                    ],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowZeroVals',
                    label: 'Zero Values',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowTGain',
                    label: 'Total Gain Columns',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ExpAll',
                    label: 'Expand Asset Types',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 8
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowQuant',
                    label: 'Quantity Columns',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 9
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowTotals',
                    label: 'Totals',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 10
                }
            }
        ]
    },
    [MPrReportNumber.DueDates]: {
        title: "Due Dates",
        reportNumber: MPrReportNumber.DueDates,
        custom: [
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 0,
                    required: true,
                    dynamic: true,
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectDescription',
                    label: 'Description Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 0, text: 'All'},
                        {id: 1,  text: 'Premium Due'},
                        {id: 2,  text: 'Maturity'},
                        {id: 3,  text: 'Lock-In Period Complete'},
                    ],
                    order: 2,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'Date'},
                        {id: 1,  text: 'Asset Name'},
                        {id: 3, text: 'Description'},
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'GroupByDescription',
                    label: 'Group by Description',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    order: 5
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowPastDueDates',
                    label: 'Display Past Due Dates',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    extra: {
                        setFieldWhenSet: {
                            field: 'ShowNextSixMonthDueDates',
                            setValue: true,
                            defaultValue: false
                        }
                    },
                    order: 6
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowNextSixMonthDueDates',
                    label: 'Limit to Next 6 Months',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    extra: {
                        setFieldWhenSet: {
                            field: 'ShowPastDueDates',
                            setValue: true,
                            defaultValue: false
                        }
                    },
                    order: 7
                }
            },
        ]
    },
    [MPrReportNumber.ClosingBalance]: {
        title: "Closing Balance",
        reportNumber: MPrReportNumber.ClosingBalance,
        setASubTypeManually: true,
        isAsOfDate: true,
        custom: [
            {
                type: 'reportDate',
                data: {
                    key: 'period',
                    label: 'As of Date',
                    required: true,
                    extra: {
                        endOfMonthToDate: true
                    },
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds'}
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ASubType',
                    label: 'Select MF Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 1,  text: 'Equity'},
                        {id: 2,  text: 'Debt'}
                    ],
                    order: 1,
                    assetTypes: [60],
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Assets',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayISINNo',
                    label: 'ISIN No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowNegVals',
                    label: 'Negative Values',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio / Policy / Ref No.',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [60],
                    order: 6
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'HideCurrVals',
                    label: 'Current Values',
                    required: true,
                    value: false,
                    options: [
                        {id: false,  text: 'Show'},
                        {id: true,  text: 'Hide'}
                    ],
                    order: 8,
                    extra: {
                        requiresCurrentValueAccess: true
                    }
                }
            }
        ]
    },
    [MPrReportNumber.HoldingPeriod]: {
        title: "Holding Period",
        reportNumber: MPrReportNumber.HoldingPeriod,
        setASubTypeManually: true,
        isAsOfDate: true,
        custom: [
            {
                type: 'label',
                data: {
                    key: 'periodText',
                    label: 'As of Date',
                    required: true,
                    value: 'Today',
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds'}
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ASubType',
                    label: 'Select MF Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 1,  text: 'Equity'},
                        {id: 2,  text: 'Debt'}
                    ],
                    order: 1,
                    assetTypes: [60],
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Assets',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayISINNo',
                    label: 'ISIN No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowNegVals',
                    label: 'Negative Values',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio / Policy / Ref No.',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [60],
                    order: 6
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'HideCurrVals',
                    label: 'Current Values',
                    required: true,
                    value: false,
                    options: [
                        {id: false,  text: 'Show'},
                        {id: true,  text: 'Hide'}
                    ],
                    order: 8,
                    extra: {
                        requiresCurrentValueAccess: true
                    }
                }
            }
        ]
    },
    [MPrReportNumber.StockRegister]: {
        title: "Stock Register",
        reportNumber: MPrReportNumber.StockRegister,
        setASubTypeManually: true,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ASubType',
                    label: 'Select MF Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 1,  text: 'Equity'},
                        {id: 2,  text: 'Debt'}
                    ],
                    order: 1,
                    assetTypes: [60],
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'Asset-wise'},
                    ],
                    order: 3,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Asset-wise',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ExpAll',
                    label: 'Expand All',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    order: 3,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Show Folio No',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    order: 3,
                    hidden: true
                }
            }
        ]
    },
    [MPrReportNumber.UnrealisedCapitalGainsSummary]: {
        title: "Unrealised Capital Gains Summary",
        reportNumber: MPrReportNumber.UnrealisedCapitalGainsSummary,
        custom: [
            {
                type: 'label',
                data: {
                    key: 'periodText',
                    label: 'As of Date',
                    required: true,
                    value: 'Today',
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 5060,  text: 'Stocks & Mutual Funds (Equity)'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'},
                        {id: 63,  text: 'InvITs & REITs'},
                        {id: 40,  text: 'Traded Bonds'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Sale Amount'},
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectGain',
                    label: 'Capital Gain Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 1,  text: 'Intraday'},
                        {id: 2,  text: 'Short-Term'},
                        {id: 3,  text: 'Long-Term'},
                    ],
                    order: 2
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'BondType',
                    label: 'Bond Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 1,  text: 'Sovereign Bonds'},
                        {id: 2,  text: 'Traded Bonds'},
                    ],
                    assetTypes: [40],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'PeriodView',
                    label: 'Period-wise Details',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowGrndFathrGain',
                    label: 'With Grandfathering',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50,5060,60],
                    order: 6
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowIndexation',
                    label: 'With Indexation',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [61,40],
                    order: 6
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [5060,60,61],
                    order: 7
                }
            }
        ]
    },
    [MPrReportNumber.UnrealisedCapitalGainsDetailed]: {
        title: "Unrealised Capital Gains Detailed",
        reportNumber: MPrReportNumber.UnrealisedCapitalGainsDetailed,
        custom: [
            {
                type: 'label',
                data: {
                    key: 'periodText',
                    label: 'As of Date',
                    required: true,
                    value: 'Today',
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'},
                        {id: 63,  text: 'InvITs & REITs'},
                        {id: 40,  text: 'Traded Bonds'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 2,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Sale Amount'},
                        {id: 2, text: 'Date'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectGain',
                    label: 'Capital Gain Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 1,  text: 'Intraday'},
                        {id: 2,  text: 'Short-Term'},
                        {id: 3,  text: 'Long-Term'},
                    ],
                    order: 2
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'BondType',
                    label: 'Bond Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 1,  text: 'Sovereign Bonds'},
                        {id: 2,  text: 'Traded Bonds'},
                    ],
                    assetTypes: [40],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowGrndFathrGain',
                    label: 'With Grandfathering',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50,5060,60],
                    order: 6
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowIndexation',
                    label: 'With Indexation',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [61,40],
                    order: 6
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [5060,60,61],
                    order: 7
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayISINNO',
                    label: 'ISIN No',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    // assetTypes: [5060,60,61],
                    order: 8
                }
            }
        ]
    },
    [MPrReportNumber.ClearTaxFormat]: {
        title: "ClearTax - Capital Gains ITR Format",
        reportNumber: MPrReportNumber.ClearTaxFormat,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true,
                        hideCustomDate: true
                    },
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'}
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectGain',
                    label: 'Capital Gain Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0, text: 'Short-Term & Long-Term'},
                        {id: 2,  text: 'Short-Term'},
                        {id: 3,  text: 'Long-Term'},
                    ],
                    order: 2,
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'RoundToInteger',
                    label: 'Decimals',
                    required: true,
                    value: true,
                    options: [
                        {id: false,  text: 'Show'},
                        {id: true,  text: 'Hide'}
                    ],
                    order: 3
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 2,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Sale Amount'},
                        {id: 2, text: 'Date'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowIndexation',
                    label: 'With Indexation',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [61,40],
                    order: 4,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [5060,60,61],
                    order: 5
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ITRTaxSoftwareType',
                    label: 'ITRTaxSoftwareType',
                    required: true,
                    value: ITRTaxSoftwareType.CLEARTAX,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'IsITRTaxSoftwareFormat',
                    label: 'IsITRTaxSoftwareFormat',
                    required: true,
                    value: true,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayISINNO',
                    label: 'ISIN No',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    // assetTypes: [5060,60,61],
                    order: 8,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowGrndFathrGain',
                    label: 'With Grandfathering',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50,5060,60],
                    order: 6,
                    hidden: true
                }
            }
        ]
    },
    [MPrReportNumber.WinmanFormat]: {
        title: "Winman - Capital Gains ITR Format",
        reportNumber: MPrReportNumber.WinmanFormat,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true,
                        hideCustomDate: true
                    },
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'}
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectGain',
                    label: 'Capital Gain Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0, text: 'Short-Term & Long-Term'},
                        {id: 2,  text: 'Short-Term'},
                        {id: 3,  text: 'Long-Term'},
                    ],
                    order: 2,
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'RoundToInteger',
                    label: 'Decimals',
                    required: true,
                    value: true,
                    options: [
                        {id: false,  text: 'Show'},
                        {id: true,  text: 'Hide'}
                    ],
                    order: 3
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 2,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Sale Amount'},
                        {id: 2, text: 'Date'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowIndexation',
                    label: 'With Indexation',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [61,40],
                    order: 4,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [5060,60,61],
                    order: 5
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ITRTaxSoftwareType',
                    label: 'ITRTaxSoftwareType',
                    required: true,
                    value: ITRTaxSoftwareType.WINMAN,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'IsITRTaxSoftwareFormat',
                    label: 'IsITRTaxSoftwareFormat',
                    required: true,
                    value: true,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayISINNO',
                    label: 'ISIN No',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    // assetTypes: [5060,60,61],
                    order: 8,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowGrndFathrGain',
                    label: 'With Grandfathering',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50,5060,60],
                    order: 6,
                    hidden: true
                }
            }
        ]
    },
    [MPrReportNumber.ContractNoteSummary]: {
        title: "Contract Notes Summary",
        reportNumber: MPrReportNumber.ContractNoteSummary,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true,
                        maxTimeSpan: {years: 2}
                        // hideCustomDate: true
                    },
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Date'},
                        {id: 0,  text: 'Broker'},
                        // {id: 2, text: 'Asset'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: -1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ReportTransType',
                    label: 'ReportTransType',
                    required: true,
                    value: StockTransactionReportTypes.STOCK_CNSUM,
                    order: 7,
                    hidden: true
                }
            },
        ]
    },
    [MPrReportNumber.StockTransactions]: {
        title: "Stock Transactions",
        reportNumber: MPrReportNumber.StockTransactions,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true,
                        maxTimeSpan: {years: 2}
                    },
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Date'},
                        {id: 0,  text: 'Broker'},
                        {id: 2, text: 'Asset'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: -1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayBroker',
                    label: 'Show Broker',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    order: 5
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ReportTransType',
                    label: 'ReportTransType',
                    required: true,
                    value: StockTransactionReportTypes.STOCK_TRANS,
                    order: 7,
                    hidden: true
                }
            },
        ]
    },
    [MPrReportNumber.MutualFundTransactions]: {
        title: "Mutual Fund Transactions",
        reportNumber: MPrReportNumber.MutualFundTransactions,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true,
                        hideCustomDate: true
                    },
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ReportTransType',
                    label: 'View Type',
                    required: true,
                    value: MFTransactionReportTypes.MF_DateTrans,
                    options: [
                        {id: MFTransactionReportTypes.MF_DateTrans,  text: 'Date-wise'},
                        {id: MFTransactionReportTypes.MF_SchemeTrans,  text: 'Scheme-wise'}
                    ],
                    order: 4,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Date'},
                        {id: 0, text: 'Asset'}
                    ],
                    order: 3,
                    computations: {
                        computationFunction: getMFTransReportSortType,
                        order: 1,
                        dependantFields: ['ReportTransType']
                    },
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: -1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4,
                    computations: {
                        computationFunction: getMFTransReportSortDir,
                        order: 1,
                        dependantFields: ['ReportTransType']
                    }
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio No',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5
                }
            }
        ]
    },
    [MPrReportNumber.ComputaxFormat]: {
        title: "CompuTax - Capital Gains ITR Format",
        reportNumber: MPrReportNumber.ComputaxFormat,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true,
                        hideCustomDate: true
                    },
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'}
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectGain',
                    label: 'Capital Gain Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0, text: 'Short-Term & Long-Term'},
                        {id: 2,  text: 'Short-Term'},
                        {id: 3,  text: 'Long-Term'},
                    ],
                    order: 2,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 2,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Sale Amount'},
                        {id: 2, text: 'Date'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowIndexation',
                    label: 'With Indexation',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [61,40],
                    order: 4,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [5060,60,61],
                    order: 5
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ITRTaxSoftwareType',
                    label: 'ITRTaxSoftwareType',
                    required: true,
                    value: ITRTaxSoftwareType.COMPUTAX,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'IsITRTaxSoftwareFormat',
                    label: 'IsITRTaxSoftwareFormat',
                    required: true,
                    value: true,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayISINNO',
                    label: 'ISIN No',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    // assetTypes: [5060,60,61],
                    order: 8,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowGrndFathrGain',
                    label: 'With Grandfathering',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50,5060,60],
                    order: 6,
                    hidden: true
                }
            }
        ]
    },
    [MPrReportNumber.SpectrumFormat]: {
        title: "Spectrum - Capital Gains ITR Format",
        reportNumber: MPrReportNumber.SpectrumFormat,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true,
                        hideCustomDate: true
                    },
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'}
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectGain',
                    label: 'Capital Gain Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0, text: 'Short-Term & Long-Term'},
                        {id: 2,  text: 'Short-Term'},
                        {id: 3,  text: 'Long-Term'},
                    ],
                    order: 2,
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'RoundToInteger',
                    label: 'Decimals',
                    required: true,
                    value: true,
                    options: [
                        {id: false,  text: 'Show'},
                        {id: true,  text: 'Hide'}
                    ],
                    order: 3
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 2,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Sale Amount'},
                        {id: 2, text: 'Date'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowIndexation',
                    label: 'With Indexation',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [61,40],
                    order: 4,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [5060,60,61],
                    order: 5
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ITRTaxSoftwareType',
                    label: 'ITRTaxSoftwareType',
                    required: true,
                    value: ITRTaxSoftwareType.SPECTRUM,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'IsITRTaxSoftwareFormat',
                    label: 'IsITRTaxSoftwareFormat',
                    required: true,
                    value: true,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayISINNO',
                    label: 'ISIN No',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    // assetTypes: [5060,60,61],
                    order: 8,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowGrndFathrGain',
                    label: 'With Grandfathering',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50,5060,60],
                    order: 6,
                    hidden: true
                }
            }
        ]
    },
    [MPrReportNumber.GeniusFormat]: {
        title: "Genius Software - Capital Gains ITR Format",
        reportNumber: MPrReportNumber.GeniusFormat,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true,
                        hideCustomDate: true
                    },
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'}
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectGain',
                    label: 'Capital Gain Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0, text: 'Short-Term & Long-Term'},
                        {id: 2,  text: 'Short-Term'},
                        {id: 3,  text: 'Long-Term'},
                    ],
                    order: 2,
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'RoundToInteger',
                    label: 'Decimals',
                    required: true,
                    value: false,
                    options: [
                        {id: false,  text: 'Show'},
                        {id: true,  text: 'Hide'}
                    ],
                    order: 3
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 2,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Sale Amount'},
                        {id: 2, text: 'Date'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowIndexation',
                    label: 'With Indexation',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [61,40],
                    order: 4,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [5060,60,61],
                    order: 5
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ITRTaxSoftwareType',
                    label: 'ITRTaxSoftwareType',
                    required: true,
                    value: ITRTaxSoftwareType.GENIUS,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'IsITRTaxSoftwareFormat',
                    label: 'IsITRTaxSoftwareFormat',
                    required: true,
                    value: true,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayISINNO',
                    label: 'ISIN No',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    // assetTypes: [5060,60,61],
                    order: 8,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowGrndFathrGain',
                    label: 'With Grandfathering',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50,5060,60],
                    order: 6,
                    hidden: true
                }
            }
        ]
    },
    [MPrReportNumber.TaxProFormat]: {
        title: "TaxPro Software - Capital Gains ITR Format",
        reportNumber: MPrReportNumber.TaxProFormat,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true,
                        hideCustomDate: true
                    },
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                        {id: 61,  text: 'Mutual Funds (Debt)'}
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectGain',
                    label: 'Capital Gain Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0, text: 'Short-Term & Long-Term'},
                        {id: 2,  text: 'Short-Term'},
                        {id: 3,  text: 'Long-Term'},
                    ],
                    order: 2,
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'RoundToInteger',
                    label: 'Decimals',
                    required: true,
                    value: false,
                    options: [
                        {id: false,  text: 'Show'},
                        {id: true,  text: 'Hide'}
                    ],
                    order: 3
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 2,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Sale Amount'},
                        {id: 2, text: 'Date'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowIndexation',
                    label: 'With Indexation',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [61,40],
                    order: 4,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    assetTypes: [5060,60,61],
                    order: 5
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ITRTaxSoftwareType',
                    label: 'ITRTaxSoftwareType',
                    required: true,
                    value: ITRTaxSoftwareType.TAXPRO,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'IsITRTaxSoftwareFormat',
                    label: 'IsITRTaxSoftwareFormat',
                    required: true,
                    value: true,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayISINNO',
                    label: 'ISIN No',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    // assetTypes: [5060,60,61],
                    order: 8,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowGrndFathrGain',
                    label: 'With Grandfathering',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50,5060,60],
                    order: 6,
                    hidden: true
                }
            }
        ]
    },
    [MPrReportNumber.ITR112AFormat]: {
        title: "Schedule 112A - Capital Gains ITR Format",
        reportNumber: MPrReportNumber.ITR112AFormat,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true,
                        hideCustomDate: true
                    },
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 50,
                    required: true,
                    options: [
                        {id: 50,  text: 'Stocks'},
                        {id: 60,  text: 'Mutual Funds (Equity)'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SelectGain',
                    label: 'Capital Gain Type',
                    required: true,
                    value: 3,
                    options: [
                        {id: 3,  text: 'Long-Term'},
                    ],
                    order: 2,
                    showByDefault: true,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'RoundToInteger',
                    label: 'Decimals',
                    required: true,
                    value: false,
                    options: [
                        {id: false,  text: 'Show'},
                        {id: true,  text: 'Hide'}
                    ],
                    order: 3
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 2,
                    options: [
                        {id: 0,  text: 'Name'},
                        {id: 1,  text: 'Sale Amount'},
                        {id: 2, text: 'Date'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowIndexation',
                    label: 'With Indexation',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [61,40],
                    order: 4,
                    hidden: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'ITRTaxSoftwareType',
                    label: 'ITRTaxSoftwareType',
                    required: true,
                    value: ITRTaxSoftwareType.ITR112A,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'IsITRTaxSoftwareFormat',
                    label: 'IsITRTaxSoftwareFormat',
                    required: true,
                    value: true,
                    order: 7,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'DisplayISINNO',
                    label: 'ISIN No',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    // assetTypes: [5060,60,61],
                    order: 8,
                    hidden: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowGrndFathrGain',
                    label: 'With Grandfathering',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    assetTypes: [50,5060,60],
                    order: 6,
                    hidden: true
                }
            }
        ]
    },
    [MPrReportNumber.ChargesReport]: {
        title: "Contract Notes Charges",
        reportNumber: MPrReportNumber.ChargesReport,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    extra: {
                        hideAllToDate: true,
                        maxTimeSpan: {years: 2}
                        // hideCustomDate: true
                    },
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Date'},
                        // {id: 2, text: 'Asset'}
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: -1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowChargesBreakdown',
                    label: 'Charges Breakdown',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 3
                }
            }
        ]
    },
    [MPrReportNumber.IncomeReportSummary]: {
        title: "Income Report - Summary",
        hasReportInName: true,
        reportNumber: MPrReportNumber.IncomeReportSummary,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Filter by Asset Type',
                    value: 0,
                    required: true,
                    dynamic: true,
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 2,
                    hidden: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'IncomeTypeID',
                    label: 'Income Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 63,  text: 'Bonus Income'},
                        {id: 62,  text: 'Dividend Income'},
                        {id: 67,  text: 'Interest Income'},
                        {id: 61,  text: 'Rent Income'},
                        {id: 64,  text: 'Other Income'},
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ExpAll',
                    label: 'Expand All',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio/Ref No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5
                }
            }
        ]
    },
    [MPrReportNumber.IncomeReportDetailed]: {
        title: "Income Report - Detailed",
        reportNumber: MPrReportNumber.IncomeReportDetailed,
        hasReportInName: true,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Filter by Asset Type',
                    value: 0,
                    required: true,
                    dynamic: true,
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 2
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'IncomeTypeID',
                    label: 'Income Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 63,  text: 'Bonus Income'},
                        {id: 62,  text: 'Dividend Income'},
                        {id: 67,  text: 'Interest Income'},
                        {id: 61,  text: 'Rent Income'},
                        {id: 64,  text: 'Other Income'},
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio/Ref No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5
                }
            }
        ]
    },
    [MPrReportNumber.GroupIncomeReportSummary]: {
        title: "Group Income Report - Summary",
        hasReportInName: true,
        reportNumber: MPrReportNumber.GroupIncomeReportSummary,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Filter by Asset Type',
                    value: 0,
                    required: true,
                    dynamic: true,
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 2,
                    hidden: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'IncomeTypeID',
                    label: 'Income Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 63,  text: 'Bonus Income'},
                        {id: 62,  text: 'Dividend Income'},
                        {id: 67,  text: 'Interest Income'},
                        {id: 61,  text: 'Rent Income'},
                        {id: 64,  text: 'Other Income'},
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ExpAll',
                    label: 'Expand All',
                    required: true,
                    value: true,
                    options: [
                        {id: true,  text: 'Yes'},
                        {id: false,  text: 'No'}
                    ],
                    order: 4
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio/Ref No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5
                }
            }
        ]
    },
    [MPrReportNumber.GroupIncomeReportDetailed]: {
        title: "Group Income Report - Detailed",
        reportNumber: MPrReportNumber.GroupIncomeReportDetailed,
        hasReportInName: true,
        custom: [
            {
                type: 'period',
                data: {
                    key: 'period',
                    label: 'Period',
                    required: true,
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Filter by Asset Type',
                    value: 0,
                    required: true,
                    dynamic: true,
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 2
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'IncomeTypeID',
                    label: 'Income Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'All'},
                        {id: 63,  text: 'Bonus Income'},
                        {id: 62,  text: 'Dividend Income'},
                        {id: 67,  text: 'Interest Income'},
                        {id: 61,  text: 'Rent Income'},
                        {id: 64,  text: 'Other Income'},
                    ],
                    order: 3
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'ShowFolioNo',
                    label: 'Folio/Ref No',
                    required: true,
                    value: false,
                    options: [
                        {id: true,  text: 'Show'},
                        {id: false,  text: 'Hide'}
                    ],
                    order: 5
                }
            }
        ]
    },
    [MPrReportNumber.ULIPInsReport]: {
        title: "ULIP & Insurance",
        reportNumber: MPrReportNumber.ULIPInsReport,
        custom: [
            {
                type: 'label',
                data: {
                    key: 'periodText',
                    label: 'As of Date',
                    required: true,
                    value: 'Today',
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 0, text: 'ULIP & Insurance'},
                        {id: AssetTypeEnum.INS,  text: 'Insurance'},
                        {id: AssetTypeEnum.ULIP,  text: 'NPS / ULIP'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 2
                }
            }
        ]
    },
    [MPrReportNumber.FDDepositReport]: {
        title: "FD & Deposit",
        reportNumber: MPrReportNumber.FDDepositReport,
        custom: [
            {
                type: 'label',
                data: {
                    key: 'periodText',
                    label: 'As of Date',
                    required: true,
                    value: 'Today',
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 0, text: 'FD & Deposit'},
                        {id: AssetTypeEnum.FD,  text: 'FD'},
                        {id: AssetTypeEnum.CD,  text: 'Deposit'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'Maturity Date'},
                        {id: 1,  text: 'Maturity Amount'},
                        {id: 2,  text: 'Investment Date'}
                    ],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 3
                }
            }
        ]
    },
    [MPrReportNumber.GroupFDDepositReport]: {
        title: "Group FD & Deposit",
        reportNumber: MPrReportNumber.GroupFDDepositReport,
        custom: [
            {
                type: 'label',
                data: {
                    key: 'periodText',
                    label: 'As of Date',
                    required: true,
                    value: 'Today',
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 0, text: 'FD & Deposit'},
                        {id: AssetTypeEnum.FD,  text: 'FD'},
                        {id: AssetTypeEnum.CD,  text: 'Deposit'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'SortType',
                    label: 'Sort Type',
                    required: true,
                    value: 0,
                    options: [
                        {id: 0,  text: 'Maturity Date'},
                        {id: 1,  text: 'Maturity Amount'},
                        {id: 2,  text: 'Investment Date'}
                    ],
                    order: 2
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 3
                }
            }
        ]
    },
    [MPrReportNumber.GroupULIPInsReport]: {
        title: "Group ULIP & Insurance",
        reportNumber: MPrReportNumber.GroupULIPInsReport,
        custom: [
            {
                type: 'label',
                data: {
                    key: 'periodText',
                    label: 'As of Date',
                    required: true,
                    value: 'Today',
                    order: 0,
                    showByDefault: true
                }
            },
            {
                type: 'dropdown',
                data: {
                    key: 'AType',
                    label: 'Select Asset Type',
                    value: 0,
                    required: true,
                    options: [
                        {id: 0, text: 'ULIP & Insurance'},
                        {id: AssetTypeEnum.INS,  text: 'Insurance'},
                        {id: AssetTypeEnum.ULIP,  text: 'ULIP'},
                    ],
                    order: 1,
                    showByDefault: true
                }
            },
            {
                type: 'radio',
                data: {
                    key: 'SortDir',
                    label: 'Sort Direction',
                    required: true,
                    value: 1,
                    options: [
                        {id: 1,  text: 'Ascending'},
                        {id: -1,  text: 'Descending'}
                    ],
                    order: 2
                }
            }
        ]
    },
}

export const ReportCustomisationFields = {
    INV: InvReportCustomisationFields
}