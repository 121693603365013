import { B2B2C_Tier, B2C_Tier, B2X, LicenseInfo } from "../constants";

export const HasSupportedLicenseTier = (licenseInfo: LicenseInfo, b2b2cTiersSupported: B2B2C_Tier[], b2b2cAllTiersSupported: boolean, b2cTiersSupported: B2C_Tier[], b2cAllTiersSupported: boolean) => {
    if (licenseInfo.B2X == B2X.B2B2C) {
        return b2b2cAllTiersSupported || b2b2cTiersSupported.includes(licenseInfo.B2B2C_Tier);
    } else if (licenseInfo.B2X == B2X.B2C) {
        return b2cAllTiersSupported || b2cTiersSupported.includes(licenseInfo.B2C_Tier);
    } else {
        return false;
    }
}