export function UpgradeStar(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="62.5" height="62.501" viewBox="0 0 62.5 62.501">
        <g id="Group_223469" data-name="Group 223469" transform="translate(-14041.984 16180.25)">
          <g id="Group_226414" data-name="Group 226414" transform="translate(14042.234 -16180)">
            <path id="Path_272065" data-name="Path 272065" d="M137.836,135.315c-6.27-1.528-9.94-2.837-12.559-5.456s-3.928-6.289-5.456-12.559c-3.1,12.559-5.278,13.848-19.821,17.2,15.773,3.631,16.984,4.861,20.634,20.634C123.987,140.592,125.277,138.41,137.836,135.315Zm8.968,15.257c-2.024,7.758-3.79,8.67-13.472,10.912,10.932,2.52,11.766,3.353,14.285,14.285,2.242-9.682,3.155-11.448,10.912-13.471-3.889-.992-6.289-1.924-8.035-3.69C148.729,156.861,147.8,154.48,146.8,150.572Z" transform="translate(-100 -113.769)" fill="#eaf4df" fill-rule="evenodd"/>
            <path id="Path_272066" data-name="Path 272066" d="M116.186,205.358c-2.651-2.62-6.366-3.93-12.712-5.458-1.064.258-2.229.536-3.474.814,15.965,3.632,17.19,4.863,20.885,20.642.281-1.231.562-2.382.823-3.434C120.162,211.65,118.837,207.978,116.186,205.358Zm31.789,31.036c-1-3.91-1-4.475-2.792-6.221-1.787-1.766-4.2-2.7-8.133-3.692-1.024.258-2.149.536-3.414.814,5.411,1.233,7.909,2.357,9.974,4.293,1.421,1.278,2.313,3.458,3.616,9.047C147.528,239.385,147.714,237.407,147.975,236.395Z" transform="translate(-99.197 -179.997)" fill="#5f854c" fill-rule="evenodd"/>
            <path id="Path_272067" data-name="Path 272067" d="M121.227,142.454c-3.756-16.226-5-17.492-21.227-21.227,16.226-3.735,17.471-5,21.227-21.227,3.756,16.226,5,17.492,21.227,21.227C126.228,124.962,124.962,126.228,121.227,142.454Z" transform="translate(-100 -100)" fill="none" stroke="#253326" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5"/>
            <path id="Path_272068" data-name="Path 272068" d="M282.7,297.391c-2.592-11.246-3.449-12.1-14.7-14.7,11.246-2.592,12.1-3.449,14.7-14.7,2.592,11.246,3.449,12.1,14.7,14.7C286.145,285.288,285.288,286.145,282.7,297.391Z" transform="translate(-235.391 -235.391)" fill="none" stroke="#253326" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5"/>
          </g>
        </g>
      </svg>
      
    );
  }
  