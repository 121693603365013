export function WalletTransactionLargeIcon(props: any) {
 return (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="155.974" height="140" viewBox="0 0 155.974 140">
   <g id="wallet-transaction" style={{ mixBlendMode: 'luminosity', isolation: 'isolate' }}>
    <path id="Path_206230" data-name="Path 206230" d="M507.4,314.251l21.936,108.031a6.166,6.166,0,0,1-4.818,7.271l-61.412,12.469a6.167,6.167,0,0,1-7.271-4.818L433.9,329.173a6.167,6.167,0,0,1,4.818-7.271l61.412-12.469A6.171,6.171,0,0,1,507.4,314.251Z" transform="translate(-433.282 -308.815)" fill="#fff" />
    <path id="Path_206230_-_Outline" data-name="Path 206230 - Outline" d="M499.853,307.31a6.659,6.659,0,0,1,6.526,5.337l21.936,108.031a6.656,6.656,0,0,1-5.2,7.854L461.7,441a6.694,6.694,0,0,1-1.332.135,6.656,6.656,0,0,1-6.522-5.338L431.91,327.766a6.656,6.656,0,0,1,5.2-7.854l61.412-12.469A6.7,6.7,0,0,1,499.853,307.31ZM460.367,440.146a5.718,5.718,0,0,0,1.135-.115l61.412-12.469a5.679,5.679,0,0,0,4.431-6.688L505.41,312.844a5.67,5.67,0,0,0-6.688-4.431L437.31,320.882a5.679,5.679,0,0,0-4.431,6.688L454.815,435.6A5.687,5.687,0,0,0,460.367,440.146Z" transform="translate(-431.775 -307.31)" />
    <path id="Ellipse_2066" data-name="Ellipse 2066" d="M5.137-2a7.132,7.132,0,0,1,7.137,7.137,7.132,7.132,0,0,1-7.137,7.137A7.132,7.132,0,0,1-2,5.137,7.132,7.132,0,0,1,5.137-2Zm0,13.285A6.148,6.148,0,1,0-1.011,5.137,6.155,6.155,0,0,0,5.137,11.285Z" transform="translate(62.212 24.025)" fill="#101010" />
    <path id="Ellipse_2067" data-name="Ellipse 2067" d="M5.137-2a7.132,7.132,0,0,1,7.137,7.137,7.132,7.132,0,0,1-7.137,7.137A7.132,7.132,0,0,1-2,5.137,7.132,7.132,0,0,1,5.137-2Zm0,13.285A6.148,6.148,0,1,0-1.011,5.137,6.155,6.155,0,0,0,5.137,11.285Z" transform="translate(60.416 14.113)" fill="#101010" />
    <rect id="Rectangle_25207" data-name="Rectangle 25207" width="122.57" height="7.928" transform="translate(67.673 125.523) rotate(-101.48)" fill="#101010" />
    <path id="Path_206231" data-name="Path 206231" d="M551.9,383.549l3.279,16.146a1.618,1.618,0,0,1-1.266,1.909l-5.646,1.148a1.619,1.619,0,0,1-1.909-1.266l-3.279-16.146a1.618,1.618,0,0,1,1.266-1.909l5.646-1.148A1.623,1.623,0,0,1,551.9,383.549Z" transform="translate(-515.53 -363.718)" fill="#485d3e" />
    <path id="Path_206232" data-name="Path 206232" d="M533.64,669.625a.769.769,0,0,1,.751.618l6.8,33.48a.77.77,0,0,1-.6.906l-2.821.574a.74.74,0,0,1-.151.015.771.771,0,0,1-.755-.617h0l-6.8-33.481a.77.77,0,0,1,.6-.905l2.817-.573A.763.763,0,0,1,533.64,669.625Zm6.536,34.078-6.711-33.049-2.389.486,6.711,33.049Zm3.08-21.164a.772.772,0,0,1,.755.618l3.9,19.2a.77.77,0,0,1-.6.905l-2.821.574a.794.794,0,0,1-.151.015.772.772,0,0,1-.755-.617l-3.9-19.2a.771.771,0,0,1,.6-.906l2.821-.574A.765.765,0,0,1,543.256,682.54Zm3.639,19.8-3.81-18.77-2.389.486,3.81,18.77Z" transform="translate(-505.745 -580.024)" fill="#101010" />
    <path id="Path_206237" data-name="Path 206237" d="M9.794,10.052a26.536,26.536,0,0,1-4.039-.211A6.146,6.146,0,0,1,2.187,8.215,6.666,6.666,0,0,1,.541,5.135,20.006,20.006,0,0,1,0,0H.989c0,3.836.6,6.224,1.9,7.515,1.563,1.56,4.224,1.554,7.592,1.547H78.842v.989H9.794Z" transform="translate(18.025 130.132) rotate(-11)" />
    <g id="Group_152832" data-name="Group 152832" transform="translate(83.888 2.045)">
     <rect id="Rectangle_25208" data-name="Rectangle 25208" width="8.952" height="31.046" transform="translate(43.336 4.501) rotate(90)" fill="#5f854c" />
     <path id="Path_206238" data-name="Path 206238" d="M203.63,918.4l15.523,8.979V909.42Z" transform="translate(-203.63 -909.42)" fill="#5f854c" />
    </g>
    <g id="Group_152833" data-name="Group 152833" transform="translate(112.638 21.11)">
     <rect id="Rectangle_25209" data-name="Rectangle 25209" width="8.952" height="31.046" transform="translate(0 13.455) rotate(-90)" fill="#101010" />
     <path id="Path_206239" data-name="Path 206239" d="M334.233,1031.057l-15.523-8.977v17.957Z" transform="translate(-290.897 -1022.08)" fill="#101010" />
    </g>
    <g id="Group_154350" data-name="Group 154350" transform="translate(66.929 36.034)">
     <ellipse id="Ellipse_2316" data-name="Ellipse 2316" cx="31.732" cy="30.158" rx="31.732" ry="30.158" transform="translate(0 0)" fill="#acc18a" />
     <path id="Path_235205" data-name="Path 235205" d="M252.933,120.888c14.821-.1,26.983,11.378,27.7,25.314.014-.373.025-.747.022-1.124-.1-14.462-12.516-26.1-27.734-26s-27.473,11.913-27.373,26.376c0,.377.019.751.037,1.124C226.109,132.636,238.112,120.989,252.933,120.888Z" transform="translate(-220.299 -116.999)" fill="#101010" />
     <path id="Path_235206" data-name="Path 235206" d="M253.273,168.108c-14.821.1-26.979-10.937-27.7-24.873a10.406,10.406,0,0,0-.022,1.124c1.055,15.312,15.022,24.388,27.722,24.3,13.326-.092,26.246-9.768,27.385-24.68a8.944,8.944,0,0,0-.037-1.124C280.1,156.8,268.094,168.006,253.273,168.108Z" transform="translate(-220.3 -114.777)" fill="#101010" />
     <path id="Path_235207" data-name="Path 235207" d="M253.528,170.641c-16.318.113-29.706-12.041-30.493-27.385-.015.411-.027.822-.024,1.238.109,15.923,13.78,28.738,30.534,28.623s30.248-13.117,30.139-29.04c0-.415-.021-.827-.042-1.237C283.064,158.191,269.846,170.528,253.528,170.641Z" transform="translate(-220.536 -114.778)" fill="#101010" />
     <path id="Path_235215" data-name="Path 235215" d="M11.982,28.625a1.269,1.269,0,0,1-.675-.164,2.2,2.2,0,0,1-.511-.45L.941,16.521a5.22,5.22,0,0,1-.368-.47,1.2,1.2,0,0,1-.123-.634v-.45A.884.884,0,0,1,.7,14.313a.884.884,0,0,1,.654-.245H3.8a11.261,11.261,0,0,0,4.723-.777A4.359,4.359,0,0,0,10.734,11.2a7.056,7.056,0,0,0,.593-2.944,7.056,7.056,0,0,0-.593-2.944A4.392,4.392,0,0,0,8.608,3.231a9.724,9.724,0,0,0-4.314-.777H.9a.884.884,0,0,1-.654-.245A.884.884,0,0,1,0,1.554V.9A.884.884,0,0,1,.245.245.884.884,0,0,1,.9,0H16.684a.884.884,0,0,1,.654.245A.884.884,0,0,1,17.584.9v.654a.884.884,0,0,1-.245.654.884.884,0,0,1-.654.245h-5.97L8.751,1.309A5.6,5.6,0,0,1,12.8,3.783,8.761,8.761,0,0,1,13.9,8.26a9,9,0,0,1-.9,4.008,6.657,6.657,0,0,1-2.965,2.944,11.693,11.693,0,0,1-5.5,1.1H3.517l-.286-1.063L13.658,27.439a.825.825,0,0,1,.286.573.5.5,0,0,1-.245.47,1.116,1.116,0,0,1-.573.143ZM.9,9a.884.884,0,0,1-.654-.245A.884.884,0,0,1,0,8.1V7.443a.884.884,0,0,1,.245-.654A.884.884,0,0,1,.9,6.543H16.684a.844.844,0,0,1,.9.9V8.1a.844.844,0,0,1-.9.9Z" transform="translate(24.008 15.846)" fill="#131100" />
    </g>
   </g>
  </svg>
 );
}
