import { useState } from 'react'
import { Box, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, styled } from '@mui/material'
import CustomTypography from '../customTypography'
import { ArrowDownWhite, PinWhite, RightArrowOutlined, ThreeDotsWhite } from 'packages/web/src/assets/icons/global'
import { SharedConstants, SharedRedux, formatDecimal, formatPercent } from '@mprofit/shared';
import { PinIcon } from 'packages/web/src/assets/icons/pinIcon';
import ToggleButtonIcon from 'packages/web/src/assets/icons/global/toggleButtonIcon';
import { useAppSelector } from 'packages/web/src/redux/hooks';
import SelectIndices from '../../portfolio/shared/selectIndices';
import { DefaultIndicesModal } from '../default-indices-modal';

function IndexCards() {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isChangeIndicesModalOpen, setIsChangeIndicesModalOpen] = useState<boolean>(false);

    const cardData: SharedConstants.IndexCardItem[] | undefined = useAppSelector(SharedRedux.Dashboard.Selectors.selectLeftNavIndexCardData);
    if (!cardData || cardData.length === 0) return <></>;

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleCard = (buttonType: string) => {
        if (buttonType === "left" && activeIndex !== 0) {
            setActiveIndex(prevState => prevState - 1)
        }
        else if (buttonType === "right" && activeIndex < cardData.length - 1) {
            setActiveIndex(prevState => prevState + 1)
        }
        else {
            setActiveIndex(prevState => prevState)
        }
    }
    return (
        <Box sx={{ padding: '16px 24px' }}>
            {
                isChangeIndicesModalOpen &&
                <DefaultIndicesModal isModalOpen={isChangeIndicesModalOpen} setIsModalOpen={setIsChangeIndicesModalOpen}/>
            }
            {/* <SelectIndices/> */}
            <Container >
                <ContentContainer>
                    <LeftContainer>
                        <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '8px' }}>
                            <CustomTypography sx={{ color: { xs: "#FFFFFF" }, marginRight: '9px' }} variant='Medium16'>{cardData[activeIndex].IndexName}</CustomTypography>
                            {/* <PinWhite style={{ cursor: 'pointer' }} /> */}
                        </Box>
                        <Box aria-describedby={id} onClick={(e) => handleClick(e)} sx={{ ":hover": { background: '#5F854C', borderRadius: '8px' } }}>
                            <ThreeDotsWhite style={{ cursor: 'pointer' }} />
                        </Box>

                        <Popper placement="bottom-start" id={id} open={open} anchorEl={anchorEl} onClick={(e) => handleClick(e)} sx={{ zIndex: 100000, background: '#F6F6F6', border: '1px solid #EBECF2', borderRadius: '8px' }}>
                            <Paper>
                                <MenuList>
                                    <MenuItem onClick={() => setIsChangeIndicesModalOpen(true)}>
                                        <ListItemIcon>
                                            <ToggleButtonIcon color="#12131A" />
                                        </ListItemIcon>
                                        <ListItemText>Configure indices</ListItemText>
                                    </MenuItem>
                                    {/* <MenuItem>
                                        <ListItemIcon>
                                            <PinIcon />
                                        </ListItemIcon>
                                        <ListItemText>Unpin index</ListItemText>
                                    </MenuItem> */}
                                </MenuList>
                            </Paper>
                        </Popper>
                    </LeftContainer>

                    <CustomTypography sx={{ color: { xs: "#FFFFFF" } }} variant="Regular20">{formatDecimal(cardData[activeIndex].IndexValue, 2, "IN")}</CustomTypography>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '4px', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <LossGainCont sx={{background: cardData[activeIndex].TUp ? '#5F6F57' : '#C94C40'}}>
                                {
                                    cardData[activeIndex].TUp ? <ArrowDownWhite style={{ transform: 'rotate(180deg)' }} /> : <ArrowDownWhite />
                                }
                                <CustomTypography sx={{ color: { xs: "#FFFFFF", paddingLeft: '4px' } }} variant='Medium14'>{formatPercent(cardData[activeIndex].TGainPct, 2, "IN")}</CustomTypography>
                            </LossGainCont>
                            <CustomTypography sx={{ color: { xs: "#FFFFFF" } }} variant='Medium14'>{formatDecimal(cardData[activeIndex].TGain, 2, "IN")}</CustomTypography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <RightArrowOutlined disabled={activeIndex === 0} onClick={() => handleCard("left")} style={{ transform: 'rotate(180deg)', marginRight: '26px', cursor: 'pointer' }} />
                            <RightArrowOutlined disabled={activeIndex === cardData.length - 1} style={{ cursor: 'pointer' }} onClick={() => handleCard("right")} />
                        </Box>
                    </Box>
                </ContentContainer>
            </Container>
        </Box >
    )
}

export default IndexCards


const Container = styled(Box)({
    padding: '12px 12px  12px 12px',
    background: " #12131A ",
    borderRadius: " 12px",
    color: "#fff",
    boxShadow: '5px 5px 1px #2a2b31, 10px 10px 1px #12131A',
})
const ContentContainer = styled(Box)({

})

const LeftContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
})

const LossGainCont = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    width: 'fit-content',
    padding: '3px 8px 2px 8px',
    marginRight: '12px'
})