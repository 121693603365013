import { Button, ButtonProps } from '@mui/material'

interface IButtonWithTextAndIconProps extends ButtonProps {
 buttonlabel?: string;
 labelColor?: string;
}

export default function ButtonWithTextAndIconTransparent(props: IButtonWithTextAndIconProps) {
 return (
  <Button
   {...props}
   disableRipple
   sx={[
    styles,
    {
        color: props.labelColor || '#64677A'
    }
   ]}
  >
   {props.buttonlabel}
  </Button>
 )
}

const styles = {
    textTransform: 'unset',
    '&:hover': { backgroundColor: '#EFF0F5' }
}