import { Box } from "@mui/material";
import CustomTypography from "../../customTypography";
import { FormatAmountUsingPrefsHelper, SharedConstants, formatPercent } from "@mprofit/shared";
import React, { Dispatch, SetStateAction } from "react";
import { useNumberFormatPrefs } from "../../../hooks/userPreferencesHooks";


interface RightLegendContentProps {
    data: SharedConstants.PieChartData[];
    onItemClick?: (item: SharedConstants.PieChartData) => void;
    handleLegendMouseEnter: (event: any, itemIndex: number) => void;
    handleLegendMouseLeave: () => void;
}

interface RightLegendProps {
    data: SharedConstants.PieChartData[];
    chartRef: any,
    setActiveIndex: Dispatch<SetStateAction<number | null>>,
    onItemClick?: (item: SharedConstants.PieChartData) => void
}

const RightLegend = ({ data, chartRef, setActiveIndex, onItemClick }: RightLegendProps) => {
    const handleLegendMouseEnter = (event: any, itemIndex: number) => {
        setActiveIndex(itemIndex);
        if (!chartRef.current) {
            return;
        }
        const activeItem = chartRef.current.state.formattedGraphicalItems?.[0].props.sectors[itemIndex];

        if (!activeItem) {
            return;
        }

        chartRef.current.setState({ activeTooltipIndex: itemIndex },
            () => {
                chartRef.current.handleItemMouseEnter({
                    tooltipPayload: [activeItem],
                    tooltipPosition: activeItem.tooltipPosition
                });
            }
        );
    };

    const handleLegendMouseLeave = () => {
        setActiveIndex(null);
        if (!chartRef.current) {
            return;
        }
        chartRef.current.setState({
            isTooltipActive: false,
            tooltipPosition: undefined
        });
    };

    return (
        <RightLegendContent
            {...{ data, onItemClick, handleLegendMouseEnter, handleLegendMouseLeave }}
        />
    )
}

const RightLegendContent = React.memo(({ data, handleLegendMouseEnter, handleLegendMouseLeave, onItemClick }: RightLegendContentProps) => {
    const numberFormatPrefs = useNumberFormatPrefs();
    return (
        <Box sx={{
            color: '#64677a',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: { xs: '160px', lg1: '200px', xl: '310px' },
            // maxHeight: '310px',
            // height: { xs: "260px", md: "240px", lg: "240px", lg1: "270px" },
        }}>
            <Box sx={style.bulletWrapper} onMouseLeave={handleLegendMouseLeave}>
                {data && data.map((item: any, index: number) => (
                    <Box
                        onMouseEnter={(event) => handleLegendMouseEnter(event, index)} onMouseLeave={handleLegendMouseLeave} key={index}
                        sx={{
                            paddingBottom: '10px',
                            cursor: onItemClick ? 'pointer' : 'default',
                            alignItems: 'center',
                            '&:hover': {
                                opacity: '0.5'
                            },
                        }}
                        onClick={onItemClick ? () => onItemClick(item.payload) : undefined}
                    >
                        <Box sx={style.bulletsLayout}>
                            <Box sx={{ backgroundColor: `${item.color}`, borderRadius: '50%', width: '10px', height: '10px' }} />
                            <CustomTypography variant="Regular16" sx={{ color: { xs: "#64677A" } }}>{item.value}</CustomTypography>
                        </Box>
                        <CustomTypography variant="Medium16" sx={{ marginLeft: '18px', color: { xs: '#64677A' } }}>{FormatAmountUsingPrefsHelper(numberFormatPrefs, item.payload.value).FormattedValue}</CustomTypography>
                    </Box>
                ))}
            </Box>
        </Box >
    )
});

export default RightLegend;

const style = {
    bulletsContainer: {
        boxSizing: 'initial',
        // paddingTop: '20px',
        color: '#64677a',
        maxWidth: '350px',
        display: 'flex',
        maxHeight: '310px',
    },
    bulletWrapper: {
        color: '#64677a',
        display: 'flex',
        flexDirection: 'column',
        // width: '100%',
        overflow: 'auto',
        '::-webkit-scrollbar': {
            width: '1px',
        }
    },
    bulletsLayout: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        // height: '25px',
    }
}