import React from 'react'

export default function QuantityIcon(props: any) {
    return (
        <svg {...props} id="Group_151091" data-name="Group 151091" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <rect id="Rectangle_24259" data-name="Rectangle 24259" width="48" height="48" rx="8" fill="#f5f5f6" />
            <path id="_647f1da107f6c45f14e41c2d6adb48d9" data-name="647f1da107f6c45f14e41c2d6adb48d9" d="M21,14a2.006,2.006,0,0,0-2-2,2,2,0,0,0,0-4H12.8a2.1,2.1,0,0,1,1.7,2.3A1.915,1.915,0,0,1,12.8,12h0a2.1,2.1,0,0,1,1.7,2.3A1.915,1.915,0,0,1,12.8,16h0a2.1,2.1,0,0,1,1.7,2.3A1.915,1.915,0,0,1,12.8,20H19a2,2,0,1,0,0-4A2.006,2.006,0,0,0,21,14ZM11,16a2,2,0,1,0,0-4,2,2,0,0,0,0-4,2,2,0,1,0,0-4H5A2,2,0,1,0,5,8a2,2,0,1,0,0,4,2,2,0,1,0,0,4,2,2,0,1,0,0,4h6a2,2,0,1,0,0-4Z" transform="translate(12 12.001)" />
        </svg>
    )
}
