export function GLD(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226566" data-name="Group 226566" transform="translate(-17890 6539.435)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 -0.435)" fill="#fff" opacity="0" />
          <g id="noun-shiny-gold-4695913" transform="translate(1.223 4.891)">
            <path id="Path_198307" data-name="Path 198307" d="M205.215,125.909l1.252,3.129h-7.041l1.252-3.129h4.537m0-.626h-4.537a.626.626,0,0,0-.581.393l-1.252,3.129a.626.626,0,0,0,.581.858h7.041a.626.626,0,0,0,.581-.858l-1.252-3.129a.626.626,0,0,0-.581-.393Zm8.605.626,1.252,3.129h-7.041l1.252-3.129h4.537m0-.626h-4.537a.626.626,0,0,0-.581.393l0,.01-1.247,3.119a.626.626,0,0,0,.581.858h7.041a.626.626,0,0,0,.581-.858l-1.251-3.129a.626.626,0,0,0-.581-.394Zm-4.3-4.237,1.252,3.129h-7.041l1.252-3.129h4.537m0-.626H204.98a.626.626,0,0,0-.581.393l-1.252,3.129a.626.626,0,0,0,.581.858h7.041a.626.626,0,0,0,.581-.858l-1.252-3.129a.626.626,0,0,0-.581-.393Z" transform="translate(-198.8 -120.42)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>


  );
}
