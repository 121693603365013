export function INS(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226547" data-name="Group 226547" transform="translate(-17890 6539.205)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 -0.205)" fill="#fff" opacity="0" />
          <g id="Group_226549" data-name="Group 226549" transform="translate(-35 -318.476)">
            <rect id="Rectangle_24190-2" data-name="Rectangle 24190" width="20" height="20" transform="translate(35 318.271)" fill="#fff" opacity="0" />
            <g id="Shield_Done" data-name="Shield Done" transform="translate(40.068 321.801)">
              <path id="Stroke_1" data-name="Stroke 1" d="M5.2,12.669s5.155-1.561,5.155-5.864.187-4.639-.226-5.053A19.173,19.173,0,0,0,5.2,0,19.16,19.16,0,0,0,.269,1.752c-.414.414-.227.75-.227,5.053S5.2,12.669,5.2,12.669Z" transform="translate(0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
              <path id="Stroke_3" data-name="Stroke 3" d="M0,1.52,1.435,2.957,4.391,0" transform="translate(3.185 4.623)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
