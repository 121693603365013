import { AssetAllocationCategID, AssetTypeEnum, AssetTypeNameMapping } from "../constants";

export const getAssetTypeNameForAA = (assetTypeID: AssetTypeEnum, assetAllocationCategID?: AssetAllocationCategID) => {
    if (assetAllocationCategID && assetAllocationCategID > 0) {
        switch (assetTypeID) {
            case AssetTypeEnum.EQ:
                switch (assetAllocationCategID) {
                    case AssetAllocationCategID.Equity:
                        return "Stocks & Equity ETFs";
                    case AssetAllocationCategID.Debt:
                        return "Debt ETFs";
                    case AssetAllocationCategID.RealEstate:
                        return "REITs";
                    case AssetAllocationCategID.Gold_Plus:
                        return "Gold & Silver ETFs";
                    case AssetAllocationCategID.Other:
                        return "Other Stocks";
                    case AssetAllocationCategID.International:
                        return "International ETFs";
                }
                break;
            case AssetTypeEnum.MFEQ:
            case AssetTypeEnum.MFD:
                switch (assetAllocationCategID) {
                    case AssetAllocationCategID.Equity:
                        return "Equity Funds";
                    case AssetAllocationCategID.Debt:
                        return "Debt Funds";
                    case AssetAllocationCategID.Retirement:
                        return "Solution Oriented Funds";
                    case AssetAllocationCategID.Gold_Plus:
                        return "Gold & Silver Funds";
                    case AssetAllocationCategID.International:
                        return "International Funds";
                    case AssetAllocationCategID.RealEstate:
                        return "Real Estate Funds";
                }
                break;
            case AssetTypeEnum.BND:
                switch (assetAllocationCategID) {
                    case AssetAllocationCategID.Gold_Plus:
                        return "Gold Bonds";
                    default:
                        return "Listed Bonds";
                }
                break;
        }
    }

    return AssetTypeNameMapping[assetTypeID];
}