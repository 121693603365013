import { SelectChangeEvent } from "@mui/material";
import { PieChartWithLeftLegend } from "../../../shared"
import { DropdownItem } from "../../../shared/drop-down";
import { SharedConstants } from "@mprofit/shared";

interface HoldingAssetAllocationChartProps {
    data: any[];
    totalValue: string | undefined;
    heading: string;
    subHeading: string;
    dropdownValue: SharedConstants.HoldingAssetAllocationDropdownValue,
    handleDropdownChange?: (selected: DropdownItem) => void,
    onItemClick?: (item: SharedConstants.PieChartData) => void,
    hideDropdown?: boolean
  }

export const HoldingAssetAllocationChart = (props: HoldingAssetAllocationChartProps) => {
    return <PieChartWithLeftLegend
      {...props}
      dropdownList={props.hideDropdown === true ? undefined : dropdownList}
      totalFieldLabel={dropdownList.find(x => x.id == props.dropdownValue)?.name}
      handleDropdownChange={props.handleDropdownChange || (()=>{})}
      onItemClick={props.onItemClick}
    />
}

const dropdownList = [{id: 'CurrValue', name: 'Current Value'}, {id: 'InvAmt', name: 'Amount Invested'}]; 