export function WalletIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="Group_149734" data-name="Group 149734" transform="translate(0.184)">
        <rect id="Rectangle_23692" data-name="Rectangle 23692" width="48" height="48" rx="8" transform="translate(-0.184)" fill="#fff" />
        <path id="Path_198515" data-name="Path 198515" d="M21.85,7.562H4.127V6.853L19.724,5.6V6.853H21.85V4.726a2.4,2.4,0,0,0-2.806-2.435L4.807,4.325A3.378,3.378,0,0,0,2,7.562V21.74a2.836,2.836,0,0,0,2.836,2.836H21.85a2.836,2.836,0,0,0,2.836-2.836V10.4A2.836,2.836,0,0,0,21.85,7.562ZM19.724,17.5a2.128,2.128,0,1,1,2.128-2.127A2.128,2.128,0,0,1,19.724,17.5Z" transform="translate(10.473 10.579)" />
      </g>
    </svg>
  );
}
