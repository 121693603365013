export function DraggableIcon(props: any) {
  return (
    <svg id="Group_150933" data-name="Group 150933" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_23696" data-name="Rectangle 23696" width="24" height="24" fill="#fff" opacity="0" />
      <g id="drag-handle-line" transform="translate(7 4)">
        <path id="Path_198047" data-name="Path 198047" d="M16.7,12.1a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,16.7,12.1Z" transform="translate(-13.5 -10.5)" fill="#b6b5bc" />
        <path id="Path_198048" data-name="Path 198048" d="M16.7,24.1a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,16.7,24.1Z" transform="translate(-13.5 -9.7)" fill="#b6b5bc" />
        <path id="Path_198049" data-name="Path 198049" d="M22.7,12.1a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,22.7,12.1Z" transform="translate(-13.1 -10.5)" fill="#b6b5bc" />
        <path id="Path_198050" data-name="Path 198050" d="M22.7,24.1a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,22.7,24.1Z" transform="translate(-13.1 -9.7)" fill="#b6b5bc" />
        <path id="Path_198051" data-name="Path 198051" d="M22.7,18.1a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,22.7,18.1Z" transform="translate(-13.1 -10.1)" fill="#b6b5bc" />
        <path id="Path_198052" data-name="Path 198052" d="M16.7,18.1a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,16.7,18.1Z" transform="translate(-13.5 -10.1)" fill="#b6b5bc" />
      </g>
    </svg>
  );
}