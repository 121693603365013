export function RupeeIcon(props: any) {
 return (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 48 48" preserveAspectRatio="xMinYMin slice">
   <g id="Group_151044" data-name="Group 151044" transform="translate(-1408 -238)">
    <rect id="Rectangle_24225" data-name="Rectangle 24225" width="48" height="48" rx="8" transform="translate(1408 238)" fill="#f5f5f6" />
    <g id="Group_150890" data-name="Group 150890" transform="translate(1419 249)">
     <circle id="Ellipse_1984" data-name="Ellipse 1984" cx="13" cy="13" r="13" fill="#12131a" />
     <path id="Path_198513" data-name="Path 198513" d="M13.815,7.205h-.883a3.952,3.952,0,0,0-.74-1.6h1.623a.8.8,0,1,0,0-1.6H5.8a.8.8,0,0,0,0,1.6H9.007a2.4,2.4,0,0,1,2.256,1.6H5.8a.8.8,0,0,0,0,1.6h5.462a2.4,2.4,0,0,1-2.256,1.6H5.8a.8.8,0,0,0-.567,1.369l6.41,6.41a.8.8,0,1,0,1.135-1.133L7.736,12.013H9.007a4.012,4.012,0,0,0,3.925-3.205h.883a.8.8,0,0,0,0-1.6Z" transform="translate(3.012 2.41)" fill="#fafafa" />
    </g>
   </g>
  </svg>
 );
}
