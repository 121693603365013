import { SharedConstants } from '@mprofit/shared';
import Hashids from 'hashids';

const hashids = new Hashids('mprofit_', 10, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890');

export interface MPrUrlParamsConstructor {
  Screen?: SharedConstants.ScreenEnum;
  DatabaseID?: number;
  FamilyID?: number;
  PortfolioID?: number;
  AssetType?: number;
  AMID?: number;
  SID?: number;
  SectorID?: number;
  MarketCapID?: number;
  L3Mode?: number;
  AssetAllocationCategID?: SharedConstants.AssetAllocationCategID;
  SEBICategoryID?: number;
  SEBISubCategoryID?: number;
}
export class MPrUrlParams {
  constructor(params?: MPrUrlParamsConstructor) {
    this.Screen = params?.Screen || SharedConstants.ScreenEnum.UNDEEFINED;
    this.DatabaseID = Math.max(params?.DatabaseID || 0);
    this.FamilyID = Math.max(params?.FamilyID || 0);
    this.PortfolioID = Math.max(params?.PortfolioID || 0);
    this.AssetType = Math.max(params?.AssetType || 0);
    this.AMID = Math.max(params?.AMID || 0);
    this.SID = Math.max(params?.SID || 0, 0);
    this.SectorID = Math.max(params?.SectorID || 0);
    this.MarketCapID = Math.max(params?.MarketCapID || 0);
    this.L3Mode = Math.max(params?.L3Mode || 0);
    this.AssetAllocationCategID = Math.max(params?.AssetAllocationCategID || 0);
    this.SEBICategoryID = Math.max(params?.SEBICategoryID || 0);
    this.SEBISubCategoryID = Math.max(params?.SEBISubCategoryID || 0);
  }

  Screen: SharedConstants.ScreenEnum = SharedConstants.ScreenEnum.UNDEEFINED;
  DatabaseID?: number = 0;
  FamilyID?: number = 0;
  PortfolioID?: number = 0;
  AssetType?: number = 0;
  AMID?: number = 0;
  SID?: number = 0;
  SectorID?: number = 0;
  MarketCapID?: number = 0;
  L3Mode: SharedConstants.HoldingsMode = SharedConstants.HoldingsMode.Undefined;
  AssetAllocationCategID: SharedConstants.AssetAllocationCategID = SharedConstants.AssetAllocationCategID.None;
  SEBICategoryID?: number = 0;
  SEBISubCategoryID?: number = 0;

  static ParamOrder: string[] = ["Screen","DatabaseID", "FamilyID", "PortfolioID", "AssetType","SectorID", "MarketCapID", "AMID", "SID", "L3Mode", "AssetAllocationCategID", "SEBICategoryID", "SEBISubCategoryID"]

  static getMPrUrlParamsFromString(urlString: string | null) {
    if (urlString == null) return new MPrUrlParams();
    
    let decodedUrl = hashids.decode(urlString)
    var obj = {}

    decodedUrl.forEach((value, index) => {
      (obj as any)[MPrUrlParams.ParamOrder[index]] = value;
    });
    return new MPrUrlParams(obj as MPrUrlParams);
  }

  getUrlString(): string {
    var urlArray: number[] = [];

    MPrUrlParams.ParamOrder.forEach(paramName => {
      urlArray.push((this as any)[paramName] as number);
    });

    let encodedString = hashids.encode(urlArray);
    return encodedString;
  }

}