import { Box } from "@mui/material"
import CustomTypography from "../../../shared/customTypography"
import styles from "./index.module.css"
import { t } from "i18next"
import { DashboardItemContainer } from "../../shared"
import { BarGraphIcon } from "packages/web/src/assets/icons/barGraphIcon"
import { PercentageIcon } from "packages/web/src/assets/icons/percentageIcon"
import { ArrowNarrowUpIcon } from "packages/web/src/assets/icons/ArrowNarrowUpIcon"
import { formatDecimalWithRupeeSymbol, formatPercent } from "@mprofit/shared"
import { ArrowNarrowDownIcon } from "packages/web/src/assets/icons/ArrowNarrowDownIcon"
import { AmountWithTooltip } from "../../../shared/numberWithTooltip"

interface HoldingUnrealisedGainProps {
    OGain?: string;
    OGainPct?: string;
    OUp?: boolean;
}

export const HoldingUnrealisedGain = ({ OGain, OGainPct, OUp }: HoldingUnrealisedGainProps) => {
    return (
        <DashboardItemContainer>
            <Box className={styles.unrealisedGainWrapper}>
                <Box className={styles.unrealisedGainContainer}>
                    <Box className={styles.unrealisedGainLeft}>
                        <BarGraphIcon width="42px" height="42px" />
                        <Box>
                            <CustomTypography variant='Medium28'>
                                <AmountWithTooltip Value={OGain} />
                            </CustomTypography>
                            <CustomTypography variant='Regular18' sx={{ color: { xs: "#64677A" }, }}>{t('portfolio.holding.unrealisedGain')}</CustomTypography>
                        </Box>
                    </Box>
                    <Box className={styles.unrealisedGainRight}>
                        <Box className={styles.unrealisedGainPercentageCont}>
                            <PercentageIcon width="42px" height="42px" />
                            <CustomTypography variant='Medium24' sx={{ color: { xs: OUp ? "#5F854C" : "#C94C40" }, padding: "0px 4px 0px 16px" }}>{formatPercent(OGainPct, 1, 'IN')}</CustomTypography>
                            {
                                OUp == null ? <></>
                                    :
                                    (
                                        OUp == true ?
                                            <ArrowNarrowUpIcon width="30px" height="30px" />
                                            :
                                            <ArrowNarrowDownIcon width="30px" height="30px" />
                                    )
                            }
                        </Box>
                        <CustomTypography variant='Regular18' sx={{ color: { xs: "#64677A" }, paddingTop: "16px" }}>{t('portfolio.holding.unrealisedGainPercent')}</CustomTypography>
                    </Box>
                </Box>
            </Box>
        </DashboardItemContainer>
    )
}