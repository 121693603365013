import { formatDecimal, formatDecimalWithRupeeSymbol } from '@mprofit/shared'
import { Box, Divider, Grid } from '@mui/material'
import PurchasePriceIcon from 'packages/web/src/assets/icons/holdingL4/purchasePriceIcon'
import QuantityIcon from 'packages/web/src/assets/icons/holdingL4/quantityIcon'
import { CustomTypography } from 'packages/web/src/components/shared'
import React from 'react'

export default function L4HoldingQuantityCard({Quant, PxPur}: {Quant: string | undefined, PxPur: string | undefined}) {
    return (
        <Grid container spacing={2} padding="24px">
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between',alignItems:'center' }}>
                <Box>
                    <CustomTypography variant='Regular18' sx={{ color: { xs: "#64677A" } }}>Quantity</CustomTypography>
                    <CustomTypography variant='Regular24'>{formatDecimal(Quant, -2, 'IN')}</CustomTypography>
                </Box>
                <QuantityIcon />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between',alignItems:'center' }}>
                <Box>
                    <CustomTypography variant='Regular18' sx={{ color: { xs: "#64677A" } }}>Average purchase price</CustomTypography>
                    <CustomTypography variant='Regular24'>{formatDecimalWithRupeeSymbol(PxPur, 2, 'IN')}</CustomTypography>
                </Box>
                <PurchasePriceIcon />
            </Grid>
        </Grid>
    )
}
