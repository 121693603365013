import { SharedRedux } from "@mprofit/shared";
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, useTheme } from "@mui/material";
import { t } from "i18next";
import { SharedConstants } from "@mprofit/shared";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { AddIcon } from "../../../assets/icons/AddIcon";
import { GoalPortfolio } from "../../../assets/icons/GoalPortfolio";
import { GroupIcon } from "../../../assets/icons/GroupIcon";
import { Refresh } from "../../../assets/icons/Refresh";
import { StrategyPortfolio } from "../../../assets/icons/StrategyPortfolio";
import { UserIcon } from "../../../assets/icons/UserIcon";
import CustomTypography from "../../shared/customTypography";
import useWindowDimensions from "../../shared/utilities/windowDimensions";
import styles from "./index.module.css";
import { NavigateToPortfolio } from "packages/web/src/routes/navigationMethods";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import PMSBadge from "../../shared/pms-badge";
import { SwitchAccountIcon } from "packages/web/src/assets/icons/SwitchAccountIcon";
import { DatabaseDisplay } from "../databaseDisplay";

export default function PortfolioDropdown({ setIsFamilyModalOpen, setIsDropdownOpen, setIsSwitchAccountModalOpen }: { setIsFamilyModalOpen: Dispatch<SetStateAction<boolean>>, setIsDropdownOpen: Dispatch<SetStateAction<boolean>>, setIsSwitchAccountModalOpen: Dispatch<SetStateAction<boolean>> }) {
  const mainPortfolios = useAppSelector((state) => SharedRedux.Portfolios.Selectors.selectActiveMainPortfoliosList(state, SharedConstants.PFolioTypes.PMS));
  const groups = useAppSelector((state) => SharedRedux.Portfolios.Selectors.selectActiveGroupsList(state, SharedConstants.PFolioTypes.PMS));
  const strategyGoalPortfolios = useAppSelector((state) => SharedRedux.Portfolios.Selectors.selectActiveStrategyGoalPortfoliosList(state, SharedConstants.PFolioTypes.PMS));
  const activeDatabase = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveDatabase)
  const databaseList = useAppSelector(SharedRedux.Portfolios.Selectors.selectDatabases);
  const hasSharedData = useAppSelector(SharedRedux.Portfolios.Selectors.selectHasSharedData);
  const hasMultipleDatabases = databaseList && databaseList.length > 1;

  const theme = useTheme();
  const lg1BreakpointValue = theme.breakpoints.values.lg1;

  const width = useWindowDimensions().width;
  const boxWidth = width < lg1BreakpointValue ? '85%' : '85%'

  const openFamilyModal = () => setIsFamilyModalOpen(true);
  const openSwitchAccountModal = () => setIsSwitchAccountModalOpen(true);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectPortfolio = (portfolio: SharedConstants.Portfolio) => {
    console.log('selectPortfolio1', portfolio);
    var activePortfolio = { CCID: portfolio.CCID, FamilyId: portfolio.FamilyId, PFID: portfolio.Id };
    // dispatch(SharedRedux.Portfolios.Actions.setActivePortfolio(activePortfolio));
    NavigateToPortfolio({ navigate, shouldDispatchActivePortfolios: true, dispatch, activePortfolio });
    setIsDropdownOpen(false);
  };

  return (
    <Box className={styles.familyMembers} sx={{ alignSelf: "center", padding: "0px", paddingBottom: "30px", width: boxWidth, boxSizing: 'border-box' }}>
      {
        hasSharedData &&
        <>
        <Box>
          <Box className={styles.portfolioSection}>
            <CustomTypography
              variant="Regular16"
              sx={{ color: { xs: "#EBECF2" } }}
            >
              {activeDatabase?.IsShared ? 'Shared By' : 'Account'}
            </CustomTypography>
            {
              hasMultipleDatabases &&
              <Box onClick={() => openSwitchAccountModal()} sx={{ cursor: "pointer" }}>
                <SwitchAccountIcon />
              </Box>
            }
          </Box>
          <Box sx={{ padding: "22px 16px 29px 16px" }} onClick={hasMultipleDatabases ? () => openSwitchAccountModal() : undefined}>
            <DatabaseDisplay database={activeDatabase} backgroundColor="#12131A" borderColor="#253326" nameColor="#EBECF2" disabled={!hasMultipleDatabases}/>
          </Box>
        </Box>

        <Divider className={styles.dropdownDivider} />
        </>
      }
      <Box className={styles.family}>
        <ActiveFamilyText />
        <Box onClick={openFamilyModal}>
          <Refresh style={{ cursor: 'pointer' }} />
        </Box>
      </Box>

      <Divider className={styles.dropdownDivider} />

      <Box className={styles.dropdownNav}>
        <Box className={styles.portfolioSection}>
          <CustomTypography variant="Regular16" sx={{ color: { xs: "#ebecf2" } }}>
            {t("leftNav.dropdown.portfolio")}
          </CustomTypography>
          <Box>
            <AddIcon />
          </Box>
        </Box>
        <List sx={{ margin: '0px 16px' }}>
          {mainPortfolios.map((portfolio: SharedConstants.Portfolio, index) => (
            <PortfolioItem portfolio={portfolio} selectPortfolio={selectPortfolio} index={index} IconComponent={UserIcon} />
          ))}
          {
            (strategyGoalPortfolios && strategyGoalPortfolios.length > 0) &&
            <Divider sx={{ backgroundColor: "#3A3D4D", width: "100%", marginTop: '8px' }} />
          }
        </List>
        {
          strategyGoalPortfolios && strategyGoalPortfolios.length > 0 &&
          <List sx={{ maginTop: "8px", marginLeft: "16px", marginRight: "16px" }}>
            {strategyGoalPortfolios.map((portfolio: SharedConstants.Portfolio, index) => (
              <PortfolioItem portfolio={portfolio} selectPortfolio={selectPortfolio} index={index} IconComponent={portfolio.IsGoalPortfolio ? GoalPortfolio : StrategyPortfolio} />
            ))}
          </List>
        }
      </Box>

      {
        groups && groups.length > 0 &&
        <Box sx={{ width: "100%", maxWidth: 272, color: "#BABDCC" }}>
          <Box className={styles.portfolioSection}>
            <CustomTypography
              variant="Regular16"
              sx={{ color: { xs: "#EBECF2" } }}
            >
              {t("leftNav.dropdown.groups")}
            </CustomTypography>
            <Box>
              <AddIcon />
            </Box>
          </Box>
          <List sx={{ margin: '0px 16px' }}>
            <Box sx={{ padding: " 0px 5px" }}>
              {groups.map((portfolio: SharedConstants.Portfolio, index) => (
                <PortfolioItem portfolio={portfolio} selectPortfolio={selectPortfolio} index={index} IconComponent={GroupIcon} />
              ))}
            </Box>
          </List>
        </Box>
      }
    </Box >
  );
}

const ActiveFamilyText = () => {
  const activeFamily = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveFamily);
  return (
    <CustomTypography
      className={styles.familyName}
      variant="Regular18"
      sx={{ color: { xs: "#FFFFFF" } }}
    >
      {activeFamily?.FamilyName}
    </CustomTypography>
  );
}

const PortfolioItem = ({ index, selectPortfolio, portfolio, IconComponent }: { index: number, selectPortfolio: (portfolio: SharedConstants.Portfolio) => void, portfolio: SharedConstants.Portfolio, IconComponent: (props: any) => JSX.Element }) => {
  return (
    <ListItem
      key={index}
      disablePadding
      className={styles.listItems}
    >
      <ListItemButton
        key={index}
        onClick={() => selectPortfolio(portfolio)}
        sx={{ padding: "14px 12px", ":hover": { background: '#253326', color: "#D0E3BB", borderRadius: '5px' } }}
      >
        <ListItemIcon sx={{ minWidth: "36px" }}>
          <IconComponent />
        </ListItemIcon>
        <CustomTypography variant="Medium16" sx={{ color: { xs: "#EBECF2" } }}>
          {portfolio.PName || portfolio.PFullName}
        </CustomTypography>
        {
          portfolio.IsCashMgmtPortfolio ? <Box ml={1}><PMSBadge /></Box> : null
        }
      </ListItemButton>
    </ListItem>
  )
}
