
export function StrategyPortfolio(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_150676" data-name="Group 150676" transform="translate(-4 -4)">
        <rect id="Rectangle_24190" data-name="Rectangle 24190" width="24" height="24" transform="translate(4 4)" fill="#fff" opacity="0" />
        <path id="Path_198409" data-name="Path 198409" d="M3.75,3V14.25A2.25,2.25,0,0,0,6,16.5H8.25M3.75,3H2.25m1.5,0h16.5m0,0h1.5m-1.5,0V14.25A2.25,2.25,0,0,1,18,16.5H15.75m-7.5,0h7.5m-7.5,0-1,3m8.5-3,1,3m0,0,.5,1.5m-.5-1.5H7.25m0,0L6.75,21m.75-9,3-3,2.148,2.148A12.061,12.061,0,0,1,16.5,7.6" transform="translate(4.25 4.5)" fill="rgba(0,0,0,0)" stroke="#ebecf2" strokeLinecap="round" strokeWidth="1.5" />
      </g>
    </svg>

  );
}

