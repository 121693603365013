import { Box } from "@mui/material";
import {
  Bar,
  CartesianGrid,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
  BarChart
} from "recharts";
import styles from "./index.module.css";
import redDownArrow from "../../../assets/icons/redArrowDown.svg";
import greenArrowUp from "../../../assets/icons/greenArrowUp.svg";
import ModalButton from "../modalButton";
import { useEffect, useState } from "react";
import CustomTypography from "../customTypography";
import { SharedConstants, formatDateForReadingWithTime, formatPercent } from "@mprofit/shared";
import { AxisDomain } from "recharts/types/util/types";
import { PerformanceIndicesModal } from "../performance-indices-modal";
import PrimaryIconButton from "../primary-icon-button";
import CachedIcon from "@mui/icons-material/Cached";
import { InfoIconTooltip } from "../info-icon-tooltip";
import { DownloadPDF } from "../../download-pdf";
import { CompleteChartPage, PdfBarChart } from "@mprofit-soft/pdf";
import { XIRRBarChartPDFDownloadButton } from "./pdf";

interface XirrBarChartProps {
  data?: SharedConstants.BarChartData[];
  chartHeading: string;
  chartSubHeading: string;
  chartLayout: 'vertical' | 'horizontal';
  chartHeight: number;
  chartWidth: number;
  chartStyle: any;
  barSizeVal: number;
  domain: AxisDomain;
  lastRefreshedTime?: Date;
  isLoading?: boolean;
  IsPerformanceViewAllowed: boolean;
  isFirstBarBlack: boolean;
  SpecificTitleText: string;
}

const XirrBarChart = (props: XirrBarChartProps) => {
  const {
    chartHeading,
    chartSubHeading,
    chartLayout,
    chartHeight,
    chartWidth,
    chartStyle,
    barSizeVal,
    lastRefreshedTime,
    isFirstBarBlack,
    isLoading,
    SpecificTitleText
  } = props;

  const [isChangeIndicesModalOpen, setIsChangeIndicesModalOpen] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);

  const [isAnimationActive, setIsAnimationActive] = useState(true);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (props.data && props.data.length > 0 && isAnimationActive) {
        setIsAnimationActive(false);
      }
    }, 1500)
  }, [props.data])

  const onAnimationEnd = () => {
    setTimeout(() => {
      if (props.data && props.data.length > 0 && isAnimationActive) {
        setIsAnimationActive(false);
      }
    }, 500)
  }

  return (
    <Box className={styles.XirrBarChartContainer}>
      <PerformanceIndicesModal isModalOpen={isChangeIndicesModalOpen} setIsModalOpen={setIsChangeIndicesModalOpen} />
      <Box className={styles.headingContainer}>
        <Box className={styles.headingSubContainer}>
          <Box>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
              <CustomTypography variant="Medium20">
                {chartHeading}
              </CustomTypography>
             
              <InfoIconTooltip tooltipLines={['This chart shows the compounded annual growth rate (CAGR) of different market indices over the selected time period.']} />
              {
                !isLoading && props.data &&
                <XIRRBarChartPDFDownloadButton SpecificTitleText={SpecificTitleText}/>
              }
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }}>{chartSubHeading}</CustomTypography>
              {/* <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '40px',
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <CustomTypography
                  variant="Regular16"
                  sx={{
                    color: { xs: '#64677A' },
                    marginLeft: '10px',
                  }}
                >{lastRefreshedTime ? `• Refreshed ${formatDateForReadingWithTime(lastRefreshedTime)}` : undefined}</CustomTypography>
                {isHovered &&
                  <PrimaryIconButton
                    // handleClick={forceRefreshTotal}
                    icon={<CachedIcon />}
                    iconBorderRadius={"4px"}
                    backgroundColor={"transparent"}
                    tooltipText={`Refresh`}
                  />}
              </Box> */}
            </Box>
          </Box>
        </Box>
        {
          !isLoading &&
          <Box className={styles.dropdownContainer}>
            <ModalButton
              text="Change Indices"
              handleClick={() => { setIsChangeIndicesModalOpen(true) }}
              disabled={!props.IsPerformanceViewAllowed}
            />
          </Box>
        }
      </Box>
      {
        props.data &&
        <Box sx={{ width: '100%', height: chartHeight, paddingTop: '24px', }} className={props.isFirstBarBlack ? 'rechart-firstYitem-black' : undefined}>
          <ResponsiveContainer>
            <BarChart
              style={chartStyle}
              layout={chartLayout}
              width={chartWidth}
              height={chartHeight}
              data={props.data}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 50,
              }}
            >
              <CartesianGrid vertical horizontal={false} fillOpacity={0.8} stroke="#EBECF2" />
              <XAxis tickLine={false} axisLine={false} type="number" domain={props.domain || [-1, 1]} tickFormatter={(tick) => { return `${formatPercent(tick, 0, 'IN')}` }} fontFamily="rubik"/>
              <YAxis
                tickLine={false}
                axisLine={false}
                tick={{ dx: -100 }}
                fontFamily="rubik"
                dataKey="Name"
                orientation="left"
                type="category"
                tickFormatter={tickFormatter}
                tickMargin={10}
                width={100}
                textAnchor="start"
              />
              <Bar radius={[0, 10, 10, 0]} dataKey="Value" barSize={barSizeVal}
                isAnimationActive={isAnimationActive}
                onAnimationEnd={onAnimationEnd}
              >
                {props.data?.map((item, index: any) => (
                  <Cell key={index} fill={barColorFill(item.Value, index, props.isFirstBarBlack)} />
                ))}
                <LabelList dataKey={'Value'} content={renderCustomizedLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      }
    </Box>
  );
};

XirrBarChart.defaultProps = {
  chartHeading: "",
  chartSubHeading: "",
  chartLayout: "vertical",
  chartHeight: 550,
  chartWidth: 500,
  chartStyle: {
  },
  barSizeVal: 10
};

export const renderCustomizedLabel = (props: any) => {
  const { viewBox, value } = props;
  var { x, y, width, height } = viewBox;

  const horizontalSign = width >= 0 ? 1 : -1;
  const horizontalOffset = horizontalSign > 0 ? 0 : -10;
  const horizontalEnd = horizontalSign > 0 ? 'end' : 'start';

  const attrs = {
    x: x - horizontalOffset,
    y: y + height / 2,
    textAnchor: horizontalEnd,
    verticalanchor: 'middle',
  }

  const todayGainColors = value >= 0 ? { color: '#5f854c', backgroundColor: '#eaf4df' } : { color: '#c94c40', backgroundColor: '#fff5f5' };

  return <g>
    <foreignObject
      {...attrs}
      style={{ height: 1, width: 1, overflow: 'visible' }}
    >
      <div className={styles.gainPctContainer} style={value >= 0 ? { transform: 'translateX(calc(-100% - 10px)) translateY(-50%)' } : { transform: 'translateY(-50%)' }}>
        <span className={styles.gainPctBox} style={{ ...todayGainColors }}>
          <img
            height={value > 0 ? "12px" : "9px"}
            width="12px"
            src={value > 0 ? greenArrowUp : redDownArrow}
            alt="arrow"
          ></img>
          <CustomTypography variant="Medium16" sx={{ marginLeft: '6px', color: { sm: value > 0 ? "#5F854C" : "#C94C40" } }}>{formatPercent(value, 1, "IN")}</CustomTypography>
        </span>
      </div>
    </foreignObject>
  </g>
};

export const barColorFill = (uv: number, index: number, isFirstBarBlack: boolean) => {
  if (index === 0 && isFirstBarBlack) return "#000";
  else return uv > 0 ? "#5F854C" : "#C94C40";
};

export const tickFormatter = (value: string, index: number) => {
  const limit = 30; // put your maximum character
  return value.length > limit ? `${value.substring(0, limit - 3)}...` : value;

};

export default XirrBarChart;
