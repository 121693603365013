import { Box, Skeleton } from "@mui/material";
import CustomTypography from "../customTypography";
import { AmountWithTooltip } from "../numberWithTooltip";
interface CommonCardProps {
  Amount: string;
  Icon?: JSX.Element;
  Title: string;
  LossGainPct?: string;
  RightComponent?: JSX.Element;
  IsLoading?: boolean;
  TitleColor?: string;
  TitleSize?: any;
  IsGain?: boolean;
  AmountSize?: any;
}

export default function CommonCard({
  Amount,
  Icon,
  Title,
  TitleSize,
  LossGainPct,
  RightComponent,
  IsLoading,
  TitleColor,
  IsGain,
  AmountSize,
}: CommonCardProps) {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: "flex", alignItems: "center", width: '100%' }}>
        <CustomTypography
          variant={TitleSize || "Regular20"}
          sx={{
            color: { xs: TitleColor || "#12131A" },
          }}
        >
          {Title}
        </CustomTypography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: '100%'
        }}
      >
        {Icon && (
          <Box
            sx={{
              marginRight: "14px",
              marginTop: "5px",
            }}
          >
            {Icon}
          </Box>
        )}
        {
          IsLoading ?
            <Skeleton
              animation="wave"
              sx={{
                bgcolor: "#f8f8f8",
                width: "70%",
                height: "16px",
              }}
            />
            :
            <CustomTypography
              variant={AmountSize || "Medium28"}
              sx={{ width: "100%", marginTop: "8px" }}
            >
              <AmountWithTooltip Value={Amount} />
            </CustomTypography>
        }
        {LossGainPct && (
          <Box
            sx={{
              borderRadius: "6px",
              padding: "4px 6px",
              backgroundColor: IsGain ? "#EAF4DF" : "#FFF5F5",
              marginLeft: "20px",
              marginTop: "15px",
            }}
          >
            <CustomTypography
              variant="Medium18"
              sx={{ color: { xs: IsGain ? "#5F854C" : "#C94C40" } }}
            >
              {LossGainPct}
            </CustomTypography>
          </Box>
        )}
        {RightComponent && <Box>{RightComponent}</Box>}
      </Box>
    </Box>
  );
}
