export function SecondaryBackIcon(props: any) {
    return (
        <svg id="Secondary_icon_button_without_bg_" data-name="Secondary icon button (without bg)" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <rect id="Rectangle_24197" data-name="Rectangle 24197" width="40" height="40" fill={props.color || "#5f6f57"} opacity="0" />
            <g id="Group_154228" data-name="Group 154228" transform="translate(7.158 7.325)">
                <path id="Path_198418" data-name="Path 198418" d="M0,0H25.675V25.675H0Z" fill="none" />
                <path id="Icon_ionic-ios-arrow-round-back" data-name="Icon ionic-ios-arrow-round-back" d="M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H27.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.026,1.026,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z" transform="translate(-5.162 -5.327)" fill="#fff" />
            </g>
        </svg>

    );
}
