export default function ToggleButtonIcon(props: any) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="22.5" height="17.5" viewBox="0 0 22.5 17.5">
      <g id="Group_151644" data-name="Group 151644" transform="translate(-5.057 -3.629)">
        <circle id="Ellipse_1977" data-name="Ellipse 1977" cx="3" cy="3" r="3" transform="translate(15.806 4.379)" fill="none" stroke={props.color ? props.color : "#5f6f57"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <line id="Line_535" data-name="Line 535" x2="10" transform="translate(5.807 7.379)" fill="none" stroke={props.color ? props.color : "#5f6f57"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <line id="Line_536" data-name="Line 536" x2="5" transform="translate(21.807 7.379)" fill="none" stroke={props.color ? props.color : "#5f6f57"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <g id="Group_150747" data-name="Group 150747" transform="translate(2 2)">
          <circle id="Ellipse_1978" data-name="Ellipse 1978" cx="3" cy="3" r="3" transform="translate(6.807 12.379)" fill="none" stroke={props.color ? props.color : "#5f6f57"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          <line id="Line_537" data-name="Line 537" x2="3" transform="translate(3.807 15.379)" fill="none" stroke={props.color ? props.color : "#5f6f57"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          <line id="Line_538" data-name="Line 538" x2="12" transform="translate(12.807 15.379)" fill="none" stroke={props.color ? props.color : "#5f6f57"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </g>
      </g>
    </svg>
  )
}
