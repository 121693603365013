export function RcaBonusIcon(props: any) {
  return (
    <svg id="Group_150779" data-name="Group 150779" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <rect id="Rectangle_24213" data-name="Rectangle 24213" width="48" height="48" rx="8" fill="#f5f5f6" />
      <g id="award" transform="translate(14.91 11)">
        <path id="Path_198208" data-name="Path 198208" d="M16.645,1.354,14.047,0l-2.6,1.354L8.554,1.8,7.243,4.427,5.162,6.5,5.638,9.4l-.477,2.9,2.082,2.068L8.554,17l2.894.441,2.6,1.354,2.6-1.354,2.894-.441,1.311-2.632,2.082-2.068L22.455,9.4l.477-2.9L20.85,4.428,19.539,1.8Zm1.862,1.869-2.351-.359-2.11-1.1-2.11,1.1-2.351.359L8.52,5.36,6.829,7.04,7.216,9.4l-.39,2.36L8.52,13.44l1.065,2.138,2.351.359,2.111,1.1,2.112-1.1,2.35-.359,1.065-2.138,1.691-1.68L20.878,9.4l.386-2.36-1.691-1.68L18.508,3.223Z" transform="translate(-5.161)" fill="#12131a" fillRule="evenodd" />
        <path id="Path_198209" data-name="Path 198209" d="M9,26.537v6.589l6.229-1.567,6.229,1.567v-6.59l-3.142.48-3.087,1.608-3.087-1.608Z" transform="translate(-6.343 -8.058)" fill="#12131a" />
      </g>
    </svg>
  );
}