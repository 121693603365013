export function CashflowTable_FeesIcon(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_228673" data-name="Group 228673" transform="translate(0 -0.5)">
                <rect id="Rectangle_49047" data-name="Rectangle 49047" width="48" height="48" rx="4" transform="translate(0 0.5)" fill="#f5f5f6"/>
                <path id="_8988853dc2c7377256d1b11dba51ad61" data-name="8988853dc2c7377256d1b11dba51ad61" d="M344.555-181.17l-3.291-4.173a3.541,3.541,0,0,0-2.261-1.3V-188.8a.5.5,0,0,0-.5-.5.5.5,0,0,0-.5.5v2.152a3.476,3.476,0,0,0-2.256,1.3l-3.3,4.178a2.094,2.094,0,0,0-.445,1.284v12.534a2.052,2.052,0,0,0,2.056,2.046h8.878A2.052,2.052,0,0,0,345-167.336v-12.544A2.057,2.057,0,0,0,344.555-181.17Zm-6.052-.587a1.456,1.456,0,0,1,1.456,1.46,1.456,1.456,0,0,1-1.456,1.46,1.455,1.455,0,0,1-1.451-1.46A1.458,1.458,0,0,1,338.5-181.756Zm1.15,8.582h.515a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-.515a1.806,1.806,0,0,1-.5.873,1.9,1.9,0,0,1-1.08.552l1.491,1.414a.5.5,0,0,1,.02.707.5.5,0,0,1-.705.02l-2.386-2.257a.5.5,0,0,1-.155-.366.491.491,0,0,1,.5-.492h.95a.927.927,0,0,0,.655-.276c.005,0,.005-.01.005-.01a1.03,1.03,0,0,0,.125-.166h-1.741a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h1.736a.932.932,0,0,0-.785-.4h-.95a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h3.336a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-.69A1.152,1.152,0,0,1,339.653-173.175Z" transform="translate(-314.122 200.122)"/>
            </g>
        </svg>
    );
}
