import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { AuthPage } from "../../constants/login";
import { SharedConstants } from "@mprofit/shared";

export interface TutorialState {
    StartHoldingTour: boolean;
    StartPerformanceTour: boolean;
};

const initialState: TutorialState = {
    StartHoldingTour: false,
    StartPerformanceTour: false
};

export const tutorialSlice = createSlice({
 name: 'tutorial',
 initialState,
 reducers: {
        toggleHoldingTour: (state, action: PayloadAction<boolean>) => {
            state.StartHoldingTour = action.payload;
        },
        togglePerformanceTour: (state, action: PayloadAction<boolean>) => {
            state.StartPerformanceTour = action.payload;
        }
    }
})

//#region Selectors

const tutorialSelector = (state: RootState) => state.tutorial;
const selectStartHoldingTour = (state: RootState) => tutorialSelector(state).StartHoldingTour;
const selectStartPerformanceTour = (state: RootState) => tutorialSelector(state).StartPerformanceTour;

//#endregion

export default tutorialSlice.reducer;
export const Actions = tutorialSlice.actions;
export const Selectors = { tutorialSelector, selectStartHoldingTour, selectStartPerformanceTour };