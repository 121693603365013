export function JWL(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226568" data-name="Group 226568" transform="translate(-17890 6539.431)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 -0.431)" fill="#fff" opacity="0" />
          <g id="diamond-outline" transform="translate(3.669 4.281)">
            <path id="Path_198276" data-name="Path 198276" d="M32.095,51.828l5.672,7.356a.441.441,0,0,0,.7,0l5.672-7.356a.451.451,0,0,0,.026-.512L42.241,48.21a.443.443,0,0,0-.376-.21h-7.5a.443.443,0,0,0-.376.21l-1.924,3.105A.451.451,0,0,0,32.095,51.828Zm.343-.333H43.8" transform="translate(-32.001 -48)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <path id="Path_198277" data-name="Path 198277" d="M119.863,48.437l-1.31,3.058L115.931,48M112,48.437l1.31,3.058L115.931,48m0,10.92-2.621-7.426m2.621,7.426,2.621-7.426" transform="translate(-109.816 -48)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          </g>
        </g>
      </g>
    </svg>
  );
}
