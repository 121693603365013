import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Menu, SvgIcon } from '@mui/material';
import { ReportsIcon } from '../../../assets/icons/ReportsIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckButton from '../check-box';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      "&::webkitScrollbar": {
        width: 7,
      },
      "&::webkitScrollbarTrack": {
        boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
      },
      "&::webkitScrollbarThumb": {
        backgroundColor: "darkgrey",
        outline: `1px solid slategrey`,
      },
    },
  },
};

interface IFilterDropdown {
  data: string[],
  disabled?: boolean,
  setSelectedOption: React.Dispatch<React.SetStateAction<string[]>>,
  selectedOption: string[],
  // handleChange : (event: SelectChangeEvent<typeof selectedOption>)=>void
}


export default function FilterDropdown({ data, disabled, selectedOption, setSelectedOption }: IFilterDropdown) {
  // const [selectedOption, setSelectedOption] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedOption>) => {
    const { target: { value }, } = event;
    setSelectedOption(typeof value === 'string' ? value.split(',') : value);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleClick =(event)=>{
  //  console.log(event)
  // }
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <FormControl sx={{ minWidth: '250px' }}>
        <Select
          sx={{ height: '40px' }}
          disabled={disabled}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedOption || ''}
          onChange={handleChange}
          input={<OutlinedInput />}
          IconComponent={(props) => (
            <KeyboardArrowDownIcon {...props} />
          )}
          renderValue={(value) => {
            let totalItem = value.length;
            if (value.includes("All reports")) {
              if (data.length === selectedOption.length - 1) {
                setSelectedOption([""])
              }
              else {
                setSelectedOption(data)
              }
            }
            else {
              setSelectedOption(value)
            }
            return (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <SvgIcon color="primary">
                  <ReportsIcon color="#969696" />
                </SvgIcon>
                {totalItem < 2 ? value : `${totalItem} reports selected`}
              </Box>
            );
          }}
          MenuProps={MenuProps}
        >
          <MenuItem disableRipple value={"All reports"}>
            <CheckButton checked={data.length === (selectedOption.length)} />
            <ListItemText primary={"All reports"} />
          </MenuItem>
          {data.map((name) => (
            <MenuItem disableRipple key={name} value={name} onClick={handleClick} >
              <CheckButton id={name} checked={selectedOption.indexOf(name) > -1} />
              <ListItemText primary={name} />
              <KeyboardArrowRightIcon />
            </MenuItem>
          ))}

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'left',
          // }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu>
        </Select>
      </FormControl>
    </Box>
  );
}
