import { Dispatch, useEffect, useState } from "react";
import { Box, Divider, Grid, Skeleton } from "@mui/material";
import styles from "./index.module.css";
import { CashIcon } from "../../../../assets/icons/CashIcon";
import { MoneyHandIcon } from "../../../../assets/icons/MoneyHandIcon";
import { MoneyTreeIcon } from "../../../../assets/icons/MoneyTreeIcon";
import { WalletIcon } from "../../../../assets/icons/WalletIcon";
import { UpgradeStar } from "packages/web/src/assets/icons/UpgradeStar";
import CustomTypography from "../../../shared/customTypography";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import {
  SharedConstants,
  SharedRedux,
  SharedUtilities,
  formatDateForReading,
  formatDateForReadingWithTime,
  formatUTCDateForReading,
} from "@mprofit/shared";
import { isZeroOrPositive } from "packages/shared/src/utilities";
import { formatPercent } from "@mprofit/shared";
import PerformanceTable from "../performance-table";
import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import XIRRTotalIndicesChart from "../xirr-total-indices-chart";
import { DashboardItemContainer } from "../../shared";
import useAutoScrollTop from "../../../hooks/useAutoScrollTop";
import ButtonPrimaryCTA from "../../../shared/button-primary-cta";
import { AmountWithTooltip } from "../../../shared/numberWithTooltip";
import AppTour from "../../../shared/app-tour";
import { PERFORMANCE_TOUR_STEPS } from "../../../shared/app-tour/appTourSteps";
import { WebRedux } from "@mprofit/web";
import DropDown from "../../../shared/drop-down";
import { InfoIconTooltip } from "../../../shared/info-icon-tooltip";
import XIRRBenchmarkChart from "../xirr-benchmark-chart";
import PerformanceXIRRChartCard from "../../../shared/performance-xirr-chart-card";
import CashflowChart from "../../../shared/cashflow-chart";
import PerformancePMS from "../performance-pms";
import { AdvancedPerformanceChart } from "../advanced-performance-chart";
import { PerformanceHeader } from "../header";
import { onClickContactSupportForUpgrade } from "packages/web/src/classes/Utilities";

enum PerformanceGainMode {
  TotalGain = 1,
  RealisedPNL = 2,
}

interface PerformanceLayout_Props {
  MPrUrlParams: MPrUrlParams;
  OnRefreshXIRRTotal: (
    urlParams: MPrUrlParams,
    dispatch: Dispatch<any>,
    force?: boolean,
    noRefresh?: boolean
  ) => void;
  OnRefreshXIRRFYWise: (
    urlParams: MPrUrlParams,
    dispatch: Dispatch<any>,
    force?: boolean,
    noRefresh?: boolean
  ) => void;
  OnRefreshXIRRTable: (
    urlParams: MPrUrlParams,
    dispatch: Dispatch<any>,
    force?: boolean,
    noRefresh?: boolean
  ) => void;
  OnRefreshXIRRTablePortfolioWise: (
    urlParams: MPrUrlParams,
    dispatch: Dispatch<any>,
    force?: boolean,
    noRefresh?: boolean
  ) => void;
  OnRefreshXIRRYearWise: (
    urlParams: MPrUrlParams,
    dispatch: Dispatch<any>,
    force?: boolean,
    noRefresh?: boolean
  ) => void;
  OnRefreshXIRRBenchmark: (
    urlParams: MPrUrlParams,
    dispatch: Dispatch<any>,
    force?: boolean,
    noRefresh?: boolean
  ) => void;
  GenericTitleText: string;
  SpecificTitleText: string;
  AdvancedChartTitleText?: string;
  RowFieldName: string;
  RowFieldHeading?: string;
  HideTable?: boolean;
  HideAdvancedChart?: boolean;
  IsAllowTour?: boolean;
  ShowPMSData: boolean;
}

export default function PerformanceLayout(props: PerformanceLayout_Props) {
  useAutoScrollTop();
  const dispatch = useAppDispatch();

  const performanceXIRRData = useAppSelector(
    SharedRedux.Performance.Selectors.selectXIRRTotalData
  );
  const activePortfolio = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolio);
  const isLoading = useAppSelector(
    SharedRedux.Performance.Selectors.selectXIRRTotalIsLoading
  );
  const _IsPerformanceViewAllowed = useAppSelector(
    SharedRedux.Licensing.Selectors.selectIsPerformanceViewEnabled
  );
  const IsPerformanceViewAllowed = _IsPerformanceViewAllowed !== false;
  console.log("🚀 ~ PerformanceLayout ~ IsPerformanceViewAllowed:", IsPerformanceViewAllowed, _IsPerformanceViewAllowed);
  const isPerformanceLoading =
    _IsPerformanceViewAllowed === undefined || !activePortfolio ||
    ((performanceXIRRData?.XIRR === null ||
      performanceXIRRData?.XIRR === undefined) &&
      isLoading);
  const isRealisedUnrealisedGainLoading =
    isPerformanceLoading ||
    performanceXIRRData?.Status ===
    SharedConstants.XIRRStatus.Completed_CalculatingRelUnrelGain;
  const lastRefreshedTime_Total = useAppSelector(
    SharedRedux.Performance.Selectors.selectXIRRTotalLastRefreshedTime
  );

  const _IsBenchmarkXIRRAllowed = useAppSelector(
    SharedRedux.Licensing.Selectors.selectIsBencharkXIRREnabled
  );
  const IsBenchmarkXIRRAllowed = _IsBenchmarkXIRRAllowed !== false;

  const isPerformancePeriodAllToDate = useAppSelector(
    SharedRedux.Performance.Selectors.selectIsPeriodAllToDate
  );

  const isLegacyProduct = useAppSelector(SharedRedux.Licensing.Selectors.selectIsLicenseLegacyProduct);

  const periodText = useAppSelector(
    SharedRedux.Performance.Selectors.selectActivePeriodText
  );

  const user = useAppSelector(SharedRedux.Login.Selectors.selectUser);
  const isCashMgmtPortfolio = useAppSelector(SharedRedux.Licensing.Selectors.selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck);
  const cashflowChartData = useAppSelector(SharedRedux.Performance.Selectors.selectCashflowChartData);

  useEffect(() => {
    dispatch(SharedRedux.Performance.Actions.clearCachedXIRR());
    if (user && _IsPerformanceViewAllowed === true && activePortfolio) {
      props.OnRefreshXIRRTotal(props.MPrUrlParams, dispatch);
      // props.OnRefreshXIRRYearWise(props.MPrUrlParams, dispatch, false, true);
      // props.OnRefreshXIRRTable(props.MPrUrlParams, dispatch, false, true);
    }
  }, [props.MPrUrlParams, activePortfolio, user, _IsPerformanceViewAllowed]);

  const forceRefreshTotal = () => {
    if (user) {
      props.OnRefreshXIRRTotal(props.MPrUrlParams, dispatch, true);
    }
  };

  const startPerformanceTour = useAppSelector(
    WebRedux.Tutorial.Selectors.selectStartPerformanceTour
  );

  const [runAppTour, setRunAppTour] = useState(false);

  const showRealisedUnrealisedPNL = isPerformancePeriodAllToDate;

  const [totalGainMode, setTotalGainMode] = useState(
    PerformanceGainMode.TotalGain
  );

  useEffect(() => {
    if (showRealisedUnrealisedPNL) {
      setTotalGainMode(PerformanceGainMode.TotalGain);
    }
  }, [showRealisedUnrealisedPNL])

  useEffect(() => {
    if (startPerformanceTour) {
      setRunAppTour(true);
      dispatch(WebRedux.Tutorial.Actions.togglePerformanceTour(false));
    }
  }, [startPerformanceTour]);

  const showPMSView = isCashMgmtPortfolio && props.ShowPMSData;

  return (
    <AppTour
      runAppTour={props.IsAllowTour === true && runAppTour}
      setRunAppTour={setRunAppTour}
      appTourSteps={PERFORMANCE_TOUR_STEPS}
    // onTourEnd={onTourEnd}
    >
      <Box>
        <Box sx={{ display: "flex", alignItems: "center" }}></Box>
        {IsPerformanceViewAllowed === false && <PerformanceLockedMessageSection />}
        <PerformanceHeader
          isPerformanceViewAllowed={IsPerformanceViewAllowed}
          performanceXIRRData={performanceXIRRData}
          isPerformanceLoading={isPerformanceLoading}
          showPerformanceByPortfolio={activePortfolio?.IsGroup && props.MPrUrlParams.Screen === SharedConstants.ScreenEnum.L1}
          showPMSView={showPMSView}
          specificTitleText={props.SpecificTitleText}
          rowFieldHeading={props.RowFieldHeading}
          rowFieldName={props.RowFieldName}
          advancedChartTitleText={props.AdvancedChartTitleText || props.SpecificTitleText}
        />
        <Box
          className={styles.XirrMainContainer}
          sx={{ opacity: !IsPerformanceViewAllowed ? 0.3 : 1, marginTop: showPMSView ? '20px' : undefined }}
        >
          {
            showPMSView ?
              <PerformancePMS
                IsPerformanceViewAllowed={IsPerformanceViewAllowed}
                IsPerformanceLoading={isPerformanceLoading}
                PerformanceXIRRData={performanceXIRRData}
                PeriodText={periodText}
                IsPerformancePeriodAllToDate={isPerformancePeriodAllToDate}
              />
              : null
          }

          <Grid
            container
            spacing={{ xs: 1, lg: 2 }}
          >
            <Grid
              item
              xs={12}
              md={6}
              className="apptour-performance-snapshot"
            >
              {
                showPMSView ?
                  <CashflowChart data={cashflowChartData} showOpeningValuationAndCashBal={!isPerformancePeriodAllToDate} isPerformanceLoading={isPerformanceLoading} />
                  :
                  <DashboardItemContainer
                    sx={{ position: "relative", marginLeft: "24px" }}
                  >
                    <Box sx={{
                      width: { xs: "50%", lg: "50%", lg1: "49%" },
                      position: "absolute",
                      left: "-24px",
                      top: "-24px",
                      maxWidth: "360px",
                    }}>
                      <PerformanceXIRRChartCard
                        GenericTitleText={props.GenericTitleText}
                        IsPerformanceViewAllowed={IsPerformanceViewAllowed}
                        IsPerformanceLoading={isPerformanceLoading}
                        PeriodText={periodText}
                        PerformanceXIRRData={performanceXIRRData}
                      />
                    </Box>

                    <Box className={styles.TopXirrPortfolioContainer}>
                      <Box className={styles.PortFolioXirr}>
                        <Box
                          sx={{
                            margin: "30px",
                            marginTop: { xs: "380px", md: "380px", lg: "400px", lg1: "430px" },
                          }}
                        >
                          {showRealisedUnrealisedPNL ? (
                            <CustomTypography
                              variant="Regular20"
                              sx={{
                                fontSize: { md: "14px" },
                                lineHeight: { md: "21px" },
                                color: { xs: "#64677A" },
                                // marginBottom: "4px",
                              }}
                            >
                              {"Total Gain"}
                            </CustomTypography>
                          ) : (
                            <DropDown
                              inputValue={totalGainMode}
                              inputList={[
                                {
                                  id: PerformanceGainMode.TotalGain,
                                  name: "Total Gain for the Period",
                                },
                                {
                                  id: PerformanceGainMode.RealisedPNL,
                                  name: "P/L booked in the Period",
                                },
                              ]}
                              containerSx={{
                                border: "0px",
                                "& .MuiSelect-select": {
                                  padding: "0px",
                                  paddingRight: "28px !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                              }}
                              iconSx={{
                                fontSize: "20px",
                                fill: "#64677A",
                              }}
                              renderValue={(selected) => (
                                <CustomTypography
                                  variant="Regular20"
                                  sx={{
                                    fontSize: { md: "14px" },
                                    lineHeight: { md: "21px" },
                                    color: { xs: "#64677A" },
                                    // marginBottom: "4px",
                                  }}
                                >
                                  {selected}
                                </CustomTypography>
                              )}
                              handleChange={(selected) =>
                                setTotalGainMode(selected.id as PerformanceGainMode)
                              }
                            />
                          )}
                          <CustomTypography
                            variant="Medium28"
                            sx={{ color: { xs: "#12131A" }, marginBottom: "12px" }}
                          >
                            {isPerformanceLoading ? (
                              <Skeleton
                                animation="wave"
                                sx={{
                                  bgcolor: "#f8f8f8",
                                  width: "70%",
                                  height: "16px",
                                }}
                              />
                            ) : totalGainMode ===
                              PerformanceGainMode.RealisedPNL ? (
                              <AmountWithTooltip
                                Value={performanceXIRRData?.RealisedGain}
                              />
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <AmountWithTooltip
                                  Value={performanceXIRRData?.TotalGain}
                                />
                                <InfoIconTooltip
                                  tooltip={
                                    "This is a total of the gain on investments and income earned during the period."
                                  }
                                />
                              </Box>
                            )}
                          </CustomTypography>
                          {!isPerformanceLoading &&
                            (totalGainMode === PerformanceGainMode.RealisedPNL ? (
                              <>
                                <Box className={styles.totalGainLossPercentageBox}>
                                  <CustomTypography
                                    variant="Medium18"
                                    sx={{ color: { xs: "#5F854C" } }}
                                  >
                                    &nbsp;
                                  </CustomTypography>
                                </Box>
                              </>
                            ) : isZeroOrPositive(
                              performanceXIRRData?.TotalGainPct
                            ) ? (
                              <Box
                                className={[
                                  styles.totalGainPercentageBox,
                                  styles.totalGainLossPercentageBox,
                                ].join(" ")}
                              >
                                <CustomTypography
                                  variant="Medium18"
                                  sx={{ color: { xs: "#5F854C" } }}
                                >
                                  {`${formatPercent(
                                    performanceXIRRData?.TotalGainPct,
                                    2,
                                    "IN",
                                    true
                                  )}`}
                                </CustomTypography>
                              </Box>
                            ) : (
                              <Box
                                className={[
                                  styles.totalLossPercentageBox,
                                  styles.totalGainLossPercentageBox,
                                ].join(" ")}
                              >
                                <CustomTypography
                                  variant="Medium18"
                                  sx={{ color: { xs: "#C94C40" } }}
                                >
                                  {`${formatPercent(
                                    performanceXIRRData?.TotalGainPct,
                                    2,
                                    "IN"
                                  )}`}
                                </CustomTypography>
                              </Box>
                            ))}
                        </Box>
                      </Box>
                      <Box className={styles.valuationInvestmentContainer}>
                        <Box className={styles.valuationInvestment}>
                          <Box
                            sx={{
                              padding: { xs: "16px", lg1: "24px" },
                              borderRadius: "8px",
                              backgroundColor: "#f5f5f6",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box
                                sx={{
                                  width: { xs: "40px", lg1: "48px" },
                                  height: { xs: "40px", lg1: "48px" },
                                }}
                              >
                                <CashIcon />
                              </Box>
                              <CustomTypography
                                variant="Regular18"
                                sx={{
                                  color: { xs: "#64677A" },
                                  marginLeft: "14px",
                                }}
                              >
                                {isPerformancePeriodAllToDate
                                  ? "First Transaction Date"
                                  : t(
                                    "portfolio.performance.xirrInfo.openingValuation"
                                  )}
                              </CustomTypography>
                            </Box>
                            <Box className={styles.valuationPrice}>
                              <CustomTypography
                                variant="Medium24"
                                sx={{ width: "100%" }}
                              >
                                {isPerformanceLoading ? (
                                  <Skeleton
                                    animation="wave"
                                    sx={{
                                      bgcolor: "#f8f8f8",
                                      width: "100%",
                                      height: "16px",
                                    }}
                                  />
                                ) : isPerformancePeriodAllToDate ? (
                                  performanceXIRRData?.FirstTransDate ? (
                                    formatUTCDateForReading(
                                      SharedUtilities.convertISTDateToSameDateUTC(performanceXIRRData?.FirstTransDate)
                                    )
                                  ) : !IsPerformanceViewAllowed ? (
                                    ""
                                  ) : (
                                    "N/A"
                                  )
                                ) : (
                                  <AmountWithTooltip
                                    Value={performanceXIRRData?.OpeningValuation}
                                  />
                                )}
                              </CustomTypography>
                            </Box>
                            <Divider
                              variant="middle"
                              sx={{ margin: { xs: "16px 0px", lg: "22px 0px" } }}
                            />
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box
                                sx={{
                                  width: { xs: "40px", lg1: "48px" },
                                  height: { xs: "40px", lg1: "48px" },
                                }}
                              >
                                <WalletIcon />
                              </Box>
                              <CustomTypography
                                variant="Regular18"
                                sx={{
                                  color: { xs: "#64677A" },
                                  marginLeft: "14px",
                                }}
                              >
                                {isPerformancePeriodAllToDate
                                  ? "Current Valuation"
                                  : "Closing Valuation"}
                              </CustomTypography>
                            </Box>
                            <Box className={styles.valuationPrice}>
                              <CustomTypography
                                variant="Medium24"
                                sx={{ width: "100%" }}
                              >
                                {isPerformanceLoading ? (
                                  <Skeleton
                                    animation="wave"
                                    sx={{
                                      bgcolor: "#f8f8f8",
                                      width: "100%",
                                      height: "16px",
                                    }}
                                  />
                                ) : (
                                  <AmountWithTooltip
                                    Value={performanceXIRRData?.ClosingValuation}
                                  />
                                )}
                              </CustomTypography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            border: "1px solid #D8DAE5",
                            borderRadius: "8px",
                            background: "#fff",
                            margin: "0px 23px 23px 23px",
                          }}
                        >
                          <Box className={styles.TotalInvestment}>
                            <CustomTypography
                              variant="Regular18"
                              sx={{
                                fontSize: { md: "14px" },
                                lineHeight: { md: "21px" },
                                color: { xs: "#64677A" },
                              }}
                            >
                              {" "}
                              {t("portfolio.performance.xirrInfo.totalInvestment")}
                            </CustomTypography>
                            <Box className={styles.TotalInvestmentPrice}>
                              <CustomTypography
                                variant="Medium24"
                                sx={{ marginRight: "40px", width: "100%" }}
                              >
                                {isPerformanceLoading ? (
                                  <Skeleton
                                    animation="wave"
                                    sx={{
                                      bgcolor: "#f8f8f8",
                                      width: "90%",
                                      height: "16px",
                                    }}
                                  />
                                ) : (
                                  <AmountWithTooltip
                                    Value={performanceXIRRData?.TotalInvestment}
                                  />
                                )}
                              </CustomTypography>
                              <MoneyTreeIcon />
                            </Box>
                          </Box>
                          <Divider variant="middle" />
                          <Box className={styles.TotalWithdrawal}>
                            <CustomTypography
                              variant="Regular18"
                              sx={{
                                fontSize: { md: "14px" },
                                lineHeight: { md: "21px" },
                                color: { xs: "#64677A" },
                              }}
                            >
                              {" "}
                              {t(`portfolio.performance.xirrInfo.totalWithdrawal`)}
                            </CustomTypography>
                            <Box className={styles.TotalWithdrawalPrice}>
                              <CustomTypography
                                variant="Medium24"
                                sx={{ marginRight: "40px", width: "100%" }}
                              >
                                {isPerformanceLoading ? (
                                  <Skeleton
                                    animation="wave"
                                    sx={{
                                      bgcolor: "#f8f8f8",
                                      width: "80%",
                                      height: "16px",
                                    }}
                                  />
                                ) : (
                                  <AmountWithTooltip
                                    Value={performanceXIRRData?.TotalWithdrawal}
                                  />
                                )}
                              </CustomTypography>
                              <MoneyHandIcon />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Divider variant="middle" />

                    <Box className={styles.BottomXirrPortfolioContainer}>
                      {showRealisedUnrealisedPNL && (
                        <>
                          <Box className={styles.realisedGain}>
                            <CustomTypography
                              variant="Regular18"
                              sx={{ color: "#64677A", marginBottom: "6px" }}
                            >
                              {"Realised P&L"}
                            </CustomTypography>
                            <CustomTypography
                              variant="Medium20"
                              sx={{ marginBottom: "6px" }}
                            >
                              {isRealisedUnrealisedGainLoading ? (
                                <Skeleton
                                  animation="wave"
                                  sx={{
                                    bgcolor: "#f8f8f8",
                                    width: "70%",
                                    height: "16px",
                                  }}
                                />
                              ) : (
                                <AmountWithTooltip
                                  Value={performanceXIRRData?.RealisedGain}
                                />
                              )}
                            </CustomTypography>
                            {/* <CustomTypography variant="Medium16" sx={{ color: { xs: "#5F854C" } }}> {'+ 4.65%'}</CustomTypography> */}
                          </Box>

                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                          />
                        </>
                      )}
                      {showRealisedUnrealisedPNL && (
                        <>
                          <Box className={styles.unrealisedGain}>
                            <CustomTypography
                              variant="Regular18"
                              sx={{ color: "#64677A", marginBottom: "6px" }}
                            >
                              {"Unrealised P&L"}
                            </CustomTypography>
                            <CustomTypography variant="Medium20">
                              {isRealisedUnrealisedGainLoading ? (
                                <Skeleton
                                  animation="wave"
                                  sx={{
                                    bgcolor: "#f8f8f8",
                                    width: "70%",
                                    height: "16px",
                                  }}
                                />
                              ) : (
                                <AmountWithTooltip
                                  Value={performanceXIRRData?.UnrealisedGain}
                                />
                              )}
                            </CustomTypography>
                          </Box>
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                          />
                        </>
                      )}
                      <Box
                        className={[
                          styles.income,
                          showRealisedUnrealisedPNL ? "" : styles.incomeLong,
                        ].join(" ")}
                      >
                        <CustomTypography
                          variant="Regular18"
                          sx={{ color: "#64677A" }}
                        >
                          Total Income
                        </CustomTypography>
                        <CustomTypography
                          variant="Medium20"
                          sx={{ width: "100%" }}
                        >
                          {isPerformanceLoading ? (
                            <Skeleton
                              animation="wave"
                              sx={{
                                bgcolor: "#f8f8f8",
                                width: "70%",
                                height: "16px",
                              }}
                            />
                          ) : (
                            <AmountWithTooltip
                              Value={performanceXIRRData?.TotalIncome}
                            />
                          )}
                        </CustomTypography>
                      </Box>
                    </Box>
                  </DashboardItemContainer>
              }

            </Grid>
            {/* Over all portFolio XIRR */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ marginTop: { xs: "24px", md: "0px" } }}
            >
              <DashboardItemContainer className="apptour-performance-indiceschart">
                <XIRRTotalIndicesChart
                  SpecificTitleText={props.SpecificTitleText}
                  IsPerformanceViewAllowed={IsPerformanceViewAllowed}
                />
              </DashboardItemContainer>
            </Grid>
          </Grid>
        </Box>

        {
          IsBenchmarkXIRRAllowed ? (
            <Box sx={{ margin: "5px 40px 32px 40px" }}>
              <DashboardItemContainer>
                <XIRRBenchmarkChart
                  // SpecificTitleText={props.SpecificTitleText}
                  IsPerformanceViewAllowed={IsPerformanceViewAllowed}
                  MPrUrlParams={props.MPrUrlParams}
                  OnRefreshXIRRBenchmark={props.OnRefreshXIRRBenchmark}
                />
              </DashboardItemContainer>
            </Box>
          )
            :
            (
              isLegacyProduct &&
              <Box sx={{ margin: "5px 40px 32px 40px", opacity: !IsBenchmarkXIRRAllowed ? 0.3 : 1 }}>
                <DashboardItemContainer>
                  <XIRRBenchmarkChart
                    ShowLockedMessage={true}
                    IsPerformanceViewAllowed={true}
                    MPrUrlParams={props.MPrUrlParams}
                    OnRefreshXIRRBenchmark={() => { }}
                  />
                </DashboardItemContainer>
              </Box>
            )
        }

        {props.HideTable !== true && (
          <>
          {
              activePortfolio?.IsGroup && props.MPrUrlParams.Screen === SharedConstants.ScreenEnum.L1 ? 
              <Box
              sx={{
                marginTop: "44px",
                opacity: !IsPerformanceViewAllowed ? 0.3 : 1,
              }}
              className="apptour-performance-table"
            >
              <PerformanceTable
                MPrUrlParams={props.MPrUrlParams}
                OnRefreshXIRRTable={props.OnRefreshXIRRTablePortfolioWise}
                rowFieldName="Portfolio"
                disabled={!IsPerformanceViewAllowed}
                isGroupByPortfolio={true}
                isPerformancePeriodAllToDate={isPerformancePeriodAllToDate}
                showLockedMessage={isLegacyProduct}
              />
            </Box> : null
          }
        
            <Box
              sx={{
                marginTop: "44px",
                opacity: !IsPerformanceViewAllowed ? 0.3 : 1,
              }}
              className="apptour-performance-table"
            >
              <PerformanceTable
                MPrUrlParams={props.MPrUrlParams}
                OnRefreshXIRRTable={props.OnRefreshXIRRTable}
                rowFieldName={props.RowFieldName}
                rowFieldHeading={props.RowFieldHeading}
                disabled={!IsPerformanceViewAllowed}
                isPerformancePeriodAllToDate={isPerformancePeriodAllToDate}
              />
            </Box>
          </>
        )}

        {props.HideAdvancedChart !== true && (
          <AdvancedPerformanceChart
            MPrUrlParams={props.MPrUrlParams}
            OnRefreshXIRRFYWise={props.OnRefreshXIRRFYWise}
            OnRefreshXIRRYearWise={props.OnRefreshXIRRYearWise}
            AdvancedChartTitleText={
              props.AdvancedChartTitleText || props.SpecificTitleText
            }
            IsPerformanceViewAllowed={IsPerformanceViewAllowed}
          />
        )}
      </Box>


    </AppTour>
  );
}

const PerformanceLockedMessageSection = () => {
  const performanceLockedMessage = useAppSelector(
    SharedRedux.Licensing.Selectors.selectPerformanceViewLockedMessage
  );
  const userEmail = useAppSelector(SharedRedux.Login.Selectors.selectUserEmail);

  const onUpgradeClick = () => {
    if (performanceLockedMessage?.isContactUsButton === true) {
      onClickContactSupportForUpgrade();
    } else {
      window.open(
        "https://mprofit.in/buy" +
        (userEmail ? "?email=" + encodeURIComponent(userEmail) : ""),
        "_blank"
      );
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#cfe3c9",
        margin: "0px 40px 32px 40px",
        borderRadius: "32px",
        display: "flex",
        padding: "24px",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "70px" }}>
        <UpgradeStar />
      </Box>
      <Box sx={{ padding: "20px", flex: "1 1 100%" }}>
        <CustomTypography variant="Medium28">
          {performanceLockedMessage?.title}
        </CustomTypography>
        <CustomTypography
          variant="Regular18"
          sx={{ color: "#12131A" }}
        >
          {performanceLockedMessage?.body}
        </CustomTypography>
        {/* this box will contain star icon, title and message */}
      </Box>
      {performanceLockedMessage?.button && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonPrimaryCTA
            buttonText={performanceLockedMessage?.button}
            handleClick={onUpgradeClick}
          />
        </Box>
      )}
    </Box>
  );
};
