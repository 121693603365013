import { SharedConstants, SharedUtilities } from '@mprofit/shared'
import { Box, Divider, Grid } from '@mui/material'
import { ScreenerInIcon } from 'packages/web/src/assets/icons/ScreenerInIcon'
import { PopOutIcon } from 'packages/web/src/assets/icons/popOutIcon'
import AssetIcon from 'packages/web/src/assets/icons/todayL4/assetIcon'
import LargeCap from 'packages/web/src/assets/icons/todayL4/largeCap'
import MidCap from 'packages/web/src/assets/icons/todayL4/midCap'
import SectorIcon from 'packages/web/src/assets/icons/todayL4/sectorIcon'
import SmallCap from 'packages/web/src/assets/icons/todayL4/smallCap'
import { CustomTypography } from 'packages/web/src/components/shared'

const marketCapIcon = {
    [SharedConstants.MarketCap_Type.None]: <></>,
    [SharedConstants.MarketCap_Type.SmallCap]: <SmallCap />,
    [SharedConstants.MarketCap_Type.MidCap]: <MidCap />,
    [SharedConstants.MarketCap_Type.LargeCap]: <LargeCap />
}

export default function L4StocksInfoCard({ stocksInfo }: { stocksInfo?: SharedConstants.StockAddInfo }) {
    const showOpenInScreenerInButton = stocksInfo && stocksInfo.ParentMProfitCode <= 0 && ((stocksInfo.NSESymbol && stocksInfo.NSESymbol.trim() != '') || (stocksInfo.BSECode && stocksInfo.BSECode > 0))
    return (
        <Grid container padding="16px 24px" spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <AssetIcon />
                    <CustomTypography sx={{ paddingLeft: '16px' }} variant="Regular24">About</CustomTypography>
                </Box>
                {
                    showOpenInScreenerInButton &&
                    <Box
                        sx={{
                            paddingLeft: '16px', paddingRight: '16px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '50px', border: '1px solid #EBECF2', backgroundColor: '#F9F9F9',
                            flexDirection: 'row', justifyContent: 'space-between', display: 'flex', alignItems: 'center', gap: '8px',
                            ":hover": {
                                backgroundColor: '#EBECF2',
                                cursor: 'pointer'
                            }
                        }}
                        onClick={
                            () => {
                                var screenerInURL = 'https://screener.in/company/';
                                if (stocksInfo.BSECode && stocksInfo.BSECode > 0) {
                                    screenerInURL += `bse:${encodeURIComponent(stocksInfo.BSECode)}/`;
                                } else {
                                    screenerInURL += `nse:${encodeURIComponent(stocksInfo.NSESymbol)}/`;
                                }
                                window.open(screenerInURL, '_blank');
                            }
                        }
                    >
                        <PopOutIcon />
                        <ScreenerInIcon />
                    </Box>
                }
            </Grid>
            <Grid item xs={12}>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', background: '#f9f9fb', borderRadius: '16px', padding: '25px 16px 24px 24px', alignItems: 'center' }}>
                    <Box>
                        <CustomTypography variant="Regular16">{stocksInfo?.NSESymbol || stocksInfo?.BSESymbol ? 'Symbol' : 'Code'}</CustomTypography>
                        <CustomTypography variant="Regular28">{stocksInfo?.NSESymbol || stocksInfo?.BSESymbol || stocksInfo?.BSECode}</CustomTypography>
                    </Box>
                    {
                        stocksInfo &&
                        <Box sx={{ display: 'flex', alignItems: 'center', background: SharedUtilities.getMarketCapColorFromID(stocksInfo.MarketCapClassification), borderRadius: '32px', padding: '2px 12px' }}>
                            {marketCapIcon[stocksInfo.MarketCapClassification as SharedConstants.MarketCap_Type]}
                            <CustomTypography variant='Regular14' sx={{ marginLeft: '8px' }}>{SharedUtilities.getMarketCapNameFromID(stocksInfo.MarketCapClassification)}</CustomTypography>
                        </Box>
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    <SectorIcon />
                    <CustomTypography sx={{ color: { xs: "#64677A" }, paddingLeft: '8px' }} variant='Regular18'>Sector</CustomTypography>
                </Box>
                <CustomTypography variant='Regular20'>{stocksInfo?.SectorName || 'Other'}</CustomTypography>
            </Grid>
            {
                stocksInfo?.ISIN &&
                <>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTypography sx={{ color: { xs: "#64677A" } }} variant='Regular18'>ISIN</CustomTypography>
                        <CustomTypography variant='Regular20'>{stocksInfo.ISIN}</CustomTypography>
                    </Grid>
                </>
            }
        </Grid>
    )
}
