import { CompleteChartPage, PdfBarChart } from "@mprofit-soft/pdf";
import { DownloadPDF, ExtraHeaderProps, ExtraOverviewProps } from "../../download-pdf";
import { ChartsSelectors } from "packages/web/src/redux/selectors/chartsSelectors";
import { usePDFSelector } from "../../download-pdf/redux";

const _XIRRBarChartPDFPage = ({ headerProps, overviewProps, specificTitleText}: {
        headerProps: ExtraHeaderProps;
        overviewProps: ExtraOverviewProps;
        specificTitleText: string;
    }) => {
    const {chartData, domain} = usePDFSelector(ChartsSelectors.selectXIRRTotalChartData(specificTitleText));

    if (!chartData) {
        return <></>;
    }

    return <CompleteChartPage
        headerProps={{
            ...headerProps,
            reportType: "Performance",
        }}
        overviewProps={{
            ...overviewProps,
            heading: "My XIRR vs. Index CAGR"
        }}
        ChartComponent={(extraChartProps) => 
        <PdfBarChart
            {...extraChartProps}
            chartData={chartData}
            barSizeType="small"
            isFirstBarBlack={true}
            axisDomain={domain}
        />
        }
    />
}

export const XIRRBarChartPDFPage = (specificTitleText: string) => ({ headerProps, overviewProps }: {
    headerProps: ExtraHeaderProps;
    overviewProps: ExtraOverviewProps;
}) => {
    return <_XIRRBarChartPDFPage {...{headerProps, overviewProps, specificTitleText}}/>;
}

export const XIRRBarChartPDFDownloadButton = ({SpecificTitleText}: {SpecificTitleText: string}) => {
    return <DownloadPDF
        PDFPage={
            XIRRBarChartPDFPage(SpecificTitleText)
        }
        ReportNameForFileName="Benchmark CAGR"
    />
}