export interface PortfolioListByPType {
    [PType: number]: Portfolio[]
}

export interface Portfolio {
    Id: number,
    FamilyId: number,
    CCID: number,
    PFolioType: number,
    PName: string,
    PFullName: string,
    IsGroup: boolean,
    PMobile: number,
    PAN: string,
    Email: string,
    IsCashMgmtPortfolio: boolean;
    IsStrategyPortfolio: boolean;
    IsGoalPortfolio: boolean;
    FamilyName?: string;
}

export interface PortfoliosByPTypeRaw {
    PList: Portfolio[],
    PType: PFolioTypes
}

export enum PFolioTypes {
    PMS = 0,
    FNO = 5,
    ACT = 10
}

export interface Family {
    FamilyId: number;
    FamilyName: string;
    Category: string;
    CategoryId: number;
    PeriodFrom: string;
    PeriodTo: string;
    CustomerID: number;
    LastPfid: number;
    Read: number;
    Write: number;
    Import: number;
    LockUnlock: number;
}

export interface FamiliesByID {
    ByID: {[ID: number]: Family},
    AllIDs: number[]
}

export interface GlobalSearchResults {
    Family?: Family[],
    Portfolio?: Portfolio[],
    Assets?: AssetSearchItem[]
}

export interface AssetSearchItem {
    Name: string,
    AMID: number,
    AssetTypeID: number,
    AssetTypeName?: string
}

export interface GlobalSearchQuery {
    Term: string,
    Filter: GlobalSearchFilter
}

export enum GlobalSearchFilter {
    ALL = 0,
    FAMILY = 1,
    PORTFOLIO = 2,
    ASSET = 3
}

export interface PortfoliosInGroupByDB {
    [CCID: number]: PortfoliosInGroupByFamily;
}

export interface PortfoliosInGroupByFamily {
    [FamilyId: number]: PortfoliosInGroupByGroupPFID
    
}
export interface PortfoliosInGroupByGroupPFID {
    [PFID: number]: number[];
}

export interface DatabaseItem {
    IsShared: boolean;
    SharedByUserName: string;
    SharedByEmail: string | null;
    CustomerId: string;
    EmailAddress: string;
    IsCloud: boolean;
    DBVersion: number;
    Cloud_DBVersion: number;
    CanAccessAnalyticsForSharedData: boolean;
    CanAccessPerformanceViewForSharedData: boolean;
}