export default function EyeVisible(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_222475" data-name="Group 222475" transform="translate(-446 -540)">
                <rect id="Rectangle_34507" data-name="Rectangle 34507" width="48" height="48" transform="translate(446 540)" fill="#fff" opacity="0" />
                <g id="Group_222477" data-name="Group 222477" transform="translate(458.766 552.251)">
                    <path id="Path_237804" data-name="Path 237804" d="M11.787,14.573A2.787,2.787,0,1,0,9,11.787,2.787,2.787,0,0,0,11.787,14.573Z" transform="translate(-0.553 -0.374)" fill="#babdcc" />
                    <path id="Path_237805" data-name="Path 237805" d="M1.317,10.9a10.454,10.454,0,0,1,19.834-.007,1.64,1.64,0,0,1,0,1.034,10.453,10.453,0,0,1-19.833.007,1.637,1.637,0,0,1,0-1.034Zm14.794.514a4.876,4.876,0,1,1-4.876-4.876,4.876,4.876,0,0,1,4.876,4.876Z" transform="translate(0)" fill="#babdcc" fill-rule="evenodd" />
                </g>
            </g>
        </svg>

    )
}
