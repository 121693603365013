import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import Router from './routes';

// for localization
import '../src/localization'
import store from '../src/redux';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { theme } from './components/shared/theme';

function App() {
  const queryClient = new QueryClient();

  try {
    (window as any).FreshworksWidget('hide', 'launcher');
  } catch {
      
  }

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;

