export function MFEQ(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226547" data-name="Group 226547" transform="translate(-17890 6539.205)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 -0.205)" fill="#fff" opacity="0" />
          <path id="Path_237158" data-name="Path 237158" d="M13.757,30.987a1.459,1.459,0,0,0-1.269-.253l-2.5.575a1.672,1.672,0,0,0-1.628-2.058H5.372a1.9,1.9,0,0,0-1.352.56L2.669,31.162H.956A.956.956,0,0,0,0,32.118v2.389a.956.956,0,0,0,.956.956H7.167a.474.474,0,0,0,.116-.014l3.823-.956a.414.414,0,0,0,.071-.024l2.322-.988.026-.012a1.469,1.469,0,0,0,.235-2.482ZM.956,32.118H2.389v2.389H.956Zm12.151.49-2.27.966-3.729.932H3.345V31.838L4.7,30.487a.947.947,0,0,1,.676-.28h2.99a.717.717,0,0,1,0,1.433H6.69a.478.478,0,1,0,0,.956H8.6a.5.5,0,0,0,.107-.012l4-.92.019,0a.514.514,0,0,1,.376.95ZM9.8,28.3a2.15,2.15,0,0,0,.352-.029,2.15,2.15,0,1,0,1.686-2.807A2.15,2.15,0,1,0,9.8,28.3Zm3.584-.717a1.195,1.195,0,1,1-1.195-1.195A1.195,1.195,0,0,1,13.379,27.579ZM9.8,24.951a1.2,1.2,0,0,1,1.15.873,2.15,2.15,0,0,0-.9,1.489,1.22,1.22,0,0,1-.254.027,1.195,1.195,0,1,1,0-2.389Z" transform="translate(2.475 -19.665)" fill="#fff" />
        </g>
      </g>
    </svg>
  );
}
