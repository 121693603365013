export function SwitchAccountIcon(props: any) {
    return (
      <svg id="Account_switching" data-name="Account switching" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <rect id="Rectangle_24101" data-name="Rectangle 24101" width="32" height="32" rx="3" fill="#010006" opacity="0.54"/>
        <g id="Account_switch" data-name="Account switch" transform="translate(4 4)">
          <path id="Path_272193" data-name="Path 272193" d="M0,0H24V24H0Z" fill="none"/>
          <path id="Path_272194" data-name="Path 272194" d="M6,21V19a4,4,0,0,1,4-4h2" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <path id="Path_272196" data-name="Path 272196" d="M8,7a4,4,0,1,0,4-4A4,4,0,0,0,8,7" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <g id="Group_228627" data-name="Group 228627" transform="translate(21767.492 -2886.571)">
            <path id="Path_272198" data-name="Path 272198" d="M16,3l1.778,1.778L16,6.555" transform="translate(-21765.715 2896.571)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"/>
            <path id="Path_272199" data-name="Path 272199" d="M10,7h4.444" transform="translate(-21762.381 2894.349)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"/>
            <path id="Path_272200" data-name="Path 272200" d="M5.778,13,4,14.778l1.778,1.778" transform="translate(-21759.049 2891.015)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"/>
            <path id="Path_272201" data-name="Path 272201" d="M4,17H8" transform="translate(-21759.049 2888.793)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"/>
          </g>
        </g>
      </svg>
    );
  }
  