import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import PerformanceLayout from "../layout";
import { Dispatch } from "react";
import { SharedRedux, SharedUtilities } from "@mprofit/shared";
import { useAppSelector } from "packages/web/src/redux/hooks";

interface L4_Performance_Props {
    MPrUrlParams: MPrUrlParams;
}

export default function L4_Performance({ MPrUrlParams }: L4_Performance_Props) {
    const assetName = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4AssetName);
    const amidSID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAMIDSID);
    const isWithRefNum = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsAssetTypeWithRefNum);
    const isTradedBonds = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsActiveAssetTypeTradedBonds);

    return (
        <PerformanceLayout
            MPrUrlParams={MPrUrlParams}
            OnRefreshXIRRTotal={onRefreshXIRR}
            OnRefreshXIRRFYWise={onRefreshXIRRFYWise}
            OnRefreshXIRRYearWise={onRefreshXIRRYearWise}
            OnRefreshXIRRTable={onRefreshXIRRTable}
            OnRefreshXIRRBenchmark={onRefreshXIRRBenchmark}
            OnRefreshXIRRTablePortfolioWise={() => {}}
            GenericTitleText={assetName ? SharedUtilities.truncateTextWithEllipsis(assetName, 40) + " XIRR" : ''}
            SpecificTitleText={assetName || ''}
            RowFieldName={'Ref #'}
            RowFieldHeading={'Asset'}
            HideTable={(amidSID.SID || -1) > 0 || !isWithRefNum}
            HideAdvancedChart={isTradedBonds}
            ShowPMSData={false}
        />
    );
}

const getXIRRPayload = (urlParams: MPrUrlParams, force?: boolean, noRefresh?: boolean): SharedRedux.Performance.RefreshXIRRPayload | undefined => {
    if (urlParams && urlParams.PortfolioID && urlParams.PortfolioID > 0 && urlParams.AssetType && urlParams.AssetType > 0) {
        return {
            CCID: urlParams.DatabaseID || -1, FamilyId: urlParams.FamilyID || -1, PFID: urlParams.PortfolioID || -1, ATTY: urlParams.AssetType,
            AMID: urlParams.AMID || -1, SID: urlParams.SID || -1,
            Force: force === true, NoRefresh: noRefresh === true
        }
    }
}

const onRefreshXIRR = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRTotal(refreshXIRRPayload));
    }
}

const onRefreshXIRRFYWise = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRFYWise(refreshXIRRPayload));
    }
}

const onRefreshXIRRYearWise = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRYearWise(refreshXIRRPayload));
    }
}

const onRefreshXIRRTable = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRTable(refreshXIRRPayload));
    }
}

const onRefreshXIRRBenchmark = (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean, noRefresh?: boolean) => {
    var refreshXIRRPayload = getXIRRPayload(urlParams, force, noRefresh);

    if (refreshXIRRPayload) {
        dispatch(SharedRedux.Performance.Actions.refreshXIRRBenchmark(refreshXIRRPayload));
    }
}