import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import TodayLayout from "../layout";
import { PieChartWithBottomLegend } from "../../../shared";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import SharedVirtualizedTable, { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import TodayValueCard from "../todayValueCard";
import TodayGainLoss from "../todayGainLoss";
import TodayPerformanceColumnChart from "../todayPerformanceColumnChart";
import { NavigateToAsset } from "packages/web/src/routes/navigationMethods";
import { useNavigate, useLocation } from 'react-router-dom';
import { getHoldingsCategName } from "packages/shared/src/utilities";

interface L3_Today_Props {
  MPrUrlParams: MPrUrlParams;
}

export default function L3_Today(props: L3_Today_Props) {
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);

  return <TodayLayout
    {...props}
    Screen_TodayValueCard={L3TodayValueCard}
    Screen_TodayGainLossCard={L3TodayGainLossCard}
    Screen_PieChart={L3PieChart}
    Screen_TodayTable={L3TodayTable}
    Screen_TodayPerformanceColumnChart={L3TodayPerformanceColumnChart}
    IsLoading={isLoading}
    ShowStocksCards={false}
    TableTitle={'Current Investments'}
  />;
}

const L3TodayValueCard = () => {
  const L3Total = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3PortfolioSummaryTotal);
  const L3IDs = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3IDs);

  return <TodayValueCard
    ScreenType={L3IDs?.Mode ? getHoldingsCategName(L3IDs.Mode) : ''}
    Title={L3Total?.CategName}
    Screen={SharedConstants.ScreenEnum.L3}
    CurrentValue={L3Total?.CurrValue}
    Icon={undefined}
  />
}

const L3TodayGainLossCard = () => {
  const L3Total = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3PortfolioSummaryTotal);

  return <TodayGainLoss TotalValues={L3Total} />
}

const L3TodayTable = ({searchTerm}: {searchTerm: string}) => {
  const amidList = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3TableData) || [];
  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const onRowClick = (row: RowDataProps) => {
    console.log('onRowClick', row);
    if (row && row.AssetTypeID > 0 && row.AMID > 0) {
      NavigateToAsset({navigate, activeScreenIDs, assetTypeID: row.AssetTypeID, AMID: row.AMID, SID: row.SID, pathname: location.pathname });
    }
  }

  var fieldNamesToSearch = ['Name'];

  return <SharedVirtualizedTable columns={tableColumns} rows={amidList} headerColor="#EAF4DF" sorting={true} onRowClick={onRowClick} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch}/>
}

const L3PieChart = () => {
  const { PieChartData, TotalValue } = useAppSelector((state) => SharedRedux.PortfolioSummary.Selectors.selectL3PieChartData(state, 'CurrValue'));

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);

  const onItemClick = (item: SharedConstants.PieChartData) => {
    if (item && assetTypeID && assetTypeID > 0 && item.IDs.AMID > 0) {
      NavigateToAsset({navigate, activeScreenIDs, assetTypeID, AMID: item.IDs.AMID, SID: item.IDs.SID, pathname: location.pathname });
    }
  }

  return <PieChartWithBottomLegend data={PieChartData} totalValue={TotalValue} onItemClick={onItemClick}/>
}

const L3TodayPerformanceColumnChart = () => {
  const assetType = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);
  const indexChartData = useAppSelector(SharedRedux.Dashboard.Selectors.selectTodayPerformanceIndicesChartValues);
  
  if (assetType == SharedConstants.AssetTypeEnum.EQ) {
    return <L3TodayPerformanceColumnChartStocks indexChartData={indexChartData}/>
  } else {
    return <TodayPerformanceColumnChart data={indexChartData} />
  }
}

const L3TodayPerformanceColumnChartStocks = ({indexChartData}: {indexChartData: SharedConstants.BarChartData[] | undefined}) => {
  const myStocksTGainPct = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectMyStocksTGainPct);
  return <TodayPerformanceColumnChart data={indexChartData && myStocksTGainPct ? [{ Name: 'My Stocks & ETFs', Value: myStocksTGainPct, IsBlack: true }, ...indexChartData].slice(0, 6) : indexChartData} />
}

const tableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'Name',
    HeaderName: 'ASSET',
    ColumnWidthPercent: '22%',
    FieldFormatter: TableDefaultFieldFormatter,
    Align: 'left',
    IsRowName: true
  },
  {
    FieldKey: 'Quant',
    HeaderName: "QTY",
    ColumnWidthPercent: '13%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right'
  },
  {
    FieldKey: 'PxPur',
    HeaderName: "AVG PUR PRICE",
    ColumnWidthPercent: '13%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right'
  },
  {
    FieldKey: 'PxCurr',
    HeaderName: "CURRENT PRICE",
    ColumnWidthPercent: '13%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right'
  },
  {
    FieldKey: 'TGain',
    HeaderName: "TODAY'S GAIN",
    ColumnWidthPercent: '13%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'TGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'TUp'
      }
    ]
  },
  {
    FieldKey: 'OGain',
    HeaderName: 'UNREALISED GAIN',
    ColumnWidthPercent: '13%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'OGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'OUp'
      }
    ]
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '13%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  }
];