
export function RcaDemergerIcon(props: any) {
  return (
    <svg id="Group_150779" data-name="Group 150779" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <rect id="Rectangle_24213" data-name="Rectangle 24213" width="48" height="48" rx="8" fill="#f5f5f6" />
      <g id="arrows-expand" transform="translate(13.914 12)">
        <path id="Path_198202" data-name="Path 198202" d="M4.5,14.271a.864.864,0,0,1,.869-.859H24.493a.859.859,0,1,1,0,1.717H5.369A.864.864,0,0,1,4.5,14.271ZM14.931,11.7a.864.864,0,0,0,.869-.859V3.109a.869.869,0,0,0-1.738,0v7.728A.864.864,0,0,0,14.931,11.7Z" transform="translate(-4.5 -2.248)" fill="#12131a" fillRule="evenodd" />
        <path id="Path_198203" data-name="Path 198203" d="M20.71,7.211a.877.877,0,0,0,0-1.231L17.275,2.5a.851.851,0,0,0-1.216,0L12.625,5.98a.878.878,0,0,0,0,1.231.852.852,0,0,0,1.216,0l2.827-2.863,2.827,2.863a.851.851,0,0,0,1.216,0Zm-4.042,9.816a.864.864,0,0,1,.859.869v7.823a.859.859,0,1,1-1.717,0V17.9A.864.864,0,0,1,16.668,17.027Z" transform="translate(-6.237 -2.247)" fill="#12131a" fillRule="evenodd" />
        <path id="Path_198204" data-name="Path 198204" d="M20.71,27.251a.859.859,0,0,1,0,1.216L17.275,31.9a.859.859,0,0,1-1.216,0l-3.435-3.435a.86.86,0,0,1,1.216-1.216l2.827,2.828,2.827-2.829a.859.859,0,0,1,1.216,0Z" transform="translate(-6.237 -7.811)" fill="#12131a" fillRule="evenodd" />
      </g>
    </svg>
  );
}