export const onClickContactSupportForUpgrade = () => {
    try {
        (window as any).FreshworksWidget('prefill', 'ticketForm', {
            custom_fields: {
              cf_customer_selected_type: 'Upgrade inquiry', //Number field
            }
          });
        (window as any).FreshworksWidget('open', 'ticketForm');
    } catch {
        window.open('https://www.mprofit.in/contact-us/', "_blank");
    }
}