import { Box, styled } from '@mui/material'
import Lottie from 'react-lottie'
import * as LoginBtnAnimation from '../../../../assets/animation/loginBtnLoader.json'

export default function LoginBtnLoader() {
    return (
        <MainContent>
            <AnimationConainer>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: LoginBtnAnimation,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={'auto'}
                    width={'auto'}
                />
            </AnimationConainer>
        </MainContent>
    )
}



const MainContent = styled(Box)({
    textAlign: 'center'
});

const AnimationConainer = styled(Box)({
    width: '100px',
    height: '100px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%'
});
