import { CompleteChartPage, PdfPMSXIRRSnapshot } from "@mprofit-soft/pdf";
import { DownloadPDF, ExtraHeaderProps, ExtraOverviewProps } from "../../../download-pdf";
import { ChartsSelectors } from "packages/web/src/redux/selectors/chartsSelectors";
import { usePDFNumberFormatPrefs, usePDFSelector } from "../../../download-pdf/redux";
import { Box } from "@mui/material";

export const PMSXIRRCardPage = ({ headerProps, overviewProps}: {
        headerProps: ExtraHeaderProps;
        overviewProps: ExtraOverviewProps;
    }) => {
    const { XIRRDisplay, performanceXIRRData, isPerformancePeriodAllToDate, periodText } = usePDFSelector(ChartsSelectors.selectXIRRCardData);
    const numberFormatPrefs = usePDFNumberFormatPrefs();

    if (!XIRRDisplay || !performanceXIRRData) {
        return <></>;
    }

    return <CompleteChartPage
        headerProps={{
            ...headerProps,
            reportType: "Performance"
        }}
        overviewProps={{
            ...overviewProps,
            heading: "Performance Snapshot"
        }}
        ChartComponent={(extraChartProps) => 
            <PdfPMSXIRRSnapshot
            {...extraChartProps}
            {
                ...{
                    PeriodText: periodText,
                    XIRRDisplay: XIRRDisplay || '',
                    XIRR: performanceXIRRData.XIRR,
                    OpeningValuation: performanceXIRRData.OpeningValuation,
                    ClosingValuation: performanceXIRRData.ClosingValuation,
                    OpeningCashBalance: performanceXIRRData.OpeningCashBalance,
                    ClosingCashBalance: performanceXIRRData.ClosingCashBalance,
                    TotalInflow: performanceXIRRData.TotalInflow,
                    TotalOutflow: performanceXIRRData.TotalOutflow,
                    TotalFees: performanceXIRRData.TotalFees,
                    IncomePayout: performanceXIRRData.IncomePayout,
                    TotalGain: performanceXIRRData.TotalGain,
                    IsPerformancePeriodAllToDate: isPerformancePeriodAllToDate,
                    NumberFormatPrefs: numberFormatPrefs
                }
            }
            />}
    />
}

export const PMSXIRRCardPDFDownloadButton = ({
        IsHideIcon,
        IsDownloadOnMount,
        OnDownloadComplete
    }: {
        IsHideIcon?: boolean,
        IsDownloadOnMount?: boolean;
        OnDownloadComplete?: () => void;
    }) => {
    return <Box>
        <DownloadPDF
            PDFPage={
                PMSXIRRCardPage
            }
            ReportNameForFileName="Performance Snapshot"
            IsWhiteBackgroundButton={true}
            IsHideIcon={IsHideIcon}
            IsDownloadOnMount={IsDownloadOnMount}
            OnDownloadComplete={OnDownloadComplete}
        />
    </Box>
}