
import { CustomTypography, GraphLoader, RecentCorporateActions } from "../../../shared";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities } from "@mprofit/shared";
import { Box, Grid } from "@mui/material";
import L4TodayValueCard from "./L4TodayValueCard";
import L4CurrentPriceCard from "./L4CurrentPriceCard";
import { DashboardItemContainer } from "../../shared";
import TodayPerformanceColumnChart from "../todayPerformanceColumnChart";
import L4StocksInfoCard from "./L4StocksInfoCard";
import { useLocation, useNavigate } from "react-router-dom";
import SharedVirtualizedTable, { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import { getColumns } from "../level_2";
import { NavigateToAsset } from "packages/web/src/routes/navigationMethods";
import { TableSearchBar } from "../../../shared/table-search-bar";
import { useState } from "react";
import useAutoScrollTop from "../../../hooks/useAutoScrollTop";
import L4MFInfoCard from "./L4MFInfoCard";


export default function L4_Today() {
    const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);
    const activeScreen = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreen);

    const assetAddInfo = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4AssetAddInfo);

    const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType) || -1;
    var showAddInfoCard = assetAddInfo !== undefined;
    if (showAddInfoCard) {
        if (assetTypeID === SharedConstants.AssetTypeEnum.EQ) {
            showAddInfoCard = true;
        } else if ([SharedConstants.AssetTypeEnum.EQ, SharedConstants.AssetTypeEnum.MFEQ, SharedConstants.AssetTypeEnum.MFD].includes(assetTypeID)) {
            var SEBICategory = (assetAddInfo as SharedConstants.MFAddInfo).SEBICategory;
            if (SEBICategory && SEBICategory.trim() != '') {
                showAddInfoCard = true;
            }
        }
    }
    
    const showQuant = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsAssetTypeWithQuant);
    const isStocks = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsAssetTypeStocks);
    useAutoScrollTop();

    return (
        <>
            {
                isLoading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', background: '#FFFFFF', height: '100%' }} >
                        <GraphLoader />
                    </Box >
                    :
                    <>
                    <Grid spacing={2} className="todayContainer" container padding='0px 40px'>
                        <Grid item xs={showQuant ? 8 : 12}>
                            <DashboardItemContainer>
                                <TodayValueCard />
                            </DashboardItemContainer>
                        </Grid>
                        {
                            showQuant &&
                            <Grid item xs={4}>
                                <DashboardItemContainer>
                                    <CurrentPriceCard />
                                </DashboardItemContainer>
                            </Grid>
                        }
                        <Grid item xs={showAddInfoCard ? 8 : 12} sx={{ display: 'flex' }}>
                            <L4TodayPerformanceColumnChart />
                        </Grid>
                        {
                            showAddInfoCard &&
                            <Grid item xs={4}>
                                <DashboardItemContainer>
                                    <TodayAssetCard assetAddInfo={assetAddInfo} />
                                </DashboardItemContainer>
                            </Grid>
                        }
                        {
                            isStocks &&
                            <Grid item xs={12} sx={{marginBottom: '50px'}}>
                                <DashboardItemContainer>
                                    <RecentCorporateActions />
                                </DashboardItemContainer>
                            </Grid>
                        }
                    </Grid>
                    {
                        (assetTypeID !== undefined && assetTypeID > SharedConstants.AssetTypeEnum.EQ) && activeScreen === SharedConstants.ScreenEnum.L4 &&
                        <Box padding="0px 40px" marginTop="70px">
                        <L4TodayTableWithContainer assetTypeID={assetTypeID}/>
                        </Box>
                    }
                    </>
            }
        </>
    )
}

const CurrentPriceCard = () => {
    const activeL4PortfolioSummaryTotal = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4PortfolioSummaryTotal);

    return (
        <L4CurrentPriceCard
            PxCurr={activeL4PortfolioSummaryTotal?.PxCurr}
            Quant={activeL4PortfolioSummaryTotal?.Quant}
            PxPur={activeL4PortfolioSummaryTotal?.PxPur}
        />
    )
}

const TodayValueCard = () => {
    const activeL4PortfolioSummaryTotal = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4PortfolioSummaryTotal);
    const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);
    const assetTypeName = assetTypeID && assetTypeID > 0 ? SharedConstants.AssetTypeNameMapping[assetTypeID as SharedConstants.AssetTypeEnum] : undefined;

    return (
        <L4TodayValueCard
            BackgroundColor="#E9EDF9"
            ScreenType={assetTypeName}
            AssetName={activeL4PortfolioSummaryTotal?.Name}
            RefNoStr={(activeL4PortfolioSummaryTotal?.SID !== undefined && activeL4PortfolioSummaryTotal.SID > 0) ? activeL4PortfolioSummaryTotal.Refno : ''}
            TotalFields={activeL4PortfolioSummaryTotal}
        />
    )
}

const L4TodayPerformanceColumnChart = () => {
    const assetType = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);
    const indexChartData = useAppSelector(SharedRedux.Dashboard.Selectors.selectTodayPerformanceIndicesChartValues);
    console.log('indexChartData', indexChartData)

    if (assetType === SharedConstants.AssetTypeEnum.EQ) {
        return <L4TodayPerformanceColumnChartStocks indexChartData={indexChartData} />
    } else {
        return <TodayPerformanceColumnChart data={indexChartData} />
    }
}

const L4TodayPerformanceColumnChartStocks = ({ indexChartData }: { indexChartData: SharedConstants.BarChartData[] | undefined }) => {
    const stockTGainPct = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4TGainPct);
    const assetName = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4AssetName);
    return <TodayPerformanceColumnChart data={indexChartData && stockTGainPct ? [{ Name: assetName || 'Asset', Value: stockTGainPct, IsBlack: true }, ...indexChartData].slice(0, 6) : indexChartData} myDataName={assetName} isSingleAsset={true}/>
}

const TodayAssetCard = ({ assetAddInfo }: { assetAddInfo?: SharedConstants.StockAddInfo | SharedConstants.MFAddInfo | SharedConstants.AssetAllocationInfo }) => {
    const assetType = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);
    console.log(assetAddInfo,'assetAddInfo')

    if (assetType === SharedConstants.AssetTypeEnum.EQ) {
        return <L4StocksInfoCard stocksInfo={assetAddInfo as SharedConstants.StockAddInfo} />
    } else if (assetType === SharedConstants.AssetTypeEnum.MFEQ || assetType == SharedConstants.AssetTypeEnum.MFD) {
        return <L4MFInfoCard mfInfo={assetAddInfo as SharedConstants.MFAddInfo} />
    } else {
        return <></>
    }
}

const L4TodayTableWithContainer = ({assetTypeID}: {assetTypeID: SharedConstants.AssetTypeEnum}) => {
    const totals = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL4TodayTable_BySID);

    console.log(totals, 'L4TodayTableWithContainer')

    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

    if (totals.length == 0) {
        return <></>;
    }
  
    const onRowClick = (row: RowDataProps) => {
      console.log('onRowClick', row);
      if (row && row.SID > 0) {
        NavigateToAsset({ navigate, activeScreenIDs, assetTypeID: row.AssetTypeID, AMID: row.AMID, SID: row.SID, pathname: location.pathname });
      }
    }

    var fieldNamesToSearch = ['Refno'];

    return <Grid container spacing={{ xs: 2, lg: 3 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
            <CustomTypography variant="Medium28">{'Folio-wise'}</CustomTypography>
            <Box width="408px">
                {/* <SearchBar searchOptions={[]} /> */}
                <TableSearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
            </Box>
        </Grid>
        <Grid item xs={12} marginBottom={"40px"}>
            <DashboardItemContainer>
                <SharedVirtualizedTable columns={getColumns(SharedConstants.HoldingsMode.Assets, assetTypeID, SharedUtilities.getRefNumField(assetTypeID), 'Refno')} rows={totals} headerColor="#EAF4DF" sorting={true} onRowClick={onRowClick} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch}/>
            </DashboardItemContainer>
        </Grid>
    </Grid>
}