import React from 'react'

export default function SmallCap(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_222311" data-name="Group 222311" transform="translate(-2.813)">
                <rect id="Rectangle_34469" data-name="Rectangle 34469" width="20" height="20" transform="translate(2.812 0)" fill="#12131a" opacity="0" />
                <g id="Group_222312" data-name="Group 222312" transform="translate(2.813 0.001)">
                    <path id="Path_237222" data-name="Path 237222" d="M0,0H20V20H0Z" fill="none" />
                    <path id="Path_237223" data-name="Path 237223" d="M5,18.917c.4-3.562,1.979-6.333,5.542-7.917" transform="translate(-0.748 -1.292)" fill="none" stroke="#12131a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Path_237224" data-name="Path 237224" d="M8.268,15.083c5.311,0,8.968-2.6,9.395-9.5V4H14.235C6.548,4,4,7.167,3.986,11.125a4.907,4.907,0,0,0,1.708,3.958H8.256Z" transform="translate(-0.83 -0.434)" fill="none" stroke="#12131a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                </g>
            </g>
        </svg>

    )
}
