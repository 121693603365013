export function ArrowNarrowDownIcon(props: any) {
  return (
    <svg id="ic-arrow-narrow-down" xmlns="http://www.w3.org/2000/svg" width="29.971" height="29.971" viewBox="0 0 29.971 29.971">
      <path id="Path_397" data-name="Path 397" d="M0,0H29.971V29.971H0Z" fill="none"/>
      <g id="Group_152070" data-name="Group 152070" transform="translate(9.457 5.384)">
        <line id="Line_23" data-name="Line 23" y1="18.968" transform="translate(5.646 0)" fill="none" stroke="#c94c40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <line id="Line_24" data-name="Line 24" x1="5.619" y2="5.62" transform="translate(5.676 13.295)" fill="none" stroke="#c94c40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <line id="Line_25" data-name="Line 25" x2="5.619" y2="5.62" transform="translate(0 13.295)" fill="none" stroke="#c94c40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </svg>
  );
}