import { Button, ButtonProps } from '@mui/material'

interface IButtonWithTextAndIconProps extends ButtonProps {
 buttonlabel?: string;
}

export default function ButtonWithTextAndIcon(props: IButtonWithTextAndIconProps) {
 return (
  <Button
   {...props}
   disableRipple
   sx={styles}
  >
   {props.buttonlabel}
  </Button>
 )
}

const styles = {
 background: '#FFFFFF',
 border: '1px solid #D8DAE5',
 borderRadius: '4px',
 color: '#64677A',
 textTransform: 'unset',
 '&:hover': { backgroundColor: '#EFF0F5' },
}