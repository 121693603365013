
export function IncomeOtherLargeIcon(props: any) {
 return (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="125.002" height="140.835" viewBox="0 0 125.002 140.835">
   <g id="Group_154204" data-name="Group 154204" transform="translate(0.5 0)" style={{ mixBlendMode: 'luminosity', isolation: 'isolate' }}>
    <g id="Group_154351" data-name="Group 154351" transform="translate(22.789 23.686) rotate(-11)">
     <ellipse id="Ellipse_2316" data-name="Ellipse 2316" cx="31.797" cy="30.221" rx="31.797" ry="30.221" transform="translate(0 0)" fill="#acc18a" />
     <path id="Path_235205" data-name="Path 235205" d="M27.444,1.812C42.3,1.71,54.482,13.213,55.2,27.178c.014-.374.025-.748.022-1.126C55.122,11.56,42.68-.1,27.43,0S-.1,11.939,0,26.431c0,.378.019.753.037,1.126C.564,13.585,12.592,1.914,27.444,1.812Z" transform="translate(5.258 2.084)" fill="#101010" />
     <path id="Path_235206" data-name="Path 235206" d="M27.785,25.305C12.933,25.407.749,14.346.032.38A10.433,10.433,0,0,0,.01,1.507C1.067,16.85,15.063,25.945,27.789,25.858c13.354-.092,26.3-9.788,27.442-24.731A8.968,8.968,0,0,0,55.194,0C54.668,13.974,42.636,25.2,27.785,25.305Z" transform="translate(5.258 28.137)" fill="#101010" />
     <path id="Path_235207" data-name="Path 235207" d="M30.581,27.858C14.229,27.971.814,15.792.025.416.009.828,0,1.24,0,1.657c.11,15.956,13.809,28.8,30.6,28.682s30.31-13.145,30.2-29.1c0-.416-.021-.828-.042-1.239C60.178,15.383,46.932,27.745,30.581,27.858Z" transform="translate(2.479 28.12)" fill="#101010" />
     <path id="Path_235215" data-name="Path 235215" d="M12.007,28.685a1.271,1.271,0,0,1-.676-.164,2.208,2.208,0,0,1-.512-.451L.942,16.555a5.231,5.231,0,0,1-.369-.471,1.2,1.2,0,0,1-.123-.635V15A.886.886,0,0,1,.7,14.342a.886.886,0,0,1,.656-.246H3.811a11.284,11.284,0,0,0,4.733-.779,4.368,4.368,0,0,0,2.213-2.09,7.071,7.071,0,0,0,.594-2.95,7.071,7.071,0,0,0-.594-2.95,4.4,4.4,0,0,0-2.131-2.09A9.744,9.744,0,0,0,4.3,2.459H.9a.886.886,0,0,1-.656-.246A.886.886,0,0,1,0,1.557V.9A.886.886,0,0,1,.246.246.886.886,0,0,1,.9,0H16.719a.886.886,0,0,1,.656.246A.886.886,0,0,1,17.621.9v.656a.886.886,0,0,1-.246.656.886.886,0,0,1-.656.246H10.736L8.769,1.311A5.608,5.608,0,0,1,12.826,3.79a8.779,8.779,0,0,1,1.106,4.487,9.019,9.019,0,0,1-.9,4.016,6.671,6.671,0,0,1-2.971,2.95A11.717,11.717,0,0,1,4.549,16.35H3.524l-.287-1.065L13.687,27.5a.826.826,0,0,1,.287.574.5.5,0,0,1-.246.471,1.118,1.118,0,0,1-.574.143ZM.9,9.015a.886.886,0,0,1-.656-.246A.886.886,0,0,1,0,8.114V7.458A.886.886,0,0,1,.246,6.8.886.886,0,0,1,.9,6.556H16.719a.846.846,0,0,1,.9.9v.656a.846.846,0,0,1-.9.9Z" transform="translate(24.058 15.879)" fill="#131100" />
    </g>
    <g id="Group_153091" data-name="Group 153091" transform="translate(0 67.509)">
     <g id="Group_154219" data-name="Group 154219">
      <path id="Path_207259" data-name="Path 207259" d="M197.38,201.526H90.761a4.934,4.934,0,0,1-4.961-4.961v-62.9a4.934,4.934,0,0,1,4.961-4.961H197.38a4.934,4.934,0,0,1,4.961,4.961v62.9A4.934,4.934,0,0,1,197.38,201.526Z" transform="translate(-85.8 -128.7)" fill="#485d3e" stroke="#485d3e" strokeWidth="1" />
      <line id="Line_733" data-name="Line 733" x2="3.557" transform="translate(104.65 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_734" data-name="Line 734" x2="3.557" transform="translate(97.351 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_735" data-name="Line 735" x2="3.744" transform="translate(89.582 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_736" data-name="Line 736" x2="3.744" transform="translate(82.092 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_737" data-name="Line 737" x2="3.651" transform="translate(75.261 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_738" data-name="Line 738" x2="3.557" transform="translate(68.708 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_739" data-name="Line 739" x2="3.37" transform="translate(62.624 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_740" data-name="Line 740" x2="3.183" transform="translate(56.54 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_741" data-name="Line 741" x2="3.089" transform="translate(49.797 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_742" data-name="Line 742" x2="3.557" transform="translate(42.59 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <path id="Path_207260" data-name="Path 207260" d="M205.2,132.4a4.468,4.468,0,0,1,1.5,2.434" transform="translate(-93.433 -128.936)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_743" data-name="Line 743" x1="3.557" transform="translate(6.833 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_744" data-name="Line 744" x1="3.557" transform="translate(14.133 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_745" data-name="Line 745" x1="3.838" transform="translate(21.623 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_746" data-name="Line 746" x1="3.838" transform="translate(29.113 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_747" data-name="Line 747" x1="3.651" transform="translate(36.13 2.434)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_748" data-name="Line 748" x1="3.557" transform="translate(18.254 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_749" data-name="Line 749" x1="3.744" transform="translate(25.837 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_750" data-name="Line 750" x2="3.651" transform="translate(10.017 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_751" data-name="Line 751" x1="3.838" transform="translate(33.23 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_752" data-name="Line 752" x1="3.651" transform="translate(40.251 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_753" data-name="Line 753" x1="3.557" transform="translate(46.897 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_754" data-name="Line 754" x1="3.37" transform="translate(53.449 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_755" data-name="Line 755" x1="3.183" transform="translate(60.002 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_756" data-name="Line 756" x1="2.995" transform="translate(66.926 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_757" data-name="Line 757" x1="3.557" transform="translate(73.576 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_758" data-name="Line 758" x2="3.557" transform="translate(102.033 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_759" data-name="Line 759" x2="3.838" transform="translate(94.26 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_760" data-name="Line 760" x2="3.838" transform="translate(86.774 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_761" data-name="Line 761" x2="3.557" transform="translate(80.031 70.205)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <path id="Path_207261" data-name="Path 207261" d="M89.1,132.4a4.468,4.468,0,0,0-1.5,2.434" transform="translate(-85.917 -128.936)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_762" data-name="Line 762" y2="3.089" transform="translate(113.356 62.53)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_763" data-name="Line 763" y2="3.183" transform="translate(113.356 56.071)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_764" data-name="Line 764" y2="3.276" transform="translate(113.356 49.331)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_765" data-name="Line 765" y2="3.37" transform="translate(113.356 42.778)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_766" data-name="Line 766" y2="3.089" transform="translate(113.356 36.881)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_767" data-name="Line 767" y2="2.995" transform="translate(113.356 31.171)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_768" data-name="Line 768" y2="2.902" transform="translate(113.356 25.836)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_769" data-name="Line 769" y2="2.808" transform="translate(113.356 20.5)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_770" data-name="Line 770" y2="3.089" transform="translate(113.356 14.135)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_771" data-name="Line 771" y2="2.995" transform="translate(113.356 8.799)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <path id="Path_207262" data-name="Path 207262" d="M205.408,202.1a6,6,0,0,1-2.808,1.31" transform="translate(-93.266 -133.392)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_772" data-name="Line 772" y2="3.089" transform="translate(1.779 62.53)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_773" data-name="Line 773" y2="3.183" transform="translate(1.779 56.071)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_774" data-name="Line 774" y2="3.276" transform="translate(1.779 49.331)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_775" data-name="Line 775" y2="3.37" transform="translate(1.779 42.778)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_776" data-name="Line 776" y2="3.089" transform="translate(1.779 36.881)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_777" data-name="Line 777" y2="2.995" transform="translate(1.779 31.171)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_778" data-name="Line 778" y2="2.902" transform="translate(1.779 25.836)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_779" data-name="Line 779" y2="2.808" transform="translate(1.779 20.5)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_780" data-name="Line 780" y2="3.089" transform="translate(1.779 14.135)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <line id="Line_781" data-name="Line 781" y2="2.995" transform="translate(1.779 8.799)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <path id="Path_207263" data-name="Path 207263" d="M89,202.1a6,6,0,0,0,2.808,1.31" transform="translate(-86.005 -133.392)" fill="none" stroke="#fff" strokeWidth="0.289" />
      <path id="Path_207264" data-name="Path 207264" d="M205.7,176.108H155.438a3.8,3.8,0,0,1-3.838-3.838V156.638a3.8,3.8,0,0,1,3.838-3.838H205.7a3.8,3.8,0,0,1,3.838,3.838V172.27A3.8,3.8,0,0,1,205.7,176.108Z" transform="translate(-90.005 -130.241)" fill="#101010" />
      <circle id="Ellipse_2117" data-name="Ellipse 2117" cx="5.336" cy="5.336" r="5.336" transform="translate(66.369 28.831)" fill="#485d3e" />
     </g>
    </g>
    <path id="Path_206203" data-name="Path 206203" d="M343.345,580.7a.735.735,0,0,1,.731.665c.78,8.188,4.775,8.824,5.98,8.824a2.1,2.1,0,0,0,.219-.009.735.735,0,0,1,.249,1.447,7.522,7.522,0,0,0-5.779,4.923,10.819,10.819,0,0,0-.667,3.952.735.735,0,0,1-1.466.107c-.752-8.719-6.094-8.964-6.147-8.965a.735.735,0,0,1-.134-1.453,7.321,7.321,0,0,0,5.456-4.8,12.157,12.157,0,0,0,.823-3.959.735.735,0,0,1,.7-.733Zm4.3,10.4a6.436,6.436,0,0,1-1.582-1.044,9.815,9.815,0,0,1-2.719-4.632c-.053.153-.11.307-.171.462a9.058,9.058,0,0,1-4.31,4.959,7.45,7.45,0,0,1,1.191.741,9.641,9.641,0,0,1,3.2,4.756q.045-.13.093-.261A9,9,0,0,1,347.643,591.1Z" transform="translate(-334.808 -543.706)" />
    <path id="Path_206203-2" data-name="Path 206203" d="M342.37,580.7a.64.64,0,0,1,.637.579c.68,7.136,4.162,7.69,5.213,7.69a1.828,1.828,0,0,0,.191-.008.64.64,0,0,1,.217,1.261,6.555,6.555,0,0,0-5.037,4.29,9.424,9.424,0,0,0-.581,3.445.641.641,0,0,1-1.278.093c-.655-7.6-5.311-7.812-5.358-7.813a.641.641,0,0,1-.117-1.267,6.381,6.381,0,0,0,4.755-4.181,10.589,10.589,0,0,0,.717-3.45.641.641,0,0,1,.611-.639Zm3.746,9.066a5.6,5.6,0,0,1-1.379-.91,8.556,8.556,0,0,1-2.37-4.036c-.046.133-.1.268-.149.4a7.9,7.9,0,0,1-3.757,4.322,6.486,6.486,0,0,1,1.039.645,8.4,8.4,0,0,1,2.792,4.145c.026-.076.053-.151.081-.228A7.841,7.841,0,0,1,346.116,589.766Z" transform="translate(-224.633 -555.12)" />
    <path id="Path_234943" data-name="Path 234943" d="M402.152,1030.38s0,13.474-11.552,15.951c0,0,10.175.275,11.552,16.225,0,0-.824-13.474,11.825-16.225C413.979,1046.331,403.8,1047.705,402.152,1030.38Z" transform="translate(-297.935 -1030.38)" fill="#5f854c" />
   </g>
  </svg>
 );
}