import { Step } from "react-joyride";

export const TODAY_TOUR_STEPS: Step[] = [
    // if className is not specified we can use the default target='.my-element' else we can use id in place of className and use target='#my-element'
    {
        title: `Select portfolio`,
        content: `Click the dropdown to select the portfolio that you wish to view. You can also view analytics for the Family Group or any other portfolio groups that you have created.`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'auto',
        spotlightPadding: 0,
        target: ".apptour-today-portfolioselect",
        disableBeacon: true
    },
    {
        title: `Today view`,
        content: `Click here to get a current view of your portfolio. This is your default view to track how your portfolio is doing today.  Explore the tour to view the features in this view.`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'auto',
        spotlightPadding: 10,
        target: ".apptour-tabs-today",
        disableBeacon: true
    },
    {
        title: `Current Value`,
        content: `View the current value of a portfolio at any given time`,
        floaterProps: {
            disableAnimation: false,
        },
        disableScrolling: true,
        spotlightPadding: 10,
        target: ".apptour-today-currentvalue",
        disableBeacon: true
    },
    {
        title: `Current gain/loss`,
        content: `Check today's gain to see how much your portfolio has moved today. Unrealised gain gives you the notional P/L on your portfolio as per current market valuation.`,
        floaterProps: {
            disableAnimation: false,
        },
        disableScrolling: true,
        placement: 'left',
        spotlightPadding: 10,
        target: ".apptour-today-currentgain",
        disableBeacon: true
    },
    {
        title: `Asset Allocation`,
        content: `Understand current exposure of your portfolio to different categories: Equity, Debt, Gold, Real Estate etc. Click into any category in the chart to explore insights specific to the category.`,
        floaterProps: {
            disableAnimation: false,
        },
        disableScrolling: true,
        placement: 'left',
        spotlightPadding: 10,
        target: ".apptour-today-assetallocation",
        disableBeacon: true
    },
    {
        title: `Today's performance vs. indices`,
        content: `See how your Stocks and ETFs have moved today compared to popular indices. You can customise this by selecting any 5 indices that you wish to track.`,
        floaterProps: {
            disableAnimation: false,
        },
        disableScrolling: true,
        spotlightPadding: 10,
        target: ".apptour-today-todayperformance",
        disableBeacon: true
    },
    {
        title: `Today's top gainers & losers`,
        content: `See which stocks in your portfolio have gained and lost the most value today.`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'right',
        spotlightPadding: 10,
        target: ".apptour-today-leaderboard",
        disableBeacon: true
    },
    {
        title: `Portfolio watchlist`,
        content: `Add stocks to your watchlist that you wish to monitor for making investment decisions.`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'left',
        spotlightPadding: 10,
        target: ".apptour-today-watchlist",
        disableBeacon: true
    },
    {
        title: `Today's portfolio snapshot`,
        content: `View your current portfolio breakdown across stocks, mutual funds, bonds, FDs & all other asset classes.`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'auto',
        spotlightPadding: 10,
        target: ".apptour-today-table",
        disableBeacon: true
    },
    {
        title: `Corporate actions`,
        content: `View a list of recent & upcoming corporate actions for your stock portfolio: dividend, bonus, split, merger, demerger`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'auto',
        spotlightPadding: 10,
        target: ".apptour-today-corporateactions",
        disableBeacon: true
    },
    {
        title: ``,
        content: ``,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'right',
        spotlightPadding: 10,
        target: ".apptour-dummy",
        disableBeacon: true
    },
]

export const HOLDING_L1_TOUR_STEPS: Step[] = [
    // if className is not specified we can use the default target='.my-element' else we can use id in place of className and use target='#my-element'
    {
        title: `Holding view`,
        content: `Click here to review your detailed holdings and gain meaningful insights into your asset allocation. Explore the tour to view the features in this view.`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'auto',
        spotlightPadding: 10,
        target: ".apptour-tabs-holdings",
        disableBeacon: true
    },
    {
        title: `Asset allocation`,
        content: `Review the asset allocation of your portfolio. View exposure of your portfolio to different categories (Equity, Debt, Real Estate etc.) or asset classes (Stocks, Mutual Funds, Bonds, FDs etc.).`,
        floaterProps: {
            disableAnimation: false,
        },
        disableScrolling: true,
        placement: 'right',
        spotlightPadding: 10,
        target: ".apptour-holding-assetallocation",
        disableBeacon: true,
    },
    {
        title: `Key metrics`,
        content: `View the total amount invested, current value and unrealised gain / loss for your portfolio`,
        floaterProps: {
            disableAnimation: false,
        },
        disableScrolling: true,
        placement: 'left',
        spotlightPadding: 0,
        target: ".apptour-holding-keymetrics",
        disableBeacon: true
    },
    {
        title: `Holdings snapshot`,
        content: `View key metrics for different categories and asset classes. Drill down a level further into each category or asset class to review detailed holdings.`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'left',
        spotlightPadding: 10,
        target: ".apptour-holding-table",
        disableBeacon: true
    },
    {
        title: `Review each asset class`,
        content: `Click into any asset class in the left navigation menu to understand its holdings in detail. For example, click Stocks & ETFs to review holdings and analyze sector-wise breakdown.`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'right',
        spotlightPadding: 0,
        target: ".apptour-leftnav-assettypes",
        disableBeacon: true
    },
    {
        title: ``,
        content: ``,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'right',
        spotlightPadding: 10,
        target: ".apptour-dummy",
        disableBeacon: true
    },
]

export const HOLDING_L2_EQ_TOUR_STEPS: Step[] = [
    // if className is not specified we can use the default target='.my-element' else we can use id in place of className and use target='#my-element'
    {
        title: `Stock insights`,
        content: `Review the exposure of your stock portfolio to different sectors and market caps.`,
        floaterProps: {
            disableAnimation: false,
        },
        disableScrolling: true,
        spotlightPadding: 10,
        target: ".apptour-holding-stockinsights",
        disableBeacon: true
    },
    {
        title: ``,
        content: ``,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'right',
        spotlightPadding: 10,
        target: ".apptour-dummy",
        disableBeacon: true
    },
]

export const HOLDING_L2_MF_TOUR_STEPS: Step[] = [
    // if className is not specified we can use the default target='.my-element' else we can use id in place of className and use target='#my-element'
    {
        title: `Mutual Fund insights`,
        content: `Review mutual fund exposure to different categories (Equity, Debt, Gold, Solution Oriented etc.)`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'right',
        spotlightPadding: 10,
        target: ".apptour-holding-mfinsights",
        disableBeacon: true
    },
    {
        title: ``,
        content: ``,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'right',
        spotlightPadding: 10,
        target: ".apptour-dummy",
        disableBeacon: true
    },
]

export const PERFORMANCE_TOUR_STEPS: Step[] = [
    // if className is not specified we can use the default target='.my-element' else we can use id in place of className and use target='#my-element'
    {
        title: `Click on Performance`,
        content: `Click here to understand your portfolio performance including XIRR, P/L and comparative performance against market indices. Explore the tour to view the features in this view.`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'auto',
        spotlightPadding: 10,
        target: ".apptour-tabs-performance",
        disableBeacon: true
    },
    {
        title: `Performance snapshot`,
        content: `View key performance metrics for any selected time period: XIRR, total gain / loss, total investment & total withdrawal.`,
        floaterProps: {
            disableAnimation: false,
        },
        disableScrolling: true,
        placement: 'right',
        spotlightPadding: 10,
        target: ".apptour-performance-snapshot",
        disableBeacon: true,
    },
    {
        title: `Comparative performance`,
        content: `Compare how your portfolio has performed relative to popular market indices in any selected time period.`,
        floaterProps: {
            disableAnimation: false,
        },
        disableScrolling: true,
        placement: 'left',
        spotlightPadding: 10,
        target: ".apptour-performance-indiceschart",
        disableBeacon: true,
    },
    {
        title: `Performance breakdown`,
        content: `View performance metrics for all your asset classes in one view. Drill down a level further to review detailed performance for each asset class.`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'bottom',
        spotlightPadding: 10,
        target: ".apptour-performance-table",
        disableBeacon: true,
    },
    {
        title: `Advanced XIRR insights`,
        content: `View historical (1 year, 2 year, 5 year) XIRR and financial year-wise XIRR for your Stocks and Mutual Funds. Compare this performance against a benchmark index of your choice to track your relative performance.`,
        floaterProps: {
            disableAnimation: false,
        },
        placement: 'top',
        spotlightPadding: 10,
        target: ".apptour-performance-advancedchart",
        disableBeacon: true,
    },
]