
export function GroupIcon({stroke = '#ebecf2'}: {stroke?: string}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_150676" data-name="Group 150676" transform="translate(-4 -4)">
        <rect id="Rectangle_24190" data-name="Rectangle 24190" width="24" height="24" transform="translate(4 4)" fill="#fff" opacity="0" />
        <g id="Group_150690" data-name="Group 150690" transform="translate(4 4)">
          <g id="Group_150691" data-name="Group 150691">
            <circle id="Ellipse_1975" data-name="Ellipse 1975" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke={stroke} strokeLinecap="round" strokeWidth="1.5" />
            <path id="Path_198411" data-name="Path 198411" d="M3,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke={stroke} strokeLinecap="round" strokeWidth="1.5" />
          </g>
          <g id="Group_150692" data-name="Group 150692">
            <path id="Path_198412" data-name="Path 198412" d="M16,3.13a4,4,0,0,1,0,7.75" fill="none" stroke={stroke} strokeLinecap="round" strokeWidth="1.5" />
            <path id="Path_198413" data-name="Path 198413" d="M21,21V19a4,4,0,0,0-3-3.85" fill="none" stroke={stroke} strokeLinecap="round" strokeWidth="1.5" />
          </g>
        </g>
      </g>
    </svg>


  );
}
