import { Grid, Box, Stack } from '@mui/material'
import React, { useState } from 'react'
import { AdvancedIcon } from '../../../assets/icons/advancedIcon';
import { CapitalGainIcon } from '../../../assets/icons/capitalgainIcon';
import { IncomeOtherIcon } from '../../../assets/icons/incomeOtherIcon';
import { OpenArrow } from '../../../assets/icons/openArrow'
import { PerformanceIcon } from '../../../assets/icons/performanceIcon';
import { PerformanceSummary } from '../../../assets/icons/PerformanceSummary';
import { TaxSoftFormatIcon } from '../../../assets/icons/taxSoftFormatIcon';
import { TransactionIcon } from '../../../assets/icons/transactionIcon';
import CustomTypography from '../../shared/customTypography';
import { SharedConstants, ReportsData } from '@mprofit/shared';
import PopularReportCard from '../shared/popular-report-card';
import { PerformanceIconChartIcon } from '../../../assets/icons/PerformaceIconChartIcon';
import { CapitalGainLargeIcon } from '../../../assets/icons/capitalGainLargeIcon';
import ExploreReportCard from '../shared/explore-report-card';
import { WalletTransactionLargeIcon } from '../../../assets/icons/walletTransactionLargeIcon';
import { ClosingBalanceLargeIcon } from '../../../assets/icons/closingBalanaceLargeIcon';
import { TaxSoftwareLargeIcon } from '../../../assets/icons/taxSoftwareLargeIcon';
import { IncomeOtherLargeIcon } from '../../../assets/icons/incomeAndOtherLargeIcon';
import { t } from 'i18next';




let exploreData = [
    {
        title: 'Performances',
        icon: <PerformanceIcon />,
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.',
        bgColor: "",
    },
    {
        title: 'Capital Gains',
        icon: <CapitalGainIcon />,
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.',
        bgColor: ""
    },
    {
        title: 'Transactions',
        icon: <TransactionIcon />,
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.',
        bgColor: ""
    },
    {
        title: 'Advanced',
        icon: <AdvancedIcon />,
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.',
        bgColor: ""
    },
    {
        title: 'Tax Software Formats',
        icon: <TaxSoftFormatIcon />,
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.',
        bgColor: ""
    },
    {
        title: 'Income & Other',
        icon: <IncomeOtherIcon />,
        desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.',
        bgColor: ""
    }
]

let mostPopularCardData = [
    {
        title: 'Performance Summary',
        icon: <PerformanceSummary fill="#D2D4FF" />,
        desc: 'PERFORMANCE',
        bgColor: "#D2D4FF",
    },
    {
        title: 'Unrealized Capital Gains-Summary',
        icon: <CapitalGainLargeIcon fill="#E2DA90" />,
        desc: 'CAPITAL GAINS',
        bgColor: "#E2DA90"
    },
    {
        title: 'Mutual Fund Transactions',
        icon: <WalletTransactionLargeIcon fill="#ACC18A" />,
        desc: 'TRANSACTIONS',
        bgColor: "#ACC18A"
    },
    {
        title: 'Closing Balance',
        icon: <ClosingBalanceLargeIcon fill="#A9D6D1" />,
        desc: 'ADVANCED',
        bgColor: "#A9D6D1"
    },
    {
        title: 'CompuTax - Capital Gains ITR Format',
        icon: <TaxSoftwareLargeIcon />,
        desc: 'TAX SOFTWARE FORMATS',
        bgColor: "#DAE5A1"
    },
    {
        title: 'Income Report - Summary',
        icon: <IncomeOtherLargeIcon />,
        desc: 'INCOME & OTHER',
        bgColor: "#F5CFA0"
    }
]

let popularCardData = [
    {
        title: 'PERFORMANCE',
        icon: <PerformanceSummary fill="#DAE5A1" />,
        desc: 'Performance Summary',
        bgColor: "#DAE5A1"
    },
    {
        title: 'PERFORMANCE',
        icon: <PerformanceSummary fill="#D6CDA4" />,
        desc: 'Annualised Return (XIRR)',
        bgColor: "#D6CDA4"
    },
    {
        title: 'PERFORMANCE',
        icon: <PerformanceSummary fill="#B1DBFF" />,
        desc: 'Historic Valuation',
        bgColor: "#B1DBFF"
    }
]


const Reports = () => {
    const [openModal, setOpenModal] = useState<boolean>(false);

    console.log(ReportsData.ReportCategoryLists.INV)
    return (
        <Stack>
            <Grid container spacing={3} padding="0px 40px 64px 40px">
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <CustomTypography variant='Medium24'>{t('reports.explore.title')}</CustomTypography>
                    <Box sx={{ display: 'flex' }}>
                        <CustomTypography variant='Medium16' sx={styles.disclaimerText}>{t('reports.explore.disclaimer')}</CustomTypography>
                        <OpenArrow />
                    </Box>
                </Grid>
                {
                    ReportsData.ReportCategoryLists.INV.map((item, index) => (
                        <Grid key={index} item xs={6} lg={4} >
                            <ExploreReportCard
                                CategoryName={item.CategoryName}
                                Description={item.Description}
                                CategoryId={item.CategoryId}
                            />
                        </Grid>
                    ))
                }
            </Grid>

            <Grid container sx={{ backgroundColor: '#EBECF2', padding: '0px 40px' }}>
                <Grid item xs={12} margin="32px 0px">
                    <CustomTypography variant='Medium24'>{t('reports.explore.popularHeader')} ✨</CustomTypography>
                </Grid>
                <Grid item xs={12} sx={styles.popularContainer}>
                    {
                        mostPopularCardData.map((item, index) => (
                            <PopularReportCard
                                key={index}
                                title={item.title}
                                desc={item.desc}
                                icon={item.icon}
                                bgColor={item.bgColor}
                            />
                        ))
                    }
                </Grid>

                <Grid item xs={12} padding="32px 0px">
                    <CustomTypography variant='Medium24'>{t('reports.explore.reportsHeader')}</CustomTypography>
                </Grid>
                <Grid item xs={12} sx={styles.popularContainer}>
                    {
                        popularCardData.map((item, index) => (
                            <PopularReportCard
                                key={index}
                                title={item.title}
                                desc={item.desc}
                                icon={item.icon}
                                bgColor={item.bgColor}
                            />
                        ))
                    }
                </Grid>
            </Grid>
        </Stack>
    )
}

const styles = {
    popularContainer: {
        display: 'flex',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
    },
    disclaimerText: {
        color: { xs: '#5F6F57' },
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        padding: '24px',
        borderRadius: '8px',
        borderColor: '#DFDFDF'
    },
    cardReport: {
        width: '398px',
        borderRadius: '8px',
        borderColor: '#DFDFDF'
    },

    cardArrow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: "#cfe3c9",
            borderRadius: '4px'
        },
    },
    cardArrowWithoutBg: {
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            borderRadius: '4px',
        },
    }
}

export default Reports;
