import SvgIcon from '@mui/material/SvgIcon';

export function PopOutIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_149757" data-name="Group 149757" transform="translate(-32 -11)">
          <g id="Rectangle_23766" data-name="Rectangle 23766" transform="translate(32 11)" strokeWidth="1" opacity="0">
            <rect width="24" height="24" />
            <rect x="0.5" y="0.5" width="23" height="23" />
          </g>
          <g id="bx-link-external" transform="translate(31.5 10.5)">
            <path id="Path_198147" data-name="Path 198147" d="M17.235,4.5l2.927,2.927L13.94,13.65,15.2,14.907l6.222-6.222,2.927,2.927V4.5Z" transform="translate(-3.846)" />
            <path id="Path_198148" data-name="Path 198148" d="M18.722,18.722H6.278V6.278H12.5L10.722,4.5H6.278A1.779,1.779,0,0,0,4.5,6.278V18.722A1.779,1.779,0,0,0,6.278,20.5H18.722A1.779,1.779,0,0,0,20.5,18.722V14.278L18.722,12.5Z" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}