import { CustomTypography, GraphLoader } from "../../../shared";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities } from "@mprofit/shared";
import { Box, Grid } from "@mui/material";
import { DashboardItemContainer } from "../../shared";
import L4HoldingPieChart from "../shared/L4_Holding_Pie_Chart";
import L4HoldingCurrentValuecard from "../shared/L4_Holding_Currrent_Value_Card";
import L4HoldingPriceCard from "../shared/L4_Holding_Current_Price_Card";
import L4HoldingQuantityCard from "../shared/L4_Holding_Quantity_Card";
import L4HoldingAmountInvestedCard from "../shared/L4_Holding_Amount_Invested_Card";
import L4HoldingTransactionCard from "../shared/L4_Holding_Transaction_card";
import { useLocation, useNavigate } from "react-router-dom";
import SharedVirtualizedTable, { RowDataProps } from "../../../shared/virtual-table-component";
import { getColumns } from "../level_2";
import { NavigateToAsset } from "packages/web/src/routes/navigationMethods";
import { useState } from "react";
import { TableSearchBar } from "../../../shared/table-search-bar";
import useAutoScrollTop from "../../../hooks/useAutoScrollTop";

export default function L4_Holding() {
    const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);
    const activeScreen = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreen);
    const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType) || -1;
    useAutoScrollTop();

    return (
        <>
            {
                isLoading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', background: '#FFFFFF', height: '100%' }} >
                        <GraphLoader />
                    </Box >
                    :
                    <>
                    <Grid spacing={2} container padding='0px 40px'>
                        <Grid item xs={12}>
                            <DashboardItemContainer>
                                <HoldingPieCharts/>
                            </DashboardItemContainer>
                        </Grid>
                        <L4HoldingValuesRow/>
                    </Grid>
                    <Box padding="0px 40px" marginTop="70px">
                    {
                        (assetTypeID !== undefined && assetTypeID > SharedConstants.AssetTypeEnum.EQ) && activeScreen === SharedConstants.ScreenEnum.L4 ?
                        (
                            <L4HoldingsTable />
                        ) :
                        (
                            <></>
                            // <Grid item xs={12} marginTop={"32px"} marginBottom={"32px"}>
                            //     <L4HoldingTransactionCard />
                            // </Grid>
                        )
                    }
                    </Box>
                    </>
                    
            }
        </>
    )
}

const HoldingPieCharts = () => {
    const [totalField, setTotalField] = useState<SharedConstants.HoldingAssetAllocationDropdownValue>('CurrValue');

    const activeL4PortfolioSummaryTotal = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4PortfolioSummaryTotal);
    const stocksSectorInfo = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4AssetStocksSector);

    return <L4HoldingPieChart
        CurrValue={activeL4PortfolioSummaryTotal?.CurrValue}
        InvAmt={activeL4PortfolioSummaryTotal?.InvAmt}
        TotalField={totalField}
        AssetTypeID={activeL4PortfolioSummaryTotal?.AssetTypeID}
        StocksSectorInfo={stocksSectorInfo}
        DropdownList={dropdownList}
        OnDropdownChange={(selected) => {setTotalField(selected.id as SharedConstants.HoldingAssetAllocationDropdownValue)}}
    />;
}

const dropdownList = [{id: 'CurrValue', name: 'Current Value'}, {id: 'InvAmt', name: 'Amount Invested'}]; 

const L4HoldingValuesRow = () => {
    const activeL4PortfolioSummaryTotal = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4PortfolioSummaryTotal);
    const showQuant = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsAssetTypeWithQuant);

    return (
        <>
        <Grid item xs={showQuant ? 8 : 12} xl={showQuant ? 4 : 12}>
            <DashboardItemContainer>
                <L4HoldingCurrentValuecard
                    CurrValue={activeL4PortfolioSummaryTotal?.CurrValue}
                    OGain={activeL4PortfolioSummaryTotal?.OGain}
                    OGainPct={activeL4PortfolioSummaryTotal?.OGainPct}
                    OUp={activeL4PortfolioSummaryTotal?.OUp}
                />
            </DashboardItemContainer>
        </Grid>
        {
            showQuant &&
            <>
            <Grid item xs={4} xl={2}>
                <DashboardItemContainer>
                    <L4HoldingPriceCard PxCurr={activeL4PortfolioSummaryTotal?.PxCurr}/>
                </DashboardItemContainer>
            </Grid>
            <Grid item xs={8} xl={4}>
                <DashboardItemContainer>
                    <L4HoldingQuantityCard PxPur={activeL4PortfolioSummaryTotal?.PxPur} Quant={activeL4PortfolioSummaryTotal?.Quant}/>
                </DashboardItemContainer>
            </Grid>
            <Grid item xs={4} xl={2}>
                <DashboardItemContainer>
                    <L4HoldingAmountInvestedCard InvAmt={activeL4PortfolioSummaryTotal?.InvAmt}/>
                </DashboardItemContainer>
            </Grid>
            </>
            }
        </>
    )
}

const L4HoldingsTable = () => {
    const tableData = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL4HoldingsTable_BySID);

    const navigate = useNavigate();
    const location = useLocation();
    const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  
    const [searchTerm, setSearchTerm] = useState('');

    const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);

    if (tableData.length == 0 || !assetTypeID) {
        return <></>;
    }
  
    const onRowClick = (row: RowDataProps) => {
      if (row && row.SID > 0) {
        NavigateToAsset({ navigate, activeScreenIDs, assetTypeID: row.AssetTypeID, AMID: row.AMID, SID: row.SID, pathname: location.pathname });
      }
    }
  
    console.log(tableData, 'tableData');

    var fieldNamesToSearch = ['Refno'];
    
    return <Grid container spacing={{ xs: 2, lg: 3 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
            <CustomTypography variant="Medium28">{'Folio-wise'}</CustomTypography>
            <Box width="408px">
                {/* <SearchBar searchOptions={[]} /> */}
                <TableSearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
            </Box>
        </Grid>
        <Grid item xs={12} marginBottom={"40px"}>
            <DashboardItemContainer>
                <SharedVirtualizedTable columns={getColumns(SharedConstants.HoldingsMode.Assets, assetTypeID, SharedUtilities.getRefNumField(assetTypeID), 'Refno')} rows={tableData} headerColor="#EAF4DF" sorting={true} onRowClick={onRowClick} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch}  defaultSortConfig={{key: 'HoldingPct', direction: SharedConstants.SortDirection.Down}}/>
            </DashboardItemContainer>
        </Grid>
    </Grid>
  
}

