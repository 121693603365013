import { Box } from "@mui/material"
import { RcaDividendIcon, RcaBonusIcon, RcaDemergerIcon, RcaMergerIcon, RcaSplitIcon, RcaCorporateDefaultIcon } from "../../../assets/icons/RCA";
import CustomTypography from "../customTypography";
import { t } from "i18next";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { SharedRedux } from '@mprofit/shared';
import { useAppSelector } from "packages/web/src/redux/hooks";
import { BulkCorporateActionType } from "../classes/corporateActions";


export const RCAIcons = {
    [BulkCorporateActionType.DIVIDEND]: <RcaDividendIcon />,
    [BulkCorporateActionType.BONUS]: <RcaBonusIcon />,
    [BulkCorporateActionType.SPLIT]: <RcaSplitIcon />,
    [BulkCorporateActionType.MERGER]: <RcaMergerIcon />,
    [BulkCorporateActionType.DEMERGER]: <RcaDemergerIcon />,
}

const RecentCorporateActions = () => {
    const AMIDSID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAMIDSID);
    const AMID = AMIDSID?.AMID || -1;
    const corporateActionsData = useAppSelector((state) => SharedRedux.Dashboard.Selectors.selectCorporateActionsList(state, AMID));
    const isLoading = useAppSelector(SharedRedux.Dashboard.Selectors.selectCorporateActionsIsLoading);

    if (isLoading) return <></>;

    return (
        <>
            <Box sx={{ paddingTop: '32px', paddingLeft: '24px', paddingBottom: '30px' }}>
                <CustomTypography variant='Medium24'>
                    {t('portfolio.today.sharedComponents.recentCorporateActions.title')}
                </CustomTypography>
            </Box>

            {
                corporateActionsData.length == 0 ?
                    <Box sx={{ display: "flex", justifyContent: "center", textAlign: "center", paddingTop: "66px" }}>
                        <Box>
                            <RcaCorporateDefaultIcon />
                            <CustomTypography
                                variant="Medium24"
                                sx={{
                                    paddingTop: "56.5px"
                                }}
                            >{t('portfolio.today.sharedComponents.recentCorporateActions.loader.title')}</CustomTypography>
                            <CustomTypography
                                variant="Regular18"
                                sx={{
                                    color: { xs: "#64677A" },
                                    paddingTop: "16px",
                                    paddingBottom: "85px"
                                }}
                            >{t(`portfolio.today.sharedComponents.recentCorporateActions.loader.${AMID > 0 ? 'descriptionSingleStock' : 'description'}`)}</CustomTypography>
                        </Box>
                    </Box>
                    :
                    <TableContainer sx={{minHeight: '360px'}}>
                        <Table>
                            <TableBody>
                                {corporateActionsData.map((row, index) => {
                                    const borderBottom = index === corporateActionsData.length - 1 ? 'none' : '1px solid #EBECF2';
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="left" sx={{ width: '24px', padding: 0, borderBottom: 'none' }}>
                                            </TableCell>
                                            <TableCell align="left" sx={{ width: '50px', paddingLeft: 0, paddingRight: 0, borderBottom }}>
                                                {RCAIcons[row.CorpActionType]}
                                            </TableCell>
                                            <TableCell align="left" sx={{borderBottom}}>
                                                <CustomTypography variant="Regular18">{row.CorpActionTypeStr}</CustomTypography>
                                                <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }}></CustomTypography>
                                            </TableCell>
                                            <TableCell align="left" sx={{borderBottom}}>
                                                <CustomTypography variant="Regular18">{row.MProfit_Company_Name}</CustomTypography>
                                                <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }}>{row.NSE_Symbol || '-'}</CustomTypography>
                                            </TableCell>
                                            <TableCell align="left" sx={{ minWidth: "90px", borderBottom }}>
                                                <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }}>Ex date</CustomTypography>
                                                <CustomTypography variant="Regular18">{row.CorpActionDateDisplay}</CustomTypography>
                                            </TableCell >
                                            <TableCell align="left" sx={{borderBottom}}>
                                                <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }}>{row.Description}</CustomTypography>
                                                <CustomTypography variant="Regular18">{row.RatioText || '-'}</CustomTypography>
                                            </TableCell>
                                            <TableCell align="left" sx={{ width: '24px', padding: 0, borderBottom: 'none' }}>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </>
    )
}
export default RecentCorporateActions