
export function AdvancedIcon(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
            <g id="Group_154360" data-name="Group 154360" transform="translate(-920 -138)" >
                <rect id="Rectangle_24810" data-name="Rectangle 24810" width="64" height="64" transform="translate(920 138)" fill="#fff" opacity="0" />
                <g id="Group_154226" data-name="Group 154226" transform="translate(923.285 142.977)">
                    <g id="trading-website">
                        <path id="Path_207186" data-name="Path 207186" d="M389.646,178.116a2.333,2.333,0,0,1-2.016-2.549V126.619a2.333,2.333,0,0,1,2.016-2.55h53.4a2.333,2.333,0,0,1,2.016,2.55v48.948a2.333,2.333,0,0,1-2.016,2.549h-53.4Z" transform="translate(-387.63 -124.069)" fill="#fff" stroke="#253326" strokeWidth="0.5" />
                        <g id="Group_154224" data-name="Group 154224" transform="translate(2.67 18.473)">
                            <path id="Path_235009" data-name="Path 235009" d="M232.426,253.514H182.083c-.054,0-.1-.086-.1-.192s.044-.192.1-.192h50.343c.054,0,.1.086.1.192S232.48,253.514,232.426,253.514Z" transform="translate(-181.985 -253.044)" fill="#cfe3c9" />
                            <path id="Path_235010" data-name="Path 235010" d="M232.426,293.514H182.083c-.054,0-.1-.086-.1-.192s.044-.192.1-.192h50.343c.054,0,.1.086.1.192S232.48,293.514,232.426,293.514Z" transform="translate(-181.985 -285.347)" fill="#cfe3c9" />
                            <path id="Path_235011" data-name="Path 235011" d="M232.426,333.514H182.083c-.054,0-.1-.086-.1-.193s.044-.192.1-.192h50.343c.054,0,.1.086.1.192S232.48,333.514,232.426,333.514Z" transform="translate(-181.985 -317.65)" fill="#cfe3c9" />
                            <path id="Path_235012" data-name="Path 235012" d="M232.426,373.514H182.083c-.054,0-.1-.086-.1-.192s.044-.192.1-.192h50.343c.054,0,.1.086.1.192S232.48,373.514,232.426,373.514Z" transform="translate(-181.985 -349.953)" fill="#cfe3c9" />
                            <path id="Path_235014" data-name="Path 235014" d="M303,284.242a.192.192,0,0,1-.192-.191l-.192-31.172a.192.192,0,0,1,.191-.194h0a.193.193,0,0,1,.192.191l.192,31.172a.193.193,0,0,1-.191.194Z" transform="translate(-279.4 -252.685)" fill="#cfe3c9" />
                            <path id="Path_235018" data-name="Path 235018" d="M220.054,268.006h-7.333a2.106,2.106,0,1,1,0-4.212h7.333a2.106,2.106,0,1,1,0,4.212Z" transform="translate(-205.103 -261.656)" fill="#5f854c" />
                            <path id="Path_235019" data-name="Path 235019" d="M353.054,307.006h-7.333a2.106,2.106,0,1,1,0-4.212h7.333a2.106,2.106,0,1,1,0,4.212Z" transform="translate(-312.511 -293.151)" fill="#5f854c" />
                            <path id="Path_235020" data-name="Path 235020" d="M353.054,350.006h-7.333a2.106,2.106,0,0,1,0-4.212h7.333a2.106,2.106,0,0,1,0,4.212Z" transform="translate(-312.511 -327.877)" fill="#5f854c" />
                        </g>
                        <path id="Path_207251" data-name="Path 207251" d="M295.682,125.766v4.189a1.9,1.9,0,0,1-2.06,1.679H245.762a1.9,1.9,0,0,1-2.06-1.679v-4.189a1.9,1.9,0,0,1,2.06-1.679h47.861A1.9,1.9,0,0,1,295.682,125.766Z" transform="translate(-241.03 -119.012)" fill="#253326" />
                        <g id="Group_153079" data-name="Group 153079" transform="translate(48.988 39.775)">
                            <path id="Path_207249" data-name="Path 207249" d="M465.918,159.7l-.987.988,2.435,2.434-1.288,1.288-2.435-2.434-.988.987-1.217-4.48Z" transform="translate(-461.338 -158.388)" fill="#485d3e" />
                            <path id="Path_207250" data-name="Path 207250" d="M465.755,164.235l-2.364-2.364-.917.917a.1.1,0,0,1-.167-.044l-1.217-4.48a.1.1,0,0,1,.122-.122l4.48,1.217a.1.1,0,0,1,.044.167l-.917.917,2.364,2.364a.1.1,0,0,1,0,.141l-1.288,1.288A.1.1,0,0,1,465.755,164.235Zm-2.293-2.575,2.364,2.364,1.147-1.147-2.364-2.364a.1.1,0,0,1,0-.141l.866-.866-4.146-1.126,1.126,4.145.866-.866A.1.1,0,0,1,463.462,161.66Z" transform="translate(-461.087 -158.138)" fill="#485d3e" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}