import { Box, CircularProgress } from '@mui/material'
import CustomTypography from '../../../shared/customTypography'
import CalanderTodayIcon from 'packages/web/src/assets/icons/calanderTodayIcon';

export default function PendingReportCard() {
    return (
        <Box sx={style.container}>
            <Box display="flex" sx={{ height: '100%' }}>
                <Box paddingRight="24px">
                    <CircularProgress />
                </Box>
                <Box sx={style.content}>
                    <Box>
                        <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A' } }} > Generating report</CustomTypography>
                        <CustomTypography padding="16px 0px 12px 0px" variant='Regular24'>Spectrum - Capital Gains ITR Format</CustomTypography>
                        <Box sx={style.dateContainer}>
                            <CalanderTodayIcon color="#64677a" />
                            <CustomTypography paddingLeft="8px" variant='Regular16' sx={{ color: { xs: '#64677A' } }}>14 Sep 2022</CustomTypography>
                        </Box>
                    </Box>
                    <Box>
                        <CustomTypography variant='Regular18'>Rahuhl KC</CustomTypography>
                        <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A' } }}>Kumar Family</CustomTypography>
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

const style = {
    container: {
        boxSizing: 'border-box',
        minWidth: '568px',
        height: '236px',
        padding: "24px",
        borderRadius: '8px',
        background: '#FFFFFF',
        border: '1px solid #EBECF2',
        // margin: '0px 24px 24px 0px',
        marginRight: '24px',
        marginBottom: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    dateContainer: {
        display: 'flex',
        alignItems: 'center',
        background: '#EAF4DF',
        padding: '4px 16px 4px 12px',
        borderRadius: '16px',
        width: 'fit-content'
    }
}