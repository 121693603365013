import { Grid, Stack, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import CustomTypography from '../../shared/customTypography'
import SearchBar from '../../shared/search-bar'
import ReadyToDownloadCard from '../shared/ready-to-download-card'
import PendingReportCard from '../shared/pending-reports-card'
import InfoIcon from '@mui/icons-material/Info';
import FilterDropdown from '../../shared/filter-dropdown';
import { useState } from 'react';
import DropDown from '../../shared/drop-down';
import ReportLogTable from '../shared/report-log-table';
import { useNavigate } from 'react-router-dom';
import { BackArrowIcon } from 'packages/web/src/assets/icons/backArrowIcon';
import CalanderTodayIcon from 'packages/web/src/assets/icons/calanderTodayIcon';
import { t } from 'i18next';

export default function ReportLog() {
  const [selectedOption, setSelectedOption] = useState<string[]>([]);
  const navigate = useNavigate();

  return (
    <Stack>
      <Grid spacing={2} container padding="0px 40px" sx={{ background: "#F6F6F6" }}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate('/portfolio/reports/explore')}>
            <BackArrowIcon color="#5F6F57" />
            <CustomTypography variant="Medium16" sx={{ color: { xs: '#5F6F57' } }}>{t('reports.reportsLog.backButton')}</CustomTypography>
          </Box>
        </Grid>
        <Grid item xs={9} padding="32px 0px">
          <CustomTypography variant='Medium28'>{t('reports.reportsLog.title')}</CustomTypography>
        </Grid>
        <Grid item xs={3} textAlign="right" padding="32px 0px">
          {/* <SearchBar searchOptions={[]} /> */}
        </Grid>
        <Grid item xs={12}>
          <CustomTypography variant='Regular18' sx={{ color: { xs: "#64677A", paddingBottom: '24px' } }}>{t('reports.reportsLog.subTitle')}</CustomTypography>
        </Grid>
      </Grid>

      <Grid display="flex" padding="0px 40px">
        <ReadyToDownloadCard />
        <ReadyToDownloadCard />
        <ReadyToDownloadCard />
      </Grid>

      <Grid padding="0px 40px" sx={{ background: "#EBECF2" }}>
        <CustomTypography variant='Regular18' padding="24px 0px">{t('reports.reportsLog.pendingReports.title')}</CustomTypography>
        <Box display="flex">
          <PendingReportCard />
          <PendingReportCard />
        </Box>
      </Grid>

      <Grid padding="72px 40px" sx={{ background: "#F6F6F6" }}>
        <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '29px' }}>
          <CustomTypography variant='Medium24' paddingRight="13px">{t('reports.reportsLog.allReports.title')}</CustomTypography>
          <Tooltip title="Complete reports are available for 15 days" arrow placement="right">
            <InfoIcon sx={{ color: '#BABDCC', cursor: 'pointer' }} />
          </Tooltip>
        </Box>

        <Grid container alignItems="center">
          <Grid item xs={8}>
            <Box sx={{ display: 'flex' }}>
              <FilterDropdown
                data={["All reports", "performance", "Capital gains", "Transactions", "Advanced", "Tax software formats", "Income & others"]}
                setSelectedOption={() => setSelectedOption}
                selectedOption={selectedOption}
              />
              <Box sx={{ paddingLeft: '12px' }}>
                <DropDown startIcon={<CalanderTodayIcon color="#64677a" />} inputValue="Created on" inputList={[]} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} textAlign="end">
            <DropDown startIcon={<CalanderTodayIcon color="#64677a" />} inputValue="Date created" inputList={[]} />
          </Grid>

          <Grid item xs={12}>
            <ReportLogTable />
          </Grid>
        </Grid>
      </Grid>

    </Stack>
  )
}
