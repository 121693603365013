import { SharedConstants, SharedRedux, SharedUtilities } from '@mprofit/shared';
import { createSelector } from '@reduxjs/toolkit';
import { AxisDomain } from 'recharts/types/util/types';
import { getColumnData } from '../../components/shared/asset-wise-performanc-table';
import { getPMSXIRRDisplay } from '../../components/shared/performance-xirr-chart-card';
import { getAdvancedPerfChartDomain } from '../../components/shared/performance-two-column-chart';

const selectXIRRTotalChartData = (specificTitleText: string) => createSelector(
    [
        SharedRedux.Performance.Selectors.selectXIRRTotalData,
        SharedRedux.Performance.Selectors.selectXIRRTotalPerformanceIndicesChartValues,
        SharedRedux.Performance.Selectors.selectXIRRTotalIsLoading
    ],
    (
        xirrTotalData,
        xirrTotalIndexData,
        xirrTotalIsLoading
    ) => {
        var chartData: SharedConstants.BarChartData[] | undefined = undefined;
        var domain: AxisDomain = [-1, 1];
        const isLast10YearIndexReturns = false;//useAppSelector(SharedRedux.Performance.Selectors.selectXIRRTotalIsOlderThan2012Return); Not needed now

        if (!xirrTotalIsLoading && xirrTotalData && xirrTotalData.XIRR != null && xirrTotalIndexData) {
            var min = -0.1;
            var max = 0.1;
            chartData = [
                ...(
                    isLast10YearIndexReturns ? [] :
                    [
                        {
                            Name: specificTitleText,
                            Value: xirrTotalData.XIRR,
                        }
                    ]
                ),
                ...xirrTotalIndexData
                // .map(x => ({...x, Value: 0.2}))
            ]
    
            chartData.forEach((item) => {
                min = Math.min(min, item.Value);
                max = Math.max(max, item.Value);
            });
    
            min = SharedUtilities.getBarChartDomainValue(min, true);
            max = SharedUtilities.getBarChartDomainValue(max, false);
    
            if (max > 0 && min < 0) {
                if (Math.abs(min) > Math.abs(max)) {
                    max = min * -1;
                } else if (Math.abs(min) < Math.abs(max)) {
                    min = max * -1
                }
            }
            domain = [min, max];
        }
        return {
            xirrTotalData,
            chartData,
            domain,
            isLoading: xirrTotalIsLoading
        }
    }
)

const selectXIRRTableData = (isGroupByPortfolio: boolean = false, rowFieldHeading: string | undefined, rowFieldName: string, tableMode: SharedConstants.AdvancedPerformanceTableMode) => createSelector(
    [
        SharedRedux.Performance.Selectors.selectPerformanceXIRRTableData(isGroupByPortfolio === true),
        SharedRedux.Performance.Selectors.selectIsPeriodAllToDate
    ],
    (
        xirrTableData,
        isPerformancePeriodAllToDate
    ) => {
        const showPMSMessage = isGroupByPortfolio && xirrTableData && xirrTableData.Item && xirrTableData.Item.GroupXIRRData && xirrTableData.Item.GroupXIRRData.findIndex(x => x.IsCashMgmtPortfolio) >= 0;
        const heading = `Performance by ${rowFieldHeading || rowFieldName}`;
        
        return {
            heading,
            tableData: xirrTableData?.Item,
            tableRowData: xirrTableData?.Item?.GroupXIRRData || [],
            columnData: getColumnData(rowFieldName, tableMode, isPerformancePeriodAllToDate, isGroupByPortfolio),
            isLoading: xirrTableData?.IsLoading,
            chartDisclaimer: showPMSMessage ? 'Note: For PMS portfolios, Opening and Closing Valuations include Cash on Hand' : undefined
        }
    }
)

const selectXIRRCardData = createSelector(
    [
        SharedRedux.Licensing.Selectors.selectIsPerformanceViewEnabled,
        SharedRedux.Portfolios.Selectors.selectActivePortfolio,
        SharedRedux.Performance.Selectors.selectXIRRTotalData,
        SharedRedux.Performance.Selectors.selectXIRRTotalIsLoading,
        SharedRedux.Performance.Selectors.selectIsPeriodAllToDate,
        SharedRedux.Performance.Selectors.selectActivePeriodText,
    ],
    (
        _IsPerformanceViewAllowed,
        activePortfolio,
        performanceXIRRData,
        isLoading,
        isPerformancePeriodAllToDate,
        periodText
    ) => {
        const IsPerformanceViewAllowed = _IsPerformanceViewAllowed !== false;

        const IsPerformanceLoading = IsPerformanceViewAllowed === undefined || !activePortfolio ||
            ((performanceXIRRData?.XIRR === null ||
            performanceXIRRData?.XIRR === undefined) &&
            isLoading);

        var XIRRDisplay: string | undefined = undefined;
        if (!IsPerformanceLoading) {
            XIRRDisplay = getPMSXIRRDisplay(performanceXIRRData, IsPerformanceViewAllowed);
        }

        return {
            performanceXIRRData,
            isPerformancePeriodAllToDate,
            XIRRDisplay,
            periodText
        }
    }
)

const selectXIRRBenchmarkChartData = createSelector(
    [
        SharedRedux.Performance.Selectors.selectXIRRBenchmarkData,
        SharedRedux.Performance.Selectors.selectXIRRBenchmarkIsLoading,
        SharedRedux.Dashboard.Selectors.selectXIRRBenchmarkSingleIndex
    ],
    (
        xirrBenchmarkData,
        xirrBenchmarkIsLoading,
        xirrBenchmarkSingleIndex
    ) => {
        var domain: AxisDomain = [-1, 1];
        var chartData: SharedConstants.BarChartData[] | undefined = undefined;

        if (!xirrBenchmarkIsLoading && xirrBenchmarkData && xirrBenchmarkData.XIRR != null && xirrBenchmarkData.BenchmarkXIRRValue != null) {
            var min = -0.1;
            var max = 0.1;
            chartData = [
                {
                    Name: 'My XIRR',
                    Value: xirrBenchmarkData.XIRR,
                },
                {
                    Name: `${((xirrBenchmarkSingleIndex?.AssetName as string) || 'Benchmark')} XIRR`,
                    Value: xirrBenchmarkData.BenchmarkXIRRValue,
                },
            ]
    
            chartData.forEach((item) => {
                min = Math.min(min, item.Value);
                max = Math.max(max, item.Value);
            });
    
            min = SharedUtilities.getBarChartDomainValue(min, true);
            max = SharedUtilities.getBarChartDomainValue(max, false);
    
            if (max > 0 && min < 0) {
                if (Math.abs(min) > Math.abs(max)) {
                    max = min * -1;
                } else if (Math.abs(min) < Math.abs(max)) {
                    min = max * -1
                }
            }
            domain = [min, max];
        }

        return {
            chartData,
            domain,
        }
    }
)

const selectXIRRAdvancedChartData = (chartMode: SharedConstants.AdvancedPerformanceChartMode) => createSelector(
    [
        chartMode === SharedConstants.AdvancedPerformanceChartMode.YearWise ? SharedRedux.Performance.Selectors.selectXIRRYearWiseWithIndices : SharedRedux.Performance.Selectors.selectXIRRFYWiseWithIndices,
        chartMode === SharedConstants.AdvancedPerformanceChartMode.YearWise ? SharedRedux.Performance.Selectors.selectXIRRYearWiseIsLoading : SharedRedux.Performance.Selectors.selectXIRRFYWiseIsLoading,
        SharedRedux.Dashboard.Selectors.selectPerformanceSingleIndex
    ],
    (
        advancedPerformanceChartData,
        isLoading,
        performanceSingleIndex
    ) => {
        let chartData, domain;
        
        if (advancedPerformanceChartData && !isLoading) {
            chartData = advancedPerformanceChartData ? advancedPerformanceChartData.map(data => ({
                Name: data.Name,
                Column1Value: data.Column1Value?? 0,
                Column2Value: data.Column2Value?? 0,
            })) : undefined;

            domain = advancedPerformanceChartData ? getAdvancedPerfChartDomain(advancedPerformanceChartData) : undefined;
        }

        return {
            chartData,
            domain,
            isLoading,
            performanceSingleIndex
        }
    }
)

const selectCashflowChartData = createSelector(
    [
        SharedRedux.Performance.Selectors.selectCashflowChartData,
        SharedRedux.Performance.Selectors.selectIsPeriodAllToDate
    ],
    (
        cashflowChartData,
        isPerformancePeriodAllToDate
    ) => {

        return {
            cashflowChartData,
            isPerformancePeriodAllToDate
        }
    }
)

export const ChartsSelectors = {
    selectXIRRTotalChartData,
    selectXIRRTableData,
    selectXIRRCardData,
    selectXIRRBenchmarkChartData,
    selectXIRRAdvancedChartData,
    selectCashflowChartData
}