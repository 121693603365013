import { Box, Divider, Grid } from "@mui/material";
import CustomTypography from "../../customTypography";
import { Dispatch, SetStateAction } from "react";
import { SharedConstants } from "@mprofit/shared";

interface IBulletProps {
  data: SharedConstants.PieChartData[],
  chartRef: any,
  setActiveIndex: Dispatch<SetStateAction<number | null>>,
  onItemClick?: (item: SharedConstants.PieChartData) => void
}
const BottomLegends = ({ data, chartRef, setActiveIndex, onItemClick }: IBulletProps) => {
  const handleLegendMouseEnter = (event: any, itemIndex: number) => {
    setActiveIndex(itemIndex);
    if (!chartRef.current) {
      return;
    }
    const activeItem = chartRef.current.state.formattedGraphicalItems?.[0].props.sectors[itemIndex];
    if (!activeItem) {
      return;
    }

    chartRef.current.setState({ activeTooltipIndex: itemIndex },
      () => {
        chartRef.current.handleItemMouseEnter({
          tooltipPayload: [activeItem],
          tooltipPosition: activeItem.tooltipPosition
        });
      }
    );
  };

  const handleLegendMouseLeave = () => {
    setActiveIndex(null)
    if (!chartRef.current) {
      return;
    }
    chartRef.current.setState({
      isTooltipActive: false,
      tooltipPosition: undefined
    });
  };
  return (
    <Grid container sx={{ display: 'flex', width: '100%' }} onMouseLeave={() => handleLegendMouseLeave()}>
      <Grid item xs={12} flex="1 1 100%" margin="10px 0px">
        <Divider sx={{ width: '100%' }} />
      </Grid>
      <Grid item xs={12}>
        <Grid container sx={{ padding: '0px 32px', width: '100%', maxHeight: { xs: 140, sm: 150, md: 100, lg: 150, xl: 150 }, overflow: 'scroll', scrollbarWidth: "none"}}>
          {data && data.map((item: any, index: any) => (
            <Grid
              item
              xs={12}
              lg1={12}
              xl={6}
              onMouseEnter={(event) => handleLegendMouseEnter(event, index)}
              onMouseLeave={() => handleLegendMouseLeave()}
              key={index}
              sx={{
                display: 'flex', alignItems: 'baseline', width: '100%', paddingRight: '5px', cursor: onItemClick ? 'pointer' : 'default',
                '&:hover': {
                  opacity: '0.5'
                }
              }}
              onClick={onItemClick ? () => onItemClick(item.payload) : undefined}
            >
              <Box sx={{ backgroundColor: item.color, borderRadius: '50%', minWidth: '10px', maxWidth: '10px', width: '10px', height: '10px', marginRight: '10px' }} />
              <CustomTypography variant="Regular16" sx={{
                color: { xs: '#64677A' }, overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}>{item.value}</CustomTypography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid >
  )
}

export default BottomLegends