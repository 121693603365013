import { MProfitAPI } from "../api";

export interface SetPasswordPayload {
    email: string;
    passwd: string;
    otp: string | undefined;
    // fullName: string;
}


export const ResetPasswordAPI = (emailAddr: string) => {
    return MProfitAPI.post('/api/ResetPasswd', {},
        {
            params: {
                emailAddr,
                custidStr: 1,
            }
        }
    );
}

export const SubmitResetPasswordAPI = (Email: string, ResetCode: string) => {
    return MProfitAPI.post('/api/Auth/ValidateResetPassword', {
        Email,
        ResetCode
    }
    );
}

export const SetPasswordAPI = (data: SetPasswordPayload) => {
    console.log('data=>', data)
    return MProfitAPI.post('api/Auth/SetPassword', {
        Email: data.email,
        Passwd: data.passwd,
        OldPasswd: data.otp,
        // FullName: data.fullName,
    }
    );
}