export function PMSReportsIcon({color = "#64677a"}: {color?: string}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_149749" data-name="Group 149749" transform="translate(-86)">
                <g id="Rectangle_23766" data-name="Rectangle 23766" transform="translate(86)" fill={color} stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="Path_198443" data-name="Path 198443" d="M18.346,13.327V10.9a3.115,3.115,0,0,0-3.115-3.115H13.846A1.038,1.038,0,0,1,12.808,6.75V5.365A3.115,3.115,0,0,0,9.692,2.25H7.962m0,11.769h6.923M7.962,16.788h3.462M10.038,2.25h-4.5A1.039,1.039,0,0,0,4.5,3.288V19.212A1.039,1.039,0,0,0,5.538,20.25H17.308a1.039,1.039,0,0,0,1.038-1.038V10.558A8.308,8.308,0,0,0,10.038,2.25Z" transform="translate(86.577 0.75)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </g>
        </svg>
    );
}