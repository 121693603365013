
import { Box, Button, Divider, FormControl, InputAdornment, InputLabel, Link, TextField, styled } from '@mui/material';
import EyeVisiblityOff from 'packages/web/src/assets/icons/login/eyeVisiblityOff';
import GoogleIcon from 'packages/web/src/assets/icons/login/googleIcon';
import { useForm, SubmitHandler } from "react-hook-form";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CustomTypography from '../../../shared/customTypography';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';
import { SharedConstants, SharedRedux, SharedUtilities } from '@mprofit/shared';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import EyeVisible from 'packages/web/src/assets/icons/login/eyeVisible';
import { WebRedux } from '@mprofit/web';
import LoginBtnLoader from '../login-btn-loader';
import { AuthPage } from 'packages/web/src/constants/login';
interface IFormInputs {
    email: string
    password: string
}


export default function LoginForm() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const { register, formState: { errors }, handleSubmit, getValues, setValue, watch } = useForm<IFormInputs>();

    const tryLogin: SubmitHandler<IFormInputs> = (data: SharedConstants.LoginPayload) => {
        dispatch(SharedRedux.Login.Actions.loginClicked(data));
    };

    const loginState = useAppSelector(SharedRedux.Login.Selectors.selectLoginState);

    useEffect(() => {
        sessionStorage.removeItem('MprCurrentUser');
    }, [])

    const [showInvalidPasswordMessage, setShowInvalidPasswordMessage] = useState(false);

    useEffect(() => {
        if (loginState.LoginStatus === SharedConstants.Login_Status.ENTER_MFA_OTP) {
            dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: AuthPage.OTPVerification }));
            dispatch(WebRedux.Auth.Actions.setLoginPayload(getValues()));
        } else if (loginState.LoginStatus === SharedConstants.Login_Status.ENTER_RESET_PASSWORD_CODE) {
            dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: AuthPage.ForgotPasswordWithResetCode }));
        } else if (loginState.LoginStatus === SharedConstants.Login_Status.INCORRECT_PASSWORD) {
            setShowInvalidPasswordMessage(true);
            setValue('password', '');
        }
    }, [loginState])

    const handleForgotPassword = () => {
        // window.open('https://cloud.mprofit.in/forgot', '_blank');
        dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: AuthPage.ForgotPassword }))
    }

    return (
        <Box sx={{
            boxSizing: 'border-box',
            width: '53%',
            minWidth: '550px',
            maxWidth: '606px',
            marginLeft: 'auto',
            background: '#FFFFFF',
            maxHeight: '73%',
            minHeight: '600px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: { xs: '28px 56px', lg: '60px 56px' },
            margin: 'auto',
            height: { md: '80%', lg: '70%' },
        }}>
            <CustomTypography variant="Medium18" sx={{ color: { xs: '#5F854C' } }}>WELCOME BACK!</CustomTypography>
            <CustomTypography sx={{ fontSize: { md: '24px', lg: '36px' }, lineHeight: '48px', fontWeight: '500', paddingTop: { md: '6px', lg: '16px' } }}>Login to MProfit</CustomTypography>
            <Divider sx={{ width: '100%', margin: { md: '14px', lg: 'auto' } }} />
            <Box component="form" noValidate autoComplete="off" sx={{ width: '100%' }} onSubmit={handleSubmit(tryLogin)}>
                <InputLabel htmlFor="emailId" sx={{ padding: '10px 0px', color: 'black', fontSize: { lg: '16px', lg1: '18px' } }}>Email</InputLabel>
                <FormControl fullWidth={true}>
                    <InputField
                        id="emailId"
                        variant="outlined"
                        aria-describedby="email-id"
                        placeholder='Enter your email'
                        hiddenLabel
                        size='medium'
                        error={errors.email && true}
                        {...register("email", { required: true, pattern: SharedUtilities.EmailValidationRegex })}
                    />
                    <ErrorText sx={{ visibility: errors.email ? "visible" : 'hidden', color: { xs: '#C94C40' },  marginTop: '5px' }} variant='Regular16'><WarningRoundedIcon fontSize="small" sx={{marginRight:'5px'}}/>Please enter a valid email address</ErrorText>
                </FormControl>

                <InputLabel htmlFor="password" sx={{ padding: '5px 0px 10px 0px', color: 'black', fontSize: { lg: '16px', lg1: '18px' } }}>Password</InputLabel>
                <FormControl fullWidth={true}>
                    <InputField
                        type={showPassword ? "text" : "password"}
                        size="medium"
                        id="password"
                        aria-describedby="password"
                        placeholder='Enter your password'
                        hiddenLabel
                        error={(errors.password != undefined) || showInvalidPasswordMessage}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" onClick={() => setShowPassword(prevState => !prevState)}>
                                    {showPassword ?
                                        <EyeVisible style={{ cursor: 'pointer' }} />
                                        :
                                        <EyeVisiblityOff style={{ cursor: 'pointer' }} />
                                    }
                                </InputAdornment>
                            ),
                        }}
                        {...register("password", { required: true, minLength: 3 })}
                    />
                    {
                        (errors.password || showInvalidPasswordMessage) ?
                        <ErrorText
                            sx={{ display: 'flex', color: { xs: '#C94C40' },  marginTop: '5px' }}
                            variant='Regular16'>
                            <WarningRoundedIcon fontSize='small' sx={{marginRight:'5px'}}/>
                            {showInvalidPasswordMessage ? 'Invalid password' : 'Please enter a valid password'}
                        </ErrorText>
                        :
                        (
                            loginState?.LoginStatus === SharedConstants.Login_Status.INVALID_USER ?
                            <ErrorText
                                sx={{ display: 'flex', color: { xs: '#C94C40' },  marginTop: '5px' }}
                                variant='Regular16'>
                                <WarningRoundedIcon fontSize='small' sx={{marginRight:'5px'}}/>
                                {'Shared logins do not currently have access to this dashboard'}
                            </ErrorText>
                            :
                            <></>
                        )
                    }
                </FormControl>
                <CustomTypography
                    variant='Medium16'
                    onClick={() => handleForgotPassword()}
                    sx={{
                        boxSizing: 'border-box',
                        cursor: 'pointer',
                        color: { xs: '#5F6F57' },
                        padding: '10px 16px 11px 16px',
                        height: '40px',
                        marginLeft: 'auto',
                        marginTop: '8px',
                        display: 'flex',
                        width: 'fit-content',
                        justifyContent: 'flex-end',
                        ':hover': { background: '#EAF4DF', color: '#5F6F57', borderRadius: '4px' }
                    }}>
                    Forgot password?
                </CustomTypography>
                <LoginButton variant="contained" type="submit">
                    {loginState.LoginStatus === SharedConstants.Login_Status.LOGGING_IN
                        ?
                        <LoginBtnLoader />
                        :
                        'Login'
                    }
                </LoginButton>
                {/* <GoogleLoginCont>
                    <GoogleIcon />
                    <CustomTypography variant='Medium16' sx={{ paddingLeft: '8px' }}>Login with Google</CustomTypography>
                </GoogleLoginCont> */}
            </Box>
            <Divider sx={{ width: '100%', margin: { md: '24px', lg: 'auto' } }} />
            <CustomTypography variant='Regular16' sx={{ textAlign: 'center' }}>Don't have an account?
                {/* <Box component="span" sx={{ cursor: 'pointer', color: '#5F6F57', fontSize: "16px", fontWeight: 500, textDecoration: 'none' }}> Sign up</Box> */}
                <Link sx={{ cursor: 'pointer', color: '#5F6F57', fontSize: "16px", fontWeight: 500, textDecoration: 'none' }} href="https://mprofit.in/sign-up"> Sign up</Link>
            </CustomTypography>
        </Box>
    )
}

const LoginButton = styled(Button)({
    height: '48px',
    background: '#010006',
    width: '100%',
    marginTop: '27px',
    textTransform: 'none',
    ":hover": { background: '#252833' },
    ":disabled": { background: '#EFF0F5' }
})

const GoogleLoginCont = styled(Box)({
    height: '48px',
    cursor: 'pointer',
    width: '100%',
    border: '1px solid #D8DAE5',
    borderRadius: '4px',
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ":hover": {
        background: '#EFF0F5',
        border: '1px solid #D8DAE5'
    }
})



const ErrorText = styled(CustomTypography)({
    minHeight: '24px',
    display: 'flex',
    alignItems: 'center',
})

const InputField = styled(TextField)({
    "& .MuiInputBase-root": {
        height: 48,
        boxSizing: 'border-box',
    },
    "&:hover": {
        background: '#f9f9f9',
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D8DAE5",//FIXME: Not working properly afrer refreshing it change th borderColor back to black
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            border: "1px solid #D8DAE5",
        },
    },
})
