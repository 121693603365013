import { call, delay, put, takeEvery } from "redux-saga/effects";
import { MProfitAPI, ResponseGenerator } from "../../api";
import type { PayloadAction } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { BackgroundJobStatusPayload, Actions } from "../modules/backgroundJobsReducer";
import { getBackgroundJobDetailedExtraFields, setBackgroundJobStatusFields } from "../../utilities";
import { BackgroundJobsDetailed, Job_Status_Code } from "../../constants";

const jobSuffix: string = '/api/Jobs/';

const backgroundJobStatusAPI = (payload: BackgroundJobStatusPayload) => {
    return MProfitAPI.get(jobSuffix + (payload.IsAdvisorData ? 'GetAdvisorJobStatus' : 'GetStatus'), {
        params: {
            jobId: payload.JobId,
            jobUserID: payload.JobUserID || -1
        }
    });
}

const backgroundJobDetailedAPI = (payload: BackgroundJobStatusPayload) => {
    return MProfitAPI.get(jobSuffix + (payload.IsAdvisorData ? 'GetAdvisorJobDetailed' : 'GetDetailed'), {
        params: {
            jobId: payload.JobId,
            jobUserID: payload.JobUserID || -1
        }
    });
}

function* onPollBackgroundJob(action: PayloadAction<BackgroundJobStatusPayload>) {
    const response: ResponseGenerator = yield call(backgroundJobDetailedAPI, action.payload);
    console.log(response, 'getJobStatus');
    
    if (response.status != HttpStatusCode.Ok) {
        return;
    }

    var backgroundJobDetailed = getBackgroundJobDetailedExtraFields(response.data, {});

    yield put(Actions.setBackgroundJobsDetailed({ JobId: action.payload.JobId, Detailed: backgroundJobDetailed }));

    var stopTimerCodes = [Job_Status_Code.Completed, Job_Status_Code.Cancelled, Job_Status_Code.Reversed];

    while (true) {
        if (stopTimerCodes.includes(backgroundJobDetailed.StatusCode)) {
            break;
        }

        yield delay(2000);

        const statusResponse: ResponseGenerator = yield call(backgroundJobStatusAPI, action.payload);
        if (statusResponse.status == HttpStatusCode.Ok) {
            backgroundJobDetailed = {...backgroundJobDetailed};
            backgroundJobDetailed.StatusCode = statusResponse.data.StatusCode;
            backgroundJobDetailed.Mode = statusResponse.data.Mode;
            backgroundJobDetailed.ErrorText = statusResponse.data.ErrorText;
            setBackgroundJobStatusFields(backgroundJobDetailed);

            yield put(Actions.setBackgroundJobsDetailed({ JobId: action.payload.JobId, Detailed: backgroundJobDetailed }));
        }
    }
}

function* backgroundJobsSaga() {
    yield takeEvery(Actions.pollBackgroundJob.type, onPollBackgroundJob);
    // yield takeEvery(refreshBackgroundJobsDetailed.type, getJobDetailed);
}

export default backgroundJobsSaga;



