import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import CustomTypography from '../../customTypography';
import Selection from '../../selection';
import { SharedConstants, SharedUtilities } from '@mprofit/shared';

interface IFinancialYearProps {
    setSelectedFY: React.Dispatch<React.SetStateAction<SharedConstants.FYListItem | undefined>>
}

export default function FinancialYear({ setSelectedFY }: IFinancialYearProps) {
    const financialYearList = useMemo(SharedUtilities.getFinancialYearListForPeriodSelection, []);

    const [selectedFYIndex, setSelectedFYIndex] = useState<number>(0);

    const onSelectFY = (item: SharedConstants.FYListItem) => {
        setSelectedFYIndex(item.index);
        setSelectedFY(item);
    }

    useEffect(() => {
        if (financialYearList && financialYearList[0]) {
            setSelectedFY(financialYearList[0]);
        }
    }, [])

    console.log('financialYearList',financialYearList)
    return (
        <Box>
            {financialYearList.map((item, index) => (
                <Box
                    key={item.title}
                    sx={{
                        border: '1px solid #EBECF2',
                        padding: '14px 27px 14px 16px',
                        marginBottom: "16px",
                        borderRadius: '4px',
                        display: 'flex',
                        background: selectedFYIndex === item.index ? "#EAF4DF" : "#FFFFFF",
                        cursor: 'pointer'
                    }}
                    onClick={() => onSelectFY(item)}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box>
                            <CustomTypography variant="Regular18" >{item.title}</CustomTypography>
                            <CustomTypography variant="Regular16" sx={{ color: { xs: "#707070" } }}>{item.subtitle}</CustomTypography>
                        </Box>
                        {/* <Box sx={{ paddingLeft: '20px' }}>
                            <Selection isSelected />
                        </Box> */}
                    </Box>
                </Box>
            ))
            }
        </Box>
    )
}