import { formatDecimal, formatDecimalWithRupeeSymbol } from '@mprofit/shared'
import { Box, Grid } from '@mui/material'
import CurrentPriceicon from 'packages/web/src/assets/icons/todayL4/currentPriceicon'
import { CustomTypography } from 'packages/web/src/components/shared'

interface ICurrentPropsCardsProps {
    PxCurr: string | undefined,
    Quant: string | undefined,
    PxPur: string | undefined
}

export default function L4CurrentPriceCard({ PxCurr, Quant, PxPur }: ICurrentPropsCardsProps) {
    return (
        <Grid container width={"100%"} sx={{ padding: '32px 24px 24px 24px', height: '100%' }}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: "column" }}>
                <Grid container sx={{ flex: "0 0 68px" }}>
                    <Grid item xs={8} >
                        <CustomTypography variant='Regular20' sx={{ color: { xs: "#64677A" } }}>Current price</CustomTypography>
                        <CustomTypography variant='Regular28' >{formatDecimalWithRupeeSymbol(PxCurr, 2, 'IN')}</CustomTypography>
                    </Grid>

                    <Grid item xs={4} >
                        <Box textAlign={"end"}>
                            <CurrentPriceicon />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{ flex: '1 1 10%', display: 'flex', alignItems: 'end' }}>
                    <Grid item xs={6} sx={{ borderLeft: '2px solid #5F6F57', paddingLeft: '16px', maxHeight: '57px' }}>
                        <CustomTypography variant='Regular18' sx={{ color: { xs: "#64677A" } }}>Quantity</CustomTypography>
                        <CustomTypography variant='Medium20'>{formatDecimal(Quant, 2, 'IN')}</CustomTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTypography variant='Regular18' sx={{ color: { xs: "#64677A" } }}>Average purchase price</CustomTypography>
                        <CustomTypography variant='Medium20' >{formatDecimalWithRupeeSymbol(PxPur, 2, 'IN')}</CustomTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
