export function MProfitIcon(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="108.93" height="23.916" viewBox="0 0 108.93 23.916">
            <defs>
                <linearGradient id="linear-gradient" x1="0.419" y1="0.277" x2="0.619" y2="0.735" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#055d2c" />
                    <stop offset="0.27" stopColor="#055d2c" stopOpacity="0.898" />
                    <stop offset="0.542" stopColor="#17692f" stopOpacity="0.753" />
                    <stop offset="1" stopColor="#17692f" stopOpacity="0.502" />
                </linearGradient>
            </defs>
            <g id="Page-1" transform="translate(-0.278 -0.4)">
                <g id="logo" transform="translate(0.278 0.4)">
                    <path id="Shape" d="M164.148,2.487C162.6,1.087,160.6.8,158.091.8h-7.5a.233.233,0,0,0-.209.1.527.527,0,0,0-.084.188v23.1a.366.366,0,0,0,.355.355H155.1a.366.366,0,0,0,.355-.355h0V15.646h2.611a8.976,8.976,0,0,0,5.974-2.047,7.072,7.072,0,0,0,2.423-5.765A6.808,6.808,0,0,0,164.148,2.487Zm-6.976,9.128h-1.734V4.471h2.026q3.822,0,3.822,3.384C161.287,10.361,159.908,11.614,157.172,11.614Z" transform="translate(-118.965 -0.712)" fill="#f0f0f0" />
                    <path id="Shape-2" data-name="Shape" d="M305.534,31a7.7,7.7,0,0,0-6.057,2.527,9.268,9.268,0,0,0-2.277,6.454,8.79,8.79,0,0,0,2.172,6.141,7.93,7.93,0,0,0,6.162,2.4,7.767,7.767,0,0,0,6.12-2.486,9.279,9.279,0,0,0,2.193-6.35,9.041,9.041,0,0,0-2.172-6.224A7.769,7.769,0,0,0,305.534,31Zm2.444,12.365a2.594,2.594,0,0,1-2.4,1.358,2.67,2.67,0,0,1-2.465-1.4,6.955,6.955,0,0,1-.815-3.572,7.148,7.148,0,0,1,.794-3.551,2.617,2.617,0,0,1,2.4-1.379c2.172,0,3.258,1.671,3.258,4.992A7,7,0,0,1,307.978,43.365Z" transform="translate(-235.182 -24.609)" fill="#f0f0f0" />
                    <path id="Path" d="M445.369.4a2.384,2.384,0,0,0-1.817.815,2.766,2.766,0,0,0-.752,1.942,2.766,2.766,0,0,0,.752,1.942,2.434,2.434,0,0,0,3.634,0,2.766,2.766,0,0,0,.752-1.942,2.731,2.731,0,0,0-.752-1.942A2.384,2.384,0,0,0,445.369.4" transform="translate(-350.371 -0.4)" fill="#f0f0f0" />
                    <path id="Path-2" data-name="Path" d="M448.186,31.1h-4.031a.366.366,0,0,0-.355.355V48.206a.366.366,0,0,0,.355.355h4.031a.352.352,0,0,0,.355-.355V31.455A.352.352,0,0,0,448.186,31.1Z" transform="translate(-351.162 -24.688)" fill="#f0f0f0" />
                    <path id="Path-3" data-name="Path" d="M250.342,31.163h0a6.244,6.244,0,0,0-.94-.063,3.865,3.865,0,0,0-2.527.815,3.82,3.82,0,0,0-1.567,1.984V31.434a.377.377,0,0,0-.334-.334h-4.2a.34.34,0,0,0-.334.313V31.5l-.042,16.73h0a.366.366,0,0,0,.355.355h4.177a.362.362,0,0,0,.355-.334v-.042h0V40.1a4.628,4.628,0,0,1,.982-3.07,3.686,3.686,0,0,1,2.548-1.629,4.192,4.192,0,0,1,1.4.209h.084a.362.362,0,0,0,.355-.334v-3.8A.321.321,0,0,0,250.342,31.163Z" transform="translate(-190.246 -24.688)" fill="#f0f0f0" />
                    <path id="Path-4" data-name="Path" d="M398.365,1.235c0-.272,0-.355-.125-.439a10.136,10.136,0,0,0-2.423-.4c-2.026,0-3.613.23-4.783,1.379A6.427,6.427,0,0,0,389.3,6.562V23.94a.362.362,0,0,0,.355.334h4.177a.366.366,0,0,0,.355-.355h0V11.031H397.7a.325.325,0,0,0,.313-.334s.042-3.509,0-3.593a.326.326,0,0,0-.083-.209.25.25,0,0,0-.167-.084c-.042-.021-3.572.021-3.572.021V6.457a3.328,3.328,0,0,1,.334-1.734,2.943,2.943,0,0,1,2.11-.71,3.72,3.72,0,0,1,1.3.209h0c.021,0,.063.021.083.021a.366.366,0,0,0,.355-.355h0Z" transform="translate(-308.045 -0.4)" fill="#f0f0f0" />
                    <path id="Path-5" data-name="Path" d="M487.8,20.05a.126.126,0,0,0-.084.021h0a4.181,4.181,0,0,1-1.859.4,1.84,1.84,0,0,1-1.316-.46,1.7,1.7,0,0,1-.5-1.337V11.111h3.488a.366.366,0,0,0,.355-.355h0V7.33c0-.188-.167-.439-.355-.439h-3.488V.834h0A.348.348,0,0,0,483.686.5h-4.031a.366.366,0,0,0-.355.355h0V13.868h0V18.63c0,3.76,1.88,5.723,5.619,5.723a9.218,9.218,0,0,0,3.008-.418c.021,0,.021-.021.042-.021a.366.366,0,0,0,.209-.313V20.426A.388.388,0,0,0,487.8,20.05Z" transform="translate(-379.247 -0.479)" fill="#f0f0f0" />
                    <path id="Path-6" data-name="Path" d="M107.1,40.457l-.835-9.357h0v.021h0l-3.237,2.548-2.339,1.9h0l-.689.522a.42.42,0,0,1,.272-.084.453.453,0,0,1,.46.355l.021.167.668,7.749a.591.591,0,0,0,.272.4.475.475,0,0,0,.5-.021l4.741-3.739a.544.544,0,0,0,.146-.188A.683.683,0,0,0,107.1,40.457Z" transform="translate(-79.171 -24.688)" fill="#219c48" />
                    <path id="Path-7" data-name="Path" d="M107.1,40.457l-.835-9.357h0v.021h0l-3.237,2.548-2.339,1.9h0l-.689.522a.42.42,0,0,1,.272-.084.453.453,0,0,1,.46.355l.021.167.668,7.749a.591.591,0,0,0,.272.4.475.475,0,0,0,.5-.021l4.741-3.739a.544.544,0,0,0,.146-.188A.683.683,0,0,0,107.1,40.457Z" transform="translate(-79.171 -24.688)" fill="url(#linear-gradient)" />
                    <g id="Group" transform="translate(0 0.084)">
                        <path id="Path-8" data-name="Path" d="M14.13,8.633a.592.592,0,0,0-.167-.355L4.543,1.072A1.46,1.46,0,0,0,3.75.8H3.708A1.444,1.444,0,0,0,2.267,2.241C2.12,3.808.951,17.322.951,17.363L.282,24.026a.5.5,0,0,0,.125.4.552.552,0,0,0,.46.146H5.9a.461.461,0,0,0,.334-.125.573.573,0,0,0,.167-.292l.23-2.862a.077.077,0,0,1,.021-.063L7.4,12.1a.477.477,0,0,1,.794-.334s1.086.606,1.149.647a.4.4,0,0,0,.251.063.593.593,0,0,0,.292-.1l4.115-3.342A.851.851,0,0,0,14.13,8.633Z" transform="translate(-0.278 -0.8)" fill="#f0f0f0" />
                        <path id="Path-9" data-name="Path" d="M108.427,89.661h5.1a.488.488,0,0,0,.376-.188.5.5,0,0,0,.1-.334l-.522-5.619a.5.5,0,0,0-.313-.418.56.56,0,0,0-.48.063l-4.7,3.7a.885.885,0,0,0-.146.209.807.807,0,0,0-.042.251l.1,1.88a.555.555,0,0,0,.146.292A.619.619,0,0,0,108.427,89.661Z" transform="translate(-85.342 -65.892)" fill="#f0f0f0" />
                    </g>
                    <path id="Path-10" data-name="Path" d="M65.66,7.208h0l-.4-4.825A1.45,1.45,0,0,0,63.843.9a1.712,1.712,0,0,0-.856.272L48.867,12.7a.539.539,0,0,0-.167.376.47.47,0,0,0,.188.376L53.525,16.4a.432.432,0,0,0,.292.1.612.612,0,0,0,.313-.1l.313-.251Z" transform="translate(-38.586 -0.796)" fill="#29b256" />
                </g>
            </g>
        </svg>

    );
}
