export function FilterFunnel(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_150698" data-name="Group 150698" transform="translate(-8.225 -8)">
                <rect id="Rectangle_24197" data-name="Rectangle 24197" width="24" height="24" transform="translate(8.225 8)" fill="#fff" opacity="0" />
                <g id="noun-filter-5816931" transform="translate(8.225 8.835)">
                    <g id="Group_226360" data-name="Group 226360" transform="translate(3.47 2)">
                        <path id="Path_272053" data-name="Path 272053" d="M62.982,395.8a.7.7,0,0,1,.177-.464l5.232-5.854A2.089,2.089,0,0,0,66.833,386H53.559A2.089,2.089,0,0,0,52,389.481l5.232,5.854a.7.7,0,0,1,.177.464v7.611A2.089,2.089,0,0,0,59.5,405.5h1.393a2.089,2.089,0,0,0,2.089-2.089Zm-1.393,0v7.611a.7.7,0,0,1-.7.7H59.5a.7.7,0,0,1-.7-.7V395.8a2.092,2.092,0,0,0-.531-1.392l-5.232-5.854a.7.7,0,0,1,.52-1.16H66.833a.7.7,0,0,1,.52,1.16l-5.232,5.854a2.092,2.092,0,0,0-.531,1.392Z" transform="translate(-51.47 -386)" fill="#64677a" fill-rule="evenodd" />
                    </g>
                </g>
            </g>
        </svg>


    );
}