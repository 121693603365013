export function TiltedArrow(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_154174" data-name="Group 154174" transform="translate(8340 17277)">
                <rect id="Rectangle_25233" data-name="Rectangle 25233" width="24" height="24" transform="translate(-8340 -17277)" fill="none" />
                <path id="Path_234915" data-name="Path 234915" d="M4.5,15,15,4.5m0,0H7.125M15,4.5v7.875" transform="translate(-8337.5 -17275)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </g>
        </svg>
    );
}
