import { useEffect, useRef } from 'react';
import { Location, useLocation } from 'react-router-dom';

const useAutoScrollTop = (): void => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);
};

export default useAutoScrollTop;
