export function OpeningCashIcon_Periodwise(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_149319" data-name="Group 149319" transform="translate(0.184)">
                <rect id="Rectangle_23692" data-name="Rectangle 23692" width="48" height="48" rx="8" transform="translate(-0.184)" fill="#fff"/>
                <g id="Group_229293" data-name="Group 229293" transform="translate(6.021 6.942)">
                <path id="Path_272359" data-name="Path 272359" d="M31.288,14.563,17.764,28.087,4.24,14.563,15.633,3.169a3.011,3.011,0,0,1,4.261,0L31.288,14.563Z" transform="translate(0 0)" fill="#d8dae5"/>
                <g id="money" transform="translate(7.504 10.569)">
                    <g id="Rectangle_49106" data-name="Rectangle 49106" transform="translate(0.506 0.454)" fill="#fff" stroke="#707070" stroke-width="1">
                    <rect width="18.916" height="11.349" stroke="none"/>
                    <rect x="0.5" y="0.5" width="17.916" height="10.349" fill="none"/>
                    </g>
                    <path id="Path_198514" data-name="Path 198514" d="M22.731,11H3.789A.789.789,0,0,0,3,11.789V22.839a.789.789,0,0,0,.789.789H22.731a.789.789,0,0,0,.789-.789V11.789A.789.789,0,0,0,22.731,11ZM6.717,14.717a.829.829,0,0,1-.56.229.789.789,0,0,1-.3-.063.908.908,0,0,1-.26-.166.829.829,0,0,1-.229-.56A.861.861,0,0,1,5.6,13.6a.908.908,0,0,1,.26-.166.794.794,0,0,1,1.089.726A.829.829,0,0,1,6.717,14.717Zm6.543,5.754a3.157,3.157,0,1,1,3.157-3.157A3.157,3.157,0,0,1,13.26,20.471Zm7.663.56a.789.789,0,1,1,.229-.56.829.829,0,0,1-.229.56Z" transform="translate(-3 -11)" fill="#12131a"/>
                </g>
                <path id="Path_272375" data-name="Path 272375" d="M31.35,21.994V37.368a1.883,1.883,0,0,1-.137.7,1.749,1.749,0,0,1-.436.66c-.056.056-.118.106-.187.162-.012.006-.019.012-.031.019a2.146,2.146,0,0,1-.2.125c-.056.031-.112.056-.168.081a.976.976,0,0,1-.174.062.5.5,0,0,1-.137.037,1.878,1.878,0,0,1-.424.05H6.134a1.877,1.877,0,0,1-.424-.05.5.5,0,0,1-.137-.037.976.976,0,0,1-.174-.062c-.056-.025-.112-.05-.168-.081a2.147,2.147,0,0,1-.2-.125c-.012-.006-.019-.012-.031-.019-.069-.056-.131-.106-.187-.162a1.749,1.749,0,0,1-.436-.66.889.889,0,0,1-.05-.137,1.883,1.883,0,0,1-.087-.561V21.994L17.764,35.518,29.4,23.881l1.887-1.887h.062Z" transform="translate(0 -7.431)" fill="#ebecf2"/>
                <path id="Path_272378" data-name="Path 272378" d="M31.124,41.751a2,2,0,0,1-.417.305c-.056.031-.112.056-.168.081a.976.976,0,0,1-.174.062.5.5,0,0,1-.137.037,1.878,1.878,0,0,1-.424.05H6.481a1.878,1.878,0,0,1-.424-.05A.5.5,0,0,1,5.92,42.2a.977.977,0,0,1-.174-.062,2,2,0,0,1-.586-.386l1.414-1.414L16.011,30.9a3.011,3.011,0,0,1,4.261,0l9.437,9.437Z" transform="translate(-0.347 -10.456)" fill="#d8dae5"/>
                </g>
            </g>
        </svg>
    );
}