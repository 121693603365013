export function EyeVisiblityIcon(props: any) {
 return (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
   <g id="Group_149749" data-name="Group 149749" transform="translate(-86)">
    <g id="Rectangle_23766" data-name="Rectangle 23766" transform="translate(86)" fill="#64677a" stroke="#707070" strokeWidth="1" opacity="0">
     <rect width="24" height="24" stroke="none" />
     <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
    </g>
    <g id="Group_154267" data-name="Group 154267" transform="translate(86)">
     <path id="Path_206032" data-name="Path 206032" d="M2.036,12.322a1.012,1.012,0,0,1,0-.639,10.5,10.5,0,0,1,19.927,0,1,1,0,0,1,0,.639,10.5,10.5,0,0,1-19.926.005Z" fill="none" stroke="#64677a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
     <path id="Path_206033" data-name="Path 206033" d="M15,12a3,3,0,1,1-3-3A3,3,0,0,1,15,12Z" fill="none" stroke="#64677a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
   </g>
  </svg>
 );
}