import { Box } from "@mui/material"
import CustomTypography from "../../../shared/customTypography"
import styles from "./index.module.css"
import { t } from "i18next"
import { DashboardItemContainer } from "../../shared"
import { RupeeIcon } from "packages/web/src/assets/icons/rupeeIcon"
import { formatDecimalWithRupeeSymbol } from "@mprofit/shared"
import dateFormat from "dateformat";
import { AmountWithTooltip } from "../../../shared/numberWithTooltip"

interface HoldingAmountInvestedProps {
    AmountInvested?: string;
}

export const HoldingAmountInvested = ({ AmountInvested }: HoldingAmountInvestedProps) => {
    return (
        <DashboardItemContainer>
            <Box className={styles.amountInvestedWrapper}>

                <Box className={styles.yourInvestment}>

                    <Box className={styles.amountContainer}>
                        <CustomTypography variant='Regular18' sx={{ color: { xs: "#64677A" } }}>{t('portfolio.holding.amountInvested')}</CustomTypography>
                        <CustomTypography variant='Medium28'>
                            <AmountWithTooltip Value={AmountInvested} />
                        </CustomTypography>
                    </Box>
                    <Box sx={{ display: { xs: "none", xl: 'block' } }} className={styles.iconCont}>
                        <RupeeIcon width="100%" height="auto" />
                    </Box>
                </Box>

                <CustomTypography variant='Regular16' sx={{ color: { xs: "#64677A" } }}>{`as on ${dateFormat(new Date(), 'd mmm yyyy')}`}</CustomTypography>
            </Box>
        </DashboardItemContainer>
    )
}