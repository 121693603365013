export function LN(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226572" data-name="Group 226572" transform="translate(-17890 6539.015)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 -0.015)" fill="#fff" opacity="0" />
          <path id="Path_237176" data-name="Path 237176" d="M10.495,6.279H6.747m3.747,1.874H6.747M8.621,11.9,6.747,10.026h.937a1.874,1.874,0,1,0,0-3.747m6.558,2.342A5.621,5.621,0,1,1,8.621,3,5.621,5.621,0,0,1,14.242,8.621Z" transform="translate(1.684 1.684)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </g>
      </g>
    </svg>
  );
}
