export enum GlobalAccessRightTypes {
    DBAccess = 0,
    User = 1,
    Family = 2,
    Portfolio = 3,
    AssetMaster = 4,
    ViewCurrentValue = 5,
    ViewReports = 6,
    FamilySharing = 7,
    AccessManagement = 8,
    Import = 9,
    ConvertToCloud = 10,
    AdvisorInformation = 11,
    LicenseManagement = 12,
    BulkCorporateAction = 13,
    PortfolioMappings = 14,
    AssetMappings = 15,
    AddExtAuthEmail = 16,
    ImportCreateFamilies = 17,
    ImportAdmin = 18,
    IsAdmin = 19,
    BulkDelete = 20,
    IgnorePortfolioMapping = 21
}

export enum GlobalAccessType {
    Add = 1,
    Edit = 2,
    Delete = 3,
    View = 4
}
export interface GlobalAccessRights {
    // AccessType: GlobalAccessRightTypes;
    [key: string]: boolean;
    Add: boolean;
    Edit: boolean;
    Delete: boolean;
    View: boolean;
}
export interface UserAccessRights {
    IsAdmin: boolean;
    IsMultipleUsers: boolean;
    IsOwner: boolean;
    GlobalAccessRights: {
        [AccessType: number]: GlobalAccessRights
    }
}

export type AccessRightProperties = 'Add' | 'Edit' | 'Delete' | 'View';