import { Button } from '@mui/material'
import CustomTypography from '../customTypography';


interface textButtonProps {
    id?: string;
    handleClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
    buttonText: string;
    endIcon?: JSX.Element;
    startIcon?: JSX.Element;
    buttonSize?: 'large' | undefined;
    disabled?: boolean
}

function ButtonText({ id, handleClick, buttonText, endIcon, startIcon, disabled, buttonSize }: textButtonProps) {
    return (
        <Button
            id={id}
            variant="contained"
            endIcon={endIcon}
            startIcon={startIcon}
            onClick={handleClick}
            disableRipple
            disabled={disabled}
            sx={{
                color: "#5F6F57",
                backgroundColor: 'transparent',
                padding: '10px 16px',
                height: buttonSize === 'large' ? "48px" : "40px",
                textTransform: 'capitalize',
                whiteSpace: 'nowrap',
                boxShadow: 0,

                '&:hover': {
                    backgroundColor: '#EAF4DF',
                    boxShadow: 0,
                },
                '&:active': {
                    backgroundColor: '#CFE3C9',
                    boxShadow: 0,
                },
                '&:focus': {
                    backgroundColor: '#CFE3C9',
                    boxShadow: 0,
                },
                '&:disabled': {
                    backgroundColor: 'white',
                    boxShadow: 0,
                },

                '& .MuiButton-endIcon': {
                    color: '#5f6f57'
                },
                '&:disabled .MuiButton-endIcon': {
                    color: '#babdcc'
                }
            }}
        >
            <CustomTypography variant='Medium16' sx={{ color: { xs: '#5F6F57' } }}>{buttonText}</CustomTypography>
        </Button>
    )
}

export default ButtonText