export function UserIcon(props: any) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_150676" data-name="Group 150676" transform="translate(-4 -4)">
        <rect id="Rectangle_24190" data-name="Rectangle 24190" width="24" height="24" transform="translate(4 4)" fill="#fff" opacity="0" />
        <g id="Group_150678" data-name="Group 150678" transform="translate(4 4)">
          <circle id="Ellipse_1974" data-name="Ellipse 1974" cx="4" cy="4" r="4" transform="translate(8 3)" fill="none" stroke="#ebecf2" strokeLinecap="round" strokeWidth="1.5" />
          <path id="Path_198408" data-name="Path 198408" d="M6,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke="#ebecf2" strokeLinecap="round" strokeWidth="1.5" />
        </g>
      </g>
    </svg>
  );
}
