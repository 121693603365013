import { Box, Tooltip, Zoom } from '@mui/material'
import { ReactNode } from 'react';

interface ITooltipDarkProps {
 children: JSX.Element;
 title?: ReactNode;
 tooltipLines?: string[];
}
export default function TooltipDark({ children, tooltipLines, title }: ITooltipDarkProps) {
    title = title || (
        tooltipLines && tooltipLines.length > 0 ? <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            {
                tooltipLines.map(x => {
                    return <span>{x}</span>
                })
            }
        </Box> : ''
    );
    return (
    <Tooltip title={title} arrow TransitionComponent={Zoom}
    componentsProps={{
        tooltip: {
        sx: {
        bgcolor: 'black',
        '& .MuiTooltip-arrow': {
        color: 'black',
        }
        }
        }
    }}
    >
    {children}
    </Tooltip>
    )
}
