import { formatDecimalWithRupeeSymbol } from '@mprofit/shared'
import { Grid } from '@mui/material'
import CurrentPriceCard from 'packages/web/src/assets/icons/holdingL4/currentPriceCard'
import { CustomTypography } from 'packages/web/src/components/shared'
import React from 'react'

export default function L4HoldingPriceCard({PxCurr}: {PxCurr: string | undefined}) {
    return (
        <Grid padding="24px" sx={{ display: "flex", flexDirection: 'column', height: '100%' }}>
            <Grid sx={{ flex: "1 1 100%" }}>
                <CurrentPriceCard />
            </Grid>
            <Grid sx={{ flex: '1 1 100%',marginTop:'34px' }}>
                <CustomTypography variant='Regular18' sx={{ color: { xs: "#64677A" } }}>Current Price</CustomTypography>
                <CustomTypography variant='Regular24'>{formatDecimalWithRupeeSymbol(PxCurr, 2, 'IN')}</CustomTypography>
            </Grid>
        </Grid>
    )
}
