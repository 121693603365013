export interface CorporateActionsDashboard {
    IsLoading: boolean;
    List?: CorporateActionDashboardItemRaw[];
}

export interface CorporateActionDashboardItemRaw {
    CorpActionID: number;
    AMID: number;
    MProfit_Company_Name: string;
    BSE_Code: number;
    NSE_Symbol: string;
    CorpActionType: CorporateActionType;
    CorpActionDateStr: string;
    RatioText: string;
    Description: string;
}

export interface CorporateActionDashboardItem extends CorporateActionDashboardItemRaw {
    CorpActionTypeStr: string;
    CorpActionDateDisplay: string;
}

export enum CorporateActionType {
    DIVIDEND = 1,
    BONUS = 2,
    SPLIT = 3,
    MERGER = 4,
    DEMERGER = 5
}

export const getCorpActionTypeStr = (corpActionType: CorporateActionType) => {
    switch (corpActionType) {
        case CorporateActionType.BONUS:
            return "Bonus";
        case CorporateActionType.SPLIT:
            return "Split / Consolidation";
        case CorporateActionType.MERGER:
            return "Merger";
        case CorporateActionType.DEMERGER:
            return "Demerger / Capital Reduction";
        case CorporateActionType.DIVIDEND:
            return "Dividend";
    }
}