import { FormSubmissionResponse } from "./formSubmissionResponse";

export enum InvReportCategoryTypes {
    PERFORMANCE = 1,
    CAPITALGAINS = 2,
    TRANSACTIONS = 3,
    ADVANCED = 4,
    TAXFORMATS = 5,
    INCOME = 6
}

export enum GroupReportCategoryTypes {
    PERFORMANCE = 1,
    INCOME = 2
}

export enum StockTransactionReportTypes {
    STOCK_CNSUM = 0,
    STOCK_SALEPURSUM = 2,
    STOCK_TRANS = 3
}

export enum MFTransactionReportTypes {
    MF_DateTrans = 0,
    MF_SchemeTrans = 1,
    MF_SALEPURSUM = 2
}

export enum MPrReportNumber
{
    None = 0,
    PortfolioSummary = 1,
    XIRR = 2,
    HistoricalValuation = 3,
    GroupXIRRSummary = 4,
    GroupXIRRDetailed = 5,
    CapitalGainSummary = 20,
    CapitalGainsITR = 21,
    UnrealisedCapitalGainsSummary = 22,
    UnrealisedCapitalGainsDetailed = 23,
    ContractNoteSummary = 40,
    StockTransactions = 41,
    MutualFundTransactions = 42,
    ChargesReport = 43,
    FnoChargesReport = 44,
    ClosingBalance = 60,
    HoldingPeriod = 61,
    StockRegister = 62,
    DueDates = 80,
    ClearTaxFormat = 101,
    WinmanFormat = 102,
    ComputaxFormat = 103,
    SpectrumFormat = 104,
    GeniusFormat = 105,
    TaxProFormat = 106,
    ITR112AFormat = 107,
    IncomeReportSummary = 140,
    IncomeReportDetailed = 141,
    GroupIncomeReportSummary = 142,
    GroupIncomeReportDetailed = 143,
    ULIPInsReport = 160,
    FDDepositReport = 161,
    GroupFDDepositReport = 162,
    GroupULIPInsReport = 163,
    FnoPortfolioSummary = 501,
    FnoPnlSummary = 502,
    FnoPnlDetailed = 503,
    GlobalCashBalances = 1002
}

export enum ITRTaxSoftwareType {
    NONE = 0,
    WINMAN = 1,
    CLEARTAX = 2,
    COMPUTAX = 3,
    SPECTRUM = 4,
    GENIUS = 5,
    TAXPRO = 6,
    ITR112A = 7
}

export interface ReportRequests {
    [RequestID: number]: FormSubmissionResponse;
}

export interface ReportMetaData {
    ExcelFileName: string;
    PDFFileName: string;
}