import { SharedRedux, SharedUtilities } from '@mprofit/shared'
import { WebRedux } from '@mprofit/web'
import { t } from 'i18next'
import { useAppSelector } from 'packages/web/src/redux/hooks'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReportsIcon } from '../../../assets/icons/ReportsIcon'
import ButtonWithTextAndIcon from '../../shared/button-with-label-and-icon'
import CustomModal from '../../shared/custom-modal'
import CustomTypography from '../../shared/customTypography'
import NavigationTabs from '../../shared/navigation-tabs'
import SnackbarComponent from '../../shared/snackbar'
import SnackbarMessage from '../shared/snackbar-message'
// import ViewSampleReport from '../shared/view-sample-report'
import { HttpStatusCode } from 'axios';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material'

export default function ReportsHeader() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let currentPath = location.pathname.split("/")[3];

    const [value, setValue] = useState<string>("Explore");
    const [openReport, setOpenReport] = useState<boolean>(false);
    const [blobReport, setBlobReport] = useState<Blob | string>("");
    const snackbars = useAppSelector((state) => WebRedux.Reports.Selectors.selectOpenSnackBars(state));

    useEffect(() => {
        if (currentPath === "explore") setValue("Explore")
        else setValue("Saved reports")
    }, [currentPath])

    const handleNavTabs = (event: any) => {
        let activeNav = event.target.value;
        setValue(activeNav)
        if (activeNav === "Saved reports") {
            navigate(`/portfolio/reports/saved`)
        }
        else if (activeNav === "Explore") {
            navigate(`/portfolio/reports/explore`)
        }
    }

    const handleCloseSnackbar = (JobId: number) => {
        dispatch(WebRedux.Reports.Actions.closeSnackBar({ JobId }));
    };

    const handleViewReport = async (JobId: number) => {
        let result = await SharedRedux.Reports.APIs.downloadReportsMetaDataAPI({ JobId });
        if (result.data.PDFFileName === "MAXSIZE" || result.data.PDFFileName === "NOPDF") {
            let generatedReport = await SharedRedux.Reports.APIs.downloadBackgroundReportAPI({ JobId, IsExcel: true })
            if (generatedReport.status === HttpStatusCode.Ok) {
                saveAs(generatedReport.data, SharedUtilities.getFileNameFromHttpResponse(generatedReport) || 'report.pdf');
                handleCloseSnackbar(JobId)
            }
        }
        else {
            let generatedPDF = await SharedRedux.Reports.APIs.downloadBackgroundReportAPI({ JobId, IsExcel: false })
            if (generatedPDF.status === HttpStatusCode.Ok) {
                saveAs(generatedPDF.data, SharedUtilities.getFileNameFromHttpResponse(generatedPDF) || 'report.pdf');
                setBlobReport(generatedPDF.data);
                setOpenReport(true);
                handleCloseSnackbar(JobId);
            }
        }
    }

    return (<>
        <Grid container>
            <Grid item xs={12}>
                <CustomTypography variant="Bold28">{t('reports.reportsHeader.title')}</CustomTypography>
                <Box sx={styles.container}>
                    <NavigationTabs
                        tabIDs={["Explore", "Saved reports"]}
                        handleChange={handleNavTabs}
                        activeID={value}
                    />
                    <ButtonWithTextAndIcon
                        buttonlabel={'View report logs'}
                        startIcon={<ReportsIcon />}
                        onClick={() => navigate('/portfolio/reports/log')}
                    />
                </Box>

            </Grid>
        </Grid>
        {
            snackbars && snackbars.map((JobId, index) => (
                <SnackbarComponent
                    key={index}
                    open={true}
                    autoHideDuration={200000}
                    onClose={() => { }}
                    closeSnackbar={() => handleCloseSnackbar(JobId)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                    sx={{ marginBottom: `${index * 160}px` }}
                    message={
                        <SnackbarMessage
                            handleViewReport={() => handleViewReport(JobId)}
                            JobId={JobId}
                        />
                    }
                />
            ))
        }
        {/* <CustomModal open={openReport} handleClose={() => setOpenReport(false)} >
            <ViewSampleReport setOpenReport={setOpenReport} pdfPreviewLink={blobReport} />
        </CustomModal> */}
    </>)
}

const styles = {
    container: {
        display: "flex",
        justifyContent: 'space-between',
        paddingTop: '24px'
    }
}