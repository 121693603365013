export function LeftArrowOutlinedIcon(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={props.size ? props.size : "64"} height={props.size ? props.size : "64"} viewBox="0 0 32 33">
            <g id="Group_149338" data-name="Group 149338" transform="translate(-935 0.11)">
                <g id="Ellipse_1918" data-name="Ellipse 1918" transform="translate(935 -0.11)" fill={props.disabled ? "#ebecf2" : "#fff"} stroke="#d8dae5" strokeWidth="0.5">
                    <ellipse cx="16" cy="16.5" rx="16" ry="16.5" stroke="none" />
                    <ellipse cx="16" cy="16.5" rx="15.75" ry="16.25" fill="none" />
                </g>
                <path id="arrow-ios-forward-outline" d="M18.182,18.307a.773.773,0,0,0,.6-1.268l-3.465-4.146,3.341-4.153a.791.791,0,1,0-1.245-.975l-3.735,4.641a.773.773,0,0,0,0,.982l3.867,4.641a.773.773,0,0,0,.642.278Z" transform="translate(933.925 3.372)" fill="#12131a" />
            </g>
        </svg >
    );
}