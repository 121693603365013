export function DownloadIcon(props: any) {
 return (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
   <g id="Group_149749" data-name="Group 149749" transform="translate(-86)">
    <g id="Rectangle_23766" data-name="Rectangle 23766" transform="translate(86)" fill="#64677a" stroke="#707070" strokeWidth="1" opacity="0">
     <rect width="24" height="24" stroke="none" />
     <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
    </g>
    <path id="Path_234931" data-name="Path 234931" d="M3,16.5v2.25A2.25,2.25,0,0,0,5.25,21h13.5A2.25,2.25,0,0,0,21,18.75V16.5M16.5,12,12,16.5m0,0L7.5,12M12,16.5V3" transform="translate(86)" fill="none" stroke="#64677a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
   </g>
  </svg>
 );
}