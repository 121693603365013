import { SharedRedux, SharedUtilities, formatPercent } from "@mprofit/shared";
import { Box } from "@mui/material";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { Bar, CartesianGrid, Cell, ComposedChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

const currChartData = [
  {
    uv: 22.28,
    // pv: 1,
    // amt: 100,
  },
];

export default function XIRRTotalChart({isPerformanceLoading}: {isPerformanceLoading: boolean}) {
  const performanceXIRRData = useAppSelector(SharedRedux.Performance.Selectors.selectXIRRTotalData);

  const chartData = [
    {
      XIRR: isPerformanceLoading ? 0 : (performanceXIRRData && performanceXIRRData.XIRR) || 0
    }
  ];

  var min = -0.1;
  var max = 0.1;

  if (performanceXIRRData && performanceXIRRData.XIRR) {
    min = SharedUtilities.getBarChartDomainValue(Math.min(min, performanceXIRRData.XIRR), true);
    max = SharedUtilities.getBarChartDomainValue(Math.max(max, performanceXIRRData.XIRR), false);
  }

  if (max > 0 && min < 0) {
    if (Math.abs(min) > Math.abs(max)) {
        max = min * -1;
    } else if (Math.abs(min) < Math.abs(max)) {
        min = max * -1
    }
  }

  return (
    <Box sx={{ width: '100%', height: { xs: '120px', lg: '120px' }, margin: 'auto' }}>
      <ResponsiveContainer>
        <ComposedChart
          layout="vertical"
          // width={350}
          data={chartData}
          margin={{
            top: 0,
            right: 20,
            bottom: 10,
            left: -20,
          }}
        >
          <CartesianGrid horizontal={false} stroke="#12131A" opacity={0.3} />
          <XAxis
            tickLine={false}
            axisLine={false}
            type="number"
            domain={[min, max]}
            tickFormatter={(tick) => { return `${formatPercent(tick, 0, 'IN')}` }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            dataKey="name"
            type="category"
            scale="band"
            tickFormatter={(tick) => { return `${formatPercent(tick, 0, 'IN')}` }}
          />
          <Bar
            radius={[0, 10, 10, 0]}
            dataKey="XIRR"
            barSize={12}
          >
            {chartData.map((index: any) => (
              <Cell key={index} fill='#000' />
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Box>

  )
}







