export function AscendingOrderIcon(props: any) {
 return (
  <svg {...props} id="Group_151510" data-name="Group 151510" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
   <path id="Path_198663" data-name="Path 198663" d="M0,0H24V24H0Z" fill="none" />
   <line id="Line_631" data-name="Line 631" x2="9" transform="translate(4 6)" fill="none" stroke="#2c3e50" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
   <line id="Line_632" data-name="Line 632" x2="7" transform="translate(4 12)" fill="none" stroke="#2c3e50" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
   <line id="Line_633" data-name="Line 633" x2="4" transform="translate(4 18)" fill="none" stroke="#2c3e50" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
   <path id="Path_198664" data-name="Path 198664" d="M15,15l3,3,3-3" fill="none" stroke="#2c3e50" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
   <line id="Line_634" data-name="Line 634" y2="12" transform="translate(18 6)" fill="none" stroke="#2c3e50" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  </svg>
 );
}