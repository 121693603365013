export function MIS(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226571" data-name="Group 226571" transform="translate(-17890 6538.99)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 0.011)" fill="#fff" opacity="0" />
          <g id="Group_222246" data-name="Group 222246" transform="translate(0 4.377)">
            <g id="Rectangle_24192" data-name="Rectangle 24192" transform="translate(0 -0.367)" fill="none" stroke="#fff" stroke-width="1">
              <rect width="20" height="12" rx="4" stroke="none" />
              <rect x="0.5" y="0.5" width="19" height="11" rx="3.5" fill="none" />
            </g>
            <path id="FC_Chelsea" data-name="FC Chelsea" d="M.326-3.147a.157.157,0,0,1-.109-.045A.147.147,0,0,1,.168-3.3l.015-.068,1.808-4.84A.244.244,0,0,1,2.254-8.4h.818a.258.258,0,0,1,.263.188l1.808,4.84a.228.228,0,0,1,.008.068.153.153,0,0,1-.045.113A.144.144,0,0,1,5-3.147H4.348a.224.224,0,0,1-.15-.045.2.2,0,0,1-.068-.1l-.353-.915H1.549L1.2-3.29a.218.218,0,0,1-.218.143ZM3.515-5.068,2.659-7.387,1.811-5.068ZM6.329-3.147A.174.174,0,0,1,6.2-3.2a.174.174,0,0,1-.053-.128V-8.22A.171.171,0,0,1,6.2-8.351.181.181,0,0,1,6.329-8.4h.705a.177.177,0,0,1,.131.049.177.177,0,0,1,.049.131v4.893a.181.181,0,0,1-.049.128.171.171,0,0,1-.131.053Zm2.544,0A.174.174,0,0,1,8.745-3.2a.174.174,0,0,1-.053-.128V-8.212a.191.191,0,0,1,.049-.135A.171.171,0,0,1,8.872-8.4h3.257a.183.183,0,0,1,.135.053.183.183,0,0,1,.053.135v.525a.171.171,0,0,1-.053.131.191.191,0,0,1-.135.049H9.72v1.426h2.259a.183.183,0,0,1,.135.053.183.183,0,0,1,.053.135v.525a.171.171,0,0,1-.053.131.191.191,0,0,1-.135.049H9.72v1.861a.171.171,0,0,1-.053.131.191.191,0,0,1-.135.049Z" transform="translate(3.763 11.401)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>


  );
}
