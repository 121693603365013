import dateFormat from 'dateformat';

export const formatDateForReading = (date?: Date | string) => {
    if (!date) return undefined;
    return dateFormat(date, 'd mmm yyyy');
}

export const formatUTCDateForReading = (date?: Date | string) => {
    if (!date) return undefined;
    return dateFormat(date, 'UTC:d mmm yyyy');
}

export const formatDateForReadingWithDay = (date?: Date | string) => {
    if (!date) return undefined;
    return dateFormat(date, 'd mmm ddd');
}

export const formatDateForReadingWithTime = (date?: Date | string) => {
    if (!date) return undefined;
    return dateFormat(date, 'd mmm yyyy h:MM TT');
}

export const formatDateYYYYMMDD = (date?: Date | string) => {
    if (!date) return undefined;
    return dateFormat(date, 'yyyy-mm-dd');
}

export const formatDateAsMonthYear = (date?: Date | string) => {
    if (!date) return undefined;
    return dateFormat(date, 'mmmm yyyy');
}

export const formatDateAsMonth = (date?: Date | string) => {
    if (!date) return undefined;
    return dateFormat(date, 'mmmm');
}

export const formatDate = (format: string, date?: Date | string) => {
    if (!date) return undefined;
    return dateFormat(date, format);
}