import { SharedConstants, SharedUtilities } from '@mprofit/shared'
import { Box, Divider, Grid } from '@mui/material'
import { StockAddInfo } from 'packages/shared/src/constants'
import AssetIcon from 'packages/web/src/assets/icons/todayL4/assetIcon'
import LargeCap from 'packages/web/src/assets/icons/todayL4/largeCap'
import { CustomTypography } from 'packages/web/src/components/shared'


export default function L4MFInfoCard({ mfInfo }: { mfInfo?: SharedConstants.MFAddInfo }) {
    return (
        <Grid container padding="16px 24px" spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <AssetIcon />
                <CustomTypography sx={{ paddingLeft: '16px' }} variant="Regular24">About this Fund</CustomTypography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {
                mfInfo?.SEBICategory &&
                <>
                    <Grid item xs={12}>
                        <CustomTypography sx={{ color: { xs: "#64677A" } }} variant='Regular18'>Fund Category</CustomTypography>
                        <CustomTypography variant='Regular20'>{mfInfo.SEBICategory}</CustomTypography>
                    </Grid>
                </>
            }
            {
                mfInfo?.SEBICategory &&
                <>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTypography sx={{ color: { xs: "#64677A" } }} variant='Regular18'>Sub-Category</CustomTypography>
                        <CustomTypography variant='Regular20'>{mfInfo.SubCategoryName}</CustomTypography>
                    </Grid>
                </>
            }
        </Grid>
    )
}
