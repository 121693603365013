import { SharedConstants } from "@mprofit/shared";
import { useSearchParams } from "react-router-dom";

export function useHoldingsMode(defaultMode: SharedConstants.HoldingsMode, allowedHoldingModes: SharedConstants.HoldingsMode[] | null): SharedConstants.HoldingsMode {
    const [searchParams] = useSearchParams();

    if (searchParams && searchParams.has('h')) {
        var modeStr = searchParams.get('h');
        if (modeStr != null && (+modeStr) > 0 && (allowedHoldingModes == null || allowedHoldingModes.includes(+modeStr))) {
            return +modeStr;
        }
    }

    return defaultMode;
}