import Big from "big.js";

export interface PieChartData {
    name: string;
    IDs: { [IDName: string]: number };
    value: number;
    pctValueBig: Big;
    numValueBig: Big;
    color?: string;
}

export const getPieChartColorByIndex = (index: number) => {
    switch (index) {
        default:
        case 0:
            return '#5F6F57';
        case 1:
            return '#FFB598';
        case 2:
            return '#BAA8EE';
        case 3:
            return '#B1DBFF';
        case 4:
            return '#ACC18A';
        case 5:
            return '#A9D6D1';
        case 6:
            return '#B1DBFF';
        case 7:
            return '#D2D4FF';
        case 8:
            return '#BAA8EE';
        case 9:
            return '#CBCEDF';
    }
}

export const getPieChartDataForTopNElements = (PieChartData: any[], totalCurrValue: Big, maxElements: number) => {
    if (PieChartData.length > maxElements) {
        var newPieChartData = [];
        var otherElement = {
            color: 'black',
            name: 'Others',
            value: 0,
            numValueBig: Big(0),
            pctValueBig: Big(0)
        }
        for (var i = 0; i < PieChartData.length; i++) {
            if (i < maxElements) {
                newPieChartData.push(PieChartData[i]);
            } else {
                otherElement.numValueBig = otherElement.numValueBig.add(PieChartData[i].numValueBig);
            }
        }
        otherElement.pctValueBig = otherElement.numValueBig.div(totalCurrValue);
        otherElement.value = otherElement.pctValueBig.toNumber();
        return [
            ...newPieChartData,
            otherElement
        ];
    }

    return PieChartData;
}

export const getTotalFeesPieChartColorByFee = (fee: string) => {
    switch (fee) {
        default:
        case "CustodyFees":
            return '#E2DA90';
        case "LegacyFees":
            return '#FFB598';
        case "ManagementFees":
            return '#7CB9D2';
        case "OtherCharges":
            return '#BAA8EE';
        case "PerformanceFees":
            return '#5F854C';
        case "STT":
            return '#A9D6D1';
        case "TDSOnDividend":
            return '#B1DBFF';
    }
}