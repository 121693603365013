export function PlusIcon(props: any) {
 return (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
   <g id="Group_149749" data-name="Group 149749" transform="translate(-86)">
    <g id="Rectangle_23766" data-name="Rectangle 23766" transform="translate(86)" fill={props.color || "#fff"} stroke="#707070" strokeWidth="1" opacity="0">
     <rect width="24" height="24" stroke="none" />
     <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
    </g>
    <g id="plus" transform="translate(90 4.001)">
     <path id="Path_198143" data-name="Path 198143" d="M15.875,7.875a.889.889,0,0,1,.889.889v7.111a.889.889,0,0,1-.889.889H8.764a.889.889,0,0,1,0-1.778h6.222V8.764A.889.889,0,0,1,15.875,7.875Z" transform="translate(-7.875 -7.875)" fill={props.color || "#fff"} fillRule="evenodd" />
     <path id="Path_198144" data-name="Path 198144" d="M16.875,17.764a.889.889,0,0,1,.889-.889h7.111a.889.889,0,0,1,0,1.778H18.653v6.222a.889.889,0,0,1-1.778,0Z" transform="translate(-9.764 -9.764)" fill={props.color || "#fff"} fillRule="evenodd" />
    </g>
   </g>
  </svg>
 );
}