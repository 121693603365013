import { Big } from 'big.js';
import { Separator } from '../constants';

export function formatDecimalLakhsMillions(value: any, dec: number, locale: string, useNegBrackets: boolean = false, showZeroesAsBlank: boolean = false, showBlankAsZeroes: boolean = false, showPlusSign: boolean = false, hideCommas: boolean = false, showRupeeSymbol: boolean = false, decIfLakhsMillions: number | undefined = undefined) {
    return formatDecimal(value, dec, locale, useNegBrackets, showZeroesAsBlank, showBlankAsZeroes, showPlusSign, hideCommas, true, showRupeeSymbol, decIfLakhsMillions);
}

export function formatDecimal(value: any, dec: number, locale: string, useNegBrackets: boolean = false, showZeroesAsBlank: boolean = false, showBlankAsZeroes: boolean = false, showPlusSign: boolean = false, hideCommas: boolean = false, showInLakhsMillions: boolean = false, showRupeeSymbol = false, decIfLakhsMillions: number | undefined = undefined) {
    var result = formatDecimalHelper(value, dec, locale, useNegBrackets, showZeroesAsBlank, showBlankAsZeroes, showPlusSign, hideCommas, showInLakhsMillions, showRupeeSymbol, decIfLakhsMillions);
    return result?.FormattedValue || '';
}

export enum RoundingType {
    Quantity = -2,
    UpToMaxDecimals = -1,
    UpTo4Decimal = -3,
    Amount = 2
}

function formatDecimalHelper(value: any, dec: number, locale: string, useNegBrackets: boolean = false, showZeroesAsBlank: boolean = false, showBlankAsZeroes: boolean = false, showPlusSign: boolean = false, hideCommas: boolean = false, showInLakhsMillions: boolean = false, showRupeeSymbol = false, decIfLakhsMillions: number | undefined = undefined): { FormattedValue: string, IsNumberInLakhsMillions: boolean } {
    if ((!value || value == '') && showBlankAsZeroes) {
        value = 0;
    }
    if (value || value == 0) {
        var lakhsMillionsSuffix = '';
        if (!(value instanceof Big)) {
            try {
                value = new Big(value);
            } catch (e) {
                return value;
            }
        }
        if (showZeroesAsBlank && value.eq(0)) {
            return { FormattedValue: '', IsNumberInLakhsMillions: false };
        }

        if (showInLakhsMillions) {
            if (locale == 'IN') {
                var oneCrore = 10000000;
                if (value.abs().gte(oneCrore)) {
                    value = value.div(oneCrore);
                    lakhsMillionsSuffix = 'Cr';
                } else if (value.abs().gte(oneCrore / 100)) {
                    value = value.div(oneCrore / 100);
                    lakhsMillionsSuffix = 'L';
                }
            } else {
                var oneBillion = 1000000000;
                if (value.abs().gte(oneBillion)) {
                    value = value.div(oneBillion);
                    lakhsMillionsSuffix = ' Bn';
                } else if (value.abs().gte(oneBillion / 1000)) {
                    value = value.div(oneBillion / 1000);
                    lakhsMillionsSuffix = ' Mn';
                }
            }
        }

        var IsNumberInLakhsMillions = false;
        if (lakhsMillionsSuffix && lakhsMillionsSuffix.length > 0) {
            IsNumberInLakhsMillions = true;
            dec = decIfLakhsMillions || dec;
        }

        if (dec == RoundingType.UpToMaxDecimals) {
            dec = Math.max(value.c.length - value.e - 1, 2);
            var number = value.toFixed(dec);
        } else if (dec == RoundingType.UpTo4Decimal) {
            dec = Math.max(value.c.length - value.e - 1, 2);
            dec = Math.min(4, dec);
            var number = value.toFixed(dec);
        } else if (dec == RoundingType.Quantity) {
            dec = Math.max(value.c.length - value.e - 1, 0);
            var number = value.toFixed(dec);
        } else {
            var number = value.toFixed(dec)
        }
        var neg = '';
        if (number.substring(0, 1) == '-') {
            neg = '-';
            number = number.substring(1);
        } else if (showPlusSign) {
            neg = '+';
        }
        if (dec > 0) {
            var final: string = number.substr(number.length - dec - 1);
            var remaining = number.substring(0, number.length - dec - 1);
        } else {
            var final = '';
            var remaining = number;
        }
        if (locale == 'IN') {
            while (remaining.length > 0) {
                if (remaining.length > 7) {
                    var part = remaining.substr(remaining.length - 7);
                    final = ',' + indianCommas(part) + final;
                    remaining = remaining.substring(0, remaining.length - 7);
                } else {
                    final = indianSmall(remaining) + final;
                    remaining = '';
                }
            }
        } else {
            while (remaining.length > 0) {
                if (remaining.length > 3) {
                    var part = remaining.substr(remaining.length - 3);
                    final = ',' + part + final;
                    remaining = remaining.substring(0, remaining.length - 3);
                } else {
                    final = remaining + final;
                    remaining = '';
                }
            }
        }

        if (showRupeeSymbol) {
            final = '₹' + final;
        }

        if (useNegBrackets && neg == "-") {
            final = '(' + final + ')';
            neg = '';
        }

        if (hideCommas) {
            return { FormattedValue: (neg + final + lakhsMillionsSuffix).replace(',', ''), IsNumberInLakhsMillions };
        } else {
            return { FormattedValue: neg + final + lakhsMillionsSuffix, IsNumberInLakhsMillions };
        }
    } else {
        return { FormattedValue: '', IsNumberInLakhsMillions: false };
    }

}

export function formatDecimalSplit(value: any, dec: number, locale: string, hideCommas: boolean = false) {
    var formattedDecimal = formatDecimal(value, dec, locale, false, false, false, false, hideCommas, false);
    return formattedDecimal.split('.');
}

export function formatDecimalWithRupeeSymbol(value: any, dec: number, locale: string, useNegBrackets: boolean = false, showInLakhsMillions: boolean = false) {
    return formatDecimal(value, dec, locale, useNegBrackets, false, false, false, false, showInLakhsMillions, true);
}

function indianSmall(number: string) {
    var final = '';
    if (number.length <= 3) {
        final = number;
    } else {
        final = number.substr(number.length - 3);
        var remaining = number.substring(0, number.length - 3);
        if (remaining.length <= 2) {
            final = remaining + ',' + final;
        } else {
            final = remaining.substr(remaining.length - 2) + ',' + final;
            remaining = remaining.substring(0, remaining.length - 2);
            final = remaining + ',' + final;
        }
    }
    return final;
}

function indianCommas(number: string) {
    return number.substring(0, 2) + ',' + number.substring(2, 4) + ',' + number.substring(4);
}

export function formatPercent(value: any, decimal: number, locale: string, showPlusSign: boolean = false, showNegSign: boolean = true) {
    if (value != undefined) {
        if (!(value instanceof Big)) {
            try {
                value = new Big(value);
            } catch (e) {
                return value;
            }
        }
        value = value.times(100);
        if (decimal == RoundingType.UpToMaxDecimals) {
            decimal = Math.max(value.c.length - value.e - 1, 2);
            var number = value.toFixed(decimal);
        } else {
            var number = value.toFixed(decimal)
        }
        var neg = '';
        if (number.substring(0, 1) == '-') {
            neg = showNegSign ? '-' : '';
            number = number.substring(1);
        } else if (showPlusSign) {
            neg = '+';
        }
        if (decimal > 0) {
            var final: string = number.substr(number.length - decimal - 1);
            var remaining = number.substring(0, number.length - decimal - 1);
        } else {
            var final = '';
            var remaining = number;
        }
        while (remaining.length > 0) {
            if (remaining.length > 3) {
                var part = remaining.substr(remaining.length - 3);
                final = ',' + part + final;
                remaining = remaining.substring(0, remaining.length - 3);
            } else {
                final = remaining + final;
                remaining = '';
            }
        }
        return neg + final + '%';
    } else {
        return '';
    }

}

export interface NumberFormatPrefs {
    ShowDecimals: boolean,
    ShowInLakhsMillions: boolean,
    Separator: Separator
}

export const FormatAmountUsingPrefsHelper = (numberFormatPrefs: NumberFormatPrefs, value: any, defaultDecimalPlaces: number = 2, showRupeeSymbol: boolean = true) => {
    var dec = numberFormatPrefs.ShowDecimals ? defaultDecimalPlaces : 0;
    return formatDecimalHelper(value, dec, numberFormatPrefs.Separator, undefined, undefined, undefined, undefined, undefined, numberFormatPrefs.ShowInLakhsMillions, showRupeeSymbol, 2);
}

export const FormatAmountUsingPrefs = (numberFormatPrefs: NumberFormatPrefs, value: any, defaultDecimalPlaces: number = 2, showRupeeSymbol: boolean = true) => {
    return FormatAmountUsingPrefsHelper(numberFormatPrefs, value, defaultDecimalPlaces, showRupeeSymbol).FormattedValue;
}

export const FormatAmountForTooltipUsingPrefs = (numberFormatPrefs: NumberFormatPrefs, value: any, defaultDecimalPlaces: number = 2, showRupeeSymbol: boolean = true) => {
    return formatDecimal(value, 2, numberFormatPrefs.Separator, undefined, undefined, undefined, undefined, undefined, false, showRupeeSymbol);
}

export const FormatGainUsingPrefs = (numberFormatPrefs: NumberFormatPrefs, value: any, defaultDecimalPlaces: number = 2, showRupeeSymbol: boolean = true) => {
    return FormatGainUsingPrefsHelper(numberFormatPrefs, value, defaultDecimalPlaces, showRupeeSymbol).FormattedValue;
}

export const FormatGainUsingPrefsHelper = (numberFormatPrefs: NumberFormatPrefs, value: any, defaultDecimalPlaces: number = 2, showRupeeSymbol: boolean = false) => {
    var dec = numberFormatPrefs.ShowDecimals ? defaultDecimalPlaces : 0;
    return formatDecimalHelper(value, dec, numberFormatPrefs.Separator, undefined, undefined, undefined, true, undefined, numberFormatPrefs.ShowInLakhsMillions, showRupeeSymbol, 2);
}

export const FormatPercentUsingPrefs = (numberFormatPrefs: NumberFormatPrefs, value: any, defaultDecimalPlaces: number = 2) => {
    return formatPercent(value, defaultDecimalPlaces, numberFormatPrefs.Separator);
}