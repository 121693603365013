export function RcaDividendIcon(props: any) {
  return (
    <svg id="Group_150779" data-name="Group 150779" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <rect id="Rectangle_24213" data-name="Rectangle 24213" width="48" height="48" rx="8" fill="#f5f5f6" />
      <g id="Group_151004" data-name="Group 151004" transform="translate(13.962 15)">
        <path id="Path_198550" data-name="Path 198550" d="M11.994,7.5a2.25,2.25,0,1,0,2.244,2.25A2.247,2.247,0,0,0,11.994,7.5Z" transform="translate(-1.522 -3)" />
        <path id="Path_198551" data-name="Path 198551" d="M1.5,4.875A1.873,1.873,0,0,1,3.37,3h17.2a1.873,1.873,0,0,1,1.87,1.875v9.75a1.873,1.873,0,0,1-1.87,1.875H3.37A1.872,1.872,0,0,1,1.5,14.625ZM8.232,9.75a3.74,3.74,0,1,1,3.74,3.75,3.745,3.745,0,0,1-3.74-3.75ZM18.7,9a.749.749,0,0,0-.748.75v.008a.749.749,0,0,0,.748.75h.008a.749.749,0,0,0,.748-.75V9.75A.749.749,0,0,0,18.711,9ZM4.492,9.75A.749.749,0,0,1,5.24,9h.008A.749.749,0,0,1,6,9.75v.008a.749.749,0,0,1-.748.75H5.24a.749.749,0,0,1-.748-.75Z" transform="translate(-1.5 -3)" fillRule="evenodd" />
        <path id="Path_198552" data-name="Path 198552" d="M2.248,18a.75.75,0,0,0,0,1.5,59,59,0,0,1,15.558,2.075,1.893,1.893,0,0,0,2.393-1.82V18.75a.749.749,0,0,0-.748-.75Z" transform="translate(-1.5 -3)" />
      </g>
    </svg>
  );
}