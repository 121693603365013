export function RightGreenBubbleIcon(props: any) {
 return (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={props.width} height={props.height} viewBox="0 0 170 182">
   <defs>
    <clipPath id="clipPath">
     <rect id="Rectangle_24235" data-name="Rectangle 24235" width="170" height="182" rx="6" fill="#fff" />
    </clipPath>
   </defs>
   <g id="Mask_Group_44" data-name="Mask Group 44" clipPath="url(#clipPath)">
    <circle id="Oval" cx="75.5" cy="75.5" r="75.5" transform="translate(67 -33)" fill="#eaf4df" />
   </g>
  </svg>

 );
}
