import { Divider, Stack } from '@mui/material';
import { CustomTypography } from '../shared';
import { styled } from "@mui/material/styles";
import { CashMgmtRow } from 'packages/shared/src/constants';
import { FormatAmountUsingPrefsHelper } from '@mprofit/shared';
import { useNumberFormatPrefs } from '../hooks/userPreferencesHooks';
import { CashflowTable_FeesIcon } from '../../assets/icons/CashflowTable_FeesIcon';
import { CashflowTable_CashInIcon } from '../../assets/icons/CashflowTable_CashInIcon';
import { CashflowTable_CashOutIcon } from '../../assets/icons/CashflowTable_CashOutIcon';

const Container = styled(Stack)({
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    border: "1px solid #EBECF2",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '24px',
    marginBottom: '16px'
});

interface CashflowItemProps {
    item: CashMgmtRow;
}

enum CashflowTransactionType {
    CASH_IN = 'Cash In',
    CASH_OUT = 'Cash Out',
    FEES = 'Fees'
}

const getCashflowItemIcon = (itemTransactionType: string) => {
    if (itemTransactionType === CashflowTransactionType.CASH_IN) {
        return <CashflowTable_CashInIcon />
    }

    if (itemTransactionType === CashflowTransactionType.CASH_OUT) {
        return <CashflowTable_CashOutIcon />
    }

    return <CashflowTable_FeesIcon />;
}

const CashflowItem = ({ item }: CashflowItemProps) => {
    const numberFormatPrefs = useNumberFormatPrefs();
    return (
        <Container>
            <Stack direction="row" flex={1} gap={2} alignItems="center">
                {getCashflowItemIcon(item?.TRSTR)}
                <CustomTypography
                    variant="Regular20"
                    sx={{
                        color: '#12131A',
                    }}
                >
                    {item?.TRSTR}
                </CustomTypography>
            </Stack>
            <Divider orientation="vertical" flexItem sx={{ mr: 4 }} />
            <Stack direction="row" flex={1} justifyContent="space-between" alignItems="center">
                <Stack direction="column">
                    <CustomTypography
                        variant="Regular18"
                        sx={{
                            color: '#12131A',
                        }}
                    >
                        {item?.DateStr}
                    </CustomTypography>
                    <CustomTypography
                        variant="Regular18"
                        sx={{
                            color: '#64677A',
                        }}
                    >
                        Date
                    </CustomTypography>
                </Stack>
                <Stack direction="column" alignItems={'flex-end'}>
                    <CustomTypography
                        variant="Regular18"
                        sx={{
                            color: '#12131A',
                        }}
                    >
                        {FormatAmountUsingPrefsHelper(numberFormatPrefs, item?.AMT || 0).FormattedValue}
                    </CustomTypography>
                    <CustomTypography
                        variant="Regular18"
                        sx={{
                            color: '#64677A',
                        }}
                    >
                        Amount
                    </CustomTypography>
                </Stack>
            </Stack>

        </Container>
    )
}



export default CashflowItem;