import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import { SxProps, Theme } from '@mui/system';

import React from 'react';
interface primaryIconButtonProps {
    icon: JSX.Element;
    buttonSize?: string;
    tooltipPlacement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
    disabled?: boolean;
    tooltipText?: string;
    iconBorderRadius?: string;
    // props: IconButtonProps,
    handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    backgroundColor?: string;
    extraSx?: SxProps<Theme>;
}

function PrimaryIconButton({ iconBorderRadius, icon, handleClick, buttonSize, tooltipPlacement, disabled, tooltipText, backgroundColor, extraSx }: primaryIconButtonProps) {

    return (
        <Tooltip
            arrow
            placement={tooltipPlacement}
            componentsProps={{
                tooltip: {
                    sx: {
                        color: "#FFFFFF",
                        backgroundColor: '#010006',
                    },
                }
            }}
            title={tooltipText}>

            <IconButton
                disableRipple
                onClick={handleClick}
                disabled={disabled}
                sx={{
                    borderRadius: iconBorderRadius || "1px",
                    color: '#5F6F57',
                    backgroundColor: backgroundColor || "#CFE3C9",
                    padding: "8px",
                    marginLeft: "12px",
                    '&:hover': {
                        color: "#FFFFFF",
                        backgroundColor: '#5F854C',
                        boxShadow: 0
                    },
                    "&:disabled": {
                        backgroundColor: "#EBECF2"
                    },
                    "&:selected": {
                        backgroundColor: 'black',
                    },
                    ...extraSx
                }}
            >
                {icon}
            </IconButton>
        </Tooltip>
    )
}

export default PrimaryIconButton