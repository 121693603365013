import { Box, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack } from '@mui/material'
import { CloseIcon } from 'packages/web/src/assets/icons/CloseIcon'
import { Dispatch, SetStateAction, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomTypography from '../../../shared/customTypography'
import InputField from '../../../shared/InputField'
import DropDown from '../../../shared/drop-down';
import SecondaryIconButton from '../../../shared/secondary-icon-button';
import { AscendingOrderIcon } from 'packages/web/src/assets/icons/ascendingorderIcon';
import SwitchButton from '../../../shared/switch-button';
import ButtonText from '../../../shared/button-text';
import ButtonPrimaryCTA from '../../../shared/button-primary-cta';

interface IEditDetailsModalProps {
  setEditDetails: Dispatch<SetStateAction<boolean>>
}
export default function EditDetailsModal({ setEditDetails }: IEditDetailsModalProps) {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false);

  const handleDropdown = () => {

  }

  return (
    <Stack sx={styles.modalContainer}>
      <Grid sx={styles.container}>
        <Box sx={styles.headerContent}>
          <CustomTypography variant="Medium24">Edit report settings</CustomTypography>
          <Box sx={{ marginLeft: 'auto' }} onClick={() => setEditDetails(false)}>
            <CloseIcon sx={{ color: '#707070', fontSize: '24px', cursor: 'pointer' }} />
          </Box>
        </Box>

        <Stack sx={styles.mainContainer}>
          <Box display='flex'>
            <Box width="30%">
              <CustomTypography variant='Regular16' sx={{ color: { xs: "#64677A" } }}>Report</CustomTypography>
              <CustomTypography>Performance</CustomTypography>
            </Box>
            <Box>
              <CustomTypography variant='Regular16' sx={{ color: { xs: "#64677A" } }}>Report type</CustomTypography>
              <CustomTypography variant='Regular18'>Portfolio summary</CustomTypography>
            </Box>
          </Box>
          <Divider sx={{ margin: "16px 0px" }} />

          <Box width="50%">
            <CustomTypography variant='Regular16'> Report name</CustomTypography>
            <InputField placeholder="input value" />
          </Box>

          <Box sx={{ paddingTop: '16px' }}>
            <CustomTypography paddingBottom="16px" variant='Regular18'>GENERATE REPORT FOR</CustomTypography>
            <Box width="70%">
              <FormControl>
                <RadioGroup row>
                  {
                    ["Stocks", "Stocks & MF Equity", "MF (Equity)", "MF (Debt)", "InvITs & REITs"].map((label, index) => (
                      <Box key={index} sx={{ border: '1px solid #D8DAE5', margin: '0px 16px 16px 0px', padding: '0px 12px', borderRadius: '4px' }} >
                        <FormControlLabel
                          value={label}
                          control={<Radio />}
                          label={label}
                        />
                      </Box>
                    ))
                  }
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <Box sx={styles.additionalContainer} >
            <Box sx={styles.dropdownBox}>
              <CustomTypography variant='Regular18' sx={{ color: { xs: '#64677A' } }}>Additional customization</CustomTypography>
              <Box >
                {
                  showAdditionalInfo ?
                    <KeyboardArrowDownIcon style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={() => setShowAdditionalInfo(prevState => !prevState)} />
                    :
                    <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={() => setShowAdditionalInfo(prevState => !prevState)} />
                }
              </Box>
            </Box>
            {
              showAdditionalInfo &&
              <Box>
                <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A', padding: '24px 0px 16px 0px' } }}>Default sort</CustomTypography>
                <Box sx={styles.sortCont}>
                  <Box paddingRight="10px">
                    <DropDown
                      inputList={[]}
                      inputValue={"FYI22-23"}
                      handleChange={handleDropdown}
                    />
                  </Box>

                  <Box paddingRight="10px">
                    <SecondaryIconButton
                      icon={<AscendingOrderIcon />}
                      tooltipText="Ascending"
                      buttonSize='large'
                    />
                  </Box>

                  <Box paddingRight="10px">
                    <SecondaryIconButton
                      icon={<AscendingOrderIcon style={{ transform: 'rotate(180deg)' }} />}
                      tooltipText="Ascending"
                      buttonSize='large'
                    />
                  </Box>
                </Box>

                <Box width="70%">
                  <FormControl>
                    <RadioGroup row>
                      {
                        ["All", "Intraday", "Short term", "Long term"].map((label, index) => (
                          <Box key={index} sx={{ border: '1px solid #D8DAE5', margin: '0px 16px 16px 0px', padding: '0px 12px', borderRadius: '4px' }} >
                            <FormControlLabel
                              value={label}
                              control={<Radio />}
                              label={label}
                            />
                          </Box>
                        ))
                      }
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box margin="24px 0px">
                  <Divider />
                </Box>
                <Grid container>
                  <Grid item xs={6}>
                    <SwitchButton label="All" />
                  </Grid>
                  <Grid item xs={6}>
                    <SwitchButton label="Intraday" />
                  </Grid>
                  <Grid item xs={6}>
                    <SwitchButton label="Short term" />
                  </Grid>
                  <Grid item xs={6}>
                    <SwitchButton label="Long term" />
                  </Grid>
                </Grid>
              </Box>
            }
          </Box>
        </Stack>
        <Box paddingTop="10px" sx={styles.footerContent}>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '20px' }}>
            <Box paddingRight="10px">
              <ButtonText buttonSize="large" buttonText="Cancel" />
            </Box>
            <Box>
              <ButtonPrimaryCTA buttonText='done' />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Stack>
  )
}

const styles = {
  modalContainer: {
    boxSizing: 'border-box',
    width: '45%',
    height: { md: '85%', xl: 'auto' },
    maxHeight: '85%',
    background: '#FFFFFF',
    borderRadius: '10px',
    padding: '0px 26px 26px 26px',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  headerContent: {
    display: 'flex',
    paddingTop: "24px",
    flex: '0 0 auto'
  },
  mainContainer: {
    flex: '1 1 100%',
    minHeight: '0px',
    overflow: 'auto'
  },
  additionalContainer: {
    border: '1px solid #D8DAE5',
    borderRadius: '4px',
    padding: '21px 24px'
  },
  dropdownBox: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
  },
  sortCont: {
    display: 'flex',
    padding: '12px 0px'
  },
  footerContent: {
    flex: '0 0 auto',
  }
}