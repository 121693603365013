import { Divider, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { CloseIcon } from 'packages/web/src/assets/icons/CloseIcon'
import React, { Dispatch, SetStateAction } from 'react'
import CustomTypography from '../../../shared/customTypography'

interface ISavedDetailsModalProps {
  setOpenDetails: Dispatch<SetStateAction<boolean>>
}
export default function SavedDetailsModal({ setOpenDetails }: ISavedDetailsModalProps) {
  return (
    <Stack sx={styles.modalContainer}>
      <Box sx={{ display: 'flex', paddingTop: "24px" }}>
        <CustomTypography variant="Medium24">Report Details</CustomTypography>
        <Box sx={{ marginLeft: 'auto' }} onClick={() => setOpenDetails(false)}>
          <CloseIcon sx={{ color: '#707070', fontSize: '24px', cursor: 'pointer' }} />
        </Box>
      </Box>
      <Stack>
        <CustomTypography variant='Regular16' sx={{ color: { xs: "#64677A" } }}>Report name</CustomTypography>
        <CustomTypography>New report design</CustomTypography>
        <Divider sx={{ margin: "16px 0px" }} />
        <Box display="flex" width="100%">
          <Box width="45%" >
            <CustomTypography variant='Regular16' sx={{ color: { xs: "#64677A" } }}>Report</CustomTypography>
            <CustomTypography variant='Regular18'>Tax Software Formats</CustomTypography>
            <br />
            <CustomTypography variant='Regular16' sx={{ color: { xs: "#64677A" } }}>Generated for</CustomTypography>
            <CustomTypography variant='Regular18'>Stocks</CustomTypography>
          </Box>
          <Box width="55%">
            <CustomTypography variant='Regular16' sx={{ color: { xs: "#64677A" } }}>Report type</CustomTypography>
            <CustomTypography variant='Regular18'>Spectrum-Capital Gains ITR Format</CustomTypography>
            <br />
            <CustomTypography variant='Regular16' sx={{ color: { xs: "#64677A" } }}>Time period</CustomTypography>
            <CustomTypography variant='Regular18'>FY 22-23</CustomTypography>
          </Box>
        </Box>
      </Stack>
    </Stack>
  )
}

const styles = {
  modalContainer: {
    width: '36%',
    background: '#FFFFFF',
    borderRadius: '10px',
    padding: '0px 26px 26px 26px'
  }
}