import axios, { AxiosInstance } from "axios";

export var API_URL: string = '';

export var MProfitAPI: AxiosInstance;

export const SetAPIURL = (_API_URL: string) => {
    API_URL = _API_URL;

    MProfitAPI = axios.create({
        baseURL: API_URL,
        validateStatus: () => true
    });
}

export const SetAPIToken = (bearerToken: string) => {
    MProfitAPI.defaults.headers.common = { 'Authorization' : 'bearer ' + bearerToken };
}

export const SetAPIResponseInterceptors = ({On401Error = () => {}, On403Error = () => {}}: {On401Error: () => void, On403Error: () => void}) => {
    MProfitAPI.interceptors.response.use(response => {
        console.log('Axios Log', response);
        if (response.status === 401 && On401Error) {
            On401Error();
        } else if (response.status == 403 && On403Error) {
            On403Error();
        } else if (response.status >= 400) {
            console.log('Axios Error', response);
        }
        return response;
     }, error => {
       return error;
    });
}

export interface ResponseGenerator {
    config?:any,
    data?:any,
    headers?:any,
    request?:any,
    status?:number,
    statusText?:string
}