import { DashboardItemContainer } from '../../shared/dashboardItemContainer'
import CustomTypography from '../../../shared/customTypography'
import { Box, styled } from '@mui/material'
import { AssetClassIcon, PortfolioIcon, SectorClassIcon, StocksClassIcon } from 'packages/web/src/assets/icons/todayL1'
import { SharedConstants, } from '@mprofit/shared';
import { AmountWithTooltip } from '../../../shared/numberWithTooltip';

interface IconProps {
    [key: number]: JSX.Element;
}

interface ColorProps {
    [key: number]: string;
}

interface OpacityProps {
    [key: number]: number;
}

interface TodayValueCardprops {
    Screen: SharedConstants.ScreenEnum,
    ScreenType: string,
    Title?: string,
    CurrentValue?: string,
    Label?: string,
    Icon?: JSX.Element
}

const TodayIcons: IconProps = {
    [SharedConstants.ScreenEnum.L1]: <PortfolioIcon />,
    [SharedConstants.ScreenEnum.L1_AA]: <PortfolioIcon />,
    [SharedConstants.ScreenEnum.L2]: <AssetClassIcon />,
    [SharedConstants.ScreenEnum.L3]: <SectorClassIcon />,
    [SharedConstants.ScreenEnum.L4]: <StocksClassIcon />,
}

const TodayColors: ColorProps = {
    [SharedConstants.ScreenEnum.L1]: '#FFFFFF',
    [SharedConstants.ScreenEnum.L1_AA]: '#A9D6D133',
    [SharedConstants.ScreenEnum.L2]: '#e7ece1',
    [SharedConstants.ScreenEnum.L3]: '#A9D6D133',
    [SharedConstants.ScreenEnum.L4]: '#E9EDF9',
}

export default function TodayValueCard({ Screen, ScreenType, Title, CurrentValue, Label = 'Current Value', Icon }: TodayValueCardprops) {
    return (
        <DashboardItemContainer className='apptour-today-currentvalue'>
            <Wrapper sx={{ background: TodayColors[Screen] }}>
                <LeftSide>
                    <GreyBoxContainer>
                        <CustomTypography color={'#fff'}>{ScreenType}</CustomTypography>
                    </GreyBoxContainer>
                    <CustomTypography sx={{ fontSize: "32px", lineHeight: '42px' }}>{Title}</CustomTypography>
                    <CustomTypography variant='Regular16' sx={{ paddingTop: '30px' }}>{Label}</CustomTypography>
                    <CustomTypography sx={{ fontSize: "32px", lineHeight: '42px', paddingBottom: '24px' }}>
                        <AmountWithTooltip Value={CurrentValue} />
                    </CustomTypography>
                </LeftSide>
                <RightSide
                    sx={{
                        marginTop: (Screen === SharedConstants.ScreenEnum.L1 || Screen == SharedConstants.ScreenEnum.L1_AA) || Screen === SharedConstants.ScreenEnum.L3 ? 'auto' : 'inherit',
                        padding: Screen === SharedConstants.ScreenEnum.L2 ? "24px" : '0px'
                    }}>
                    {Icon || TodayIcons[Screen]}
                </RightSide>
            </Wrapper>
        </DashboardItemContainer>
    )
}

const Wrapper = styled(Box)({
    borderRadius: '8px',
    padding: '24px 0px 0px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    boxSizing: 'border-box'
});

const GreyBoxContainer = styled(Box)({
    background: '#3A3D4D',
    color: 'white',
    width: 'fit-content',
    borderRadius: '4px',
    padding: '5px 8px',
    marginBottom: '8px'
});

const RightSide = styled(Box)({

})

const LeftSide = styled(Box)({

})