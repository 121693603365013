import React, { useEffect } from 'react'
import { SharedConstants, SharedRedux } from '@mprofit/shared';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';
import { WebRedux } from '@mprofit/web';
import { Box, Typography, styled } from '@mui/material';
import DottedBackground from '../../../assets/background/dottedCircles.png'
import { MProfitIcon } from 'packages/web/src/assets/icons/MprofitIcon';
import { ChartIcons } from 'packages/web/src/assets/icons/login';
import LoginForm from './login-form';
import OTPVerification from './otp-verification';
import ForgotPassword from './forgot-password';
import SetNewPassword from './set-new-password';
import AllDone from './all-done';
import ForgotPasswordWithResetCode from './forgot-password-with-reset-code';
import { AuthPage } from 'packages/web/src/constants/login';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(SharedRedux.Login.Selectors.selectUser);

  const activeIndex = useAppSelector((state) => WebRedux.Auth.Selectors.selectActiveAuthPage(state))

  const loginState = useAppSelector(SharedRedux.Login.Selectors.selectLoginState);

  useEffect(() => {
    if (loginState.LoginStatus === SharedConstants.Login_Status.LOGIN_SUCCESS && user) {
      navigate('/portfolio/today');
      sessionStorage.setItem('MprCurrentUser', JSON.stringify(user));
      dispatch(WebRedux.Auth.Actions.setLoginPayload(undefined));
    }
  }, [loginState, user])

  return (
    <Wrapper>
      <Container>
        <LeftContainer sx={{ display: { xs: 'none', lg: 'block' } }}>
          <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
            <LeftContent>
              <TopContent>
                <MProfitIcon />
                <Header>Manage your investments the
                  <SpanText component="span"> right way
                  </SpanText>
                </Header>
              </TopContent>
              <Box sx={{ minWidth: '257px', minHeight: '283px' }}>
                <ChartIcons />
              </Box>
            </LeftContent>
          </Box>
        </LeftContainer>
        <RightContainer>
          <Content>
            {(() => {
              switch (activeIndex) {
                case AuthPage.LoginForm:
                  return <LoginForm />
                case AuthPage.OTPVerification:
                  return <OTPVerification />
                case AuthPage.PasswordResetCodeVerification:
                  return <OTPVerification isPasswordResetCodeVerification={true} />
                case AuthPage.ForgotPassword:
                  return <ForgotPassword />
                case AuthPage.ForgotPasswordWithResetCode:
                  return <ForgotPasswordWithResetCode />
                case AuthPage.SetNewPassword:
                  return <SetNewPassword />
                case AuthPage.AllDone:
                  return <AllDone />
                default:
                  return null
              }
            })()}
          </Content>
        </RightContainer>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled(Box)({
  background: '#12131A',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column'
});

const Container = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
});

const RightContainer = styled(Box)({
  flex: '1 1 60%',
  display: 'flex',
  backgroundImage: `url(${DottedBackground})`,
  backgroundPosition: 'center bottom 40px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '95%'
});

const LeftContainer = styled(Box)({
  flex: '1 1 40%',
});

const TopContent = styled(Box)({
  flex: '1 1 100%'
});

const SpanText = styled(Box)({
  color: '#5F854C',
  fontWeight: 500,
  position: 'relative',
  ':after': {
    content: `''`,
    position: 'absolute',
    bottom: -15,
    left: 3,
    border: '2px solid #5F854C',
    opacity: '0.7',
    transform: 'matrix(1, -0.03, -10, 1, 0, 0)',
    width: '100%'
  }
});

const LeftContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxHeight: '73%',
  minHeight: '550px',
  height: '67%',
  minWidth: '450px',
  width: '53%',
  marginLeft: 'auto'
});

const Header = styled(Typography)({
  color: "#FFFFFF",
  fontSize: '36px',
  lineHeight: '48px',
  fontWeight: '400',
  paddingTop: '48px'
});

const Content = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});