import { Box, styled } from "@mui/material";
import styles from "./index.module.css";

// Custom Component
import ButtonPrimaryCTA from "../../../shared/button-primary-cta";

// Icons and Img
import userProfileIcon from "../../../../assets/icons/userProfileIcon.svg";
import { HelpIcon } from "../../../../assets/icons/HelpIcon";
import { SupportIcon } from "../../../../assets/icons/SupportIcon";
import { ClipBoardIcon } from "../../../../assets/icons/ClipBoardIcon";
import { DownArrowSolidIcon } from "../../../../assets/icons/DownArrowSolidIcon";
import { ImportFileIcon } from "../../../../assets/icons/ImportFileIcon";
import { ReactComponent as OpenInNewIcon } from '../../../../assets/icons/OpenInNewIcon.svg'

import GlobalSearchBar from "../../../shared/global-search-bar.tsx";
import ProfileMenu from "../profile-menu";
import { CustomTypography } from "../../../shared";
import ButtonText from "../../../shared/button-text";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { useAppSelector } from "packages/web/src/redux/hooks";

export default function DashboardMainHeader({isPlaceholder} : {isPlaceholder?: boolean}) {
  if (isPlaceholder) {
    return <Box className={styles.container} sx={{height: '48px'}}></Box>
  } else {
    return (
      <Box className={styles.container}>
        {/* Search */}
        <Box sx={{ display: 'flex', flex: '1 1 100%' }}>
          {/* <SearchBar searchOptions={[{ title: "hello", year: 1999 }, { title: "hey", year: 1909 }]} /> */}
          <GlobalSearchBar />
        </Box>

        {/*right hand component */}
        <Box className={styles.rightComponent}>
          {/* <Box className={styles.navBarIcons}>
            <HelpIcon />
          </Box> */}
          {/* <Box className={styles.navBarIcons}>
            <SupportIcon />
          </Box> */}
          {/* <Box className={styles.navBarIcons}>
            <ClipBoardIcon />
          </Box> */}
          {/* <Box className={styles.importBtn}>
            <ButtonPrimaryCTA
              startIcon={<ImportFileIcon />}
              buttonText={"Import"}
              endIcon={<DownArrowSolidIcon />}
            />
          </Box> */}
          {/* <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#5F6F57',
              borderRadius: '32px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '7px',
              paddingBottom: '7px'
          }}>
              <CustomTypography variant={'Regular14'} sx={{color: '#fff'}}>
                  BETA
              </CustomTypography>
          </Box> */}
          <OpenOldAppButton/>
          <ProfileMenu />
        </Box>
      </Box>
    );
  }
}

const OpenOldAppButton = () => {
  const user = useAppSelector(SharedRedux.Login.Selectors.selectUser);
  const onOpenOldAppClick = () => {
    if (user) {
      openOldApp(user);

      if (document.activeElement instanceof HTMLElement)
        document.activeElement.blur();
    }
  }

  return <Box>
    <ButtonText buttonText="Classic View" startIcon={<OpenInNewIcon/>} handleClick={onOpenOldAppClick}/>
  </Box>
}

const openOldApp = (user: SharedConstants.User) => {
  var url = process.env.REACT_APP_CLOUD_APP_URL_REDIRECT || 'https://cloud.mprofit.in/redirect';
  if (user) {
    if (window.opener && !window.opener.closed) {
      var isOpenedFromClassic = sessionStorage.getItem('isOpenedFromClassic') === '1';

      if (isOpenedFromClassic) {
        alert("Classic View is already open in another tab. Please click on the tab to open it again.")
        return;
      }
    }
      var win: any = window.open(url, "redirect_cloud_mprofit_in_" + user.email);
      console.log(win, 'winOldApp')
      var count = 0;
      var redirectInterval = setInterval(() => {
          win.postMessage({
              email: user.email,
              access_token: user.access_token,
              customerId: user.customerId,
              is_admin: user.is_admin,
              token_type: user.token_type,
              userName: user.userName,
              isActive: user.isActive,
              isMFAEnabled: user.isMFAEnabled
          }, url);
          count++;
          if (count > 10) {
              clearInterval(redirectInterval);
          }              
      }, 1000);
  }
}
