import { PriceValuesByAMID } from "./portfolioSummary";
import { Big } from 'big.js';

export interface WatchlistWithLoading {
    IsLoading: boolean;
    Watchlist?: Watchlist;
    WatchlistPriceURLs?: string[];
    WatchlistPriceValues?: PriceValuesByAMID;
}

export interface Watchlist {
    ID: string;
    CustomerID: number;
    UserID: number;
    ATTY: number;
    WatchlistName: string;
    WatchlistType: WatchlistTypeEnum;
    Items: WatchlistItem[];
}

export interface WatchlistSearch {
    IsLoading: boolean;
    Term?: string;
    Items?: APIAssetSearchItem[];
}

export interface APIAssetSearchItem {
    id: number;
    text: string;
}

export interface WatchlistItem {
    MProfitCode: number;
    AssetName: string;
    IsPinned?: boolean;
}

export interface WatchlistEditItem {
    MProfitCode: number;
    IsPinned?: boolean;
}

export enum WatchlistTypeEnum {
    All = -2,
    None = -1,
    UserDefined = 0,
    SystemStocks = 1,
    SystemIndices = 2,
    PerformanceIndices = 3
}

export interface IndexCardItem {
    IndexName: string;
    IndexValue: Big;
    TGain: Big;
    TGainPct: Big;
    TUp: boolean
}


export interface WatchlistCardItem {
    MProfitCode: number;
    AssetName: string;
    Price?: Big;
    TGain?: Big;
    TGainPct?: Big;
    TUp?: boolean
}