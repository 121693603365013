
export function TaxSoftFormatIcon(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
            <g id="Group_152993" data-name="Group 152993" transform="translate(-920 -138)">
                <rect id="Rectangle_24810" data-name="Rectangle 24810" width="64" height="64" transform="translate(920 138)" fill="#fff" opacity="0" />
                <g id="man-creating-a-nft" transform="translate(920.999 145)">
                    <path id="Path_207095" data-name="Path 207095" d="M126.879,96.256H72.117A3.91,3.91,0,0,1,68.2,92.339V60.517A3.91,3.91,0,0,1,72.117,56.6h54.763a3.91,3.91,0,0,1,3.917,3.917V92.3A3.94,3.94,0,0,1,126.879,96.256Z" transform="translate(-68.2 -56.6)" fill="#fff" stroke="#000" strokeWidth="0.289" />
                    <path id="Path_207096" data-name="Path 207096" d="M68.2,151.9v2.378a3.91,3.91,0,0,0,3.917,3.917h54.763a3.91,3.91,0,0,0,3.917-3.917V151.9Z" transform="translate(-68.2 -118.574)" fill="#acc18a" stroke="#192752" strokeWidth="0.289" />
                    <path id="Path_207097" data-name="Path 207097" d="M139.267,69.3,127,76.371V90.6l12.267,7.113L151.576,90.6V76.371Z" transform="translate(-107.992 -66.355)" fill="#485d3e" />
                    <path id="Path_207098" data-name="Path 207098" d="M140.932,72.7,129.9,79.047V91.825l11.032,6.389,11.074-6.389V79.047Z" transform="translate(-109.657 -68.306)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.289" />
                    <rect id="Rectangle_25223" data-name="Rectangle 25223" width="9.896" height="8.777" transform="translate(26.332 39.656)" fill="#acc18a" stroke="#000" strokeWidth="0.289" />
                    <rect id="Rectangle_25224" data-name="Rectangle 25224" width="19.723" height="1.364" transform="translate(21.436 48.433)" fill="#acc18a" stroke="#000" strokeWidth="0.289" />
                    <path id="Path_207100" data-name="Path 207100" d="M153.4,170h-9.9v3.252Z" transform="translate(-117.168 -130.344)" fill="#090b00" />
                    <path id="Path_207101" data-name="Path 207101" d="M435.719,1142.139s-.034,1.2,0,1.34,1.185.051,1.306.154-.12,4.5,0,4.638a2.429,2.429,0,0,0,1.357,0c.12-.12.017-4.69.137-4.741s1.082.1,1.237-.086.189-1.15,0-1.271A17.651,17.651,0,0,0,435.719,1142.139Zm12.089,2.25s.5.628.481.812-1.615,2.864-1.495,3.019a3.6,3.6,0,0,0,1.7.148c.189-.114,1.014-2.674,1.134-2.674s.567,2.616.825,2.674a3.529,3.529,0,0,0,1.546-.148c.069-.155-1.22-2.86-1.2-3.019s.979-2.5,1.014-2.754-1.58-.257-1.666-.1a19.3,19.3,0,0,1-.749,1.907c-.11.017-1.312-1.98-1.467-2.012s-1.271,0-1.357.26S447.808,1144.39,447.808,1144.39Zm-3.439-2.027a2.747,2.747,0,0,0-1.89,0c-.137.189-1.254,6.006-1.254,6.006.026.182,1.4.075,1.46,0s.129-1.428.206-1.5.773-.017.79.086.082,1.271.133,1.411a2.4,2.4,0,0,0,1.379,0A43.7,43.7,0,0,0,444.369,1142.363Zm-1.479,3.208a6.978,6.978,0,0,1,.464-1.612c.179-.138.372,1.46.372,1.612s-.836,0-.836,0Z" transform="translate(-412.558 -1128.102)" fill="#fff" />
                </g>
            </g>
        </svg>
    );
}