export function DownArrow(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g id="Group_150506" data-name="Group 150506" transform="translate(-252 -151)">
        <rect id="Rectangle_24186" data-name="Rectangle 24186" width="40" height="40" transform="translate(252 151)" fill="#fff" opacity="0" />
        <path id="arrow-ios-downward-outline" d="M15.5,21.5a1.142,1.142,0,0,1-.731-.263L7.911,15.521a1.144,1.144,0,0,1,1.462-1.759L15.5,18.88l6.123-4.935a1.171,1.171,0,1,1,1.451,1.839L16.215,21.3A1.142,1.142,0,0,1,15.5,21.5Z" transform="translate(256.502 153.502)" fill="#fff" />
      </g>
    </svg>

  );
}