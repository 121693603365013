import { useEffect, useState } from 'react'

import { AddIcCallOutlined } from '@mui/icons-material'

import Big from 'big.js'

import styles from './index.module.css'
import PillsButton from '../pills-button'
import ButtonPrimaryCTA from '../button-primary-cta'
import { decimal, percent } from '../utilities/numFormat'
import greenArrowUp from '../../../assets/icons/greenArrowUp.svg'
import redArrowDown from '../../../assets/icons/redArrowDown.svg'
import { ZigzagArrowDown } from '../../../assets/icons/zigzagArrowDown'
import { ZigzagArrowUp } from '../../../assets/icons/zigzagArrowUp'
import { ReactComponent as LeaderboardCastle } from '../../../assets/icons/leaderboardCastle.svg'
import leaderBoardImg from '../../../assets/graphics/leaderBoardImage.svg'
import { NoLosersToday } from '../../../assets/icons/NoLosersToday'
import { NoGainersToday } from '../../../assets/icons/NoGainersToday'
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material'
import CustomTypography from '../customTypography'
import { useAppSelector } from 'packages/web/src/redux/hooks'
import { SharedConstants, SharedRedux, formatDecimal, formatPercent } from '@mprofit/shared'
import LossGainBox from '../loss-gain-box'
import { NavigateToAsset } from 'packages/web/src/routes/navigationMethods'
import { useLocation, useNavigate } from 'react-router-dom'

interface leaderBoardProps {
    Gainers?: {
        id: number,
        AssetName: string
        AssetSymbol: string
        CurrentValue: Big
        TGain: Big
        TGainPct: Big
        TUp: boolean
    }[],
    Losers?: {
        id: number,
        AssetName: string
        AssetSymbol: string
        CurrentValue: Big
        TGain: Big
        TGainPct: Big
        TUp: boolean
    }[]
}

const LeaderBoard = () => {

    const GainersLosers = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectLeaderboardGainersLosers);

    const { Gainers, Losers } = GainersLosers || { Gainers: [], Losers: [] };

    const [tableShow, setTableShow] = useState("Gainers")

    const handleClick = (tableType: string) => {
        setTableShow(tableType)
    }
    return (
        <Box sx={style.container} >
            <Box className={styles.header}>
                <Box className={styles.heading}>
                    <LeaderboardCastle />
                    <CustomTypography variant='Medium24'>Today's Movers</CustomTypography>
                </Box>

                {GainersLosers && <Box className={styles.btnContainer}>
                    <PillsButton
                        handleClick={handleClick}
                        startIcon={<ZigzagArrowUp />}
                        buttonLabel={"Top Gainers"}
                        optionType={"Gainers"}
                        activeOptionType={tableShow}
                        height="48px"
                    />
                    <PillsButton
                        handleClick={handleClick}
                        startIcon={<ZigzagArrowDown />}
                        buttonLabel={"Top Losers"}
                        optionType={"Losers"}
                        activeOptionType={tableShow}
                        height="48px"
                    />
                </Box>
                }
            </Box>
            {
                GainersLosers ?
                    <Box className={styles.leaderBoradContainer}>
                        <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 12px' }}>
                            <TableBody sx={{ borderSpacing: '0' }}>
                                {
                                    tableShow === "Gainers" && Gainers && Gainers.length > 0 ? Gainers.map((item: SharedConstants.AMIDAssetTotal, index) => (
                                        <GainerLoserRow item={item} index={index} key={index}/>
                                    ))

                                        : tableShow === "Gainers" && <Box className={styles.infoContainer}>
                                            {/* <img src={NoLosersToday} alt="img" /> */}
                                            <NoGainersToday />
                                            <h4 className={styles.infoContainerHeading}>No Gainers today</h4>
                                            <p className={styles.infoContainerSubHeading}>Hmm... looks like there are no gainers today. No worries, this too shall pass.</p>

                                        </Box>
                                }
                            </TableBody>
                        </Table>
                        <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 12px' }}>
                            <TableBody>
                                {
                                    tableShow === "Losers" && Losers && Losers.length > 0 ? Losers.map((item, index) => (
                                        <GainerLoserRow item={item} index={index} key={index}/>
                                    )) : tableShow === "Losers" && <Box className={styles.infoContainer}>
                                        {/* <img src={NoLosersToday} alt="img" /> */}
                                        <NoLosersToday />
                                        <h4 className={styles.infoContainerHeading}>No losers today</h4>
                                        <p className={styles.infoContainerSubHeading}>Great going! The market appears favorable today.</p>

                                    </Box>

                                }
                            </TableBody>
                        </Table>
                    </Box>
                    :
                    <Box className={styles.infoContainer}>
                        <img src={leaderBoardImg} alt="img" />
                        <h4 className={styles.infoContainerHeading}>No Stocks in your portifolio</h4>
                        <p className={styles.infoContainerSubHeading}>Add stocks in your portfolio to track the daily performance of your stocks</p>
                        {/* <ButtonPrimaryCTA

                            buttonText={'Default'}
                            startIcon={<AddIcCallOutlined />}
                        /> */}
                    </Box>
            }

        </Box >

    )
}

export default LeaderBoard;

const GainerLoserRow = ({ item, index }: { item: SharedConstants.AMIDAssetTotal, index: number }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

    const onItemClick = () => {
        if (item && item.AssetTypeID && item.AssetTypeID > 0 && item.AMID > 0) {
            NavigateToAsset({ navigate, activeScreenIDs, assetTypeID: item.AssetTypeID, AMID: item.AMID, SID: item.SID, pathname: location.pathname });
        }
    }
    return (
        <TableRow key={index} sx={{ outline: "1px solid #EBECF2" }}>
            <TableCell scope="row" sx={{ width: '60%', padding: '16px', paddingRight: '5px', verticalAlign: 'center', borderBottom: 'none', '& :hover': { cursor: 'pointer', fontWeight: 500, color: '#5F854C' } }} onClick={onItemClick}>
                <Box>
                    <CustomTypography variant='Regular20'>{item.Name}</CustomTypography>
                    <CustomTypography variant="Regular16" sx={{ color: { xs: "#64677A" } }}></CustomTypography>
                </Box>
            </TableCell>
            <TableCell align="right" sx={{ width: '20%', padding: '16px 5px', verticalAlign: 'center', borderBottom: 'none' }}>
                <CustomTypography variant="Regular18">{formatDecimal(item.CurrValue, 2, 'IN')}</CustomTypography>
            </TableCell>
            <TableCell align="right" sx={{ width: '20%', padding: '16px', paddingLeft: '5px', borderBottom: 'none' }}>
                <CustomTypography variant='Medium18' sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: { xs: item.TUp ? "#5F854C" : "#C94C40" } }}>
                    <img
                        height="18"
                        src={item.TUp ? greenArrowUp : redArrowDown}
                        alt="arrow">
                    </img>
                    {formatPercent(item.TGainPct, 2, 'IN')}
                </CustomTypography>
                <CustomTypography variant='Medium14' sx={{ color: { xs: item.TUp ? "#5F854C" : "#C94C40" } }} >({item.TUp ? "+" : ""}{formatDecimal(item.TGain, 2, 'IN')})</CustomTypography>
            </TableCell>
        </TableRow>
    )
}

const style = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: 0
    }
}