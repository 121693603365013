import { useNumberFormatPrefs } from '../../hooks/userPreferencesHooks';
import { FormatAmountForTooltipUsingPrefs, FormatAmountUsingPrefsHelper, FormatGainUsingPrefsHelper } from '@mprofit/shared';
import TooltipDark from '../tooltip-dark';


export const NumberWithTooltip = ({DefaultDecimalPlaces, Value, IsGain = false} : {DefaultDecimalPlaces: number, Value: any, IsGain?: boolean}) => {
    const numberFormatPrefs = useNumberFormatPrefs();
    const {FormattedValue, IsNumberInLakhsMillions} = IsGain ? FormatGainUsingPrefsHelper(numberFormatPrefs, Value, DefaultDecimalPlaces) : FormatAmountUsingPrefsHelper(numberFormatPrefs, Value, DefaultDecimalPlaces);
    if (IsNumberInLakhsMillions) {
        return (
            <TooltipDark
                // componentsProps={{
                //     tooltip: {
                //         sx: {
                //             color: "#FFFFFF",
                //             backgroundColor: '#010006',
                //         },
                //     }
                // }}
                title={FormatAmountForTooltipUsingPrefs(numberFormatPrefs, Value, DefaultDecimalPlaces)}>
                <span>{FormattedValue}</span>
            </TooltipDark>
        )
    } else {
        return <span>{FormattedValue}</span>
    }
}

export const AmountWithTooltip = ({Value}: {Value: any}) => <NumberWithTooltip Value={Value} DefaultDecimalPlaces={2}/>;
export const GainWithTooltip = ({Value}: {Value: any}) => <NumberWithTooltip Value={Value} DefaultDecimalPlaces={2}/>;