import { Skeleton, SkeletonProps, styled } from '@mui/material'

export default function SkeletonLoader(props: SkeletonProps) {
    return (
        <Skeletons  {...props} animation={false} />
    )
}

const Skeletons = styled(Skeleton)({
    background: '#252833',
    borderRadius: '14px'
})