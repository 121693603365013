import { Box, Divider, Grid } from "@mui/material";
import { SharedConstants } from "@mprofit/shared";
import CustomTypography from "../customTypography";

interface IBulletProps {
  data: SharedConstants.PieChartData[];
  onItemClick?: (item: SharedConstants.PieChartData) => void;
}
const BottomLegends = ({ data, onItemClick }: IBulletProps) => {
  return (
    <Grid
      container
      sx={{ display: "flex", width: "100%" }}
    >
      <Grid
        item
        xs={12}
        flex="1 1 100%"
        // margin="10px 0px"
        marginTop="10px"
        marginBottom="16px"
      >
        <Divider sx={{ width: "100%", borderColor: '#E6E7EA' }} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ padding: "0px 35px", width: "100%" }}
      >
        <Grid
          container
          sx={{ width: "100%" }}
          rowGap={1}
        >
          {data &&
            data.map((item: any, index: any) => (
              <Grid
                item
                xs={6}
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  width: "100%",
                  cursor: onItemClick ? "pointer" : "default",
                  "&:hover": {
                    opacity: "0.5",
                  },
                }}
                onClick={
                  onItemClick ? () => onItemClick(item.payload) : undefined
                }
              >
                <Box
                  sx={{
                    backgroundColor: item.color,
                    borderRadius: "50%",
                    minWidth: "8px",
                    maxWidth: "8px",
                    width: "8px",
                    height: "8px",
                    marginRight: "5px",
                  }}
                />
                <CustomTypography
                  variant="Regular18"
                  sx={{
                    color: { xs: "#64677A" },
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.value}
                </CustomTypography>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BottomLegends;
