export function DownloadIconInButton(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_149749" data-name="Group 149749" transform="translate(-86)">
            <g id="Rectangle_23766" data-name="Rectangle 23766" transform="translate(86)" fill="#64677a" stroke="#64677a" stroke-width="1" opacity="0">
                <rect width="24" height="24" stroke="none"/>
                <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
            </g>
            <g id="Group_229370" data-name="Group 229370" transform="translate(86)">
                <path id="Path_272379" data-name="Path 272379" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_272380" data-name="Path 272380" d="M4,17v2a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V17" fill="none" stroke="#64677a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_272381" data-name="Path 272381" d="M7,11l5,5,5-5" fill="none" stroke="#64677a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_272382" data-name="Path 272382" d="M12,4V16" fill="none" stroke="#64677a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
            </g>
        </svg>
    );
}