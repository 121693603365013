export function ClosingBalanceLargeIcon(props: any) {
 return (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="144.072" height="135.617" viewBox="0 0 144.072 135.617">
   <g id="Group_154226" data-name="Group 154226" transform="translate(0.25 0.25)" style={{ mixBlendMode: 'luminosity', isolation: 'isolate' }}>
    <g id="trading-website">
     <path id="Path_207186" data-name="Path 207186" d="M392.67,259.185c-2.779,0-5.039-2.859-5.039-6.374V130.443c0-3.515,2.26-6.374,5.039-6.374H526.163c2.779,0,5.039,2.859,5.039,6.374V252.812c0,3.515-2.261,6.374-5.039,6.374H392.67Z" transform="translate(-387.63 -124.069)" fill="#fff" stroke="#253326" strokeWidth="0.5" />
     <g id="Group_154224" data-name="Group 154224" transform="translate(6.676 46.182)">
      <path id="Path_235009" data-name="Path 235009" d="M308.087,254.091H182.23c-.135,0-.245-.215-.245-.481s.11-.481.245-.481H308.087c.135,0,.245.215.245.481S308.222,254.091,308.087,254.091Z" transform="translate(-181.985 -252.916)" fill="#cfe3c9" />
      <path id="Path_235010" data-name="Path 235010" d="M308.087,294.091H182.23c-.135,0-.245-.215-.245-.481s.11-.481.245-.481H308.087c.135,0,.245.215.245.481S308.222,294.091,308.087,294.091Z" transform="translate(-181.985 -273.673)" fill="#cfe3c9" />
      <path id="Path_235011" data-name="Path 235011" d="M308.087,334.091H182.23c-.135,0-.245-.216-.245-.481s.11-.481.245-.481H308.087c.135,0,.245.215.245.481S308.222,334.091,308.087,334.091Z" transform="translate(-181.985 -294.431)" fill="#cfe3c9" />
      <path id="Path_235012" data-name="Path 235012" d="M308.087,374.091H182.23c-.135,0-.245-.215-.245-.481s.11-.481.245-.481H308.087c.135,0,.245.215.245.481S308.222,374.091,308.087,374.091Z" transform="translate(-181.985 -315.189)" fill="#cfe3c9" />
      <path id="Path_235014" data-name="Path 235014" d="M303.577,331.578a.481.481,0,0,1-.481-.478l-.481-77.931a.481.481,0,0,1,.478-.484h0a.481.481,0,0,1,.481.478l.481,77.931a.482.482,0,0,1-.478.484Z" transform="translate(-244.578 -252.685)" fill="#cfe3c9" />
      <path id="Path_235018" data-name="Path 235018" d="M234.213,274.324H215.881a5.266,5.266,0,1,1,0-10.531h18.332a5.266,5.266,0,1,1,0,10.531Z" transform="translate(-196.835 -258.449)" fill="#5f854c" />
      <path id="Path_235019" data-name="Path 235019" d="M367.213,313.324H348.88a5.265,5.265,0,1,1,0-10.531h18.332a5.265,5.265,0,1,1,0,10.531Z" transform="translate(-265.854 -278.688)" fill="#5f854c" />
      <path id="Path_235020" data-name="Path 235020" d="M367.213,356.324H348.88a5.266,5.266,0,0,1,0-10.531h18.332a5.266,5.266,0,0,1,0,10.531Z" transform="translate(-265.854 -301.003)" fill="#5f854c" />
     </g>
     <path id="Path_207251" data-name="Path 207251" d="M373.652,128.285v10.473c0,2.319-2.306,4.2-5.149,4.2H248.852c-2.844,0-5.15-1.88-5.15-4.2V128.285c0-2.319,2.306-4.2,5.15-4.2H368.5C371.348,124.087,373.652,125.968,373.652,128.285Z" transform="translate(-237.023 -111.4)" fill="#253326" />
     <g id="Group_153079" data-name="Group 153079" transform="translate(122.471 99.438)">
      <path id="Path_207249" data-name="Path 207249" d="M472.638,161.53,470.169,164l6.086,6.086-3.22,3.22-6.086-6.086-2.469,2.469-3.042-11.2Z" transform="translate(-461.188 -158.238)" fill="#485d3e" />
      <path id="Path_207250" data-name="Path 207250" d="M472.758,173.381l-5.91-5.909-2.292,2.292a.25.25,0,0,1-.417-.111l-3.042-11.2a.25.25,0,0,1,.306-.306l11.2,3.041a.249.249,0,0,1,.111.417L470.42,163.9l5.91,5.911a.249.249,0,0,1,0,.352l-3.22,3.22A.249.249,0,0,1,472.758,173.381Zm-5.733-6.438,5.91,5.909,2.867-2.867-5.91-5.911a.249.249,0,0,1,0-.352l2.165-2.165-10.365-2.815,2.815,10.364,2.165-2.164A.249.249,0,0,1,467.024,166.942Z" transform="translate(-461.087 -158.138)" fill="#485d3e" />
     </g>
    </g>
   </g>
  </svg>

 );
}