export function NCD(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226554" data-name="Group 226554" transform="translate(-17890 6539)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" fill="#fff" opacity="0" />
          <g id="Group_222247" data-name="Group 222247" transform="translate(5.095 3.821)">
            <path id="Path_237160" data-name="Path 237160" d="M14.362,10.139V8.414a2.219,2.219,0,0,0-2.219-2.219h-.986a.74.74,0,0,1-.74-.74V4.469A2.219,2.219,0,0,0,8.2,2.25H6.965m1.479,0H5.24a.74.74,0,0,0-.74.74V14.331a.74.74,0,0,0,.74.74h8.383a.74.74,0,0,0,.74-.74V8.167A5.917,5.917,0,0,0,8.445,2.25Z" transform="translate(-4.5 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <path id="Path_237161" data-name="Path 237161" d="M9,12.243l2.918-2.918L12.993,8.25m-3.494.5H9.5v.005H9.5Zm.25,0A.25.25,0,1,1,9.5,8.5.25.25,0,0,1,9.749,8.749Zm2.745,2.995H12.5v.005h-.005Zm.25,0a.25.25,0,1,1-.25-.25A.25.25,0,0,1,12.744,11.744Z" transform="translate(-6.066 -1.84)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          </g>
        </g>
      </g>
    </svg>

  );
}
