export function RcaMergerIcon(props: any) {
  return (
    <svg id="Group_150779" data-name="Group 150779" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <rect id="Rectangle_24213" data-name="Rectangle 24213" width="48" height="48" rx="8" fill="#f5f5f6" />
      <g id="arrows-collapse" transform="translate(13.915 12)">
        <path id="Path_198205" data-name="Path 198205" d="M4.5,14.494a.872.872,0,0,1,.869-.875H24.492a.875.875,0,0,1,0,1.749H5.369A.872.872,0,0,1,4.5,14.494ZM14.931,2.25a.872.872,0,0,1,.869.875V11a.869.869,0,1,1-1.738,0V3.125A.872.872,0,0,1,14.931,2.25Z" transform="translate(-4.5 -2.25)" fill="#12131a" fillRule="evenodd" />
        <path id="Path_198206" data-name="Path 198206" d="M20.813,8.13a.878.878,0,0,1,0,1.238l-3.477,3.5a.865.865,0,0,1-1.231,0l-3.477-3.5a.88.88,0,0,1,0-1.238.867.867,0,0,1,1.231,0l2.862,2.88,2.862-2.881a.865.865,0,0,1,1.231,0ZM16.721,27.99a.872.872,0,0,0,.869-.875V19.244a.869.869,0,1,0-1.738,0v7.871A.872.872,0,0,0,16.721,27.99Z" transform="translate(-6.29 -3.502)" fill="#12131a" fillRule="evenodd" />
        <path id="Path_198207" data-name="Path 198207" d="M20.813,26.366a.878.878,0,0,0,0-1.238l-3.477-3.5a.865.865,0,0,0-1.231,0l-3.477,3.5a.88.88,0,0,0,0,1.238.867.867,0,0,0,1.231,0l2.862-2.88,2.862,2.881a.865.865,0,0,0,1.231,0Z" transform="translate(-6.29 -6.507)" fill="#12131a" fillRule="evenodd" />
      </g>
    </svg>
  );
}