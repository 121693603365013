import { CompleteChartPage, PdfXIRRSnapshot } from "@mprofit-soft/pdf";
import { DownloadPDF, ExtraHeaderProps, ExtraOverviewProps } from "../../download-pdf";
import { ChartsSelectors } from "packages/web/src/redux/selectors/chartsSelectors";
import { usePDFNumberFormatPrefs, usePDFSelector } from "../../download-pdf/redux";
import { Box } from "@mui/material";
import { SharedUtilities } from "@mprofit/shared";

export const XIRRCardPage = ({ headerProps, overviewProps}: {
        headerProps: ExtraHeaderProps;
        overviewProps: ExtraOverviewProps;
    }) => {
    const { XIRRDisplay, performanceXIRRData, isPerformancePeriodAllToDate, periodText } = usePDFSelector(ChartsSelectors.selectXIRRCardData);
    const numberFormatPrefs = usePDFNumberFormatPrefs();

    if (!XIRRDisplay || !performanceXIRRData) {
        return <></>;
    }

    return <CompleteChartPage
        headerProps={{
        ...headerProps,
        reportType: "Performance"
        }}
        overviewProps={{
        ...overviewProps,
        heading: "Performance Snapshot"
        }}
        ChartComponent={(extraChartProps) => 
        <PdfXIRRSnapshot
            {...extraChartProps}
            {
            ...{
                PeriodText: periodText,
                XIRRDisplay: XIRRDisplay || '',
                XIRR: performanceXIRRData.XIRR,
                OpeningValuation: performanceXIRRData.OpeningValuation,
                ClosingValuation: performanceXIRRData.ClosingValuation,
                TotalInvestment: performanceXIRRData.TotalInvestment,
                TotalWithdrawal: performanceXIRRData.TotalWithdrawal,
                TotalIncome: performanceXIRRData.TotalIncome,
                TotalGain: performanceXIRRData.TotalGain,
                TotalGainPct: performanceXIRRData.TotalGainPct,
                RealisedGain: performanceXIRRData.RealisedGain,
                UnrealisedGain: performanceXIRRData.UnrealisedGain,
                FirstTransDate: performanceXIRRData.FirstTransDate ? SharedUtilities.convertISTDateToSameDateUTC(performanceXIRRData.FirstTransDate) : undefined,
                IsPerformancePeriodAllToDate: isPerformancePeriodAllToDate,
                NumberFormatPrefs: numberFormatPrefs
            }
            }
        />}
    />
}

export const XIRRCardPDFDownloadButton = ({
        IsHideIcon,
        IsDownloadOnMount,
        OnDownloadComplete
    }: {
        IsHideIcon?: boolean,
        IsDownloadOnMount?: boolean;
        OnDownloadComplete?: () => void;
    }) => {
    return <Box>
        <DownloadPDF
            PDFPage={
                XIRRCardPage
            }
            ReportNameForFileName="Performance Snapshot"
            IsHideIcon={IsHideIcon}
            IsDownloadOnMount={IsDownloadOnMount}
            OnDownloadComplete={OnDownloadComplete}
        />
    </Box>
}