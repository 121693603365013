
export default function CurrentPriceCard(props: any) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_151098" data-name="Group 151098" transform="translate(-1408 -238)">
                <rect id="Rectangle_24225" data-name="Rectangle 24225" width="48" height="48" rx="8" transform="translate(1408 238)" fill="#f5f5f6" />
                <path id="_8988853dc2c7377256d1b11dba51ad61" data-name="8988853dc2c7377256d1b11dba51ad61" d="M345.495-180.585l-3.538-4.473a3.809,3.809,0,0,0-2.43-1.4v-2.306a.539.539,0,0,0-.538-.538.539.539,0,0,0-.538.538v2.306a3.738,3.738,0,0,0-2.425,1.4l-3.548,4.478A2.24,2.24,0,0,0,332-179.2v13.435a2.2,2.2,0,0,0,2.21,2.194h9.543a2.2,2.2,0,0,0,2.22-2.183V-179.2A2.2,2.2,0,0,0,345.495-180.585Zm-6.505-.629a1.563,1.563,0,0,1,1.564,1.564,1.562,1.562,0,0,1-1.564,1.564,1.561,1.561,0,0,1-1.559-1.564A1.565,1.565,0,0,1,338.989-181.214Zm1.237,9.2h.554a.539.539,0,0,1,.538.538.539.539,0,0,1-.538.538h-.554a1.934,1.934,0,0,1-.538.936,2.049,2.049,0,0,1-1.161.591l1.6,1.516a.536.536,0,0,1,.022.758.536.536,0,0,1-.758.022l-2.564-2.419a.536.536,0,0,1-.167-.392.527.527,0,0,1,.538-.527h1.021a1,1,0,0,0,.7-.3c.005,0,.005-.011.005-.011a1.1,1.1,0,0,0,.134-.177h-1.871a.539.539,0,0,1-.538-.538.539.539,0,0,1,.538-.538h1.866a1,1,0,0,0-.844-.43h-1.022a.539.539,0,0,1-.538-.538.539.539,0,0,1,.538-.538h3.586a.539.539,0,0,1,.538.538.539.539,0,0,1-.538.538h-.742A1.234,1.234,0,0,1,340.226-172.015Z" transform="translate(1093.013 438.438)" />
            </g>
        </svg>
    )
}
