export function PE(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="performance-dropdown-assettype-icon">
      <g id="Group_226545" data-name="Group 226545" transform="translate(17896 -6533)">
        <g id="performance-dropdown-assettype-icon-fillelement" data-name="Rectangle 48909" transform="translate(-17896 6533)" fill="#253326" stroke="#fff" stroke-width="1">
          <rect width="32" height="32" rx="16" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="none" />
        </g>
        <g id="Group_226551" data-name="Group 226551" transform="translate(-17890 6539.336)">
          <rect id="Rectangle_24190" data-name="Rectangle 24190" width="20" height="20" transform="translate(0 -0.336)" fill="#fff" opacity="0" />
          <g id="noun-lock-1786558" transform="translate(4.302 1.844)">
            <path id="Path_198387" data-name="Path 198387" d="M246.8,134.51h-.947V132.8a3.658,3.658,0,1,0-7.315,0v1.713h-.947a1.126,1.126,0,0,0-1.125,1.125v8.217a1.126,1.126,0,0,0,1.125,1.125H246.8a1.126,1.126,0,0,0,1.125-1.125v-8.217a1.126,1.126,0,0,0-1.125-1.125Zm-4.6-4.527a2.817,2.817,0,0,1,2.814,2.814v1.713h-5.628V132.8a2.817,2.817,0,0,1,2.814-2.814Zm-4.886,5.652a.282.282,0,0,1,.281-.281H246.8a.282.282,0,0,1,.281.281v8.218a.282.282,0,0,1-.281.281h-9.209a.282.282,0,0,1-.281-.281Z" transform="translate(-236.464 -129.139)" fill="#fff" />
            <path id="Name" d="M3.67-5.026a.281.281,0,0,1-.19-.057A1.138,1.138,0,0,1,3.361-5.2l-1.9-2.108a.433.433,0,0,1-.1-.168.862.862,0,0,1-.022-.22v-.318a.212.212,0,0,1,.057-.15.2.2,0,0,1,.154-.062h.415a2.01,2.01,0,0,0,.855-.146.811.811,0,0,0,.41-.392,1.4,1.4,0,0,0,.11-.573,1.349,1.349,0,0,0-.11-.556.861.861,0,0,0-.392-.4,1.675,1.675,0,0,0-.794-.154H1.492a.212.212,0,0,1-.15-.057.2.2,0,0,1-.062-.154v-.326a.2.2,0,0,1,.062-.15.2.2,0,0,1,.15-.062H5.134a.2.2,0,0,1,.15.062.2.2,0,0,1,.062.15v.326a.2.2,0,0,1-.062.154.212.212,0,0,1-.15.057h-1.4l.018-.212a1.037,1.037,0,0,1,.67.534,1.952,1.952,0,0,1,.185.86,1.941,1.941,0,0,1-.22.926,1.622,1.622,0,0,1-.666.661,2.526,2.526,0,0,1-1.1.282l-.309.026.1-.5L4.675-5.317a.224.224,0,0,1,.071.132.139.139,0,0,1-.053.119.214.214,0,0,1-.132.04Zm-2.178-4a.2.2,0,0,1-.154-.062.212.212,0,0,1-.057-.15V-9.56a.2.2,0,0,1,.062-.154.212.212,0,0,1,.15-.057H5.134a.212.212,0,0,1,.15.057.2.2,0,0,1,.062.154l-.009.326a.212.212,0,0,1-.057.15.2.2,0,0,1-.154.062Z" transform="translate(2.416 18.588)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>

  );
}
