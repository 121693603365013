export function NoGainersToday(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="283" height="226" viewBox="0 0 283 226">
      <g id="Group_152105" data-name="Group 152105" transform="translate(-486 -43)">
        <rect id="Rectangle_24619" data-name="Rectangle 24619" width="283" height="226" transform="translate(486 43)" fill="none" />
        <g id="Group_152104" data-name="Group 152104" transform="translate(439.256 35.363)">
          <path id="Path_199097" data-name="Path 199097" d="M272.87,134.289c1.323,24.431-46.359,74.11-105.13,55.723-27.335-8.553-25.019-22.749-45.8-41.457-12.389-11.15-30.19-19.584-35.14-35.791-4.277-13.99-1.654-29.027,5.761-38.808,16.675-22,50.341-37.059,83.512-2.4s93.382-.276,96.8,62.736Z" transform="translate(22.12 14.712)" fill="#eaf4df" />
          <path id="Path_199098" data-name="Path 199098" d="M236.494,80.56c-4.56,8.154-14.549,9.275-30.553,7.725-12.035-1.168-23.009-2.071-35.047-8.645a67.445,67.445,0,0,1-19.96-16.861c-5.275-6.546-12.636-14.016-9.027-21.437,4.96-10.195,33.641-18.781,61.492-4.74,30.593,15.429,37.559,35.988,33.1,43.958Z" transform="translate(41.816 7.08)" fill="#eaf4df" />
          <path id="Path_199099" data-name="Path 199099" d="M239.723,84.934c-8.593,4.27-20-1.22-20-1.22s2.535-12.41,11.131-16.674,19.993,1.214,19.993,1.214-2.535,12.41-11.127,16.68Z" transform="translate(69.42 19.754)" fill="#eaf4df" />
          <path id="Path_199100" data-name="Path 199100" d="M57.258,109.856c11.227,6.741,27.334.51,27.334.51s-2.106-17.158-13.34-23.89-27.327-.517-27.327-.517,2.106,17.157,13.333,23.9Z" transform="translate(7.819 26.036)" fill="#eaf4df" />
          <path id="Path_199101" data-name="Path 199101" d="M223.233,55.2a4.7,4.7,0,1,0,4.691,4.7A4.7,4.7,0,0,0,223.233,55.2Z" transform="translate(69.004 16.176)" fill="#5f854c" />
          <path id="Path_199102" data-name="Path 199102" d="M92.53,74.48a5.287,5.287,0,1,0,5.278,5.287A5.282,5.282,0,0,0,92.53,74.48Z" transform="translate(23.001 22.931)" fill="#5f854c" />
          <path id="Path_199103" data-name="Path 199103" d="M66.117,60.931a4.406,4.406,0,1,1,4.4-4.406A4.4,4.4,0,0,1,66.117,60.931Z" transform="translate(14.054 15.096)" fill="#5f854c" />
          <path id="Path_199104" data-name="Path 199104" d="M192.752,74.938a2.056,2.056,0,1,1,2.053-2.056A2.054,2.054,0,0,1,192.752,74.938Z" transform="translate(59.249 21.651)" fill="#666af6" />
          <path id="Path_199105" data-name="Path 199105" d="M237.468,123.534a2.643,2.643,0,1,1,2.64-2.643A2.641,2.641,0,0,1,237.468,123.534Z" transform="translate(74.712 38.267)" fill="#ebecf2" />
          <path id="Path_199106" data-name="Path 199106" d="M65.383,124.772a3.818,3.818,0,1,1,3.812-3.819A3.815,3.815,0,0,1,65.383,124.772Z" transform="translate(14.002 37.878)" fill="#ebecf2" />
          <path id="Path_199107" data-name="Path 199107" d="M97.79,51.371a2.906,2.906,0,1,1,2.9-2.906A2.9,2.9,0,0,1,97.79,51.371Z" transform="translate(25.677 12.797)" fill="#ebecf2" />
          <path id="Path_199108" data-name="Path 199108" d="M202.179,52.18a3.231,3.231,0,1,1,3.226-3.231A3.228,3.228,0,0,1,202.179,52.18Z" transform="translate(62.141 12.853)" fill="#ebecf2" />
          <path id="Path_199109" data-name="Path 199109" d="M74.411,75.265a2.9,2.9,0,1,1-3.548-2.052A2.9,2.9,0,0,1,74.411,75.265Z" transform="translate(16.506 22.452)" fill="#ebecf2" />
          <path id="Path_199110" data-name="Path 199110" d="M127.29,38.071a3.86,3.86,0,1,1,3.853-3.86A3.856,3.856,0,0,1,127.29,38.071Z" transform="translate(35.68 7.469)" fill="#ebecf2" />
          <path id="Path_199111" data-name="Path 199111" d="M217.09,105.712a2.434,2.434,0,1,1,2.964-2.375A2.718,2.718,0,0,1,217.09,105.712Z" transform="translate(67.458 32.21)" fill="#ebecf2" />
          <path id="Path_199112" data-name="Path 199112" d="M182.309,29.528a6.028,6.028,0,1,1-7.383-4.27A6.04,6.04,0,0,1,182.309,29.528Z" transform="translate(52.156 5.611)" fill="#ebecf2" />
          <path id="Path_199113" data-name="Path 199113" d="M294.019,108.673h.077c.459,6.517,5.3,6.617,5.3,6.617s-5.335.1-5.335,7.635c0-7.53-5.335-7.635-5.335-7.635s4.837-.1,5.3-6.617ZM104.41,18.37h.134c.791,11.4,9.123,11.58,9.123,11.58s-9.188.183-9.188,13.361c0-13.178-9.189-13.361-9.189-13.361s8.329-.176,9.12-11.581Z" transform="translate(25.818 3.27)" fill="#ebecf2" />
          <path id="Path_199114" data-name="Path 199114" d="M224.1,71.364l-24.44-24.5a17.264,17.264,0,0,0-12.22-5.077H125.465A17.3,17.3,0,0,0,108.18,59.112V180.418a17.3,17.3,0,0,0,17.285,17.33h86.419a17.3,17.3,0,0,0,17.284-17.33v-96.8A17.361,17.361,0,0,0,224.1,71.364Z" transform="translate(30.334 11.474)" fill="#fff" stroke="#ebecf2" strokeWidth="4" fillRule="evenodd" />
          <path id="Path_199115" data-name="Path 199115" d="M128.93,86.616H182.27M128.93,110.821H182.27m-53.453,24.122h60.021" transform="translate(37.565 27.184)" fill="none" stroke="#ebecf2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10" />
          <path id="Path_199116" data-name="Path 199116" d="M168.208,142.231a35.586,35.586,0,1,1,71.172,0m0,0a35.586,35.586,0,1,1-71.172,0" transform="translate(51.368 34.18)" fill="#5f854c" fillRule="evenodd" />
          <path id="Path_199117" data-name="Path 199117" d="M82.556,107.029l24.531-2.71,9.465,16.045,12.394-3.589,24.76,51.607,7.639-3.573-2.793,20.8-17.832-11.1,7.447-3.456-23.416-45.314-12.578,2.941-8.517-14.314-21.2,4.716Z" transform="translate(21.321 33.387)" fill="#5f854c" />
          <path id="Path_199118" data-name="Path 199118" d="M186.134,124.975l-.022,7.643" transform="translate(57.642 40.625)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4" />
          <path id="Path_199119" data-name="Path 199119" d="M202.625,124.975l-.022,7.643" transform="translate(63.42 40.625)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4" />
          <path id="Path_199120" data-name="Path 199120" d="M185.641,143.275c9.754-8.451,18.27-4.907,23.542,0" transform="translate(57.477 45.252)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4" />
        </g>
      </g>
    </svg>
  );
}