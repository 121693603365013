import { Box, Grid, Stack } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, CartesianAxis } from "recharts";
import styles from "./index.module.css";
import { SharedConstants, SharedUtilities, formatPercent } from "@mprofit/shared";
import Lottie from "react-lottie";
import * as animationData from '../../../assets/animation/assetWisePerformanceAnimation.json'
import { DefaultTableIcon } from "packages/web/src/assets/icons/DefaultTableIcon";
import CustomTypography from "../customTypography";
import ButtonPrimaryCTA from "../button-primary-cta";
import { t } from "i18next";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const skeletonData: SharedConstants.BarChartTwoColumnData[] = [
{
  Name: "All to Date",
  Column1Value: -0.0794,
  Column2Value: 0.0372
}, {
  Name: "1 Year",
  Column1Value: 0.0501,
  Column2Value: 0.205
}, {
  Name: "2 Years",
  Column1Value: -0.0794,
  Column2Value: 0.0973
}, {
  Name: "3 Years",
  Column1Value: -0.0794,
  Column2Value: 0.2114
}, {
  Name: "5 Years",
  Column1Value: -0.0794,
  Column2Value: 0.1202
}]

interface ColumnChartProps {
  data?: any[],
  isLoading?: boolean,
  onRefresh?: () => void,
  disabled?: boolean
}

const PerformanceTwoColumnChart = ({ data, isLoading = true, onRefresh = () => { }, disabled = false }: ColumnChartProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Box className={styles.todayPerfomanceDoubleContainer}>
      <Box sx={{ width: '100%', minHeight: '450px', position: 'relative' }}>
        {
          data && !isLoading ?
            <TwoColChart data={data}/>
            :
            <Box sx={style.overLayContainer}>
              <Box sx={style.graphOverlay}>
                <Box sx={style.overlayItem}>
                  {
                    isLoading ?
                      <>
                        <Box sx={style.animationCont}>
                          <Lottie options={defaultOptions} height={256} width={256} />
                          <Box sx={style.animationStatic} >
                            <DefaultTableIcon />
                          </Box>
                        </Box>
                        <CustomTypography>{'Calculating your historical XIRR'}</CustomTypography>
                      </>
                      :
                      <Stack>
                        <DefaultTableIcon style={{ margin: "auto" }} />
                        <CustomTypography variant='Medium24' sx={{ marginTop: '40px' }}>{'View your historical XIRR performance'}</CustomTypography>
                        <CustomTypography variant='Regular18'>{'Track your annualized return across different time periods. You can view both historical (1 yr, 2 yr, 3 yr) XIRR and financial year-wise XIRR.'}</CustomTypography>
                        <Box paddingTop="17px">
                          <ButtonPrimaryCTA
                            disabled={disabled}
                            handleClick={() => onRefresh()}
                            startIcon={<RemoveRedEyeOutlinedIcon />}
                            buttonText={'Calculate'}
                          />
                        </Box>
                      </Stack>
                  }
                </Box>
              </Box>
              <Grid sx={{ width: '100%', height: '100%', position: 'relative' }}>
                <Box sx={style.blurOverlay}></Box>
                <TwoColChart data={skeletonData} hideLabels={true}/>
              </Grid>
            </Box >

        }
      </Box>
    </Box>
  );
};

const TwoColChart = ({data, hideLabels}: {data: SharedConstants.BarChartTwoColumnData[], hideLabels?: boolean}) => {
  return <ResponsiveContainer minHeight={400} width={'100%'}>
    <BarChart width={900} height={400} data={data} barGap={60} margin={{ top: 5, right: 30, left: 20, bottom: 35, }}>
      <XAxis tickLine={false} axisLine={false} dy={42} dataKey="Name" fontFamily="rubik" interval={0} stroke="#64677A" />
      <CartesianGrid stroke="#D8DAE5" vertical={false} opacity={"0.6"} />
      <YAxis orientation="left" stroke="#64677A" axisLine={{ stroke: '#EBECF2' }} domain={getAdvancedPerfChartDomain(data)} tickFormatter={(tick) => formatPercent(tick, 0, "IN")} tickLine={false}/>
      <Bar dataKey="Column1Value" fill="#ffb598" radius={[10, 10, 0, 0]} barSize={12} label={hideLabels === true ? undefined : (e) => Label(e)} />
      <Bar dataKey="Column2Value" fill="#b1dbff" radius={[10, 10, 0, 0]} barSize={12} label={hideLabels === true ? undefined : (e) => Label(e)} />
    </BarChart>
  </ResponsiveContainer>
}

export const getAdvancedPerfChartDomain = (data: SharedConstants.BarChartTwoColumnData[]) => {
  if (data) {
    var min = 0;
    var max = 0;

    data.forEach((item) => {
      min = Math.min(min, item.Column1Value || 0);
      min = Math.min(min, item.Column2Value || 0);
      max = Math.max(max, item.Column1Value || 0);
      max = Math.max(max, item.Column2Value || 0);
    });

    min = SharedUtilities.getBarChartDomainValue(min, true);
    max = SharedUtilities.getBarChartDomainValue(max, false);

    // if (max > 0 && min < 0) {
    //     if (Math.abs(min) > Math.abs(max)) {
    //         max = min * -1;
    //     } else if (Math.abs(min) < Math.abs(max)) {
    //         min = max * -1
    //     }
    // }
    return [min, max];
  }

  return [-1, 1]
}

const Label = (e: any) => {
  if (e.value === undefined) return <></>;
  
  return (
    <g>
      <foreignObject
        x={e.value > 0 ? e.x - 17 : e.x - 23}
        y={e.value > 0 ? e.y - 40 : e.y + 15}
        dy={-4}
        width={100}
        height={30}
      >
        <Box className={styles.subInfoGreenDouble}>
          <span className={styles.infoBoxGreenDouble}>{formatPercent(e.value, 2, "IN")}</span>
        </Box>
      </foreignObject>
    </g >
  );
};

export default PerformanceTwoColumnChart;

const style = {
  blurOverlay: {
    width: '91%',
    height: '100%',
    position: 'absolute',
    opacity: '0.9',
    backdropFilter: 'blur(7px)',
    zIndex: 1,
    left: '100px',
    bottom: '10%'
  },
  overLayContainer: {
    width: '100%',
    height: 'inherit',
    overflowY: 'hidden',
    // borderBottom: '1px solid #D8DAE5',
    position: 'relative',
    '::-webkit-scrollbar': {
      width: '1px'
    }
  },
  graphOverlay: {
    width: { xs: '70%', lg1: '80%', xl: '75%' },
    zIndex: 2,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  overlayItem: {
    textAlign: 'center'
  },
  animationCont: {
    margin: 'auto',
    width: '256px',
    height: '256px',
    position: 'relative'
  },
  animationStatic: {
    position: 'absolute',
    top: '80px',
    left: '-10px'
  }
}