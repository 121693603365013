import { MFTransactionReportTypes, MPrReportNumber } from '../constants';
import { LicenseType } from '../constants';

const PortfolioSummary = {
    ReportName: "Portfolio Summary",
    ReportId: [MPrReportNumber.PortfolioSummary],
    AssetTypes: [
        {id: 0,  text: 'All Assets'},
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "Get an overview of your active portfolio holdings with current valuations.",
    Description: "Get an overview of your active portfolio holdings with current valuations. You can sort, filter & customise this report and save as Excel or PDF.",
    Description_CashMgmtPortfolio: [
        "Get an overview of your active invested holdings with current valuations. You can sort, filter & customise this report and save as Excel or PDF.",
        "Please note that this portfolio summary report displays your invested holdings (and does not include cash on hand). To view portfolio performance on an aggregate level, you can view your Performance Snapshot."
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Portfolio%20Summary.pdf?sp=r&st=2019-08-29T07:21:27Z&se=2999-08-29T15:21:27Z&spr=https&sv=2018-03-28&sig=LPJrm%2FuPCVx47%2BfU54YwxcruVGqFVgI2mJsYuToAY74%3D&sr=b",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Portfolio%20Summary.xlsx?sp=r&st=2019-08-29T07:19:56Z&se=2999-08-29T15:19:56Z&spr=https&sv=2018-03-28&sig=S7AERrRBJKkgurRYQYZBnpW7ILZywDsVcLB9uV5ZMLo%3D&sr=b",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Portfolio%20Summary.png?sp=r&st=2019-08-29T07:29:36Z&se=2999-08-29T15:29:36Z&spr=https&sv=2018-03-28&sig=bHOkM58KmzouIf3peA5qAyh%2BQH01OR9bqfrCJs2d%2B3A%3D&sr=b",
    AllowedForAdvisorNonPremiumClients: true,    
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: false
};

const GroupPortfolioSummary = {
    ReportName: "Group Portfolio Summary",
    ReportId: [MPrReportNumber.PortfolioSummary],
    AssetTypes: [
        {id: 0,  text: 'All Assets'},
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "Get an overview of your active portfolio holdings with current valuations in this portfolio group.",
    Description: "Get an overview of your active portfolio holdings with current valuations in this portfolio group. You can sort, filter & customise this report and save as Excel or PDF.",
    Description_CashMgmtPortfolio: [
        "Get an overview of your active invested holdings with current valuations in this portfolio group. You can sort, filter & customise this report and save as Excel or PDF.",
        "Please note that this report displays your group's invested holdings (including reinvestment) and does not include cash on hand."
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Portfolio%20Summary.pdf?sp=r&st=2019-08-29T07:21:27Z&se=2999-08-29T15:21:27Z&spr=https&sv=2018-03-28&sig=LPJrm%2FuPCVx47%2BfU54YwxcruVGqFVgI2mJsYuToAY74%3D&sr=b",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Portfolio%20Summary.xlsx?sp=r&st=2019-08-29T07:19:56Z&se=2999-08-29T15:19:56Z&spr=https&sv=2018-03-28&sig=S7AERrRBJKkgurRYQYZBnpW7ILZywDsVcLB9uV5ZMLo%3D&sr=b",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Portfolio%20Summary.png?sp=r&st=2019-08-29T07:29:36Z&se=2999-08-29T15:29:36Z&spr=https&sv=2018-03-28&sig=bHOkM58KmzouIf3peA5qAyh%2BQH01OR9bqfrCJs2d%2B3A%3D&sr=b",
    AllowedForAdvisorNonPremiumClients: true,    
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: false
};

const HistoricalValuation = {
    ReportName: "Historical Valuation",
    ReportId: [MPrReportNumber.HistoricalValuation],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "View historical end-of-month valuations for Stocks and Mutual Funds in your portfolio.",
    Description: "View historical end-of-month valuations for Stocks and Mutual Funds in your portfolio. You can sort, filter & customise this report and save as Excel or PDF.",
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Historical%20Valuation.pdf?sp=r&st=2020-06-16T11:29:52Z&se=2999-06-16T19:29:52Z&spr=https&sv=2019-10-10&sr=b&sig=YS1iF7zwas9mOG1gUJdwyO9WnLRGufbeuwjKPCwVltc%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Historical%20Valuation.xlsx?sp=r&st=2020-06-16T11:30:41Z&se=2999-06-16T19:30:41Z&spr=https&sv=2019-10-10&sr=b&sig=7aP9FUAyLpl5AkpnDAiY8H8Gh0%2B8ulKGGXbB0HzUTnU%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Historical%20Valuation.png?sp=r&st=2020-06-16T11:31:00Z&se=2999-06-16T19:31:00Z&spr=https&sv=2019-10-10&sr=b&sig=9coyI9aUgx9xGVfMtCv8sMzUFzyFO6JGl7DJYK%2BPghk%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR, LicenseType.INVESTORPLUS],
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: false,
    RequiresMProfitPro: true,
    RequiresCurrentValueAccess: true
};

const CapitalGainsSummary = {
    ReportName: "Capital Gains - Summary",
    ReportId: [MPrReportNumber.CapitalGainSummary],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 5060,  text: 'Stocks & MF (Equity)'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'},
        {id: 63,  text: 'InvITs & REITs'},
        {id: 40,  text: 'Traded Bonds'},
    ],
    ShortDescription: "Get an overview of your realised Capital Gains for Stocks, Mutual Funds & Traded Bonds for any financial year.",
    Description: [
        "Get an overview of your realised Capital Gains for Stocks, Mutual Funds & Traded Bonds for any financial year. Save this report as Excel or PDF.",
        "You can view this report with or without Grandfathering applied for Stocks & Equity MFs and with or without indexation for Debt MFs."
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Capital%20Gains%20Summary.pdf?sp=r&st=2019-08-29T07:21:09Z&se=2999-08-29T15:21:09Z&spr=https&sv=2018-03-28&sig=im9CYznm5RWgwSXDYde35sHCzE0ZhoL7JeibZihnHnQ%3D&sr=b",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Capital%20Gains%20Summary.xlsx?sp=r&st=2019-08-29T07:19:25Z&se=2999-08-29T15:19:25Z&spr=https&sv=2018-03-28&sig=L51GW6b6H9%2B9n83TUBHU89iUxDDydca8iz16YIbU9wA%3D&sr=b",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Capital%20Gains%20Summary.png?sp=r&st=2019-08-29T07:26:40Z&se=2999-08-29T15:26:40Z&spr=https&sv=2018-03-28&sig=MnnfYWdpjyEJu8CZxqT%2BOeYwU7gTMuiBfqHtOCFqbFs%3D&sr=b",
    AllowedForAdvisorNonPremiumClients: true,    
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true
};

const XIRR = {
    ReportName: "Annualised Return (XIRR)",
    ReportId: [MPrReportNumber.XIRR],
    AssetTypes: [
        {id: 0,  text: 'All Assets'},
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "Get a detailed view of your annualized return for each asset, asset category, individual portfolio or group of portfolios.",
    Description: "Get a detailed view of your annualized return for each asset, asset category, individual portfolio or group of portfolios. You can sort, filter & customise this report and save as Excel or PDF.",
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_XIRR.pdf?sp=r&st=2019-08-29T07:21:41Z&se=2999-08-29T15:21:41Z&spr=https&sv=2018-03-28&sig=k2rt9KCwpd5xvC0TXexKi1%2B32R5nnWrCim8aB1jhzfo%3D&sr=b",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_XIRR.xlsx?sp=r&st=2019-08-29T07:20:19Z&se=2999-08-29T15:20:19Z&spr=https&sv=2018-03-28&sig=UFLZZDvFkZyB%2B2sDcOCnLJIyc0vUOwylB28PIht4oSc%3D&sr=b",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_XIRR.png?sp=r&st=2019-08-29T07:27:16Z&se=2999-08-29T15:27:16Z&spr=https&sv=2018-03-28&sig=yYPQv3SVeV4cWY2%2FufYUNf3RqWohVf8pHablT8S3LAI%3D&sr=b",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true,
    RequiresCurrentValueAccess: true
};

const GroupXIRRSummary = {
    ReportName: "Group XIRR Summary",
    ReportId: [MPrReportNumber.GroupXIRRSummary],
    AssetTypes: [
        {id: 0,  text: 'All Assets'},
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "Get an overview of your annualized return (XIRR) for each asset & asset category and overall XIRR in this portfolio group.",
    Description: "Get an overview of your annualized return (XIRR) for each asset & asset category and overall XIRR in this portfolio group. You can sort, filter & customise this report and save as Excel or PDF.",
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Group%20XIRR%20Summary.pdf?sp=r&st=2021-02-24T16:09:10Z&se=2999-02-25T00:09:10Z&spr=https&sv=2020-02-10&sr=b&sig=O7AZqA2oVBlOF%2BBUW464KV7t5tQk3C6ceHZ%2Fwh6F8YM%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Group%20XIRR%20Summary.xlsx?sp=r&st=2021-02-24T16:08:32Z&se=2999-02-25T00:08:32Z&spr=https&sv=2020-02-10&sr=b&sig=82KRG3r%2FFmvvh%2BfvJepe4gefop8vwxU1FBBCPO%2BuCQs%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Group%20XIRR%20Summary.png?sp=r&st=2021-02-24T16:07:41Z&se=2999-02-25T00:07:41Z&spr=https&sv=2020-02-10&sr=b&sig=jqlO6rBMqouAtt%2Fy16uHEDaLfbwVrX9iH2oM2HWwKlk%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    AllowedForAdvisorPremiumClients: true,
    RequiresCurrentValueAccess: true
};

const GroupXIRRDetailed = {
    ReportName: "Group XIRR Detailed",
    ReportId: [MPrReportNumber.GroupXIRRDetailed],
    AssetTypes: [
        {id: 0,  text: 'All Assets'},
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "Get a detailed portfolio-wise view of your annualized return (XIRR) for each asset & asset category and overall XIRR in this portfolio group.",
    Description: "Get a detailed portfolio-wise view of your annualized return (XIRR) for each asset & asset category and overall XIRR in this portfolio group. You can sort, filter & customise this report and save as Excel or PDF.",
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Group%20XIRR%20Detailed.pdf?sp=r&st=2021-02-24T16:09:26Z&se=2999-02-25T00:09:26Z&spr=https&sv=2020-02-10&sr=b&sig=N5jds0SEc0f2%2Fwmv%2BFJles5yHKId%2B5%2FoeUm6M5YHJco%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Group%20XIRR%20Detailed.xlsx?sp=r&st=2021-02-24T16:08:50Z&se=2999-02-25T00:08:50Z&spr=https&sv=2020-02-10&sr=b&sig=wrQZPZJSylA0R2oTiiL213xGExLUEZak6jGAFyUr2uo%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Group%20XIRR%20Detailed.png?sp=r&st=2021-02-24T16:08:13Z&se=2999-02-25T00:08:13Z&spr=https&sv=2020-02-10&sr=b&sig=MfJ621BWbVlUdBd2vT6rLWi6qiMsFd1VGy8BXygxcrE%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    AllowedForAdvisorPremiumClients: true,
    RequiresCurrentValueAccess: true
};

const CapitalGainsITR = {
    ReportName: "Capital Gains - ITR Format",
    ReportId: [MPrReportNumber.CapitalGainsITR],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'},
        {id: 63,  text: 'InvITs & REITs'},
        {id: 40,  text: 'Traded Bonds'},
    ],
    ShortDescription: "View your intra-day, short-term & long-term Capital Gains with ISIN Details in Income Tax Return Format.",
    Description: [
        "View your intra-day, short-term & long-term Capital Gains with ISIN Details in Income Tax Return Format. You can view your Capital Gains for any financial year and save the report as Excel or PDF.",
        "Get Fair Market Value (highest price on 31st Jan, 2018) & Cost of Acquisition details for Stocks and Equity MFs as per LTCG Grandfathering clause. View your Capital Gains with or without indexation for Debt MFs and Traded Bonds."
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Capital%20Gains%20ITR%20Format.pdf?sp=r&st=2019-08-29T07:20:44Z&se=2999-08-29T15:20:44Z&spr=https&sv=2018-03-28&sig=JZqARa8YuCP%2FE5yu3Flp1VvybhhIc9sMUovUH2Cpf%2BA%3D&sr=b",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Capital%20Gains%20ITR%20Format.xlsx?sp=r&st=2019-08-29T07:17:56Z&se=2999-08-29T15:17:56Z&spr=https&sv=2018-03-28&sig=ySexh4R9i24e%2FSVdh%2F8E3Y4%2FVYhuLaTact33qEgWgaw%3D&sr=b",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Capital%20Gains%20ITR%20Format.png?sp=r&st=2019-08-29T07:26:20Z&se=2999-08-29T15:26:20Z&spr=https&sv=2018-03-28&sig=oXGCSpfxOmayd51PFqsCdD4%2FzWNufxvpDp5iBeuuixY%3D&sr=b",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    NonLegacy_LockedForFree: true,
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true
};

const ClosingBalance = {
    ReportName: "Closing Balance",
    ReportId: [MPrReportNumber.ClosingBalance],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'Mutual Funds'}
    ],
    ShortDescription: "View closing balance quantity & amount with date-wise purchase for every asset in your portfolio, as of any date specified by you.",
    Description: "View closing balance quantity & amount with date-wise purchase for every asset in your portfolio, as of any date specified by you.",
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Closing%20Balance.pdf?sp=r&st=2020-03-28T11:06:22Z&se=2999-03-28T19:06:22Z&spr=https&sv=2019-02-02&sr=b&sig=C4UHpTha8XMSzay3GVU4drTw0BJD%2FrLZnyw7sST9Q7A%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Closing%20Balance.xlsx?sp=r&st=2020-03-28T10:59:48Z&se=2999-03-28T18:59:48Z&spr=https&sv=2019-02-02&sr=b&sig=o5jWqHsjQedaqi86U6n1Mbb0SW5cDXHAJ8WqjPoRmY4%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Closing%20Balance.png?sp=r&st=2020-03-28T11:08:00Z&se=2999-03-28T19:08:00Z&spr=https&sv=2019-02-02&sr=b&sig=IPYzVgv2jqg7QFq5EygrDs%2BjowW5hAzO%2FwjKrUkXu30%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true
};

const HoldingPeriod = {
    ReportName: "Holding Period",
    ReportId: [MPrReportNumber.HoldingPeriod],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'Mutual Funds'}
    ],
    ShortDescription: "View holding period information along with closing balance quantity, amount and date-wise purchase for every asset in your portfolio.",
    Description: "View holding period information along with closing balance quantity, amount and date-wise purchase for every asset in your portfolio.",
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Holding%20Period.pdf?sp=r&st=2020-03-28T11:06:55Z&se=2999-03-28T19:06:55Z&spr=https&sv=2019-02-02&sr=b&sig=krU0LzLTNfEWdoVCWWWelzrG75C7C8Ln5WF9na8xC%2Fc%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Holding%20Period.xlsx?sp=r&st=2020-03-28T11:01:53Z&se=2999-03-28T19:01:53Z&spr=https&sv=2019-02-02&sr=b&sig=jhPFX6aHtN7XH9RCO0aciejZEkm8b5opd5U06yWjyQg%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Holding%20Period.png?sp=r&st=2020-03-28T11:08:14Z&se=2999-03-28T19:08:14Z&spr=https&sv=2019-02-02&sr=b&sig=gMqMJ6ycRz2zqU%2FTl39UmKqje7lJ6RVIrbYD0%2FQBce8%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR, LicenseType.INVESTORPLUS],
    RequiresMProfitPro: true,
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true
};

const StockRegister = {
    ReportName: "Stock Register",
    ReportId: [MPrReportNumber.StockRegister],
    AssetTypes: [
        {id: 50, text: "Stocks"},
        {id: 60, text: "Mutual Funds"}
    ],
    ShortDescription: "For any defined time period, view opening balances, date-wise transactions with gain/loss and closing balances for each asset.",
    Description: "For any defined time period, view opening balances, date-wise transactions with gain/loss and closing balances for each asset.",
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Stock%20Register.pdf?sp=r&st=2020-04-03T07:14:11Z&se=2999-04-03T15:14:11Z&spr=https&sv=2019-02-02&sr=b&sig=43uELl17z%2B4CK2AeYWVP%2BZAoKjDUTZjVEU%2Fj%2BJ3Nzt0%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Stock%20Register.xlsx?sp=r&st=2020-04-03T07:13:21Z&se=2999-04-03T15:13:21Z&spr=https&sv=2019-02-02&sr=b&sig=vmmUdXQptT%2BtqUldwR4rXchDgx0af5n%2FQhAgHWVsQ3c%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Stock%20Register.png?sp=r&st=2020-04-03T07:14:49Z&se=2999-04-03T15:14:49Z&spr=https&sv=2019-02-02&sr=b&sig=3ZDWl0ZudKh7jU9pP27kLCMhMrrrBdWGG09xLIEN4vA%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR,LicenseType.INVESTORPLUS],
    RequiresMProfitPro: true,
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true
};

const UnrealisedCapitalGainsSummary = {
    ReportName: "Unrealised Capital Gains - Summary",
    ReportId: [MPrReportNumber.UnrealisedCapitalGainsSummary],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 5060,  text: 'Stocks & MF (Equity)'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'},
        {id: 63,  text: 'InvITs & REITs'},
        {id: 40,  text: 'Traded Bonds'},
    ],
    ShortDescription: "Get an overview of your Unrealised Capital Gains for Stocks, Mutual Funds & Traded Bonds.",
    Description: [
        "Get an overview of your Unrealised Capital Gains for Stocks, Mutual Funds & Traded Bonds. Save this report as Excel or PDF.",
        "You can view this report with or without Grandfathering applied for Stocks & Equity MFs and with or without indexation for Debt MFs."
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Unrealised%20Capital%20Gains%20Summary.pdf?sp=r&st=2020-06-16T11:20:02Z&se=2999-06-16T19:20:02Z&spr=https&sv=2019-10-10&sr=b&sig=aaUHVuOeidfJXxsunCFenbrmy7bxGkzhY25GlQ2Nnsw%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Unrealised%20Capital%20Gains%20Summary.xlsx?sp=r&st=2020-06-16T11:21:04Z&se=2999-06-16T19:21:04Z&spr=https&sv=2019-10-10&sr=b&sig=QQi5fXwYQ5ex4DeBmrC9iJe45wbW3BN5PAGls2l81eY%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Unrealised%20Capital%20Gains%20Summary.png?sp=r&st=2020-06-16T11:18:52Z&se=2999-06-16T19:18:52Z&spr=https&sv=2019-10-10&sr=b&sig=FMvOLedONETi6LlpW0RM4WIFpK%2FAl4Id7OtA6htkEQA%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR, LicenseType.INVESTORPLUS],
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true,
    RequiresMProfitPro: true,
    RequiresCurrentValueAccess: true
};

const UnrealisedCapitalGainsDetailed = {
    ReportName: "Unrealised Capital Gains - Detailed",
    ReportId: [MPrReportNumber.UnrealisedCapitalGainsDetailed],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'},
        {id: 63,  text: 'InvITs & REITs'},
        {id: 40,  text: 'Traded Bonds'},
    ],
    ShortDescription: "View your intra-day, short-term & long-term Unrealised Capital Gains with ISIN Details.",
    Description: [
        "View your intra-day, short-term & long-term Unrealised Capital Gains with ISIN Details. Save the report as Excel or PDF.",
        "Get Fair Market Value (highest price on 31st Jan, 2018) & Cost of Acquisition details for Stocks and Equity MFs as per LTCG Grandfathering clause. View your Capital Gains with or without indexation for Debt MFs and Traded Bonds."
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Unrealised%20Capital%20Gains%20Detailed.pdf?sp=r&st=2020-06-16T11:19:42Z&se=2999-06-16T19:19:42Z&spr=https&sv=2019-10-10&sr=b&sig=tp%2F17Oa45XeKsvqURJnpN79KgX3QZqI29U4Ti5cvIw8%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Unrealised%20Capital%20Gains%20Detailed.xlsx?sp=r&st=2020-06-16T11:20:35Z&se=2999-06-16T19:20:35Z&spr=https&sv=2019-10-10&sr=b&sig=2ZCkgS7bAPPNbAVtzXx0%2BZ9eY2W2oZh3LWTeCqnYwGI%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Unrealised%20Capital%20Gains%20Detailed.png?sp=r&st=2020-06-16T11:17:46Z&se=2999-06-16T19:17:46Z&spr=https&sv=2019-10-10&sr=b&sig=ZlGU152uTel4KQ2lMaqJET0oIQfy5Th6luQoi52EDIo%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR, LicenseType.INVESTORPLUS],
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true,
    RequiresMProfitPro: true,
    RequiresCurrentValueAccess: true,
    NonLegacy_LockedForFree: true,
};

const DueDates = {
    ReportName: "Due Dates Report",
    ReportId: [MPrReportNumber.DueDates],
    AssetTypes: [
        {id: 0,  text: 'All Assets'},
        {id: 95, text: "FDs"},
        {id: 140, text: "Insurance"},
        {id: 141, text: "NPS / ULIP"},
    ],
    ShortDescription: "Keep track of due dates for policy premium payments of Insurance or NPS / ULIP assets. Stay updated with maturity dates for various assets such as FDs, PPF, loans and deposits.",
    Description: "Keep track of due dates for policy premium payments of Insurance or NPS / ULIP assets. Stay updated with maturity dates for various assets such as FDs, PPF, loans and deposits.",
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Due%20Dates.pdf?sp=r&st=2019-11-14T10:12:16Z&se=2999-11-14T18:12:16Z&spr=https&sv=2019-02-02&sr=b&sig=VNSGfIwZZoCePfG1kDXgksCnM82M3IWCJP3814cojfc%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Due%20Dates.xlsx?sp=r&st=2019-11-14T10:12:55Z&se=2999-11-14T18:12:55Z&spr=https&sv=2019-02-02&sr=b&sig=Cxf6rR51NN62QMcRKHfNUPLUjsMX4%2F7oWOGUBifnMTg%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Due%20Dates.png?sp=r&st=2019-11-14T10:15:22Z&se=2999-11-14T18:15:22Z&spr=https&sv=2019-02-02&sr=b&sig=Zb9uMg7Jb2Zhy%2FAE1qLXomz3sHlFG%2BV5AVdaOrmV8cc%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: false
};

const GroupDueDates = {
    ReportName: "Group Due Dates Report",
    ReportId: [MPrReportNumber.DueDates],
    AssetTypes: [
        {id: 0,  text: 'All Assets'},
        {id: 95, text: "FDs"},
        {id: 140, text: "Insurance"},
        {id: 141, text: "NPS / ULIP"},
    ],
    ShortDescription: "Keep track of due dates for policy premium payments of Insurance, NPS & ULIP assets in this portfolio group. Stay updated with maturity dates for various assets such as FDs, PPF, Loans and Deposits.",
    Description: "Keep track of due dates for policy premium payments of Insurance, NPS & ULIP assets in this portfolio group. Stay updated with maturity dates for various assets such as FDs, PPF, Loans and Deposits.",
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Due%20Dates.pdf?sp=r&st=2019-11-14T10:12:16Z&se=2999-11-14T18:12:16Z&spr=https&sv=2019-02-02&sr=b&sig=VNSGfIwZZoCePfG1kDXgksCnM82M3IWCJP3814cojfc%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Due%20Dates.xlsx?sp=r&st=2019-11-14T10:12:55Z&se=2999-11-14T18:12:55Z&spr=https&sv=2019-02-02&sr=b&sig=Cxf6rR51NN62QMcRKHfNUPLUjsMX4%2F7oWOGUBifnMTg%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Due%20Dates.png?sp=r&st=2019-11-14T10:15:22Z&se=2999-11-14T18:15:22Z&spr=https&sv=2019-02-02&sr=b&sig=Zb9uMg7Jb2Zhy%2FAE1qLXomz3sHlFG%2BV5AVdaOrmV8cc%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: false
};

const CapitalGainsITR_ClearTax = {
    ReportName: "ClearTax - Capital Gains ITR Format",
    ReportId: [MPrReportNumber.ClearTaxFormat],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in ClearTax Software format.",
    Description: [
        "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in ClearTax Software format.",
        "You can copy & paste details in the relevant columns from this report into the Capital Gain sheet provided to you by ClearTax.",
        "Do not copy the blank columns from this report, as they correspond to columns containing formulas in the ClearTax sheet."
    ],
    PreviewPdfUrl: null,
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_ClearTax%20Software%20ITR%20Format.xlsx?sp=r&st=2020-09-10T06:01:28Z&se=2999-09-10T14:01:28Z&spr=https&sv=2019-12-12&sr=b&sig=85FuC9ehlfyjhs9xB2A9jLjWGQYJ5SvsSNfth9WdmLc%3D",
    PreviewImage: null,
    LockedForLicenseTypes: [LicenseType.INVESTOR, LicenseType.INVESTORPLUS],
    AllowedForAdvisorPremiumClients: true,
    RequiresMProfitPro: true,
    HideFromGroup: true,
    NonLegacy_LockedForFree: true,
};

const CapitalGainsITR_Winman = {
    ReportName: "Winman - Capital Gains ITR Format",
    ReportId: [MPrReportNumber.WinmanFormat],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in Winman Software format.",
    Description: [
        "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in Winman Software format.",
        "You can copy & paste details in the relevant columns from this report into the Capital Gain sheet provided to you by Winman.",
    ],
    PreviewPdfUrl: null,
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Winman%20Software%20ITR%20Format.xlsx?sp=r&st=2020-09-10T06:02:09Z&se=2999-09-10T14:02:09Z&spr=https&sv=2019-12-12&sr=b&sig=LfInDQQj2tpW5l%2Fv0adprn1m4GwQCyvVGLdQ%2FFkC%2FCk%3D",
    PreviewImage: null,
    LockedForLicenseTypes: [LicenseType.INVESTOR, LicenseType.INVESTORPLUS],
    AllowedForAdvisorPremiumClients: true,
    RequiresMProfitPro: true,
    HideFromGroup: true,
    NonLegacy_LockedForFree: true,
};

const CapitalGainsITR_Computax = {
    ReportName: "CompuTax - Capital Gains ITR Format",
    ReportId: [MPrReportNumber.ComputaxFormat],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in CompuTax Software format.",
    Description: [
        "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in CompuTax Software format.",
        "You can copy & paste details in the relevant columns from this report into the Capital Gain sheet provided to you by CompuTax.",
    ],
    PreviewPdfUrl: null,
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_CompuTax%20Software%20ITR%20Format.xlsx?sp=r&st=2020-10-06T12:45:23Z&se=2999-10-06T20:45:23Z&spr=https&sv=2019-12-12&sr=b&sig=eN1v8JMocSgqSFtB8nPwhU%2BqrIThloI81eGcjUmlCTo%3D",
    PreviewImage: null,
    LockedForLicenseTypes: [LicenseType.INVESTOR, LicenseType.INVESTORPLUS],
    AllowedForAdvisorPremiumClients: true,
    RequiresMProfitPro: true,
    HideFromGroup: true,
    NonLegacy_LockedForFree: true,
};

const CapitalGainsITR_Spectrum = {
    ReportName: "Spectrum - Capital Gains ITR Format",
    ReportId: [MPrReportNumber.SpectrumFormat],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in Spectrum Software format.",
    Description: [
        "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in Spectrum Software format.",
        "You can copy & paste details in the relevant columns from this report into the Capital Gain sheet provided to you by Spectrum.",
    ],
    PreviewPdfUrl: null,
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Spectrum%20Software%20ITR%20Format.xlsx?sp=r&st=2020-10-06T12:45:51Z&se=2999-10-06T20:45:51Z&spr=https&sv=2019-12-12&sr=b&sig=FXia%2Foz6VNv8Pm3bO4fxpQwFOPhh05x7HzRYTbyMsU8%3D",
    PreviewImage: null,
    LockedForLicenseTypes: [LicenseType.INVESTOR, LicenseType.INVESTORPLUS],
    AllowedForAdvisorPremiumClients: true,
    RequiresMProfitPro: true,
    HideFromGroup: true,
    NonLegacy_LockedForFree: true,
};

const CapitalGainsITR_Genius = {
    ReportName: "Genius - Capital Gains ITR Format",
    ReportId: [MPrReportNumber.GeniusFormat],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in Genius Software format.",
    Description: [
        "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in Genius Software format.",
        "You can copy & paste details in the relevant columns from this report into the Capital Gain sheet provided to you by Genius.",
    ],
    PreviewPdfUrl: null,
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Genius%20Software%20ITR%20Format.xlsx?sp=r&st=2021-07-10T15:21:37Z&se=2999-07-10T23:21:37Z&spr=https&sv=2020-08-04&sr=b&sig=ahwlnttdjui%2FejlUPGgsP97Yvq2lLvthJOEo0yDKDDk%3D",
    PreviewImage: null,
    LockedForLicenseTypes: [LicenseType.INVESTOR, LicenseType.INVESTORPLUS],
    AllowedForAdvisorPremiumClients: true,
    RequiresMProfitPro: true,
    HideFromGroup: true,
    NonLegacy_LockedForFree: true,
};

const CapitalGainsITR_TaxPro = {
    ReportName: "TaxPro - Capital Gains ITR Format",
    ReportId: [MPrReportNumber.TaxProFormat],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
        {id: 61,  text: 'MF (Debt)'}
    ],
    ShortDescription: "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in TaxPro Software format.",
    Description: [
        "Download an Excel report detailing your short-term and long-term Capital Gains with ISIN details for any financial year in TaxPro Software format.",
        "You can copy & paste details in the relevant columns from this report into the Capital Gain sheet provided to you by TaxPro.",
    ],
    PreviewPdfUrl: null,
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_TaxPro%20Software%20ITR%20Format.xlsx?sp=r&st=2021-07-10T15:22:04Z&se=2999-07-10T23:22:04Z&spr=https&sv=2020-08-04&sr=b&sig=MaNeWNqCulwV%2F36Zanr%2BrWKAEdXn8uD81SdrUCEyynY%3D",
    PreviewImage: null,
    LockedForLicenseTypes: [LicenseType.INVESTOR, LicenseType.INVESTORPLUS],
    AllowedForAdvisorPremiumClients: true,
    RequiresMProfitPro: true,
    HideFromGroup: true,
    NonLegacy_LockedForFree: true,
};

const CapitalGainsITR_112A = {
    ReportName: "Schedule 112A ITR Format",
    ReportId: [MPrReportNumber.ITR112AFormat],
    AssetTypes: [
        {id: 50,  text: 'Stocks'},
        {id: 60,  text: 'MF (Equity)'},
    ],
    ShortDescription: "Download an Excel report detailing your long-term Capital Gains with ISIN details for any financial year in the ITR Schedule 112A format.",
    Description: [
        "Download an Excel report detailing your long-term Capital Gains with ISIN details for any financial year in the ITR Schedule 112A format. Please note that Schedule 112A is only for long-term capital gains as per Income Tax rules.",
        "You can copy & paste details in the relevant columns from this report into the Capital Gain sheet downloaded from the ITR Utility.",
    ],
    PreviewPdfUrl: null,
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Schedule%20112A%20ITR%20Format.xlsx?sp=r&st=2021-07-22T18:47:52Z&se=2999-07-23T02:47:52Z&spr=https&sv=2020-08-04&sr=b&sig=9bcOhy1vCLyjzPmPofRaN5LynOiPKENUpgTzSkM%2F7YM%3D",
    PreviewImage: null,
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    AllowedForAdvisorPremiumClients: true,
    RequiresMProfitPro: true,
    HideFromGroup: true,
    NonLegacy_LockedForFree: true,
};

const StocksCNSummary = {
    ReportName: "Contract Note Summary",
    ReportId: [MPrReportNumber.ContractNoteSummary],
    AssetTypes: [
        {id: 0,  text: 'Generate Report'}
    ],
    ShortDescription: "Get a summary report for all contract notes imported across a selected time period to a portfolio.",
    Description: [
        "Get a summary report for all contract notes imported across a selected time period to a portfolio. Details in the report include Contract Note Date, Broker Name, Contract Note Number and Amount Payable / Receivable.",
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Contract%20Notes%20Summary.pdf?sp=r&st=2020-10-06T14:10:54Z&se=2999-10-06T22:10:54Z&spr=https&sv=2019-12-12&sr=b&sig=OyoXlGeZak%2FsGhjVzyIXjLipNjPqoyxQa1KrAMJ7kaw%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Contract%20Notes%20Summary.xlsx?sp=r&st=2020-10-06T13:42:41Z&se=2999-10-06T21:42:41Z&spr=https&sv=2019-12-12&sr=b&sig=bxEof30yW5qmeIHS1Wv6BG2FXRuo4lNbt9Gkoj9RzNo%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Contract%20Notes%20Summary.png?sp=r&st=2020-10-06T14:19:13Z&se=2999-10-06T22:19:13Z&spr=https&sv=2019-12-12&sr=b&sig=vQpwmgDVMhOe%2BL8p2zavfB%2F8PXhBn7DQ5dk0AGbomO4%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: true,
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true
}

const StocksTransactions = {
    ReportName: "Stock Transactions",
    ReportId: [MPrReportNumber.StockTransactions],
    AssetTypes: [
        {id: 0,  text: 'Generate Report'}
    ],
    ShortDescription: "Get a report detailing all your Stock transactions across a selected time period in a portfolio.",
    Description: [
        "Get a report detailing all your Stock transactions across a selected time period in a portfolio. For each transaction in the report, view Date, Quantity, Price, Brokerage and Amount.",
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Stock%20Transactions.pdf?sp=r&st=2020-10-06T14:18:11Z&se=2999-10-06T22:18:11Z&spr=https&sv=2019-12-12&sr=b&sig=oHaCpIl1BFBEBHvDE1r5DOyF5%2BmQp7e26ZYvZyhrPpI%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Stock%20Transactions.xlsx?sp=r&st=2020-10-06T13:43:28Z&se=2999-10-06T21:43:28Z&spr=https&sv=2019-12-12&sr=b&sig=y0%2F6hRq7ge6YXxCCGyKu%2F0J8TnUqsEnf9pgMBmm%2BZr0%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Stock%20Transactions.png?sp=r&st=2020-10-06T14:19:50Z&se=2999-10-06T22:19:50Z&spr=https&sv=2019-12-12&sr=b&sig=gGgSs0gK63ZjOoMS4F18DWs9Da2WYjYEnT%2BZEq%2FDz6Y%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: true,
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true,
    NonLegacy_LockedForFree: true,
}

const MFTransactions = {
    ReportName: "Mutual Fund Transactions",
    ReportId: [MPrReportNumber.MutualFundTransactions],
    AssetTypes: [
        {id: MFTransactionReportTypes.MF_DateTrans,  text: 'Date-wise'},
        {id: MFTransactionReportTypes.MF_SchemeTrans,  text: 'Scheme-wise'}
    ],
    SelectField: "ReportTransType",
    ShortDescription: "Get a report detailing all your Mutual Fund transactions across a selected time period in a portfolio.",
    Description: [
        "Get a report detailing all your Mutual Fund transactions across a selected time period in a portfolio. For each transaction in the report, view Date, Quantity, Price, Gross Amount, STT, Stamp Charges, TDS and Net Amount.",
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Mutual%20Fund%20Transactions.pdf?sp=r&st=2020-10-06T14:11:23Z&se=2999-10-06T22:11:23Z&spr=https&sv=2019-12-12&sr=b&sig=JiifOnoN2rYbAZoV6x0RwWjObEvixrKp2wMr3osVK60%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Mutual%20Fund%20Transactions.xlsx?sp=r&st=2020-10-06T14:06:01Z&se=2999-10-06T22:06:01Z&spr=https&sv=2019-12-12&sr=b&sig=aYXEMf0u6yxIAvklC9oC2k%2F6Zj3WXoNflDBUh1OkTK8%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Mutual%20Fund%20Transactions.png?sp=r&st=2020-10-06T14:19:35Z&se=2999-10-06T22:19:35Z&spr=https&sv=2019-12-12&sr=b&sig=hx7fUbxRD%2FMTAyI94DuT0amIVlqzTJb5KpmHRX%2F%2BkCk%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: true,
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true,
    NonLegacy_LockedForFree: true,
}

const CNCharges = {
    ReportName: "Contract Note Charges",
    ReportId: [MPrReportNumber.ChargesReport],
    AssetTypes: [
        {id: 0,  text: 'Generate Report'}
    ],
    ShortDescription: "Get a report detailing your stock contract note charges across a selected time period in a portfolio.",
    Description: [
        "Get a report detailing your stock contract note charges across a selected time period in a portfolio. For each contract note in the selected time period - view STT, GST, Stamp Charges, Transaction Charges and Other Charges.",
    ],
    PreviewPdfUrl: null,
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Contract%20Note%20Charges.xlsx?sp=r&st=2020-12-14T17:48:40Z&se=2999-12-15T01:48:40Z&spr=https&sv=2019-12-12&sr=b&sig=%2B9wAsTPH3Yd2wFKRQL%2FUN5DuLX1OdtRGks%2Fdyso3BXs%3D",
    PreviewImage: null,
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: true,
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true
}

const IncomeReportSummary = {
    ReportName: "Income Report - Summary",
    ReportId: [MPrReportNumber.IncomeReportSummary],
    AssetTypes: [
        {id: 0,  text: 'Generate Report'}
    ],
    ShortDescription: "Get an overview of your dividend, interest & other income across all asset categories.",
    Description: [
        "Get an overview of your dividend, interest & other income across all asset categories. You can filter & customise this report and save as Excel or PDF.",
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Income%20Summary.pdf?sp=r&st=2021-02-03T17:38:37Z&se=2999-02-04T01:38:37Z&spr=https&sv=2019-12-12&sr=b&sig=TTwDjbF8pmXRX%2FnS3v3PgsBx8R9ZTYWg6XEmOcfw918%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Income%20Summary.xlsx?sp=r&st=2021-02-03T17:36:17Z&se=2999-02-04T01:36:17Z&spr=https&sv=2019-12-12&sr=b&sig=OAjEX1I8qkL9%2BVZ9oenZ9jPJIY7aDLQDTmP2W3cDCKg%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Income%20Summary.png?sp=r&st=2021-02-03T17:41:44Z&se=2999-02-04T01:41:44Z&spr=https&sv=2019-12-12&sr=b&sig=H%2FKXkvMHBn7yxARylMLW4p0km1r3oV2jsjuKppyv9%2BU%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: false,
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true
}

const IncomeReportDetailed = {
    ReportName: "Income Report - Detailed",
    ReportId: [MPrReportNumber.IncomeReportDetailed],
    AssetTypes: [
        {id: 0,  text: 'Generate Report'}
    ],
    ShortDescription: "Get date-wise details across all asset categories for dividend, interest & other income in your portfolio.",
    Description: [
        "Get date-wise details across all asset categories for dividend, interest & other income in your portfolio. You can sort, filter & customise this report and save as Excel or PDF.",
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Income%20Detailed.pdf?sp=r&st=2021-02-03T17:38:20Z&se=2999-02-04T01:38:20Z&spr=https&sv=2019-12-12&sr=b&sig=qM5UoPW4Iy%2FLm4DFeEEDP2OdHKVQ5o%2Foz70WcFy9wmM%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Income%20Detailed.xlsx?sp=r&st=2021-02-03T17:35:57Z&se=2999-02-04T01:35:57Z&spr=https&sv=2019-12-12&sr=b&sig=5WtRnKD38KvcJRaZ6sxtzG2a2h9kaJhRn7qOFTIOhgc%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Income%20Detailed.png?sp=r&st=2021-02-03T17:41:08Z&se=2999-02-04T01:41:08Z&spr=https&sv=2019-12-12&sr=b&sig=tbwWj3WxBaW5SxcJGxK5YVRZ3b6Vsz%2ByRJ3HDUNuX4M%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: false,
    AllowedForAdvisorPremiumClients: true,
    HideFromGroup: true
}

const GroupIncomeReportSummary = {
    ReportName: "Group Income Report - Summary",
    ReportId: [MPrReportNumber.GroupIncomeReportSummary],
    AssetTypes: [
        {id: 0,  text: 'Generate Report'}
    ],
    ShortDescription: "Get an overview of your dividend, interest & other income for this portfolio group.",
    Description: [
        "Get an overview of your dividend, interest & other income for this portfolio group. You can filter & customise this report and save as Excel or PDF.",
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Group%20Income%20Summary.pdf?sp=r&st=2021-02-03T17:38:02Z&se=2999-02-04T01:38:02Z&spr=https&sv=2019-12-12&sr=b&sig=xt8YVG1sBtVNuCI6Is1NJq%2Fl0hD8TOAfvdaAFSsSNaA%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Group%20Income%20Summary.xlsx?sp=r&st=2021-02-03T17:35:13Z&se=2999-02-04T01:35:13Z&spr=https&sv=2019-12-12&sr=b&sig=Nnl6wnZizvvL08tMYRVFEW0nEnwp8i1X0MVgIxLxReQ%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Group%20Income%20Summary.png?sp=r&st=2021-02-03T17:40:29Z&se=2999-02-04T01:40:29Z&spr=https&sv=2019-12-12&sr=b&sig=kZTMAuS6GzQpF0rLCmRRYaevfJT7brCO%2Fw36FokIAb8%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: false,
    AllowedForAdvisorPremiumClients: true
}

const GroupIncomeReportDetailed = {
    ReportName: "Group Income Report - Detailed",
    ReportId: [MPrReportNumber.GroupIncomeReportDetailed],
    AssetTypes: [
        {id: 0,  text: 'Generate Report'}
    ],
    ShortDescription: "Get date-wise details for dividend, interest & other income in this portfolio group.",
    Description: [
        "Get date-wise details for dividend, interest & other income in this portfolio group. You can sort, filter & customise this report and save as Excel or PDF.",
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Group%20Income%20Detailed.pdf?sp=r&st=2021-02-03T17:37:36Z&se=2999-02-04T01:37:36Z&spr=https&sv=2019-12-12&sr=b&sig=TWDx9K%2BS9ta%2BhNBm2L%2BcRsTdP4i4MiflOumU53i1%2BnI%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Group%20Income%20Detailed.xlsx?sp=r&st=2021-02-03T17:34:33Z&se=2999-02-04T01:34:33Z&spr=https&sv=2019-12-12&sr=b&sig=Vy4g1c5%2B224rVXaz3jjJFst5%2FZdEFzxG7ifyZkR7fCc%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Group%20Income%20Detailed.png?sp=r&st=2021-02-03T17:40:05Z&se=2999-02-04T01:40:05Z&spr=https&sv=2019-12-12&sr=b&sig=vpVWHX9Mr1RM9f0FIRhhuLxsZfQftt2JDStYdXrVBT4%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: false,
    AllowedForAdvisorPremiumClients: true
}

const ULIPInsReport = {
    ReportName: "ULIP & Insurance Report",
    ReportId: [MPrReportNumber.ULIPInsReport],
    AssetTypes: [
        {id: 0,  text: 'Generate Report'}
    ],
    ShortDescription: "Get an overview of all your ULIP and Insurance Policies with details such as Policy No., Sum Assured, Nominee, Total Premium Paid, Total Withdrawal and Maturity Date.",
    Description: [
        "Get an overview of all your ULIP and Insurance Policies with details such as Policy No., Sum Assured, Nominee, Total Premium Paid, Total Withdrawal and Maturity Date. Get a total of Sum Assured across all your policies.",
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_ULIP%20%26%20Insurance.pdf?sp=r&st=2021-05-28T12:59:51Z&se=2999-05-28T20:59:51Z&spr=https&sv=2020-02-10&sr=b&sig=uVrbKQobNjXEpBOpScXeXUy5%2BChKyuE%2Ft37QEBpmBuw%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_ULIP%20%26%20Insurance.xlsx?sp=r&st=2021-05-28T13:00:52Z&se=2999-05-28T21:00:52Z&spr=https&sv=2020-02-10&sr=b&sig=WNrkLDfuVw72zkv2P82a6QQ7FTae2jrn4iK2jz9jqcw%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_ULIP%20%26%20Insurance.png?sp=r&st=2021-05-28T12:57:53Z&se=2999-05-28T20:57:53Z&spr=https&sv=2020-02-10&sr=b&sig=6519OfT5OoUg%2B9T1GuDd8i7P2LaC96QUYAQwnLVBq4Y%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: false,
    AllowedForAdvisorPremiumClients: true
}

const FDDepositReport = {
    ReportName: "FD & Deposit Report",
    ReportId: [MPrReportNumber.FDDepositReport],
    AssetTypes: [
        {id: 0,  text: 'Generate Report'}
    ],
    ShortDescription: "Get an overview of all your FDs and Deposits with details such as Maturity date, Interest type, Interest %, Investment Date, Principal Amount and Maturity Amount.",
    Description: [
        "Get an overview of all your FDs and Deposits with details such as Maturity date, Interest type, Interest %, Investment Date, Principal Amount and Maturity Amount.",
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_FD%20%26%20Deposits%20Summary.pdf?sp=r&st=2021-05-28T12:58:42Z&se=2999-05-28T20:58:42Z&spr=https&sv=2020-02-10&sr=b&sig=6KwZD7nXslSkKXopnGCl8%2FxAgaXH3xiiyOVD2D1cmZo%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_FD%20%26%20Deposits%20Summary.xlsx?sp=r&st=2021-05-28T13:00:17Z&se=2999-05-28T21:00:17Z&spr=https&sv=2020-02-10&sr=b&sig=SqkGkkI0wtrYf5mOiwiE9LN1LJlQM5i7nusLr%2BI7qLE%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_FD%20%26%20Deposits%20Summary.png?sp=r&st=2021-05-28T12:57:19Z&se=2999-05-28T20:57:19Z&spr=https&sv=2020-02-10&sr=b&sig=Ab088GXelAeNsrBIlU5EnUL7vdtjh8V2Vs0SOtYxgp4%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: false,
    AllowedForAdvisorPremiumClients: true
}

const GroupFDDepositReport = {
    ReportName: "Group FD & Deposit Report",
    ReportId: [MPrReportNumber.GroupFDDepositReport],
    AssetTypes: [
        {id: 0,  text: 'Generate Report'}
    ],
    ShortDescription: "Get an overview of FDs and Deposits across all portfolios in this group with details such as Maturity date, Interest type, Interest %, Investment Date, Principal Amount and Maturity Amount.",
    Description: [
        "Get an overview of FDs and Deposits across all portfolios in this group with details such as Maturity date, Interest type, Interest %, Investment Date, Principal Amount and Maturity Amount.",
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Group%20FD%20%26%20Deposits%20Summary.pdf?sp=r&st=2021-05-28T12:59:13Z&se=2999-05-28T20:59:13Z&spr=https&sv=2020-02-10&sr=b&sig=ARhreWeofoscaRQgJdBtjswwvZ%2FKs89HudA5Lzhy3yg%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Group%20FD%20%26%20Deposits%20Summary.xlsx?sp=r&st=2021-05-28T13:00:36Z&se=2999-05-28T21:00:36Z&spr=https&sv=2020-02-10&sr=b&sig=Dv52ULkK7MsN5CGDXk6UfhZpnYf4N%2FYSX%2Bq2fmNZA7w%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Group%20FD%20%26%20Deposits%20Summary.png?sp=r&st=2021-05-28T12:57:38Z&se=2999-05-28T20:57:38Z&spr=https&sv=2020-02-10&sr=b&sig=QiPXCf5gNbmFRIl3quindaaLTfLhBVkGBDIPAW7wut8%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: false,
    AllowedForAdvisorPremiumClients: true
}

const GroupULIPInsReport = {
    ReportName: "Group ULIP & Insurance Report",
    ReportId: [MPrReportNumber.GroupULIPInsReport],
    AssetTypes: [
        {id: 0,  text: 'Generate Report'}
    ],
    ShortDescription: "Get an overview of ULIP and Insurance Policies across all portfolios in this group with details such as Policy No., Sum Assured, Nominee, Total Premium Paid, Total Withdrawal and Maturity Date.",
    Description: [
        "Get an overview of ULIP and Insurance Policies across all portfolios in this group with details such as Policy No., Sum Assured, Nominee, Total Premium Paid, Total Withdrawal and Maturity Date. Get a total of Sum Assured across all the policies in this group.",
    ],
    PreviewPdfUrl: "https://reportpreviews.blob.core.windows.net/pdf-previews/Sample%20Report_Group%20ULIP%20%26%20Insurance.pdf?sp=r&st=2021-05-28T12:59:33Z&se=2999-05-28T20:59:33Z&spr=https&sv=2020-02-10&sr=b&sig=fz3IsXFsPZJODVxhA48%2BQYfMW%2FJE1LftX78RDvEDMM8%3D",
    PreviewExcelUrl: "https://reportpreviews.blob.core.windows.net/excel-previews/Sample%20Report_Group%20ULIP%20%26%20Insurance.xlsx?sp=r&st=2021-05-28T13:01:07Z&se=2999-05-28T21:01:07Z&spr=https&sv=2020-02-10&sr=b&sig=MniQD8EDLp56fS%2FFlw77S7%2FRoHxawujafUayoh993y8%3D",
    PreviewImage: "https://reportpreviews.blob.core.windows.net/img-previews/Sample%20Report_Group%20ULIP%20%26%20Insurance.png?sp=r&st=2021-05-28T12:58:17Z&se=2999-05-28T20:58:17Z&spr=https&sv=2020-02-10&sr=b&sig=F3OpXoIy%2FWbE4p9pzGF0G7uIV63OVHhGV3neIew%2Bkfc%3D",
    LockedForLicenseTypes: [LicenseType.INVESTOR],
    LockedForTrial: false,
    AllowedForAdvisorPremiumClients: true
}

export const ReportDetails = {
    PortfolioSummary,
    GroupPortfolioSummary,
    HistoricalValuation,
    CapitalGainsSummary,
    XIRR,
    GroupXIRRSummary,
    GroupXIRRDetailed,
    CapitalGainsITR,
    ClosingBalance,
    HoldingPeriod,
    StockRegister,
    UnrealisedCapitalGainsSummary,
    UnrealisedCapitalGainsDetailed,
    DueDates,
    GroupDueDates,
    CapitalGainsITR_ClearTax,
    CapitalGainsITR_Winman,
    CapitalGainsITR_Computax,
    CapitalGainsITR_Spectrum,
    CapitalGainsITR_Genius,
    CapitalGainsITR_TaxPro,
    CapitalGainsITR_112A,
    StocksCNSummary,
    StocksTransactions,
    MFTransactions,
    CNCharges,
    IncomeReportSummary,
    IncomeReportDetailed,
    GroupIncomeReportSummary,
    GroupIncomeReportDetailed,
    ULIPInsReport,
    FDDepositReport,
    GroupFDDepositReport,
    GroupULIPInsReport
};