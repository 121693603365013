export function DownloadPDFIcon(props: any) {
    return (
    <svg {...props} id="Secondary_icon_button_without_bg_" data-name="Secondary icon button (without bg)" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <g id="Group_229307" data-name="Group 229307" transform="translate(8 8)">
          <path id="Path_272379" data-name="Path 272379" d="M0,0H24V24H0Z" fill="none"/>
          <path id="Path_272380" data-name="Path 272380" d="M4,17v2a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V17" fill="none" stroke="#12131a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          <path id="Path_272381" data-name="Path 272381" d="M7,11l5,5,5-5" fill="none" stroke="#12131a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          <path id="Path_272382" data-name="Path 272382" d="M12,4V16" fill="none" stroke="#12131a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
      </svg>
      
    );
}