

import SvgIcon from '@mui/material/SvgIcon';

export function ZigzagArrowUp(props: any) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
        <defs>
          <clipPath id="clipPath">
            <rect id="Rectangle_23758" data-name="Rectangle 23758" width="24" height="24" fill="#0e8c43" stroke="#0e8c43" strokeWidth="1" />
          </clipPath>
        </defs>
        <g id="Group_148420" data-name="Group 148420" transform="translate(-1)">
          <g id="Mask_Group_41" data-name="Mask Group 41" transform="translate(1)" clipPath="url(#clipPath)">
            <path id="direction-curve" d="M6.12,0,0,6.12,1.236,7.356l4.009-4.01V9.264A2.631,2.631,0,0,1,4.671,10.9L2.707,13.357a4.388,4.388,0,0,0-.958,2.731v4.893H3.5V16.088a2.631,2.631,0,0,1,.574-1.638L6.036,12a4.388,4.388,0,0,0,.958-2.731V3.346L11,7.355,12.239,6.12Z" transform="translate(14.486) rotate(40)" fill="#0e8c43" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}