import { Box } from "@mui/material"
import { CustomTypography } from "../../../shared"
import NavigationSecondaryTabs from "../../../shared/navigation-secondary-tabs"
import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams"
import { SharedConstants, SharedRedux, SharedUtilities } from "@mprofit/shared"
import { useHoldingsMode } from "packages/web/src/classes/HoldingsModeHook"
import { useSearchParams } from "react-router-dom"
import { useAppSelector } from "packages/web/src/redux/hooks"

interface HoldingTabsProps {
    MPrUrlParams: MPrUrlParams
}

export const HoldingTabs = ({ MPrUrlParams }: HoldingTabsProps) => {
    const holdingsMode = useHoldingsMode(SharedUtilities.getDefaultHoldingsModeForScreen(MPrUrlParams?.Screen, MPrUrlParams?.AssetType), null);
    const [searchParams, setSearchParams] = useSearchParams();

    const assetAllocationCategID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategID);
    
    if (MPrUrlParams == null) return <></>;


    var tabs = assetAllocationCategID && assetAllocationCategID > 0 ? null : SharedUtilities.getHoldingsModesListForScreen(MPrUrlParams.Screen, MPrUrlParams.AssetType);

    if (tabs == null || tabs.length == 0) return <></>;

    const setActiveTab = (activeTabID: string | number) => {
        let updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.set('h', '' + activeTabID);
        setSearchParams(updatedSearchParams.toString());
    }

    var tourClassName = undefined;
    if (MPrUrlParams.AssetType === SharedConstants.AssetTypeEnum.EQ) {
        tourClassName = 'apptour-holding-stockinsights'
    } else if (MPrUrlParams.AssetType === SharedConstants.AssetTypeEnum.MFEQ) {
        tourClassName = 'apptour-holding-mfinsights'
    }

    return <Box sx={{ overflowX: 'auto', '::-webkit-scrollbar': { width: '1px' }, }} className={tourClassName}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', }}>
            <CustomTypography variant="Regular18" sx={{ color: { xs: '#64677A', marginRight: '21px' }, minWidth: 'max-content' }}>View by</CustomTypography>
            <NavigationSecondaryTabs
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTabID={holdingsMode}
                bgColor="#ffffff"
            />
        </Box>
    </Box>
}