import { Box, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal } from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction, memo, useEffect, useState } from "react";
import { AddGreen } from "../../../assets/icons/AddGreen";
import { Cross } from "../../../assets/icons/Cross";
import { DotIcon } from "../../../assets/icons/DotIcon";
import { GoalPortfolioIcon_FamilyView } from "../../../assets/icons/GoalPortfolioIcon_FamilyView";
import { GroupMemberIcon } from "../../../assets/icons/GroupMemberIcon";
import { StrategyPortfolioIcon_FamilyView } from "../../../assets/icons/StrategyPortfolioIcon_FamilyView";
import { MemberIcon } from "../../../assets/icons/MemberIcon";
import { TooltipDelete } from "../../../assets/icons/TooltipDelete";
import { TooltipEdit } from "../../../assets/icons/TooltipEdit";
import CustomTypography from "../../shared/customTypography";
import SearchBar from "../../shared/search-bar";
import styles from './index.module.css'
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities } from "@mprofit/shared";
import { useNavigate } from "react-router-dom";
import { NavigateToPortfolio } from "packages/web/src/routes/navigationMethods";

const FamilyModal = memo(({ isModalOpen, setIsModalOpen }: {isModalOpen: boolean, setIsModalOpen: Dispatch<SetStateAction<boolean>>}) => {
  const activeFamily = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveFamily);
  const [tempActiveFamily, setTempActiveFamily] = useState<SharedConstants.Family | undefined>(activeFamily);

  useEffect(() => {
    setTempActiveFamily(activeFamily)
  }, [activeFamily])

  return (
    <Modal
      open={isModalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid className={styles.modal} sx={{ width: { xs: '90%', lg: '80%', } }}>
        <Grid container>
          <FamilyListColumn tempActiveFamily={tempActiveFamily} setTempActiveFamily={setTempActiveFamily}></FamilyListColumn>
          <PortfolioListSection tempActiveFamily={tempActiveFamily} setOpenModal={setIsModalOpen}></PortfolioListSection>
        </Grid>
      </Grid>
    </Modal>
  )
});

export default FamilyModal;

const FamilyListColumn = ({tempActiveFamily, setTempActiveFamily}: {tempActiveFamily?: SharedConstants.Family, setTempActiveFamily: Dispatch<SetStateAction<SharedConstants.Family | undefined>>}) => {
  const familyList = useAppSelector(SharedRedux.Portfolios.Selectors.selectFamilyList);
  const [searchTerm, setSearchTerm] = useState('');

  const finalList = SharedUtilities.filterItems(searchTerm, familyList, ['FamilyName']);

  return (
    <Grid item xs={3} sx={{ paddingTop: '32px', backgroundColor: '#ffffff', borderRadius: '8px 0px 0px 8px' }}>
      <Box className={styles.familyNames}>
        <Box>
          <CustomTypography className={styles.familyTitle}>{t('leftNav.modal.familyList.title')}</CustomTypography>
        </Box>
        <Box className={styles.searchBox}>
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
        </Box>
        <List>
          <Box className={styles.familiesList}>
            {(finalList || []).map((family, index) => {
              var isTempActive = (tempActiveFamily && tempActiveFamily.FamilyId === family.FamilyId);
              return (
              <ListItem
                key={index}
                disablePadding
                className={styles.familyNamesItems}
                sx={isTempActive ? {backgroundColor: '#000'} : {}}
                onClick={() => setTempActiveFamily(family)}
              >
                <ListItemButton key={index}>
                  <CustomTypography variant="Regular20" sx={{ color: isTempActive ? '#fff' : '#64677A' }}>{family.FamilyName}</CustomTypography>
                </ListItemButton>
              </ListItem>
            )})}
          </Box>
        </List>
        {/* <Box className={styles.addNewFamilyBox}>
          <CustomTypography
            variant="Medium18"
          >
            {t('leftNav.modal.familyList.footer')}
          </CustomTypography>
          <Box>
            <AddGreen />
          </Box>
        </Box> */}
      </Box>
    </Grid>
  )
}

const PortfolioListSection = ({tempActiveFamily, setOpenModal}: {tempActiveFamily?: SharedConstants.Family, setOpenModal: Dispatch<SetStateAction<boolean>>}) => {
  const tempActivePortfolioList = useAppSelector((state) => SharedRedux.Portfolios.Selectors.selectPortfolioListByFamily(state, tempActiveFamily));

  const mainPortfoliosList = (tempActivePortfolioList || []).filter(x => !x.IsGroup && !x.IsStrategyPortfolio && !x.IsGoalPortfolio);
  const groupsList = (tempActivePortfolioList || []).filter(x => x.IsGroup);
  const strategyGoalPortfoliosList = (tempActivePortfolioList || []).filter(x => x.IsStrategyPortfolio || x.IsGoalPortfolio);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectPortfolio = (portfolio: SharedConstants.Portfolio) => {
    var activePortfolio = { CCID: portfolio.CCID, FamilyId: portfolio.FamilyId, PFID: portfolio.Id };
    NavigateToPortfolio({ navigate, shouldDispatchActivePortfolios: true, dispatch, activePortfolio });
    setOpenModal(false);
  };

  return (
    <Grid item xs={9} sx={{ padding: "40px 32px 0px 0px" }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} sx={{ paddingLeft: '20px', }}>
              <CustomTypography variant="Medium24" sx={{ color: { xs: "#64677A" } }}>{tempActiveFamily?.FamilyName}</CustomTypography>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Box className={styles.dots}
                  // onMouseEnter={handleTooltip}
                  // onMouseLeave={handleTooltipOut}
                >
                  <DotIcon/>
                </Box> */}
                <Box onClick={() => {
                  setOpenModal(false)
                }} sx={{cursor: 'pointer'}}>
                    <Cross />
                  </Box>
                  {/* {tooltip ? (
                    <List className={styles.tooltip}>
                      <ListItem disablePadding>
                        <ListItemButton
                          key={"edit"}
                          sx={{ padding: "8px 15px" }}
                        >
                          <ListItemIcon sx={{ minWidth: "32px" }}>
                            {<TooltipEdit />}
                          </ListItemIcon>
                          <CustomTypography>
                            {t("leftNav.modal.tooltip.editFamily")}
                          </CustomTypography>
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          key={"delete"}
                          sx={{ padding: "8px 15px" }}
                        >
                          <ListItemIcon sx={{ minWidth: "32px" }}>
                            {<TooltipDelete />}
                          </ListItemIcon>
                          <CustomTypography sx={{ color: { xs: "#c94c40" } }}>
                            {t('leftNav.modal.tooltip.deleteFamily')}
                          </CustomTypography>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  ) : (
                    ""
                  )} */}
                </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Box className={styles.members}>
            <Box className={styles.portfolioHeader}>
              <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }} className={styles.Typography}>
                {t('leftNav.modal.portfolio')}
              </CustomTypography>
              {/* <AddGreen /> */}
            </Box>
            <Divider
              className={styles.Divider}
            />
            <List>
              <Box className={styles.membersList}>
                {mainPortfoliosList.map((portfolio, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    className={styles.membersItems}
                    onClick={() => selectPortfolio(portfolio)}
                  >
                    <ListItemButton key={index} sx={{alignItems: 'start'}}>
                      <ListItemIcon sx={{ minWidth: "35px" }}>
                        <MemberIcon />
                      </ListItemIcon>
                      <CustomTypography variant="Regular20" sx={{ color: { xs: '#64677A' } }}>{portfolio.PFullName || portfolio.PName}</CustomTypography>
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            </List>
          </Box>
        </Grid>
        {
          groupsList && groupsList.length > 0 &&
          <Grid item xs={4}>
            <Box className={styles.members}>
              <Box className={styles.portfolioHeader}>
                <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }} className={styles.Typography}>
                  {t('leftNav.modal.groups')}
                </CustomTypography>
                {/* <AddGreen /> */}
              </Box>
              <Divider
                className={styles.Divider}
              />
              <List>
                <Box className={styles.membersList}>
                  {groupsList.map((portfolio, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      className={styles.membersItems}
                      onClick={() => selectPortfolio(portfolio)}
                    >
                      <ListItemButton
                        key={index}
                        sx={{ padding: "14px 28px" }}
                      >
                        <ListItemIcon sx={{ minWidth: "35px" }}>
                          <GroupMemberIcon />
                        </ListItemIcon>
                        <CustomTypography variant="Regular20" sx={{ color: { xs: '#64677A' } }}>{portfolio.PFullName || portfolio.PName}</CustomTypography>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Box>
              </List>
            </Box>
          </Grid>
        }
        {
          strategyGoalPortfoliosList && strategyGoalPortfoliosList.length > 0 &&
          <Grid item xs={4}>
            <Box className={styles.portfolioHeader}>
              <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' } }}>{t('leftNav.modal.strategyAndGoal')}</CustomTypography>
              {/* <AddGreen /> */}
            </Box>
            <Divider
              className={styles.lastDivider}
            />
            <List>
              <Box className={styles.membersList}>
                {strategyGoalPortfoliosList.map((portfolio, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    className={styles.membersItems}
                  >
                    <ListItemButton
                      key={index}
                      sx={{ padding: "14px 28px" }}
                      onClick={() => selectPortfolio(portfolio)}
                    >
                      <ListItemIcon sx={{ minWidth: "35px" }}>
                        {portfolio.IsGoalPortfolio ? (
                          <GoalPortfolioIcon_FamilyView />
                          ) : (
                          <StrategyPortfolioIcon_FamilyView />
                        )}
                        {/* {value.icon} */}
                      </ListItemIcon>
                      <CustomTypography variant="Regular20" sx={{ color: { xs: '#64677A' } }}>{portfolio.PFullName || portfolio.PName}</CustomTypography>
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            </List>
          </Grid>
        }
      </Grid>
  </Grid>
  )
}







