import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Box, Button, Container, FormControl, InputLabel, TextField, Typography, styled } from '@mui/material'
import LongBackArrow from 'packages/web/src/assets/icons/login/LongBackArrow';
import CustomTypography from '../../../shared/customTypography';
import IllustrationsBackground from '../../../../assets/background/illustrations.jpg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, SubmitHandler } from "react-hook-form";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';
import { WebRedux } from '@mprofit/web';
import { SharedRedux, SharedConstants, SharedAPIs } from '@mprofit/shared';
import { HttpStatusCode } from 'axios'
import { AuthPage } from 'packages/web/src/constants/login';
import LoginBtnLoader from '../login-btn-loader';
interface IFormInputs {
  code: string
  password: string
}

interface ICodeInputProps {
  setVerified: React.Dispatch<React.SetStateAction<boolean>>,
  setIsValidOtp: React.Dispatch<React.SetStateAction<boolean>>,
  otpValue?: string,
  onOtpChange: (otp: string) => void,
  otpArray: string[];
  setOtpArray: React.Dispatch<React.SetStateAction<string[]>>;
}


export default function OTPVerification({isPasswordResetCodeVerification = false}: {isPasswordResetCodeVerification?: boolean}) {
  const dispatch = useAppDispatch();
  const [resendOTP, setResendOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpArray, setOtpArray] = useState<string[]>(["", "", "", "", "", ""]);
  const [verified, setVerified] = useState<boolean>(true);
  const [isWrongOtp, setIsWrongOtp] = useState<boolean>(false);
  const [isValidOtp, setIsValidOtp] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (resendOTP) {
      setTimeout(() => {
        setResendOTP(false);
      }, 5000);
    }
  }, [resendOTP])

  const { register, formState: { errors }, handleSubmit, setValue } = useForm<IFormInputs>();

  var loginPayload = useAppSelector(WebRedux.Auth.Selectors.selectLoginPayload) || { email: '', password: '' };

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    setIsWrongOtp(false);
    setLoading(true);
    SharedAPIs.Login.SubmitResetPasswordAPI(loginPayload.email, data.code).then(response => {
      if (response.status === HttpStatusCode.Ok && response.data.IsSuccess) {
        setLoading(false);
        dispatch(WebRedux.Auth.Actions.setLoginPayload({ email: loginPayload.email, password: '', mfaOTP: data.code }));
        dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: AuthPage.SetNewPassword }))
      }
      else {
        setLoading(false);
        setIsWrongOtp(true)
      }
    })
  };

  const loginState = useAppSelector(SharedRedux.Login.Selectors.selectLoginState);

  useEffect(() => {
    if (loginState.IsError) {
      setOtpArray(["", "", "", "", "", ""]);
      setIsValidOtp(false);
    } else if (loginState.LoginStatus === SharedConstants.Login_Status.ENTER_MFA_OTP && loading) {
      setResendOTP(true);
      setLoading(false);
    }
  }, [loginState])

  const handleVerify = () => {
    var otpLoginPayload: SharedConstants.LoginPayload = { ...loginPayload, mfaOTP: otp };
    dispatch(SharedRedux.Login.Actions.loginClicked(otpLoginPayload));
  }

  const handleResendOTP = () => {
    setLoading(true);
    if (!isPasswordResetCodeVerification) {
      var otpLoginPayload: SharedConstants.LoginPayload = { ...loginPayload };
      dispatch(SharedRedux.Login.Actions.loginClicked(otpLoginPayload));
    }
    else {
      SharedAPIs.Login.ResetPasswordAPI(loginPayload.email).then(response => {
        if (response.status === HttpStatusCode.NoContent) {
          //do something
          setResendOTP(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    }
  }

  const onBackClick = () => {
    dispatch(SharedRedux.Login.Actions.reset());
    dispatch(WebRedux.Auth.Actions.handleBack());
  }

  var codeOrOTP = isPasswordResetCodeVerification ? 'code' : 'OTP';

  return (
    <Wrapper>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <BackContainer onClick={onBackClick}>
          <LongBackArrow />
          <CustomTypography variant='Medium16' sx={{ marginLeft: '8px', color: { xs: 'brandBase.300' } }}>Back</CustomTypography>
        </BackContainer>
        <HeaderCont>
          <Heading>Check your email</Heading>
          <CustomTypography variant='Regular18' sx={{ paddingTop: '16px' }}>{isPasswordResetCodeVerification ? `We've sent a password reset code to` : `We've sent a 6-digit OTP to`}</CustomTypography>
          <CustomTypography variant='Medium18' sx={{ color: { xs: 'brandBase.300' } }}>{loginPayload.email}</CustomTypography>
        </HeaderCont>

        {isPasswordResetCodeVerification ?
          <FormContainer>
            <CodeInputCont>
              <Box component="form" noValidate autoComplete="off" sx={{ width: '100%', paddingTop: '52px' }} onSubmit={handleSubmit(onSubmit)}>
                <InputLabel htmlFor="code" sx={{ padding: '10px 0px', color: 'black', fontSize: '18px' }}>Password reset code</InputLabel>
                <FormControl fullWidth={true}>
                  <TextField
                    id="code"
                    aria-describedby="code-id"
                    placeholder='Enter code'
                    hiddenLabel
                    error={errors.code && true}
                    {...register("code", { required: true })}
                  />
                  <ErrorText sx={{ visibility: isWrongOtp ? 'visible' : 'hidden', color: { xs: '#C94C40' }, marginBottom: '4px' }} variant='Regular16'><WarningRoundedIcon fontSize="small" />Invalid Code</ErrorText>
                </FormControl>
                <ContinueButton size='small' variant='contained' type='submit'>
                  {
                    loading ?
                      <LoginBtnLoader />
                      :
                      'Continue to reset password'
                  }</ContinueButton>
              </Box>
            </CodeInputCont>
          </FormContainer>
          :
          <OTPContainer>
            <CustomTypography variant="Regular18">Enter the 6-digit OTP</CustomTypography>
            <CodeInputCont sx={{ display: 'block' }}>
              <CodeInput setVerified={setVerified} setIsValidOtp={setIsValidOtp} onOtpChange={setOtp} otpArray={otpArray} setOtpArray={setOtpArray} />
              {loginState.IsError && <ErrorText variant='Regular16' sx={{ color: { xs: '#C94C40' } }}><WarningRoundedIcon fontSize="small" />{'Invalid OTP. Please try again.'}</ErrorText>}
            </CodeInputCont>
            <VerifyButton disabled={!isValidOtp} size='small' variant='contained' onClick={() => handleVerify()}>
              {
                loginState.LoginStatus === SharedConstants.Login_Status.LOGGING_IN ?
                  <LoginBtnLoader />
                  : 'Verify OTP'
              }
            </VerifyButton>
          </OTPContainer>
        }
        {
          resendOTP ?
            <CustomTypography
              variant="Regular16"
              sx={{
                color: { xs: "brandBase.300" },
                height: '100%',
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'center',
                marginBottom: 'auto',
                paddingLeft: '32px',
                paddingBottom: '30px'
              }}
            >
              <CheckCircleIcon fontSize='small' sx={{ marginRight: '6px' }} />{`A new ${codeOrOTP} has been sent to your email address.`}
            </CustomTypography>
            :
            (
              !loading &&
              <CustomTypography variant="Regular16"
                sx={{ height: '100%', display: 'flex', alignItems: 'end', justifyContent: 'center', marginBottom: 'auto', paddingLeft: '32px', paddingBottom: '30px' }}>
                {`Didn't receive the ${codeOrOTP}?`}
                <SpanText
                  variant='Medium16'
                  sx={{ color: { xs: 'brandBase.300' }, marginLeft: '6px', cursor: 'pointer' }}
                  onClick={() => {
                    handleResendOTP()
                  }}
                >
                  {`Resend ${codeOrOTP}`}
                </SpanText>
              </CustomTypography>
            )
        }
      </Box>
    </Wrapper>
  )
}

const CodeInput: React.FC<ICodeInputProps> = ({ otpArray, setOtpArray, onOtpChange, setVerified, setIsValidOtp }: ICodeInputProps) => {
  const [activeInput, setActiveInput] = useState<number>(0);

  const inputs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    onOtpChange(otpArray.join(""));
    if (otpArray.join("").length === 6) {
      setIsValidOtp(true)
    } else {
      setIsValidOtp(false)
    }

  }, [otpArray]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;

    if (!isNaN(parseInt(value)) && value !== "") {
      setOtpArray((prevState) => {
        const newState = [...prevState];
        newState[index] = value;
        return newState;
      });

      if (index < inputs.current.length - 1 && value) {
        inputs.current[index + 1].focus();
      }
    } else if (value === "" && otpArray[index]) {
      setOtpArray((prevState) => {
        const newState = [...prevState];
        newState[index] = "";
        return newState;
      });

      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 6);
    const newOtp = [...otpArray];
    for (let i = 0; i < pastedData.length; i++) {
      if (i < newOtp.length) {
        newOtp[i] = pastedData[i];
      }
    }
    setOtpArray(newOtp);
  };

  return (
    <div>
      {Array.from({ length: 6 }, (_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          value={otpArray[index]}
          onChange={(e) => handleInputChange(e, index)}
          onPaste={handlePaste}
          style={{
            boxSizing: 'border-box',
            width: "40px",
            height: "48px",
            margin: "12px 12px 6px 0",
            // border: '1px solid #D8DAE5',
            border: activeInput === index ? "2px solid #64677A" : '1px solid #D8DAE5',
            borderRadius: '4px',
            paddingLeft: '12px'
          }}
          // style={{ boxSizing: 'border-box', width: "40px", height: "48px", margin: "12px 12px 6px 0", border: activeInput == index ? "2px solid #C94C40" : '1px solid #D8DAE5', borderRadius: '4px', paddingLeft: '12px' }}
          ref={(input) => {
            inputs.current[index] = input as HTMLInputElement;
          }}
          onFocus={() => setActiveInput(index)}
          onBlur={() => setActiveInput(-1)}
        />
      ))}
    </div>
  );
};

const Wrapper = styled(Container)({
  boxSizing: 'border-box',
  maxHeight: '73%',
  minHeight: '550px',
  height: '65%',
  minWidth: '550px',
  width: '53%',
  marginLeft: 'auto',
  background: '#FFFFFF',
  borderRadius: '8px',
  margin: 'auto',
  padding: '24px'
});

const BackContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '59px',
  cursor: 'pointer'
});

const HeaderCont = styled(Box)({
  backgroundImage: `url(${IllustrationsBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center right',
  // backgroundSize: '187px 102px'
  marginLeft: '32px'
});

const Heading = styled(Typography)({
  fontSize: '36px',
  lineHeight: '48px',
  fontWeight: 500
});

const OTPContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '64px',
  paddingLeft: '32px'
});

const FormContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '32px',
  paddingRight: '32px'
});

const CodeInputCont = styled(Box)({
  display: 'flex',
});

const VerifyButton = styled(Button)({
  padding: '15px 32px 14px 32px',
  background: '#010006',
  textTransform: 'none',
  color: 'base.white',
  width: 'fit-content',
  maxWidth: '135px',
  height: '48px',
  ':hover': {
    background: '#252833'
  },
  ':disabled': {
    background: '#EBECF2',
    color: '#BABDCC'
  },
  marginTop: '20px'
});

const ContinueButton = styled(Button)({
  padding: '15px 32px 14px 32px',
  background: '#010006',
  textTransform: 'none',
  color: 'base.white',
  width: '100%',
  height: '48px',
  ':hover': {
    background: '#252833'
  },
  ':disabled': {
    background: '#EBECF2',
    color: '#BABDCC'
  }
});

const ErrorText = styled(CustomTypography)({
  minHeight: '24px',
  display: 'flex',
  alignItems: 'center',
});

const SpanText = styled(CustomTypography)({

});