import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import CustomTypography from '../../customTypography';
import { SharedConstants, SharedUtilities } from '@mprofit/shared';

interface ITrailingPeriodProps {
    setSelectedTrailingPeriod: React.Dispatch<React.SetStateAction<SharedConstants.TrailingPeriodItem | undefined>>
}

export default function TrailingPeriod({ setSelectedTrailingPeriod }: ITrailingPeriodProps) {
    const TrailingPeriodList = useMemo(SharedUtilities.getTrailingPeriodListForPeriodSelection, []);

    const [selectedTrailingPeriodIndex, setSelectedTrailingPeriodIndex] = useState<number>(0);

    const onSelectTrailingPeriod = (item: SharedConstants.TrailingPeriodItem) => {
        setSelectedTrailingPeriodIndex(item.index);
        setSelectedTrailingPeriod(item);
    }

    useEffect(() => {
        if (TrailingPeriodList && TrailingPeriodList[0]) {
            setSelectedTrailingPeriod(TrailingPeriodList[0]);
        }
    }, [])

    console.log('TrailingPeriodList',TrailingPeriodList)
    return (
        <Box>
            {TrailingPeriodList.map((item, index) => (
                <Box
                    key={item.title}
                    sx={{
                        border: '1px solid #EBECF2',
                        padding: '14px 27px 14px 16px',
                        marginBottom: "16px",
                        borderRadius: '4px',
                        display: 'flex',
                        background: selectedTrailingPeriodIndex === item.index ? "#EAF4DF" : "#FFFFFF",
                        cursor: 'pointer'
                    }}
                    onClick={() => onSelectTrailingPeriod(item)}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box>
                            <CustomTypography variant="Regular18" >{item.title}</CustomTypography>
                        </Box>
                        {/* <Box sx={{ paddingLeft: '20px' }}>
                            <Selection isSelected />
                        </Box> */}
                    </Box>
                </Box>
            ))
            }
        </Box>
    )
}