import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from '@mui/material';

interface ComponentTabsProps {
  id?: string
  options: {id: number, text: string}[];
  disabled?: boolean;
  handleChange?: (newValue: number) => void;
  value?: number;
  bgColor?: string
}

export default function ComponentTabs({ id, options, disabled, handleChange, value, bgColor }: ComponentTabsProps) {
  return (
    <Box sx={{ padding: '5px', borderRadius: '8px', background: bgColor || "#EBECF2", minWidth: 'max-content' }}>
      <ToggleButtonGroup
        id={id}
        disabled={disabled}
        value={value}
        exclusive
        onChange={handleChange ? (event, value) => handleChange(value) : undefined}
        aria-label="text alignment"
        sx={{ height: { xs: "33px", lg: "40px" } }}
      >
        {
          options.map(((option, index) => (
            <ToggleButton
              key={index}
              value={option.id}
              disableRipple
              sx={{
                height: { xs: "33px", lg: '40px' },
                fontSize: '18px',
                fontWeight: '400',
                padding: "4px 32px 4px 32px",
                border: "none",
                backgroundColor: bgColor || "#F6F6F6",
                textTransform: "initial",
                color: '#64677A',
                borderRadius: "8px !important",
                "&.Mui-selected": {
                  "&.MuiToggleButton-root": {
                    borderRadius: "8px",
                    background: "#D0DBBF",
                    color: '#12131A'
                  },
                },
                "&.Mui-disabled": {
                  "&.MuiToggleButton-root": {
                    border: "none",
                    borderRadius: "8px",
                    background: index === 0 ? "#EBECF2" : "#F6F6F6",
                    color: index === 0 ? "#BABDCC" : ""
                  },
                },
                "&:hover": {
                  "&.MuiToggleButton-root": {
                    borderRadius: "8px",
                    color: '#12131A'
                  },
                }
              }}
            >
              {option.text}
            </ToggleButton>
          )))
        }
      </ToggleButtonGroup>
    </Box>
  );
}
