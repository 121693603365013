import { all } from "redux-saga/effects";

import { SharedRedux } from '@mprofit/shared';
// import catSaga from './modules/tempSaga'

export default function* rootSaga() {
    yield all([
        ...(SharedRedux.Sagas.map((saga: any) => saga())),
        // sagaLogin(),
        // catSaga()
    ]);
}
