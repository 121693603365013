import { GroupReportCategoryTypes, InvReportCategoryTypes } from "../constants";
import { ReportDetails } from "./report-details";

const InvReportCategoryData = [
    {
        CategoryId: InvReportCategoryTypes.PERFORMANCE,
        CategoryName: "Performance",
        Reports: [
            ReportDetails.PortfolioSummary,
            ReportDetails.XIRR,
            ReportDetails.HistoricalValuation
        ],
        Description: "This is dummy description for Performance"
    },
    {
        CategoryId: InvReportCategoryTypes.CAPITALGAINS,
        CategoryName: "Capital Gains",
        Reports: [
            ReportDetails.CapitalGainsSummary,
            ReportDetails.CapitalGainsITR,
            ReportDetails.CapitalGainsITR_112A,
            ReportDetails.UnrealisedCapitalGainsSummary,
            ReportDetails.UnrealisedCapitalGainsDetailed,
        ],
        Description: "This is description for Capital Gains"
    },
    {
        CategoryId: InvReportCategoryTypes.TRANSACTIONS,
        CategoryName: "Transactions",
        Reports: [
            ReportDetails.StocksCNSummary,
            ReportDetails.CNCharges,
            ReportDetails.StocksTransactions,
            ReportDetails.MFTransactions,
        ],
        Description: "This is description for Transactions"
    },
    {
        CategoryId: InvReportCategoryTypes.ADVANCED,
        CategoryName: "Advanced",
        Reports: [
            ReportDetails.ClosingBalance,
            ReportDetails.HoldingPeriod,
            ReportDetails.StockRegister
        ],
        Description: "This is description for Advanced"
    },
    {
        CategoryId: InvReportCategoryTypes.TAXFORMATS,
        CategoryName: "Tax Software Formats",
        Reports: [
            ReportDetails.CapitalGainsITR_ClearTax,
            ReportDetails.CapitalGainsITR_Winman,
            ReportDetails.CapitalGainsITR_Computax,
            ReportDetails.CapitalGainsITR_Spectrum,
            ReportDetails.CapitalGainsITR_Genius,
            ReportDetails.CapitalGainsITR_TaxPro
        ],
        Description: "This is description for Tax Software Formats"
    },
    {
        CategoryId: InvReportCategoryTypes.INCOME,
        CategoryName: "Income & Other",
        Reports: [
            ReportDetails.IncomeReportSummary,
            ReportDetails.IncomeReportDetailed,
            ReportDetails.FDDepositReport,
            ReportDetails.ULIPInsReport,
            ReportDetails.DueDates,
        ],
        Description: "This is description for Income & Other"
    }
];

const GroupInvReportsCategoryData = [
    {
        CategoryId: GroupReportCategoryTypes.PERFORMANCE,
        CategoryName: "Performance",
        Reports: [
            ReportDetails.GroupPortfolioSummary,
            ReportDetails.GroupXIRRSummary,
            ReportDetails.GroupXIRRDetailed
        ]
    },
    {
        CategoryId: GroupReportCategoryTypes.INCOME,
        CategoryName: "Income & Other",
        Reports: [
            ReportDetails.GroupIncomeReportSummary,
            ReportDetails.GroupIncomeReportDetailed,
            ReportDetails.GroupFDDepositReport,
            ReportDetails.GroupULIPInsReport,
            ReportDetails.GroupDueDates
        ]
    }
]

export const ReportCategoryLists = {
    INV: InvReportCategoryData,
    GroupINV: GroupInvReportsCategoryData
}