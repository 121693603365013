const routeNames = {
    LOGIN: '/',
    REDIRECT: '/redirect',
    REGISTER: '/register',
    PORTFOLIO: '/portfolio',
    TODAY: '/portfolio/today',
    HOLDING: '/portfolio/holding',
    PERFORMANCE: '/portfolio/performance',
    ERROR: '/error',
    TEMP: '/portfolio/temp',
    REPORTS: '/portfolio/reports/explore',
    SAVEDREPORTS: '/portfolio/reports/saved',
    REPORTLOG: '/portfolio/reports/log',
    CASHFLOWS: '/portfolio/cashflows'
}
export default routeNames;